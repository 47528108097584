<mat-card>
    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [gridData]="gridDataResult"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [category]="translate.instant('Published Journeys')"
        canSelectRows="true"
        clickableRows="true"
        searchable="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToPublishWorkflow($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
    ></app-data-grid-comp>
</mat-card>
