import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disability-pay-gap-report',
  templateUrl: './disability-pay-gap-report.component.html',
  styleUrls: ['./disability-pay-gap-report.component.scss']
})
export class DisabilityPayGapReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
