import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    BusinessRuleAutomationNotificationType, BusinessRuleAutomationOutcome,
    BusinessRuleAutomationRecipientType,
    BusinessRuleAutomationRule,
    BusinessRuleAutomationVerbose,
    ConditionType,
    TriggerType
} from '@app/modules/business-rule-automation/models/business-rule-automation.model';
import { BusinessRuleAutomationService } from '@app/modules/business-rule-automation/services/business-rule-automation.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { Table, TableField } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { fieldTypes } from '@app/consts';
import * as moment from 'moment';

@Component({
    selector: 'app-business-rule-automation-form',
    templateUrl: './business-rule-automation-form.component.html',
    styleUrls: ['./business-rule-automation-form.component.scss']
})
export class BusinessRuleAutomationFormComponent implements OnInit {

    constructor(
        private fb: UntypedFormBuilder,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private service: BusinessRuleAutomationService,
        private metadataTableService: MetadataTableService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) {
        this.businessRuleId = this.getIdInURL();
        console.log('this.businessRuleId', this.businessRuleId);
    }

    get localizations() {
        return this.formData.controls.name as UntypedFormArray;
    }

    get shouldShowForm(): boolean {
        const res = this.formLoaded && this.form && (this.businessRuleId === null || this.businessRule !== null);
        return res;
    }

    get rules() {
        return this.form.controls.rules as UntypedFormArray;
    }

    get outcomes() {
        return this.form.controls.outcomes as UntypedFormArray;
    }

    public businessRuleId: string;
    public businessRule: BusinessRuleAutomationVerbose;

    public formId = 'frm_hOU10wBeRpuvho';
    public formData: any;
    public formLoaded = true;
    public formValid = false;
    public formPristine: boolean;
    public getFormData = false;

    public form: UntypedFormGroup;

    // public rules: BusinessRuleAutomationRule[]
    public triggerTypes: TriggerType[];
    public conditionTypes: ConditionType[];
    public filteredConditionTypes: ConditionType[];
    public tables: Table[];
    public fields: TableField[];
    public notificationTypes: BusinessRuleAutomationNotificationType[];
    public triggerTables: TableField[];
    public filteredTriggerTables: TableField[];
    public outcomeTypes: any[] = [
        {id: 'notifications', name: 'Notifications'},
        {id: 'update', name: 'Update'},
    ];
    outcomeTypeVal = 'notifications'
    private shouldCloseForm = true;
    fieldsCache = {};
    triggerFieldsCache = {};
    conditionTypesCache = {};

    outcomeConditionTypesCache = [];

    fieldsToFilter = new Set([
        'tfi_AbsenceNumber',
        'tfi_AbsenceChangeReasonComments',
        'tfi_AbsenceChangeReason',
        'tfi_AbsenceEndTime',
        'tfi_AbsenceStartTime',
        'tfi_OCompReasonComments',
        'tfi_OCompChangeReason',
        'tfi_ChangeReasonComments',
        'tfi_ChangeReason',
        'tfi_PositionChangeReason',
        'tfi_PositionChangeReasonComments',
        'tfi_CoChangeReason',
        'tfi_CohangeReasonComments',
        'tfi_PrefferedLanguage',
        'tfi_ErChangeReason',
        'tfi_ErChangeReasonComments',
        'tfi_ToilChangeReason',
        'tfi_ToilChangeReasonComments',
        'tfi_ToilRecordEndTime',
        'tfi_ToilRecordStartTime',
        'tfi_CostCentre' // TODO need to get this one working on backend
    ]);

    supportedTablesDate = new Set([
        'tbl_Employees',
        'tbl_Toil',
        'tbl_Absences',
        'tbl_EmploymentRecords',
        'tbl_Compensations',
        'tbl_OtherCompensations',
        'tbl_Positions',
    ]);

    private notificationRecipientTypes: BusinessRuleAutomationRecipientType[];

    protected readonly fieldTypes = fieldTypes;

    ngOnInit(): void {

        this.loadData().subscribe(() => {
            if (!this.businessRuleId) {
                this.createForm();
            } else {
                // fetch item
                this.service.getBusinessRuleById(this.businessRuleId).subscribe(
                    (data: BusinessRuleAutomationVerbose) => {
                        this.businessRule = data;
                        this.createForm();
                    });
            }
        });
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('id')
        );
        return IdInURL;
    }

    loadData() {
        const triggerTypes$ = this.service.getTriggerTypes(0, 100);
        const conditionTypes$ = this.service.getConditionTypes(0, 100);
        const tables$ = this.metadataTableService.getTables(0, '1000');
        const notificationTypes$ = this.service.getNotificationTypes(0, 100);
        const notificationRecipientTypes$ = this.service.getNotificationRecipientTypes(0, 100);

        return forkJoin({
            triggerTypes: triggerTypes$,
            conditionTypes: conditionTypes$,
            tables: tables$,
            notificationTypes: notificationTypes$,
            notificationRecipientTypes: notificationRecipientTypes$,
        }).pipe(
            tap(results => {

                const includeTriggerTypeIds = ['AutomationsDate', 'AutomationsUpdate', 'AutomationsDelete', 'AutomationsCreate'];
                this.triggerTypes = results.triggerTypes.data.filter(triggerType => includeTriggerTypeIds.includes(triggerType.id));

                this.conditionTypes = results.conditionTypes.data;

                // const includeIds = ['tbl_Employees', 'tbl_Toil', 'tbl_Absences', 'tbl_EmploymentRecords', 'tbl_Compensations', 'tbl_OtherCompensations', 'tbl_Positions'];
                // this.tables = results.tables.data.filter(table => includeIds.includes(table.id));
                this.tables = results.tables.data;
                this.triggerTables = results.tables.data;

                this.notificationTypes = results.notificationTypes.data;

                this.notificationRecipientTypes = results.notificationRecipientTypes.data.filter(field => {
                    return field.id != 'AutomationsNotificationRecipientTypeManagerL2';
                });
            })
        );
    }

    async createForm() {

        this.formData = {
            name: [],
            description: [],
            status: true,
            activeUsersOnly: false,
        };

        this.form = this.fb.group({
            triggerId: [null],
            triggerType: [null, Validators.required],
            tableId: ['', Validators.required],
            fieldId: ['', Validators.required],
            conditionType: [{value: null}, Validators.required],
            valueOne: [{value: '', disabled: true}, Validators.required],
            rules: this.fb.array([]),
            outcomes: this.fb.array([]),
            outcomeType: ['']
        });


        this.form.get('tableId').valueChanges.subscribe(selectedTableId => {
            if (selectedTableId) {
                console.log(this.triggerFieldsCache)
                if (!this.triggerFieldsCache[selectedTableId]) {
                    this.metadataTableService.getTableFields(selectedTableId, 0, '1000').subscribe(res => {

                        // Filter out unnecessary fields
                        const filteredFields = res.data.filter(field => {
                            return this.fieldsToFilter.has(field.id) === false;
                        });

                        this.triggerFieldsCache[selectedTableId] = filteredFields;
                        this.form.get('fieldId').setValue('');
                        this.form.get('fieldId').setValue('');
                    });
                } else {
                    this.form.get('fieldId').setValue('');
                }
            }
        });

        this.form.get('fieldId').valueChanges.subscribe(selectedFieldId => {
            if (selectedFieldId) {
                this.updateConditionTypesCacheRule(this.form.get('tableId').value, selectedFieldId);
            }
        });

        this.outcomeConditionTypesCache.push(
            {
                id: "update",
                name: "Update"
            }
        );
        this.outcomeConditionTypesCache.push(
            {
                id: "create",
                name: "Create"
            }
        );

        if (this.businessRuleId) {
            console.log('this.businessRule', this.businessRule);
            this.formData.name = this.businessRule.name;
            this.formData.description = this.businessRule.description;
            this.formData.status = this.businessRule.status;
            this.formData.activeUsersOnly = this.businessRule.activeUsersOnly;


            if (this.businessRuleId === "adtg_WllI6y5sbeACnE") {
                // await this.updateConditionTypesCacheRule("tbl_Compensations", "tfi_CompensationLtiStockOption")
                this.form.patchValue({
                    triggerId: this.businessRule.trigger.id,
                    triggerType: "AutomationsUpdate",
                    tableId: "tbl_Compensations",
                    fieldId: " tfi_CompensationStiActualPercentage",
                    valueOne: 300000,
                    outcomeType: 'update',
                });

                // const outcome = new BusinessRuleAutomationOutcome();
                // outcome.id = "id1";
                // outcome.tableId = "";
                // outcome.fieldId = "";
                // outcome.valueOne = null;
                // outcome.valueOne = "";

                const outcome = {
                    id: "testId",
                    tableId: "tbl_Compensations",
                    fieldId: "tfi_CompensationLtiStockOption",
                    conditionType: {
                        id: "update",
                        name: "Update"
                    },
                    valueOne: "4",
                    valueTwo: "",
                }
                this.addNewOutcome(outcome);

                await this.updateTriggerFieldsBasedOnTable("tbl_Compensations", this.form);
                this.form.get('fieldId').setValue('tfi_CompensationStiActualPercentage');
                this.form.get('conditionType').setValue('NumberIsGreaterThan');
                this.outcomeTypeVal = 'update'
            } else if( this.businessRuleId === "adtg_cWpmcx3HO4Truw") {
                this.form.patchValue({
                    triggerId: this.businessRule.trigger.id,
                    triggerType: "AutomationsUpdate",
                    tableId: "tbl_Compensations",
                    fieldId: " tfi_CompensationStiActualPercentage",
                    valueOne: 500000,
                    outcomeType: 'update',
                });

                // const outcome = new BusinessRuleAutomationRule();
                // outcome.id = "id1";
                // outcome.tableId = "";
                // outcome.fieldId = "";
                // outcome.conditionType = "";
                // outcome.valueOne = null;
                // this.addNewOutcome(outcome);
                const outcome = {
                    id: "testId",
                    tableId: "tbl_Compensations",
                    fieldId: "tfi_CompensationLtiStockOption",
                    conditionType: {
                        id: "update",
                        name: "Update"
                    },
                    valueOne: "6",
                    valueTwo: "",
                }
                this.addNewOutcome(outcome);

                await this.updateTriggerFieldsBasedOnTable("tbl_Compensations", this.form);
                this.form.get('fieldId').setValue('tfi_CompensationStiActualPercentage');
                this.form.get('conditionType').setValue('NumberIsGreaterThan');
                this.outcomeTypeVal = 'update'
            } else {
                this.form.patchValue({
                    triggerId: this.businessRule.trigger.id,
                    triggerType: this.businessRule.trigger.triggerType ? this.businessRule.trigger.triggerType.id : null,
                    tableId: this.businessRule.trigger.tableId,
                    outcomeType: 'notifications',
                });


                this.outcomeTypeVal = 'notifications'

                await this.updateTriggerTablesBasedOnId(this.businessRule.trigger.triggerType.id);

                await this.updateTriggerFieldsBasedOnTable(this.businessRule.trigger.tableId, this.form);

                // DONT ASK :)
                await sleep(1000);

                this.form.patchValue({
                    fieldId: this.businessRule.trigger.fieldId,
                });

                this.updateConditionTypes(this.businessRule.trigger.triggerType ? this.businessRule.trigger.triggerType.id : null);

                this.form.patchValue({
                    conditionType: this.businessRule.trigger.conditionType ? this.businessRule.trigger.conditionType.id : null,
                    valueOne: this.businessRule.trigger.valueOne
                });

                }
                this.form.get('valueOne').enable();

                for (const rule of this.businessRule.rules) {
                    this.addNewRule(rule);
            }
        }

        function sleep(ms: number): Promise<void> {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        this.form.get('triggerType').valueChanges.subscribe(async triggerType => {
            await this.updateTriggerChildrenBasedOnId(triggerType, this.form);
            await this.updateTriggerTablesBasedOnId(triggerType);
            this.updateConditionTypes(triggerType);
        });

        this.form.get('tableId').valueChanges.subscribe(async selectedTableId => {
            await this.updateTriggerFieldsBasedOnTable(selectedTableId, this.form);
        });

        this.form.get('fieldId').valueChanges.subscribe(async selectedFieldId => {
            if (selectedFieldId) {
                if (this.form.get('triggerType').value === 'AutomationsDate') {
                    this.form.get('conditionType').enable();
                    this.form.get('valueOne').enable();
                }
            }
        });

        this.form.get('conditionType').valueChanges.subscribe(selectedValue => {
            if (selectedValue) {
                this.form.get('valueOne').setValue('');
                this.form.get('valueOne').enable();
            } else {

                this.form.get('valueOne').disable();
            }
        });
    }

    formDataEmitted(formData) {
        this.save(formData);
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    formStatusUpdated(formValid) {
        if (!this.formLoaded) {
            this.formLoaded = true;
        }
        this.formValid = formValid;
    }

    addNewLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }


    save(formData) {
        formData.trigger = {
            triggerType: this.form.get('triggerType').value,
            tableId: this.form.get('tableId').value,
            fieldId: this.form.get('fieldId').value,
            conditionType: this.form.get('conditionType').value,
            valueOne: this.form.get('valueOne').value,
        };

        const rulesArray = this.form.get('rules').value;

        formData.rules = rulesArray.map(rule => {
            const formattedRule = {
                id: rule.id ? rule.id : null,
                tableId: rule.tableId,
                fieldId: rule.fieldId,
                conditionType: rule.conditionType,
                valueOne: rule.valueOne,
                valueTwo: rule.valueTwo
            };

            if (rule.conditionType === 'DateBefore' || rule.conditionType === 'DateAfter') {
                if (rule.valueOne) {
                    formattedRule.valueOne = moment(rule.valueOne).format('YYYY-MM-DD');
                }
            }

            return formattedRule;
        });

        this.overlayService.show();
        if (!this.businessRuleId) {

            this.service.createBusinessRule(formData).subscribe(
                (data) => {
                    this.overlayService.hide();

                    this.businessRuleId = data.dataTriggerId;

                    if (this.shouldCloseForm) {
                        this.snackbarService.openSnackBar(this.translate.instant('BusinessRuleAutomationCreatedSuccessfully'), 'clear', 'success');
                        this.close();
                    } else {
                        this.shouldCloseForm = true;
                    }
                });

        } else {
            formData.id = this.businessRuleId;
            this.service.updateBusinessRule(formData).subscribe(
                (data) => {
                    this.overlayService.hide();
                    this.snackbarService.openSnackBar(this.translate.instant('BusinessRuleAutomationUpdatedSuccessfully'), 'clear', 'success');
                    this.close();
                });
        }
    }

    close() {
        this.router.navigate(['SiteSettings/BusinessRuleAutomation']);
    }


    addNewOutcome(rule?: any) {
        console.log(rule);

        if (this.rules.length >= 2) {
            return;
        }


        const ruleForm = this.fb.group({
            id: rule?.id ? rule.id : null,
            tableId: [rule?.tableId || '', Validators.required],
            fieldId: ['', Validators.required],
            conditionType: [null, Validators.required],
            valueOne: [{value: '', disabled: false}, Validators.required],
            valueTwo: [{value: '', disabled: true}, Validators.required],
        });

        if (rule) {
            this.loadAndPatchOutcome(rule, ruleForm);
        } else {
            this.evaluateRuleStatus('', ruleForm);
            this.setupValueChangesListeners(ruleForm);
            this.outcomes.push(ruleForm);
        }
    }

    addNewRule(rule?: BusinessRuleAutomationRule) {

        if (this.rules.length >= 3) {
            return;
        }

        const ruleForm = this.fb.group({
            id: rule?.id ? rule.id : null,
            tableId: [rule?.tableId || '', Validators.required],
            fieldId: ['', Validators.required],
            conditionType: [null, Validators.required],
            valueOne: [{value: '', disabled: true}, Validators.required],
            valueTwo: [{value: '', disabled: true}, Validators.required],
        });

        if (rule) {
            this.loadAndPatchRule(rule, ruleForm);
        } else {
            this.evaluateRuleStatus('', ruleForm)
            this.setupValueChangesListeners(ruleForm);
            this.rules.push(ruleForm);
        }
    }

    loadAndPatchRule(rule: BusinessRuleAutomationRule, ruleForm: FormGroup) {
        const tableId = rule.tableId;
        if (tableId && !this.fieldsCache[tableId]) {
            this.metadataTableService.getTableFields(tableId, 0, '1000').subscribe(res => {
                // Filter out unnecessary fields
                const filteredFields = res.data.filter(field => {
                    return this.fieldsToFilter.has(field.id) === false;
                });
                this.fieldsCache[tableId] = filteredFields;
                this.updateConditionTypesCache(tableId, rule.fieldId);
                this.patchRuleForm(rule, ruleForm);
                this.setupValueChangesListeners(ruleForm);
                this.rules.push(ruleForm);
            });
        } else {
            this.patchRuleForm(rule, ruleForm);
            this.setupValueChangesListeners(ruleForm);
            this.rules.push(ruleForm);
        }
        this.evaluateRuleStatus(rule.conditionType.id, ruleForm);
    }

    loadAndPatchOutcome(rule: BusinessRuleAutomationRule, ruleForm: FormGroup) {
        const tableId = rule.tableId;
        if (tableId && !this.fieldsCache[tableId]) {
            this.metadataTableService.getTableFields(tableId, 0, '1000').subscribe(res => {
                // Filter out unnecessary fields
                const filteredFields = res.data.filter(field => {
                    return this.fieldsToFilter.has(field.id) === false;
                });
                this.fieldsCache[tableId] = filteredFields;
                // this.updateConditionTypesCache(tableId, rule.fieldId);
                this.patchRuleForm(rule, ruleForm);
                this.setupValueChangesListeners(ruleForm);
                this.outcomes.push(ruleForm);
            });
        } else {
            this.patchRuleForm(rule, ruleForm);
            this.setupValueChangesListeners(ruleForm);
            this.outcomes.push(ruleForm);
        }
        this.evaluateRuleStatus(rule.conditionType.id, ruleForm);
    }

    patchRuleForm(rule: BusinessRuleAutomationRule, ruleForm: FormGroup) {
        ruleForm.patchValue({
            tableId: rule.tableId,
            fieldId: rule.fieldId,
            conditionType: rule.conditionType.id,
            valueOne: rule.valueOne,
            valueTwo: rule.valueTwo,
        });

    }


    deleteRule(index: number) {
        this.rules.removeAt(index);
    }

    deleteOutcome(index: number) {
        this.outcomes.removeAt(index);
    }

    setupValueChangesListeners(ruleForm) {

        ruleForm.get('tableId').valueChanges.subscribe(selectedTableId => {
            console.log('setupValueChangesListeners > tableId', selectedTableId);
            if (selectedTableId) {
                if (!this.fieldsCache[selectedTableId]) {
                    this.metadataTableService.getTableFields(selectedTableId, 0, '1000').subscribe(res => {

                        // Filter out unnecessary fields
                        const filteredFields = res.data.filter(field => {
                            return this.fieldsToFilter.has(field.id) === false;
                        });

                        this.fieldsCache[selectedTableId] = filteredFields;
                        ruleForm.get('fieldId').setValue('');
                        this.updateFieldsForRule(ruleForm, selectedTableId);
                    });
                } else {
                    this.updateFieldsForRule(ruleForm, selectedTableId);
                }
            }
        });

        ruleForm.get('conditionType').valueChanges.subscribe(selectedValue => {
            this.evaluateRuleStatus(selectedValue, ruleForm);
        });

        ruleForm.get('fieldId').valueChanges.subscribe(selectedFieldId => {
            if (selectedFieldId) {
                this.updateConditionTypesCache(ruleForm.get('tableId').value, selectedFieldId);
            }
        });
    }

    updateConditionTypesCacheRule(tableId: string, selectedFieldId: string) {
        console.log(this.triggerFieldsCache)
        console.log(tableId)
        console.log(selectedFieldId)
        const selectedField = this.triggerFieldsCache[tableId].find(f => f.id === selectedFieldId);
        console.log(selectedField)
        if (!selectedField) { return; }

        switch (selectedField.fieldType.id) {
            case 'TEXT' :
            case 'LOCALIZED' :
                this.filteredConditionTypes = this.conditionTypes.filter(ct => ct.dataType === 'string');
                break;
            case 'DATETIME':
                this.filteredConditionTypes = this.conditionTypes.filter(ct => ct.dataType === 'date');
                break;
            case 'INTEGER':
            case 'DECIMAL':
                this.filteredConditionTypes= this.conditionTypes.filter(ct => ct.dataType === 'number');
                break;
            case 'DROPDOWN':
            case 'BOOLEAN':
                this.filteredConditionTypes = this.conditionTypes.filter(ct => ct.dataType === 'bool');
                break;
        }

        // this.filteredConditionTypes = this.conditionTypesCache[selectedFieldId];
        console.log('Updated conditionTypesCache:', this.filteredConditionTypes);
    }

    updateConditionTypesCache(tableId: string, selectedFieldId: string) {
        const selectedField = this.fieldsCache[tableId].find(f => f.id === selectedFieldId);
        if (!selectedField) { return; }

        switch (selectedField.fieldType.id) {
            case 'TEXT' :
            case 'LOCALIZED' :
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'string');
                break;
            case 'DATETIME':
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'date');
                break;
            case 'INTEGER':
            case 'DECIMAL':
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'number');
                break;
            case 'DROPDOWN':
            case 'BOOLEAN':
                this.conditionTypesCache[selectedFieldId] = this.conditionTypes.filter(ct => ct.dataType === 'bool');
                break;
        }

        // this.filteredConditionTypes = this.conditionTypesCache[selectedFieldId];
        console.log('Updated conditionTypesCache:', this.conditionTypesCache[selectedFieldId]);
    }

    updateFieldsForRule(ruleForm, tableId) {
        ruleForm.get('fieldId').setValue('');
    }

    private updateConditionTypes(triggerTypeId: string) {
        // const selectedTriggerType = this.triggerTypes.find(tt => tt.id === triggerTypeId);
        // if (selectedTriggerType) {
        //     const dataType = this.getDataTypeForTriggerType(selectedTriggerType);
        //     this.filteredConditionTypes = this.conditionTypes.filter(ct => ct.dataType === dataType);
        // } else {
        //     this.filteredConditionTypes = [];
        // }
    }
    private getDataTypeForTriggerType(triggerType: TriggerType): string {
        if (triggerType.id === 'AutomationsDate') {
            return 'date';
        } else if (triggerType.id === 'AutomationsUpdate') {
            return 'none';
        }
        return '';
    }
    private async updateTriggerFieldsBasedOnTable(selectedTableId: string, formGroup: FormGroup): Promise<void> {
        if (selectedTableId) {
            const res = await this.metadataTableService.getTableFields(selectedTableId, 0, '1000').toPromise();
            formGroup.get('fieldId').setValue('');

            let fields;
            if (formGroup.get('triggerType').value === 'AutomationsDate') {
                fields = res.data.filter(field => field.fieldType && field.fieldType.id === 'DATETIME');
            } else {
                fields = res.data;
            }

            this.fields = fields;
            // Additional logic to handle fields (e.g., updating a dropdown)
        }
    }

    private async updateTriggerTablesBasedOnId(triggerTypeId: string): Promise<void> {
        // console.log(this.triggerTables);
        // this.filteredTriggerTables = this.triggerTables;
        // console.log(this.filteredTriggerTables);

        // this.tables = this.filteredTriggerTables;
        // if (triggerTypeId) {
        //     if (triggerTypeId === 'AutomationsDate') {
        //         this.filteredTriggerTables = this.triggerTables.filter(table => {
        //             return this.supportedTablesDate.has(table.id);
        //         });
        //     } else if (triggerTypeId === 'AutomationsUpdate') {
        //         this.filteredTriggerTables = this.triggerTables;
        //     }
        // }
    }

    private async updateTriggerChildrenBasedOnId(triggerTypeId: string,  ruleForm): Promise<void> {
        if (triggerTypeId) {
            if (triggerTypeId === 'AutomationsDate') {

                ruleForm.get('fieldId').setValidators([Validators.required]);
                ruleForm.get('conditionType').setValidators([Validators.required]);
                ruleForm.get('valueOne').setValidators([Validators.required]);

            } else if (triggerTypeId === 'AutomationsUpdate') {

                ruleForm.get('fieldId').setValidators(null);

                ruleForm.get('conditionType').setValidators(null);
                ruleForm.get('conditionType').disable();

                ruleForm.get('valueOne').setValidators(null);
                ruleForm.get('valueOne').disable();
            }

            ruleForm.get('tableId').setValue(null);
            ruleForm.get('fieldId').setValue(null);
            ruleForm.get('conditionType').setValue(null);
            ruleForm.get('valueOne').setValue(null);
        }
    }

    onOutcomeSelectionChange(event: MatSelectChange) {
        const selectedValue = event.value;
        this.outcomeTypeVal = selectedValue;

        if (this.businessRuleId == null) {
            this.shouldCloseForm = false;
            this.getFormData = !this.getFormData;
        }
    }

    evaluateRuleStatus(selectedValue, ruleForm) {
        if (selectedValue == 'StringIsEmpty' || selectedValue == 'StringIsNotEmpty') {
            ruleForm.get('valueOne').setValidators(null);
            ruleForm.get('valueOne').setValue('');
            ruleForm.get('valueOne')?.disable();
        } else if (selectedValue == ''){
            ruleForm.get('valueOne').setValue('');
            ruleForm.get('valueOne')?.disable();
        } else {
            ruleForm.get('valueOne').setValue('');
            ruleForm.get('valueOne').setValidators([Validators.required]);
            ruleForm.get('valueOne')?.enable();
        }

        if (selectedValue != 'NumberIsBetween') {
            ruleForm.get('valueTwo').setValidators(null);
            ruleForm.get('valueTwo').setValue('');
            ruleForm.get('valueTwo')?.disable();
        } else {
            ruleForm.get('valueTwo').setValue('');
            ruleForm.get('valueTwo').setValidators([Validators.required]);
            ruleForm.get('valueTwo')?.enable();
        }
    }
}
