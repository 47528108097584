<ng-container *ngIf="!loadingFormDetails; loading">
    <div class="form">
        <h5 mat-dialog-title>{{ currentCultureFormName }}</h5>
        
        <mat-dialog-content>
            <app-form-generator 
                [formId]="formId"
                [formData]="formData"
                [emitFormDataTrigger]="getFormData"
                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                (emitFormData)="formDataEmitted($event)"
                (emitFormPristine)="formPristineEmitted($event)"
                (emitFormStatus)="formStatusUpdated($event)"
            ></app-form-generator>
        </mat-dialog-content>
        
        <mat-dialog-actions>
            <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
            
            <button 
                *appTableAccess="[tableUpdateAccessObj]" 
                mat-raised-button 
                setColor="primaryColour" 
                color="primary" 
                [disabled]="formValid === false" 
                appPreventDoubleClick 
                (throttledClick)="getFormData = !getFormData">
                {{ 'Save' | translate: {Default: "Save"} }}
            </button> 
        </mat-dialog-actions>
    </div>
</ng-container>

<ng-template #loading>
    <mat-dialog-content class="loading">
        <ngx-skeleton-loader
        count="5"
        [theme]="{ 
            'height.px': 50
        }"
        ></ngx-skeleton-loader>
    </mat-dialog-content>
</ng-template>
