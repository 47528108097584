<mat-accordion multi>
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-checkbox class="example-margin" setColor="primaryColour" [(ngModel)]="task.completed" (click)="checkboxChange($event); $event.stopPropagation();"></mat-checkbox>
            <span class="task">{{task.name}}</span>
        </mat-panel-title>
        <mat-panel-description>
            {{task.description}}
        </mat-panel-description>
      </mat-expansion-panel-header>

        <app-form-generator 
            [formId]="task.form"
            [emitFormDataTrigger]="getFormData"
            (emitFormData)="formDataEmitted($event)"
            (emitFormStatus)="formStatusUpdated($event)"
            (emitFormPristine)="formPristineEmitted($event)"
        ></app-form-generator>
        <button 
            mat-raised-button color="primary" 
            setColor="primaryColour" 
            [disabled]="formValid === false" 
            appPreventDoubleClick 
            (throttledClick)="getFormData = !getFormData" 
        >
            {{ 'Save' | translate: {Default: "Save"} }}
        </button>
    </mat-expansion-panel>
</mat-accordion>