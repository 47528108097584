<div class="container succession-interview-form">
    <mat-form-field appearance="fill">
        <mat-label>
            Succession Interview By
        </mat-label>

        <mat-select disableOptionCentering [(ngModel)]="successionInterviewBy">
          <mat-option *ngFor="let employee of employees" [value]="employee.id">
            <div class="employee-option">
                <app-employee-image [employeeId]="employee.id" [width]="30" [height]="30" [round]="true"></app-employee-image>
    
                <span class="employee">
                    {{employee.firstname}} {{employee.lastname}}
                </span>
            </div>
          </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Succession Interview Comments</mat-label>

        <textarea rows="15" matInput [(ngModel)]="successionInterviewComments"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Current Position Description</mat-label>

        <textarea rows="15" matInput [(ngModel)]="currentPositionDescription"></textarea>
    </mat-form-field>


    <mat-form-field  appearance="fill">
        <mat-label>
            Succession Interview Date
        </mat-label>

        <input matInput [matDatepicker]="picker" [(ngModel)]="successionInterviewDate">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle  [for]="picker"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
</div>