import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { TableField } from '@app/modules/security-setup/models/table-field.model';

@Component({
  selector: 'app-change-request-approval-dialog',
  templateUrl: './change-request-approval-dialog.component.html',
  styleUrls: ['./change-request-approval-dialog.component.scss']
})
export class ChangeRequestApprovalDialogComponent implements OnInit {
  changeRequest: any;
  formDetails: any;
  loadingFormElements: boolean;
  formElement: any;
  loadingDbTableFields: boolean;
  dbTableFields: TableField[];
  currentCulture: Culture;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslateService,
    private metadataFormService: MetadataFormService,
    private securitySetupService: SecuritySetupService,
    private dialogRef: MatDialogRef<ChangeRequestApprovalDialogComponent>,
    private employeeSecurityService: EmployeeSecurityService,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.changeRequest = data.changeRequest;
  }

  ngOnInit(): void {
    this.getCurrentCulture();
  }
  
  getCurrentCulture() {
    this.employeeSecurityService.getCurrentEmployeeCulture()
    .subscribe(
      res => {
        this.currentCulture = res;
        this.getFormDetails();
      }
    )
  }

  getFormDetails() {
    this.metadataFormService.getForm(this.changeRequest.form)
      .subscribe(
        res => {
          this.formDetails = res;
          this.formDetails.name = this.formDetails.name.filter(formName => formName.culture === this.currentCulture.id);
          this.getFormElements();
        }
      );
  }

  getFormElements() {
    this.loadingFormElements = true;

    this.metadataFormService.getFormElements(this.changeRequest.form, 0, '1000')
      .pipe(
        finalize(() => this.loadingFormElements = false)
      )
      .subscribe(
        res => {
          this.formElement = res.data.find((formElement) => formElement.id === this.changeRequest.formElement);

          // If the form has a table assocciated with it then fetch the table field data to override form element data
          if(this.formDetails.table !== null){
            this.getDbTableFields();
          }
        }
      );
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(this.formDetails.table?.id, 0, '1000')
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
      })
    )
    .subscribe(
      res => {
        this.dbTableFields = res.data;
        this.addDBTableValues();
      }
    );
  }

  addDBTableValues() {
    this.dbTableFields.forEach(
      dbTableField => {
        if(this.formElement.tableField?.id === dbTableField.id){
          this.formElement.text = dbTableField.name;
        }
      }
    )
  }

  close(approved?: boolean) {
    this.dialogRef.close(approved);
  }

}
