import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-succession-employee-details',
  templateUrl: './succession-employee-details.component.html',
  styleUrls: ['./succession-employee-details.component.scss']
})
export class SuccessionEmployeeDetailsComponent implements OnInit {
  @Input() employee;

  manager
  birthday = '1990-03-06'
  hireDate = '2018-08-12'
  roleDate = '2022-05-21'

  constructor() { }

  ngOnInit(): void {
    this.manager = `${this.employee.managers[0]?.firstName} ${this.employee.managers[0]?.lastName}`
  }

}
