import { Component, OnInit } from '@angular/core';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { TreeItem } from '@progress/kendo-angular-treeview';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-pt-calibration-report',
  templateUrl: './pt-calibration-report.component.html',
  styleUrls: ['./pt-calibration-report.component.scss']
})
export class PtCalibrationReportComponent implements OnInit {
  public expandedKeys: any[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
  public checkedKeys: any[] = ["0_1", "0_2"];
  performanceReviewCategories = [
    {
        "text": "Job Knowledge & Skills",
        "items": [
            { "text": "Technical Skills" },
            { "text": "Product/Service Knowledge" },
            { "text": "Problem-Solving Ability" },
            { "text": "Adaptability to New Tools/Technology" }
        ]
    },
    {
        "text": "Quality of Work",
        "items": [
            { "text": "Accuracy" },
            { "text": "Attention to Detail" },
            { "text": "Consistency" },
            { "text": "Creativity/Innovation" }
        ]
    },
    {
        "text": "Productivity & Efficiency",
        "items": [
            { "text": "Timeliness" },
            { "text": "Work Volume" },
            { "text": "Multitasking" },
            { "text": "Resourcefulness" }
        ]
    },
    {
        "text": "Communication",
        "items": [
            { "text": "Verbal Communication" },
            { "text": "Written Communication" },
            { "text": "Listening Skills" },
            { "text": "Interpersonal Skills" }
        ]
    },
    {
        "text": "Teamwork & Collaboration",
        "items": [
            { "text": "Cooperation" },
            { "text": "Respect for Others" },
            { "text": "Conflict Resolution" },
            { "text": "Dependability" }
        ]
    },
    {
        "text": "Initiative & Accountability",
        "items": [
            { "text": "Proactivity" },
            { "text": "Decision-Making" },
            { "text": "Accountability" },
            { "text": "Goal Orientation" }
        ]
    },
    {
        "text": "Customer/Client Focus",
        "items": [
            { "text": "Responsiveness" },
            { "text": "Customer Service" },
            { "text": "Problem Resolution" },
            { "text": "Relationship Building" }
        ]
    },
    {
        "text": "Leadership (If Applicable)",
        "items": [
            { "text": "Motivation of Team" },
            { "text": "Delegation" },
            { "text": "Mentorship" },
            { "text": "Decision-Making" }
        ]
    },
    {
        "text": "Professional Development",
        "items": [
            { "text": "Learning & Growth" },
            { "text": "Openness to Feedback" },
            { "text": "Skill Enhancement" },
            { "text": "Career Progression" }
        ]
    },
    {
        "text": "Overall Performance",
        "items": [
            { "text": "Overall Contribution" },
            { "text": "Strengths" },
            { "text": "Areas for Improvement" }
        ]
    }
  ];

  // barc hart data
  public barChartSeriesData = [];

  // heatmap data
  public heatMapCategories = {
    x: [],
    y: [],
  };
  public heatMapData = [];

  // scatter chart data
  public scatterChartData = [];

  loadingEmployees: boolean;
  employees: any[];
  selectedCategories: string[] = [];

  selectedChart: string = "bar";
  
  constructor(
    private employeeContactCardService: EmployeeContactCardService
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  public onCheckedKeysChange(event): void {
    this.setSelectedCategories();
  }

  getEmployees(): void {
    this.loadingEmployees = true;

    this.employeeContactCardService.getAllEmployeeContactCards(0, '10')
    .pipe(
        finalize(()=>{  })
    )
    .subscribe(
      res => {
        this.employees = res.data;
        this.setSelectedCategories();
      }
    )
  }

  setSelectedCategories(): void {
    this.selectedCategories = [];

    this.checkedKeys.forEach(key => {
      const [categoryIndex, subCategoryIndex] = key.split('_');

      if(subCategoryIndex){
        this.selectedCategories.push(this.performanceReviewCategories[categoryIndex].items[subCategoryIndex].text);
      }
      
    });

    this.resetChartData();
  }
  
  resetChartData(): void {
    this.barChartSeriesData = [];
    
    this.heatMapData = [];
    
    this.scatterChartData = [];
    
    this.createChartData();
  }

  createChartData(): void {
    this.loadingEmployees = true;
    this.loadingEmployees = false;

    this.employees.forEach(employee => {
      let values = [];
      
      this.selectedCategories.forEach(category => {
          values.push({
            [category]: Math.floor(Math.random() * 11)
          })
      });

        // bar chart data
        this.barChartSeriesData.push({
          employee: `${employee.firstname} ${employee.lastname}`,
          ...Object.assign({}, ...values)
        });

        // scatter chart data
        this.scatterChartData.push({
          current: `${employee.firstname} ${employee.lastname}`,
          stats: [
            Object.assign({}, ...values)
          ],
        });

        // heatmap data
        this.heatMapCategories.x.push(`${employee.firstname} ${employee.lastname}`);
        this.selectedCategories.forEach((category, index) => { 
          this.heatMapData.push([`${employee.firstname} ${employee.lastname}`, category, Object.values(values[index])]);
        });
      }
    );

  }

}
