<div class="workflow-details">
    <form class="workflow-category-form" [formGroup]="workflowCategoryDetails">

        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>

            <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>

            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <div class="tasks">
            <div class="tasks-header">
                <h6>{{ 'Tasks' | translate: {Default: "Tasks"} }}</h6>
                
                <div class="k-flex-grow"></div>

                <button type="button" mat-raised-button (click)="addNewTask()"><mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add Task</button>
            </div>

            <div class="task" *ngFor="let task of tasks; let i=index">
                <app-workflow-editor-task [task]="task"></app-workflow-editor-task>
                        
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon" (click)="removeTask(i)">highlight_off</mat-icon>
            </div>
        </div>

        
      
        <!-- <button type="submit" [disabled]="myForm.invalid">Submit</button> -->
      </form>
</div>