import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  @Input() summary: {
    title: string,
    description: string,
    sections: {
      title: string,
      paragraphs: string[],
    }[],
    bulletList: {
      title: string,
      items: string[],
    }
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
