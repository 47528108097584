<div class="task-details">
    <form class="workflow-task-form" [formGroup]="workflowTaskDetails">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>

            <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>

            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>

            <mat-select disableOptionCentering formControlName="type">
                <mat-option value="standard">Standard</mat-option>
                <mat-option value="document">Document</mat-option>
                <mat-option value="form">Form</mat-option>
                <mat-option value="letter">Letter</mat-option>
                <mat-option value="survey">Survey</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="survey-section" *ngIf="workflowTaskDetails.get('type').value === 'survey'">
            <button type="button" color="primary" mat-raised-button (click)="showSelectSurveyModal()"><mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Select Survey</button>
            <h6 class="selected-survey">{{selectedSurvey?.title}}</h6>
        </div>

        <div class="form-section" *ngIf="workflowTaskDetails.get('type').value === 'form'">
            <button type="button" color="primary" mat-raised-button (click)="showSelectFormModal()" [disabled]="loadingForms"><mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Select Form</button>
            <h6 class="selected-form">{{selectedForm?.name}}</h6>
        </div>

        <div class="letter-section" *ngIf="workflowTaskDetails.get('type').value === 'letter'">
            <button type="button" color="primary" mat-raised-button (click)="showSelectLetterModal()" [disabled]="loadingLetters"><mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Select Letter</button>
            <h6 class="selected-form">{{selectedLetter?.name}}</h6>
        </div>
    </form>
</div>