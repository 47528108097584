<h5 mat-dialog-title>Edit</h5>

<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label>
            Name
        </mat-label>
    
        <input matInput type="text" [(ngModel)]="newName">
    </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button class="primary-button submit-button" (click)="save()">Save</button>
</mat-dialog-actions>