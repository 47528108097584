import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engagement-survey',
  templateUrl: './engagement-survey.component.html',
  styleUrls: ['./engagement-survey.component.scss']
})
export class EngagementSurveyComponent implements OnInit {
  surveySettings = {
    title: "Employee Satisfaction Survey",
    description: "Please take a few minutes to complete this survey. Your specific answers will be completely anonymous",
    type: "paged",
    questions: [
      {
        name: "I am satisfied with our firm as an employee",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "I find my work interesting and challenging",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "My salary is appropriate based on my responsibilities and experience",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "I feel my contributions are adequately recognized and rewarded",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "I am treated with trust and respect",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "Management communicates all the information I need to perform my job effectively",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "I recieve an adequate level of training and on-the-job mentoring",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "My salary is appropriate based on my responsibilities and experience",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "I understand how my performance is evaluated",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "I am satisfied with the level of guidance provided by my supervisor",
        description: "",
        type: "radio",
        options: [
          "Strongly Disagree",
          "Disagree",
          "Neither",
          "Agree",
          "Strongly Agree",
        ]
      },
      {
        name: "Any other input you would like to add",
        description: "",
        type: "textarea"
      }
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

}
