import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Integration, Mapping, Route } from '../components/demo-integration-editor/demo-integration.model';

@Injectable({
  providedIn: 'root'
})
export class DemoIntegrationsService {

  integrations: Integration[] = [
    {
      id: '12213123',
      name: 'Zellis',
      description: 'example integration with zellis payroll',
      integration: {
        id: '325345',
        name: 'Zellis'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: {
        "openapi": "3.0.0",
        "info": {
          "title": "Zellis API",
          "description": "API for managing employee records, payroll, and other HR-related tasks.",
          "version": "1.0.0"
        },
        "servers": [
          {
            "url": "https://api.zellis.com/v1"
          }
        ],
        "paths": {
          "/employees": {
            "get": {
              "summary": "Retrieve all employees",
              "description": "Retrieve a list of all employees in the system.",
              "responses": {
                "200": {
                  "description": "A list of employees",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "array",
                        "items": {
                          "$ref": "#/components/schemas/Employee"
                        }
                      }
                    }
                  }
                }
              }
            },
            "post": {
              "summary": "Create a new employee record",
              "description": "Create a new employee record in the system.",
              "requestBody": {
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "$ref": "#/components/schemas/Employee"
                    }
                  }
                }
              },
              "responses": {
                "201": {
                  "description": "Employee created successfully"
                }
              }
            }
          },
          "/employees/{employeeId}": {
            "get": {
              "summary": "Retrieve employee details",
              "description": "Retrieve the details of a specific employee by ID.",
              "parameters": [
                {
                  "name": "employeeId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "string"
                  }
                }
              ],
              "responses": {
                "200": {
                  "description": "Employee details",
                  "content": {
                    "application/json": {
                      "schema": {
                        "$ref": "#/components/schemas/Employee"
                      }
                    }
                  }
                }
              }
            },
            "put": {
              "summary": "Update employee details",
              "description": "Update an existing employee's details.",
              "parameters": [
                {
                  "name": "employeeId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "string"
                  }
                }
              ],
              "requestBody": {
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "$ref": "#/components/schemas/Employee"
                    }
                  }
                }
              },
              "responses": {
                "200": {
                  "description": "Employee updated successfully"
                }
              }
            },
            "delete": {
              "summary": "Delete employee record",
              "description": "Delete an employee record by ID.",
              "parameters": [
                {
                  "name": "employeeId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "string"
                  }
                }
              ],
              "responses": {
                "204": {
                  "description": "Employee deleted successfully"
                }
              }
            }
          },
          "/payroll": {
            "get": {
              "summary": "Retrieve payroll details",
              "description": "Retrieve payroll details for employees.",
              "responses": {
                "200": {
                  "description": "Payroll details",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "array",
                        "items": {
                          "$ref": "#/components/schemas/Payroll"
                        }
                      }
                    }
                  }
                }
              }
            },
            "post": {
              "summary": "Process payroll",
              "description": "Submit payroll details for processing.",
              "requestBody": {
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "$ref": "#/components/schemas/Payroll"
                    }
                  }
                }
              },
              "responses": {
                "201": {
                  "description": "Payroll processed successfully"
                }
              }
            }
          }
        },
        "components": {
          "schemas": {
            "Employee": {
              "type": "object",
              "properties": {
                "EmployeeID": {
                  "type": "string",
                  "description": "Unique identifier for the employee"
                },
                "EmployeeName": {
                  "type": "string",
                  "description": "Full name of the employee"
                },
                "Department": {
                  "type": "string",
                  "description": "Department the employee works in"
                },
                "Location": {
                  "type": "string",
                  "description": "Location of the employee"
                },
                "Salary": {
                  "type": "number",
                  "description": "Employee's salary"
                }
              },
              "required": ["EmployeeID", "EmployeeName", "Department"]
            },
            "Payroll": {
              "type": "object",
              "properties": {
                "EmployeeID": {
                  "type": "string",
                  "description": "Unique identifier for the employee"
                },
                "PayPeriod": {
                  "type": "string",
                  "description": "The pay period for the payroll (e.g., '2024-09')"
                },
                "Salary": {
                  "type": "number",
                  "description": "Salary processed for the pay period"
                }
              },
              "required": ["EmployeeID", "PayPeriod", "Salary"]
            }
          }
        }
      },
      mappingId: '48912zellisAPI7987'
    },
    {
      id: '65784543',
      name: 'iCIMS',
      description: 'An iCIMS integration connects the iCIMS talent acquisition platform with other systems (e.g., HRIS, ATS, or payroll) to streamline recruiting, hiring, and onboarding processes by automating data transfer, enhancing workflow efficiency, and improving communication between tools. ',
      integration: {
        id: '65784543',
        name: 'iCIMS'
      },
      frequency: 'Monthly',
      startDate: '2023-06-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.icims.com/v1',
        authurl: 'https://auth.icims.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: {
        "openapi": "3.0.0",
        "info": {
          "title": "iCIMS Job and Candidate API",
          "description": "This API allows users to manage job postings and candidate data in the iCIMS platform.",
          "version": "1.1.0"
        },
        "servers": [
          {
            "url": "https://api.icims.com/v2",
            "description": "Production server"
          },
          {
            "url": "https://sandbox.api.icims.com/v2",
            "description": "Sandbox server for testing"
          }
        ],
        "paths": {
          "/jobs/search": {
            "get": {
              "summary": "Search for job postings",
              "description": "Retrieve job postings based on search criteria.",
              "operationId": "searchJobs",
              "tags": ["Jobs"],
              "parameters": [
                {
                  "name": "searchTerms",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "string"
                  },
                  "description": "The search terms for the job search."
                },
                {
                  "name": "location",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "string"
                  },
                  "description": "The location to filter the jobs by."
                },
                {
                  "name": "category",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "string"
                  },
                  "description": "The job category to filter the results by."
                },
                {
                  "name": "page",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "The page number for pagination."
                },
                {
                  "name": "pageSize",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "integer",
                    "default": 25
                  },
                  "description": "The number of results per page (max 100)."
                }
              ],
              "responses": {
                "200": {
                  "description": "A list of job postings matching the search criteria.",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "object",
                        "properties": {
                          "jobs": {
                            "type": "array",
                            "items": {
                              "type": "object",
                              "properties": {
                                "jobId": {
                                  "type": "integer",
                                  "description": "Unique ID for the job posting."
                                },
                                "title": {
                                  "type": "string",
                                  "description": "The title of the job."
                                },
                                "location": {
                                  "type": "string",
                                  "description": "The location of the job."
                                },
                                "category": {
                                  "type": "string",
                                  "description": "The job category."
                                },
                                "description": {
                                  "type": "string",
                                  "description": "A description of the job posting."
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "400": {
                  "description": "Invalid search parameters provided."
                },
                "500": {
                  "description": "An error occurred while searching for jobs."
                }
              }
            }
          },
          "/jobs/{jobId}": {
            "get": {
              "summary": "Get job details",
              "description": "Retrieve details of a specific job posting by ID.",
              "operationId": "getJobById",
              "tags": ["Jobs"],
              "parameters": [
                {
                  "name": "jobId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "Unique ID of the job."
                }
              ],
              "responses": {
                "200": {
                  "description": "Job details retrieved successfully.",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "object",
                        "properties": {
                          "jobId": {
                            "type": "integer",
                            "description": "Unique ID for the job posting."
                          },
                          "title": {
                            "type": "string",
                            "description": "The title of the job."
                          },
                          "location": {
                            "type": "string",
                            "description": "The location of the job."
                          },
                          "category": {
                            "type": "string",
                            "description": "The job category."
                          },
                          "description": {
                            "type": "string",
                            "description": "A description of the job posting."
                          },
                          "requirements": {
                            "type": "string",
                            "description": "The job requirements."
                          },
                          "salaryRange": {
                            "type": "string",
                            "description": "The salary range for the job."
                          }
                        }
                      }
                    }
                  }
                },
                "404": {
                  "description": "Job not found."
                },
                "500": {
                  "description": "An error occurred while retrieving the job details."
                }
              }
            },
            "put": {
              "summary": "Update a job posting",
              "description": "Update an existing job posting by ID.",
              "operationId": "updateJob",
              "tags": ["Jobs"],
              "parameters": [
                {
                  "name": "jobId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "Unique ID of the job to update."
                }
              ],
              "requestBody": {
                "description": "The updated job details.",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "title": {
                          "type": "string",
                          "description": "The title of the job."
                        },
                        "location": {
                          "type": "string",
                          "description": "The location of the job."
                        },
                        "category": {
                          "type": "string",
                          "description": "The job category."
                        },
                        "description": {
                          "type": "string",
                          "description": "A description of the job posting."
                        },
                        "requirements": {
                          "type": "string",
                          "description": "The job requirements."
                        },
                        "salaryRange": {
                          "type": "string",
                          "description": "The salary range for the job."
                        }
                      }
                    }
                  }
                }
              },
              "responses": {
                "200": {
                  "description": "Job updated successfully."
                },
                "404": {
                  "description": "Job not found."
                },
                "400": {
                  "description": "Invalid job details provided."
                },
                "500": {
                  "description": "An error occurred while updating the job."
                }
              }
            },
            "delete": {
              "summary": "Delete a job posting",
              "description": "Delete a specific job posting by ID.",
              "operationId": "deleteJob",
              "tags": ["Jobs"],
              "parameters": [
                {
                  "name": "jobId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "Unique ID of the job to delete."
                }
              ],
              "responses": {
                "204": {
                  "description": "Job deleted successfully."
                },
                "404": {
                  "description": "Job not found."
                },
                "500": {
                  "description": "An error occurred while deleting the job."
                }
              }
            }
          },
          "/jobs": {
            "post": {
              "summary": "Create a new job posting",
              "description": "Create a new job posting in the system.",
              "operationId": "createJob",
              "tags": ["Jobs"],
              "requestBody": {
                "description": "The job details to create a new posting.",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "title": {
                          "type": "string",
                          "description": "The title of the job.",
                          "example": "Software Engineer"
                        },
                        "location": {
                          "type": "string",
                          "description": "The location of the job.",
                          "example": "New York, NY"
                        },
                        "category": {
                          "type": "string",
                          "description": "The job category.",
                          "example": "Engineering"
                        },
                        "description": {
                          "type": "string",
                          "description": "A description of the job posting.",
                          "example": "We are looking for a talented software engineer."
                        },
                        "requirements": {
                          "type": "string",
                          "description": "The job requirements.",
                          "example": "5+ years of experience in software development."
                        },
                        "salaryRange": {
                          "type": "string",
                          "description": "The salary range for the job.",
                          "example": "$80,000 - $120,000"
                        }
                      }
                    }
                  }
                }
              },
              "responses": {
                "201": {
                  "description": "Job created successfully.",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "object",
                        "properties": {
                          "jobId": {
                            "type": "integer",
                            "description": "Unique ID of the created job.",
                            "example": 12345
                          },
                          "title": {
                            "type": "string",
                            "description": "The title of the job.",
                            "example": "Software Engineer"
                          },
                          "location": {
                            "type": "string",
                            "description": "The location of the job.",
                            "example": "New York, NY"
                          },
                          "category": {
                            "type": "string",
                            "description": "The job category.",
                            "example": "Engineering"
                          }
                        }
                      }
                    }
                  }
                },
                "400": {
                  "description": "Invalid job details provided."
                },
                "500": {
                  "description": "An error occurred while creating the job."
                }
              }
            }
          },
          "/candidates": {
            "get": {
              "summary": "Retrieve all candidates",
              "description": "Retrieve a list of all candidates in the system.",
              "operationId": "getAllCandidates",
              "tags": ["Candidates"],
              "parameters": [
                {
                  "name": "page",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "The page number for pagination."
                },
                {
                  "name": "pageSize",
                  "in": "query",
                  "required": false,
                  "schema": {
                    "type": "integer",
                    "default": 25
                  },
                  "description": "The number of results per page."
                }
              ],
              "responses": {
                "200": {
                  "description": "A list of candidates retrieved successfully.",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "object",
                        "properties": {
                          "candidates": {
                            "type": "array",
                            "items": {
                              "type": "object",
                              "properties": {
                                "candidateId": {
                                  "type": "integer",
                                  "description": "Unique ID for the candidate."
                                },
                                "firstName": {
                                  "type": "string",
                                  "description": "First name of the candidate."
                                },
                                "lastName": {
                                  "type": "string",
                                  "description": "Last name of the candidate."
                                },
                                "email": {
                                  "type": "string",
                                  "description": "Email address of the candidate."
                                },
                                "resume": {
                                  "type": "string",
                                  "description": "URL to the candidate's resume."
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "500": {
                  "description": "An error occurred while retrieving candidates."
                }
              }
            },
            "post": {
              "summary": "Create a new candidate",
              "description": "Add a new candidate to the system.",
              "operationId": "createCandidate",
              "tags": ["Candidates"],
              "requestBody": {
                "description": "The candidate details to create a new record.",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "firstName": {
                          "type": "string",
                          "description": "First name of the candidate.",
                          "example": "John"
                        },
                        "lastName": {
                          "type": "string",
                          "description": "Last name of the candidate.",
                          "example": "Doe"
                        },
                        "email": {
                          "type": "string",
                          "description": "Email address of the candidate.",
                          "example": "john.doe@example.com"
                        },
                        "resume": {
                          "type": "string",
                          "description": "URL to the candidate's resume.",
                          "example": "https://example.com/resume/johndoe"
                        }
                      }
                    }
                  }
                }
              },
              "responses": {
                "201": {
                  "description": "Candidate created successfully.",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "object",
                        "properties": {
                          "candidateId": {
                            "type": "integer",
                            "description": "Unique ID of the created candidate.",
                            "example": 123
                          },
                          "firstName": {
                            "type": "string",
                            "description": "First name of the candidate.",
                            "example": "John"
                          },
                          "lastName": {
                            "type": "string",
                            "description": "Last name of the candidate.",
                            "example": "Doe"
                          },
                          "email": {
                            "type": "string",
                            "description": "Email address of the candidate.",
                            "example": "john.doe@example.com"
                          }
                        }
                      }
                    }
                  }
                },
                "400": {
                  "description": "Invalid candidate details provided."
                },
                "500": {
                  "description": "An error occurred while creating the candidate."
                }
              }
            }
          },
          "/candidates/{candidateId}": {
            "get": {
              "summary": "Get candidate details",
              "description": "Retrieve details of a specific candidate by ID.",
              "operationId": "getCandidateById",
              "tags": ["Candidates"],
              "parameters": [
                {
                  "name": "candidateId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "Unique ID of the candidate."
                }
              ],
              "responses": {
                "200": {
                  "description": "Candidate details retrieved successfully.",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "object",
                        "properties": {
                          "candidateId": {
                            "type": "integer",
                            "description": "Unique ID for the candidate."
                          },
                          "firstName": {
                            "type": "string",
                            "description": "First name of the candidate."
                          },
                          "lastName": {
                            "type": "string",
                            "description": "Last name of the candidate."
                          },
                          "email": {
                            "type": "string",
                            "description": "Email address of the candidate."
                          },
                          "resume": {
                            "type": "string",
                            "description": "URL to the candidate's resume."
                          }
                        }
                      }
                    }
                  }
                },
                "404": {
                  "description": "Candidate not found."
                },
                "500": {
                  "description": "An error occurred while retrieving candidate details."
                }
              }
            },
            "put": {
              "summary": "Update a candidate record",
              "description": "Update an existing candidate's details by ID.",
              "operationId": "updateCandidate",
              "tags": ["Candidates"],
              "parameters": [
                {
                  "name": "candidateId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "Unique ID of the candidate to update."
                }
              ],
              "requestBody": {
                "description": "The updated candidate details.",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "type": "object",
                      "properties": {
                        "firstName": {
                          "type": "string",
                          "description": "First name of the candidate."
                        },
                        "lastName": {
                          "type": "string",
                          "description": "Last name of the candidate."
                        },
                        "email": {
                          "type": "string",
                          "description": "Email address of the candidate."
                        },
                        "resume": {
                          "type": "string",
                          "description": "URL to the candidate's resume."
                        }
                      }
                    }
                  }
                }
              },
              "responses": {
                "200": {
                  "description": "Candidate updated successfully."
                },
                "404": {
                  "description": "Candidate not found."
                },
                "400": {
                  "description": "Invalid candidate details provided."
                },
                "500": {
                  "description": "An error occurred while updating the candidate."
                }
              }
            },
            "delete": {
              "summary": "Delete a candidate",
              "description": "Delete a specific candidate by ID.",
              "operationId": "deleteCandidate",
              "tags": ["Candidates"],
              "parameters": [
                {
                  "name": "candidateId",
                  "in": "path",
                  "required": true,
                  "schema": {
                    "type": "integer"
                  },
                  "description": "Unique ID of the candidate to delete."
                }
              ],
              "responses": {
                "204": {
                  "description": "Candidate deleted successfully."
                },
                "404": {
                  "description": "Candidate not found."
                },
                "500": {
                  "description": "An error occurred while deleting the candidate."
                }
              }
            }
          }
        },
        "components": {
          "securitySchemes": {
            "BearerAuth": {
              "type": "http",
              "scheme": "bearer",
              "bearerFormat": "JWT"
            }
          }
        },
        "security": [
          {
            "BearerAuth": []
          }
        ]
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: '4374575234235',
      name: 'ADP',
      description: "An ADP integration links ADP's payroll and HR services with other systems to automate data synchronization, streamline HR processes, and ensure seamless management of employee information.",
      integration: {
        id: '45748764',
        name: 'ADP'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.adp.com/v1',
        authurl: 'https://auth.adp.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: {
        "openapi": "3.0.0",
        "info": {
          "title": "ADP Payroll API",
          "description": "API to manage payroll operations such as creating, updating, and fetching payroll data.",
          "version": "1.0.0"
        },
        "servers": [
          {
            "url": "https://api.adp.com/payroll/v1",
            "description": "ADP Payroll API Base URL"
          }
        ],
        "paths": {
          "/employees": {
            "get": {
              "summary": "Retrieve list of employees",
              "description": "Fetch a list of all employees with payroll information.",
              "responses": {
                "200": {
                  "description": "A list of employees",
                  "content": {
                    "application/json": {
                      "schema": {
                        "type": "array",
                        "items": {
                          "$ref": "#/components/schemas/Employee"
                        }
                      }
                    }
                  }
                },
                "401": {
                  "description": "Unauthorized request"
                },
                "500": {
                  "description": "Internal server error"
                }
              }
            },
            "post": {
              "summary": "Add a new employee",
              "description": "Add a new employee to the payroll system.",
              "requestBody": {
                "description": "Employee object that needs to be added to the system",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "$ref": "#/components/schemas/Employee"
                    }
                  }
                }
              },
              "responses": {
                "201": {
                  "description": "Employee successfully created"
                },
                "400": {
                  "description": "Invalid input"
                },
                "500": {
                  "description": "Internal server error"
                }
              }
            }
          },
          "/employees/{employeeId}": {
            "get": {
              "summary": "Get employee by ID",
              "description": "Fetch payroll details for a specific employee.",
              "parameters": [
                {
                  "in": "path",
                  "name": "employeeId",
                  "required": true,
                  "schema": {
                    "type": "string"
                  },
                  "description": "ID of the employee"
                }
              ],
              "responses": {
                "200": {
                  "description": "Employee details",
                  "content": {
                    "application/json": {
                      "schema": {
                        "$ref": "#/components/schemas/Employee"
                      }
                    }
                  }
                },
                "404": {
                  "description": "Employee not found"
                },
                "500": {
                  "description": "Internal server error"
                }
              }
            },
            "put": {
              "summary": "Update employee payroll information",
              "description": "Update the payroll details for an employee.",
              "parameters": [
                {
                  "in": "path",
                  "name": "employeeId",
                  "required": true,
                  "schema": {
                    "type": "string"
                  },
                  "description": "ID of the employee to update"
                }
              ],
              "requestBody": {
                "description": "Updated employee payroll information",
                "required": true,
                "content": {
                  "application/json": {
                    "schema": {
                      "$ref": "#/components/schemas/Employee"
                    }
                  }
                }
              },
              "responses": {
                "200": {
                  "description": "Employee payroll updated successfully"
                },
                "400": {
                  "description": "Invalid input"
                },
                "404": {
                  "description": "Employee not found"
                },
                "500": {
                  "description": "Internal server error"
                }
              }
            }
          },
          "/payrolls": {
            "get": {
              "summary": "Get payroll summary",
              "description": "Fetch a summary of the payroll for all employees.",
              "responses": {
                "200": {
                  "description": "Payroll summary",
                  "content": {
                    "application/json": {
                      "schema": {
                        "$ref": "#/components/schemas/PayrollSummary"
                      }
                    }
                  }
                },
                "401": {
                  "description": "Unauthorized request"
                },
                "500": {
                  "description": "Internal server error"
                }
              }
            }
          },
          "/payrolls/{payrollId}": {
            "get": {
              "summary": "Get payroll by ID",
              "description": "Fetch details of a specific payroll run.",
              "parameters": [
                {
                  "in": "path",
                  "name": "payrollId",
                  "required": true,
                  "schema": {
                    "type": "string"
                  },
                  "description": "ID of the payroll run"
                }
              ],
              "responses": {
                "200": {
                  "description": "Payroll details",
                  "content": {
                    "application/json": {
                      "schema": {
                        "$ref": "#/components/schemas/PayrollDetails"
                      }
                    }
                  }
                },
                "404": {
                  "description": "Payroll not found"
                },
                "500": {
                  "description": "Internal server error"
                }
              }
            }
          }
        },
        "components": {
          "schemas": {
            "Employee": {
              "type": "object",
              "properties": {
                "id": {
                  "type": "string",
                  "description": "Unique identifier for the employee"
                },
                "name": {
                  "type": "string"
                },
                "salary": {
                  "type": "number"
                },
                "hireDate": {
                  "type": "string",
                  "format": "date"
                },
                "department": {
                  "type": "string"
                }
              }
            },
            "PayrollSummary": {
              "type": "object",
              "properties": {
                "totalEmployees": {
                  "type": "integer"
                },
                "totalPayroll": {
                  "type": "number"
                },
                "payrollDate": {
                  "type": "string",
                  "format": "date"
                }
              }
            },
            "PayrollDetails": {
              "type": "object",
              "properties": {
                "payrollId": {
                  "type": "string",
                  "description": "Unique ID for the payroll run"
                },
                "employees": {
                  "type": "array",
                  "items": {
                    "$ref": "#/components/schemas/Employee"
                  }
                },
                "totalAmount": {
                  "type": "number"
                },
                "payrollDate": {
                  "type": "string",
                  "format": "date"
                }
              }
            }
          }
        }
      }
      ,
      mappingId: 'adp12345api'
    },
    {
      id: '435456765',
      name: 'Docebo',
      description: "A Docebo integration connects the Docebo learning management system with other platforms to automate training processes, synchronize learner data, and enhance the overall learning experience across systems.",
      integration: {
        id: '123123',
        name: 'Docebo'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: '45tgefg',
      name: 'BOSS',
      description: 'example integration with BOSS sales system',
      integration: {
        id: 'f43rerf',
        name: 'BOSS'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'v45fgh45y',
      name: 'PIPA',
      description: 'example integration with PIPA fleet system',
      integration: {
        id: 'f43rerf',
        name: 'BOSS'
      },
      frequency: 'Weekly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_mwUJf9lRKWbNPg", firstName: "Esme", lastName: "Biship"},
      createdOn: '2023-01-05T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'f345g',
      name: 'White Pages',
      description: 'example integration with White Pages directory system',
      integration: {
        id: 'drg345tgf',
        name: 'White Pages'
      },
      frequency: 'Daily',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_oQiQUCIWV3S8fP", firstName: "Francesca", lastName: "Campos"},
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'g45grdfxz',
      name: 'Genesys',
      description: 'example integration with Genesys telephony system',
      integration: {
        id: 'drg345tgf',
        name: 'Genesys'
      },
      frequency: 'Daily',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'b54gtrg',
      name: 'OpenBlend',
      description: 'example integration with OpenBlend coaching system',
      integration: {
        id: 'bfgb345gr',
        name: 'OpenBlend'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_uLtt6NpkTOTWDZ", firstName: "Ben", lastName: "Buckton"},
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'c432tdfgdf',
      name: 'SAP Litmos',
      description: 'example integration with SAP Litmos coaching system',
      integration: {
        id: 'c324trvfg',
        name: 'SAP Litmos'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'v45ytfdh',
      name: ' Azure AD',
      description: 'example integration with  Azure Active Directory',
      integration: {
        id: 'fr234terg',
        name: ' Azure AD'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_QbBuVt9SLK8fSI", firstName: "Kone", lastName: "Bouba"},
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      docsUrl: 'https://apidocs.zellis.com/',
      mappingId: '32714rny890f7yn'
    },
  ]

  mappings: Mapping[] = [
    {
      id: '32714rny890f7yn',
      name: 'Zellis Mapping',
      description: '',
      categories: [
        {
          id: 'Workers',
          name: 'Workers',
          description: 'Worker REST API',
          selected: false,
          routes: [
            {
              type: 'put',
              route: 'Workers',
              summary: 'Create or update Worker',
              description: 'Creating a new or updating an existing Worker record. Use of this method will wholly replace the existing record with a new one. Therefore empty/null fields will overwrite existing populated items.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
              selected: true,
              fields: [
                {
                  text: 'WorkerNumber',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_EmployeeId'
                  }
                },
                {
                  text: 'KnownAs',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_PreferredName'
                  }
                },
                {
                  text: 'Surname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_LastName'
                  }
                },
                {
                  text: 'PreviousSurname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_MaidenName'
                  }
                },
                {
                  text: 'BirthDate',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_Birthday'
                  }
                }
              ]
            },
            {
              type: 'post',
              route: 'Workers',
              summary: 'Create Worker',
              description: 'Creating a new Worker record. Use this Method where Worker does not yet exist in ResourceLink Application.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
              selected: true,
              fields: [
                {
                  text: 'WorkerNumber',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_EmployeeId'
                  }
                },
                {
                  text: 'KnownAs',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_PreferredName'
                  }
                },
                {
                  text: 'Surname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_LastName'
                  }
                },
                {
                  text: 'PreviousSurname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_MaidenName'
                  }
                },
                {
                  text: 'BirthDate',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_Birthday'
                  }
                }
              ]
            },
            {
              type: 'patch',
              route: 'Workers',
              summary: 'Partially update Worker',
              description: 'Updating an existing Worker record with selected data values. Use of this method will replace only the data items provided. Any not specified will be left as is.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
              selected: false,
              fields: [
                {
                  text: 'WorkerNumber',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_EmployeeId'
                  }
                },
                {
                  text: 'KnownAs',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_PreferredName'
                  }
                },
                {
                  text: 'Surname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_LastName'
                  }
                },
                {
                  text: 'PreviousSurname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_MaidenName'
                  }
                },
                {
                  text: 'BirthDate',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_Birthday'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]

  yamlMappings: any[] = [
    {
      "id": "32714rny890f7yn",
      "name": "iCIMS",
      "paths": {
        "/jobs/search": {
          "get": {
            "summary": "Search for job postings",
            "description": "Retrieve job postings based on search criteria.",
            "fields": [
              {
                "text": "JobTitle",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_JobTitle"
                }
              },
              {
                "text": "Department",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Department"
                }
              },
              {
                "text": "Location",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Location"
                }
              }
            ]
          }
        },
        "/jobs/{jobId}": {
          "get": {
            "summary": "Get job details",
            "description": "Retrieve details of a specific job posting by ID.",
            "fields": [
              {
                "text": "JobTitle",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_JobTitle"
                }
              },
              {
                "text": "Department",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Department"
                }
              },
              {
                "text": "Location",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Location"
                }
              }
            ]
          },
          "put": {
            "summary": "Update a job posting",
            "description": "Update an existing job posting by ID.",
            "fields": [
              {
                "text": "JobTitle",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_JobTitle"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Salary"
                }
              }
            ]
          },
          "delete": {
            "summary": "Delete a job posting",
            "description": "Delete a specific job posting by ID."
          }
        },
        "/jobs": {
          "post": {
            "summary": "Create a new job posting",
            "description": "Create a new job posting in the system.",
            "fields": [
              {
                "text": "JobTitle",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_JobTitle"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Salary"
                }
              },
              {
                "text": "Description",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Jobs",
                  "field": "tfi_Description"
                }
              }
            ]
          }
        },
        "/candidates": {
          "get": {
            "summary": "Retrieve all candidates",
            "description": "Retrieve a list of all candidates in the system.",
            "fields": [
              {
                "text": "CandidateName",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Candidates",
                  "field": "tfi_CandidateName"
                }
              },
              {
                "text": "Email",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Candidates",
                  "field": "tfi_Email"
                }
              }
            ]
          },
          "post": {
            "summary": "Create a new candidate",
            "description": "Add a new candidate to the system.",
            "fields": [
              {
                "text": "CandidateName",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Candidates",
                  "field": "tfi_CandidateName"
                }
              },
              {
                "text": "Email",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Candidates",
                  "field": "tfi_Email"
                }
              },
              {
                "text": "Resume",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Candidates",
                  "field": "tfi_Resume"
                }
              }
            ]
          }
        }
      }
    },
    {
      "id": "48912zellisAPI7987",
      "name": "Zellis",
      "paths": {
        "/employees/search": {
          "get": {
            "summary": "Search for employee records",
            "description": "Retrieve employee records based on search criteria.",
            "fields": [
              {
                "text": "EmployeeName",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_EmployeeName"
                }
              },
              {
                "text": "Department",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Department"
                }
              },
              {
                "text": "Location",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Location"
                }
              }
            ]
          }
        },
        "/employees/{employeeId}": {
          "get": {
            "summary": "Get employee details",
            "description": "Retrieve details of a specific employee by ID.",
            "fields": [
              {
                "text": "EmployeeName",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_EmployeeName"
                }
              },
              {
                "text": "Department",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Department"
                }
              },
              {
                "text": "Location",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Location"
                }
              }
            ]
          },
          "put": {
            "summary": "Update employee record",
            "description": "Update an existing employee record by ID.",
            "fields": [
              {
                "text": "EmployeeName",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_EmployeeName"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Salary"
                }
              }
            ]
          },
          "delete": {
            "summary": "Delete employee record",
            "description": "Delete a specific employee record by ID."
          }
        },
        "/employees": {
          "post": {
            "summary": "Create a new employee record",
            "description": "Create a new employee record in the system.",
            "fields": [
              {
                "text": "EmployeeName",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_EmployeeName"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Salary"
                }
              },
              {
                "text": "Department",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "tfi_Department"
                }
              }
            ]
          }
        },
        "/payroll": {
          "get": {
            "summary": "Retrieve payroll details",
            "description": "Retrieve payroll details for employees.",
            "fields": [
              {
                "text": "EmployeeID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payroll",
                  "field": "tfi_EmployeeID"
                }
              },
              {
                "text": "PayPeriod",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payroll",
                  "field": "tfi_PayPeriod"
                }
              }
            ]
          },
          "post": {
            "summary": "Process payroll",
            "description": "Submit payroll details for processing.",
            "fields": [
              {
                "text": "EmployeeID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payroll",
                  "field": "tfi_EmployeeID"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Payroll",
                  "field": "tfi_Salary"
                }
              },
              {
                "text": "PayPeriod",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payroll",
                  "field": "tfi_PayPeriod"
                }
              }
            ]
          }
        }
      }
    },
    {
      "id": "adp12345api",
      "name": "ADP Payroll API",
      "paths": {
        "/employees": {
          "get": {
            "summary": "Retrieve all employees",
            "description": "Fetch a list of all employees with payroll information.",
            "fields": [
              {
                "text": "EmployeeID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_ID"
                }
              },
              {
                "text": "Name",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Name"
                }
              },
              {
                "text": "Department",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Department"
                }
              }
            ]
          },
          "post": {
            "summary": "Add a new employee",
            "description": "Create a new employee record in the payroll system.",
            "fields": [
              {
                "text": "EmployeeID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_ID"
                }
              },
              {
                "text": "Name",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Name"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Salary"
                }
              }
            ]
          }
        },
        "/employees/{employeeId}": {
          "get": {
            "summary": "Retrieve employee details",
            "description": "Fetch details of a specific employee by ID.",
            "fields": [
              {
                "text": "EmployeeID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_ID"
                }
              },
              {
                "text": "Name",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Name"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Salary"
                }
              }
            ]
          },
          "put": {
            "summary": "Update employee details",
            "description": "Update details of an existing employee by ID.",
            "fields": [
              {
                "text": "Name",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Name"
                }
              },
              {
                "text": "Salary",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Employees",
                  "field": "emp_Salary"
                }
              }
            ]
          },
          "delete": {
            "summary": "Delete employee",
            "description": "Delete a specific employee record by ID."
          }
        },
        "/payrolls": {
          "get": {
            "summary": "Retrieve payroll summary",
            "description": "Fetch a summary of payroll data for all employees.",
            "fields": [
              {
                "text": "PayrollID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_ID"
                }
              },
              {
                "text": "TotalAmount",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_TotalAmount"
                }
              },
              {
                "text": "PayrollDate",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_Date"
                }
              }
            ]
          }
        },
        "/payrolls/{payrollId}": {
          "get": {
            "summary": "Retrieve payroll details",
            "description": "Fetch details of a specific payroll by ID.",
            "fields": [
              {
                "text": "PayrollID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_ID"
                }
              },
              {
                "text": "EmployeeList",
                "valueType": "array",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_Employees"
                }
              }
            ]
          }
        },
        "/payroll": {
          "post": {
            "summary": "Create a new payroll",
            "description": "Create a new payroll entry for all employees.",
            "fields": [
              {
                "text": "PayrollID",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_ID"
                }
              },
              {
                "text": "TotalAmount",
                "valueType": "number",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_TotalAmount"
                }
              },
              {
                "text": "PayrollDate",
                "valueType": "string",
                "mappedTo": {
                  "table": "tbl_Payrolls",
                  "field": "payroll_Date"
                }
              }
            ]
          }
        }
      }
    }
    
    
  ]
  

  constructor() { }

  getIntegrations(): Observable<Integration[]> {
    return of(this.integrations)
  }

  getIntegration(id: string): Observable<Integration | undefined> {
    return of(this.integrations.find(obj => obj.id === id))
  }

  getMappings(): Observable<Mapping[]> {
    return of(this.mappings)
  }

  getMapping(id: string): Observable<Mapping | undefined> {
    return of(this.mappings.find(obj => obj.id === id))
  }

  getYamlMapping(id: string): Observable<any[]> {
    return of(this.yamlMappings.find(obj => obj.id === id));
  }
}
