import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-announcements-widget',
  templateUrl: './announcements-widget.component.html',
  styleUrls: ['./announcements-widget.component.scss']
})
export class AnnouncementsWidgetComponent implements OnInit {
  items = [
    {
      img: 'assets/images/announcements/diverse1.jpg',
      title: 'Welcome back to the office',
      description: `We're excited to see everyone back in the office! Lets make this transition as smooth as possible. Remember, we're all in this together.`,
    },
    {
      img: 'assets/images/announcements/diverse5.jpg',
      title: 'Employee engagement is key',
      description: 'Enaged employees are more productive and happier. Lets work together to make sure everyone is engaged and happy.',
    },
    {
      img: 'assets/images/announcements/diverse3.jpg',
      title: `Let's keep communication open`,
      description: 'communication is to a happy and healthy workplace. Have an idea for how we can improve? We want to hear from you!',
    },
    {
      img: 'assets/images/announcements/diverse4.jpg',
      title: `All hands on deck for Q${moment().quarter()}`,
      description: `We're gearing up for Q${moment().quarter()}! Lets make it the best quarter yet. We've got some exciting projects in the works, and your hard work is what will make them successful.`,
    },
  ]
  loading: boolean = true;

  constructor() { 
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  ngOnInit(): void {
  }

}
