import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-matrix-list-dialog',
  templateUrl: './matrix-list-dialog.component.html',
  styleUrls: ['./matrix-list-dialog.component.scss']
})
export class MatrixListDialogComponent implements OnInit {
  formValid = true;
  employee: EmployeeContactCard;
  potential: string;
  performance: string;
  title: string;

  constructor(
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<MatrixListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ){
    this.employee = data.employee;
    this.potential = data.potential;
    this.performance = data.performance;
    this.title = data.title;
  }

  ngOnInit(): void {
    // console.log(this.employee)
    // console.log(this.potential)
    // console.log(this.performance)
  }

  save(){
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      this.dialogRef.close(
        {
          employee: this.employee,
          potential: this.potential,
          performance: this.performance,
          title: this.title,
        }
      );
    }, 500)
  }

  close() {
    this.dialogRef.close();
  }

}
