<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Gender Pay Quartiles</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">

        <h6>Headcount</h6>
        <kendo-grid [data]="headcountData">

            <kendo-grid-column field="quarter" title="quarter" [width]="50"></kendo-grid-column>

            <kendo-grid-column-group title="Employees" [columnMenu]="false">
                <kendo-grid-column field="employeeMale" title="M" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="employeeFemale" title="F" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="employeeDiff" title="Diff" [width]="50"> </kendo-grid-column>
            </kendo-grid-column-group>

            <kendo-grid-column-group title="Members" [columnMenu]="false">
                <kendo-grid-column field="memberMale" title="M" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="memberFemale" title="F" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="memberDiff" title="Diff" [width]="50"> </kendo-grid-column>
            </kendo-grid-column-group>

            <kendo-grid-column-group title="Our People" [columnMenu]="false">
                <kendo-grid-column field="peopleMale" title="M" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="peopleFemale" title="F" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="peopleDiff" title="Diff" [width]="50"> </kendo-grid-column>
            </kendo-grid-column-group>

        </kendo-grid>

        <h6>Hourly Salary</h6>
        <kendo-grid [data]="hourlySalaryData">

            <kendo-grid-column field="quarter" title="quarter" [width]="50"></kendo-grid-column>

            <kendo-grid-column-group title="Employees" [columnMenu]="false">
                <kendo-grid-column field="employeeMale" title="M" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="employeeFemale" title="F" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="employeeDiff" title="Diff" [width]="50"> </kendo-grid-column>
            </kendo-grid-column-group>

            <kendo-grid-column-group title="Members" [columnMenu]="false">
                <kendo-grid-column field="memberMale" title="M" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="memberFemale" title="F" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="memberDiff" title="Diff" [width]="50"> </kendo-grid-column>
            </kendo-grid-column-group>

            <kendo-grid-column-group title="Our People" [columnMenu]="false">
                <kendo-grid-column field="peopleMale" title="M" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="peopleFemale" title="F" [width]="50"> </kendo-grid-column>
                <kendo-grid-column field="peopleDiff" title="Diff" [width]="50"> </kendo-grid-column>
            </kendo-grid-column-group>

        </kendo-grid>

        
    </mat-card-content>
</mat-card>
