<mat-card class="mat-elevation-z0">
    <mat-card-title >
        <h5>{{ 'TotalSalaryByDepartments' | translate: {Default: "Total Salary by Departments"} }}</h5>
    </mat-card-title>

    <mat-card-content>
        <div id="chart" *ngIf="showChart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [labels]="chartOptions.labels"
              [legend]="chartOptions.legend"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>
