import { Component, Input, OnInit } from '@angular/core';
import { EmploymentRecordPositionsService } from '../../../employment-records/components/employment-record-details/components/employment-record-positions/services/employment-record-positions.service';
import { finalize } from 'rxjs';
import { EmployeePosition } from '../../../employment-records/components/employment-record-details/components/employment-record-positions/models/employment-record-position.model';
import * as moment from 'moment';
import { PositionsService } from '@app/modules/positions/services/positions.service';
import { EmployeeCompensationService } from '../employee-compensation/services/employee-compensation.service';
import { EmployeeCompensation } from '../employee-compensation/models/employee-compensation.model';
import { EmployeeContactCardService } from '../../../../container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { EmployeeContactCard } from '../../../../container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { EmployeeSecurityService } from '../../../employee-security/services/employee-security.service';
import { PositionVerbose } from '@app/modules/positions/models/positions.model';
import { CompanyLogosService } from '@app/modules/site-settings-styling/components/company-logos/services/company-logos.service';
import { EnvironmentService } from '@app/core/services/environment.service';

@Component({
  selector: 'app-employment-verification-letter',
  templateUrl: './employment-verification-letter.component.html',
  styleUrls: ['./employment-verification-letter.component.scss']
})
export class EmploymentVerificationLetterComponent implements OnInit {
  @Input() employeeId: string;
  loadingPosition: boolean;
  positions: EmployeePosition[];
  isLoadingCompensation: boolean;
  compensation: EmployeeCompensation;
  loadingEmployeeDetails: boolean;
  employeeDetails: EmployeeContactCard;
  moment = moment;

  constructor(
    private employmentRecordPositionsService: EmploymentRecordPositionsService,
    private positionsService: PositionsService,
    private employeeCompensationService: EmployeeCompensationService,
    private employeeContactCardService: EmployeeContactCardService,
    private employeeSecurityService: EmployeeSecurityService,
    public companyLogosService: CompanyLogosService,
    public environmentService: EnvironmentService,

  ) { }

  ngOnInit(): void {
    this.getEmployeeDetails();
    this.getEmployeeCompensation();
    this.getPositionDetails();
  }

  getEmployeeDetails() {
    this.loadingEmployeeDetails = true;

    this.employeeContactCardService.getEmployeeContactCard(this.employeeId)
    .pipe(
      finalize( () => {
        this.loadingEmployeeDetails = false;
      })
    )
    .subscribe(
      res => {
        this.employeeDetails = res;
      }
    )
  }

  getPositionDetails() {
    this.loadingPosition = true;

    this.employmentRecordPositionsService.getEmployeePositions(this.employeeId)
    .pipe(
      finalize( () => {
        this.loadingPosition = false;
      })
    )
    .subscribe(
      res => {
        this.positions = res;

        this.positions.sort((a, b) => {
          return moment(b.startDate).diff(a.startDate);
        });

        this.positions.forEach(
          employeePosition => {
            employeePosition.positionDetails = this.positionsService.getPosition(employeePosition.position.id);
          }
        );
      }
    )
  }

  getEmployeeCompensation() {
    this.isLoadingCompensation = true;

    let asOf = moment().format('YYYY-MM-DD');
    this.employeeCompensationService.getCompensation(this.employeeId, asOf)
    .pipe(
        finalize(() => {
            this.isLoadingCompensation = false;
        })
    )
    .subscribe(
        res => {
            this.compensation = res;
        }
    );
  }

  getPositionName(positionVerbose: PositionVerbose) {
    let localization = positionVerbose.name.find( localization => localization.culture === this.employeeSecurityService.currentUserCulture.id);
    return localization.text
  }

}
