import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Survey } from '@app/modules/survey-section/models/surveys.model';
import { SurveysService } from '@app/modules/survey-section/services/surveys.service';
import { WorkflowTask } from '@app/modules/workflow-section/models/workflows.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-survey-task-item',
  templateUrl: './survey-task-item.component.html',
  styleUrls: ['./survey-task-item.component.scss']
})
export class SurveyTaskItemComponent implements OnInit {
  @Input() task: WorkflowTask;
  
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  isLoading: boolean = true;
  survey: Survey;

  constructor(
    private snackbarService: SnackbarService,  
    private surveysService: SurveysService,
  ) { }

  ngOnInit(): void {
    this.getSurvey();
  }

  getSurvey() {
    this.surveysService.getSurveys()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
        res => {
          this.survey = res.find(obj => obj.id === this.task.survey);
        }
    );
  }

  checkboxChange($event: MatCheckboxChange) {
    this.checkedChange.emit($event.checked);
  } 

}
