import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salary-review-dashboard',
  templateUrl: './salary-review-dashboard.component.html',
  styleUrls: ['./salary-review-dashboard.component.scss']
})
export class SalaryReviewDashboardComponent implements OnInit {
  details = [
    {
      label: "Salary Review",
      value: "250",
    },
    {
      label: "Reviews in Progress",
      value: "420",
    },
    {
      label: "Approval Required",
      value: "180",
    },
    {
      label: "Completed",
      value: "70",
    },
    {
      label: "Total Salary",
      value: "$30.4M",
    },
    {
      label: "Avg - Salary",
      value: "$60,000",
    },
  ]


  constructor() { }

  ngOnInit(): void {
  }

}
