import { Component, Inject, Input, OnInit } from '@angular/core';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { PerformanceReview } from '../../models/performance-review.model';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-active-review-review-details',
  templateUrl: './active-review-review-details.component.html',
  styleUrls: ['./active-review-review-details.component.scss']
})
export class ActiveReviewReviewDetailsComponent implements OnInit {
  performanceReview: PerformanceReview
  reviewer: any
  employeeContactCard$: any;
  reviewerEmployeeContactCard$: any;

  ratingOptions = [
    "Exceeds Expectations",
    "Meets Expections",
    "Did Not Meet Expectations",
    "Not Rated"
  ]

  constructor(
    private employeeContactCardService: EmployeeContactCardService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialogRef: MatDialogRef<ActiveReviewReviewDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.performanceReview = data.performanceReview;
    this.reviewer = data.reviewer;

    this.employeeContactCard$ = this.employeeContactCardService.getEmployeeContactCard(this.performanceReview.review.employee.id);
    this.reviewerEmployeeContactCard$ = this.employeeContactCardService.getEmployeeContactCard(this.reviewer.reviewer.id);
  }

  ngOnInit(): void {
  }

  navigateToReviewDetails(reviewer: any){}

  

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
      this.dialogRef.close('saved');
    }, 2000);
  }

  close() {
    this.dialogRef.close();
  }

}
