import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeOffGuidesService } from '../../services/time-off-guides.service';
import { TimeOffGuideDialogComponent } from '../time-off-guide-dialog/time-off-guide-dialog.component';

export class GoalGuide {
  id: string;
  title: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-time-off-guides',
  templateUrl: './time-off-guides.component.html',
  styleUrls: ['./time-off-guides.component.scss']
})
export class TimeOffGuidesComponent implements OnInit {
  guides: GoalGuide[] = [];

  constructor(
    private dialog: MatDialog,
    private timeOffGuidesService: TimeOffGuidesService
  ) { }

  ngOnInit(): void {
    this.getGuides();
  }

  getGuides() {
    this.timeOffGuidesService.getGuides().subscribe((guides) => {
      this.guides = guides;
    });
  }

  openGuideDialog(guide: GoalGuide) {
    let guideHtml = this.timeOffGuidesService.getGuide(guide.id);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      guideTitle: guide.title,
      htmlContent: guideHtml.html
    };

    const dialogRef = this.dialog.open(TimeOffGuideDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

}
