import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ReviewsService } from '../reviews/services/reviews.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Review, ReviewGoalPlan, ReviewGoalPlanGoal, ReviewSubmit } from '../reviews/models/review.model';
import { Observable, finalize } from 'rxjs';
import { db_tables, routes } from '@app/consts';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { ReviewPlansService } from '../review-plans/services/review-plans.service';
import * as _ from 'lodash';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { comment } from 'postcss';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-review-page',
  templateUrl: './review-page.component.html',
  styleUrls: ['./review-page.component.scss']
})
export class ReviewPageComponent implements OnInit {
  public db_tables = db_tables;
  isLoading: boolean;
  getFormData: boolean = false;
  formValid: boolean = false;
  formData: any;
  currentFormData: any;
  formPristine: boolean = true;
  reviewId: string;
  review: Review;
  allGoalPlansReviewStatus: string;

  reviewPlanFormId: string = "frm_WrPd2HV5RGCscl";
  reviewPlanFormData;

  reviewerColumns = [
    { field: "reviewer", title: "Reviewer", tableId: "tfi_PrpRReviewerUser", type: "employee" },
    { field: "reviewerTypeExtracted", tableId: "tfi_PrpRReviewerType", title: "Reviewer Type" },
    { field: "orderExtracted", title: "Order", tableId: "tfi_PrpROrderNumber" },
    { field: "statusExtracted", title: this.translate.instant('Reviewer-Status'), type: 'chip', chipValue: ['Complete', '',  'InProgress', 'Pending'] },
  ]

  reviewPlanLabels = [
    {
      id: 'tfi_RpName',
      label: 'Name'
    },
    {
      id: 'tfi_RpDescription',
      label: 'Description'
    },
    {
      id: 'tfi_RpStartDate',
      label: 'Start Date'
    },
    {
      id: 'tfi_RpEndDate',
      label: 'End Date'
    },
    {
      id: 'tfi_RpEnforceFullWeightAcrossGoalPlans',
      label: 'Enforce Full Weight Across Goal Plans'
    },
    {
      id: 'tfi_RpFinalReview',
      label: 'Final Review'
    },
    {
      id: 'tfi_RpRequireEmployeeWeightEntry',
      label: 'Require Employee Weight Entry'
    }
  ]

  reviewRatingForm: FormGroup;
  isLoadingRatings: boolean;
  reviewRatingOptions: any[];
  user$: any;
  loadingDbTableFields: boolean;
  error: boolean;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private reviewsService: ReviewsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private employeeContactCardService: EmployeeContactCardService,
    private fb: FormBuilder,
    private oidcAuthService: OidcAuthService,
    private reviewPlansService: ReviewPlansService,
    private securitySetupService: SecuritySetupService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
    this.reviewId = this.getIdInURL();
  }

  ngOnInit(): void {
    this.getReview();
    this.getDbTableFields();
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('reviewId')
    );

    return IdInURL;
  }

  getDbTableFields() {
    this.loadingDbTableFields = true;

    this.securitySetupService.getFields(db_tables.PerformanceReviewPlans, 0, '100')
    .pipe(
      finalize(()=>{
        this.loadingDbTableFields = false;
      })
    )
    .subscribe(
      res => {
        res.data.forEach(dataItem => {
          const labelToUpdate = this.reviewPlanLabels.find(labelItem => labelItem.id === dataItem.id);
          if (labelToUpdate) {
              labelToUpdate.label = dataItem.name; // Updating label value from data
          }
        });
      }
    );
  }

  getLabel(id: string){
    const reviewPlanLabel = this.reviewPlanLabels.find(labelItem => labelItem.id === id);

    return reviewPlanLabel?.label
  }

  getReview() {
    this.isLoading = true;

    this.reviewsService.getReview(this.reviewId)
    .subscribe(
      {
        next: (v) => {
          this.review = this.formatData(v);
          this.organizeGoals();
          this.setStatus();
          this.buildFormData();
          this.getReviewPlanRatings();
        },
        error: (e) => this.error = true,
        complete: () => this.isLoading = false
      }
    );
  }

  refreshReview() {
    // setting isloading is refreshing the page, I need to find out a way to refresh the data and not the page
    this.isLoading = true;

    this.reviewsService.getReview(this.reviewId)
    .subscribe(
      {
        next: (v) => {
          this.review = this.formatData(v);
          this.organizeGoals();
          this.setStatus();
          this.buildFormData();
          this.getReviewPlanRatings();
        },
        error: (e) => this.error = true,
        complete: () => this.isLoading = false
      }
    );
  }

  createReviewRatingForm() {
    // this.reviewRatingForm = this.fb.group({
    //   reviewRating: new UntypedFormControl({ value: this.review?.rating?.id, disabled: this.review?.settings?.overallRating === 'Read'  }),
    //   reviewComments: new UntypedFormControl({ value: this.review?.comments, disabled: this.review?.settings?.overallComments === 'Read' })
    // });
  }

  getReviewerPermissions(reviewerId: string) {
    let reviewerPermission = this.review.settings.reviewerPermissions.find( reviewerPermission => reviewerPermission.reviewer === reviewerId)

    return reviewerPermission;
  }

  getReviewPlanRatings() {
    this.isLoadingRatings = true;

    this.reviewPlansService.getReviewPlanRatings(this.review?.reviewPlan?.id, '100', 0, 'SortOrder-asc')
      .pipe(
        finalize( () => {
          this.isLoadingRatings = false;
          this.createReviewRatingForm();
        })
      )
      .subscribe(
        (res) => {
          this.reviewRatingOptions = res.data;
        }
      );
  }

  getOverallReviewStatus(): string {
    let review = this.review.reviews.find( r => r.reviewedBy?.id === this.user$.userId);
    let reviewerPermissions = this.getReviewerPermissions(this.user$.userId);
    let ratingNeedsReview = false;
    let commentsNeedsReview = false;

    if(reviewerPermissions === undefined || this.review.reviews === null || review === undefined || (reviewerPermissions.comments !== "Edit" && reviewerPermissions.rating !== "Edit")){
      return 'nothingNeeded';
    }

    if(reviewerPermissions.comments === "Edit" && review.comments === null) {
      commentsNeedsReview = true;
    }

    if(reviewerPermissions.rating === "Edit" && review.rating === null) {
      ratingNeedsReview = true;
    }

    if(ratingNeedsReview || commentsNeedsReview) {
      return 'needsReview';
    }
    else {
      return 'reviewed';
    }
  }

  organizeGoals() {
    this.review?.goalPlans.forEach((plan) => {
      const parentGoals = plan.goals.filter((goal) => !goal.parent);
      parentGoals.forEach((parent) => {
        const children = plan.goals.filter((goal) => goal.parent && goal.parent.id === parent.id);
        if (children.length > 0) {
          parent.childGoals = children;
        }
      });
      plan.goals = parentGoals;
    });
  }

  // This function formats the data within a review object
  formatData(review: Review) {
    // Create an empty array to store updated reviewers' data
    const updatedReviewers = [];

    // Loop through each reviewer in the review object
    review.reviewers.forEach(reviewer => {
      // If the reviewer has more than one order
      if (reviewer.order.length > 1) {
        // Loop through each order within the reviewer's orders
        reviewer.order.forEach(order => {
          // Create a new reviewer object with individual orders and push it to the updatedReviewers array
          const newReviewer = {
            id: reviewer.id,
            firstName: reviewer.firstName,
            lastName: reviewer.lastName,
            order: [order],
          };
          updatedReviewers.push(newReviewer);
        });
      } else {
        // If the reviewer has only one order, push the reviewer directly to the updatedReviewers array
        updatedReviewers.push(reviewer);
      }
    });

    // Sort the updatedReviewers array based on the order value of the first order
    updatedReviewers.sort((a, b) => {
      const orderA = a.order[0].order;
      const orderB = b.order[0].order;
      return orderA - orderB;
    });
    
    // Modify the review.reviewers array to include specific extracted properties for each reviewer
    review.reviewers = updatedReviewers.map(reviewer => ({
      ...reviewer,
      "reviewer": { id: reviewer.id, firstName: reviewer.firstName, lastName: reviewer.lastName },
      "orderExtracted": reviewer.order[0].order,
      "statusExtracted": reviewer.order[0].status.name,
      "reviewerTypeExtracted": reviewer.order[0].reviewerType.name,
    }));

    // Return the updated review object
    return review;    
  }

  buildFormData() {
    this.reviewPlanFormData = {
      name: this.review ? this.review?.reviewPlan?.name : null,
      description: this.review ? this.review?.reviewPlan?.description : null,
      startDate: this.review ? this.review?.reviewPlan?.startDate : null,
      endDate: this.review ? this.review?.reviewPlan?.endDate : null,
    }
  }

  navigateBack() {
    this.router.navigate([`${routes.PERFORMANCE}/${routes.REVIEWS}`]);
  }

  removeUnwantedDataForSubmit(originalData: any): ReviewSubmit {
    const convertedData: ReviewSubmit = {
      rating: originalData?.rating,
      comments: originalData?.comments,
      goalPlans: originalData?.goalPlans?.map((originalGoalPlan: any) => {
        const convertedGoalPlan = {
          id: originalGoalPlan?.id,
          rating: null,
          comments: null,
          goals: originalGoalPlan?.goals?.map(
            (originalGoal: any) => {

              if(originalGoal?.reviews !== null){
                const convertedReview = {
                  id: originalGoal?.reviews ? originalGoal?.id : null,
                  comments: originalGoal?.reviews ? originalGoal?.reviews[0]?.comments : null,
                  rating: originalGoal?.reviews && originalGoal?.reviews[0]?.rating ? originalGoal?.reviews[0]?.rating?.id : null
                };
      
                return convertedReview;
              }
            }
          ).filter((goal: any) => goal !== null && goal !== undefined) // Filter out null/undefined values
        };
  
        return convertedGoalPlan;
      })
    };
  
    return convertedData;
  }

  saveGoalPlanReview(goalPlanReview: {goalPlanId: string, rating: string, comments: string}) {
    let overallReview = this.review.reviews.find(review => review.reviewedBy.id === this.user$.userId);

    let reviewSubmit = {
      "rating": overallReview?.rating ? overallReview?.rating?.id : null,
      "comments": overallReview?.comments,
      "goalPlans": [
        {
          "id": goalPlanReview.goalPlanId,
          "rating": goalPlanReview.rating,
          "comments": goalPlanReview.comments,
          "goals": []
        }
      ]
    }

    this.overlayService.show();

    this.reviewsService.updateReview(this.review.id, reviewSubmit)
    .pipe(
      finalize(() => {
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.refreshReview();

        this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
      }
    );
  }

  getGoalPlanByGoalId(goalId: string, goalPlans: ReviewGoalPlan[]): ReviewGoalPlan {
    if(goalPlans){
      for (const plan of goalPlans) {
        if (this.containsGoal(plan.goals, goalId)) {
          return plan;
        }
      }
    }
    
    return null;
  }

  containsGoal = (goals: ReviewGoalPlanGoal[], goalId: string): boolean => {
    if(goals){
      for (const goal of goals) {
        if (goal.id === goalId || this.containsGoal(goal.childGoals, goalId)) {
          return true;
        }
      }
    }

    return false;
  };

  saveGoalReview(goalReview: {goalId: string, id: string, rating: string, comments: string}) {
    let copiedReview = _.cloneDeep(this.review);
    let overallReview = this.review?.reviews?.find(review => review.reviewedBy.id === this.user$.userId);
    let goalPlan = this.getGoalPlanByGoalId(goalReview.goalId, copiedReview.goalPlans);
    let goalPlanReview = goalPlan?.reviews?.find(review => review.reviewedBy.id === this.user$.userId)

    let reviewSubmit = {
      rating: overallReview?.rating?.id ?? null,
      comments: overallReview?.comments ?? null,
      goalPlans: [
        {
          id: goalPlan.id,
          comments: goalPlanReview?.comments ?? null,
          rating: goalPlanReview?.rating ?? null,
          goals: [
            {
              id: goalReview?.goalId,
              comments: goalReview?.comments ?? null,
              rating: goalReview?.rating ?? null
            }
          ]
        }
      ]
    }

    this.overlayService.show();

    this.reviewsService.updateReview(this.review.id, reviewSubmit)
    .pipe(
      finalize(() => {
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.refreshReview();

        this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
      }
    );
  }

  saveReview(
    newReview: {
      reviewedBy: {
        id: string
        firstName: string
        lastName: string
      }, 
      rating: string, 
      comments: string
    }
  ) {
    // let reviewSubmit = _.cloneDeep(this.review);

    // // replace the existing review with the new one
    // let index = reviewSubmit.reviews.findIndex(review => review.reviewedBy.id === newReview.reviewedBy.id);
    // if (index !== -1) {
    //   reviewSubmit.reviews[index] = newReview;
    // }

    let reviewSubmit = {
      rating: newReview.rating,
      comments: newReview.comments
    }

    this.overlayService.show();

    this.reviewsService.updateReview(this.review.id, reviewSubmit)
    .pipe(
      finalize(() => {
        this.overlayService.hide();
      })
      )
      .subscribe(
        (res) => {
          this.refreshReview();
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        }
    );
  }

  submitReview() {
    let copiedReview = _.cloneDeep(this.review);

    // remove any reviews that arnt for the currently logged in user
    for (const goalPlan of copiedReview.goalPlans) {
      for (const goal of goalPlan.goals) {
        if(goal.reviews !== null) {
          goal.reviews = goal.reviews.filter(review => review.reviewedBy.id === this.user$.userId)
        }
      }
    }

    let reviewSumbit = {
      rating: {
        id: this.reviewRatingForm.get('reviewRating').value
      },
      comments: this.reviewRatingForm.get('reviewComments').value,
      goalPlans: null
    }
    this.overlayService.show();

    this.reviewsService.updateReview(this.review.id, this.removeUnwantedDataForSubmit(reviewSumbit))
    .pipe(
      finalize(() => {
        this.overlayService.hide();
      })
      )
      .subscribe(
        (res) => {
          this.refreshReview();
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        }
    );
  }

  openConfirmationWarning() {
    if(this.review?.overallReviewStatus === 'needsReview' || this.review?.allGoalPlansReviewStatus === 'needsReview') {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
  
      dialogConfig.data = {
        localizationCode: 'Review-CompleteReviewWarning'
      };
  
      const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
        data => {
          if (data === true) {
            this.completeReview();
          }
        }
      );
    }
    else {
      this.completeReview();
    }
  }

  completeReview() {
    this.overlayService.show();

    this.reviewsService.completeReview(this.review.id)
    .pipe(
      finalize(() => {
        this.overlayService.hide();
      })
      )
      .subscribe(
        (res) => {
          this.refreshReview();
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        }
    );
  }

  revertReview() {
    this.overlayService.show();

    this.reviewsService.revertReview(this.review.id)
    .pipe(
      finalize(() => {
        this.overlayService.hide();
      })
      )
      .subscribe(
        (res) => {
          this.refreshReview();
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        }
    );
  }

  setStatus() {
    this.review?.goalPlans?.forEach(
      goalPlan => {
        goalPlan?.goals?.map(goal => {
          goal?.childGoals?.map( childGoal => {
            childGoal.status = this.getGoalReviewStatus(childGoal, goalPlan);
          })
          
          goal.status = this.getGoalReviewStatus(goal, goalPlan);
        });
        goalPlan.goalsStatus = this.getAllGoalsReviewStatusInGoalPlan(goalPlan);
        goalPlan.reviewStatus = this.getOverallGoalPlanReviewStatus(goalPlan);
        goalPlan.overallStatus = this.getGoalPlanStatus(goalPlan);
      }
    )

    this.review.allGoalPlansReviewStatus = this.getAllGoalPlansReviewStatus();
    this.review.overallReviewStatus = this.getOverallReviewStatus();
  }

  

  getGoalReviewStatus(goal: ReviewGoalPlanGoal, goalPlan: ReviewGoalPlan) {
    let review = goal.reviews?.find( r => r.reviewedBy?.id === this.user$.userId)
    let childGoalStatus: string[] = [];

    let goalPlanPermissions = this.review.settings.goalPlanPermissions?.find( permission => permission?.goalPlan === goalPlan.id );
    let goalPermissions = goalPlanPermissions.goalPermissions?.find( permission => permission?.goal === goal.id );
    let reviewerPermissions = goalPermissions?.reviewerPermissions?.find( permission => permission?.reviewer === this.user$.userId );

    let ratingNeedsReview = false;
    let commentsNeedsReview = false;

    goal?.childGoals?.forEach(
      goal => {
        childGoalStatus.push(this.getGoalReviewStatus(goal, goalPlan))
      }
    )

    
    if(childGoalStatus.length > 0) {
      if(childGoalStatus.includes('needsReview')) {
        return 'needsReview';
      }
      else if(childGoalStatus.includes('reviewed')) {
        if(review === null) {
          return 'reviewed';
        }
        else if(reviewerPermissions === undefined) {
          return 'reviewed';
        }
  
        if(reviewerPermissions.comments === "View" && reviewerPermissions.rating === "View") {
          return 'reviewed';
        }

        if(reviewerPermissions.comments === "View" && reviewerPermissions.rating === "None") {
          return 'reviewed';
        }

        if(reviewerPermissions.comments === "None" && reviewerPermissions.rating === "View") {
          return 'reviewed';
        }
        
        if(reviewerPermissions.comments === "Edit" && review.comments === null) {
          commentsNeedsReview = true;
        }
  
        if(reviewerPermissions.rating === "Edit" && review.rating === null) {
          ratingNeedsReview = true;
        }
  
        if(ratingNeedsReview || commentsNeedsReview) {
          return 'needsReview';
        }
        else {
          return 'reviewed';
        }
      }
    }
    else {
      if(review === null) {
        return 'nothingNeeded';
      }
      else if(reviewerPermissions === undefined) {
        return 'nothingNeeded';
      }

      if(reviewerPermissions.comments === "View" && reviewerPermissions.rating === "View") {
        return 'nothingNeeded';
      }

      if(reviewerPermissions.comments === "View" && reviewerPermissions.rating === "None") {
        return 'nothingNeeded';
      }

      if(reviewerPermissions.comments === "None" && reviewerPermissions.rating === "View") {
        return 'nothingNeeded';
      }
      
      if(reviewerPermissions.comments === "Edit" && review.comments === null) {
        commentsNeedsReview = true;
      }

      if(reviewerPermissions.rating === "Edit" && review.rating === null) {
        ratingNeedsReview = true;
      }

      if(ratingNeedsReview || commentsNeedsReview) {
        return 'needsReview';
      }
      else {
        return 'reviewed';
      }
    }
  }

  getAllGoalsReviewStatusInGoalPlan(goalPlan: ReviewGoalPlan): string {
    let goalStatus = goalPlan.goals.map(goal => goal.status);

    if(goalStatus.includes('needsReview')) {
      return 'needsReview';
    }
    else if(goalStatus.includes('reviewed')) {
      return 'reviewed';
    }
    else {
      return 'nothingNeeded';
    }
  }

  getGoalPlanStatus(goalPlan: ReviewGoalPlan) {
    let goalStatus = [goalPlan.goalsStatus, goalPlan.reviewStatus];

    if(goalStatus.includes('needsReview')) {
      return 'needsReview';
    }
    else if(goalStatus.includes('reviewed')) {
      return 'reviewed';
    }
    else {
      return 'nothingNeeded';
    }
  }

  getOverallGoalPlanReviewStatus(goalPlan?: ReviewGoalPlan): string {
    let goalPlanPermissions = this.review.settings.goalPlanPermissions?.find( permission => permission?.goalPlan === goalPlan.id );
    let reviewerPermissions = goalPlanPermissions.reviewerPermissions?.find( permission => permission?.reviewer === this.user$.userId );
    let review = goalPlan.reviews?.find( r => r.reviewedBy?.id === this.user$.userId);
    let ratingNeedsReview = false;
    let commentsNeedsReview = false;

    if(reviewerPermissions === undefined || this.review.reviews === null || review === undefined || (reviewerPermissions.comments !== "Edit" && reviewerPermissions.rating !== "Edit")){
      return 'nothingNeeded';
    }

    if(reviewerPermissions.comments === "Edit" && review.comments === null) {
      commentsNeedsReview = true;
    }

    if(reviewerPermissions.rating === "Edit" && review.rating === null) {
      ratingNeedsReview = true;
    }

    if(ratingNeedsReview || commentsNeedsReview) {
      return 'needsReview';
    }
    else {
      return 'reviewed';
    }
  }

  isGoalPlanReviewed(goalPlan: ReviewGoalPlan) {
    let goalPlanPermissions = this.review.settings.goalPlanPermissions?.find( permission => permission?.goalPlan === goalPlan.id );
    let reviewerPermissions = goalPlanPermissions?.reviewerPermissions?.find( permission => permission?.reviewer === this.user$.userId );
    let goalStatus = [this.getOverallGoalPlanReviewStatus(goalPlan), this.getAllGoalsReviewStatusInGoalPlan(goalPlan)];
    
    if(reviewerPermissions === undefined) {
      return 'nothingNeeded';
    }

    if(goalStatus.includes('needsReview')) {
      return 'needsReview';
    }
    else if(goalStatus.includes('reviewed')) {
      return 'reviewed';
    }
    else {
      return 'nothingNeeded';
    }
  }

  getAllGoalPlansReviewStatus(): string {
    let goalStatus = this.review.goalPlans.map( goalPlan => goalPlan.overallStatus )

    if(goalStatus.includes('needsReview')) {
      return 'needsReview';
    }
    else if(goalStatus.includes('reviewed')) {
      return 'reviewed';
    }
    else {
      return 'nothingNeeded';
    }
  }

  saveAsPdf() {
    this.overlayService.show('Downloading PDF...');

    setTimeout(() => {
      const link = document.createElement('a');
      link.href = 'assets/performance/Oliver-Alford-PIP-Review-1.pdf';
      link.download = 'Oliver-Alford-PIP-Review-1.pdf';
      link.click();
      this.overlayService.hide();
    }, 2000);
  }

}
