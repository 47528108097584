<div class="pay-gap">
    <mat-toolbar class="page-header" role="heading">
        <h1>{{report?.title}}</h1>
    
        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToReportGroups()">
            Back to Reports
        </button>
    </mat-toolbar>
    
    <mat-toolbar class="page-header" role="heading">
        <mat-tab-group [(selectedIndex)]="selectedTabIndex" mat-stretch-tabs="false" mat-align-tabs="center">
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="material-icons-outlined">leaderboard</mat-icon>
        
                <span appLocalizationPopupDirective [localizationCode]="'PayGapReport-Report'">
                  {{ 'PayGapReport-Report' | translate: {Default: "Report"} }}
                </span>
              </ng-template>
        
              <ng-template matTabContent>
              </ng-template>
            </mat-tab>
        
            <mat-tab>
                <ng-template mat-tab-label>
                  <mat-icon class="material-icons-outlined">summarize</mat-icon>
          
                  <span appLocalizationPopupDirective [localizationCode]="'PayGapReport-Summary'">
                    {{ 'PayGapReport-Summary' | translate: {Default: "Summary"} }}
                  </span>
                </ng-template>
          
                <ng-template matTabContent>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-toolbar>

    <mat-card *ngIf="selectedTabIndex === 0" class="pay-gap-report">
        <mat-card-header>
            <mat-card-title>
                
            </mat-card-title>
            <mat-card-subtitle>{{report?.description}}</mat-card-subtitle>
        </mat-card-header>
    
        <mat-card-content>
            <div class="row">
                <app-simple-piechart [dataArray]="report?.proportionData?.data" [title]="report?.proportionData?.title"></app-simple-piechart>
                <app-hourly-rate-comparison [hourlyRateComparisonData]="report?.hourlyRateComparisonData"></app-hourly-rate-comparison>
            </div>
            <app-ordinary-pay [ordinaryPay]="report?.ordinaryPay"></app-ordinary-pay>
            <div class="row">
                <app-one-off-contributions [oneOffContributions]="report?.oneOffContributions"></app-one-off-contributions>
                <app-pay-by-quartile [payByQuartile]="report?.payByQuartile"></app-pay-by-quartile>
            </div>
        </mat-card-content>
    </mat-card>

    <app-summary *ngIf="selectedTabIndex === 1" [summary]="report?.summary"></app-summary>
</div>



