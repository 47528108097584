<app-layout>

    <mat-toolbar class="page-header" role="heading">
        <h1>Turnover/Attrition</h1>
    </mat-toolbar>

    <div class="content">
        <div class="left">
            <app-simple-table [dataArray]="seniorityData" title="Current Average Seniority In Months"></app-simple-table>
            <app-turnover-pie-chart></app-turnover-pie-chart>
            <!-- <app-simple-piechart [dataArray]="turnoverRateData" title="Employee Turnover Rate" description="As at December 31, 2022"></app-simple-piechart> -->
            <app-simple-horizontal-bar-chart [chartOptions]="turnoverRatePerDivisionData" title="Turnover Rate by Division"></app-simple-horizontal-bar-chart>
        </div>

        <div class="right">
            <app-turnover-rate></app-turnover-rate>
            <app-actual-vs-benchmark></app-actual-vs-benchmark>
        </div>
    </div>
</app-layout>