import { Injectable } from '@angular/core';
import { features } from '@app/consts';
import { modules } from '@app/consts/modules';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportsService {

    constructor(
        private translate: TranslateService,
    ) {
    }

    public getReports(): Observable<any> {
        return of([
            // {
            //   "id": "g",
            //   "name": "Global Insights",
            //   "icon": "groups",
            //   "detail": "Snapshot of global metrics and insights relating to new hires, headcount, global talent and global mobility.",
            //   "href": "/Reports/HeadCountReport"
            // },
            // {
            //   "id": "k",
            //   "name": "Headcount By Month",
            //   "icon": "date_range",
            //   "detail": "View analytics for headcount, new hires and related data by each month.  Get insights to support your decision making.",
            //   "href": "/Reports/HeadcountByMonthReport"
            // },
            {
                "id": "g",
                "name": "Global Insights",
                "icon": "groups",
                "detail": "Snapshot of global metrics and insights relating to new hires, headcount, global talent and global mobility.",
                "href": "/Reports/HeadCountReport",
                "featureCode": "allow"
            },
            {
                "id": "g",
                "name": "Gender And Ethnicity Pay Gap",
                "icon": "request_quote",
                "detail": "An overview of gender pay gap (GPG) statistics, including the unadjusted gender pay gap used to monitor imbalances in earnings between men and women.",
                "href": "/Reports/GenderEthnicityPayGap",
                "featureCode": "allow"
            },
            {
              "id": "f",
              "name": "Workforce Planning",
              "icon": "people_alt",
              "detail": "See how your data can be viewed across a broad array of demographic filters including age, gender, role, levels, seniority and other factors.",
              "href": "/Reports/WorkforcePlanningReport",
                "featureCode": "allow"
            },
            {
              "id": "h",
              "name": "Diversity Benchmark Report",
              "icon": "find_in_page",
              "detail": "Validate and verify your data with reports tracking deletions, history, HR manager assignments and related information.",
              "href": "/Reports/DiversityBenchmarkReport",
                "featureCode": "allow"
            },
            // {
            //   "id": "m",
            //   "name": "Compensation Reports",
            //   "icon": "analytics",
            //   "detail": "See how turnover and attrition may be impacting your business.  Track employee movement and terminations over time.",
            //   "href": "/Reports/CompensationReport"
            // },
            // {
            //   "id": "i",
            //   "name": "Extract Reports",
            //   "icon": "file_upload",
            //   "detail": "For the power user, these robust reports give a deep and broad view of data directly into Excel allowing you to conduct deep analysis across many records at once."
            // },
            // {
            //   "id": "e",
            //   "name": "List Reports",
            //   "icon": "list",
            //   "detail": "See info on contact lists, create mailing lists, emergency contact lists which can be easily filtered and sorted to fit your needs.",
            //   "href": "/ReportsList"
            // },


            {
                'id': 'a',
                'name': 'Organization Chart',
                'icon': 'groups',
                'detail': 'Visualise the organisational structure of your company. From the top down, for specific departments or managers, or across regions or work locations.',
                'href': ['/Reports/OrgChart', 'organizations', 'false'],
                'featureCode': features.ORG_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': 'Position Chart',
                'icon': 'supervised_user_circle',
                'detail': 'Gain insight into your organization from a positions perspective. From the top down, for specific departments or managers, or across regions or work locations.',
                'href': ['/Reports/OrgChart', 'positions', 'false'],
                'featureCode': features.POSITIONS_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('Employees'),
                'icon': 'people',
                'detail': '',
                'href': ['/Reports/Custom/Type/Employees'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('PayGap'),
                'icon': 'request_quote',
                'detail': '',
                'href': ['/Reports/Custom/Type/PayGap'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('Terminations'),
                'icon': 'people',
                'detail': '',
                'href': ['/Reports/Custom/Type/Terminations'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('TimeOff'),
                'icon': 'alarm',
                'detail': '',
                'href': ['/Reports/Custom/Type/TimeOff'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('Toil'),
                'icon': 'alarm',
                'detail': '',
                'href': ['/Reports/Custom/Type/Toil'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('Performance'),
                'icon': 'leaderboard',
                'detail': '',
                'href': ['/Reports/Custom/Type/Performance'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': modules.PERFORMANCE
            },
            {
                'id': 'p',
                'name': this.translate.instant('Configuration'),
                'icon': 'settings',
                'detail': '',
                'href': ['/Reports/Custom/Type/Configuration'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            {
                'id': 'p',
                'name': this.translate.instant('Security'),
                'icon': 'security',
                'detail': '',
                'href': ['/Reports/Custom/Type/Security'],
                'featureCode': features.HEAD_COUNT_REPORT,
                'module': 'allow'
            },
            // {
            //   "id": "p",
            //   "name": "Employee Bank Details",
            //   "icon": "account_balance",
            //   "detail": "",
            //   "href": ["/Reports/EmployeeBankDetailsReport"],
            //   "featureCode": features.BANK_FEATURES_REPORT
            // },
            // {
            //     'id': 'a',
            //     'name': 'Absence Report',
            //     'icon': 'people',
            //     'detail': 'Display Absence Reports for the employees.',
            //     'href': ['/Reports/Predefined/AbsenceReport'],
            //     'featureCode': 'allow'
            // },


      // {
      //   "id": "custom",
      //   "name": "Custom Reports",
      //   "icon": "supervised_user_circle",
      //   "detail": "",
      //   "href": ["/Reports/Custom"],
      //   "featureCode": features.POSITIONS_REPORT
      // },
    //   {
    //     "id": "p",
    //     "name": this.translate.instant('BasicEmployeeReport'),
    //     "icon": "account_circle",
    //     "detail": "",
    //     "href": ["/Reports/BasicEmployeeReport"],
    //     "featureCode": features.BASIC_EMPLOYEE_DETAILS_REPORT
    //   },
    //   {
    //     "id": "p",
    //     "name": this.translate.instant('BasicHeadcountReport'),
    //     "icon": "people",
    //     "detail": "",
    //     "href": ["/Reports/BasicHeadcountReport"],
    //     "featureCode": features.HEAD_COUNT_REPORT
    //   },
    ]);
  }


    public getDataGridReports(): Observable<any> {
        return of([
            {
                title: 'Employee Profile with Photo (PDF)',
                category: 'Employee Profile',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Custom Employee Profile',
                category: 'Employee Profile',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Profiles',
                category: 'Employee Profile',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Profile - Photo Audit Report',
                category: 'Employee Profile',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Multiple Employee Compensation Export',
                category: 'Extract Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Compensation Export',
                category: 'Extract Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Multiple Employee General Export',
                category: 'Extract Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Full Extract',
                category: 'Extract Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Contract Expiry',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Deleted Employee History',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Deleted Profiles',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Business Address',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Documents',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Emergency Contacts',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee History',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee ID Report',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Relocation Report',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Multiple HR Manager Report',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Multiple Employee Mailing List',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'My Team Birthdays',
                category: 'Periodic and Audit Reports',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee Age',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employees By Job Family and Job Band',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employees By Job Family and Job Type',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Monthly Headcount',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Monthly Headcount With Rehire Date',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'New Hires By Date',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Service Awards',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Termination By Reason',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Termination By Date',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Termination By Month And Gender',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Turnover By Age',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Turnover By OU',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Turnover By Role Group & Level',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Turnover By Years Of Service',
                category: 'Metric Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'History/Future Change Report',
                category: 'Administrator Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Lookup Extract Report',
                category: 'Administrator Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Required Fields Audit Report',
                category: 'Administrator Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Salary Band Range Report',
                category: 'Administrator Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'TalentTrack Table Configuration Report',
                category: 'Administrator Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Employee In Root Organizational Unit',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Login History',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Organizational Unit Audit Report',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Organizational Unit Export Report',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role Access Audit Report',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role Assignment By Employee',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role Assignment Member List',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role Configuration Details',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role Configuration System Functions',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role Employee Access Audit Report',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Role OU Access Report',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Security Log Report',
                category: 'Security Report',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Monthly Holiday Utilisation Report',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyHolidayUtilisationReport'
            },
            {
                title: 'Monthly absence report',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '3 Sep 2020',
                scheduled: true,
                published: false,
                href: '/Reports/MonthlyAbsenceReport'
            },
            {
                title: 'Global Insights',
                category: 'Management',
                createdBy: 'Mark Otto',
                lastModified: '21 Feb 2021',
                scheduled: true,
                published: true,
                href: '/Reports/HeadCountReport'
            },
            {
                title: 'Headcount By Month',
                category: 'Management',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/HeadcountByMonthReport'
            },
            {
                title: 'Workforce Planning Report',
                category: 'Management',
                createdBy: 'Mark Otto',
                lastModified: '1 Jan 2020',
                scheduled: false,
                published: true,
                href: '/Reports/WorkforcePlanningReport'
            },
            {
                title: 'Diversity Benchmark Report',
                category: 'Management',
                createdBy: 'Mark Otto',
                lastModified: '23 Jun 2020',
                scheduled: true,
                published: true,
                href: '/Reports/DiversityBenchmarkReport'
            },
            {
                title: 'Org Chart',
                category: 'Management',
                createdBy: 'Mark Otto',
                lastModified: '9 May 2020',
                scheduled: false,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Long service',
                category: 'Recruitment',
                createdBy: 'Mark Otto',
                lastModified: '9 May 2021',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Sickness by reason reported',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: false,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Employees who have reached sickness trigger',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Annual leave outstanding',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '21 Feb 2021',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Maternity, paternity, adoption report',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Flexible Holidays Report (UK)',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Quarterly/Monthly Sickness Report',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Booked annual leave info per country',
                category: 'Absence',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Monthly attrition',
                category: 'Management information',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Starters report',
                category: 'Management information',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Movers report',
                category: 'Management information',
                createdBy: 'Mark Otto',
                lastModified: '21 Feb 2021',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Leavers report',
                category: 'Management information',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Leavers report',
                category: 'Management information',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Gender pay gap',
                category: 'Equalities reports',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Audit report',
                category: 'Changes to system',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Pension report',
                category: 'Payroll Report',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Buy/Sell (UK)',
                category: 'Payroll Report',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Training Report',
                category: 'Payroll Report',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Live headcount report',
                category: 'Payroll Report',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
            {
                title: 'Long Service Award Report (live)',
                category: 'Payroll Report',
                createdBy: 'Mark Otto',
                lastModified: '11 May 2020',
                scheduled: true,
                published: false,
                href: '/Reports/OrgChart'
            },
        ]);
    }

    public getNewHires(): Observable<any> {
        return of([{
            name: 'Mark Otto',
            email: 'ottoto@wxample.com',
            position: 'Technical Analyst',
            wage: '$70',
            joinDate: '11 May 2020',
            city: 'Otsego',
            status: 'active',
            actions: true
        },
            {
                name: 'Jacob Thornton',
                email: 'thornton@wxample.com',
                position: 'Systems Administrator',
                wage: '$55',
                joinDate: '4 Jun 2020',
                city: 'Fivepointville',
                status: 'active',
                actions: true
            },
            {
                name: 'Larry Wheeler',
                email: 'larryw@wxample.com',
                position: 'Hiring Manager',
                wage: '$80',
                joinDate: '27 Aug 2020',
                city: 'Leadville North',
                status: 'pending',
                actions: true
            },
            {
                name: 'Joseph May',
                email: 'josephmay@wxample.com',
                position: 'Corporate Sales Manager',
                wage: '$200',
                joinDate: '19 Feb 2020',
                city: 'Seaforth',
                status: 'terminated',
                actions: true
            },
            {
                name: 'Peter Horadnia',
                email: 'horadnia@wxample.com',
                position: 'Warehouse Coordinator',
                wage: '$457',
                joinDate: '1 Mar 2020',
                city: 'Hanoverton',
                status: 'active',
                actions: true
            }]);
    }
}
