import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoalsGuidesService {

  constructor() { }

  getGuides(): any {
    return of(
      [
        {
          id: '1',
          title: 'Performance planning guide',
          description: 'Learn how to set goals and achieve them',
          image: 'assets/images/goals-guides/goals-guide-1.jpg'
        },
        {
          id: '2',
          title: 'Setting personal and company goals',
          description: 'Learn how to set goals and achieve them',
          image: 'assets/images/goals-guides/goals-guide-2.jpg'
        },
        {
          id: '3',
          title: 'How to set effective goals',
          description: 'Learn how to set goals and achieve them',
          image: 'assets/images/goals-guides/goals-guide-3.jpg'
        }
      ]
    )
  }

  getGuide(id: string) {
    let guides = [
      {
        id: '1',
        html: `

    <h5>1. Define Clear Goals and Objectives</h5>
    <p><strong>Summary:</strong> Establish specific, measurable, achievable, relevant, and time-bound (SMART) goals for the upcoming performance period.</p>
    <ul>
        <li>Set individual goals aligned with organizational objectives.</li>
        <li>Ensure goals are realistic and within the employee’s control.</li>
        <li>Prioritize goals based on importance and impact.</li>
        <li>Regularly review and adjust goals as needed.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Goal:</strong> Increase customer satisfaction by 15% within the next quarter by enhancing the support ticket resolution process.</li>
    </ul>

    <h5>2. Identify Key Performance Indicators (KPIs)</h5>
    <p><strong>Summary:</strong> Determine metrics that will be used to evaluate progress and success.</p>
    <ul>
        <li>Select relevant KPIs that align with the established goals.</li>
        <li>Use a mix of quantitative and qualitative indicators.</li>
        <li>Ensure KPIs are trackable and reportable.</li>
        <li>Provide clarity on how each KPI will be measured.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>KPI:</strong> Average ticket resolution time reduced from 24 hours to 12 hours.</li>
    </ul>

    <h5>3. Develop Action Plans</h5>
    <p><strong>Summary:</strong> Outline specific steps and strategies that will help achieve the set goals.</p>
    <ul>
        <li>Break down goals into smaller, manageable tasks.</li>
        <li>Assign responsibilities and set deadlines for each task.</li>
        <li>Include resources needed (e.g., training, tools).</li>
        <li>Incorporate a feedback loop for continuous improvement.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Action Plan:</strong> Implement a new ticketing system by the end of the month to streamline processes, followed by training sessions for all support staff.</li>
    </ul>

    <h5>4. Foster Continuous Communication</h5>
    <p><strong>Summary:</strong> Maintain open lines of communication between employees and managers to ensure alignment and address challenges promptly.</p>
    <ul>
        <li>Schedule regular check-ins (weekly, bi-weekly) to discuss progress.</li>
        <li>Encourage two-way feedback.</li>
        <li>Address any roadblocks or challenges early on.</li>
        <li>Adjust goals or strategies if necessary.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Communication Strategy:</strong> Hold bi-weekly meetings to review ticket resolution metrics and gather feedback on the new system.</li>
    </ul>

    <h5>5. Provide Support and Resources</h5>
    <p><strong>Summary:</strong> Ensure employees have the tools, training, and support needed to meet their objectives.</p>
    <ul>
        <li>Identify gaps in skills or knowledge.</li>
        <li>Offer relevant training or professional development opportunities.</li>
        <li>Provide access to necessary tools and resources.</li>
        <li>Create a supportive work environment.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Support:</strong> Enroll the support team in a customer service excellence course to enhance their skills in handling complex issues.</li>
    </ul>

    <h5>6. Monitor Progress and Provide Feedback</h5>
    <p><strong>Summary:</strong> Regularly assess performance against goals and provide constructive feedback.</p>
    <ul>
        <li>Track progress using KPIs and other metrics.</li>
        <li>Offer real-time feedback to reinforce positive behavior or correct issues.</li>
        <li>Use data-driven insights to inform feedback.</li>
        <li>Recognize and reward achievements.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Feedback:</strong> “You’ve significantly reduced the ticket resolution time, which has greatly improved our customer satisfaction. Let’s work on maintaining this momentum.”</li>
    </ul>

    <h5>7. Conduct Performance Reviews</h5>
    <p><strong>Summary:</strong> Formalize the evaluation process with structured performance reviews.</p>
    <ul>
        <li>Prepare a comprehensive review that includes self-assessment and manager assessment.</li>
        <li>Discuss achievements, challenges, and areas for improvement.</li>
        <li>Set new goals and adjust action plans for the next performance period.</li>
        <li>Document the review and follow up on agreed actions.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Review Outcome:</strong> Acknowledge the 15% increase in customer satisfaction and set a new goal to further enhance the customer experience by introducing a feedback loop.</li>
    </ul>

    <h5>8. Encourage Development and Growth</h5>
    <p><strong>Summary:</strong> Focus on the long-term development of employees to enhance their skills and career growth.</p>
    <ul>
        <li>Identify areas for professional growth.</li>
        <li>Encourage participation in development programs (e.g., workshops, certifications).</li>
        <li>Align development goals with career aspirations.</li>
        <li>Provide opportunities for increased responsibility or leadership.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Development Plan:</strong> Enroll in a leadership development course to prepare for a future managerial role.</li>
    </ul>

    <h5>9. Adjust Plans Based on Outcomes</h5>
    <p><strong>Summary:</strong> Review the results and refine the performance planning process for future cycles.</p>
    <ul>
        <li>Analyze what worked and what didn’t.</li>
        <li>Adjust goals, KPIs, and action plans based on outcomes.</li>
        <li>Incorporate lessons learned into future planning.</li>
        <li>Stay flexible and adaptable to change.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Adjustment:</strong> Based on feedback, update the ticketing system to include a customer feedback feature that will provide more insights into service quality.</li>
    </ul>

    <h5>10. Celebrate Success</h5>
    <p><strong>Summary:</strong> Acknowledge and celebrate the achievements of employees to boost morale and motivation.</p>
    <ul>
        <li>Recognize individual and team accomplishments.</li>
        <li>Celebrate milestones and successful completion of goals.</li>
        <li>Provide rewards or incentives for outstanding performance.</li>
        <li>Share success stories within the organization.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Celebration:</strong> Host a team lunch to celebrate the successful implementation of the new ticketing system and the resulting increase in customer satisfaction.</li>
    </ul>`
      },
      {
        id: '2',
        html: `<h5>1. Align Personal Goals with Company Objectives</h5>
    <p><strong>Summary:</strong> Ensure that personal goals are in harmony with the company’s mission and strategic objectives.</p>
    <ul>
        <li>Understand the company's vision and long-term objectives.</li>
        <li>Identify how your role contributes to the overall success of the organization.</li>
        <li>Set personal goals that support and enhance company goals.</li>
        <li>Communicate your goals with your manager to ensure alignment.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Personal Goal:</strong> Improve project management skills to lead a team on a high-priority company project next year.</li>
    </ul>

    <h5>2. Make Goals SMART</h5>
    <p><strong>Summary:</strong> Develop goals that are Specific, Measurable, Achievable, Relevant, and Time-bound (SMART).</p>
    <ul>
        <li>Be specific about what you want to achieve.</li>
        <li>Determine how you will measure success.</li>
        <li>Ensure the goal is attainable given resources and time.</li>
        <li>Align the goal with your job responsibilities and company priorities.</li>
        <li>Set a clear deadline for achieving the goal.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>SMART Goal:</strong> Increase monthly sales by 10% within the next six months by improving client outreach and follow-up processes.</li>
    </ul>

    <h5>3. Break Down Goals into Actionable Steps</h5>
    <p><strong>Summary:</strong> Divide larger goals into smaller, manageable tasks to make them easier to achieve.</p>
    <ul>
        <li>Identify the key milestones needed to achieve your goal.</li>
        <li>Create a step-by-step action plan.</li>
        <li>Set deadlines for each task to stay on track.</li>
        <li>Regularly review progress and adjust the plan as needed.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Action Plan:</strong> Research and implement a new CRM tool within the first month, then use it to enhance client communication over the next three months.</li>
    </ul>

    <h5>4. Prioritize Goals</h5>
    <p><strong>Summary:</strong> Focus on the most important goals that will have the biggest impact on your personal growth and the company’s success.</p>
    <ul>
        <li>Rank goals based on their importance and urgency.</li>
        <li>Focus on high-impact goals that align with key company initiatives.</li>
        <li>Allocate time and resources to the most critical goals first.</li>
        <li>Be willing to adjust priorities as circumstances change.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Priority Goal:</strong> Complete the certification course needed to qualify for leading the new company initiative within the next quarter.</li>
    </ul>

    <h5>5. Set Both Short-term and Long-term Goals</h5>
    <p><strong>Summary:</strong> Balance immediate goals with those that support long-term career growth and company success.</p>
    <ul>
        <li>Identify goals that can be achieved in the short term (weeks to months).</li>
        <li>Set long-term goals that contribute to your career trajectory and the company’s future.</li>
        <li>Ensure short-term goals are stepping stones towards long-term objectives.</li>
        <li>Regularly revisit and refine long-term goals based on progress.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Short-term Goal:</strong> Complete the first phase of the marketing campaign within two months.</li>
        <li><strong>Long-term Goal:</strong> Become the head of the marketing department within the next five years.</li>
    </ul>

    <h5>6. Involve Stakeholders in Goal Setting</h5>
    <p><strong>Summary:</strong> Collaborate with your manager, team, and other relevant stakeholders when setting goals to ensure they are realistic and aligned with company expectations.</p>
    <ul>
        <li>Discuss your goals with your manager to get feedback and approval.</li>
        <li>Engage with team members to align goals with team objectives.</li>
        <li>Consider how your goals impact other departments or stakeholders.</li>
        <li>Be open to suggestions and adjustments based on stakeholder input.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Collaboration:</strong> Work with your manager to set a sales target that aligns with the company’s overall revenue goals for the year.</li>
    </ul>

    <h5>7. Monitor Progress and Stay Flexible</h5>
    <p><strong>Summary:</strong> Regularly track your progress and be open to adjusting your goals as circumstances change.</p>
    <ul>
        <li>Set regular check-ins to review goal progress.</li>
        <li>Adjust goals as needed based on performance or changes in company strategy.</li>
        <li>Stay flexible and adapt to new opportunities or challenges.</li>
        <li>Celebrate small wins to maintain motivation.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Monitoring:</strong> Hold monthly meetings with your manager to review sales performance and adjust strategies as needed.</li>
    </ul>

    <h5>8. Reflect and Learn from the Goal-Setting Process</h5>
    <p><strong>Summary:</strong> After completing your goals, take time to reflect on what worked, what didn’t, and how you can improve the goal-setting process in the future.</p>
    <ul>
        <li>Analyze the outcome of your goals – were they achieved? Why or why not?</li>
        <li>Identify lessons learned from both successes and failures.</li>
        <li>Use insights to set better goals in the future.</li>
        <li>Document your reflections for future reference.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Reflection:</strong> After completing the marketing campaign, review what strategies led to the most success and which areas need improvement for next time.</li>
    </ul>

    <h5>9. Adjust and Set New Goals Based on Achievements</h5>
    <p><strong>Summary:</strong> Once goals are met, set new ones to continue progressing both personally and within the company.</p>
    <ul>
        <li>Review achieved goals and celebrate successes.</li>
        <li>Set new goals that build on previous accomplishments.</li>
        <li>Ensure new goals remain aligned with company direction.</li>
        <li>Continuously challenge yourself to grow.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>New Goal:</strong> After successfully leading a project, set a goal to manage a larger team on the next company initiative.</li>
    </ul>

    <h5>10. Celebrate Successes and Share Achievements</h5>
    <p><strong>Summary:</strong> Recognize and celebrate the achievement of goals, both personally and within the company, to foster a culture of success and motivation.</p>
    <ul>
        <li>Take time to acknowledge when goals are achieved.</li>
        <li>Share successes with your team and company.</li>
        <li>Celebrate milestones to boost morale and motivation.</li>
        <li>Use achievements as a foundation for setting future goals.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Celebration:</strong> Host a team celebration after surpassing the sales target, and highlight the contributions of each team member.</li>
    </ul>`
      },
      {
        id: '3',
        html: `<h5>1. Define Clear and Specific Goals</h5>
    <p><strong>Summary:</strong> Effective goals are well-defined and clearly articulated, leaving no room for ambiguity.</p>
    <ul>
        <li>Be specific about what you want to achieve.</li>
        <li>Clearly define the desired outcome.</li>
        <li>Avoid vague language; instead, use precise terms.</li>
        <li>Ensure that anyone reading the goal understands it fully.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Specific Goal:</strong> Increase the social media engagement rate by 20% within the next quarter by posting daily and interacting with followers.</li>
    </ul>

    <h5>2. Make Goals Measurable</h5>
    <p><strong>Summary:</strong> Goals should have clear criteria for measuring progress and success.</p>
    <ul>
        <li>Include quantifiable indicators to track progress.</li>
        <li>Set benchmarks or milestones to measure achievements.</li>
        <li>Determine how you will collect data to measure your progress.</li>
        <li>Regularly review the metrics to stay on track.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Measurable Goal:</strong> Achieve a 10% increase in website traffic over the next six months by implementing a targeted SEO strategy.</li>
    </ul>

    <h5>3. Ensure Goals are Achievable</h5>
    <p><strong>Summary:</strong> Set goals that are challenging yet attainable within your current resources and capabilities.</p>
    <ul>
        <li>Consider your current situation, resources, and constraints.</li>
        <li>Set realistic goals that push you without being overwhelming.</li>
        <li>Avoid setting goals that are too easy or too difficult to achieve.</li>
        <li>Break down large goals into smaller, manageable tasks.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Achievable Goal:</strong> Complete a certification course in project management within the next three months by dedicating two hours a day to study.</li>
    </ul>

    <h5>4. Make Goals Relevant</h5>
    <p><strong>Summary:</strong> Goals should be aligned with your long-term objectives and overall purpose.</p>
    <ul>
        <li>Ensure your goals are in line with your personal or professional aspirations.</li>
        <li>Focus on goals that are important to you and your development.</li>
        <li>Avoid goals that do not contribute to your overall objectives.</li>
        <li>Review your goals regularly to ensure they remain relevant.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Relevant Goal:</strong> Develop leadership skills by leading a cross-functional project that aligns with your goal of becoming a team leader.</li>
    </ul>

    <h5>5. Set Time-bound Goals</h5>
    <p><strong>Summary:</strong> Effective goals have a clear deadline or timeframe for completion.</p>
    <ul>
        <li>Set a specific deadline for achieving your goal.</li>
        <li>Break down the goal into smaller tasks with individual deadlines.</li>
        <li>Use timeframes to create a sense of urgency and motivation.</li>
        <li>Adjust the timeline as needed based on progress and obstacles.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Time-bound Goal:</strong> Launch the new product within the next six months, with key milestones including prototype development, testing, and marketing rollout.</li>
    </ul>

    <h5>6. Write Down Your Goals</h5>
    <p><strong>Summary:</strong> Documenting your goals increases commitment and clarity.</p>
    <ul>
        <li>Write down your goals to make them tangible and real.</li>
        <li>Use clear and concise language when documenting your goals.</li>
        <li>Keep your written goals in a visible place for regular reference.</li>
        <li>Review and update your written goals as you make progress.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Documented Goal:</strong> Create a written action plan to achieve a 15% increase in customer retention by implementing a loyalty program within the next four months.</li>
    </ul>

    <h5>7. Create an Action Plan</h5>
    <p><strong>Summary:</strong> Break your goals into actionable steps to make them more manageable.</p>
    <ul>
        <li>Identify the specific tasks required to achieve your goal.</li>
        <li>Assign deadlines and responsibilities for each task.</li>
        <li>Use tools like checklists or project management software to track progress.</li>
        <li>Adjust your action plan as needed based on new information or challenges.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Action Plan:</strong> To achieve a 20% increase in online sales, outline steps such as optimizing the website, launching targeted ads, and offering promotions.</li>
    </ul>

    <h5>8. Monitor Progress and Adjust as Needed</h5>
    <p><strong>Summary:</strong> Regularly review your progress and be flexible in adjusting your goals or approach.</p>
    <ul>
        <li>Set regular check-ins to assess your progress.</li>
        <li>Use metrics and feedback to evaluate how close you are to achieving your goal.</li>
        <li>Be open to adjusting your strategy if you encounter obstacles.</li>
        <li>Celebrate milestones and reassess goals if necessary.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Progress Review:</strong> Hold monthly reviews to assess the effectiveness of the marketing campaign and make adjustments to improve performance.</li>
    </ul>

    <h5>9. Stay Committed and Motivated</h5>
    <p><strong>Summary:</strong> Maintain your commitment to achieving your goals by staying motivated and focused.</p>
    <ul>
        <li>Remind yourself regularly why the goal is important to you.</li>
        <li>Break down tasks to make progress more manageable and rewarding.</li>
        <li>Seek support from others, such as mentors or colleagues.</li>
        <li>Reward yourself for small achievements to maintain momentum.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Motivation Strategy:</strong> Set up a reward system where you treat yourself after reaching key milestones in your fitness goal.</li>
    </ul>

    <h5>10. Reflect on the Goal-Setting Process</h5>
    <p><strong>Summary:</strong> After completing your goals, take time to reflect on the process and learn from it.</p>
    <ul>
        <li>Analyze what worked well and what could be improved.</li>
        <li>Identify challenges you faced and how you overcame them.</li>
        <li>Use your reflections to set even more effective goals in the future.</li>
        <li>Document lessons learned to guide future goal-setting efforts.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Reflection:</strong> After achieving your goal of improving team collaboration, reflect on the strategies that were most effective and how they can be applied to future projects.</li>
    </ul>`
      }
    ]

    return guides.find(guide => guide.id === id);
  }
}
