import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gender-ethnicity-pay-gap-report',
  templateUrl: './gender-ethnicity-pay-gap-report.component.html',
  styleUrls: ['./gender-ethnicity-pay-gap-report.component.scss']
})
export class GenderEthnicityPayGapReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
