import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, } from '@progress/kendo-angular-grid';
import { finalize, map } from 'rxjs';

@Component({
  selector: 'app-succession-interview',
  templateUrl: './succession-interview.component.html',
  styleUrls: ['./succession-interview.component.scss']
})
export class SuccessionInterviewComponent implements OnInit {
  employees;
  isLoading: boolean;
  getRequest: any;

  successionInterviewComments = 'They enjoy working in our organization and is amenable to working at any location in Canada to further his experience.  He shows great interest in any development activities required to help him advance when the opportunity should arise.  I would recommend him as a High Performer with Potential'
  currentPositionDescription = 'Please see attached job description'
  successionInterviewBy
  successionInterviewDate = '2023-05-01T00:00:00+00:00'

  constructor(
    public translate: TranslateService,
    private employeeContactCardService: EmployeeContactCardService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees(): void {
    this.isLoading = true;

    this.getRequest = this.employeeContactCardService.getAllEmployeeContactCards(0, '100', null, null, true)
    .pipe(
      finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.employees = res.data
        this.successionInterviewBy = this.getRandomItem(this.employees)?.id
      }
    )
  }

  getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

}