import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { routes } from '@app/consts/routes';
import { features } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { FeatureService } from '@app/core/services/feature.service';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';

@Component({
  selector: 'app-succession-track',
  templateUrl: './succession-track.component.html',
  styleUrls: ['./succession-track.component.scss']
})
export class SuccessionTrackComponent implements OnInit {
    public routes: typeof routes = routes;
    navList = [
      {
        text: `Succession Profiles`,
        link: `/SuccessionTrack/SuccessionProfiles`,
        icon: 'supervised_user_circle',
        featureCode: 'allow'
      },
      {
        text: `Talent Matrix`,
        link: `/SuccessionTrack/TalentMatrix`,
        icon: 'grid_on',
        featureCode: 'allow'
      },
        // {
        //     text: this.translate.instant('Positions'),
        //     link: `${routes.SITE_SETTINGS}${routes.POSITIONS}`,
        //     icon: 'badge',
        //     featureCode: 'Positions'
        // },
        // {
        //     text: this.translate.instant('CostCenters'),
        //     link: `${routes.SITE_SETTINGS}${routes.COST_CENTERS}`,
        //     icon: 'attach_money',
        //     featureCode: features.COST_CENTERS
        // },
        // {
        //     text: this.translate.instant('Lists'),
        //     link: `${routes.SITE_SETTINGS}${routes.LISTS}`,
        //     icon: 'edit_note',
        //     featureCode: 'Lists'
        // },
        // {
        //     text: this.translate.instant('tbl_WorkLocations'),
        //     link: `${routes.SITE_SETTINGS}${routes.WORK_LOCATIONS}`,
        //     icon: 'home_work',
        //     featureCode: 'WorkLocations'
        // },
        // {
        //     text: this.translate.instant('WorkRotations'),
        //     link: `${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}`,
        //     icon: 'pending_actions',
        //     featureCode: 'WorkRotations',
        //     module: modules.TIME_OFF
        // },
        // {
        //     text: this.translate.instant('TimeOff'),
        //     link: `${routes.SITE_SETTINGS}${routes.TIME_OFF}`,
        //     icon: 'alarm',
        //     featureCode: features.TIME_OFF,
        //     module: modules.TIME_OFF
        // },
        // {
        //     text: this.translate.instant('StatutoryHolidays'),
        //     link: `${routes.SITE_SETTINGS}${routes.STATUTORY_HOLIDAYS}`,
        //     icon: 'wb_sunny',
        //     featureCode: features.TIME_OFF,
        //     module: modules.TIME_OFF
        // },
        // {
        //     text: this.translate.instant('Forms'),
        //     link: `${routes.SITE_SETTINGS}${routes.FORMS}`,
        //     icon: 'library_books',
        //     featureCode: 'Forms'
        // },
        // {
        //     text: this.translate.instant('Configure'),
        //     link: `${routes.SITE_SETTINGS}${routes.CONFIGURE}`,
        //     icon: 'settings',
        //     featureCode: features.SITE_SETTINGS
        // },
        // {
        //     text: this.translate.instant('Currency'),
        //     link: `${routes.SITE_SETTINGS}${routes.CURRENCY}`,
        //     icon: 'attach_money',
        //     featureCode: 'Currency'
        // },
        // {
        //     text: this.translate.instant('Translations'),
        //     link: `${routes.SITE_SETTINGS}${routes.TRANSLATIONS}`,
        //     icon: 'translate',
        //     featureCode: features.GLOBALIZATION
        // },
        // {
        //     text: this.translate.instant('CountryConfig'),
        //     link: `${routes.SITE_SETTINGS}${routes.COUNTRY_CONFIG}`,
        //     icon: 'public',
        //     featureCode: features.COUNTRY_CONFIG
        // },
        // {
        //     text: this.translate.instant('SchedulesAdministration'),
        //     link: `${routes.SITE_SETTINGS}${routes.GENERATE_SCHEDULE}`,
        //     icon: 'calendar_month',
        //     featureCode: features.SCHEDULES,
        //     module: modules.TIME_OFF
        // },
        // {
        //     text: this.translate.instant('TableFieldEditor'),
        //     link: `${routes.SITE_SETTINGS}${routes.TABLE_FIELD_EDITOR}`,
        //     icon: 'edit_note',
        //     featureCode: 'allow'
        // },
        // {
        //     text: this.translate.instant('Letters'),
        //     link: `${routes.SITE_SETTINGS}${routes.LETTERS}${routes.LETTERS_LIST}`,
        //     icon: 'history_edu',
        //     featureCode: 'allow'
        // },
        // {
        //     text: this.translate.instant('Journeys'),
        //     link: `${routes.SITE_SETTINGS}${routes.WORKFLOWS}${routes.WORKFLOWS_LIST}`,
        //     icon: 'checklist_rtl',
        //     featureCode: 'allow'
        // },
        // {
        //     text: this.translate.instant('Surveys'),
        //     link: `${routes.SITE_SETTINGS}${routes.SURVEYS}`,
        //     icon: 'poll',
        //     featureCode: 'allow'
        // },
        // {
        //     text: this.translate.instant('Notifications'),
        //     link: `${routes.SITE_SETTINGS}${routes.NOTIFICATIONS}`,
        //     icon: 'email',
        //     featureCode: 'allow',
        // },
        // {
        //     text: this.translate.instant('Documents'),
        //     link: `${routes.SITE_SETTINGS}${routes.DOCUMENTS}`,
        //     icon: 'description',
        //     featureCode: 'allow'
        // },
        // {
        //     text: this.translate.instant('Integrations'),
        //     link: `${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.INTEGRATIONS_LIST}`,
        //     icon: 'integration_instructions',
        //     featureCode: 'allow'
        // },
        // {
        //     text: this.translate.instant('Data Trigger Rules'),
        //     link: `${routes.SITE_SETTINGS}${routes.DATA_TRIGGER_RULES}`,
        //     icon: 'data_thresholding',
        //     featureCode: 'allow'
        // },
        // {
        //     text: "Job Management",
        //     link: `${routes.SITE_SETTINGS}${routes.JOB_MANAGEMENT}`,
        //     icon: 'manage_accounts',
        //     featureCode: 'allow'
        // },
        // {
        //     text: "Import Data",
        //     link: `${routes.SITE_SETTINGS}${routes.IMPORT_DATA}${routes.DATA_TEMPLATE_DOWNLOAD}`,
        //     icon: 'upload_file',
        //     featureCode: 'allow'
        // },

        
    ];

    constructor(
        private translate: TranslateService,
        public router: Router,
        private featureService: FeatureService,
        private moduleConfigurationService: ModuleConfigurationService,
    ) {
    }

    ngOnInit(): void {
        this.navigateToFirstAllowedSection();
    }

    navigateToFirstAllowedSection() {
        let urlToNavigateTo = '';

        if(this.router.url === `${routes.SITE_SETTINGS}`) {
          this.navList.every(element => {
            let hasAccess = this.featureService.hasFeatureAccess(element.featureCode)

            if(hasAccess){
              urlToNavigateTo = element.link;
              return false;
            }

            return true;
          });
        }

        if(urlToNavigateTo !== ''){
          this.router.navigate([urlToNavigateTo]);
        }
    }
}
