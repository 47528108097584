import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SuccessionTrackService } from '@app/modules/succession-track/services/succession-track.service';
import { RowActionButtonInput, RowActionEventOutput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize, map } from 'rxjs';
// import { Integration } from '../../models/integration.model';

@Component({
  selector: 'app-succession-successors',
  templateUrl: './succession-successors.component.html',
  styleUrls: ['./succession-successors.component.scss']
})
export class SuccessionSuccessorsComponent implements OnInit {
  @Input() successors;
  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'successor', 
      title: 'Successor',
      type: "employee"
    }, 
    {
      field: 'readiness', 
      title: 'Readiness'
    }, 
    {
      field: 'nominatedBy', 
      title: 'Nominated By',
      type: "employee"
    },
    {
      field: 'comments', 
      title: 'Comments'
    },  
  ];


  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: string = '0';
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  employees;
  
  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.gridDataResult = {
      data: this.successors,
      total: this.successors.length,
    }
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToSuccessionProfile(employee) {
    // if(employee){
    //   this.router.navigate([`${routes.SUCCESSION_TRACK}${routes.SUCCESSION_PROFILE}/${employee?.name?.id}`], {relativeTo: this.route});
    // }
    // else {
    //   this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}`], {relativeTo: this.route});
    // }
  }

  rowActionButtonClicked($event){
    if($event.id === 'analytics') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ANALYTICS}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
    else if($event.id === 'error-log') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ERROR_LOG}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
  }

  pageChange(event){}

}
