<ng-container *ngIf="loadingEmployeeDetails || loadingPosition || isLoadingCompensation; else loaded">
    <ngx-skeleton-loader
        count="5"
        [theme]="{ 
            'height.px': 50
        }"
    ></ngx-skeleton-loader>
</ng-container>
<ng-template #loaded>
    <kendo-pdf-export #pdf>
        <div class="letter" >
            <div class="header">
                <ng-container *ngIf="this.companyLogosService.activeCompanyLogo !== undefined; else noLogo">
                    <img 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                style: 'background'
                            }
                        ]" 
                        style="object-fit: none;"
                        class="hubbub-logo" [src]="this.companyLogosService.activeCompanyLogo" alt="logo"/>
                </ng-container>
                <ng-template #noLogo>
                    <img 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                style: 'background'
                            }
                        ]" 
                        style="object-fit: none;"
                        class="hubbub-logo" [src]="this.environmentService.env.customLogoPath ? this.environmentService.env.customLogoPath : 'assets/HubbubHrLogo.svg'" alt="logo"/>
                </ng-template>
    
                <div>
                    <p>Hubbub HR</p>
                    <p>408 - 55 Water Street,</p>
                    <p>Vancouver, BC,</p>
                    <p>Canada, V6B 1A1</p>
                </div>
                
            </div>
            
            <div class="body">
                <p class="space"><b>{{ moment().format('YYYY-MM-DD') | date:'longDate' }}</b></p>
                <p class="space">To whom it may concern,</p>
                <p>This letter is official verification of <b>{{ employeeDetails?.firstname }} {{ employeeDetails?.lastname }}'s</b> employment at Hubbub HR.</p>
                <p>Below are additional details regarding their employment</p>
        
                <ul>
                    <ng-container *ngIf="positions && positions.length > 0">
                        <li>Date of employment: {{ positions[0].startDate | date:'longDate' }} - Present</li>
                        <ng-container *ngIf="positions[0].positionDetails | async as positionDetails">
                            <li>Job Title: {{ getPositionName(positionDetails) }}</li>
                        </ng-container>
                    </ng-container>
                    <li *ngIf="isLoadingCompensation === false && compensation.salarySystemCurrency?.id && compensation.salarySystem">Salary: {{compensation.salarySystemCurrency?.id | currencyDisplay: compensation.salarySystem}}</li>
                </ul>
        
                <p>If you need any supplementary details, contact me at <b>hr@hubbubhr.com</b> or <b>555-555-5555</b> for more information.</p>
            </div>
        
            <div class="footer">
                <div>
                    <p>Sincerely,</p>
                    <p>Vivien Rivera</p>
                    <p><b>Human Resources Manager</b></p>
                </div>
            </div>
        </div>
    
    </kendo-pdf-export>

    <button mat-stroked-button class="export-to-pdf-button" (click)="pdf.saveAs('invoice.pdf')" appLocalizationPopupDirective localizationCode="ExportToExcel">
        <img
            class="ms-icon"
            src="assets/icons/pdf-icon.svg"
            style="width: 30px;
                        height: 30px;
                        vertical-align: middle;">
        {{ 'SaveAsPdf' | translate: {Default: "Save As PDF"} }}
    </button>
</ng-template>
