import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CheckDeviceService } from '@app/core/services/check-device/check-device.service';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { TimeOffService } from '@app/modules/time-off/services/time-off.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import * as moment from 'moment';
import { Observable, isObservable, of, startWith } from 'rxjs';
import { MyTimeOffRequestDialogComponent } from './components/my-time-off-request-dialog/my-time-off-request-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { api_routes, db_tables } from '@app/consts';

@Component({
  selector: 'app-my-time-off-requests',
  templateUrl: './my-time-off-requests.component.html',
  styleUrls: ['./my-time-off-requests.component.scss']
})
export class MyTimeOffRequestsComponent implements OnInit {
  @Input() refreshMyTimeOffRequest: boolean;


  // data grid stuff
  public columns: any[] = [
    { field: "timeOffType", subField:"name", title: "Time Off Type", type: "navigation", tableId: "tfi_TimeOffType"},
    { field: "startDate", title: "Start Date", type: "date", tableId: "tfi_AbsenceStartDate"},
    { field: "endDate", title: "End Date", type: "date", tableId: "tfi_AbsenceEndDate"},
    { field: "startTime", title: "Start Time", tableId: "tfi_AbsenceStartTime"},
    { field: "endTime", title: "End Time", tableId: "tfi_AbsenceEndTime"},
    { field: "hours", title: "Hours", tableId: "tfi_AbsenceHours"},
    { field: "managers", title: "Managers", type: "employees" }
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_PGpName", title: "Name", type: "String", dataType: 'String'},
    { field: "description", tableId: "tfi_PGpDescription", title: "Description", type: "String", dataType: 'String'},
    { field: "lockGoals", tableId: "tfi_PGpLockGoals", title: this.translate.instant("GoalPlan-LockGoals"), dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "permitEmployeeGoalEntry", tableId: "tfi_PGpPermitEmployeeGoalEntry", title: this.translate.instant("GoalPlan-PermitEmployeeGoalEntry"), dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "permitEmployeeChildGoalEntry", tableId: "tfi_PGpPermitEmployeeChildGoalEntry", title: this.translate.instant("GoalPlan-PermitEmployeeChildGoalEntry"), dataType: "ProvidedList", listOptions: {
      list: [{id: true, name: 'True'}, {id: false, name: 'False'},],
      id: 'id',
      name: 'name'
    }},
    { field: "createdBy", tableId: "", title: this.translate.instant("GoalPlan-CreatedBy"), type: "String", dataType: 'String', filterValue: ["CreatedBy.FirstName", "CreatedBy.LastName"]},
    { field: "createdOn", tableId: "", title: this.translate.instant("GoalPlan-CreatedOn"), type: "date", dataType: 'Date'},
    { field: "lastPublishedOn", tableId: "", title: this.translate.instant("GoalPlan-LastPublishedOn"), type: "date", dataType: 'Date'},
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "description", sortValue: "description" },
    { field: "permitEmployeeGoalEntry", sortValue: "permitEmployeeGoalEntry" },
    { field: "permitEmployeeChildGoalEntry", sortValue: "permitEmployeeChildGoalEntry" },
    { field: "lockGoals", sortValue: "lockGoals" },
    { field: "createdBy", sortValue: ["Version.CreatedBy.FirstName", "Version.CreatedBy.LastName"] },
    { field: "createdOn", sortValue: "Version.CreatedOn" },
    { field: "lastPublishedOn", sortValue: "lastPublishedOn" },
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  searchValue: string;
  searchFilterString: string;
  getGoalsPlansRequest: any;
  sortString: string;
  filterString: string;




  
  leaveRequests$: any;
  public selectedFilter;
  firstrun : boolean = true;
  isMobile: boolean;
  selectedStatusFilters = [];
  statusOptions = [
    {id: "trs_Pending", name: 'Pending'},
    {id: "trs_Cancelled", name: 'Cancelled'},
    {id: "trs_Approved", name: 'Approved'},
    {id: "trs_Denied", name: 'Denied'}
  ]

  filterOptions = [
    {
      value: '3',
      text: 'Last3Months'
    },
    {
      value: '6',
      text: 'Last6Months'
    },
    {
      value: '12',
      text: 'Last12Months'
    },
    {
      value: '',
      text: 'All'
    },
  ]

  constructor(
    private workflowsAbsencesService: WorkflowsAbsencesService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private checkDeviceService: CheckDeviceService

  ) {
    this.checkDeviceService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.selectedFilter = this.filterOptions[0];
  }

  ngOnInit(): void {
    this.getLeaveRequests();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refreshMyTimeOffRequest && changes.refreshMyTimeOffRequest.currentValue !== undefined){
      if(this.firstrun) {
        this.firstrun = false;
      }
      else {
        this.getLeaveRequests()
      }
    }
  }

  getLeaveRequests() {
    this.workflowsAbsencesService.getMyRequests(0, 100, `version.createdOn-desc`, this.buildFilterString())
    .subscribe(
      res => {
        if(res instanceof Observable){
          res.subscribe(
            r => {
              this.gridDataResult = {
                data: r.data,
                total: r.total,
              }
              this.gridData = r.data;

              this.leaveRequests$ = res;
            }
          )
        }
        else {
          this.leaveRequests$ = of(res);
        }
      }
    );
  }

  buildFilterString(): string {
    let filterString;
    let statusOptionString;


    if(this.selectedFilter !== "") {
      filterString = `(from > "${moment().subtract(parseInt(this.selectedFilter.value), 'months').format()}")`
    }

    this.selectedStatusFilters.forEach(
      option => {
        if(statusOptionString !== undefined){
          statusOptionString = `${statusOptionString} OR (RequestStatus.Id = "${option.id}")`
        }
        else {
          statusOptionString = `(RequestStatus.Id = "${option.id}")`
        }
      }
    )

    if(filterString !== undefined && statusOptionString !== undefined){
      filterString = `${filterString} AND (${statusOptionString})`;
    }
    else if(statusOptionString !== undefined){
      filterString = `(${statusOptionString})`;
    }

    return filterString;
  }
  
  openLeaveRequestDialog(timeOffLeaveRequest: MyRequest) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = this.isMobile ? 0 : 500;

    dialogConfig.data = {
      timeOffRequest: timeOffLeaveRequest
    };

    const dialogRef = this.dialog.open(MyTimeOffRequestDialogComponent, dialogConfig);
  }

  getSelectedStatusesText(): string {
    const selectedStatuses = this.selectedStatusFilters || [];
    return selectedStatuses.map((status: any) => this.translate.instant(status.name)).join(', ');
  }
}
