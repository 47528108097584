import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '../overlay/overlay.service';

@Component({
  selector: 'app-survey-generator',
  templateUrl: './survey-generator.component.html',
  styleUrls: ['./survey-generator.component.scss']
})
export class SurveyGeneratorComponent implements OnInit {
  @Input() surveySettings;

  showForm: boolean = true;
  showTickAnimation: boolean = false;
  selectedQuestion: number = 0;

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
  }

  get formProgress() {
    return (this.selectedQuestion/(this.surveySettings.questions.length-1)) * (100/1);
  }

  nextQuestion() {
    this.selectedQuestion = this.selectedQuestion + 1;
  }

  previousQuestion() {
    this.selectedQuestion = this.selectedQuestion - 1;
  }

  submit() {
    this.overlayService.show();

    this.showForm = false;
    
    setTimeout(() => {
      this.showTickAnimation = true;
      this.overlayService.hide();
    }, 500)
  }

}
