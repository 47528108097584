import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs';
import { EmployeeProfilePhotoService } from '../../services/employee-profile-photo.service';

@Component({
  selector: 'app-upload-profile-image-comp',
  templateUrl: './upload-profile-image-comp.component.html',
  styleUrls: ['./upload-profile-image-comp.component.scss']
})
export class UploadProfileImageCompComponent implements OnInit {
  @Input() employeeID: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  wrongFileTypeUploaded: boolean = false;

  constructor(
    private translate: TranslateService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private imageService: EmployeeProfilePhotoService,
    private route: ActivatedRoute,
  ) { 
    // this.route.paramMap.subscribe(
    //     params => this.employeeID = params.get("id")
    // ) 
  }

  ngOnInit(): void { }

  //return a promise that resolves with a File instance
  urltoFile(url, filename, mimeType){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  upload() {
    this.overlayService.show();

    this.urltoFile(this.croppedImage, `${this.employeeID}-Profile-Picture.${base64ToFile(this.croppedImage).type}`, base64ToFile(this.croppedImage).type)
    .then((file) => { 
      this.imageService.uploadProfilePhoto(file, this.employeeID)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        () => {
          this.snackbarService.openSnackBar(this.translate.instant('SavedSuccessfully'), 'clear', 'success');
        }
      );
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded() {
      this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.snackbarService.openSnackBar(this.translate.instant('IncorrectProfileImageFileType'), 'clear', 'error');
  }

  rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }


  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
      this.transform = {
          ...this.transform,
          rotate: this.rotation
      };
  }
}
