<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5>{{mapping?.name}}</h5>
    </mat-card-title>

    <mat-card-content>
        <div class="top mapping">

                <mat-accordion>
                  <mat-expansion-panel *ngFor="let path of apiPaths; let first = first" [expanded]="first">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-checkbox [(ngModel)]="path.selected"></mat-checkbox>
                        {{ path.path }}
                      </mat-panel-title>
                      <mat-panel-description>
                        {{ path.description || 'No description available' }}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
              
                    <!-- Expansion Panel Content -->
                    <div>
              
                      <!-- Display more details for the path -->
                      <div>
                        <h5>{{ path.summary }}</h5> 
                        <!-- <h6>Methods:</h6> -->


                        <mat-accordion>
                            <mat-expansion-panel *ngFor="let method of path.methods; let first = first" [expanded]="first">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-checkbox></mat-checkbox>

                                    <mat-chip-list>
                                        <mat-chip class="green-chip" *ngIf="method.method === 'post'">POST</mat-chip>
                                        <mat-chip class="blue-chip" *ngIf="method.method === 'put'">PUT</mat-chip>
                                        <mat-chip class="red-chip" *ngIf="method.method === 'delete'">DELETE</mat-chip>
                                        <mat-chip class="yellow-chip" *ngIf="method.method === 'get'">GET</mat-chip>
                                    </mat-chip-list>

                                    {{ path.path }}
                                </mat-panel-title>
                                <mat-panel-description>
                                  {{ method.description || 'No description available' }}
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                        
                              <!-- Expansion Panel Content -->
                              <div>
                                <div class="field-container">
                                    <!-- <div class="instructions page-description-section">Click on API field to view mapped field</div> -->
                                    <div class="code-container">
                                        {{'{'}}
                                        <div class="field" *ngFor="let field of method?.fields">
                                            <div (click)="selectField(field)">
                                                "{{field.text}}" : valueType
                                            </div>
                                        </div>
                                        {{'}'}}
                                    </div>
                                </div>
                              </div>
                            </mat-expansion-panel>
                        </mat-accordion>

                        <!-- <ul>
                          <li *ngFor="let method of path.methods">
                            <mat-checkbox></mat-checkbox>

                            <mat-chip-list>
                                <mat-chip class="green-chip" *ngIf="method.method === 'post'">POST</mat-chip>
                                <mat-chip class="blue-chip" *ngIf="method.method === 'put'">PUT</mat-chip>
                                <mat-chip class="red-chip" *ngIf="method.method === 'delete'">DELETE</mat-chip>
                                <mat-chip class="yellow-chip" *ngIf="method.method === 'get'">GET</mat-chip>
                            </mat-chip-list>
                            <h6>{{ path.path }}</h6>
                            {{ method.description || 'No description' }}

                            
                          </li>
                        </ul> -->
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>

            
            
            
            
            
            <!-- <div class="categories">
                <mat-expansion-panel class="category-expansion" expanded="true" *ngFor="let category of mapping?.categories">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <mat-checkbox [(ngModel)]="category.selected" (click)="$event.stopPropagation();" (change)="categoryCheckboxChange($event, category)"></mat-checkbox>

                        /{{category?.name}}
                      </mat-panel-title>
                      <mat-panel-description>
                        {{category?.description}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>

                    <mat-accordion class="example-headers-align" multi>
                        <mat-expansion-panel class="route-expansion" *ngFor="let route of category?.routes">
                            <mat-expansion-panel-header>
                              <mat-panel-title>
                                <mat-checkbox [(ngModel)]="route.selected" (click)="$event.stopPropagation();"></mat-checkbox>

                                <mat-chip-list>
                                    <mat-chip class="green-chip" *ngIf="route?.type === 'post'">POST</mat-chip>
                                    <mat-chip class="blue-chip" *ngIf="route?.type === 'put'">PUT</mat-chip>
                                    <mat-chip class="red-chip" *ngIf="route?.type === 'delete'">DELETE</mat-chip>
                                    <mat-chip class="yellow-chip" *ngIf="route?.type === 'patch'">PATCH</mat-chip>
                                </mat-chip-list>

                                /{{route?.route}}
                              </mat-panel-title>
                              <mat-panel-description>
                                {{route?.summary}}
                              </mat-panel-description>
                            </mat-expansion-panel-header>
    
                            <div class="route-description" [innerHTML]="route?.description"></div>
    
                            <div class="field-container">
                                <div class="instructions page-description-section">Click on API field to view mapped field</div>
                                <div class="code-container">
                                    {{'{'}}
                                    <div class="field" *ngFor="let field of route?.fields">
                                        <div (click)="selectField(field)">
                                            "{{field.text}}" : valueType
                                        </div>
                                    </div>
                                    {{'}'}}
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-expansion-panel>
            </div>

            <div class="selected-field-container" *ngIf="selectedField">
                <h5>Mapped Field Details</h5>

                <div class="step">
                    <h6>Step 1: Choose Table</h6>
    
                    <ng-container *ngIf="loadingTables; else loadedTables">
                        <ngx-skeleton-loader
                            count="1"
                            [theme]="{ 
                            'height.px': 50,
                            'width.px': 300
                            }"
                        ></ngx-skeleton-loader>
                    </ng-container>
    
                    <ng-template #loadedTables>
                        <mat-form-field appearance="fill">
                            <mat-label>Table</mat-label>
                            <mat-select disableOptionCentering [(value)]="selectedTable" (valueChange)="handleSelectTableClick($event)" class="mat-form-field">
                                <mat-option value="">-- None --</mat-option>
                
                                <mat-optgroup *ngFor="let group of tableGroups" [label]="group.groupName">
                                    <mat-option *ngFor="let table of group.tables" [value]="table">
                                        {{table.name}}
                                    </mat-option>
                                </mat-optgroup>
                
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                    
                </div>
    
                <div class="step" *ngIf="selectedTable != null">
                    <h6>Step 2: Choose Field</h6>
    
                    <ng-container *ngIf="loadingFields; else loadedFields">
                        <ngx-skeleton-loader
                            count="1"
                            [theme]="{ 
                            'height.px': 50,
                            'width.px': 300
                            }"
                        ></ngx-skeleton-loader>
                    </ng-container>
    
                    <ng-template #loadedFields>
                        <mat-form-field appearance="fill">
                            <mat-label>Fields</mat-label>
                            <mat-select disableOptionCentering [(value)]="selectedField" (valueChange)="handleSelectFieldClick($event)">
                                <mat-option>-- None --</mat-option>
            
                                <mat-option *ngFor="let field of fieldList" [value]="field">
                                    {{field.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
    
                </div>

                <div class="bottom">
                    
                    <ng-container *ngIf="loadingTableField; else loadedTableField">
                        <div style="width:100%; padding: 10px;">
                            <ngx-skeleton-loader
                              count="5"
                              [theme]="{ 
                                'height.px': 50
                              }"
                            ></ngx-skeleton-loader>
                        </div>
                    </ng-container>
                    
                    <ng-template #loadedTableField>
                        <div *ngIf="selectedFieldVerbose && !loadingTableField">
        
                            <app-form-generator 
                            [formId]="formId"
                            [formData]="formData"
                            readOnly="true"
                            [emitFormDataTrigger]="getFormData"
                            (emitFormData)="formDataEmitted($event)"
                            (emitFormStatus)="formStatusUpdated($event)"
                            ></app-form-generator>

                            <form class="mapping-properties-container" [formGroup]="integrationDetails">
                                <h6>Mapping Properties</h6>

                                <mat-form-field appearance="fill">
                                    <mat-label>Integration Field Name</mat-label>
                            
                                    <input matInput type="text" formControlName="integrationFieldName">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Field Location</mat-label>
                            
                                    <input matInput type="text" formControlName="fieldLocation">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Response Location</mat-label>
                            
                                    <input matInput type="text" formControlName="responseLocation">
                                </mat-form-field>
                            </form>
                        </div>
                    </ng-template>
                </div>
            </div> -->









            

            <!-- {
                id: '32714rny890f7yn',
                name: 'Zellis Mapping',
                description: '',
                categories: [
                  {
                    id: 'Workers',
                    name: 'Workers',
                    description: 'Worker REST API',
                    routes: [
                      {
                        type: 'put',
                        route: 'Workers',
                        summary: 'Create or update Worker',
                        description: 'Creating a new or updating an existing Worker record. Use of this method will wholly replace the existing record with a new one. Therefore empty/null fields will overwrite existing populated items.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
                        fields: [
                          {
                            text: 'WorkerNumber',
                            valueType: 'string',
                            mappedTo: {
                                table: 'tbl_Employees',
                                field: 'tfi_EmployeeId'
                            }
                          },
                          {
                            text: 'KnownAs',
                            valueType: 'string',
                            mappedTo: {
                                table: 'tbl_Employees',
                                field: 'tfi_PreferredName'
                            }
                          },
                          {
                            text: 'Surname',
                            valueType: 'string',
                            mappedTo: {
                                table: 'tbl_Employees',
                                field: 'tfi_LastName'
                            }
                          },
                          {
                            text: 'PreviousSurname',
                            valueType: 'string',
                            mappedTo: {
                                table: 'tbl_Employees',
                                field: 'tfi_MaidenName'
                            }
                          },
                          {
                            text: 'BirthDate',
                            valueType: 'string',
                            mappedTo: {
                                table: 'tbl_Employees',
                                field: 'tfi_Birthday'
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              } -->








            
        </div>
    </mat-card-content>
</mat-card>