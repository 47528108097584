import { Component, OnInit } from '@angular/core';
import { GoalsGuidesService } from '../../services/goals-guides.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GoalsGuideDialogComponent } from '../goals-guide-dialog/goals-guide-dialog.component';

export class GoalGuide {
  id: string;
  title: string;
  description: string;
  image: string;
}

@Component({
  selector: 'app-goals-guides',
  templateUrl: './goals-guides.component.html',
  styleUrls: ['./goals-guides.component.scss']
})
export class GoalsGuidesComponent implements OnInit {
  guides: GoalGuide[] = [];

  constructor(
    private dialog: MatDialog,
    private goalGuidesService: GoalsGuidesService
  ) { }

  ngOnInit(): void {
    this.getGuides();
  }

  getGuides() {
    this.goalGuidesService.getGuides().subscribe((guides) => {
      this.guides = guides;
    });
  }

  openGuideDialog(guide: GoalGuide) {
    let guideHtml = this.goalGuidesService.getGuide(guide.id);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      guideTitle: guide.title,
      htmlContent: guideHtml.html
    };

    const dialogRef = this.dialog.open(GoalsGuideDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

}
