<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
      <h4 class="title">Recent Activity</h4>

      <!-- <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <mat-select [(ngModel)]="birthdayTimePeriodSearchValue">
          <mat-option value="thisMonth">This Month</mat-option>
          <mat-option value="thisWeek">This Week</mat-option>
        </mat-select>
      </mat-form-field> -->
    </mat-card-title>

    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="employee" role="listitem" *ngFor="let employee of employees">
                    <!-- <div class="task-details">
                        <div>{{employee.task.task | uppercase}}</div>
                        <div class="date">Due by: {{employee.taskDueDate | date:'mediumDate'}}</div>
                    </div> -->
                    <div class="employee-details">
                        <app-employee-image class="profile-pic" round="true" width="50" height="50" [employeeId]="employee.id"></app-employee-image>
                        <div class="details" (click)="handleClick(employee.task)" [ngClass]="employee.task.task == 'Review' ? 'pointer' : '' ">
                            <span>
                                {{employee.task.description}}
                            </span>
                            <span class="assigned-by">
                                {{employee.taskDueDate | date:'mediumDate'}}
                            </span>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-card-content>
</mat-card>
