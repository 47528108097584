import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Letter } from '@app/modules/letter-generator/models/leter.model';

@Component({
  selector: 'app-letter-selection-dialog',
  templateUrl: './letter-selection-dialog.component.html',
  styleUrls: ['./letter-selection-dialog.component.scss']
})
export class LetterSelectionDialogComponent implements OnInit {

  letters: Letter[];
  selectedItems = [];

  constructor(
    private dialogRef: MatDialogRef<LetterSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.letters = data.letters;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  add() {
    this.dialogRef.close(this.selectedItems[0]);
  }

}
