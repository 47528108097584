<kendo-grid
      [kendoGridBinding]="gridView"
      kendoGridSelectBy="id"
      [selectedKeys]="mySelection"
      (selectedKeysChange)="emitSelectedRecipients()"
      [pageSize]="20"
      [pageable]="true"
      [sortable]="true"
      [groupable]="false"
      [reorderable]="false"
      [resizable]="true"
      [height]="700"
      [columnMenu]="{ filter: true }"
    >
      <kendo-grid-checkbox-column
        [width]="45"
        [headerClass]="{ 'text-center': true }"
        [class]="{ 'text-center': true }"
        [resizable]="false"
        [columnMenu]="false"
        showSelectAll="true"
      ></kendo-grid-checkbox-column>
      <kendo-grid-column-group title="Employee" [columnMenu]="false">
        <kendo-grid-column field="full_name" title="Contact Name" [width]="220">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="customer-name">{{ dataItem.full_name }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="job_title" title="Job Title" [width]="220">
        </kendo-grid-column>
        <kendo-grid-column
          field="country"
          title="Country"
          [width]="100"
          [class]="{ 'text-center': true }"
          [resizable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <img class="flag" [src]="flagURL(dataItem)" width="30" />
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="is_online"
          title="Status"
          [width]="100"
          [class]="{ 'text-center': true }"
          [resizable]="false"
          filter="boolean"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              *ngIf="dataItem.is_online === true"
              class="badge badge-success"
              >Online</span
            >
            <span
              *ngIf="dataItem.is_online === false"
              class="badge badge-danger"
              >Offline</span
            >
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
      <kendo-grid-column-group title="Contacts" [columnMenu]="false">
        <kendo-grid-column field="phone" title="Phone" [width]="130">
        </kendo-grid-column>
        <kendo-grid-column field="address" title="Address" [width]="200">
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>