import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';

@Component({
  selector: 'app-mass-import-export',
  templateUrl: './mass-import-export.component.html',
  styleUrls: ['./mass-import-export.component.scss']
})
export class MassImportExportComponent implements OnInit {
  public routes: typeof routes = routes;
  navList = [
    {
      text: 'Template Download',
      link: `${routes.SITE_SETTINGS}${routes.IMPORT_DATA}${routes.DATA_TEMPLATE_DOWNLOAD}`,
      icon: 'file_download'
    },
    {
      text: 'Template Upload',
      link: `${routes.SITE_SETTINGS}${routes.IMPORT_DATA}${routes.DATA_TEMPLATE_UPLOAD}`,
      icon: 'file_upload'
    },
    {
      text: 'Instructions',
      link: `${routes.SITE_SETTINGS}${routes.IMPORT_DATA}${routes.INSTRUCTIONS}`,
      icon: 'integration_instructions'
    },
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
