import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {api_routes} from '@app/consts';
import {EmployeeBankDetails} from '../models/employee-bank-details.model';
import {EnvironmentService} from "@app/core/services/environment.service";
import { PagedData } from '@app/modules/lookup/models/paged-data.model';


const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeBankDetailsService {
  bankdetails = [
    {
        "id": "usr_Q58GDL7caiRl0A",
        "bankAccountName": "John Doe",
        "bankSortCode": "04-00-04",
        "bankAccountNumber": "12345678",
        "buildingSocietyRollNo": "BS12345",
        "bankName": "HSBC Bank",
        "iban": "GB29NWBK60161331926819",
        "swift": "HBUKGB4B",
        "big": "HSBCUKL",
        "percentage": 0.4,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    },
    {
        "id": "usr_KL9TX73EiAQR7B",
        "bankAccountName": "Jane Smith",
        "bankSortCode": "20-19-97",
        "bankAccountNumber": "87654321",
        "buildingSocietyRollNo": "BS54321",
        "bankName": "Barclays Bank",
        "iban": "GB33BUKB20201555555555",
        "swift": "BUKBGB22",
        "big": "BARCGB22",
        "percentage": 0.2,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    },
    {
        "id": "usr_LK7JN98OciQW3C",
        "bankAccountName": "Alice Brown",
        "bankSortCode": "40-47-84",
        "bankAccountNumber": "98765432",
        "buildingSocietyRollNo": "BS67890",
        "bankName": "Lloyds Bank",
        "iban": "GB85LOYD30963444966369",
        "swift": "LOYDGB2L",
        "big": "LOYDGB2L",
        "percentage": 0,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    },
    {
        "id": "usr_JL5UZ20PmiQX8D",
        "bankAccountName": "Michael Green",
        "bankSortCode": "30-99-99",
        "bankAccountNumber": "11223344",
        "buildingSocietyRollNo": "BS11223",
        "bankName": "Santander Bank",
        "iban": "GB18ABBY09012712345678",
        "swift": "ABBYGB2L",
        "big": "ABBYGB2L",
        "percentage": 0.4,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    }
  ]

  constructor(private http: HttpClient, private envService: EnvironmentService) {}

  getBankDetails(employeeId: string, asOf?: string): Observable<PagedData<any>> {
    return of(
      {
        "data":this.bankdetails,
        "skip":0,
        "take":20,
        "total":this.bankdetails.length
     }
    )

    // let params = new HttpParams();
    // asOf ? params = params.append('AsOf', asOf) : null;
    
    // return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.BANK_DETAIL}`, {
    //   headers: headers,
    //   params: params
    // });
  }

  updateBankDetails(employeeId: string, employeeBankDetailsSubmit: any): Observable<any> {
    return this.http.put<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.BANK_DETAIL}`, employeeBankDetailsSubmit, {
      headers: headers
    });
  }
}
