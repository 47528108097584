import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SelectEmployeesDialogComponent } from '@app/shared/components/select-employees-dialog/select-employees-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { GoalPlan, GoalPlanSubmit, GoalPlanVerbose } from "@app/modules/performance/components/goal-plans/models/goal-plan.model";
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { AddReviewerDialogComponent } from "@app/modules/performance/components/reviews/components/add-reviewer-dialog/add-reviewer-dialog.component";
import { defer, forkJoin } from "rxjs";
import { EmployeeService } from "@app/core/services/employee.service";

@Component({
    selector: 'app-review-editor',
    templateUrl: './review-editor.component.html',
    styleUrls: ['./review-editor.component.scss']
})
export class ReviewEditorComponent implements OnInit {
    public view: any;
    formId: string = 'frm_61BFdEngzNrEkU';
    getFormData: boolean = false;
    formValid: boolean = false;
    formData: any = {
        id: '23452345',
        name: [{"culture":"en-GB","text":"Goals Review 2022"}],
        startDate: "2022-12-30T00:00:00",
        completeDate: "2022-12-30T00:00:00"
    };
    goalPlan: GoalPlanVerbose;
    formGeneratorDialogRef: MatDialogRef<FormGeneratorDialogComponent>;
    selectEmployeesDialogRef: MatDialogRef<SelectEmployeesDialogComponent>;
    formPristine: boolean = true;
    ratingsPristine: boolean = true;
    reviewId: any;
    isLoading: boolean;
    isLoadingEmployees: boolean;
    // employeesGridDataResult: { data: GoalPlanEmployee[]; total: number; };
    employeesGridDataResult: { data: any[]; total: number; } = {data: [], total: 0};
    pageSize: number = 5;
    skip: number = 0;
    selectedItems: string[] = [];

    parentGroup: FormGroup;

    private goalPlans: GoalPlan[] = [];
    private reviewers: any[] = [];

    public columns: any[] = [
        {field: "name", title: "Name"},
        {field: "allowEmployeeGoalEntry", title: "Allow Employee Goal Entry", type: "booleanToggle"},
        {field: "hasNotes", title: "Has Notes", type: "booleanToggle"},
        {field: "completionDateType", subField: "description", title: "Completion Date Type"},
        {field: "expectedCompletionDateType", subField: "description", title: "Expected Completion Date Type"},
        {field: "statusType", subField: "description", title: "Status Type"},
    ];


    constructor(
        private dialog: MatDialog,
        private router: Router,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private translate: TranslateService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private goalPlanService: GoalPlanService,
        private employeeService: EmployeeService,
        private fb: UntypedFormBuilder,
    ) {
        this.reviewId = this.getIdInURL();
    }

    ngOnInit(): void {
        this.getGoalPlan();
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('reviewId')
        );

        return IdInURL;
    }

    getGoalPlan() {
        this.isLoading = true;

        this.goalPlanService.getGoalPlans()
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            (res) => {
                this.goalPlans = res.data;
                this.buildFormData();
                // this.getEmployees();
            }
        );
    }

    buildFormData() {
        this.parentGroup = this.fb.group({
            goalPlans: this.fb.array([]),
        });
    }

    addCostCenterForm(): void {
        const goalPlans = this.parentGroup.get('goalPlans') as FormArray;
        let goalPlanFormGroup = this.createGoalPlanFormGroup();

        goalPlans.push(goalPlanFormGroup);
    }

    createGoalPlanFormGroup(): FormGroup {
        let group = this.fb.group({
            goalPlan: ['', Validators.required],
            percentAllocated: [''],
            goals: this.fb.array([]),
        });

        group.controls['goalPlan'].valueChanges.subscribe((value) => {
            this.goalPlanService.getGoals(value).subscribe((res) => {
                console.log("res", res);
                let goals = group.get('goals') as FormArray;
                goals.clear();
                res.data.forEach((goal) => {
                    goals.push(this.fb.group({
                        description: new FormControl({value: goal.description, disabled: true}),
                        goalPlan: [],
                        percentAllocated: [''],
                    }));
                });
            });
        });

        return group;
    }


    getEmployees() {
        this.isLoadingEmployees = true;

        this.goalPlanService.getGoalPlanEmployees(this.reviewId, this.pageSize.toString(), this.skip)
        .pipe(
            finalize(() => {
                this.isLoadingEmployees = false;
            })
        )
        .subscribe(
            (res) => {
                this.employeesGridDataResult = {
                    data: res.data,
                    total: res.total,
                }
            }
        );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.view = this.getEmployees();
    }

    showAddUserModal() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        // dialogConfig.data = {
        //     nonSelectableEmployees: this.employeesGridDataResult.data.map(employee => employee.id)
        // };

        dialogConfig.data = {
            nonSelectableEmployees: []
        };

        this.ngZone.runOutsideAngular(() => {
            this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);
        });
        // this.selectEmployeesDialogRef = this.dialog.open(SelectEmployeesDialogComponent, dialogConfig);

        const sub = this.selectEmployeesDialogRef.componentInstance.emitSelectedEmployees.subscribe((event) => {
            this.addEmployees(event)
        });
    }

    addEmployees(selectedEmployees: string[]) {
        console.log("selectedEmployees", selectedEmployees);
        const observables = selectedEmployees.map(selectedUserId => defer(() => this.employeeService.getTalent(selectedUserId)));

        this.overlayService.show();

        forkJoin(observables)
        .pipe(finalize(() => this.overlayService.hide()))
        .subscribe(
            (res) => {
                // this.getEmployees();
                console.log("res", res);
                res.map((employee) => {
                    let gridItem = {
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        provideRatings: false,
                        provideComments: false,
                        viewOtherReviewRatings: false,
                        employeeCanViewRatings: false,
                        managerCanViewRatings: false,
                    }
                    this.employeesGridDataResult.data.push(gridItem);
                    this.employeesGridDataResult.total++;
                });

                this.selectEmployeesDialogRef.close();
                this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
            },
            err => {
                this.snackbarService.openSnackBar(err, 'clear', 'warn');
            }
        );
    }

    deleteAllSelected() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: `Are you sure you want to remove ${this.selectedItems.length} User(s)?`
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {

                    this.employeesGridDataResult.data = this.employeesGridDataResult.data.filter((employee) => {
                        return !this.selectedItems.includes(employee.id);
                    });

                    this.employeesGridDataResult.total = this.employeesGridDataResult.data.length;

                    this.selectedItems = [];
                    this.snackbarService.openSnackBar('Removed successfully', 'clear', 'success');
                }
            }
        );
    }

    formDataEmitted(formDataEmitted) {
        this.save(formDataEmitted);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    formPristineEmitted(formPristine: boolean) {
        this.formPristine = formPristine;
    }

    openConfirmCloseDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            text: this.translate.instant('UnsavedChangesMessage')
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data === true) {
                    this.navigateBack();
                }
            }
        );
    }

    navigateBack() {
        this.router.navigate([`${routes.PERFORMANCE}/Reviews`]);
    }

    save(formData: GoalPlanSubmit) {
        this.overlayService.show();

        setTimeout(() => {
        this.overlayService.hide();
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }, 500)
    }

    addNewGoal() {
        this.addCostCenterForm();
    }


    deleteGoal(i: number) {
        const costCenters = this.parentGroup.get('goalPlans') as FormArray;
        costCenters.removeAt(i);
    }

    showAddVariableReviewer() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        const dialogRef = this.dialog.open(AddReviewerDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.addVariableReviewer(data);
                }
            }
        );
    }

    private addVariableReviewer(data: any) {
        let gridItem = {
            firstName: data.reviewer,
            provideRatings: data.provideRatings,
            provideComments: data.provideComments,
            viewOtherReviewRatings: data.viewOtherReviewRatings,
            employeeCanViewRatings: data.employeeCanViewRatings,
            managerCanViewRatings: data.managerCanViewRatings,
        }
        console.log("gridItem", gridItem);
        console.log("data", data);

        this.employeesGridDataResult.data.push(gridItem);
        this.employeesGridDataResult.total++;
    }
}

