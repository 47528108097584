import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import {api_routes, db_tables} from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-discipline',
  templateUrl: './employee-discipline.component.html',
  styleUrls: ['./employee-discipline.component.scss']
})
export class EmployeeDisciplineComponent implements OnInit {
  @Input() formIds: string[];
  employeeID: string;

  public columns: any[] = [
    { field: "action", title: "Action", type: "navigation" },
    { field: "reason", title: "Reason" },
    { field: "date", title: "Date", type: "date" },
    { field: "conductedBy", title: "Conducted By" },
    { field: "comments", title: "Comments" },
  ];

  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult = {
    data: [
      {
        action: "Poor Attendance",
        reason: "Public transport delays",
        date: "2022-04-23T00:00:00",
        conductedBy: "Mark Smith",
        comments: "Employee has been notified and agrees this is an issue",
      },
      {
        action: "Performance",
        reason: "Poor employee performance",
        date: "2022-06-23T00:00:00",
        conductedBy: "Mark Smith",
        comments: "Employee has confirmed they did have adequate training and the correct equipment",
      },
    ],
    total: 2,
  };
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: string = '0';
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_vmmI4Zj0zefPLq'
  changeReasonFormId: string = 'frm_tXry4lyrw3xK9e';
  formValid: boolean;
  private sortString: string = 'date-desc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  
  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
    private overlayService: OverlayService
  ) { 
    this.route.paramMap.subscribe(
      params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getDisciplines() {
    
  }

  getDiscipline(grievance?: any) {
    
  }

  openFormDialog(grievance?: any) {
    // let formData = {
    //   id: grievance ? grievance.id : null,
    //   asOf: moment().format(),
    //   type: grievance && grievance.type ? grievance.type.id : null,
    //   date: grievance ? grievance.date : null,
    //   filedBy: grievance && grievance.filedBy ? grievance.filedBy.id : null,
    //   shortDescription: grievance ? grievance.shortDescription : null,
    //   description: grievance ? grievance.description : null,
    //   status: grievance && grievance.status ? grievance.status.id : null,
    //   finalResolution: grievance && grievance.finalResolution ? grievance.finalResolution.id : null,
    //   changeReason: '',
    //   changeReasonComments: ''
    // };

    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;

    // dialogConfig.data = {
    //   formTitle: `${this.translate.instant('Grievance')}`,
    //   formId: this.formId,
    //   changeReasonFormId: this.changeReasonFormId,
    //   targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
    //   formData: formData
    // };

    // this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    // const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
    //   this.save(event)
    // });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    this.overlayService.hide()
    this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');

    
  }

  deleteDisciplines(GrievanceIds: string[]) {
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip.toString();
    this.pageSize = event.take
    this.view = this.getDisciplines();
  }

}




