import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SuccessionTrackService } from '@app/modules/succession-track/services/succession-track.service';
import { RowActionButtonInput, RowActionEventOutput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize, map } from 'rxjs';
// import { Integration } from '../../models/integration.model';

@Component({
  selector: 'app-succession-potential-and-readiness',
  templateUrl: './succession-potential-and-readiness.component.html',
  styleUrls: ['./succession-potential-and-readiness.component.scss']
})
export class SuccessionPotentialAndReadinessComponent implements OnInit {

  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'jobTitle', 
      title: 'Job Title',
    }, 
    {
      field: 'readiness', 
      title: 'Readiness'
    }, 
    {
      field: 'comments', 
      title: 'Comments'
    },   
  ];

  talentPools = [
    'Group A - Global Executive',
    'Group B - Global Leader',
    'Group C - Business Specialist',
    'Group E - Early Career'
  ]

  potentialRatings = [
    'Promotable (1 level)',
    'Needs Development',
    'Critical Retention',
    'Too New to Rate',
  ]

  promotionReadiness = [
    'Within 1 Year',
    'Within 2 Year',
    'Immediate (Ready Now)',
    'Too New to Rate'
  ]

  data = [
    {
      jobTitle: 'VP, Human Resources',
      readiness: 'Within 1 Year',
      comments: 'With more experience in leadership and strategic management, they will be ready for a more senior position such as VP.',
    },
    {
      jobTitle: 'Director, HR',
      readiness: 'Immediate (Ready Now)',
      comments: 'They are ready to assume the duties of Director HR in an acting position immediately.',
    },
  ]

  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: string = '0';
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  
  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private successionTrackService: SuccessionTrackService,
    private employeeContactCardService: EmployeeContactCardService,
  ) { }

  ngOnInit(): void {
    this.gridDataResult = {
      data: this.data,
      total: this.data.length,
    }
  }

  getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.round(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToSuccessionProfile(employee) {
    
  }

  rowActionButtonClicked($event){
    if($event.id === 'analytics') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ANALYTICS}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
    else if($event.id === 'error-log') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ERROR_LOG}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
  }

  pageChange(event){}

}
