import { Component, Input, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { Form } from '@app/modules/form-generator/models/form.model';
import { Letter } from '@app/modules/letter-generator/models/leter.model';
import { LetterService } from '@app/modules/letter-generator/services/letter.service';
import { Survey } from '@app/modules/survey-section/models/surveys.model';
import { SurveysService } from '@app/modules/survey-section/services/surveys.service';
import { WorkflowTask } from '@app/modules/workflow-section/models/workflows.model';
import { finalize } from 'rxjs';
import { FormSelectionDialogComponent } from '../form-selection-dialog/form-selection-dialog.component';
import { LetterSelectionDialogComponent } from '../letter-selection-dialog/letter-selection-dialog.component';
import { SurveySelectionDialogComponent } from '../survey-selection-dialog/survey-selection-dialog.component';

@Component({
  selector: 'app-workflow-editor-task',
  templateUrl: './workflow-editor-task.component.html',
  styleUrls: ['./workflow-editor-task.component.scss']
})
export class WorkflowEditorTaskComponent implements OnInit {
  @Input() task: WorkflowTask;
  
  workflowTaskDetails: FormGroup;

  surveys: Survey[];
  selectSurveyDialogRef: MatDialogRef<SurveySelectionDialogComponent>;
  selectedSurvey: Survey;

  forms: Form[];
  loadingForms: boolean = true;
  selectedForm: Form;

  loadingLetters: boolean;
  letters: Letter[];
  selectedLetter: Letter;

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private surveysService: SurveysService,
    private metadataFormService: MetadataFormService,
    private letterService: LetterService,
  ) { }

  ngOnInit(): void {
    this.workflowTaskDetails = this.fb.group({
      name: [this.task ? this.task.name : '', Validators.required],
      description: [this.task ? this.task.description : ''],
      type: [this.task ? this.task.type : '', Validators.required],
    });

    this.getSurveys();
    this.getForms();
    this.getLetters();
  }

  getSurveys() {
    this.surveysService.getSurveys()
    .subscribe(
        res => {
            this.surveys = res;

            if(this.task?.survey) {
              this.selectedSurvey = this.surveys.find(survey => survey.id === this.task.survey);
            }
        }
    );
  }
  
  getForms(): void {
    this.loadingForms = true;

    this.metadataFormService.getForms(0, '100', null, "name-asc")
    .pipe(
        finalize(() => {
            this.loadingForms = false;
        })
    )
    .subscribe(
        res => {
          this.forms = res.data

          if(this.task?.form) {
            this.selectedForm = this.forms.find(form => form.id === this.task.form);
          }
        }
    );
  }

  getLetters() {
    this.loadingLetters = true;

    this.letterService.getLetters()
    .pipe(
      finalize( () => {
        this.loadingLetters = false;
      })
    )
    .subscribe( 
      res => {
        this.letters = res;

        if(this.task?.letter) {
          this.selectedLetter = this.letters.find(letter => letter.id === this.task.letter);
        }
      }
    );
  }

  showSelectLetterModal() {
    const dialogConfig = new MatDialogConfig();
    let dialogRef: MatDialogRef<LetterSelectionDialogComponent>
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      letters: this.letters
    };

    this.ngZone.runOutsideAngular(() => {
      dialogRef = this.dialog.open(LetterSelectionDialogComponent, dialogConfig);
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedLetter = this.letters.find(letter => letter.id === data);
        }
      }
    ); 
  }

  showSelectFormModal() {
    const dialogConfig = new MatDialogConfig();
    let dialogRef: MatDialogRef<FormSelectionDialogComponent>
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      forms: this.forms
    };

    this.ngZone.runOutsideAngular(() => {
      dialogRef = this.dialog.open(FormSelectionDialogComponent, dialogConfig);
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedForm = this.forms.find(form => form.id === data);
        }
      }
    ); 
  }

  showSelectSurveyModal() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      surveys: this.surveys
    };

    this.ngZone.runOutsideAngular(() => {
      this.selectSurveyDialogRef = this.dialog.open(SurveySelectionDialogComponent, dialogConfig);
    });

    this.selectSurveyDialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.selectedSurvey = this.surveys.find(survey => survey.id === data);
        }
      }
    ); 
  }

}
