<div>
    <div class="title">
        <h3 setColor="primaryColour">
            {{data.title}}
        </h3>
    </div>
    <div class="text">
        <p>
            {{data.text}}
        </p>
    </div>
</div>
<div class="form-container">
    <div class="form-title">
        <h5 setColor="primaryColour">{{data.formTitle}}</h5>
    </div>
    <!-- <app-dynamic-form 
      [hideTitle]="true"
      [hideButtons]="true"
      [canViewHistory]="false"
      [data]="" 
      [submittingForm]="false"
      [emptyForm]="true"
      [formId]="data.formId">
    </app-dynamic-form> -->

    <div class="form">
        <app-form-generator 
            [formId]="data.formId"
        ></app-form-generator>
    </div>
</div>