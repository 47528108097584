<div class="header" [ngStyle]="{ 'border-color': color }">
    <span [matBadge]="employees.length" matBadgeColor="warn" class="material-icons-outlined">
        person
    </span>

    <h6>{{title}}</h6>

    <span>({{getPercentage()}})</span>
</div>

<div class="list">
    <mat-list>
        <ng-container *ngFor="let employee of employees">
            <mat-list-item
                appPreventDoubleClick 
                (throttledClick)="openPotentialPerformanceDialog(employee)"
            >
                <div class="employee-name">
                    <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
        
                    <span class="employee">
                        {{employee.firstname}} {{employee.lastname}}
                    </span>
                </div>
            </mat-list-item>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>
