<mat-card class="mat-elevation-z0">
    <mat-card-title>
        <h5 appLocalizationPopupDirective [localizationCode]="'MyTimeOffInLieuRequests'">{{ 'MyTimeOffInLieuRequests' | translate: {Default: "My Time Off in Lieu Requests"} }}</h5>

        <div>
            <mat-form-field appearance="outline" appLocalizationPopupDirective [localizationCode]="'Status'">
                <mat-label>{{ 'Status' | translate: {Default: "Status"} }}</mat-label>
                <mat-select (selectionChange)="getLeaveRequests()" [(ngModel)]="selectedStatusFilters" multiple>
                    <mat-select-trigger>
                        {{ getSelectedStatusesText() }}
                    </mat-select-trigger>
                  <mat-option *ngFor="let option of statusOptions" appLocalizationPopupDirective [localizationCode]="option.name" [value]="option">{{option.name | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appLocalizationPopupDirective [localizationCode]="'Filter'" appearance="outline">
                <mat-label>{{ 'Filter' | translate: {Default: "Filter"} }}</mat-label>
                <mat-select (selectionChange)="getLeaveRequests()" [(ngModel)]="selectedFilter">
                    <mat-select-trigger>
                        {{ selectedFilter.text | translate}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let option of filterOptions" [value]="option">
                        <div appLocalizationPopupDirective [localizationCode]="option.text">{{ option.text | translate}}</div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card-title>

    <mat-card-content>
        <div class="time-off-types-container" *ngIf="leaveRequests$ | withLoading | async as leaveRequests">
            <ng-template [ngIf]="leaveRequests.value">
                <div class="no-data" *ngIf="leaveRequests.value.data.length === 0" appLocalizationPopupDirective [localizationCode]="'NoToilRequests'">
                    <mat-icon aria-hidden="false" class="material-icons-outlined">date_range</mat-icon>

                    {{ 'NoToilRequests' | translate: {Default: "No Time Off In Lieu Requests"} }}
                </div>

                <mat-list>
                    <ng-container *ngFor="let leaveRequest of leaveRequests.value.data">
                        <toil-requests-list-item
                            [employeeToilRequest]="leaveRequest"
                            (openEmployeeToilRequestDialog)="openLeaveRequestDialog($event)"
                            (refreshRequests)="getLeaveRequests()"
                        ></toil-requests-list-item>
                    </ng-container>
                </mat-list>
            </ng-template>
            <ng-template [ngIf]="leaveRequests.error">Error {{ obs.error }}</ng-template>
            <ng-template [ngIf]="leaveRequests.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>
