<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'Documents'">
    {{ 'Documents' | translate: {Default: "Documents"} }}
</h5>

<mat-dialog-content>
    <div class="document-upload">
        <div>
            <button setColor="primaryColour" mat-raised-button class="primary-button" (click)="fileInput.click()">
                {{ 'ChooseAFile' | translate: {Default: "Choose a file"} }}
            </button>
            <input
                #fileInput
                type="file"
                id="fileInput"
                (change)="selectFile($event)"
                name="fileInput"
            />
        </div>
        <div *ngIf="currentFile">
            <div class="current-file">
                <mat-toolbar class="progress-bar">
                    <mat-progress-bar color="accent" [value]="progress"></mat-progress-bar>
                    <span class="progress">{{ progress }}%</span>
                </mat-toolbar>
                <div class="current-file-details">
                    <div class="current-file-name">{{currentFile.name}}</div>
                    <div class="current-file-size">{{ currentFile.size | filesize }}</div>
                </div>
            </div>
        </div>
    
        <div *ngIf="message" class="message">
            {{ message }}
        </div>
    </div>

    <div class="form" *ngIf="clonedDocument">
        <mat-form-field appearance="fill">
            <mat-label>
                Select A Directory
            </mat-label>
         
            <mat-select disableOptionCentering [(ngModel)]="clonedDocument.parentDirectoryId">
                <mat-option [value]="null">
                    None
                </mat-option>
    
                <ng-container *ngFor="let directory of directories">
                    <mat-option [value]="directory.id">{{directory.name}}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input type="text" matInput [(ngModel)]="clonedDocument.name">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Comments</mat-label>
            <textarea matInput [(ngModel)]="clonedDocument.comments" placeholder="Enter your comments"></textarea>
        </mat-form-field>

        <mat-slide-toggle setColor="primaryColour" [(ngModel)]="clonedDocument.hugoAccess">Hugo Access</mat-slide-toggle>

        <mat-slide-toggle setColor="primaryColour" [(ngModel)]="clonedDocument.isPrivate">Private</mat-slide-toggle>

        <div class="form-elements-container">
            <app-work-locations-form-field
                class="work-locations-form-field"
                [formElement]="workLocationFormField"
                [parentGroup]="form"
            ></app-work-locations-form-field>
    
            <app-security-role-form-field
                class="security-role-form-field"
                [formElement]="securityRoleFormField"
                [parentGroup]="form"
            ></app-security-role-form-field>
        </div>

    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button class="close" appPreventDoubleClick (throttledClick)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="saveDocument()">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>

<!-- <app-site-settings-menu>
    <mat-card class="mat-elevation-z0">
        <mat-card-header>
            <mat-card-title>Documents</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="document-upload">
                <div>
                    <button mat-raised-button class="primary-button" (click)="fileInput.click()">
                        {{ 'ChooseAFile' | translate: {Default: "Choose a file"} }}
                    </button>
                    <input
                        #fileInput
                        type="file"
                        id="fileInput"
                        (change)="selectFile($event)"
                        name="fileInput"
                    />
                </div>
                <div *ngIf="currentFile">
                    <div class="current-file">
                        <mat-toolbar class="progress-bar">
                            <mat-progress-bar color="accent" [value]="progress"></mat-progress-bar>
                            <span class="progress">{{ progress }}%</span>
                        </mat-toolbar>
                        <div class="current-file-details">
                            <div class="current-file-name">{{currentFile.name}}</div>
                            <div class="current-file-size">{{ currentFile.size | filesize }}</div>
                        </div>
                    </div>
                </div>
            
                <div *ngIf="message" class="message">
                    {{ message }}
                </div>
            </div>

            <div *ngIf="document">
                <mat-form-field appearance="fill">
                    <mat-label>
                        Select A Directory
                    </mat-label>
                 
                    <mat-select disableOptionCentering [(ngModel)]="clonedDocument.parentDirectoryId">
                        <mat-option value='null'>
                            None
                        </mat-option>
            
                        <ng-container *ngFor="let directory of directories">
                            <mat-option [value]="directory.id">{{directory.name}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Comments</mat-label>
                    <textarea matInput [(ngModel)]="clonedDocument.comments" placeholder="Enter your comments"></textarea>
                </mat-form-field>

                <mat-slide-toggle [(ngModel)]="clonedDocument.hugoAccess">Hugo Access</mat-slide-toggle>

                <mat-slide-toggle [(ngModel)]="clonedDocument.isPrivate">Private</mat-slide-toggle>
            </div>

        </mat-card-content>

        <mat-card-actions>
            <button mat-raised-button class="primary-button" (click)="saveDocument()">Save</button>
            <button mat-raised-button class="secondary-button" (click)="cancel()">Cancel</button>
        </mat-card-actions>
    </mat-card>
</app-site-settings-menu> -->
