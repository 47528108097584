import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-disability-pay-quartiles',
  templateUrl: './disability-pay-quartiles.component.html',
  styleUrls: ['./disability-pay-quartiles.component.scss']
})
export class DisabilityPayQuartilesComponent implements OnInit {

  headcountData = [
    {
      "quarter": "Q1",
      "employeeMale": "30.5%",
      "employeeFemale": "69.5%",
      "employeeDiff": "75",
      "memberMale": "75.0%",
      "memberFemale": "25.0%",
      "memberDiff": "16",
      "peopleMale": "28.0%",
      "peopleFemale": "72.0%",
      "peopleDiff": "98"
    },
    {
      "quarter": "Q2",
      "employeeMale": "22.0%",
      "employeeFemale": "78.0%",
      "employeeDiff": "104",
      "memberMale": "60.0%",
      "memberFemale": "40.0%",
      "memberDiff": "5",
      "peopleMale": "24.0%",
      "peopleFemale": "76.0%",
      "peopleDiff": "114"
    },
    {
      "quarter": "Q3",
      "employeeMale": "32.0%",
      "employeeFemale": "68.0%",
      "employeeDiff": "69",
      "memberMale": "66.0%",
      "memberFemale": "34.0%",
      "memberDiff": "13",
      "peopleMale": "40.0%",
      "peopleFemale": "60.0%",
      "peopleDiff": "46"
    },
    {
      "quarter": "Q4",
      "employeeMale": "48.5%",
      "employeeFemale": "51.5%",
      "employeeDiff": "9",
      "memberMale": "74.0%",
      "memberFemale": "26.0%",
      "memberDiff": "8",
      "peopleMale": "62.0%",
      "peopleFemale": "38.0%",
      "peopleDiff": "43"
    }
  ]

  hourlySalaryData = [
    {
      "quarter": "Q1",
      "employeeMale": "£9.50",
      "employeeFemale": "£9.54",
      "employeeDiff": "-0.4%",
      "memberMale": "£52.50",
      "memberFemale": "£52.98",
      "memberDiff": "-0.9%",
      "peopleMale": "£9.60",
      "peopleFemale": "£9.80",
      "peopleDiff": "-2.1%"
    },
    {
      "quarter": "Q2",
      "employeeMale": "£12.90",
      "employeeFemale": "£12.49",
      "employeeDiff": "3.2%",
      "memberMale": "£65.50",
      "memberFemale": "£64.83",
      "memberDiff": "1.0%",
      "peopleMale": "£13.80",
      "peopleFemale": "£13.62",
      "peopleDiff": "1.3%"
    },
    {
      "quarter": "Q3",
      "employeeMale": "£19.20",
      "employeeFemale": "£18.51",
      "employeeDiff": "3.6%",
      "memberMale": "£96.90",
      "memberFemale": "£95.45",
      "memberDiff": "1.5%",
      "peopleMale": "£23.20",
      "peopleFemale": "£22.44",
      "peopleDiff": "3.2%"
    },
    {
      "quarter": "Q4",
      "employeeMale": "£35.00",
      "employeeFemale": "£35.05",
      "employeeDiff": "8.4%",
      "memberMale": "£176.70",
      "memberFemale": "£174.02",
      "memberDiff": "1.5%",
      "peopleMale": "£75.90",
      "peopleFemale": "£65.65",
      "peopleDiff": "13.5%"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
