<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5>Upload Image</h5>
    </mat-card-title>
    <mat-card-content>
        <div class="section">
            <div class="section-header">
                <h6>Step 1: Choose Image</h6>
                <mat-divider></mat-divider>
            </div>
            <button class="choose-file-button" type="button" mat-raised-button (click)="fileInput.click()">Choose Image</button>
            <input hidden type="file" #fileInput (change)="fileChangeEvent($event)" />
        </div>

        <div *ngIf="imageChangedEvent && imageChangedEvent !== ''; else noImage">

            <div class="section">
                <div class="section-header">
                    <h6>Step 2: Edit Image</h6>
                    <mat-divider></mat-divider>
                </div>
                
                <div class="image-button-controls">
                    <div>
                        <button mat-stroked-button (click)="rotateLeft()">Rotate left</button>
                        <button mat-stroked-button (click)="rotateRight()">Rotate right</button>
                        <button mat-stroked-button (click)="flipHorizontal()">Flip horizontal</button>
                        <button mat-stroked-button (click)="flipVertical()">Flip vertical</button>
                    </div>
                    <div>
                        <button mat-stroked-button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect Ratio'}}</button>
                        <button mat-stroked-button (click)="resetImage()">Reset image</button>
                    </div>
                    <div>
                        <button mat-stroked-button (click)="zoomOut()">Zoom -</button> <button mat-stroked-button (click)="zoomIn()">Zoom +</button>
                    </div>
                </div>
        
                <div class="cropper-container">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [containWithinAspectRatio]="containWithinAspectRatio"
                        [aspectRatio]="1 / 1"
                        [resizeToWidth]="256"
                        [cropperMinWidth]="128"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [alignImage]="'left'"
                        [style.display]="showCropper ? null : 'none'"
                        format="png"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady($event)"
                        (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                </div>
            </div>

            <div class="section">
                <div class="section-header">
                    <h6>Step 3: Preview and Upload</h6>
                    <mat-divider></mat-divider>
                </div>
                <div class="preview-container">
                    <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
                </div>
                <button mat-flat-button color="primary" (click)="upload()">Upload</button>
            </div>
        </div>
        <ng-template #noImage>
            <div class="no-image">
                <h6>No Image Selected</h6>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>