<div class="guides">
  <app-time-off-guides></app-time-off-guides>
</div>

<mat-expansion-panel togglePosition="before" expanded>
    <mat-expansion-panel-header>
      <h4>Time Off</h4>
    </mat-expansion-panel-header>
  
    <ng-template matExpansionPanelContent>

      <div class="time-off-container">
        <app-time-off-policy-widget (timeOffRequestCreated)="timeOffRequestCreated($event)"></app-time-off-policy-widget>
        
        <app-my-time-off-requests [refreshMyTimeOffRequest]="refreshMyTimeOffRequest"></app-my-time-off-requests>
      </div>

    </ng-template>
</mat-expansion-panel>

<mat-expansion-panel togglePosition="before">
    <mat-expansion-panel-header>
      <h4>TOIL</h4>
    </mat-expansion-panel-header>
  
    <ng-template matExpansionPanelContent>
      <div class="time-off-container">
        <app-toil-policy-widget (toilRequestCreated)="toilRequestCreated($event)"></app-toil-policy-widget>
        
        <app-my-toil-requests [refreshMyToilRequest]="refreshMyToilRequest"></app-my-toil-requests>
      </div>
    </ng-template>
</mat-expansion-panel>

<mat-expansion-panel togglePosition="before">
    <mat-expansion-panel-header>
      <h4>Schedule</h4>
    </mat-expansion-panel-header>
  
    <ng-template matExpansionPanelContent>
      <app-schedule-widget></app-schedule-widget>
    </ng-template>
</mat-expansion-panel>

<mat-expansion-panel togglePosition="before">
  <mat-expansion-panel-header>
    <h4 appLocalizationPopupDirective localizationCode="TeamMembersSchedule">{{ 'TeamMembersSchedule' | translate: {Default: "Team Members Schedule"} }}</h4>
  </mat-expansion-panel-header>

  <ng-template matExpansionPanelContent>
    <app-team-members-schedule-widget></app-team-members-schedule-widget>
  </ng-template>
</mat-expansion-panel>


