import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compensation-section',
  templateUrl: './compensation-section.component.html',
  styleUrls: ['./compensation-section.component.scss']
})
export class CompensationSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
