<mat-card class="mat-elevation-z0">
    <mat-card-title>
        <h5 appLocalizationPopupDirective localizationCode="TimeOffRequests">{{ 'TimeOffRequests' | translate: {Default: "Time Off Requests"} }}</h5>

        <mat-form-field appLocalizationPopupDirective [localizationCode]="'Filter'" appearance="outline">
            <mat-label>{{ 'Filter' | translate: {Default: "Filter"} }}</mat-label>
            <mat-select (selectionChange)="getLeaveRequests()" [(ngModel)]="selectedFilter">
                <mat-select-trigger>
                    {{ selectedFilter?.text | translate}}
                </mat-select-trigger>
                <mat-option *ngFor="let option of filterOptions" [value]="option">
                    <div appLocalizationPopupDirective [localizationCode]="option.text">{{ option.text | translate}}</div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-card-title>
  
    <mat-card-content>
        <div class="time-off-types-container" *ngIf="timeOffLeaveRequests$ | withLoading | async as timeOffLeaveRequests">
            <ng-template [ngIf]="timeOffLeaveRequests.value">
                <div class="no-data" *ngIf="timeOffLeaveRequests.value.data.length === 0">
                    <mat-icon aria-hidden="false" class="date-icon material-icons-outlined">date_range</mat-icon>
                    <div appLocalizationPopupDirective [localizationCode]="'NoTimeOffRequests'">
                        {{ 'NoTimeOffRequests' | translate: {Default: "No Time Off Requests To Approve"} }}
                    </div>
                </div>

                <mat-list>
                    <ng-container *ngFor="let timeOffLeaveRequest of timeOffLeaveRequests.value.data">
                        <app-approval-list-item 
                            [timeOffLeaveRequest]="timeOffLeaveRequest"
                            (openPendingTimeOffDialog)="openPendingTimeOffDialog($event)"
                        ></app-approval-list-item>
                    </ng-container>
                </mat-list>
            </ng-template>
            <ng-template [ngIf]="timeOffLeaveRequests.error">Error {{ obs.error }}</ng-template>
            <ng-template [ngIf]="timeOffLeaveRequests.loading">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>