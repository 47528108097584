<app-performance>
    <mat-card>
        <div class="container golden-rules pt-5 pb-5">
            <div class="top-row pt-5 pb-5">
                <div class="col-md-6 offset-md-3">
                    <!--?xml version="1.0" encoding="utf-8"?-->
                    <!-- Generator: Adobe Illustrator 27.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 620.9 620.8" style="enable-background:new 0 0 620.9 620.8;" xml:space="preserve">
                        <style type="text/css">
                            .st0{fill:#FFFFFF;}
                            .st1{clip-path:url(#SVGID_00000085961335022470354210000013905552074651427242_);}
                            .st2{clip-path:url(#SVGID_00000160189792718963187500000014774961027961527446_);enable-background:new    ;}
                            .st3{font-family:'Poppins-Bold';}
                            .st4{font-size:22px;}
                            .st5{letter-spacing:2;}
                            .st6{clip-path:url(#SVGID_00000160189792718963187500000014774961027961527446_);}
                            .st7{clip-path:url(#SVGID_00000065770618260808646620000008588085578698868109_);}
                            .st8{clip-path:url(#SVGID_00000107563153568523011270000005848395734655336340_);enable-background:new    ;}
                            .st9{clip-path:url(#SVGID_00000107563153568523011270000005848395734655336340_);}
                            .st10{clip-path:url(#SVGID_00000097474521751013398310000008100196866469424256_);}
                            .st11{clip-path:url(#SVGID_00000023256644138501792490000012040428281852364704_);enable-background:new    ;}
                            .st12{clip-path:url(#SVGID_00000023256644138501792490000012040428281852364704_);}
                            .st13{fill:none;stroke:#FFFFFF;stroke-width:6;}
                            .st14{fill:none;stroke:#FFFFFF;stroke-width:1.5;}
                            .st15{fill:none;stroke:#FFFFFF;stroke-width:5.669;}
                            .st16{fill:none;}
                            .st17{font-family:'Poppins-Regular';}
                            .st18{font-size:18.49px;}
                            .st19{font-size:18.4881px;}
                            .st20{font-size:18.4937px;}
                            .st21{font-size:18.4922px;}
                            .st22{clip-path:url(#SVGID_00000055688578567475878420000009151639444875877792_);enable-background:new    ;}
                            .st23{font-size:18.4924px;}
                            .st24{font-size:18.4974px;}
                            .st25{font-size:18.4939px;}
                            .st26{font-size:18.4933px;}
                            .st27{clip-path:url(#SVGID_00000137837923565181038980000005731688857416638867_);enable-background:new    ;}
                            .st28{font-size:18.493px;}
                            .st29{font-size:18.4961px;}
                            .st30{font-size:18.4941px;}
                            .st31{font-size:18.4968px;}
                            .st32{font-size:18.4851px;}
                            .st33{font-size:18.4985px;}
                            .st34{clip-path:url(#SVGID_00000038391990479564667810000009752201721563585167_);enable-background:new    ;}
                            .st35{font-size:18.4964px;}
                            .st36{font-size:18.4831px;}
                            .st37{font-size:18.4812px;}
                            .st38{font-size:18.4908px;}
                            .st39{clip-path:url(#SVGID_00000024696582799147370690000012194114922464632489_);fill:none;stroke:#FFFFFF;stroke-width:1.5;}
                            .st40{font-family:'Poppins-Medium';}
                            .st41{font-size:24px;}
                            .st42{letter-spacing:3;}
                        </style>
                        <!-- <polygon id="left" class="st0" points="112.1,413.4 102.2,419.1 308.6,66.4 308.6,77.6 "/> -->
                        <g id="" class="pillar">
                            <defs>
                                <rect id="SVGID_1_" x="78.5" y="242.6" width="95" height="32.1"></rect>
                            </defs>
                            <clipPath id="SVGID_00000031169295699250639220000013923152698052590498_">
                                <use xlink:href="#SVGID_1_" style="overflow:visible;"></use>
                            </clipPath>
                            <g style="clip-path:url(#SVGID_00000031169295699250639220000013923152698052590498_);">
                                <defs>
                                    <rect id="SVGID_00000141439514377150668810000004771308739803597973_" x="78.5" y="242.6" width="95" height="32.1"></rect>
                                </defs>
                                <clipPath id="SVGID_00000036224769416798522650000014873419053192741041_">
                                    <use xlink:href="#SVGID_00000141439514377150668810000004771308739803597973_" style="overflow:visible;"></use>
                                </clipPath>
                                <g style="clip-path:url(#SVGID_00000036224769416798522650000014873419053192741041_);enable-background:new    ;">
                                    <text data-id="giving" transform="matrix(1 0 0 1 78.508 261.308)" class="pillar copy st0 st3 st4 st5">GIVING</text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000036224769416798522650000014873419053192741041_);">
                                    <text transform="matrix(1 0 0 1 153.9457 261.308)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000036224769416798522650000014873419053192741041_);enable-background:new    ;">
                                </g>
                            </g>
                        </g>
                        <polygon id="left" class="line st0" points="112.1,413.4 102.2,419.1 308.6,66.4 308.6,77.6 "></polygon>
                        <!-- <polygon id="right" class="st0" points="515,419.1 505.1,413.4 308.6,77.6 308.6,66.4 "/> -->
                        <g id="" class="pillar">
                            <defs>
                                <rect id="SVGID_00000095303246982078000990000005242955210688667287_" x="439.7" y="243.1" width="117.3" height="32.1"></rect>
                            </defs>
                            <clipPath id="SVGID_00000008829863446165420980000000839842179490627218_">
                                <use xlink:href="#SVGID_00000095303246982078000990000005242955210688667287_" style="overflow:visible;"></use>
                            </clipPath>
                            <g style="clip-path:url(#SVGID_00000008829863446165420980000000839842179490627218_);">
                                <defs>
                                    <rect id="SVGID_00000178905313147760375680000013635066255493770685_" x="439.7" y="243.1" width="117.3" height="32.1"></rect>
                                </defs>
                                <clipPath id="SVGID_00000139272827301381163960000008664539836312278682_">
                                    <use xlink:href="#SVGID_00000178905313147760375680000013635066255493770685_" style="overflow:visible;"></use>
                                </clipPath>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);enable-background:new    ;">
                                    <text data-id="earning" transform="matrix(1 0 0 1 439.721 261.861)" class="copy st0 st3 st4 st5">EARNING</text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);">
                                    <text transform="matrix(1 0 0 1 454.2627 261.861)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);">
                                    <text transform="matrix(1 0 0 1 477.7585 261.861)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);">
                                    <text transform="matrix(1 0 0 1 499.4061 261.861)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);">
                                    <text transform="matrix(1 0 0 1 523.2319 261.861)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);">
                                    <text transform="matrix(1 0 0 1 537.0256 261.861)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000139272827301381163960000008664539836312278682_);">
                                    <text transform="matrix(1 0 0 1 560.8514 261.861)" class="st0 st3 st4"> </text>
                                </g>
                            </g>
                        </g>
                        <polygon id="right" class="line st0" points="515,419.1 505.1,413.4 308.6,77.6 308.6,66.4 "></polygon>
                        <!-- <polygon id="bottom" class="st0" points="515,419.1 102.2,419.1 112.1,413.4 505.1,413.4 "/> -->
                        <g id="" class="pillar">
                            <defs>
                                <rect id="SVGID_00000177457987409515334090000004943214654596135563_" x="228.1" y="451.7" width="161" height="32.1"></rect>
                            </defs>
                            <clipPath id="SVGID_00000112603226879017312700000017552313364380855226_">
                                <use xlink:href="#SVGID_00000177457987409515334090000004943214654596135563_" style="overflow:visible;"></use>
                            </clipPath>
                            <g style="clip-path:url(#SVGID_00000112603226879017312700000017552313364380855226_);">
                                <defs>
                                    <rect id="SVGID_00000041984670834274721620000012924019619405746068_" x="228.1" y="451.7" width="161" height="32.1"></rect>
                                </defs>
                                <clipPath id="SVGID_00000041260560401867497160000011754407927825278118_">
                                    <use xlink:href="#SVGID_00000041984670834274721620000012924019619405746068_" style="overflow:visible;"></use>
                                </clipPath>
                                <g style="clip-path:url(#SVGID_00000041260560401867497160000011754407927825278118_);enable-background:new    ;">
                                    <text data-id="sustaining" transform="matrix(1 0 0 1 228.14 470.407)" class="copy st0 st3 st4 st5">SUSTAINING</text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000041260560401867497160000011754407927825278118_);">
                                    <text transform="matrix(1 0 0 1 294.271 470.407)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000041260560401867497160000011754407927825278118_);">
                                    <text transform="matrix(1 0 0 1 317.7668 470.407)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000041260560401867497160000011754407927825278118_);">
                                    <text transform="matrix(1 0 0 1 331.5605 470.407)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000041260560401867497160000011754407927825278118_);">
                                    <text transform="matrix(1 0 0 1 364.4943 470.407)" class="st0 st3 st4"> </text>
                                </g>
                                <g style="clip-path:url(#SVGID_00000041260560401867497160000011754407927825278118_);">
                                    <text transform="matrix(1 0 0 1 388.342 470.407)" class="st0 st3 st4"> </text>
                                </g>
                            </g>
                        </g>
                        <polygon id="bottom" class="line st0" points="515,419.1 102.2,419.1 112.1,413.4 505.1,413.4 "></polygon>
                        <circle id="Ellipse_153" class="st13" cx="311" cy="302.3" r="269"></circle>
                        <path id="Path_31780" class="st14" d="M308.6,128.8c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C276.5,143.2,290.9,128.8,308.6,128.8z"></path>
                        <path id="Path_31781" class="st14" d="M271.8,195.8c17.8,0,32.1,14.4,32.1,32.1s-14.4,32.1-32.1,32.1s-32.1-14.4-32.1-32.1l0,0
                            C239.7,210.2,254.1,195.8,271.8,195.8z"></path>
                        <path id="Path_31782" class="st14" d="M344.4,195.8c17.8,0,32.1,14.4,32.1,32.1s-14.4,32.1-32.1,32.1c-17.8,0-32.1-14.4-32.1-32.1v0
                            C312.3,210.2,326.6,195.8,344.4,195.8z"></path>
                        <path id="Path_31783" class="st14" d="M235.7,264.5c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C203.5,278.8,217.9,264.5,235.7,264.5z"></path>
                        <path id="Path_31784" class="st14" d="M196,333.3c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C163.9,347.7,178.3,333.3,196,333.3z"></path>
                        <path id="Path_31785" class="st14" d="M270.5,333.3c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C238.4,347.7,252.8,333.3,270.5,333.3z"></path>
                        <path id="Path_31786" class="st14" d="M345,333.3c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C312.9,347.7,327.3,333.3,345,333.3z"></path>
                        <path id="Path_31787" class="st14" d="M419.5,333.3c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C387.4,347.7,401.7,333.3,419.5,333.3z"></path>
                        <path id="Path_31788" class="st14" d="M309.3,264.5c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C277.2,278.8,291.6,264.5,309.3,264.5z"></path>
                        <path id="Path_31789" class="st14" d="M383,264.5c17.8,0,32.1,14.4,32.1,32.1c0,17.8-14.4,32.1-32.1,32.1
                            c-17.8,0-32.1-14.4-32.1-32.1c0,0,0,0,0,0C350.9,278.8,365.3,264.5,383,264.5z"></path>
                        <path id="Line_734_00000066480039077458322090000017540316392869174960_" class="st15" d="M308.6,72"></path>
                        <path id="Line_734_00000177462213642012330410000013160510896713236376_" class="st15" d="M510.1,416.2"></path>
                        <path id="Line_735_00000136411054834761348280000012246801975143868298_" class="st15" d="M510.1,416.2"></path>
                        <path id="Line_735_00000012434810322805523930000016393691822923413950_" class="st15" d="M107.1,416.2"></path>
                        <rect id="Rectangle_2632" class="st16" width="620.9" height="620.8"></rect>
                        <text transform="matrix(0.7611 0.6487 -0.6487 0.7611 498.3469 74.375)" class="st0 st17 st18">P</text>
                        <text transform="matrix(0.7313 0.682 -0.682 0.7313 507.7797 82.4411)" class="st0 st17 st18">A</text>
                        <text transform="matrix(0.7001 0.7141 -0.7141 0.7001 518.087 92.1298)" class="st0 st17 st19">S</text>
                        <text transform="matrix(0.6691 0.7431 -0.7431 0.6691 526.8565 101.13)" class="st0 st17 st18">S</text>
                        <text transform="matrix(0.6451 0.7641 -0.7641 0.6451 535.2452 110.5629)" class="st0 st17 st18">I</text>
                        <text transform="matrix(0.6159 0.7878 -0.7878 0.6159 539.3298 115.3022)" class="st0 st17 st20">O</text>
                        <text transform="matrix(0.5739 0.8189 -0.8189 0.5739 549.2632 128.1209)" class="st0 st17 st21">N</text>
                        <g>
                            <defs>
                                <rect id="SVGID_00000042726228096448331350000000891844557149284270_" width="620.9" height="620.8"></rect>
                            </defs>
                            <clipPath id="SVGID_00000158016595431514941020000001506779210132990096_">
                                <use xlink:href="#SVGID_00000042726228096448331350000000891844557149284270_" style="overflow:visible;"></use>
                            </clipPath>
                            <g style="clip-path:url(#SVGID_00000158016595431514941020000001506779210132990096_);enable-background:new    ;">
                                <text transform="matrix(-0.1025 0.9947 -0.9947 -0.1025 605.2803 327.722)" class="st0 st17 st18">R</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000158016595431514941020000001506779210132990096_);enable-background:new    ;">
                                <text transform="matrix(-0.142 0.9899 -0.9899 -0.142 603.9448 340.2359)" class="st0 st17 st23">E</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000158016595431514941020000001506779210132990096_);enable-background:new    ;">
                                <text transform="matrix(-0.1805 0.9836 -0.9836 -0.1805 602.3939 350.981)" class="st0 st17 st18">S</text>
                            </g>
                        </g>
                        <text transform="matrix(-0.2209 0.9753 -0.9753 -0.2209 600.1607 362.9809)" class="st0 st17 st18">P</text>
                        <text transform="matrix(-0.2588 0.9659 -0.9659 -0.2588 597.4623 374.716)" class="st0 st17 st18">E</text>
                        <text transform="matrix(-0.3021 0.9533 -0.9533 -0.3021 594.6871 385.181)" class="st0 st17 st18">C</text>
                        <text transform="matrix(-0.3455 0.9384 -0.9384 -0.3455 589.8978 400.0269)" class="st0 st17 st18">T</text>
                        <text transform="matrix(0.9482 0.3176 -0.3176 0.9482 212.7091 593.5021)" class="st0 st17 st18">F</text>
                        <text transform="matrix(0.9621 0.2726 -0.2726 0.9621 223.312 597.0721)" class="st0 st17 st18">O</text>
                        <text transform="matrix(0.9746 0.2239 -0.2239 0.9746 239.1201 601.4599)" class="st0 st17 st24">R</text>
                        <text transform="matrix(0.9858 0.168 -0.168 0.9858 251.9737 604.4526)" class="st0 st17 st25">W</text>
                        <text transform="matrix(0.9938 0.111 -0.111 0.9938 271.6888 607.6837)" class="st0 st17 st26">A</text>
                        <g>
                            <defs>
                                <rect id="SVGID_00000075151979542304401620000018090279984686017210_" width="620.9" height="620.8"></rect>
                            </defs>
                            <clipPath id="SVGID_00000070078849856255878770000003719734038869943963_">
                                <use xlink:href="#SVGID_00000075151979542304401620000018090279984686017210_" style="overflow:visible;"></use>
                            </clipPath>
                            <g style="clip-path:url(#SVGID_00000070078849856255878770000003719734038869943963_);enable-background:new    ;">
                                <text transform="matrix(0.9979 6.482840e-02 -6.482840e-02 0.9979 285.935 609.215)" class="st0 st17 st18">R</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000070078849856255878770000003719734038869943963_);enable-background:new    ;">
                                <text transform="matrix(0.9998 1.799708e-02 -1.799708e-02 0.9998 298.9908 610.0413)" class="st0 st17 st28">D</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000070078849856255878770000003719734038869943963_);enable-background:new    ;">
                                <text transform="matrix(0.9988 -4.815246e-02 4.815246e-02 0.9988 320.441 610.102)" class="st0 st17 st18">T</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000070078849856255878770000003719734038869943963_);enable-background:new    ;">
                                <text transform="matrix(0.9957 -9.296910e-02 9.296910e-02 0.9957 332.2966 609.5195)" class="st0 st17 st29">H</text>
                            </g>
                        </g>
                        <text transform="matrix(0.9918 -0.128 0.128 0.9918 346.7669 608.071)" class="st0 st17 st30">I</text>
                        <text transform="matrix(0.9866 -0.1629 0.1629 0.9866 353.0233 607.3113)" class="st0 st17 st31">N</text>
                        <text transform="matrix(0.9778 -0.2094 0.2094 0.9778 367.6901 604.822)" class="st0 st17 st18">K</text>
                        <text transform="matrix(0.9705 -0.241 0.241 0.9705 380.1879 602.063)" class="st0 st17 st18">I</text>
                        <text transform="matrix(0.9612 -0.2758 0.2758 0.9612 386.338 600.5801)" class="st0 st17 st18">N</text>
                        <text transform="matrix(0.945 -0.327 0.327 0.945 400.683 596.4291)" class="st0 st17 st18">G</text>
                        <text transform="matrix(-0.3672 -0.9301 0.9301 -0.3672 38.0441 415.7189)" class="st0 st17 st18">T</text>
                        <text transform="matrix(-0.3321 -0.9432 0.9432 -0.3321 33.8992 405.13)" class="st0 st17 st32">E</text>
                        <text transform="matrix(-0.2917 -0.9565 0.9565 -0.2917 30.304 394.9118)" class="st0 st17 st18">A</text>
                        <text transform="matrix(-0.2409 -0.9706 0.9706 -0.2409 26.2747 381.7447)" class="st0 st17 st33">M</text>
                        <g>
                            <defs>
                                <rect id="SVGID_00000052105715250209833580000009735801240148356780_" width="620.9" height="620.8"></rect>
                            </defs>
                            <clipPath id="SVGID_00000008133405644686093720000016629380097849661116_">
                                <use xlink:href="#SVGID_00000052105715250209833580000009735801240148356780_" style="overflow:visible;"></use>
                            </clipPath>
                            <g style="clip-path:url(#SVGID_00000008133405644686093720000016629380097849661116_);enable-background:new    ;">
                                <text transform="matrix(-0.18 -0.9837 0.9837 -0.18 22.143 365.0532)" class="st0 st17 st18">W</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000008133405644686093720000016629380097849661116_);enable-background:new    ;">
                                <text transform="matrix(-0.121 -0.9927 0.9927 -0.121 18.7494 346.0037)" class="st0 st17 st35">O</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000008133405644686093720000016629380097849661116_);enable-background:new    ;">
                                <text transform="matrix(-7.330786e-02 -0.9973 0.9973 -7.330786e-02 16.903 330.2483)" class="st0 st17 st18">R</text>
                            </g>
                            <g style="clip-path:url(#SVGID_00000008133405644686093720000016629380097849661116_);enable-background:new    ;">
                                <text transform="matrix(-3.155029e-02 -0.9995 0.9995 -3.155029e-02 16.01 317.7102)" class="st0 st17 st18">K</text>
                            </g>
                        </g>
                        <text transform="matrix(0.5631 -0.8264 0.8264 0.5631 65.491 138.4984)" class="st0 st17 st18">I</text>
                        <text transform="matrix(0.5911 -0.8066 0.8066 0.5911 68.7919 133.5669)" class="st0 st17 st18">N</text>
                        <text transform="matrix(0.6254 -0.7803 0.7803 0.6254 77.314 122.0407)" class="st0 st17 st18">T</text>
                        <text transform="matrix(0.6542 -0.7563 0.7563 0.6542 84.4471 113.1923)" class="st0 st17 st36">E</text>
                        <text transform="matrix(0.6873 -0.7263 0.7263 0.6873 91.5 104.9983)" class="st0 st17 st37">G</text>
                        <text transform="matrix(0.721 -0.693 0.693 0.721 102.353 93.6302)" class="st0 st17 st38">R</text>
                        <text transform="matrix(0.7423 -0.67 0.67 0.7423 111.5021 84.9389)" class="st0 st17 st18">I</text>
                        <text transform="matrix(0.7616 -0.6481 0.6481 0.7616 115.897 80.9459)" class="st0 st17 st18">T</text>
                        <text transform="matrix(0.787 -0.617 0.617 0.787 124.5519 73.6042)" class="st0 st17 st18">Y</text>
                        <g>
                            <defs>
                                <rect id="SVGID_00000018949550644557755150000002991746806986218640_" width="620.9" height="620.8"></rect>
                            </defs>
                            <clipPath id="SVGID_00000129198244926402998520000000174836063290963854_">
                                <use xlink:href="#SVGID_00000018949550644557755150000002991746806986218640_" style="overflow:visible;"></use>
                            </clipPath>
                            <path id="Path_31790" style="clip-path:url(#SVGID_00000129198244926402998520000000174836063290963854_);fill:none;stroke:#FFFFFF;stroke-width:1.5;" d="
                                M9,304.7c0-0.7,0-1.4,0-2c-0.1-57.2,16.2-113.3,46.8-161.6"></path>
                            <path id="Path_31791" style="clip-path:url(#SVGID_00000129198244926402998520000000174836063290963854_);fill:none;stroke:#FFFFFF;stroke-width:1.5;" d="
                                M206.9,586.2c-77.4-28.5-139.8-87.4-172.8-162.9"></path>
                            <path id="Path_31792" style="clip-path:url(#SVGID_00000129198244926402998520000000174836063290963854_);fill:none;stroke:#FFFFFF;stroke-width:1.5;" d="
                                M592,413.5c-31.3,79-94.4,141.3-173.8,171.5"></path>
                            <path id="Path_31793" style="clip-path:url(#SVGID_00000129198244926402998520000000174836063290963854_);fill:none;stroke:#FFFFFF;stroke-width:1.5;" d="
                                M565.5,140.1c31.1,48.5,47.6,105,47.5,162.6c0,6.7-0.2,13.3-0.6,19.9"></path>
                            <path id="Path_31794" style="clip-path:url(#SVGID_00000129198244926402998520000000174836063290963854_);fill:none;stroke:#FFFFFF;stroke-width:1.5;" d="
                                M497,64.8c1,0.8,1.9,1.5,2.9,2.3"></path>
                            <path id="Path_31795" style="clip-path:url(#SVGID_00000129198244926402998520000000174836063290963854_);fill:none;stroke:#FFFFFF;stroke-width:1.5;" d="
                                M133.6,58.3C242.6-20.8,390.9-18.2,497,64.8"></path>
                        </g>
                        <text transform="matrix(1 0 0 1 303.8 170.2939)" class="st0 st40 st41 st42">1</text>
                        <text transform="matrix(1 0 0 1 266.076 236.2939)" class="st0 st40 st41 st42">2</text>
                        <text transform="matrix(1 0 0 1 226.236 305.2939)" class="st0 st40 st41 st42">4</text>
                        <text transform="matrix(1 0 0 1 188.28 375.2939)" class="st0 st40 st41 st42">7</text>
                        <text transform="matrix(1 0 0 1 302.332 305.2939)" class="st0 st40 st41 st42">5</text>
                        <text transform="matrix(1 0 0 1 261.344 375.2939)" class="st0 st40 st41 st42">8</text>
                        <text transform="matrix(1 0 0 1 375.296 305.2939)" class="st0 st40 st41 st42">6</text>
                        <text transform="matrix(1 0 0 1 337.38 375.2939)" class="st0 st40 st41 st42">9</text>
                        <text transform="matrix(1 0 0 1 406.428 375.2939)" class="st0 st40 st41 st42">10</text>
                        <text transform="matrix(1 0 0 1 337.872 236.2939)" class="st0 st40 st41 st42">3</text>
                    </svg>
                </div>
            </div>
        
            <div class="rules pt-3 pb-5">
                <h3 class="display-5 medium text-center text-white text-uppercase pt-4 pb-5">The ten golden rules</h3>                
                <div class="row">
                    <div class="col-md-4">
                        <ul class="p-0 m-0">
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">1</div>
                                I aim, therefore I am
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">2</div>
                                The greatest among you
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">3</div>
                                Exceptional execution
                            </li>                        
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <ul class="p-0 m-0">
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">4</div>
                                Imagine the next big thing
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">5</div>
                                Honey as money
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">6</div>
                                Design organically
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">7</div>
                                Collaborate to accelerate
                            </li>                        
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <ul class="p-0 m-0">
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">8</div>
                                Delight the stakeholder
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">9</div>
                                No risk, no gain
                            </li>
                            <li class="text-white d-flex align-items-center pt-2 pb-2">
                                <div class="num d-flex justify-content-center align-items-center">10</div>
                                Share your story
                            </li>                        
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>
</app-performance>