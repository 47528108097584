import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { EmployeeProfilePhotoService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/upload-profile-image/services/employee-profile-photo.service';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-succession-image',
  templateUrl: './succession-image.component.html',
  styleUrls: ['./succession-image.component.scss']
})
export class SuccessionImageComponent implements OnInit {
  @Input() employeeID: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  wrongFileTypeUploaded: boolean = false;

  constructor(
    private snackbarService: SnackbarService,
    private imageService: EmployeeProfilePhotoService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    
  }

  //return a promise that resolves with a File instance
  urltoFile(url, filename, mimeType){
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
  }

  upload() {
    this.urltoFile(this.croppedImage, `${this.employeeID}-Profile-Picture.${base64ToFile(this.croppedImage).type}`, base64ToFile(this.croppedImage).type)
    .then((file) => { 
      this.imageService.uploadProfilePhoto(file, this.employeeID)
      .subscribe(
        (res) => {
          console.log(res);
        },
        err => {
          this.snackbarService.openSnackBar('There was an error uploading the profile image', 'clear', 'error');
        },
        () => {
          this.snackbarService.openSnackBar('Profile image uploaded successfully', 'clear', 'success');
        }
      );
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  imageLoaded() {
      this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
  }

  loadImageFailed() {
    this.imageChangedEvent = '';
    this.snackbarService.openSnackBar('Wrong filetype chosen. (only png, gif and jpg are allowed)', 'clear', 'error');
  }

  rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }


  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
      this.transform = {
          ...this.transform,
          rotate: this.rotation
      };
  }
}
