import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { FileViewerDialogComponent } from '@app/shared/components/file-viewer-dialog/file-viewer-dialog.component';
import { PdfViewerDialogComponent } from '@app/shared/components/pdf-viewer-dialog/pdf-viewer-dialog.component';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { Document, DocumentUpdate } from './models/document.model';
import { DocumentSiteUploadDialogComponent } from './components/document-site-upload-dialog/document-site-upload-dialog.component';
import { DirectorySiteFormDialogComponent } from './components/directory-site-form-dialog/directory-site-form-dialog.component';
import { EditSiteDocumentDialogComponent } from './components/edit-site-document-dialog/edit-site-document-dialog.component';
import { SiteDocumentsService } from './services/site-documents.service';
import * as fileSaver from 'file-saver';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-site-settings-documents',
  templateUrl: './site-settings-documents.component.html',
  styleUrls: ['./site-settings-documents.component.scss']
})
export class SiteSettingsDocumentsComponent implements OnInit {
  employeeID: string;
  refreshView: boolean;
  selectedView: string = 'treeView';

  constructor(
    private siteDocumentsService: SiteDocumentsService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
      // TODO insert system user ID here
      this.employeeID = "usr_GWGmNiX25fBVvk"
  }

  createDirectory(name: string, parentDirectoryID: string) {
    this.siteDocumentsService.uploadDocument(this.employeeID, null, name, parentDirectoryID)
    .subscribe(
      (res) => {
        this.refreshView = !this.refreshView;
        this.snackbarService.openSnackBar('Directory saved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  download(document: Document) {
    this.overlayService.show('Downloading');
    this.siteDocumentsService.downloadDocument(this.employeeID, document.id)
    .subscribe(
      response => {
        console.log(response.headers);

        let blob:any = new Blob([response.body], { type: document.contentType });
        fileSaver.saveAs(blob, document.name);
        this.overlayService.hide();

		  }
    ),
    err => this.snackbarService.openSnackBar(err, 'clear', 'warn');
  }

  delete(id: string) {
    this.siteDocumentsService.delete(this.employeeID, id)
    .subscribe(
      (res) => {
        this.refreshView = !this.refreshView;
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  moveItem(details: {'destination': Document, 'itemToMove': Document}) {
    const updateDetails: DocumentUpdate = {
      parent: details.destination ? details.destination.id : null,
      comments: details.itemToMove.comments,
      name: details.itemToMove.name
    }

    this.siteDocumentsService.updateDocument(this.employeeID, details.itemToMove.id, updateDetails)
    .subscribe(
      (res) => {
        this.refreshView = !this.refreshView;
        this.snackbarService.openSnackBar('Moved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  openConfirmDeleteDialog(itemToDelete: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to delete ${itemToDelete.name}?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete(itemToDelete.id);
        }
      }
    );
  }

  viewFile(file) {
    if(file.contentType === 'application/pdf') {
      this.openPdfViewerDialog(file, true);
    }
    else {
      this.openFileViewerDialog(file);
    }
  }

  openPdfViewerDialog(document: any, signatureRequired: boolean) {
    const dialogConfig = new MatDialogConfig();

    this.siteDocumentsService.getDocumentUrl(this.employeeID, document.id)
    .subscribe(
      (res) => {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = '80%';

        dialogConfig.data = {
            pdfUrl: res,
            signatureRequired: signatureRequired
        };

        const dialogRef = this.dialog.open(PdfViewerDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
          data => {
            if (data === true) {
              this.snackbarService.openSnackBar('Form saved successfully', 'clear', 'success');
            }
          }
        );
      },
      err => {
        console.log(err)
      }
    );
  }

  openFileViewerDialog(document: any) {
    const dialogConfig = new MatDialogConfig();

    const viewer = {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'office',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'office',
      'application/vnd.oasis.opendocument.spreadsheet': 'office',
      'application/msword': 'office',
      'text/csv': 'google',
      'application/zip': 'google',
      'image/jpeg': 'google'
    }

    this.siteDocumentsService.getDocumentUrl(this.employeeID, document.id)
    .subscribe(
      (res) => {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = '80%';

        dialogConfig.data = {
            fileUrl: res,
            viewer: viewer[document.contentType]
        };

        const dialogRef = this.dialog.open(FileViewerDialogComponent, dialogConfig);

      },
      err => {
        console.log(err)
      }
    );
  }

  openDirectoryFormDialog(parentDirectory?: Directory) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    const dialogRef = this.dialog.open(DirectorySiteFormDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data && data.directoryName){
          this.createDirectory(data.directoryName, parentDirectory ? parentDirectory.id : null);
        }
      }
    );
  }

  openDocumentUploadDialog(parentDirectory?: Directory) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeId: this.employeeID,
      parentDirectory: parentDirectory ? parentDirectory.id : null,
    };

    const dialogRef = this.dialog.open(DocumentSiteUploadDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data) {
          this.refreshView = !this.refreshView;
          this.snackbarService.openSnackBar('Document uploaded', 'clear', 'success');
        }
      }
    );
  }

  openDocumentEditDialog(document: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeId: this.employeeID,
      document: document
    };

    const dialogRef = this.dialog.open(EditSiteDocumentDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.refreshView = !this.refreshView;
          this.snackbarService.openSnackBar('Document updated', 'clear', 'success');
        }
      }
    );
  }
}
