import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Letter } from '@app/modules/letter-generator/models/leter.model';
import { LetterService } from '@app/modules/letter-generator/services/letter.service';
import { WorkflowTask } from '@app/modules/workflow-section/models/workflows.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-letter-task-item',
  templateUrl: './letter-task-item.component.html',
  styleUrls: ['./letter-task-item.component.scss']
})
export class LetterTaskItemComponent implements OnInit {
  @Input() task: WorkflowTask;
  
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  isLoading: boolean = true;
  selectedLetter: Letter;

  constructor(
    private letterService: LetterService
  ) { }

  ngOnInit(): void {
    this.getLetters();
  }

  getLetters() {
    this.isLoading = true;

    this.letterService.getLetters()
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe( 
      res => {
        if(this.task?.letter) {
          this.selectedLetter = res.find(letter => letter.id === this.task.letter);
        }
      }
    );
  }

  checkboxChange($event: MatCheckboxChange) {
    this.checkedChange.emit($event.checked);
  } 

}
