import {TimeOffPolicy} from '@app/modules/lookup/models/time-off-policy.model';
import {TimeOff} from '@app/modules/lookup/models/time-off.model';

export class PagedData<T> {
    skip: number;
    take: number;
    totalPages?: number;
    total: number;
    data: T[];
}

