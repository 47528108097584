<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5>{{title}}</h5>
    </mat-card-title>

    <mat-card-content class="chart__content">
        <div id="chart">
            <apx-chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [xaxis]="chartOptions.xaxis"
                [yaxis]="chartOptions.yaxis"
                [tooltip]="chartOptions.tooltip"
                [grid]="chartOptions.grid"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>


