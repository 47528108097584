import {Component, OnInit, ViewChild} from '@angular/core';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {finalize} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {routes} from '@app/consts';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {
    Holiday,
    HolidayGroupSubmit,
    HolidayGroupVerbose,
    HolidaySubmit,
    HolidayVerbose
} from '@app/modules/statutory-holidays/models/statutory-holidays.model';
import {StatutoryHolidaysService} from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import {defer, forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FormGeneratorDialogComponent} from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import {FormGeneratorComponent} from '@app/shared/components/form-generator/form-generator.component';
import {DataTriggerRule} from '@app/modules/data-trigger-rules/models/data-trigger-rules.model';
import {MetadataFormService} from '@app/core/services/metadata/metadata-form.service';
import {DataTriggerRulesService} from '@app/modules/data-trigger-rules/services/data-trigger-rules.service';
import {FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {cloneDeep} from 'lodash';
import * as moment from 'moment/moment';
import {MetadataTableService} from '@app/core/services/metadata/metadata-table.service';
import {EmailNotificationsService} from '@app/modules/site-settings/email-notifications/services/email-notifications.service';
import {LookupService} from '@app/modules/lookup/services/lookup.service';
import {TableListFieldOption} from '@app/modules/lookup/models/lookup.model';

@Component({
    selector: 'app-data-trigger-rules-editor',
    templateUrl: './data-trigger-rules-editor.component.html',
    styleUrls: ['./data-trigger-rules-editor.component.scss']
})
export class DataTriggerRulesEditorComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent;

    public holidayGroupId: string;
    public dataTriggerGroupID: string;
    public isLoading: boolean;
    public holidayGroup: HolidayGroupVerbose = null;
    public holidays: DataTriggerRule[] = [];
    public gridDataResult: GridDataResult = {
        data: [],
        total: 0,
    };
    public forms: any[] = [];
    public fields: any[] = [];
    public actions: any[] = [];
    public conditions: any[] = [];

    public outcomes: any[] = [];

    private emailNotificationFields: any[] = [];

    public triggerName: string;
    public triggerDescription: string;
    public currentTrigger: DataTriggerRule = null;
    formGroupData: UntypedFormGroup;


    public rules: any[] = [];

    public pageSize = 20;
    public skip = '0';

    public columns: any[] = [
        {field: 'name', title: 'Name', type: 'navigation'},
        {field: 'startDate', title: 'Start Date', type: 'date'},
        {field: 'endDate', title: 'End Date', type: 'date'},
        {field: 'hourOverride', title: 'Hours Override'},
        {field: 'notes', title: 'Notes'},
    ];
    formId = 'frm_0bC3Q4XDVP2UZS';
    getFormData = false;
    formValid = false;
    formData: any;
    dialogRef: any;
    isLoadingHoliday: boolean;
    clearSelectedItems = false;

    constructor(
        private fb: FormBuilder,
        private dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private translate: TranslateService,
        private formService: MetadataFormService,
        private tableService: MetadataTableService,
        private dataTriggerRulesService: DataTriggerRulesService,
        private emailNotificationsService: EmailNotificationsService,
        private lookupService: LookupService,

    ) {
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.dataTriggerGroupID = this.getIdInURL();

        this.getForms();
        this.getEmailNotificationFields();

        if (this.dataTriggerGroupID !== null) {
            this.currentTrigger = this.dataTriggerRulesService.getDataTriggerRule(this.dataTriggerGroupID);
            this.currentTrigger.rules.forEach((rule, index) => {
                this.getFormFields(rule.tableId, rule, true);
                rule.filteredDropdowns = [];

            });

            this.currentTrigger.outcomes.forEach((outcome, index) => {
                outcome.filteredDropdowns = [];
                if (outcome.actionId === 'setValue') {
                    outcome.selectedType = 'setValue';
                    this.getFormFields(outcome.tableId, outcome, true);
                } else if (outcome.actionId === 'triggerNotification') {
                    outcome.selectedType = 'triggerNotification';
                }
            });
        } else {
            this.currentTrigger = {
                id: '2crh5b234hbv',
                name: '',
                description: ' ',
                createdBy: 'usr_33xh1RLZykBym3',
                createdByName: 'Kevin Abdoulaye',
                createdOn: '2023-02-10T00:00:00',
                rules: [
                    {
                        id: '3454fdf34',
                        fieldValue: '',
                        fieldValue2: '',
                        formId: '',
                        fieldId: '',
                        conditionId: '-than',
                        dataType: '',
                        currentField: '',
                        tableId: '',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00',
                    }],
                outcomes: [
                    {
                        id: 'dfgrt4t5',
                        actionId: '',
                        fieldValue: '',
                        fieldValue2: '',
                        formId: '',
                        tableId: '',
                        fieldId: '',
                        dataType: '',
                        currentField: '',
                        createdBy: '',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }]
            };

        }
        this.getActions();
        this.triggerName = '';
        this.triggerDescription = '';


    }

    getForms(): any {
        this.formService.getForms(parseInt(this.skip), String(300), '', 'name-asc')
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            ).subscribe(
            res => {

                const filteredData = res.data.filter((item) => {
                    return item.table != null && item.table !== '';
                });

                this.forms = filteredData;
                this.rules.forEach((rule, index) => {
                    this.rules[index].filteredForms = filteredData;
                });

                this.outcomes.forEach((rule, index) => {
                    this.outcomes[index].filteredForms = filteredData;
                });
            }
        );
    }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('ruleId')
        );

        return IdInURL;
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }


    navigateBack() {
        this.router.navigate([`${routes.SITE_SETTINGS}/${routes.DATA_TRIGGER_RULES}`]);
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip.toString();
        this.pageSize = event.take;
        // this.getHolidayDays();
    }

    onFormChange(event: any, source: any) {
        this.forms.forEach((form, index) => {
            if (form.id === event.value) {
                this.getFormFields(form.table.id, source);
            }
        });
    }

    getFormFields(formId: string, source: any, initialLoad?: boolean) {

        this.tableService.getTableFields(formId, parseInt(this.skip), String(200)).pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
            .subscribe(
                res => {
                    const dataTypes = ['DATETIME', 'TEXT', 'DECIMAL', 'DROPDOWN'];
                    const filteredData = res.data.filter((item) => {
                        return item.fieldType && dataTypes.includes(item.fieldType.id);
                    });
                    source.filteredFields = filteredData;
                    if (!initialLoad) {
                        source.filteredConditions = [];
                        source.fieldValue = '';
                        source.filteredDropdowns = [];
                        source.dataType = '';
                    }
                    this.getConditions(source);

                    if (source.dataType == 'DROPDOWN') {
                        let currentField = null;
                        source.filteredFields.forEach((field, index) => {
                            if (field.id === source.fieldId) {
                                currentField = field;
                            }
                        });
                        this.getDropdownFields(currentField.properties[0].value, source);
                    }
                }
            );
    }

    onFieldChange(event: any, source: any) {
        let fieldType = null;
        source.filteredFields.forEach((field, index) => {
            if (field.id === event.value) {
                fieldType = field.fieldType.id;
            }
        });
        const filteredConditions = this.conditions.filter((item) => {
            return item.conditions && item.dataType === fieldType;
        }).map((item) => item.conditions);
        source.filteredConditions = filteredConditions[0];
        source.dataType = fieldType;
        source.currentField = fieldType;
        source.filteredDropdowns = [];
    }

    onFieldChangeOutcome(event: any, source: any) {
        let selectedField = null;
        source.filteredFields.forEach((field, index) => {
            if (field.id === event.value) {
                selectedField = field;
            }
        });
        source.dataType = selectedField.fieldType.id;
        source.currentField = selectedField.fieldType.id;
        source.conditionId = '';
        this.getDropdownFields(selectedField, source);


        if (source.dataType == 'DROPDOWN') {
            this.getDropdownFields(selectedField.properties[0].value, source);
        }
    }


    onConditionChange(event: any, source: any) {
        if (event.value == 'days-before' || event.value == 'days-after') {
            source.dataType = 'DECIMAL';
            source.conditionId = 'DECIMAL';
        } else if (event.value == 'between') {
            source.dataType = 'DOUBLE-DECIMAL';
            source.conditionId = 'DOUBLE-DECIMAL';
        } else if (event.value == 'is-empty' || event.value == 'is-not-empty') {
            source.dataType = 'EMPTY';
            source.conditionId = 'EMPTY';
        } else if (event.value == 'date-equals') {
            source.dataType = 'DATETIME';
            source.conditionId = 'DATETIME';
        } else {
            source.dataType = source.currentField;
            // source.conditionId = source.currentField;
        }

        if (source.dataType == 'DROPDOWN') {
            let currentField = null;
            source.filteredFields.forEach((field, index) => {
                if (field.id === source.fieldId) {
                    currentField = field;
                }
            });
            this.getDropdownFields(currentField.properties[0].value, source);
        }

        source.fieldValue = '';
        source.fieldValue2 = '';
    }


    getActions() {

        this.dataTriggerRulesService.getAvailableActions(this.skip, String(300))
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.actions = res.data;

                    this.currentTrigger.outcomes.forEach((outcome, index) => {
                        outcome.types = res.data;
                    });
                }
            );
    }


    getConditions(source: any) {

        this.dataTriggerRulesService.getConditions(this.skip, String(300))
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.conditions = res.data;


                    // source.forEach((rule, index) => {
                    let fieldType = null;
                    source.filteredFields.forEach((field,) => {
                        if (field.id === source.fieldId) {
                            fieldType = field.fieldType.id;
                        }
                    });
                    const filteredConditions = this.conditions.filter((item) => {
                        return item.conditions && item.dataType === fieldType;
                    }).map((item) => item.conditions);
                    source.filteredConditions = filteredConditions[0];
                    if (source.conditionId && (source.conditionId == 'days-before' || source.conditionId == 'days-after')) {

                    } else {
                        source.dataType = fieldType;
                        source.currentField = fieldType;
                        source.conditionId = source.conditionId;
                    }

                    // source.dataType = fieldType;
                    // source.currentField = fieldType;
                    // source.conditionId = source.conditionId;

                    // });
                }
            );
    }

    getEmailNotificationFields() {
        this.emailNotificationsService.getEmailNotifications()
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    // this.currentTrigger.outcomes.forEach((outcome, index) => {
                    //     outcome.emailNotificationFields = res;
                    // });
                    this.emailNotificationFields = res;
                }
            );
    }

    onActionTypeChange(event: any, source: any) {
        source.selectedType = event.value;

        if (event.value == 'triggerNotification') {
            source.fieldValue = '';
            // this.getEmailNotificationFields();

            source.filteredFields = [];
            source.filteredConditions = [];
            source.fieldValue = '';
        }
    }


    addNewRule() {

        this.currentTrigger.rules.push({
            fieldValue: '',
            fieldValue2: '',
            filteredForms: this.forms,
            filteredFields: this.fields,
            filteredConditions: this.conditions,
            filteredDropdowns: [],
            dataType: '',
            currentField: '',
            tableId: '',
            expanded: true,
        });
    }

    addNewOutcome() {
        this.currentTrigger.outcomes.push({
            fieldValue: '',
            fieldValue2: '',
            types: this.actions,
            filteredForms: this.forms,
            filteredFields: this.fields,
            filteredConditions: this.conditions,
            filteredDropdowns: [],
            actionType: '',
            dataType: '',
            currentField: '',
            expanded: true,
        });
    }

    getDropdownFields(value: string, source: any) {
        this.lookupService.getTableListFieldOptions(value, parseInt(this.skip), String(200)).pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
            .subscribe(
                res => {
                    if (res.data) {
                        source.filteredDropdowns = res.data;
                    } else {
                        source.filteredDropdowns = res;
                    }
                }
            );
    }


    deleteRule(index: number) {
        this.currentTrigger.rules.splice(index, 1);
    }

    deleteOutcome(index: number) {
        this.currentTrigger.outcomes.splice(index, 1);
    }

    createForm() {
        this.formGroupData = this.fb.group({
            forms: this.fb.array([]),
            rules: this.fb.array([]),
            name: this.fb.array([]),
            properties: this.fb.array([]),
            formId: [this.formId],
            changeReason: [''],
            changeReasonComments: [''],
        });
    }

    save() {
        this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
    }
}
