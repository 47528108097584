<div class="content" *ngIf="!refresh">
    <div class="top">
        <app-employee-image class="profile-pic" [employeeId]="user$.userId" round="true" width="200" height="200"></app-employee-image>
        <button 
            mat-mini-fab 
            color="primary" 
            setColor
            [setColorRules]="[
            {
                color: 'primaryColour',
                style: 'background'
            }
            ]" 
            class="edit-button"
            (click)="openFormDialog()">
            <mat-icon class="material-icons-outlined">edit</mat-icon>
        </button>
    </div>

    <div class="middle">
        <div class="about-me-text" [innerHTML]="aboutMeValue"></div>
        <button 
            mat-mini-fab 
            color="primary" 
            setColor
            [setColorRules]="[
            {
                color: 'primaryColour',
                style: 'background'
            }
            ]" 
            class="edit-button"
            (click)="showEditor = true">
            <mat-icon class="material-icons-outlined">edit</mat-icon>
        </button>

        <div *ngIf="showEditor"  class="editor-container">
            <kendo-editor [(value)]="aboutMeValue"></kendo-editor>

            <button 
                mat-raised-button 
                appPreventDoubleClick 
                (throttledClick)="showEditor = false" >
                {{ 'Cancel' | translate: {Default: "Cancel"} }}
            </button>
        </div>

    </div>

    <div class="bottom">
        <div class="icons">
            <img class="reirect__logo-img" src="./assets/icons/about-me/facebook.svg" alt="logo">
            <img class="reirect__logo-img" src="./assets/icons/about-me/instagram.svg" alt="logo">
            <img class="reirect__logo-img" src="./assets/icons/about-me/linkedin.svg" alt="logo">
            <img class="reirect__logo-img" src="./assets/icons/about-me/twitterx.svg" alt="logo">

            <button 
                mat-mini-fab 
                color="primary" 
                setColor
                [setColorRules]="[
                {
                    color: 'primaryColour',
                    style: 'background'
                }
                ]" 
                class="edit-button"
                (click)="displayForm()">
                <mat-icon class="material-icons-outlined">edit</mat-icon>
            </button>
        </div>

        <div class="form" *ngIf="showForm">

            <app-form-generator 
                [formId]="formId"
                [formData]="formData"
                [emitFormDataTrigger]="getFormData"
                [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                (emitFormData)="formDataEmitted($event)"
                (emitFormStatus)="formStatusUpdated($event)"
                (emitFormPristine)="formPristineEmitted($event)"
            ></app-form-generator>

            <div class="buttons" *ngIf="showButtons">
                <button 
                    mat-raised-button 
                    appPreventDoubleClick 
                    (throttledClick)="showForm = false" >
                    {{ 'Cancel' | translate: {Default: "Cancel"} }}
                </button>

                <button 
                    mat-raised-button 
                    setColor="primaryColour" 
                    color="primary" 
                    [disabled]="formValid === false" 
                    appPreventDoubleClick 
                    (throttledClick)="getFormData = !getFormData" >
                    {{ 'Save' | translate: {Default: "Save"} }}
                </button>


            </div>
        </div>
    </div>
</div>