import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-goals-guide-dialog',
  templateUrl: './goals-guide-dialog.component.html',
  styleUrls: ['./goals-guide-dialog.component.scss']
})
export class GoalsGuideDialogComponent implements OnInit {
  guideTitle: string;
  htmlContent: any;

  constructor(
    private dialogRef: MatDialogRef<GoalsGuideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.guideTitle = data.guideTitle;
    this.htmlContent = data.htmlContent;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

}
