<app-site-settings-menu>
    <mat-card class="main mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'IntegrationAnalytics' | translate: {Default: "Integration Analytics"} }}: {{integration.name}}</h5>

            <button mat-flat-button color="primary" routerLink="{{routes.SITE_SETTINGS}}{{routes.INTEGRATIONS}}{{routes.INTEGRATIONS_LIST}}">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">arrow_back</mat-icon> Return To Integrations
            </button>
        </mat-card-title>
        <mat-card-content>


            <app-brush-chart></app-brush-chart>
            
            <div class="bottom">
                <app-errors-chart></app-errors-chart>
                
                <mat-card class="chart-card mat-elevation-z0">
                    <mat-card-title class="header">
                        <h6>{{ 'FailedRequests' | translate: {Default: "Failed Requests"} }}</h6>
                    </mat-card-title>
                    <mat-card-content>
                        <apx-chart
                            [series]="failedChartOptions.series"
                            [chart]="failedChartOptions.chart"
                            [xaxis]="failedChartOptions.xaxis"
                        ></apx-chart>
                    </mat-card-content>
                </mat-card>
            </div>


            <!-- <mat-card class="chart-card mat-elevation-z0">
                <mat-card-title class="header">
                    <h6>{{ 'TotalRequests' | translate: {Default: "Total Requests"} }}</h6>
                </mat-card-title>
                <mat-card-content>
                    <apx-chart
                        [series]="chartOptions.series"
                        [chart]="chartOptions.chart"
                        [xaxis]="chartOptions.xaxis"
                    ></apx-chart>
                </mat-card-content>
            </mat-card> -->


        </mat-card-content>
    </mat-card>
</app-site-settings-menu>