<mat-card>
    <mat-card-title class="header">
      <h5 class="title">Company Announcements</h5>
        
      <mat-form-field appearance="outline">
        <mat-label>Filter</mat-label>
        <mat-select [(ngModel)]="searchPeriod">
          <mat-option value="thisMonth">This Month</mat-option>
          <mat-option value="thisWeek">This Week</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-card-title>
  
    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="company-announcement" role="listitem" *ngFor="let companyAnnouncement of companyAnnouncements">
                    <div class="company-announcement-details">
                        <mat-icon class="material-icons-outlined">{{companyAnnouncement.icon}}</mat-icon>
                        
                        <div>
                            <span class="topic">
                                {{companyAnnouncement.topic}}
                            </span>
                            <div class="date">
                                {{companyAnnouncement.date | date:'mediumDate'}}
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
            <ng-container *ngIf="companyAnnouncements.length === 0">
                <h6>No company announcements</h6>
            </ng-container>
        </ng-template>
    </mat-card-content>
</mat-card>
  