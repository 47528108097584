import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";
import { EmployeeGoal, EmployeeGoalSubmit } from "@app/modules/performance/components/goal-plans/models/goal-plan.model";
import { GoalDialogComponent } from "@app/modules/performance/components/goal-dialog/goal-dialog.component";
import { ReviewsService } from '@app/modules/performance/components/reviews/services/reviews.service';

@Component({
  selector: 'app-manager-review-widget',
  templateUrl: './manager-review-widget.component.html',
  styleUrls: ['./manager-review-widget.component.scss']
})
export class ManagerReviewWidgetComponent implements OnInit {
  public columns: any[] = [
    { field: "review", title: "Review", type: "longText" },
    { field: "rating", title: "Overall Rating",  },
    { field: "goalPlan", title: "Goal Plan", subField: "name" },
    { field: "reviewer", subField: "name", title: "Reviewer",},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: string = '0';
  clearSelectedItems: boolean = false;
  dialogRef: any;
  formValid: any;
  user$: any;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private overlayService: OverlayService,
    private oidcAuthService: OidcAuthService,
    private goalService: GoalPlanService,
    private reviewsService: ReviewsService
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.view = this.getData();
  }

  getData() {
    this.isLoading = true;

    this.reviewsService.getReviews(this.user$.userId, this.pageSize, this.skip)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {

          // res.employeeGoals.data.map( employeeGoal => employeeGoal.employeeId = res.employeeId )
          //
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          // this.gridData = res.employeeGoals.data;
        }
      );
  }

  getGoalVerbose(goal?: EmployeeGoal) {
    if(goal === undefined){
      this.openReviewDialog();
    }
    else {
      let employeeGoal: EmployeeGoal;

      this.isLoading = true;

      this.goalService.getEmployeeGoal(this.user$.userId, goal.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          employeeGoal = res;
          this.openReviewDialog(employeeGoal);
        }
      );
    }
  }

  openReviewDialog(goal?: EmployeeGoal) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      goal: goal,
      employeeId: goal?.employee.id || this.user$.userId
    };

    this.dialogRef = this.dialog.open(GoalDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.getData();
        }
      }
    );

  }

  // openGoalDialog(goal?: EmployeeGoal) {
  //   let formData = {
  //     id: goal ? goal.id : null,
  //     description: goal ? goal.description : null,
  //     percentageComplete: goal ? goal.percentageComplete : null,
  //     expectedCompletionDate: goal ? goal.expectedCompletionDate : null,
  //     actualCompletionDate: goal ? goal.actualCompletionDate : null,
  //     goalTypeId: goal ? goal.goalType?.id : null,
  //   };

  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     formTitle: `${this.translate.instant('Goal')}`,
  //     formId: 'frm_iel8cV73NvPu4w',
  //     formData: formData
  //   };

  //   this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

  //   const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
  //     goal ? this.updateGoal(event) : this.createNewGoal(event)
  //   });
  // }

  createNewGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.createEmployeeGoal(this.user$.userId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getData();
        }
    );
  }

  updateGoal(formData: EmployeeGoalSubmit){
    this.overlayService.show();

    this.goalService.updateEmployeeGoal(this.user$.userId, formData)
    .pipe(
        finalize(() => this.overlayService.hide())
    )
    .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(res);
          this.getData();
        }
    );
  }

  deleteGoals(goalIds: string[]) {
    const observables = goalIds.map(selectedItem => defer(() => this.goalService.deleteEmployeeGoal(this.user$.userId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getData();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip.toString();
    this.pageSize = event.take
    this.view = this.getData();
  }
}
