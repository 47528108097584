import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-employee-personal-details',
    templateUrl: './employee-personal-details.component.html',
    styleUrls: ['./employee-personal-details.component.scss']
})
export class EmployeePersonalDetailsComponent implements OnInit {
  employeeId;

  constructor(
    private route: ActivatedRoute,
  ) { 
    this.employeeId = this.getIdInURL();
  }

  ngOnInit(): void {
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

}
