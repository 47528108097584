import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmailNotification } from "@app/modules/site-settings/email-notifications/models/email-notification.model";
import { random } from "lodash";

@Component({
    selector: 'app-email-notifications-dialog',
    templateUrl: './email-notifications-dialog.component.html',
    styleUrls: ['./email-notifications-dialog.component.scss']
})
export class EmailNotificationsDialogComponent implements OnInit {
    employmentRecordId: string;
    employeeId: string;
    isLoading: boolean;
    form: UntypedFormGroup;
    notification: EmailNotification;
    recipientsList: string[] = [
        'john@example.com',
        'jane@example.com',
        'bob@example.com',
        'alice@example.com',
        'dave@example.com',
        'mary@example.com',
        'sam@example.com',
        'all-staff@example.com',
        'hr-staff@example.com',
        'marketing-team@example.com',
        'sales-team@example.com',
        'engineering-team@example.com',
        'customer-service@example.com',
        'finance-dept@example.com',
        'legal-team@example.com',
        'operations-group@example.com',
        'support-team@example.com',
        'management-team@example.com',
    ];

    constructor(
        private fb: UntypedFormBuilder,
        private lookupService: LookupService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private dialogRef: MatDialogRef<EmailNotificationsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.notification = data.notification;
    }

    ngOnInit(): void {

        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            active: [this.notification?.active ?? null, Validators.required],
            reminderPriorDays: [this.notification?.reminderPriorDays ?? null, Validators.required],
            sendEveryDays: [this.notification?.sendEveryDays ?? null, Validators.required],
            excludeTerminatedEmployees: [this.notification?.excludeTerminatedEmployees ?? null, Validators.required],
            subject: [this.notification?.subject ?? null, Validators.required],
            content: [this.notification?.content ?? null, Validators.required],
            recipients: [this.notification?.recipients ?? null, Validators.required],
        });
    }

    save() {
        this.overlayService.show();

        if (this.notification) {
            this.notification.active = this.form.value.active;
            this.notification.reminderPriorDays = this.form.value.reminderPriorDays;
            this.notification.sendEveryDays = this.form.value.sendEveryDays;
            this.notification.excludeTerminatedEmployees = this.form.value.excludeTerminatedEmployees;
            this.notification.subject = this.form.value.subject;
            this.notification.content = this.form.value.content;
            this.notification.recipients = this.form.value.recipients;
        } else {
            let notification: EmailNotification = {
                id: 100 + random(1000),
                active: this.form.value.active,
                reminderPriorDays: this.form.value.reminderPriorDays,
                sendEveryDays: this.form.value.sendEveryDays,
                excludeTerminatedEmployees: this.form.value.excludeTerminatedEmployees,
                subject: this.form.value.subject,
                content: this.form.value.content,
                recipients: this.form.value.recipients,
            }
            this.notification = notification
        }


        // wait
        setTimeout(() => {
            this.overlayService.hide();
            this.dialogRef.close({notification: this.notification});
        }, 1000);
    }

    close() {
        this.dialogRef.close();
    }
}
