import { Component, Inject, Input, OnInit } from '@angular/core';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { PerformanceReview } from '../../models/performance-review.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { ActiveReviewReviewDetailsComponent } from '../active-review-review-details/active-review-review-details.component';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-active-review',
  templateUrl: './active-review.component.html',
  styleUrls: ['./active-review.component.scss']
})
export class ActiveReviewComponent implements OnInit {
  performanceReview: PerformanceReview
  employeeContactCard$: any;
  public bindingType: String = 'array';
  isLoading: boolean;
  pageSize: number = 20;
  skip: string = '0';
  activeReviewDialogRef: MatDialogRef<ActiveReviewReviewDetailsComponent>;

  public columns: any[] = [
    {
      field: 'reviewer', 
      title: 'Name',
      type: "employee"
    }, 
    {
      field: 'order', 
      title: 'reviewer order'
    }, 
    {
      field: 'status', 
      subField: 'name',
      title: 'reviewer status',
      type: "chip",
      chipValue: [ "Complete", "Cancelled", "In Progress" ]
    }, 
  ];

  public gridDataResult: GridDataResult = {
    data: [],
    total: 0
  };

  constructor(
    private employeeContactCardService: EmployeeContactCardService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ActiveReviewComponent>,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.performanceReview = data.performanceReview;
    this.employeeContactCard$ = this.employeeContactCardService.getEmployeeContactCard(this.performanceReview.review.employee.id);

    this.gridDataResult = {
      data: this.performanceReview.review.reviewers,
      total: this.performanceReview.review.reviewers.length
    }
  }

  ngOnInit(): void {
  }

  openReviewDialog(reviewer?: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      performanceReview: this.performanceReview,
      reviewer: reviewer
    };

    this.activeReviewDialogRef = this.dialog.open(ActiveReviewReviewDetailsComponent, dialogConfig);

    this.activeReviewDialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          console.log('closed')
        }
      }
    );

  }

  navigateToReviewDetails(reviewer: any){}

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
      this.dialogRef.close('saved');
    }, 2000);
  }

  close() {
    this.dialogRef.close();
  }

}
