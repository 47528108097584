<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{ 'PtCalibrationReport' | translate: {Default: "PT Calibration Report"} }}</h1>
    </mat-toolbar>

    <div *ngIf="loadingEmployees === false" class="content">

      <div class="top">
        <h5>Select results to be displayed</h5>
        <kendo-treeview
          [nodes]="performanceReviewCategories"
          textField="text"
          kendoTreeViewHierarchyBinding
          childrenField="items"
          [filterable]="true"
          kendoTreeViewExpandable
          (selectionChange)="onSelectionChange($event)"
          [expandedKeys]="expandedKeys"
          kendoTreeViewCheckable
          [(checkedKeys)]="checkedKeys"
          (checkedKeysChange)="onCheckedKeysChange($event)"
        >
        </kendo-treeview>

      </div>
      
      <mat-button-toggle-group [(ngModel)]="selectedChart">
        <mat-button-toggle value="bar">
          <mat-icon class="material-icons-outlined">bar_chart</mat-icon>
          Bar Chart
        </mat-button-toggle>
        <mat-button-toggle value="scatter">
          <mat-icon class="material-icons-outlined">scatter_plot</mat-icon>
          Scatter Chart
        </mat-button-toggle>
        <mat-button-toggle value="heatmap">
          <mat-icon class="material-icons-outlined">map</mat-icon>
          Heatmap
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="chart-container">
        <!-- bar chart -->
        <kendo-chart *ngIf="selectedChart === 'bar'">
          <kendo-chart-legend position="bottom" orientation="horizontal">
          </kendo-chart-legend>
          <kendo-chart-series>
            <ng-container *ngFor="let category of selectedCategories">
              <kendo-chart-series-item
                type="column"
                [data]="barChartSeriesData"
                [name]="category"
                [field]="category"
                categoryField="employee"
              ></kendo-chart-series-item>
            </ng-container>
          </kendo-chart-series>
        </kendo-chart>
  
        <!-- scatter chart -->
        <kendo-chart *ngIf="selectedChart === 'scatter'" [title]="{ text: '' }">
          <kendo-chart-series>
            <kendo-chart-series-item
              *ngFor="let series of scatterChartData"
              type="scatterLine"
              name="{{ series.current }}"
              [data]="series.stats"
              [xField]="selectedCategories[0]"
              [yField]="selectedCategories[1]"
            >
            </kendo-chart-series-item>
          </kendo-chart-series>
          <kendo-chart-x-axis>
            <kendo-chart-x-axis-item
              [title]="{ text: selectedCategories[0] }"
              [labels]="{ format: '{0}' }"
              [max]="10"
            >
            </kendo-chart-x-axis-item>
          </kendo-chart-x-axis>
          <kendo-chart-y-axis>
            <kendo-chart-y-axis-item
              [title]="{ text: selectedCategories[1] }"
              [labels]="{ format: '{0}' }"
              [max]="10"
            >
            </kendo-chart-y-axis-item>
          </kendo-chart-y-axis>
          <!-- <kendo-chart-tooltip [format]="`selectedCategories[1]: {1} selectedCategories[0]: {0}`"> </kendo-chart-tooltip> -->
        </kendo-chart>
  
        <!-- heatmap chart -->
        <kendo-chart *ngIf="selectedChart === 'heatmap'">
          <kendo-chart-series>
            <kendo-chart-series-item
              type="heatmap"
              [data]="heatMapData"
              xField="a"
              yField="b"
              valueField="value"
            >
            </kendo-chart-series-item>
          </kendo-chart-series>
        </kendo-chart>
      </div>

    </div>
</app-layout>
