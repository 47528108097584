import { Component, OnInit } from '@angular/core';
import { MatDialog, } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SuccessionTrackService } from '@app/modules/succession-track/services/succession-track.service';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, } from '@progress/kendo-angular-grid';
import { finalize, map } from 'rxjs';

@Component({
  selector: 'app-succession-peer-recommendations',
  templateUrl: './succession-peer-recommendations.component.html',
  styleUrls: ['./succession-peer-recommendations.component.scss']
})
export class SuccessionPeerRecommendationsComponent implements OnInit {

  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'recommendedBy', 
      title: 'Recommended By',
      type: "employee"
    },
    {
      field: 'disciplinaryActionReason', 
      title: 'Disciplinary Action Reason'
    }, 
    {
      field: 'successor', 
      title: 'Successor',
      type: "employee"
    }, 
    {
      field: 'comments', 
      title: 'Comments'
    },  
  ];

  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  employees;
  
  constructor(
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private employeeContactCardService: EmployeeContactCardService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees(): void {
    this.isLoading = true;

    this.getRequest = this.employeeContactCardService.getAllEmployeeContactCards(this.skip, String(this.pageSize), null, null, true)
    .pipe(
      finalize(()=>{ this.isLoading = false; }),
      map(
        res => {
          this.employees = res.data;

          const modifiedItems = res.data.map(
            employee => {
              let randomEmployee = this.getRandomItem(this.employees)
              let randomEmployee2 = this.getRandomItem(this.employees)

              return {
                recommendedBy: {
                  id: randomEmployee?.id,
                  firstName: randomEmployee?.firstname,
                  lastName: randomEmployee?.lastname
                },
                disciplinaryActionReason: 'HR Approval',
                successor: {
                  id: randomEmployee2?.id,
                  firstName: randomEmployee2?.firstname,
                  lastName: randomEmployee2?.lastname
                },
                comments: 'HR feels that they would be a good possible successor but it would require approvals.'
              };
            }
          );

          res.data = modifiedItems;
          return res
        }
      )
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: [res.data[0], res.data[1]],
          total: 2,
        }
      }
    )
  }

  getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToSuccessionProfile(employee) {
    // if(employee){
    //   this.router.navigate([`${routes.SUCCESSION_TRACK}${routes.SUCCESSION_PROFILE}/${employee?.name?.id}`], {relativeTo: this.route});
    // }
    // else {
    //   this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}`], {relativeTo: this.route});
    // }
  }

  rowActionButtonClicked($event){
    if($event.id === 'analytics') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ANALYTICS}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
    else if($event.id === 'error-log') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ERROR_LOG}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
  }

  pageChange(event){}

}

