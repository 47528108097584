import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { finalize } from 'rxjs/operators';
import { Letter } from '../../models/leter.model';
import { LetterService } from '../../services/letter.service';
import { forkJoin, Observable } from 'rxjs';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { TableList } from '@app/modules/lookup/models/lookup.model';

@Component({
  selector: 'app-create-letter',
  templateUrl: './create-letter.component.html',
  styleUrls: ['./create-letter.component.scss']
})
export class CreateLetterComponent implements OnInit {
  public value = `<p> The Editor enables users to create rich textual content. </p>`;
  form: UntypedFormGroup;
  letterIdInUrl: string;
  letter: Letter;
  tables: any;
  tableList: Observable<any>
  loadingFields: boolean = true;

  constructor(
    private snackbarService: SnackbarService,
    private fb: UntypedFormBuilder,
    private letterService: LetterService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
    private lookupService: LookupService
  ) { }

  ngOnInit(): void {
    // this.tableList = this.lookupService.getTableLists();
    this.letterIdInUrl = this.getIdInURL();

    if(this.letterIdInUrl != null){
      this.getLetter();
    }
    else {
      this.createForm();
    }

    this.getTablesAndFields();
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("letterId")
    )
      
    return IdInURL;
  }

  getLetter(){
    this.letterService.getLetter(this.letterIdInUrl)
    .pipe(
      finalize( () => this.createForm() )
    )
    .subscribe(
      res => this.letter = res
    )
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.letter ? this.letter.id : null],
      name: [this.letter ? this.letter.name : null, Validators.required],
      description: [this.letter ? this.letter.description : null],
      letterHTML: [this.letter ? this.letter.letterHTML : null, Validators.required]
    });
  }

  save() {
    this.overlayService.show();
    console.log(this.form.controls.letterHTML.value);

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Letter saved successfully', 'clear', 'success');
    }, 2000);
  }

  getTablesAndFields() {
    this.lookupService.getTableLists()
    .pipe(
      finalize( () => {
          this.getFields();
        } 
      )
    )
    .subscribe(
      res => {
        this.tables = res;
      }
    );
  }

  getFields() {
    let observables: Observable<any>[] = [];

    this.tables.forEach(table => {
      observables.push(this.lookupService.getFields(table.id));
    });

    forkJoin(observables)
    .pipe(
      finalize( () => this.loadingFields = false )
    )
    .subscribe(
      (res) => {
        res.forEach((fields, index) => {
            this.tables[index].fields = fields.data;
          }
        ) 
      }
    );
  }

  /**
   * Predicate function that only allows even numbers to be
   * sorted into even indices and odd numbers at odd indices.
   */
  sortPredicate(index: number, item: CdkDrag<number>) {
    return false
  }

  drag(event: DragEvent, tableId: string, fieldId: string) {
    if (event.dataTransfer) {
      event.dataTransfer.setData('text/plain', `{${tableId} - ${fieldId}}`);
    }
  }
      
}
