<div class="survey-details">
    <form class="survey-question-form" [formGroup]="surveyQuestionDetails">

        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>

            <input matInput type="text" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Description</mat-label>

            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>

            <mat-select disableOptionCentering formControlName="type">
                <mat-option value="radio">Radio</mat-option>
                <mat-option value="radio">Checkbox</mat-option>
                <mat-option value="textarea">Text Area</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="options" *ngIf="surveyQuestionDetails.get('type').value === 'radio'">
            <div formArrayName="options">
                <div class="option" *ngFor="let control of surveyQuestionDetails.get('options')['controls']; let i=index">
                    <mat-form-field appearance="fill">
                        <input matInput type="text" [formControlName]="i">
                    </mat-form-field>
                            
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon" (click)="removeControl(i)">highlight_off</mat-icon>
                </div>
            </div>
            <button type="button" mat-raised-button (click)="addControl()"><mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add Option</button>
        </div>

        
      
        <!-- <button type="submit" [disabled]="myForm.invalid">Submit</button> -->
      </form>
</div>