<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [view]="view"
    [gridData]="gridData"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    [category]="translate.instant('Reviews')"
    canSelectRows="true"
    clickableRows="true"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    hideActionButtons="true"
    (emitPageChangeEvent)="pageChange($event)"
    (emitDeleteEvent)="deleteGoals($event)"
></app-data-grid-comp>
