<div class="container">
  <div class="left">
    <app-photo-and-details></app-photo-and-details>
  </div>

  <div class="right">
    <mat-accordion>
      <mat-expansion-panel togglePosition="before" expanded>
        <mat-expansion-panel-header>
          <h4>Introduce Yourself To The Team</h4>
        </mat-expansion-panel-header>
      
        <ng-template matExpansionPanelContent>
          <ng-container *ngIf="packet$ | async as packet">
            <ng-container *ngFor="let packetItem of packet; let i = index">
              <ng-container *ngIf="packetItem.type === 'questionnaire'">

                <div class="form-container">
                  <app-form-generator 
                      [formId]="packetItem.data.formId"
                  ></app-form-generator>
  
                  <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()">Save</button>
                </div>
                
              </ng-container>
            </ng-container>
          </ng-container>

          <app-employment-history-grid [employeeId]="user$.userId"></app-employment-history-grid>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <h4>Personal Details</h4>
          </mat-expansion-panel-header>
        
          <ng-template matExpansionPanelContent>
            <app-personal-details-form [employeeId]="user$.userId"></app-personal-details-form>
          </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <h4>Emergency Contacts</h4>
          </mat-expansion-panel-header>
        
          <ng-template matExpansionPanelContent>
            <app-emergency-contacts-grid [employeeId]="user$.userId"></app-emergency-contacts-grid>
          </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <h4>Family Dependants</h4>
        </mat-expansion-panel-header>
      
        <ng-template matExpansionPanelContent>
          <app-family-dependants-grid [employeeId]="user$.userId"></app-family-dependants-grid>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <h4>Employment Verification Letter</h4>
        </mat-expansion-panel-header>
      
        <ng-template matExpansionPanelContent>
          <app-employment-verification-letter [employeeId]="user$.userId"></app-employment-verification-letter>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>