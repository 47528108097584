import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-upload-profile-pic-dialog',
  templateUrl: './upload-profile-pic-dialog.component.html',
  styleUrls: ['./upload-profile-pic-dialog.component.scss']
})
export class UploadProfilePicDialogComponent implements OnInit {
  employeeID: any;

  constructor(
    private dialogRef: MatDialogRef<UploadProfilePicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employeeID = data.employeeID;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close(true);
  }

}
