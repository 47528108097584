import { Component, OnInit } from '@angular/core';
import {SecurityProtectedBase} from '@app/shared/components/security-protected/security-protected';
import {FeatureService} from '@app/core/services/feature.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {features, routes} from '@app/consts';
import {finalize} from 'rxjs/operators';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {StatutoryHolidaysService} from '@app/modules/statutory-holidays/services/statutory-holidays.service';
import {DataTriggerRulesService} from '@app/modules/data-trigger-rules/services/data-trigger-rules.service';
import {Organization} from '@app/modules/organization-structure/models/organization-structure.model';
import {DataTriggerRule} from '@app/modules/data-trigger-rules/models/data-trigger-rules.model';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-data-trigger-rules',
  templateUrl: './data-trigger-rules.component.html',
  styleUrls: ['./data-trigger-rules.component.scss']
})
export class DataTriggerRulesComponent  implements OnInit {
    refreshView: boolean = true;
    // public routes: typeof routes = routes;
    public bindingType: String = 'array';
    public gridDataResult: GridDataResult;
    public selectedPositions: any[] = [];

    form: UntypedFormGroup;
    isLoading: boolean;
    pageSize: number = 20;
    skip: string = '0';
    filterString: string;
    getRequest: any;
    public searchValue: string;
    clearSelectedItems: boolean = false;
    public columns: any[] = [
        {
            field: 'name',
            title: 'Name'
        },
        {
            field: 'description',
            title: 'Description'
        },
        {
            field: 'createdByName',
            title: 'Created By',
            // type: 'employee'
        },
        {
            field: 'createdOn',
            title: 'Created On',
            type: 'date'
        }
    ];

    constructor(
        featureService: FeatureService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private dataTriggerRulesService: DataTriggerRulesService,
        private translate: TranslateService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        // super(featureService, features.DATA_TRIGGER_RULES);
    }

    ngOnInit(): void {
        this.getDataTriggerRules();
    }

    getDataTriggerRules(): void {
        this.isLoading = true;

        this.getRequest = this.dataTriggerRulesService.getDataTriggerRules(this.skip, String(this.pageSize), this.filterString)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    openEditDataTriggerRules(id: string): void {
        this.router.navigateByUrl(`${routes.DATA_TRIGGER_RULES}${routes.EDIT}/${id}`);
    }

    openCreateDataTriggerRules(id: string): void {
        // this.router.navigateByUrl(`${routes.DATA_TRIGGER_RULES}${routes.CREATE_FORM}/${id}`);
    }

    navigateToDataTriggerRule(dataTriggerRule?: DataTriggerRule) {
        if (dataTriggerRule){
            this.router.navigate([`${routes.SITE_SETTINGS}${routes.DATA_TRIGGER_RULES}${routes.EDITOR}/${dataTriggerRule?.id}`]);
        }
        else {
            this.router.navigate([`${routes.SITE_SETTINGS}${routes.DATA_TRIGGER_RULES}${routes.EDITOR}`]);
        }
    }

}
