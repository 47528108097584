<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>Pay Gap Report</h1>

        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToReportGroups()">
            Back to Reports
        </button>
    </mat-toolbar>

    <div class="pay-gap-report">

        <div class="top">
            <div class="pay-gap-explanation">
                <p>
                    The pay gap is the difference in average earnings between different groups. It is often expressed as a percentage of the earnings of a reference group. The pay gap can be influenced by a variety of factors, including differences in education, experience, occupation, and hours worked. However, it can also reflect discrimination and biases in the workplace.
                </p>
                <p>
                    In this report, we analyze the pay gap across different quartiles, departments, and over the years for gender, ethnicity, and disability. The charts below provide a visual representation of the pay gap, highlighting areas where disparities are most pronounced and tracking changes over time.
                </p>
            </div>
        
            <div class="company-initiatives">
                <h3>Our Commitment to Reducing the Pay Gap</h3>
                <p>
                    At our company, we are dedicated to fostering an inclusive and equitable workplace where all employees have the opportunity to thrive. We recognize that addressing the pay gap is a multifaceted challenge that requires a comprehensive and strategic approach. Here are some of the key initiatives we are focusing on to reduce the pay gap:
                </p>
                <ul>
                    <li>
                        <strong>Transparent Pay Practices:</strong> We are committed to transparency in our pay practices. This includes conducting regular pay audits, publishing pay gap reports, and ensuring that our compensation structures are fair and equitable.
                    </li>
                    <li>
                        <strong>Inclusive Hiring and Promotion Processes:</strong> We are enhancing our recruitment and promotion processes to eliminate biases and ensure that all candidates have equal opportunities. This includes implementing blind recruitment techniques, diverse hiring panels, and clear, objective criteria for promotions.
                    </li>
                    <li>
                        <strong>Employee Development Programs:</strong> We offer a range of professional development programs designed to support the career growth of all employees. This includes mentorship programs, leadership training, and continuous learning opportunities to help employees advance in their careers.
                    </li>
                    <li>
                        <strong>Work-Life Balance Initiatives:</strong> We understand the importance of work-life balance and offer flexible working arrangements, parental leave policies, and support for employees with caregiving responsibilities. These initiatives help to create a more supportive and inclusive work environment.
                    </li>
                    <li>
                        <strong>Diversity and Inclusion Training:</strong> We provide ongoing diversity and inclusion training for all employees to raise awareness about unconscious biases and promote a culture of respect and inclusion. This training is an essential part of our efforts to create a more equitable workplace.
                    </li>
                    <li>
                        <strong>Employee Resource Groups (ERGs):</strong> We support the formation of Employee Resource Groups that provide a platform for employees to connect, share experiences, and advocate for positive change within the company. These groups play a crucial role in fostering a sense of community and belonging.
                    </li>
                    <li>
                        <strong>Regular Monitoring and Reporting:</strong> We continuously monitor our progress in reducing the pay gap and report on our findings. This helps us to identify areas for improvement and hold ourselves accountable to our goals.
                    </li>
                </ul>
                <p>
                    By implementing these initiatives, we are committed to creating a workplace where everyone is valued and has the opportunity to succeed. We believe that reducing the pay gap is not only the right thing to do but also essential for driving innovation, productivity, and overall business success.
                </p>
            </div>
        </div>

    
        <div class="employee-distribution">
            <h3>Employee Distribution</h3>
            <p>
                Understanding the distribution of employees across different demographics is crucial for identifying areas where diversity and inclusion efforts can be improved. Below, we provide a breakdown of our workforce by gender, ethnicity, and disability status.
            </p>
            <div class="distribution-charts">
                <div class="chart">
                    <kendo-chart>
                        <kendo-chart-title text="Gender Distribution"></kendo-chart-title>
                        <kendo-chart-legend position="bottom"></kendo-chart-legend>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                [autoFit]="true"
                                type="pie"
                                [data]="[
                                { category: 'Male Employees', value: 50 },
                                { category: 'Female Employees', value: 45 },
                                { category: 'Non-binary Employees', value: 5 }
                                ]"
                                field="value"
                                categoryField="category"
                                [labels]="{ visible: true, content: pieChartLabelContent }"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                    </kendo-chart>
                </div>
    
                <div class="chart">
                    <kendo-chart>
                        <kendo-chart-title text="Ethnicity Distribution"></kendo-chart-title>
                        <kendo-chart-legend position="bottom"></kendo-chart-legend>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                [autoFit]="true"
                                type="pie"
                                [data]="[
                                { category: 'White Employees', value: 60 },
                                { category: 'Global Majority Employees', value: 40 }
                                ]"
                                field="value"
                                categoryField="category"
                                [labels]="{ visible: true, content: pieChartLabelContent }"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                    </kendo-chart>
                </div>
    
                <div class="chart">
                    <kendo-chart>
                        <kendo-chart-title text="Disability Distribution"></kendo-chart-title>
                        <kendo-chart-legend position="bottom"></kendo-chart-legend>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                [autoFit]="true"
                                type="pie"
                                [data]="[
                                { category: 'Disabled Employees', value: 20 },
                                { category: 'Non-Disabled Employees', value: 80 }
                                ]"
                                field="value"
                                categoryField="category"
                                [labels]="{ visible: true, content: pieChartLabelContent }"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                    </kendo-chart>
                </div>
            </div>
        </div>
    
        <div class="pay-gap-by-department">
            <h3>Pay Gap by Department</h3>
            <p>
                The following chart shows the pay gap across different departments in our company. This helps us identify which departments have the most significant disparities and where we need to focus our efforts to ensure equitable pay practices.
            </p>
    
            <div class="distribution-charts">
                <div class="chart">
                    <kendo-chart>
                        <kendo-chart-title text="Pay Gap by Department"></kendo-chart-title>
                        <kendo-chart-category-axis>
                            <kendo-chart-category-axis-item
                                [categories]="['HR', 'Engineering', 'Sales', 'Marketing', 'Finance', 'Operations', 'IT']"
                                [title]="{ text: 'Departments' }"
                            >
                            </kendo-chart-category-axis-item>
                        </kendo-chart-category-axis>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                type="column"
                                [data]="[10, 15, 20, 25, 30, 35, 40]"
                                [name]="'Male'"
                            >
                            </kendo-chart-series-item>
                            <kendo-chart-series-item
                                type="column"
                                [data]="[8, 12, 18, 22, 28, 32, 38]"
                                [name]="'Female'"
                            >
                            </kendo-chart-series-item>
                            <kendo-chart-series-item
                                type="column"
                                [data]="[9, 14, 19, 24, 29, 34, 39]"
                                [name]="'Ethnicity'"
                            >
                            </kendo-chart-series-item>
                            <kendo-chart-series-item
                                type="column"
                                [data]="[11, 16, 21, 26, 31, 36, 41]"
                                [name]="'Disability'"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-legend position="bottom"></kendo-chart-legend>
                    </kendo-chart>
                </div>
        
                <div class="chart-row">
                    <div class="chart">
                        <kendo-chart [title]="{ text: 'Pay Gap by Department' }">
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    type="radarArea"
                                    name="Male"
                                    [data]="departmentPayGapData"
                                    field="male"
                                    categoryField="department"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="radarArea"
                                    name="Female"
                                    [data]="departmentPayGapData"
                                    field="female"
                                    categoryField="department"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="radarArea"
                                    name="Ethnicity"
                                    [data]="departmentPayGapData"
                                    field="ethnicMinority"
                                    categoryField="department"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="radarArea"
                                    name="Disability"
                                    [data]="departmentPayGapData"
                                    field="disability"
                                    categoryField="department"
                                >
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-value-axis>
                                <kendo-chart-value-axis-item
                                    [labels]="{ format: 'C0' }"
                                    [plotBands]="valuePlotBands"
                                >
                                </kendo-chart-value-axis-item>
                            </kendo-chart-value-axis>
                            <kendo-chart-legend position="bottom"></kendo-chart-legend>
                        </kendo-chart>
                    </div>
        
                    <div class="chart">
                        <kendo-chart [title]="{ text: 'Pay Gap by Department' }">
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item
                                    [categories]="['HR', 'Engineering', 'Sales', 'Marketing', 'Finance', 'Operations', 'IT']"
                                    [title]="{ text: 'Departments' }"
                                >
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
        
                            <kendo-chart-series-defaults [highlight]="{ inactiveOpacity: 0.3 }">
                            </kendo-chart-series-defaults>
        
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    type="area"
                                    [data]="[38, 34, 46, 32, 33, 37, 42]"
                                    [name]="'Male'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="area"
                                    [data]="[32, 31, 37, 30, 29, 34, 39]"
                                    [name]="'Female'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="area"
                                    [data]="[35, 33, 40, 31, 30, 36, 41]"
                                    [name]="'Ethnicity'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="area"
                                    [data]="[37, 32, 42, 29, 28, 35, 40]"
                                    [name]="'Disability'"
                                >
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                        </kendo-chart>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="pay-gap-over-years">
            <h3>Pay Gap Over the Years</h3>
            <p>
                The following chart shows the pay gap over the past five years. This helps us track our progress in reducing the pay gap and identify trends over time.
            </p>
    
            <div class="distribution-charts">
                <div class="chart">
                    <kendo-chart>
                        <kendo-chart-title text="Pay Gap Over the Years"></kendo-chart-title>
                        <kendo-chart-category-axis>
                            <kendo-chart-category-axis-item [categories]="['2019', '2020', '2021', '2022', '2023']">
                            </kendo-chart-category-axis-item>
                        </kendo-chart-category-axis>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                type="line"
                                [data]="[16, 15, 14, 16, 15]"
                                [name]="'Male'"
                            >
                            </kendo-chart-series-item>
                            <kendo-chart-series-item
                                type="line"
                                [data]="[8, 10, 11, 13, 14]"
                                [name]="'Female'"
                            >
                            </kendo-chart-series-item>
                            <kendo-chart-series-item
                                type="line"
                                [data]="[10, 12, 13, 15, 16]"
                                [name]="'Ethnicity'"
                            >
                            </kendo-chart-series-item>
                            <kendo-chart-series-item
                                type="line"
                                [data]="[12, 14, 15, 17, 18]"
                                [name]="'Disability'"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-tooltip>
                            <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                                Year: {{ value.category }} <br />
                                Pay Gap: {{ value.value }}%
                            </ng-template>
                        </kendo-chart-tooltip>
                    </kendo-chart>
                </div>
        
                <div class="chart-row">
                    <div class="chart">
                        <kendo-chart>
                            <kendo-chart-title text="Gender Distribution"></kendo-chart-title>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [categories]="['2019', '2020', '2021', '2022', '2023']">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    type="bar"
                                    [gap]="2"
                                    [spacing]="0.25"
                                    [data]="[50, 48, 46, 44, 42]"
                                    [name]="'Male'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="bar"
                                    [data]="[45, 46, 47, 48, 49]"
                                    [name]="'Female'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="bar"
                                    [data]="[5, 6, 7, 8, 9]"
                                    [name]="'Non-binary'"
                                >
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                        </kendo-chart>
                    </div>
        
                    <div class="chart">
                        <kendo-chart>
                            <kendo-chart-title text="Pay Gap"></kendo-chart-title>
                            <kendo-chart-category-axis>
                                <kendo-chart-category-axis-item [categories]="['2019', '2020', '2021', '2022', '2023']">
                                </kendo-chart-category-axis-item>
                            </kendo-chart-category-axis>
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    type="bar"
                                    [gap]="2"
                                    [spacing]="0.25"
                                    [data]="[16, 15, 14, 16, 15]"
                                    [name]="'Male'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="bar"
                                    [data]="[8, 10, 11, 13, 14]"
                                    [name]="'Female'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="bar"
                                    [data]="[10, 12, 13, 15, 16]"
                                    [name]="'Ethnicity'"
                                >
                                </kendo-chart-series-item>
                                <kendo-chart-series-item
                                    type="bar"
                                    [data]="[12, 14, 15, 17, 18]"
                                    [name]="'Disability'"
                                >
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                        </kendo-chart>
                    </div>
                </div>
            </div>
    
    
        </div>
    
        <div class="pay-gap-by-quartile">
            <h3>Pay Gap by Quartile</h3>
            <p>
                The following chart shows the pay gap across different quartiles in our company. This helps us identify which quartiles have the most significant disparities and where we need to focus our efforts to ensure equitable pay practices.
            </p>
            <div class="chart">
                <kendo-chart>
                    <kendo-chart-title text="Pay Gap by Quartile"></kendo-chart-title>
                    <kendo-chart-category-axis>
                        <kendo-chart-category-axis-item
                            [categories]="['Q1', 'Q2', 'Q3', 'Q4']"
                            [title]="{ text: 'Quartiles' }"
                        >
                        </kendo-chart-category-axis-item>
                    </kendo-chart-category-axis>
                    <kendo-chart-series>
                        <kendo-chart-series-item
                            type="column"
                            [data]="[5, 10, 15, 20]"
                            [name]="'Male'"
                        >
                        </kendo-chart-series-item>
                        <kendo-chart-series-item
                            type="column"
                            [data]="[3, 7, 12, 18]"
                            [name]="'Female'"
                        >
                        </kendo-chart-series-item>
                        <kendo-chart-series-item
                            type="column"
                            [data]="[4, 8, 13, 19]"
                            [name]="'Ethnicity'"
                        >
                        </kendo-chart-series-item>
                        <kendo-chart-series-item
                            type="column"
                            [data]="[6, 9, 14, 21]"
                            [name]="'Disability'"
                        >
                        </kendo-chart-series-item>
                    </kendo-chart-series>
                    <kendo-chart-legend position="bottom"></kendo-chart-legend>
                </kendo-chart>
            </div>
        </div>
    </div>
</app-layout>
