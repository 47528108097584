import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexPlotOptions, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent} from "ng-apexcharts";

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    plotOptions: ApexPlotOptions;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    tooltip: ApexTooltip;
    grid: ApexGrid
};

@Component({
    selector: 'app-simple-horizontal-bar-chart',
    templateUrl: './simple-horizontal-bar-chart.component.html',
    styleUrls: ['./simple-horizontal-bar-chart.component.scss']
})
export class SimpleHorizontalBarChartComponent implements OnInit {
    @ViewChild("chart") chart: ChartComponent;
    @Input() public chartOptions: Partial<ChartOptions>;
    @Input() title: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
