<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h5>Pay By Quartile</h5>
        </mat-card-title>
        <mat-card-subtitle>*Only "full pay relevant employees" are counted for these calculations</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <app-simple-piechart 
            *ngFor="let payByQuartile of payByQuartile" 
            [dataArray]="payByQuartile?.data" 
            [title]="payByQuartile?.title"
            [description]="payByQuartile?.description"
        >
        </app-simple-piechart>
    </mat-card-content>
</mat-card>