<div class="container">
    <div class="top">
        <h6>{{ 'Documents' | translate: {Default: "Documents"} }}</h6>
    </div>

    <app-documents-tree-view 
        *ngIf="selectedView == 'treeView'"
        [employeeID]="employeeID"
        (editAction)="openDocumentEditDialog($event)"
        (deleteAction)="openConfirmDeleteDialog($event)"
        (viewAction)="download($event)"
        (moveAction)="moveItem($event)"
        (downloadAction)="download($event)"
        (showCreateNewDirectoryForm)="openDirectoryFormDialog($event)"
        (showDocumentUploadDialog)="openDocumentUploadDialog($event)"
        [refreshView]="refreshView"
    ></app-documents-tree-view>
</div>


