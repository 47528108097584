import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-off-section',
  templateUrl: './time-off-section.component.html',
  styleUrls: ['./time-off-section.component.scss']
})
export class TimeOffSectionComponent implements OnInit {
  refreshMyTimeOffRequest: boolean = false;
  refreshMyToilRequest: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  timeOffRequestCreated(event: boolean) {
    this.refreshMyTimeOffRequest = !this.refreshMyTimeOffRequest;
  }

  toilRequestCreated(event: boolean) {
    this.refreshMyToilRequest = !this.refreshMyToilRequest;
  }

}
