import { Component, OnInit } from '@angular/core';
import {ChartOptions} from "@app/modules/reports/components/diversity-report/components/simple-vertical-bar-chart/simple-horizontal-bar-chart.component";

@Component({
  selector: 'app-turnover-report',
  templateUrl: './turnover-report.component.html',
  styleUrls: ['./turnover-report.component.scss']
})
export class TurnoverReportComponent implements OnInit {

  turnoverRatePerDivisionData: Partial<ChartOptions> = {
    series: [
        {
            name: "",
            data: [45.9, 40.5, 34.2, 33.5, 32.8]
        }
    ],
    chart: {
        type: "bar",
        height: 230,
    },
    plotOptions: {
        bar: {
            horizontal: true
        }
    },
    grid: {
        show: false,
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        categories: [
            "Mobile/Web",
            "IT",
            "Customer Success",
            "R&D",
            "Marketing",
        ]
    },
    tooltip: {
        enabled: true,
        y: {
            formatter: function (value) {
                return value + "%";
            }
        },
        x: {
            show: false
        }
    }
  };

  turnoverRateData = [
    { category: "Turnover", value: 35 },
    { category: "Remaining", value: 65 },
  ];

  seniorityData = [
    { label: 'Actual Seniority', value: '30.50' },
    { label: 'Benchmark Seniority', value: '32.55' },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
