<div mat-dialog-title class="top">
    <h5 >{{ performanceReview.name }}</h5>

    <mat-chip-list>
        <mat-chip class="green-chip" *ngIf="reviewer.status.id === 'complete'">Complete</mat-chip>
        <mat-chip class="red-chip" *ngIf="reviewer.status.id === 'notStarted'">Not Started</mat-chip>
        <mat-chip class="yellow-chip" *ngIf="reviewer.status.id === 'inProgress'">In Progress</mat-chip>
    </mat-chip-list>
</div>


<mat-dialog-content>

    <div class="employee-contact-card-container" *ngIf="reviewerEmployeeContactCard$ | withLoading | async as reviewerEmployeeContactCard">
        <div class="form-section">
            <div class="form-section-title">
                <h6>Reviewer</h6>
            </div>
            <div class="form-section-contents">
                <div class="employee">
                    <app-employee-image class="profile-pic" [employeeId]="reviewer.reviewer.id" round="true" width="200" height="200"></app-employee-image>
                    
                    <div class="employee-details">
                        <mat-form-field appearance="fill" >
                            <mat-label>
                                Employee Name
                            </mat-label>
                    
                            <input matInput type="text" [value]="reviewer.reviewer.firstName+' '+reviewer.reviewer.lastName">
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill" >
                            <mat-label>
                                <div class="label">Position</div>
                            </mat-label>
                    
                            <input matInput type="text" [value]="reviewerEmployeeContactCard?.value?.positions[0]?.name">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="employee-contact-card-container" *ngIf="employeeContactCard$ | withLoading | async as employeeContactCard">

        <div class="form-section">
            <div class="form-section-title">
                <h6>Reviewed Employee</h6>
            </div>
            <div class="form-section-contents">
                <div class="employee">
                    <app-employee-image class="profile-pic" [employeeId]="performanceReview.review.employee.id" round="true" width="200" height="200"></app-employee-image>
                    
                    <div class="employee-details">
                        <mat-form-field appearance="fill" >
                            <mat-label>
                                Employee Name
                            </mat-label>
                    
                            <input matInput type="text" [value]="performanceReview.review.employee.firstName+' '+performanceReview.review.employee.lastName">
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill" >
                            <mat-label>
                                <div class="label">Position</div>
                            </mat-label>
                    
                            <input matInput type="text" [value]="employeeContactCard?.value?.positions[0]?.name">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-section">
        <div class="form-section-title">
            <h6>Goals</h6>
        </div>
        <div class="form-section-contents">
            <div class="goals">
                <div *ngFor="let goal of reviewer.details.listOfGoals" class="goal">
                    <mat-form-field appearance="fill" >
                        <mat-label>
                            Goal
                        </mat-label>
                
                        <input matInput type="text" [value]="goal.name">
                    </mat-form-field>
                
                    <mat-form-field appearance="fill">
                        <mat-label>Comments</mat-label>
                
                        <textarea rows="10" matInput [value]="goal.comments"></textarea>
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>
                            Rating
                        </mat-label>
                
                      <mat-select disableOptionCentering [value]="goal.rating">
                            <mat-option></mat-option>
                            <mat-option *ngFor="let option of ratingOptions" [value]="option">{{option}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div class="form-section container">
        <div class="form-section-title">
            <h6>Overall Comments</h6>
        </div>
        <div class="form-section-contents">
            <mat-form-field appearance="fill">
                <mat-label>Overall Comments</mat-label>
        
                <textarea rows="10" matInput [value]="reviewer.details.overallComments"></textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="form-section container">
        <div class="form-section-title">
            <h6>Overall Rating</h6>
        </div>
        <div class="form-section-contents">
            <mat-form-field appearance="fill">
                <mat-label>
                    Rating
                </mat-label>
        
              <mat-select disableOptionCentering [value]="reviewer.details.overallRating">
                    <mat-option></mat-option>
                    <mat-option *ngFor="let option of ratingOptions" [value]="option">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    </div>

    
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        mat-raised-button 
        color="primary" 
        appPreventDoubleClick 
        (throttledClick)="save()" 
    >{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>