import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { api_routes, db_tables } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { HistoryDialogComponent } from '../../../../../employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { AssignEmployeeTimeOffType, EmployeeTimeOffType } from '../../models/leave-request.model';
import { EmployeeLeaveService } from '../../services/employee-leave.service';
import { AssignEmployeePolicyDialogComponent } from '../assign-employee-policy-dialog/assign-employee-policy-dialog.component';
import { LeaveRequestDialogComponent } from '../leave-request-dialog/leave-request-dialog.component';
import { TimeOffCalculationLogDialogComponent } from '../time-off-calculation-log-dialog/time-off-calculation-log-dialog.component';
import { RequestTimeOffDialogComponent } from '@app/modules/homepage/components/time-off-policies-widget/components/request-time-off-dialog/request-time-off-dialog.component';

@Component({
  selector: 'app-balance-control',
  templateUrl: './balance-control.component.html',
  styleUrls: ['./balance-control.component.scss']
})
export class BalanceControlComponent implements OnInit {
  @Input() employeeId: string;
  @Input() employeeTimeOffType: EmployeeTimeOffType;
  @Input() targetEmployeeTablePermissions: EmployeeTableSecurity[];
  @Output() emitDeleted: EventEmitter<any> = new EventEmitter<any>();
  employeeTimeOffPolicyFormId: string = 'frm_QagglWRLTcIRT4';

  modifyTimeOffTypeBanceFormId: string = 'frm_vbKs1DqvUDvz8S'
  modifyTimeOffTypeBanceChangeReasonFormId: string = 'frm_fjJulLMP4AnW5h'
  dialogRef: any;

  constructor(
    private employeeLeaveService: EmployeeLeaveService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private decimalPipe: DecimalPipe,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.EmployeeTimeOffPolicies,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  openAssignPolicyDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId,
      employeeTimeOffType: this.employeeTimeOffType
    };

    const dialogRef = this.dialog.open(AssignEmployeePolicyDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.emitDeleted.emit();
        }
      }
    );
  }

  openConfirmDeleteDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: 'Are you sure you want to delete this?'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deletePolicy();
        }
      }
    );
  }

  deletePolicy() {
    let assignEmployeeTimeOffType: AssignEmployeeTimeOffType[] = [{
      type: this.employeeTimeOffType.timeOffType.id,
      policy: null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: ''
    }]

    this.overlayService.show();

    this.employeeLeaveService.assignEmployeeTimeOffTypes(this.employeeId, assignEmployeeTimeOffType)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
        this.emitDeleted.emit();
      }
    )
  }

  openLeaveRequestDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId,
      employeeTimeOffType: this.employeeTimeOffType
    };


    const dialogRef = this.dialog.open(LeaveRequestDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {

        }
      }
    );
  }

  openModifyBalanceFormDialog() {
    let formData = {
      hours: this.decimalPipe.transform(this.employeeTimeOffType.availableHours, '1.0-1'),
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('ModifyBalance')}`,
      formId: this.modifyTimeOffTypeBanceFormId,
      changeReasonFormId: this.modifyTimeOffTypeBanceChangeReasonFormId,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.saveModifyBalance(event)
    });
  }

  saveModifyBalance(formData: any) {
    formData.asOf = moment().format();

    this.overlayService.show();

    this.employeeLeaveService.updateTimeOffTypeHours(this.employeeId, this.employeeTimeOffType.timeOffType.id, formData)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      res => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        this.dialogRef.close();
        this.emitDeleted.emit();
      }
    )
  }

  openModifyBalanceHistoryDialog() {
    const dialogConfig = new MatDialogConfig();
    let columns: any[] = [
      { field: "hours", title: "Hours" },
    ];

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      url: `${api_routes.TALENT}/${this.employeeId}/${api_routes.TIMEOFF}/${this.employeeTimeOffType.timeOffType.id}/${api_routes.MANUAL_ADJUSTMENTS}/${api_routes.HISTORY}`,
      columns: columns,
      isNotEmployeeDetails: true
    };

    const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
        data => {
        }
    );
  }

  openCalculationLogDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeId: this.employeeId,
      employeeTimeOffType: this.employeeTimeOffType
    };

    const dialogRef = this.dialog.open(TimeOffCalculationLogDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {

        }
      }
    );
  }

  openRequestTimeOffDialog(timeOffType: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 500;

    dialogConfig.data = {
      timeOffType: timeOffType,
      employeeId: this.employeeId
    };

    const dialogRef = this.dialog.open(RequestTimeOffDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
          if (data === true) {
            debugger;
            // this.timeOffRequestCreated.emit(true);
          }
      }
    );
  }

}
