<div class="container">
    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'TalentPool' | translate: {Default: "Talent Pool"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="talentPool">
            <mat-option></mat-option>
            <mat-option *ngFor="let talentPool of talentPools" [value]="talentPool">{{talentPool}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'PotentialRating' | translate: {Default: "Potential Rating"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="potentialRating">
            <mat-option></mat-option>
            <mat-option *ngFor="let potentialRating of potentialRatings" [value]="potentialRating">{{potentialRating}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'CriticalSkills' | translate: {Default: "Critical Skills"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="criticalSkills">
            <mat-option></mat-option>
            <mat-option *ngFor="let criticalSkill of criticalSkillsOptions" [value]="criticalSkill">{{criticalSkill}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'PromotionReadiness' | translate: {Default: "Promotion Readiness"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="promotionReadiness">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of promotionReadinessOptions" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'RetentionRisk' | translate: {Default: "Retention Risk"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="retentionRisk">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of retentionRisks" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'PerformanceRating' | translate: {Default: "Performance Rating"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="performanceRating">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of performanceRatings" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'LeadershipMatrixRating' | translate: {Default: "Leadership Matrix Rating"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="leadershipMatrixRating">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of leadershipMatrixRatings" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'CriticalRole' | translate: {Default: "Critical Role"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="criticalRole">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of criticalRoles" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'ReasonForLeaving' | translate: {Default: "Reason For Leaving"} }}
        </mat-label>

      <mat-select disableOptionCentering [(ngModel)]="reasonForLeaving">
            <mat-option></mat-option>
            <mat-option *ngFor="let option of reasonForLeavings" [value]="option">{{option}}</mat-option>
      </mat-select>
    </mat-form-field>
</div>