<div class="main-container" *ngIf="isLoading === false">
    <div class="top-container">
        <h5>{{workflow.name}}</h5>
        <div>{{workflow.description}}</div>

        <div class="progress-bar">
            <mat-progress-bar mode="determinate" [value]="getWorkflowProgress()"></mat-progress-bar>
            <div class="progress-text">{{getWorkflowProgress() | number:'1.0-0'}}% Done</div>
        </div>
        <!-- <div class="buttons">
            <button mat-flat-button>
                <mat-icon class="material-icons-outlined">add</mat-icon> 
                Add
            </button>
            <button mat-flat-button routerLink="{{routes.CHECKLIST}}/1">
                <mat-icon class="material-icons-outlined">settings</mat-icon> 
            </button>
        </div> -->
    </div>
    <div class="bottom-container">
        <mat-accordion multi>
            <mat-expansion-panel [expanded]="true" *ngFor="let category of workflow.categories">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{category.name}}
                </mat-panel-title>
                <mat-panel-description>
                    {{category.description}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            
              <div class="progress-bar">
                  <mat-progress-bar mode="determinate" [value]="getCategoryProgress(category)"></mat-progress-bar>
                  <div class="progress-text">{{getCategoryProgress(category) | number:'1.0-0'}}% Done</div>
              </div>
          
              <mat-list class="checkbox-task-list">
                  <div class="checkbox-task" *ngFor="let task of category.tasks">
                      <mat-list-item>


                        <app-form-task-item
                            class="item"
                            *ngIf="task.type === 'form'"
                            [task]="task"
                        ></app-form-task-item>

                        <app-survey-task-item
                            class="item"
                            *ngIf="task.type === 'survey'"
                            [task]="task"
                        ></app-survey-task-item>

                        <app-letter-task-item
                            class="item"
                            *ngIf="task.type === 'letter'"
                            [task]="task"
                        ></app-letter-task-item>

                        <ng-container *ngIf="task.type === 'standard'">
                            <mat-checkbox setColor="primaryColour" class="example-margin" [(ngModel)]="task.completed"></mat-checkbox>
                            <span class="task">{{task.name}}</span>
                            <span class="assigned-to">{{task.description}}</span>
                            <span class="date">{{task.createdOn | date:'mediumDate'}}</span>
                        </ng-container>

                        <!-- <span class="icon">
                            <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon> 
                        </span> -->
                      </mat-list-item>
                      <mat-divider></mat-divider>
                  </div>
              </mat-list>
          
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>