import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '@environments/environment';
import {SecurityFeature} from '@app/modules/security-setup/models/security-role.model';
import {SecurityProtectedElement} from '@app/shared/components/security-protected/security-protected';
import {EnvironmentService} from "@app/core/services/environment.service";

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class FeatureService {

    private features: SecurityFeature[] = [];

    constructor(private http: HttpClient, private envService: EnvironmentService) {}

    syncCurrentUserFeatures(callback: () => any) {
        return this.http.get<any>(`${this.envService.env.apiUrl}Security/Features/CurrentUser`,
            {
                headers: headers
            })
            .subscribe(res => {
                this.features = res;
                this.features.push(
                    {id: "Performance", name: "Performance"}
                )
                callback();
            });
    }

    getFeatures() {
        return this.features;
    }

    public hasFeatureAccess(featureCode: string | string[]): boolean {
        let index = -1;
        if (featureCode === 'allow' || featureCode.includes('allow')) {
            return true;
        }

        // If it is an array of feature codes go through them and check if any of them have access
        if(Array.isArray(featureCode)){
            featureCode.forEach(
                code => {
                    if(this.features.findIndex(feature => feature.id === code) >= 0) {
                        return true;
                    }
                }
            )
        }
        else {
            index = this.features.findIndex(feature => feature.id === featureCode);
        }
        
        return index >= 0;
    }

    public hasAnyFeatureAccess(featureCodes: string[]): boolean {
        return this.features.filter(feature => featureCodes.filter(f => feature.id === f).length > 0).length > 0;;
    }

    public getIndexOfTheFirstAllowedFeature(featureCodes: SecurityProtectedElement[]): SecurityProtectedElement {
        return featureCodes.find(featureCode => this.features.find(feature => feature.id === featureCode.featureCode) || featureCode.featureCode == 'allow')
    }
}
