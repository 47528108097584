import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-new-disability-pay-gap-report',
  templateUrl: './new-disability-pay-gap-report.component.html',
  styleUrls: ['./new-disability-pay-gap-report.component.scss']
})
export class NewDisabilityPayGapReportComponent implements OnInit {
    report = {
        title: 'Disability Pay Gap Report',
        description: null,
        ordinaryPay: {
                title: 'Ordinary Pay',
                description: `Disability pay gap - the difference between the pay of employees with disabilities and employees without disabilities as a percentage of the pay of employees without disabilities.`,
                mean: '6%',
                median: '9%',
        },
        hourlyRateComparisonData: {
                title: 'Hourly Rate Comparison',
                description: 'This chart shows the mean and median hourly rates for employees with and without disabilities.',
                currency: 'GBP',
                categories: ['With Disability', 'Without Disability'],
                meanHourlyRate: [21.90, 24.35],
                medianHourlyRate: [19.75, 22.50],
        },
        proportionData: {
                title: 'Proportion of employees with and without disabilities',
                data: [
                        { category: "With Disability", value: 30 },
                        { category: "Without Disability", value: 70 },
                ]
        },
        oneOffContributions: {
                description: 'The company does not pay bonuses. To comply with regulations, we have used any pay that is paid annually or less frequently than monthly as a `one-off contribution` to calculate the equivalent of bonus pay. This includes one-off payments such as long service awards.',
                data: [
                        {
                                title: 'One-off pay',
                                description: `One-off payments disability pay gap - the difference between payments to employees with disabilities and employees without disabilities as a % of the payments to employees without disabilities.`,
                                left: {
                                        label: 'Mean',
                                        value: '-1%',
                                },
                                right: {
                                        label: 'Median',
                                        value: '2%',
                                }
                        },
                        {
                                title: 'One-off payments made',
                                description: null,
                                left: {
                                        label: 'Employees with disabilities paid as a % of all employees with disabilities',
                                        value: '12%',
                                },
                                right: {
                                        label: 'Employees without disabilities paid as a % of all employees without disabilities',
                                        value: '15%',
                                }
                        }
                ]
        },
        payByQuartile: [
                {
                        title: 'Upper quartile',
                        description: 'Proportion of employees with and without disabilities in the upper quartile (paid above the 75th percentile point)',
                        data: [
                                { category: "With Disability", value: 40 },
                                { category: "Without Disability", value: 60 },
                        ]
                },
                {
                        title: 'Upper middle quartile',
                        description: 'Proportion of employees with and without disabilities in the upper middle quartile (paid above the median and at or below the 75th percentile point)',
                        data: [
                                { category: "With Disability", value: 42 },
                                { category: "Without Disability", value: 58 },
                        ]
                },
                {
                        title: 'Lower middle quartile',
                        description: 'Proportion of employees with and without disabilities in the lower middle quartile (paid above the 25th percentile point and at or below the median)',
                        data: [
                                { category: "With Disability", value: 35 },
                                { category: "Without Disability", value: 65 },
                        ]
                },
                {
                        title: 'Lower quartile',
                        description: 'Proportion of employees with and without disabilities in the lower quartile (paid below the 25th percentile point)',
                        data: [
                                { category: "With Disability", value: 38 },
                                { category: "Without Disability", value: 62 },
                        ]
                },
        ],
        summary: {
                title: "Disability Pay Gap Overview",
                description: "Summary of the disability pay gap analysis",
                sections: [
                        {
                                title: "Disability Pay Gap Insights",
                                paragraphs: [
                                        `30% of our workforce has a disability. This year's report indicates a reduction in the disability pay gap. The mean pay gap has decreased from 8% in ${moment().subtract(1, 'year').year()} to 6% in ${moment().year()}, and the median pay gap has reduced from 11% in ${moment().subtract(1, 'year').year()} to 9% in ${moment().year()}.`
                                ]
                        },
                        {
                                title: "Implications",
                                paragraphs: [
                                        `Our disability pay gap data highlights that our efforts to enhance inclusivity at all levels are yielding positive results. Recruitment plays a crucial role in improving representation, and as of March ${moment().year()}, 30% of our employees have a disability. Promoting and advancing our employees with disabilities into senior roles is equally important, with 20% of our senior positions held by employees with disabilities.`,
                                        `The data also shows that our initiatives, such as focusing on salary negotiations and proactively examining starting salaries, have resulted in equitable starting salaries for both employees with and without disabilities. Pay increases are also contributing to reducing the disability pay gap, as a higher proportion of employees with disabilities are receiving promotions and pay raises.`
                                ]
                        }
                ],
                bulletList: {
                        title: "Key Actions Driving Improvements: ",
                        items: [
                                "A dedicated Pay Gap Taskforce collaborating with senior leaders to focus on career development, succession planning, and pay moderation.",
                                "Involvement of our Executive Leadership Team in the recruitment process for all senior leader appointments.",
                                "Encouraging senior leaders to provide sponsorship support to accelerate the development of employees with disabilities.",
                                "Ensuring that every vacancy for middle management and above roles includes a diverse shortlist.",
                                "Providing post-interview feedback to each candidate and coaching hiring managers to deliver constructive feedback.",
                                "Revamping our Inclusive Mentoring Program to give leaders insights into the experiences of our employees with disabilities and the challenges they face.",
                                "Promoting agile working arrangements and explicitly including flexible working opportunities in our recruitment process."
                        ],
                }
        }
    };

  constructor() { }

  ngOnInit(): void {
  }

}
