<h5 mat-dialog-title>Select Form</h5>

<mat-dialog-content>
    <kendo-grid
        [data]="forms"
        [style.maxHeight.vh]="65"
        [selectable]="{
            enabled: true,
            mode: 'single'
        }"
        kendoGridSelectBy="id"
        [(selectedKeys)]="selectedItems"
    >
        <kendo-grid-checkbox-column [width]="80"></kendo-grid-checkbox-column>
        <kendo-grid-column field="name" title="Name"></kendo-grid-column>
        <kendo-grid-column field="table" title="Table">
            <ng-template kendoGridCellTemplate let-dataItem >
                <div class="grid-cell-vertical">
                    <span>
                        {{dataItem.table?.name}}
                    </span>
                </div>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <button class="add-all-selected-button" [disabled]="selectedItems.length === 0" mat-raised-button color="primary" appPreventDoubleClick (throttledClick)="addForm()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add Form
    </button>
</mat-dialog-actions>

