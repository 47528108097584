<div>
    <div class="title">
        <h3 setColor="primaryColour">
            {{data.title}}
        </h3>
    </div>
    <div class="text">
        <p [innerHTML]="data.text"></p>
    </div>
</div>


