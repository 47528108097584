<mat-list class="navigation-menu-list">

  <ng-container *ngIf="translationsLoaded; else loading">
    <ng-container
      *ngFor="let item of menuItems"
    >
      <ng-container *appHasModuleAccess="item?.module">
  
        <mat-list-item 
        [routerLink]="item.route" 
        setColor
        [setColorRules]="[
          {
              color: 'primaryColour',
              events: ['hover']
          },
          {
              color: 'primaryColour',
              setIfClass: ['primaryColor'],
              events: ['routerLinkActive']
          },
        ]"
        routerLinkActive="primaryColor" 
        class="primaryHoverColor" 
        *appHasFeature="item.feature"
      >
        <mat-icon 
          [matTooltip]="showTooltip ? item.text : null" 
          routerLinkActive="primaryColor" 
          setColor
          [setColorRules]="[
            {
                color: 'primaryColour',
                events: ['hover']
            },
            {
                color: 'primaryColour',
                setIfClass: ['primaryColor'],
                events: ['routerLinkActive']
            },
          ]"
          matTooltipPosition="right" 
          class="material-icons-outlined"
        >{{ item.icon }}</mat-icon> 
  
        <div appLocalizationPopupDirective [localizationCode]="item.localizationCode">
          {{ translate.instant(item.localizationCode) }}
        </div>
  
        </mat-list-item>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <ngx-skeleton-loader
      count="5"
      [theme]="{ 
          'height.px': 50
      }"
    ></ngx-skeleton-loader>
  </ng-template>






  <!-- <mat-list-item 
    routerLink="{{routes.HOMEPAGE}}" 
    setColor="primaryColour" 
    [setColorSettings]="{
        setIfClass: 'primaryColor',
        events: ['routerLinkActive', 'hover']
    }" 
    routerLinkActive="primaryColor" 
    class="primaryHoverColor" 
    *appHasFeature="features.HOMEPAGE"
  >
    <mat-icon 
      [matTooltip]="showTooltip ? 'Homepage' : null" 
      setColor="primaryColour" 
      routerLinkActive="primaryColor" 
      [setColorSettings]="{
          setIfClass: 'primaryColor',
          events: ['routerLinkActive']
      }"
      matTooltipPosition="right" 
      class="material-icons-outlined"
    >home</mat-icon> 

    {{ 'Homepage' | translate: {Default: "Homepage"} }}

  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.DASHBOARD}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="features.DASHBOARDS">
    <mat-icon [matTooltip]="showTooltip ? 'Dashboard' : null" matTooltipPosition="right" class="material-icons-outlined">dashboard</mat-icon> {{ 'Dashboard' | translate: {Default: "Dashboard"} }}
  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.NEWSFEED}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="'NewsFeed'">
    <mat-icon [matTooltip]="showTooltip ? 'News Feed' : null" matTooltipPosition="right" class="material-icons-outlined">feed</mat-icon> News Feed
  </mat-list-item>
  <mat-list-item routerLink="{{routes.INBOX}}" routerLinkActive="primaryColor" class="primaryHoverColor">
    <mat-icon [matTooltip]="showTooltip ? 'Inbox' : null" matTooltipPosition="right" class="material-icons-outlined">forum</mat-icon> Inbox
  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.TALENT_TRACK}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="features.TALENT">
    <mat-icon [matTooltip]="showTooltip ? 'Talent' : null" matTooltipPosition="right" class="material-icons-outlined">group</mat-icon> {{ 'Talent' | translate: {Default: "Talent"} }}
  </mat-list-item>
  <mat-list-item routerLink="{{routes.REPORTS}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="features.REPORTS">
    <mat-icon [matTooltip]="showTooltip ? translate.instant('Reports') : null" matTooltipPosition="right" class="material-icons-outlined">assessment</mat-icon>
    <div appLocalizationPopupDirective [localizationCode]="'Reports'">{{ 'Reports' | translate: {Default: "Reports"} }}</div>
    
  </mat-list-item>
  <mat-list-item routerLink="{{routes.SITE_SETTINGS}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="siteSettingsFeatures">
    <mat-icon [matTooltip]="showTooltip ? 'Site Settings' : null" matTooltipPosition="right" class="material-icons-outlined">settings_applications</mat-icon> {{ 'SiteSettings' | translate: {Default: "Site Settings"} }}
  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.LETTERS}}{{routes.LETTERS_LIST}}" routerLinkActive="primaryColor" class="primaryHoverColor">
    <mat-icon [matTooltip]="showTooltip ? 'Letters' : null" matTooltipPosition="right" class="material-icons-outlined">history_edu</mat-icon> Letters
  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.SECURITY}}{{routes.ROLES}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="features.SECURITY">
    <mat-icon [matTooltip]="showTooltip ? 'Security' : null" matTooltipPosition="right" class="material-icons-outlined">security</mat-icon> {{ 'Security' | translate: {Default: "Security"} }}
  </mat-list-item> -->
  <!-- <mat-list-item routerLink="{{routes.BENEFITS}}{{routes.COVERAGES}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="'Benefits'">
    <mat-icon [matTooltip]="showTooltip ? 'Benefits' : null" matTooltipPosition="right" class="material-icons-outlined">handshake</mat-icon> {{ 'Benefits' | translate: {Default: "Benefits"} }}
  </mat-list-item> -->
  <!-- <mat-list-item *ngIf="currentEmployeeDetails.userType === 'User'" routerLink="{{routes.ADMIN_CONTROLS}}{{routes.POSITIONS}}" routerLinkActive="primaryColor" class="primaryHoverColor">
    <mat-icon [matTooltip]="showTooltip ? 'Admin Controls' : null" matTooltipPosition="right" class="material-icons-outlined">admin_panel_settings</mat-icon> {{ 'AdminControls' | translate: {Default: "Admin Controls"} }}
  </mat-list-item>
  <ng-container *appHasModuleAccess="modules.PERFORMANCE">
    <mat-list-item routerLink="{{routes.PERFORMANCE}}" routerLinkActive="primaryColor" class="primaryHoverColor" *appHasFeature="features.PERFORMANCE">
      <mat-icon [matTooltip]="showTooltip ? translate.instant('Performance') : null" matTooltipPosition="right" class="material-icons-outlined">speed</mat-icon> 
      <div appLocalizationPopupDirective [localizationCode]="'Performance'">{{ 'Performance' | translate: {Default: "Performance"} }}</div>
    </mat-list-item>
  </ng-container> -->
</mat-list>
