<app-site-settings-menu>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'SurveyEditor' | translate: {Default: "Survey Editor"} }}</h5>

            <div class="k-flex-grow"></div>
            <button 
                mat-raised-button
                routerLink="{{routes.SITE_SETTINGS}}{{routes.SURVEYS}}">
                {{ 'Close' | translate: {Default: "Close"} }}
            </button>

            <button 
                mat-raised-button color="primary" 
                setColor="primaryColour"
                [disabled]="surveyDetails.invalid" 
                appPreventDoubleClick 
                (throttledClick)="save()">
                {{ 'Save' | translate: {Default: "Save"} }}
            </button>
        </mat-card-title>
        <mat-card-content>
            <div class="left">
                <div class="survey-details">
                    
                    <form class="survey-details-form" [formGroup]="surveyDetails">
                        <h5>{{ 'SurveyDetails' | translate: {Default: "Survey Details"} }}</h5>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Title</mat-label>
    
                            <input matInput type="text" formControlName="title">
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Description</mat-label>
    
                            <textarea matInput formControlName="description"></textarea>
                        </mat-form-field>
    
                        <mat-form-field appearance="fill">
                            <mat-label>Type</mat-label>
                
                            <mat-select disableOptionCentering formControlName="type">
                                <mat-option value="paged">Paged</mat-option>
                            </mat-select>
                        </mat-form-field>
                      
                        <!-- <button type="submit" [disabled]="myForm.invalid">Submit</button> -->
                      </form>
                </div>
            </div>
            <div class="right">
                <div class="questions-section">
                    <div class="questions-section-header">
                        <h5>{{ 'Questions' | translate: {Default: "Questions"} }}</h5>

                        <div class="k-flex-grow"></div>

                        <button 
                            mat-raised-button
                            appPreventDoubleClick 
                            (throttledClick)="addNewQuestion()">
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> {{ 'AddQuestion' | translate: {Default: "Add Question"} }}
                        </button>
                    </div>
                    <div class="questions">
                        <div class="question" *ngFor="let question of questions; let i=index">
                            <app-survey-editor-question [question]="question"></app-survey-editor-question>
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon" (click)="removeQuestion(i)">highlight_off</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>
