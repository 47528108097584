<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h5>One-off Contributions</h5>
        </mat-card-title>
        <mat-card-subtitle>
            {{ oneOffContributions.description }}
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <div class="value-container" *ngFor="let data of oneOffContributions.data">
            <div class="title">
                {{ data.title }}
            </div>
            <div class="description">
                {{ data.description }}
            </div>
            <div class="values">
                <div class="value-container">
                    <div class="label">
                        <h6>
                            {{ data.left.label }}
                        </h6>
                    </div>
                    <div class="value">
                        {{ data.left.value }}
                    </div>
                </div>
        
                <div class="value-container">
                    <div class="label">
                        <h6>
                            {{ data.right.label }}
                        </h6>
                    </div>
                    <div class="value">
                        {{ data.right.value }}
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>

</mat-card>