<app-layout>

    <mat-toolbar class="page-header" role="heading">
        <h1 appLocalizationPopupDirective [localizationCode]="'Disability-Pay-Gap-Report'">{{ 'Disability-Pay-Gap-Report' | translate: {Default: "Disability Pay Gap Report"} }}</h1>
    </mat-toolbar>

    <div class="content">
        <div>
            <app-disability-pay-gap-grid></app-disability-pay-gap-grid>
            <app-disability-pay-quartiles></app-disability-pay-quartiles>
            <app-disability-bonus-pay></app-disability-bonus-pay>
        </div>
    </div>
</app-layout>