import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';

@Component({
  selector: 'app-workflow-section',
  templateUrl: './workflow-section.component.html',
  styleUrls: ['./workflow-section.component.scss']
})
export class WorkflowSectionComponent implements OnInit {
  public routes: typeof routes = routes;
  
  navList = [
    {
      text: 'Journeys',
      link: `${routes.SITE_SETTINGS}${routes.WORKFLOWS}${routes.WORKFLOWS_LIST}`,
      icon: 'checklist_rtl'
    },
    {
      text: 'Published Journeys',
      link: `${routes.SITE_SETTINGS}${routes.WORKFLOWS}${routes.WORKFLOWS_PUBLISHED}${routes.WORKFLOWS_PUBLISHED_LIST}`,
      icon: 'send'
    },
    // {
    //   text: 'Create Letter',
    //   link: `${routes.SITE_SETTINGS}${routes.LETTERS}${routes.LETTERS_EDITOR}`,
    //   icon: 'create'
    // },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
