<mat-toolbar 
  setColor
  [setColorRules]="[
    {
      color: 'primaryColour',
      style: 'background'
    }
  ]" 
  color="primary" 
  class="header selected">

  <div class="header__title">
    <app-navigation-menu></app-navigation-menu>

    <!-- <button class="header__title-button" mat-mini-fab (click)="openMenu()">
      <mat-icon class="material-icons-outlined header__title-button-icon"  *ngIf="!isMenuOpened" >menu</mat-icon>
      <mat-icon class="material-icons-outlined header__title-button-icon"  *ngIf="isMenuOpened" >arrow_back</mat-icon>
    </button> -->

    <ng-container *ngIf="this.companyLogosService.activeCompanyLogo !== undefined; else noLogo">
      <img class="company-logo" [src]="this.companyLogosService.activeCompanyLogo" alt="logo"/>
    </ng-container>
    <ng-template #noLogo>
      <img class="hubbub-logo" [src]="this.environmentService.env.customLogoPath ? this.environmentService.env.customLogoPath : 'assets/HubbubHrLogo.svg'" alt="logo"/>
    </ng-template>

  </div>

  <app-site-search-bar></app-site-search-bar>

  <div class="header-toolbar">
    <!-- <app-email-notifications></app-email-notifications>
    <app-email></app-email> -->
    <app-hugo-dropdown></app-hugo-dropdown>
    <app-translate></app-translate>
    <app-user [user]="user$" (signOut)="signOut()"></app-user>
  </div>

</mat-toolbar>
