<h5 mat-dialog-title>
    {{ 'ApproveChangeRequest' | translate: {Default: "Approve Change Request"} }}
</h5>

<mat-dialog-content>

    <div class="change-details">
        <h6 class="row field">
            {{ formDetails?.name[0]?.text }} - {{ formElement?.text }}

            <mat-chip-list>
                <mat-chip class="orange-chip">Pending Approval</mat-chip>
            </mat-chip-list>
        </h6>
    
        <div class="change-container">

            <div class="cards">
                <mat-card class="mat-elevation-z0 change-card">
                    <mat-card-header>
                        <mat-card-title>
                            <div class="label" appLocalizationPopupDirective [localizationCode]="'CurrentValue'">{{ 'CurrentValue' | translate: {Default: "Current Value"} }}</div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="current-value">
                            {{ changeRequest.previousValue }}
                        </div>
                    </mat-card-content>
                </mat-card>
        
                <mat-icon class="material-icons-outlined">arrow_forward</mat-icon>
        
                <mat-card class="mat-elevation-z0 change-card">
                    <mat-card-header>
                        <mat-card-title>
                            <div class="label" appLocalizationPopupDirective [localizationCode]="'NewValue'">{{ 'NewValue' | translate: {Default: "New Value"} }}</div>
                        </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="new-value">
                            {{ changeRequest.newValue }}
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
    
            <div class="row">
                <div class="label" appLocalizationPopupDirective [localizationCode]="'ChangeRequestedFor'">{{ 'ChangeRequestedFor' | translate: {Default: "Change Requested For"} }}: </div>
                
                <div class="employee-name">
                    <app-employee-image class="profile-pic" round="true" width="35" height="35" [employeeId]="changeRequest.employee?.id"></app-employee-image>
                    <div>{{changeRequest.employee?.firstname}} {{changeRequest.employee?.lastname}}</div>
                </div>
            </div>

            <div class="row">
                <div class="label" appLocalizationPopupDirective [localizationCode]="'EffectiveDate'">{{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}: </div>
                {{ changeRequest.effectiveDate | date:'mediumDate' }}
            </div>
        </div>
    </div>
    
    <div class="requester-details">
        <div class="row">
            <div class="label">
                Requested By: 
            </div>

            <div class="employee-name">
                <app-employee-image class="profile-pic" round="true" width="35" height="35" [employeeId]="changeRequest.changedBy?.id"></app-employee-image>
                <div>{{ changeRequest.changedBy?.firstname }} {{ changeRequest.changedBy?.lastname }}</div>
            </div>
        </div>

        <div class="row">
            <div class="label">
                Requested On:
            </div>

            {{ changeRequest.changedOn | date:'medium' }}
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    
    <button mat-raised-button class="red-button" appPreventDoubleClick (throttledClick)="close(false)">
        {{ 'Reject' | translate: {Default: "Reject"} }}
    </button>

    <button mat-raised-button class="green-button" appPreventDoubleClick (throttledClick)="close(true)">
        {{ 'Approve' | translate: {Default: "Approve"} }}
    </button>

</mat-dialog-actions>