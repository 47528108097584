<app-succession-track>

    <div class="employee-contact-card-container" *ngIf="employeeContactCard$ | withLoading | async as employeeContactCard">
        <ng-template [ngIf]="employeeContactCard.value">

            <mat-card class="mat-elevation-z0">
                <mat-card-title class="header">
                    <h5>{{ 'SuccessionProfile' | translate: {Default: "Succession Profile"} }}</h5>

                    <div>
                        
                        <button mat-stroked-button setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                style: 'color'
                            },
                            {
                                color: 'primaryColour',
                                style: 'border-color'
                            }
                        ]"color="primary" (click)="updateStep('Complete')" *ngIf="step === 'Pending'">Set Workflow Step: Complete</button>
                        <button mat-stroked-button setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                style: 'color'
                            },
                            {
                                color: 'primaryColour',
                                style: 'border-color'
                            }
                        ]"color="primary" (click)="updateStep('Archived')" *ngIf="step === 'Complete'">Set Workflow Step: Archive</button>
                        <button mat-stroked-button setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                style: 'color'
                            },
                            {
                                color: 'primaryColour',
                                style: 'border-color'
                            }
                        ]" color="primary" (click)="generatePdf()">Succession Chart</button>
                        
                        <button mat-stroked-button (click)="back()">Back</button>
                        <button mat-flat-button setColor="primaryColour" color="primary" (click)="save()">Save</button>
                    </div>
                    
                </mat-card-title>
        
                <mat-card-content>
                    <div class="employee-details">
                        <div class="profile-pic-container">
                            <app-employee-image class="profile-pic" mat-card-image [employeeId]="employeeContactCard.value.id" round="true" width="200" height="200"></app-employee-image>
                        </div>
                        <div class="details">
                            <div class="name-details">
                                <div class="name" *ngIf="employeeContactCard.value.firstname && employeeContactCard.value.lastname">{{employeeContactCard.value.firstname}} {{employeeContactCard.value.lastname}}</div>
                                <div class="username" *ngIf="employeeContactCard.value.preferredName">{{employeeContactCard.value.preferredName}}</div>
                                <div class="username" *ngIf="employeeContactCard.value.customClientId">{{employeeContactCard.value.customClientId}}</div>
                                <!-- <div class="username" *ngIf="employeeContactCard.value.organization">{{employeeContactCard.value.organization.name}}</div> -->

                                <mat-chip-list>
                                    <mat-chip [ngClass]="{
                                        'green-chip' : step === 'Complete', 
                                        'yellow-chip': step === 'Pending',
                                        'blue-chip': step === 'Archived'
                                    }">
                                        {{step}}
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
            
                            <mat-tab-group *ngIf="isLoading === false" setColor="primaryColour" animationDuration="0ms" class="succession-profile-tab-group">
                                <mat-tab label="Employee Details">
                                    <app-succession-employee-details
                                        [employee]="employeeContactCard.value"
                                    ></app-succession-employee-details>
                                </mat-tab>
                                <mat-tab label="Talent Summary">
                                    <app-succession-talent-summary
                                        [employee]="employeeContactCard.value"
                                        [successors]="successors"
                                    ></app-succession-talent-summary>
                                </mat-tab>
                                <mat-tab label="Development">
                                    <app-employee-review-widget></app-employee-review-widget>
                                </mat-tab>
                                <mat-tab label="Employee Self-Assessment">
                                    <app-succession-emp-self-assessment></app-succession-emp-self-assessment>
                                </mat-tab>
                                <mat-tab label="Expertise & Qualifications">
                                    <app-success-expertise></app-success-expertise>
                                </mat-tab>
                                <mat-tab label="Photo">
                                    <app-succession-image
                                        [employeeId]="employeeContactCard.value.id"
                                    ></app-succession-image>
                                </mat-tab>
                                <mat-tab label="Documents">
                                    <app-succession-documents></app-succession-documents>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>

        </ng-template>
        <ng-template [ngIf]="employeeContactCard.error">
          <h6>There was an error loading</h6>
        </ng-template>
        <ng-template [ngIf]="employeeContactCard.loading">
            <div class="employee-details">
                <div class="profile-pic-container">
                    <ngx-skeleton-loader 
                    count="1" 
                    appearance="circle"
                    [theme]="{ 
                        'height.px': 200,
                        'width.px': 200
                    }">
                    </ngx-skeleton-loader>
                </div>
                <div style="width:200px; padding: 10px;">
                    <ngx-skeleton-loader
                    count="3"
                    [theme]="{ 
                        'height.px': 50
                    }"
                    ></ngx-skeleton-loader>
                </div>
            </div>
        </ng-template>
    </div>
    


    
</app-succession-track>