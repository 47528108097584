<app-site-settings-menu>

    <app-data-grid-comp
        *ngIf="true"
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [category]="translate.instant('Data Trigger Rules')"
        canSelectRows="true"
        clickableRows="true"
        searchable="true"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="navigateToDataTriggerRule($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
    ></app-data-grid-comp>

<!--    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>-->
</app-site-settings-menu>

<!--<app-site-settings-menu>-->
<!--    <div class="main-container">-->
<!--        <div class="menu">-->
<!--            <mat-list>-->
<!--                <mat-list-item *ngFor="let item of navList" [routerLink]="item.link" routerLinkActive="active">-->
<!--                    <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>-->
<!--                    <div>{{item.text}}</div>-->
<!--                </mat-list-item>-->
<!--            </mat-list>-->
<!--        </div>-->
<!--        <div class="content">-->
<!--            <div class="main-content">-->
<!--                <ng-content></ng-content>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--</app-site-settings-menu>-->
