import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Lookup } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { EmploymentRecordBenefit } from '../../models/employment-record-benefit.model';
import { EmploymentRecordBenefitsService } from '../../services/employment-record-benefits.service';

@Component({
  selector: 'app-email-notifications-dialog',
  templateUrl: './employment-record-benefit-dialog.component.html',
  styleUrls: ['./employment-record-benefit-dialog.component.scss']
})
export class EmploymentRecordBenefitDialogComponent implements OnInit {
  employmentRecordId: string;
  employeeId: string;
  benefitId: string;
  isLoading: boolean;
  form: UntypedFormGroup;
  employmentRecordBenefit: EmploymentRecordBenefit;
  benefitGroupOptions: Observable<Lookup[]>;
  benefitChangeReasonOptions: Observable<Lookup[]>;
  benefitContactNoOptions: Observable<Lookup[]>;
  benefitDivisionOptions: Observable<Lookup[]>;
  benefitPlanNameOptions: Observable<Lookup[]>;
  benefitPlanProviderOptions: Observable<Lookup[]>;
  benefitSubDivisionOptions: Observable<Lookup[]>;
  benefitSubGroupOptions: Observable<Lookup[]>;
  languageOptions: Observable<Lookup[]>;

  constructor(
    private fb: UntypedFormBuilder,
    private lookupService: LookupService,
    private overlayService: OverlayService,
    private employmentRecordBenefitsService: EmploymentRecordBenefitsService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<EmploymentRecordBenefitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.employmentRecordId = data.employmentRecordId;
    this.employeeId = data.employeeId;
    this.benefitId = data.benefitId;
  }

  ngOnInit(): void {
    this.getLookups();

    this.benefitId !== null
      ? this.getEmploymentRecordBenefit()
      : this.isLoading = false;
        this.createForm();
  }

  getEmploymentRecordBenefit() {
    this.isLoading = true;

    this.employmentRecordBenefitsService.getBenefit(this.employeeId, this.employmentRecordId, this.benefitId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.employmentRecordBenefit = res;
        this.createForm();
      }
    );
  }

  getLookups() {
    this.benefitGroupOptions = this.lookupService.getLookups("/lookup/BENEFIT_GROUP");
    this.benefitChangeReasonOptions = this.lookupService.getLookups("/lookup/BENEFIT_CHANGE_REASON");
    this.benefitContactNoOptions = this.lookupService.getLookups("/lookup/BENEFIT_CONTRACT");
    this.benefitDivisionOptions = this.lookupService.getLookups("/lookup/BENEFIT_DIVISION");
    this.benefitPlanNameOptions = this.lookupService.getLookups("/lookup/BENEFIT_PLAN_NAME");
    this.benefitPlanProviderOptions = this.lookupService.getLookups("/lookup/BENEFIT_PLAN_PROVIDER");
    this.benefitSubDivisionOptions = this.lookupService.getLookups("/lookup/BENEFIT_SUB_DIVISION");
    this.benefitSubGroupOptions = this.lookupService.getLookups("/lookup/BENEFIT_SUB_GROUP");
    this.languageOptions = this.lookupService.getLookups("/lookup/LANGUAGE");
  }

  createForm() {
    this.form = this.fb.group({
      id: [this.employmentRecordBenefit ? this.employmentRecordBenefit.id : null],
      effectiveDate: [moment().format()],
      benefitGroup: [this.employmentRecordBenefit && this.employmentRecordBenefit.benefitGroup ? this.employmentRecordBenefit.benefitGroup.id : null],
      planName: [this.employmentRecordBenefit && this.employmentRecordBenefit.planName ? this.employmentRecordBenefit.planName.id : null],
      benefitsDivision: [this.employmentRecordBenefit && this.employmentRecordBenefit.benefitsDivision ? this.employmentRecordBenefit.benefitsDivision.id : null],
      contractNo: [this.employmentRecordBenefit && this.employmentRecordBenefit.contractNo ? this.employmentRecordBenefit.contractNo.id : null],
      language: [this.employmentRecordBenefit && this.employmentRecordBenefit.language ? this.employmentRecordBenefit.language.id : null],
      subGroup: [this.employmentRecordBenefit && this.employmentRecordBenefit.subGroup ? this.employmentRecordBenefit.subGroup.id : null],
      planProvider: [this.employmentRecordBenefit && this.employmentRecordBenefit.planProvider ? this.employmentRecordBenefit.planProvider.id : null],
      benefitsSubDivision: [this.employmentRecordBenefit && this.employmentRecordBenefit.benefitsSubDivision ? this.employmentRecordBenefit.benefitsSubDivision.id : null],
      numberOfDependents: [this.employmentRecordBenefit ? this.employmentRecordBenefit.numberOfDependents : 0],
      startDate: [this.employmentRecordBenefit ? this.employmentRecordBenefit.startDate : null],
      endDate: [this.employmentRecordBenefit ? this.employmentRecordBenefit.endDate : null],
      comments: [''],
      changeReason: [''],
      changeReasonComments: ['']
    });
  }

  save(){
    this.overlayService.show();

    this.employmentRecordBenefitsService.postBenefit(this.employeeId, this.employmentRecordId, this.form.value)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar('Benefit created successfully', 'clear', 'success');
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
