import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {api_routes} from '@app/consts';
import {EnvironmentService} from "@app/core/services/environment.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private snackbarService: SnackbarService, private envService: EnvironmentService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        // retry(1),
        catchError((errorResponse: HttpErrorResponse) => {
          const { apiUrl } = this.envService.env;
          const regexPatterns = [
            `^${apiUrl}${api_routes.TALENT}/[^.]+/${api_routes.PHOTO}*$`,
            `^${apiUrl}Timeoff/${api_routes.TYPES}/[^.]+/${api_routes.ICON}*$`,
            `^${apiUrl}Toil/${api_routes.TYPES}/[^.]+/${api_routes.ICON}*$`,
            `^${apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.STYLING_COLOURS}/${api_routes.CURRENT_USER}*$`,
            `^${apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/${api_routes.CURRENT_USER}*$`,
            `^${apiUrl}${api_routes.METADATA}/${api_routes.SITE_SETTINGS}/${api_routes.COMPANY_LOGOS}/[^.]+/${api_routes.LOGO}*$`,
            `^https://chatgpthugo.azurewebsites.net/thread*$`
          ];
          const errorUrl = errorResponse.url;
          const isMatch = regexPatterns.some(pattern => new RegExp(pattern).test(errorUrl));

          let errorMessage = '';
          let panelOpenState = false;
          let errorType = 'error'


          if(errorResponse.status === 400 && errorResponse.error?.validationDetails === true) {
            // If it is a validation error open snackbar error on default
            panelOpenState = true;

            errorType = 'warn';

            for (const [key, value] of Object.entries(errorResponse.error.errors)) {
              errorMessage = value.toString();
            }
          }
          else if(errorResponse.status === 403) {
            errorMessage = `${errorResponse.error[0]}. If you need your permissions updated please contact your system admin.`
          }
          else {
            errorMessage = `Error Code: ${errorResponse.status}\nMessage: ${errorResponse.message}`;
          }


          if (!isMatch) {
            this.snackbarService.openSnackBar(errorMessage, 'clear', errorType, true, panelOpenState);
          }

          return throwError(() => new Error(errorMessage))
        })
      )
  }
}
