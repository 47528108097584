<div class="container">
    <mat-form-field appearance="fill" >
        <mat-label>
            Job Title
        </mat-label>

        <input matInput type="text" [value]="employee.positions[0]?.name">
    </mat-form-field>

    <mat-form-field appearance="fill" >
        <mat-label>
            <div class="label">Manager</div>

        </mat-label>

        <input matInput type="text" [ngModel]="manager">
    </mat-form-field>

    <mat-form-field appearance="fill" >
        <mat-label>
            <div class="label">Organisation</div>

        </mat-label>

        <input matInput type="text" [value]="employee.organization?.name">
    </mat-form-field>

    <mat-form-field  appearance="fill">
        <mat-label>
            Date Of Birth
        </mat-label>

        <input matInput [matDatepicker]="picker" [ngModel]="birthday">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle  [for]="picker"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field  appearance="fill">
        <mat-label>
            Orig Hire Date
        </mat-label>

        <input matInput [matDatepicker]="picker1" [ngModel]="hireDate">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle  [for]="picker1"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>

    <mat-form-field  appearance="fill">
        <mat-label>
            Current Role Date
        </mat-label>

        <input matInput [matDatepicker]="picker2" [ngModel]="roleDate">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle  [for]="picker2"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
</div>