import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { EmployeeContactCard } from '../models/employee-contact-card.model';
import { EnvironmentService } from "@app/core/services/environment.service";

const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class EmployeeContactCardService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  getAllEmployeeContactCards(skip?: number, take?: string, filterString?: string, sortString?: string, filterInactive: boolean = true): Observable<PagedData<any>> {
    let params = new HttpParams();
    skip ? params = params.append('skip', skip) : null;
    take ? params = params.append('take', take) : null;
    filterString ? params = params.append('Filter', filterString) : null;
    sortString ? params = params.append('Sort', sortString) : null;
    params = params.append('FilterInactive', filterInactive ? 'true' : 'false');

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.CONTACT_CARDS}`, {
      headers: headers,
      params: params
    });
  }

  getEmployeeContactCard(employeeId: string): Observable<EmployeeContactCard> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${api_routes.CONTACT_CARDS}/${employeeId}`, {
      headers: headers
    }).pipe(
      tap(res => {
        let seen = new Set<String>();
        let managers = []
        res.managers.forEach(manager => {
          if (! seen.has(manager.id) && manager.id != employeeId){
            seen.add(manager.id)
            managers.push(manager)
          }
        })
        res.managers = managers
        return res
      })
    );
  }
}
