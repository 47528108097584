import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { DataBindingDirective, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BenefitsGroup } from './models/benefits-group.model';
import { BenefitsGroupsService } from './services/benefits-groups.service';

@Component({
  selector: 'app-benefits-groups',
  templateUrl: './benefits-groups.component.html',
  styleUrls: ['./benefits-groups.component.scss']
})
export class BenefitsGroupsComponent implements OnInit {

  public columns: any[] = [
    { field: "name", title: "Name", type: "navigation"},
    { field: "deductionFrequency", subField: "name", title: "Deduction Frequency"}
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  public sort: SortDescriptor[] = [];
  pageSize: number = 20;
  skip: number = 0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  filterString: string;
  searchFilterString: string;
  searchValue: string = "";
  sortString: string;
  loadingGroups: boolean;
  getGroupsRequest: any;
  clearSelectedItems: boolean = false;

  constructor(
    private dialog: MatDialog,
    private benefitsGroupsService: BenefitsGroupsService,
    private overlayService: OverlayService,
    public router: Router,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getGroups();
  }

  getGroups() {
    let filterString;
    this.loadingGroups = true;

    if(this.searchValue !== ""){
      filterString = `(name like "${this.searchValue}")`;
    }

    this.getGroupsRequest = this.benefitsGroupsService.getGroups(null, filterString, String(this.pageSize), this.skip)
    .pipe(
      finalize( () => { this.loadingGroups = false } )
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    )
  }

  search(newSearchString: string){
    this.searchValue = newSearchString;
    this.getGroupsRequest.unsubscribe();
    this.getGroups();
  }

  navigateToGroupEditor(group?: BenefitsGroup) {
    if(group) {
      this.router.navigate([`${routes.BENEFITS}${routes.GROUPS}/${routes.EDITOR}/${group?.id}`]);
    }
    else {
      this.router.navigate([`${routes.BENEFITS}${routes.GROUPS}/${routes.EDITOR}`]);
    }
  }

  deleteGroups(groupIds: string[]) {
    const observables = groupIds.map(selectedItem => defer(() => this.benefitsGroupsService.deleteGroup(selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getGroups();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getGroups();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    if (sort[0].dir === undefined) {
      this.sortString = null
    }
    else {
      //use regex to get column field to sort with
      let field: any;

      //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
      (sort[0].field).match(/.+(?=\.)/) === null
      ? field = sort[0].field
      : field = (sort[0].field).match(/.+(?=\.)/)
      this.sortString = `${field}-${sort[0].dir}`;
    }

    this.getGroups();
  }
}

