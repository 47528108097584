import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pay-gap-report',
  templateUrl: './pay-gap-report.component.html',
  styleUrls: ['./pay-gap-report.component.scss']
})
export class PayGapReportComponent implements OnInit {
  @Input() report: {
    title: string,
    description: string,
    ordinaryPay: {
      title: string,
      description: string,
      mean: string,
      median: string,
    }
    hourlyRateComparisonData: {
      title: string,
      description: string,
      currency: string,
      categories: string[],
      meanHourlyRate: [number, number],
      medianHourlyRate: [number, number],
    },
    proportionData: {
      title: string,
      data: { category: string, value: number }[]
    },
    oneOffContributions: {
      description: string;
      data: {
        title: string;
        description: string;
        left: {
          label: string;
          value: string;
        },
        right: {
          label: string;
          value: string;
        }
      }[]
    },
    payByQuartile: {
      title: string,
      description: string,
      data: {
        category: string,
        value: number
      }[]
    }[],
    summary: {
      title: string,
      description: string,
      sections: {
        title: string,
        paragraphs: string[],
      }[],
      bulletList: {
        title: string,
        items: string[],
      }
    }
  };

  selectedTabIndex: number = 0;


  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  backToReportGroups() {
    this.router.navigate([`Reports/Custom/Type/PayGap`]);
  }

}
