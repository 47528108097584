import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api_routes } from '@app/consts';
import { DirectorySubmit } from '@app/shared/models/employee/documents/directory-submit.model';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { environment } from '@environments/environment';
import {from, Observable, of, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentUpdate } from '../models/document.model';
import {EnvironmentService} from '@app/core/services/environment.service';



const headers = new HttpHeaders({
  'Content-Type':  'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class SiteDocumentsService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }


 files = [
        {
            "version": {
                "createdOn": "2023-03-01T16:58:45.4821178+00:00",
                "effectiveDate": "2023-03-01T00:00:00",
                "modifiedList": [
                    "Name"
                ],
                "changeReason": null,
                "comments": null,
                "isDeleted": false,
                "createdBy": {
                    "id": "hubbub_3",
                    "firstName": "alan",
                    "lastName": "niemiec"
                }
            },
            "id": "dle_yv5Gt9atC9LTC8",
            "isFolder": true,
            "fileLength": null,
            "name": "Financials",
            "comments": null,
            "contentType": null,
            "parentid" : ""
        },
        {
            "version": {
                "createdOn": "2022-05-19T23:09:39.6105779+00:00",
                "effectiveDate": "2022-05-19T00:00:00",
                "modifiedList": [
                    "Name"
                ],
                "changeReason": null,
                "comments": null,
                "isDeleted": false,
                "createdBy": {
                    "id": "usr_f8GHeWSZZWGVkH",
                    "firstName": "=Test Field Sec",
                    "lastName": "(DONT CHANGE)"
                }
            },
            "id": "dle_mAXNcJ0Az7JIji",
            "isFolder": true,
            "fileLength": null,
            "name": "Letters",
            "comments": null,
            "contentType": null,
            "parentid" : ""
        },
        {
            "version": {
                "createdOn": "2022-05-18T23:13:22.2241549+00:00",
                "effectiveDate": "2022-05-18T00:00:00",
                "modifiedList": [
                    "Name"
                ],
                "changeReason": null,
                "comments": null,
                "isDeleted": false,
                "createdBy": {
                    "id": "usr_0000001",
                    "firstName": "Samantha",
                    "lastName": "Bourne"
                }
            },
            "id": "dle_XALSpu7O4u4nPm",
            "isFolder": false,
            "fileLength": 1124446,
            "name": "Letters/Welcome Letter.pdf",
            "comments": null,
            "contentType": "application/pdf",
            "getterId": "Financial Report.doc",
            "parentid" : "dle_mAXNcJ0Az7JIji"
        },
        {
            "version": {
                "createdOn": "2022-05-19T21:00:42.5163696+00:00",
                "effectiveDate": "2022-05-19T00:00:00",
                "modifiedList": [
                    "Name"
                ],
                "changeReason": null,
                "comments": null,
                "isDeleted": false,
                "createdBy": {
                    "id": "usr_f8GHeWSZZWGVkH",
                    "firstName": "Samantha",
                    "lastName": "Bourne"
                }
            },
            "id": "dle_XALSpu7O4u4nPs",
            "isFolder": false,
            "fileLength": 503296,
            "name": "Financial Report.doc",
            "comments": null,
            "contentType": "application/msword",
            "getterId": "assets/Financial Report.doc",
            "parentid" : ""
        },
         {
             "version": {
                 "createdOn": "2022-05-19T21:00:42.5163696+00:00",
                 "effectiveDate": "2022-05-19T00:00:00",
                 "modifiedList": [
                     "Name"
                 ],
                 "changeReason": null,
                 "comments": null,
                 "isDeleted": false,
                 "createdBy": {
                     "id": "usr_f8GHeWSZZWGVkH",
                     "firstName": "James",
                     "lastName": "Huey"
                 }
             },
             "id": "dle_XALSpu7O4u4nPt",
             "isFolder": false,
             "fileLength": 503296,
             "name": "Budget Expenses.csv",
             "comments": null,
             "contentType": "application/msword",
             "getterId": "Financials/Budget Expenses.csv",
             "parentid" : "dle_yv5Gt9atC9LTC8"
         }
    ]


  getDocuments(employeeId: string, folderId?: string): Observable<any> {
    let params = new HttpParams();
    folderId ? params = params.append('FolderId', folderId) : null;

    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}`, {
      headers: headers,
      params: params
    });

    // Uncomment if we want static files data for demo
    //   let res = [];
    //   if (folderId != null) {
    //       for (let item of this.files) {
    //           if (item.parentid === folderId){
    //               res.push(item);
    //           }
    //       }
    //   } else {
    //       for (let item of this.files) {
    //           if (item.parentid === ""){
    //               res.push(item);
    //           }
    //       }
    //   }
    //   return of(res);
  }

  getDocument(employeeId: string, documentId: string): Observable<any> {
    return this.http.get<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  deleteDocument(employeeId: string, documentId: string): Observable<any> {
    return this.http.delete<any[]>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  createDocument(employeeId: string, directory: DirectorySubmit): Observable<Directory> {
    return this.http.post<Directory>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}`, directory, {
      headers: headers
    });
  }

  updateDocument(employeeId: string, documentId: string, documentUpdate: DocumentUpdate): Observable<Directory> {
    return this.http.put<Directory>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, documentUpdate, {
      headers: headers
    });
  }

  uploadDocument(employeeId: string, file: File, name: string, parentId: string): Observable<any> {
    const formData: FormData = new FormData();

    formData.append('File', file);
    formData.append('Parent', parentId ? parentId : '');
    formData.append('Comments', '');
    formData.append('Name', name ? name : '');

    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}`,
      formData,
      {
        headers: {},
        reportProgress: true,
        observe: 'events'
      }
    );
      // this.files.push(
      //     {
      //     "version": {
      //     "createdOn": "2022-05-19T21:00:42.5163696+00:00",
      //         "effectiveDate": "2022-05-19T00:00:00",
      //         "modifiedList": [
      //         "Name"
      //     ],
      //         "changeReason": null,
      //         "comments": null,
      //         "isDeleted": false,
      //         "createdBy": {
      //         "id": "usr_f8GHeWSZZWGVkH",
      //             "firstName": "James",
      //             "lastName": "Huey"
      //     }
      // }, "id": Math.random().toString(),
      //     "isFolder": false,
      //     "fileLength": 503296,
      //     "name": file.name,
      //     "comments": null,
      //     "contentType": file.type,
      //     "getterId": "",
      //     "parentid" : parentId
      //     }
      // )
      //
      // let res = []
      // for (let item of this.files) {
      //     if (item.parentid === ""){
      //         res.push(item);
      //     }
      // }
      // return of(res)
  }

  getDocumentUrl(employeeId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`,
    {
      headers: headers,
      responseType: 'text' as 'json'
    }
    );
  }

  downloadDocument(employeeId: string, documentId: string): Observable<any> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}/${api_routes.DOWNLOAD}`,
    {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json'
    });

      // var obj
      // for (let item of this.files) {
      //     if (item.id === documentId) {
      //         obj = item;
      //         break;
      //     }
      // }

      // const url = 'assets/mock-data/'+ obj.getterId;
      // return this.http.get(url).subscribe((data: any) => {
      //     const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
          // const url = window.URL.createObjectURL(blob);
          // const link = document.createElement('a');
          // link.href = url;
          // link.download = 'data.json';
          // link.click();
      // });

  }



  // getDirectories(empId: string): Observable<Directory[]> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/Directory/', {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getDirectoryContents(empId: string, directoryId: string): Observable<any> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/Directory/' + directoryId, {
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  // }

  // getDocumentUrl(empId: string, documentId: string): Observable<any> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/' + documentId + '/Download',
  //   {
  //     headers: {
  //       'Content-Type': 'text/plain; charset=utf-8'
  //     },
  //     responseType: 'text' as 'json'
  //   }
  //   );
  // }

  // updateDirectory(empId: string, directory: DirectorySubmit): Observable<Directory> {
  //   return this.http.post<Directory>(endpoint + 'Employee/' + empId + '/Documents/Directory', directory, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });
  // }

  // uploadDocument(empId: string, file: File, parentId: string): Observable<any> {
  //   const formData: FormData = new FormData();

  //   formData.append('Id', '');
  //   formData.append('File', file);
  //   formData.append('FolderId', parentId ? parentId : '');
  //   formData.append('Comments', '');
  //   formData.append('EffectiveDate', '');
  //   formData.append('ChangeReason', '');
  //   formData.append('ChangeReasonComments', '');

  //   return this.http.post<any>(endpoint + 'Employee/' + empId + '/Documents/',
  //     formData,
  //     {
  //       headers: {},
  //       reportProgress: true,
  //       observe: 'events'
  //     }
  //   );
  // }

  delete(employeeId: string, documentId: string): Observable<any> {
    return this.http.delete<any>(`${this.envService.env.apiUrl}${api_routes.TALENT}/${employeeId}/${api_routes.DOCUMENTS}/${documentId}`, {
      headers: headers
    });
  }

  // downloadDocument(empId: string, id: string): Observable<any> {
  //   return this.http.get<any>(endpoint + 'Employee/' + empId + '/Documents/' + id + '/Download', {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   });
  // }
}
