import { Component, OnInit } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexLegend, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis } from "ng-apexcharts";

export type ApexChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    dataLabels: ApexDataLabels;
    yaxis: ApexYAxis;
    title: ApexTitleSubtitle;
    labels: string[];
    legend: ApexLegend;
    subtitle: ApexTitleSubtitle;
    tooltip: ApexTooltip;
    fill: ApexFill;
    plotOptions: any;
    grid: any;
    height: number;
};

@Component({
    selector: 'app-diversity-report',
    templateUrl: './goal-performance-report.component.html',
    styleUrls: ['./goal-performance-report.component.scss']
})
export class GoalPerformanceReportComponent implements OnInit {


    public activeGoalsData = [
        {
            category: "A",
            value: 1,
        },
    ];
    public holeSize: number = 100;

    public overallProgressData = [
        {
            category: "B",
            value: 0.291,
        }, {
            category: "C",
            value: 0.709,
        },
    ];
    public alignedGoalsData = [
        {
            category: "D",
            value: 0.2,
        }, {
            category: "C",
            value: 0.8,
        },
    ];

    public chartOptions: Partial<ApexChartOptions>;

    public goalAchievementChartOptions: Partial<ApexChartOptions>;

    constructor() {
        this.chartOptions = {
            series: [
                {
                    name: "",
                    data: [
                        18.5,
                        24.8,
                        23,
                        32.5,
                    ]
                }
            ],
            chart: {
                type: "area",
                height: 350,
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true
            },
            stroke: {
                curve: "straight"
            },
            labels: [
                "Jan 23",
                "Feb 23",
                "Mar 23",
                "Apr 23",
            ],
            legend: {
                horizontalAlign: "left"
            }
        };

        this.goalAchievementChartOptions = {
            series: [
                {
                    name: "",
                    data: [11, 32, 21, 19, 10, 1, 5]
                }
            ],
            chart: {
                type: "bar",
                // height: 430,
            },
            plotOptions: {
                bar: {
                    horizontal: false
                }
            },
            grid: {
                show: false,
            },
            dataLabels: {
                enabled: false,
                formatter(val: string | number | number[], opts?: any): string | number {
                    return val + "%";
                }
            },
            xaxis: {
                categories: [
                    "16-24",
                    "25-34",
                    "35-44",
                    "45-54",
                    "55 - 64",
                    "65+",
                    "Prefer not to say"
                ]
            },
            height: 300
        }
    }

    ngOnInit(): void {
    }

    public getColor(category: any): string {
        const colors = {
            "A": '#D5E4F0',
            "B": '#22F56D',
            "C": '#F1F1F1',
            "D": '#FFA500'
        };

        return colors[category.category] || '#ccc';
    }

}
