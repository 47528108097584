import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { SecurityRole } from '@app/modules/security-setup/models/security-role.model';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { defer, finalize } from 'rxjs';

@Component({
  selector: 'app-select-security-role-dialog',
  templateUrl: './select-security-role-dialog.component.html',
  styleUrls: ['./select-security-role-dialog.component.scss']
})
export class SelectSecurityRoleDialogComponent implements OnInit {
  @Output() emitSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  
  public gridDataResult: GridDataResult;
  public isLoading: boolean;
  public selectedItems: any[] = [];
  public roleList: SecurityRole[] = [];
  private sortString = "name-asc";
  public searchValue: string;
  public searchFilterString: string;
  clearSelectedItems: boolean = false;
  pageSize: number = 10;
  skip: number = 0;
  filterString: string;

  public columns: any[] = [
      {field: 'name', title: 'Name', dataType: "String"},
      {field: 'totalUsers', title: 'Total Users', dataType: "String"},
      {field: 'roleType', subField: 'name', title: 'Role Type', dataType: "String"},
      {field: 'description', title: 'Description', type: "longText", dataType: "String"},
  ]

  public filterCategories: any[] = [
      {field: 'name', title: 'Name', dataType: "String"},
      {field: 'description', title: 'Description', dataType: "String"}
  ];
  getRequest: any;

  constructor(
      private securityRoleService: SecurityRoleService,
      public translate: TranslateService,
      private dialog: MatDialog,
      private dialogRef: MatDialogRef<SelectSecurityRoleDialogComponent>,
  ) {}

  ngOnInit(): void {
      this.getSecurityRoles();
  }

  getSecurityRoles() {
      this.isLoading = true;

      this.getRequest = this.securityRoleService.getSecurityRoles(this.skip, String(this.pageSize), this.searchFilterString, this.sortString)
      .pipe(
          finalize(
              () => this.isLoading = false
          )
      )
      .subscribe(
          pagedSecurityRoles => {
              this.gridDataResult = {
                  data: pagedSecurityRoles.data,
                  total: pagedSecurityRoles.total,
              };
          }
      );
  }

  deleteAllSelected(itemsToDelete: string[]) {

  }

  search(searchValue) {
      this.searchValue = searchValue;
      this.searchFilterString = `(Name like "${this.searchValue}")`;
      this.getRequest.unsubscribe();
      this.getSecurityRoles();
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getSecurityRoles();
  }

  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      this.skip = 0;;
      this.getSecurityRoles();
  }

  openConfirmDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to add ${this.selectedItems.length} selected Security Role(s)?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.emitSelected.emit(this.selectedItems);
          this.dialogRef.close();
        }
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedItems.push(selectionEvent.selectedRows[0].dataItem.id);
    }
    else if(selectionEvent?.deselectedRows[0]?.dataItem) {
      let index = this.selectedItems.findIndex( i => i === selectionEvent.deselectedRows[0].dataItem.id);

      if(index > -1){
        this.selectedItems.splice(index, 1);
      }
    }
  }

}
