import { Component, OnInit } from '@angular/core';
import { TableList, TableListField } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { finalize, forkJoin, from, Observable } from 'rxjs';
import * as XLSX from 'xlsx/xlsx.mjs';
@Component({
  selector: 'app-data-template-download',
  templateUrl: './data-template-download.component.html',
  styleUrls: ['./data-template-download.component.scss']
})
export class DataTemplateDownloadComponent implements OnInit {
  isLoading: boolean = true;
  tableLookups: {name:string, list:TableList[]}[];
  selectedTablesList: TableList[] = [];
  // selectedTableListField: TableListField;
  tableListSearchValue: string = '';
  selectedTablesListSearchValue: string = '';
  tables: any[] = [];
  loadingFields: boolean;
  loadingTables: boolean;

  constructor(
    private lookupService: LookupService,
    private securitySetupService: SecuritySetupService,
  ) {
  }

  ngOnInit(): void {
    this.getAllTables();
  }

  capitalizeFirstLetter(string) {
    return string.charAt(1).toUpperCase() + string.slice(2);
  }

  getAllTables() {
    this.loadingTables = true;
    
    from(this.securitySetupService.getAllTables())
    .pipe(
      finalize( () => this.loadingTables = false )
    )
    .subscribe(
      res => {
        this.tables = res.data;
        this.getAllFields();
      }
    );
  }

  getAllFields() {
    this.loadingFields = true;

    let observables: Observable<any>[] = [];

    this.tables.forEach(table => {
      observables.push(from(this.securitySetupService.getAllFields(table.id)));
    });

    forkJoin(observables)
    .pipe(
      finalize( () => this.loadingFields = false )
    )
    .subscribe(
      (res) => {
        res.forEach((fields, index) => {
            this.tables[index].fields = fields.data;
          }
        ) 
      }
    );
  }

  addToList(tableList: any, item?: any){
    let tableListIndex = this.tables.findIndex( table => table.id === tableList.id );
    let selectedIndex = this.selectedTablesList.findIndex( table => table.id === tableList.id );


    if(item) {
      // if a field from this table has already been selected add it to the existing table
      if(selectedIndex !== -1) {
        this.selectedTablesList[selectedIndex].fields.push(item)
      }
      // if a field from this table has not already been selected add it to the table and the field
      else if(selectedIndex === -1) {
        let copy = {...tableList}
        copy.fields = [{...item}];
        this.selectedTablesList.push(copy);
      }

      // remove the selected field from the list
      if(tableListIndex !== -1) {
        let fieldIndex = tableList.fields.findIndex( field => field.id === item.id)

        if(fieldIndex !== -1){
          tableList.fields.splice(fieldIndex, 1)
        }
      }
    }
    else {
      // if a field from this table has already been selected add it to the existing table
      if(selectedIndex !== -1) {
        this.selectedTablesList[selectedIndex].fields = [...this.selectedTablesList[selectedIndex].fields, ...tableList.fields]
      }
      // if a field from this table has not already been selected add it to the table
      else if(selectedIndex === -1) {
        let copy = {...tableList}
        this.selectedTablesList.push(copy);
      }

      // remove the fields list
      if(tableListIndex !== -1) {
        this.tables[tableListIndex].fields = [];
      }
    }
  }

  removeFromList(tableList: any, item?: any){
    let tableListIndex = this.tables.findIndex( table => table.id === tableList.id );
    let selectedIndex = this.selectedTablesList.findIndex( table => table.id === tableList.id );

    if(item) {
      if(tableListIndex !== -1) {
        this.tables[tableListIndex].fields.push(item);

        let fieldIndex = tableList.fields.findIndex( field => field.id === item.id)
        if(fieldIndex !== -1){
          tableList.fields.splice(fieldIndex, 1)
        }
      }

      if(tableList.fields.length === 0){
        let selectedIndex = this.selectedTablesList.findIndex( table => table.id === tableList.id );

        this.selectedTablesList.splice(selectedIndex, 1);
      }
    }
    else {
      // if a field from this table has already been selected add it to the existing table
      if(tableListIndex !== -1) {
        this.tables[tableListIndex].fields = [...this.tables[tableListIndex].fields, ...tableList.fields]
      }
      // if a field from this table has not already been selected add it to the table
      else if(tableListIndex === -1) {
        let copy = {...tableList}
        this.tables.push(copy);
      }

      // remove the fields list
      if(tableListIndex !== -1) {
        this.selectedTablesList.splice(selectedIndex, 1)
      }
    }
  }

  exportToExcel(): void {
    const workbook = XLSX.utils.book_new();

    this.selectedTablesList.forEach(
      selectedTable => {
        const sheetData = [['EEID', 'EE Name', ...selectedTable.fields.map( field => field.name )]];
        const sheetName = selectedTable.name;
        const sheet = XLSX.utils.aoa_to_sheet(sheetData);
        XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
      }
    )

    // Generate Excel file
    const excelFileBuffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const excelFile = new Blob([excelFileBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    this.saveAsExcelFile(excelFile, 'data-load-file-template');
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url: string = window.URL.createObjectURL(data);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = fileName + '.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}
