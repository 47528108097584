import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { UploadProfilePicDialogComponent } from '../upload-profile-pic-dialog/upload-profile-pic-dialog.component';
import { set } from 'lodash';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import * as moment from 'moment';
import { finalize } from 'rxjs';
import { EmployeeService } from '@app/core/services/employee.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { CurrentUserDetails, TalentVerbose } from '@app/shared/models/employee.model';

@Component({
  selector: 'app-photo-and-details',
  templateUrl: './photo-and-details.component.html',
  styleUrls: ['./photo-and-details.component.scss']
})
export class PhotoAndDetailsComponent implements OnInit {
  user$: any;
  dialogRef: any;
  refresh = false;
  isLoading: boolean;
  talent: TalentVerbose;
  showForm: boolean = false;
  showButtons: boolean = false;
  formData: any
  formValid: any;
  formPristine: boolean;
  formId: string = 'frm_ojAfEWxnp9MD4C';
  changeReasonFormId: string = 'frm_yPsGlEqUSjqwkp';
  showEditor: boolean = false;
  aboutMeValue: string;
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private employeeService: EmployeeService,
    private oidcAuthService: OidcAuthService
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
    this.aboutMeValue = `<h2>Hello, I'm ${this.currentEmployeeDetails?.firstname}</h2><p></p><p>I am a versatile graphic designer who can approach marketing projects from concept to implementation</p>`;

  }

  ngOnInit(): void {
    this.getEmployeePersonalDetails();
  }

  openFormDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employeeID: this.user$.userId
    };

    this.dialogRef = this.dialog.open(UploadProfilePicDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.refreshData();
        }
      }
    );
  }

  refreshData() {
    this.refresh = true;
    
    setTimeout(() => {
      this.refresh = false;
    }, 100);
  }

  editSocialLinks() {

  }

  /*
  .########..########.########...######...#######..##....##....###....##..........########..########.########....###....####.##........######......######..########..######..########.####..#######..##....##.....######..########....###....########..########
  .##.....##.##.......##.....##.##....##.##.....##.###...##...##.##...##..........##.....##.##..........##......##.##....##..##.......##....##....##....##.##.......##....##....##.....##..##.....##.###...##....##....##....##......##.##...##.....##....##...
  .##.....##.##.......##.....##.##.......##.....##.####..##..##...##..##..........##.....##.##..........##.....##...##...##..##.......##..........##.......##.......##..........##.....##..##.....##.####..##....##..........##.....##...##..##.....##....##...
  .########..######...########...######..##.....##.##.##.##.##.....##.##..........##.....##.######......##....##.....##..##..##........######......######..######...##..........##.....##..##.....##.##.##.##.....######.....##....##.....##.########.....##...
  .##........##.......##...##.........##.##.....##.##..####.#########.##..........##.....##.##..........##....#########..##..##.............##..........##.##.......##..........##.....##..##.....##.##..####..........##....##....#########.##...##......##...
  .##........##.......##....##..##....##.##.....##.##...###.##.....##.##..........##.....##.##..........##....##.....##..##..##.......##....##....##....##.##.......##....##....##.....##..##.....##.##...###....##....##....##....##.....##.##....##.....##...
  .##........########.##.....##..######...#######..##....##.##.....##.########....########..########....##....##.....##.####.########..######......######..########..######.....##....####..#######..##....##.....######.....##....##.....##.##.....##....##...
  */

  displayForm() {
    this.showForm = true;

    setTimeout(() => {
      this.showButtons = true;
    }, 300);
  }

  getEmployeePersonalDetails(){
    this.isLoading = true; 

    this.employeeService.getTalent(this.user$.userId)
    .pipe(
      finalize(()=>{ 
        this.isLoading = false; 
      })
      )
      .subscribe(
        res => {
          this.talent = res;
          this.createFormData();
      }
    )
  }

  createFormData() {
    this.isLoading = true; 

    // this.formData = {
    //   id: this.talent ? this.talent.id : this.user$.userId,
    //   asOf: moment().format(),
    //   linkedInUrl: this.talent ? this.talent.linkedInUrl : null,
    //   twitterHandle: this.talent ? this.talent.twitterHandle : null,
    //   internetAddress: this.talent ? this.talent.internetAddress : null,
    //   facebookUrl: this.talent ? this.talent.facebookUrl : null,
    // }

    this.formData = {
      id: this.talent ? this.talent.id : null,
      userId: this.talent ? this.talent.userId : null,
      asOf: moment().format(),
      originalCreatedOn: this.talent?.version?.createdOn ? this.talent?.version?.createdOn : null,
      changeReason: null,
      changeReasonComments: '',
      firstname: this.talent ? this.talent.firstName : null,
      lastname: this.talent ? this.talent.lastName : null,
      middleName: this.talent ? this.talent.middleName : null,
      preferredName: this.talent ? this.talent.preferredName : null,
      maidenName: this.talent ? this.talent.maidenName : null,
      gender: this.talent ? this.talent.gender?.id : null,
      birthday: this.talent ? this.talent.birthday : null,
      maritalStatus: this.talent ? this.talent.maritalStatus?.id : null,
      culturalAffiliation: this.talent ? this.talent.culturalAffiliation?.id : null,
      religiousAffiliation: this.talent ? this.talent.religiousAffiliation?.id : null,
      preferredLanguage: this.talent ? this.talent.preferredLanguage : null,
      title: this.talent ? this.talent.title?.id : null,
      customClientId: this.talent ? this.talent.customClientId : null,
      username: this.talent ? this.talent.username : null,
      linkedInUrl: this.talent ? this.talent.linkedInUrl : null,
      twitterHandle: this.talent ? this.talent.twitterHandle : null,
      facebookUrl: this.talent ? this.talent.facebookUrl : null,
      streetAddress: this.talent ? this.talent.streetAddress : null,
      city: this.talent ? this.talent.city : null,
      province: this.talent ? this.talent.province : null,
      zip: this.talent ? this.talent.zip : null,
      country: this.talent ? this.talent.country?.id : null,
      email: this.talent ? this.talent.email : null,
      workPhone: this.talent ? this.talent.workPhone : null,
      homePhone: this.talent ? this.talent.homePhone : null,
      cellPhone: this.talent ? this.talent.cellPhone : null,
      timezone: this.talent ? this.talent.timezone?.id : null,
      culture: this.talent ? this.talent.culture?.id : null,
      organization: this.talent ? this.talent.organization?.id : null,
      streetAddress2: this.talent ? this.talent.streetAddress2 : null,
      municipality: this.talent ? this.talent.municipality : null,
      region1: this.talent ? this.talent.region1 : null,
      region2: this.talent ? this.talent.region2 : null,
      region3: this.talent ? this.talent.region3 : null,
      neighbourhood: this.talent ? this.talent.neighbourhood : null,
      email2: this.talent ? this.talent.email2 : null,
      internetAddress: this.talent ? this.talent.internetAddress : null,
      socialSecurityNumber: this.talent ? this.talent.socialSecurityNumber : null,
      identityNumber: this.talent ? this.talent.identityNumber : null,
      regionOfBirth: this.talent ? this.talent.regionOfBirth : null,
      townOfBirth: this.talent ? this.talent.townOfBirth : null,
      poBox: this.talent ? this.talent.poBox : null,
      registeredDisabled: this.talent ? this.talent.registeredDisabled?.id : null,
      disability: this.talent ? this.talent.disability?.id : null,
      veteranStatus: this.talent ? this.talent.veteranStatus?.id : null,
      equalEmploymentOpportunity: this.talent ? this.talent.equalEmploymentOpportunity?.id : null,
      nationality: this.talent ? this.talent.nationality?.id : null,
      countryOfBirth: this.talent ? this.talent.countryOfBirth?.id : null,
      preferredPronoun: this.talent ? this.talent.preferredPronoun : null,
      sexualOrientation: this.talent ? this.talent.sexualOrientation?.id : null,
    }

    this.isLoading = false; 
  }

  formDataEmitted(formData) {
    this.openChangeReasonDialog(formData);
    // this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
              this.addChangeReasonsToFormData(formData, data);
          }

      }
    );
  }

  addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.save(merged);
  }

  save(formData) {
    this.formData.linkedInUrl = formData.linkedInUrl;
    this.formData.twitterHandle = formData.twitterHandle;
    this.formData.internetAddress = formData.internetAddress;
    this.formData.facebookUrl = formData.facebookUrl;
    this.formData.changeReason = formData.changeReason;
    this.formData.changeReasonComments = formData.changeReasonComments;

    this.overlayService.show();

    this.employeeService.updateTalent(this.formData)
    .pipe(
      finalize(()=>{ 
        this.overlayService.hide();
      })
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  /*
  .########..########.########...######...#######..##....##....###....##..........########..########.########....###....####.##........######......######..########..######..########.####..#######..##....##....########.##....##.########.
  .##.....##.##.......##.....##.##....##.##.....##.###...##...##.##...##..........##.....##.##..........##......##.##....##..##.......##....##....##....##.##.......##....##....##.....##..##.....##.###...##....##.......###...##.##.....##
  .##.....##.##.......##.....##.##.......##.....##.####..##..##...##..##..........##.....##.##..........##.....##...##...##..##.......##..........##.......##.......##..........##.....##..##.....##.####..##....##.......####..##.##.....##
  .########..######...########...######..##.....##.##.##.##.##.....##.##..........##.....##.######......##....##.....##..##..##........######......######..######...##..........##.....##..##.....##.##.##.##....######...##.##.##.##.....##
  .##........##.......##...##.........##.##.....##.##..####.#########.##..........##.....##.##..........##....#########..##..##.............##..........##.##.......##..........##.....##..##.....##.##..####....##.......##..####.##.....##
  .##........##.......##....##..##....##.##.....##.##...###.##.....##.##..........##.....##.##..........##....##.....##..##..##.......##....##....##....##.##.......##....##....##.....##..##.....##.##...###....##.......##...###.##.....##
  .##........########.##.....##..######...#######..##....##.##.....##.########....########..########....##....##.....##.####.########..######......######..########..######.....##....####..#######..##....##....########.##....##.########.
  */

}