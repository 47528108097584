<h5 mat-dialog-title>Email Notification</h5>

<mat-dialog-content>
    <form class="form-container" *ngIf="form" [formGroup]="form">

        <mat-form-field appearance="fill">
            <mat-label>Subject</mat-label>
            <input matInput type="text" formControlName="subject">
        </mat-form-field>


        <mat-form-field appearance="fill">
            <mat-label>Recipients</mat-label>
            <mat-select formControlName="recipients" multiple>
                <mat-option *ngFor="let recipient of recipientsList" [value]="recipient">
                    {{ recipient }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Active</mat-label>
            <mat-select formControlName="active">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Reminder Prior (Days)</mat-label>
            <input matInput type="number" formControlName="reminderPriorDays">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Send Every (Days)</mat-label>
            <input matInput type="number" formControlName="sendEveryDays">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Exclude Terminated Employees</mat-label>
            <mat-select formControlName="excludeTerminatedEmployees">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
            </mat-select>
        </mat-form-field>

        <kendo-editor formControlName="content"></kendo-editor>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
