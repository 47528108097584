<app-site-settings-menu>
    <mat-card>
        <mat-card-title class="header">
            <h5 mat-dialog-title *ngIf="dataTriggerGroupID">Edit a Data Trigger Rule</h5>
            <h5 mat-dialog-title *ngIf="!dataTriggerGroupID">Create a new Data Trigger Rule</h5>

            <div class="buttons-container">
                <button mat-raised-button (click)="navigateBack()">Close</button>
                <button
                    mat-raised-button
                    color="primary"
                    (click)="save()"
                >
                    Save
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div class="position-details-form">
                <ng-container *ngIf="isLoading; else loaded">
                    <ngx-skeleton-loader
                        count="5"
                        [theme]="{
                          'height.px': 50
                      }"
                    ></ngx-skeleton-loader>
                </ng-container>
                <ng-template #loaded>
                    <div class="form-section">

                        <div class="form-row">
                            <div class="form-section-title">
                                <h6>Data Trigger Rule Details</h6>
                            </div>
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Trigger Name
                                    </mat-label>

                                    <input matInput type="text" [value]="currentTrigger.name" required>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>
                                        Description
                                    </mat-label>

                                    <input matInput type="text" [value]="currentTrigger.description" required>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="form-section-title">
                            <h6>Rules</h6>
                        </div>
                        <div class="section-row">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel *ngFor=" let rule of currentTrigger.rules; let idx = index" [expanded]="idx==0 || rule.expanded">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div class="label">Rule #{{idx + 1}}</div>
                                        </mat-panel-title>
                                        <div class="card-buttons-container">

                                            <mat-icon
                                                class="material-icons-outlined"
                                                (click)="deleteRule(idx); $event.stopPropagation();"
                                                matTooltip="Delete"
                                            >delete
                                            </mat-icon>
                                        </div>
                                    </mat-expansion-panel-header>

                                    <mat-panel-description>
                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                Select Form
                                            </mat-label>

                                            <mat-select disableOptionCentering
                                                        (selectionChange)="onFormChange($event, rule)"
                                                        [(ngModel)]="rule.formId">
                                                <mat-option *ngFor="let form of forms"
                                                            [value]="form.id">{{ form.name || form.table?.name || form.id }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                Select Field
                                            </mat-label>

                                            <mat-select disableOptionCentering
                                                        (selectionChange)="onFieldChange($event, rule)"
                                                        [(ngModel)]="rule.fieldId">
                                                <mat-option *ngFor="let field of rule.filteredFields"
                                                            [value]="field.id">{{ field.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                Select Condition
                                            </mat-label>

                                            <mat-select disableOptionCentering
                                                        (selectionChange)="onConditionChange($event, rule)"
                                                        [(ngModel)]="rule.conditionId">
                                                <mat-option
                                                    *ngFor="let condition of rule.filteredConditions"
                                                    [value]="condition.id">{{ condition.name }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <div [ngSwitch]="rule.dataType">
                                            <ng-container *ngSwitchCase="'DECIMAL'">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Value
                                                    </mat-label>

                                                    <input matInput type="number" [(ngModel)]="rule.fieldValue">
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DOUBLE-DECIMAL'">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        From
                                                    </mat-label>

                                                    <input matInput type="number" [(ngModel)]="rule.fieldValue2">
                                                </mat-form-field>
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        To
                                                    </mat-label>

                                                    <input matInput type="number" [(ngModel)]="rule.fieldValue">
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'TEXT'">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        String Value
                                                    </mat-label>

                                                    <input matInput type="text" [value]="rule.fieldValue">
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DATETIME'">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Date
                                                    </mat-label>

                                                    <input matInput [matDatepicker]="picker"
                                                           [(ngModel)]="rule.fieldValue">
                                                    <span fxLayout="row" matSuffix>
                                                                <mat-datepicker-toggle
                                                                    [for]="picker"></mat-datepicker-toggle>
                                                            </span>
                                                    <mat-datepicker #picker></mat-datepicker>
                                                </mat-form-field>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'DROPDOWN'">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Action Type
                                                    </mat-label>

                                                    <mat-select disableOptionCentering [(ngModel)]="rule.fieldValue">
                                                        <mat-option *ngFor="let type of rule.filteredDropdowns"
                                                                    [value]="type.id">{{ type.text || type.table?.name || type.id }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>
                                        </div>
                                    </mat-panel-description>
                                </mat-expansion-panel>
                            </mat-accordion>

                            <button mat-stroked-button color="primary" (click)="addNewRule()">
                                <mat-icon class="material-icons-outlined">add</mat-icon>
                                Add New Rule
                            </button>
                        </div>


                        <div class="form-section-title">
                            <h6>Outcomes</h6>
                        </div>
                        <div class="section-row">
                            <mat-accordion class="example-headers-align" multi>
                                <mat-expansion-panel *ngFor=" let outcome of currentTrigger.outcomes; let idx = index" [expanded]="idx==0 || outcome.expanded">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <div class="label">Outcome #{{idx + 1}}</div>
                                        </mat-panel-title>
                                        <div class="card-buttons-container">

                                            <mat-icon
                                                class="material-icons-outlined"
                                                (click)="deleteOutcome(idx); $event.stopPropagation();"
                                                matTooltip="Delete"
                                            >delete
                                            </mat-icon>
                                        </div>
                                    </mat-expansion-panel-header>
                                    <mat-panel-description>
                                        <mat-form-field appearance="fill">
                                            <mat-label>
                                                Select Action Type
                                            </mat-label>

                                            <mat-select disableOptionCentering
                                                        (selectionChange)="onActionTypeChange($event, outcome)"
                                                        [(ngModel)]="outcome.actionId">
                                                <mat-option *ngFor="let type of outcome.types"
                                                            [value]="type.id">{{ type.name || type.table?.name || type.id }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-panel-description [ngSwitch]="outcome.selectedType">

                                            <ng-container *ngSwitchCase="'triggerNotification'">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Notification
                                                    </mat-label>

                                                    <mat-select disableOptionCentering [(ngModel)]="outcome.fieldValue">
                                                        <mat-option
                                                            *ngFor="let notification of emailNotificationFields"
                                                            [value]="notification.id">{{ notification.subject }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </ng-container>


                                            <ng-container *ngSwitchCase="'setValue'" class ="set-value-columns">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Form
                                                    </mat-label>

                                                    <mat-select disableOptionCentering
                                                                (selectionChange)="onFormChange($event, outcome)"
                                                                [(ngModel)]="outcome.formId">
                                                        <mat-option *ngFor="let form of forms"
                                                                    [value]="form.id">{{ form.name || form.table?.name || form.id }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Field
                                                    </mat-label>

                                                    <mat-select disableOptionCentering
                                                                (selectionChange)="onFieldChangeOutcome($event, outcome)"
                                                                [(ngModel)]="outcome.fieldId">
                                                        <mat-option *ngFor="let field of outcome.filteredFields"
                                                                    [value]="field.id">{{ field.name }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                            </ng-container>
                                            <ng-container *ngIf="outcome.actionId == 'setValue'">

<!--                                                <mat-form-field appearance="fill">-->
<!--                                                    <mat-label>-->
<!--                                                        Value-->
<!--                                                    </mat-label>-->

<!--                                                    <input matInput type="text" [value]="outcome.fieldValue">-->
<!--                                                </mat-form-field>-->

                                                <div [ngSwitch]="outcome.dataType">
                                                    <ng-container *ngSwitchCase="'DECIMAL'">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>
                                                                Value
                                                            </mat-label>

                                                            <input matInput type="number" [(ngModel)]="outcome.fieldValue">
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'DOUBLE-DECIMAL'">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>
                                                                From
                                                            </mat-label>

                                                            <input matInput type="number" [(ngModel)]="outcome.fieldValue2">
                                                        </mat-form-field>
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>
                                                                To
                                                            </mat-label>

                                                            <input matInput type="number" [(ngModel)]="outcome.fieldValue">
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'TEXT'">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>
                                                                String Value
                                                            </mat-label>

                                                            <input matInput type="text" [value]="outcome.fieldValue">
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'DATETIME'">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>
                                                                Date
                                                            </mat-label>

                                                            <input matInput [matDatepicker]="picker"
                                                                   [(ngModel)]="outcome.fieldValue">
                                                            <span fxLayout="row" matSuffix>
                                                                <mat-datepicker-toggle
                                                                    [for]="picker"></mat-datepicker-toggle>
                                                            </span>
                                                            <mat-datepicker #picker></mat-datepicker>
                                                        </mat-form-field>
                                                    </ng-container>
                                                    <ng-container *ngSwitchCase="'DROPDOWN'">
                                                        <mat-form-field appearance="fill">
                                                            <mat-label>
                                                                Select Action Type
                                                            </mat-label>

                                                            <mat-select disableOptionCentering [(ngModel)]="outcome.fieldValue">
                                                                <mat-option *ngFor="let type of outcome.filteredDropdowns"
                                                                            [value]="type.id">{{ type.text || type.table?.name || type.id }}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </ng-container>
                                                </div>


                                            </ng-container>
                                        </mat-panel-description>
                                    </mat-panel-description>

                                </mat-expansion-panel>
                            </mat-accordion>
                            <button mat-stroked-button color="primary" (click)="addNewOutcome()">
                                <mat-icon class="material-icons-outlined">add</mat-icon>
                                Add New Outcome
                            </button>

                        </div>
                    </div>
                </ng-template>

            </div>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>
