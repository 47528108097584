<h5 mat-dialog-title>{{ 'SelectSecurityRole' | translate: {Default: "Select Security Role"} }}</h5>

<mat-dialog-content>
    <app-data-grid-comp
        [columns]="columns"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [hideActionButtons]="true"
        [skip]="skip"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        [selectedRowsButtonSettings]="{
            show: false,
            text: null,
            icon: null
        }"
        clickableRows="true"
        searchable="true"
        category=""
        [filterCategories]="filterCategories"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitSearchEvent)="search($event)"
        (emitDeleteEvent)="deleteAllSelected($event)"
        (emitFilterEvent)="filterCallback($event)"
        (emitSelectedRows)="selectedRowsChanged($event)"
    ></app-data-grid-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <button class="add-all-selected-button" [disabled]="selectedItems.length === 0" mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openConfirmDialog()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add All Selected ({{selectedItems.length}})
    </button>
</mat-dialog-actions>