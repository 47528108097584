import { Localization } from '@app/shared/models/localization.model';

export interface BusinessRuleAutomation {
    id: string,
    name: string,
    description: string,
    status: boolean,
    activeUsersOnly: boolean,
}

export interface BusinessRuleAutomationVerbose {
    id: string,
    name: Localization[],
    description: Localization[],
    status: boolean,
    activeUsersOnly: boolean,
    trigger: Trigger,
    rules: BusinessRuleAutomationRule[],
}

export interface Trigger {
    id: string,
    triggerType: TriggerType;
    tableId: string;
    fieldId: string;
    conditionType: ConditionType;
    valueOne: string;
}

export interface BusinessRuleAutomationRule {
    id: string,
    tableId: string,
    fieldId: string;
    conditionType: ConditionType;
    valueOne: string;
    valueTwo: string;
}

export interface BusinessRuleAutomationOutcome {
    id: string,
    tableId: string,
    fieldId: string;
    conditionType: ConditionType;
    valueOne: string;
}

export interface TriggerType {
    id: string,
    name: string,
}

export interface ConditionType {
    id: string,
    name: string,
    dataType: string,
}


export interface BusinessRuleAutomationNotification {
    id: string,
    notificationType: BusinessRuleAutomationNotificationType,
    status: boolean,
    recipientType: BusinessRuleAutomationRecipientType,
    notificationRecipientType: BusinessRuleAutomationRecipientType,
    notificationRecipients: BusinessRuleAutomationNotificationRecipient[],
    subject: Localization[],
    body: Localization[],
}

export interface BusinessRuleAutomationNotificationType {
    id: string,
    name: string,
}

export interface BusinessRuleAutomationRecipientType {
    id: string,
    name: string,
}

export interface BusinessRuleAutomationNotificationRecipient {
    emailAddress: string,
}
