import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-company-announcements-widget',
    templateUrl: './company-announcements-widget.component.html',
    styleUrls: ['./company-announcements-widget.component.scss']
})
export class CompanyAnnouncementsWidgetComponent implements OnInit {
    loading: boolean = false;
    searchPeriod: string;
    companyAnnouncements: {
        id: string,
        topic: string,
        date: string,
        icon: string
    }[] = [
        {
            id: '1232342',
            topic: 'New share purchase programme',
            date: moment().subtract(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD'),
            icon: 'insights'
        },
        {
            id: '5674567',
            topic: 'Updated Covid-19 policies',
            date: moment().subtract(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD'),
            icon: 'coronavirus'
        },
        {
            id: '5674567',
            topic: 'Remote Working Policy',
            date: moment().subtract(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD'),
            icon: 'maps_home_work'
        },
        {
            id: '7897890',
            topic: 'Launch of New Product',
            date: moment().subtract(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD'),
            icon: 'store'
        },
        {
            id: '9879871',
            topic: 'Partnership with Industry Leader',
            date: moment().subtract(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD'),
            icon: 'people'
        }
    ]

    constructor() {
    }

    ngOnInit(): void {
    }

}
