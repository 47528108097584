<mat-tab-group animationDuration="0ms" setColor="primaryColour" class="succession-profile-tab-group">
    <mat-tab label="Formal Education">
        <app-succession-education></app-succession-education>
    </mat-tab>
    <mat-tab label="Work History">
        <app-succession-mobility></app-succession-mobility>
    </mat-tab>
    <mat-tab label="Training & Certificates">
        <app-succession-potential-and-readiness></app-succession-potential-and-readiness>
    </mat-tab>
    <mat-tab label="Professional Expertise">
        <app-succession-successors
        ></app-succession-successors>
    </mat-tab>
    <mat-tab label="Languages">
        <app-succession-peer-recommendations></app-succession-peer-recommendations>
    </mat-tab>
    <mat-tab label="Associations & Awards">
        <app-succession-interview></app-succession-interview>
    </mat-tab>
    <mat-tab label="Projects">
        <app-succession-interview></app-succession-interview>
    </mat-tab>
</mat-tab-group>