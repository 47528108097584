import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { api_routes, db_tables } from '@app/consts';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { FamilyDependantService } from '../../services/family-dependant.service';
import { FamilyDependant } from '../../models/family-dependant.model';

@Component({
  selector: 'app-family-dependants-grid',
  templateUrl: './family-dependants-grid.component.html',
  styleUrls: ['./family-dependants-grid.component.scss']
})
export class FamilyDependantsGridComponent implements OnInit {
  @Input() formIds: string[];
  @Input() employeeId: string;

  public columns: any[] = [
    { field: "firstName", title: "firstName", tableId: "tfi_FFirstName"},
    { field: "lastName", title: "last Name", tableId: "tfi_FLastName"},
    { field: "isDependent", title: "is Dependent", type: "boolean", default: ["Yes", "No"], tableId: "tfi_FIsDependant"},
    { field: "email", title: "email", tableId: "tfi_FmlyEmail"},
    { field: "homePhone", title: "home Phone", tableId: "tfi_FmlyHomePhone"},
    { field: "workPhone", title: "work Phone", tableId: "tfi_FmlyWorkPhone"},
    { field: "comments", title: "Comments", type:"longText", tableId: "tfi_FComments"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  dialogRef: MatDialogRef<FormGeneratorDialogComponent>;
  formId: string = 'frm_FyFtnMvPWhDtcO'
  changeReasonFormId: string = 'frm_uv9BMPrR6cnCh1';
  formValid: boolean;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.FAMILYS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private familyDependantService: FamilyDependantService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    // this.route.paramMap.subscribe(
    //     params => this.employeeId = params.get("id")
    // )
  }

    ngOnInit(): void {
        this.view = this.getFamilyDependants();
        this.getTargetEmployeeTablePermissions();
    }

    getFamilyDependants() {
        this.isLoading = true;

        this.familyDependantService.getFamilyDependants(this.employeeId, String(this.pageSize), this.skip)
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                (res) => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                    this.gridData = res.data;
                }
            );
    }

    getTargetEmployeeTablePermissions() {
        this.isLoadingPermissions = true;

        let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

        // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
        if(currentFetchedData !== null){
          this.targetEmployeeTablePermissions = currentFetchedData;
          this.isLoadingPermissions = false;
        }
        else {
          this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
          .pipe(
            finalize(() => {
                this.isLoadingPermissions = false;
            })
          )
          .subscribe(
            res => {
              this.targetEmployeeTablePermissions = res;
            }
          );
        }
    }

    getFamilyDependant(familyDependant?: FamilyDependant) {
        if (familyDependant === undefined) {
            this.openFormDialog();
        } else {
            this.isLoading = true;

            this.familyDependantService.getFamilyDependant(this.employeeId, familyDependant.id)
                .pipe(
                    finalize(() => this.isLoading = false)
                )
                .subscribe(
                    res => {
                        this.openFormDialog(res);
                    }
                );
        }
    }

    openFormDialog(familyDependant?: FamilyDependant) {
        let formData = {
            id: familyDependant ? familyDependant.id : null,
            asOf: moment().format(),
            changeReason: '',
            changeReasonComments: '',
            firstName: familyDependant ? familyDependant.firstName : null,
            lastName: familyDependant ? familyDependant.lastName : null,
            middleName: familyDependant ? familyDependant.middleName : null,
            preferredName: familyDependant ? familyDependant.preferredName : null,
            birthday: familyDependant ? familyDependant.birthday : null,
            gender: familyDependant ? familyDependant.gender?.id : null,
            isDependent: familyDependant ? familyDependant.isDependent : false,
            relationshipToEmployee: familyDependant ? familyDependant.relationshipToEmployee?.id : null,
            title: familyDependant ? familyDependant.title?.id : null,
            streetAddress: familyDependant ? familyDependant.streetAddress : null,
            city: familyDependant ? familyDependant.city : null,
            province: familyDependant ? familyDependant.province : null,
            zip: familyDependant ? familyDependant.zip : null,
            country: familyDependant ? familyDependant.country?.id : null,
            email: familyDependant ? familyDependant.email : null,
            workPhone: familyDependant ? familyDependant.workPhone : null,
            homePhone: familyDependant ? familyDependant.homePhone : null,
            cellPhone: familyDependant ? familyDependant.cellPhone : null,
            comments: familyDependant ? familyDependant.comments : null,
        };

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            formTitle: `${this.translate.instant('FamilyDependant')}`,
            formId: this.formId,
            changeReasonFormId: this.changeReasonFormId,
            targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
            formData: formData
        };

        this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

        const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
            this.save(event);
        });
    }

    formDataEmitted(formData) {
        this.save(formData);
    }

    formStatusUpdated(formValid) {
        this.formValid = formValid;
    }

    save(formData: any) {
        this.overlayService.show();
        if (formData.birthday) {formData.birthday = moment(formData.birthday).format().slice(0, 10);}
        if (!formData.id) {
            this.familyDependantService.createNewFamilyDependant(this.employeeId, formData)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                        this.dialogRef.close(true);
                        this.getFamilyDependants();
                    }
                );
        } else {
            this.familyDependantService.updateFamilyDependant(this.employeeId, formData)
                .pipe(
                    finalize(() => this.overlayService.hide())
                )
                .subscribe(
                    (res) => {
                        this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                        this.dialogRef.close(true);
                        this.getFamilyDependants();
                    }
                );
        }
    }

    deleteFamilyDependants(familyDependantIds: string[]) {
        const observables = familyDependantIds.map(selectedItem => defer(() => this.familyDependantService.deleteFamilyDependant(this.employeeId, selectedItem)));

        this.overlayService.show();

        forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.view = this.getFamilyDependants();
                    this.clearSelectedItems = !this.clearSelectedItems;
                    this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip;
        this.pageSize = event.take
        this.view = this.getFamilyDependants();
    }

}
