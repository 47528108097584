import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { WorkflowTask } from '@app/modules/workflow-section/models/workflows.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-task-item',
  templateUrl: './form-task-item.component.html',
  styleUrls: ['./form-task-item.component.scss']
})
export class FormTaskItemComponent implements OnInit {
  @Input() task: WorkflowTask;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  getFormData: boolean = false;
  formValid: boolean = false;
  formPristine: boolean = false;

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  formDataEmitted(formData) {
    this.save();
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  checkboxChange($event: MatCheckboxChange) {
    this.checkedChange.emit($event.checked);
  } 

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    }, 500)
  }

}
