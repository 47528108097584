export interface Workflow {
    id: string
    name: string
    description: string
    categories: WorkflowCategory[]
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
}

export interface WorkflowCategory {
    id: string
    name: string
    description: string
    tasks: WorkflowTask[]
}

export interface WorkflowTask {
    id: string
    name: string
    description: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    completed: boolean
    type: string
    document?: any
    form?: any
    letter?: any
    survey?: any
}

export interface PublishedWorkflow {
    id: string
    name: string
    description: string
    workflow: {
        id: string
        name: string
    }
    startDate: string
    endDate: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    createdOn: string
    status: {
        id: string,
        text: string
    }
    participants: {
        id: string
        firstName: string
        lastName: string
    }[]
    owners: {
        id: string
        firstName: string
        lastName: string
    }[]
}