import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { features } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { FeatureService } from '@app/core/services/feature.service';
import { TranslationService } from '@app/core/services/translation.service';
import { EmployeeSecurityService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-security/services/employee-security.service';
import { HistoryDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { NewTranslationDialogComponent } from '@app/modules/translations/components/new-translation-dialog/new-translation-dialog.component';
import { UpdateTranslationDialogComponent } from '@app/modules/translations/components/update-translation-dialog/update-translation-dialog.component';
import { CurrentUserDetails } from '../models/employee.model';

@Directive({
  selector: '[appLocalizationPopupDirective]'
})
export class LocalizationPopupDirectiveDirective {
  @Input() localizationCode: string; // Input property to receive content for the popup
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private dialog: MatDialog,
    private el: ElementRef, 
    private renderer: Renderer2,
    private translationService: TranslationService,
    private employeeSecurityService: EmployeeSecurityService,
    private employeeService: EmployeeService,
    private featureService: FeatureService,
  ) {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  ngOnInit() {
    if(this.currentEmployeeDetails.userType === 'User'){
      // this.addIcon(); // Add the icon when the directive initializes
    }
  }

  private addIcon() {
    // Create a mat-icon element
    const matIcon = this.renderer.createElement('mat-icon');
    this.renderer.addClass(matIcon, 'material-icons-outlined'); // Add the icon class
    this.renderer.addClass(matIcon, 'clickable-icon'); // Add the icon class
    this.renderer.addClass(matIcon, 'k-mr-2'); // Add the icon class
    
    // this.renderer.addClass(matIcon, 'bordered-icon'); // Add the icon class
    matIcon.innerHTML = 'translate'; // Set the icon name

    // Append the mat-icon element before the element's content
    this.renderer.insertBefore(this.el.nativeElement, matIcon, this.el.nativeElement.firstChild);

    this.addIconClickListener(); // Attach click listener to the icon when the directive initializes
  }

  private addIconClickListener() {
    const iconElement = this.el.nativeElement.querySelector('mat-icon');

    if (iconElement) {
      this.renderer.listen(iconElement, 'click', (event) => {
        event.stopPropagation();

        // If there is an existing trasnlation open the editor, else open the dialog to add a new translation code
        if(this.translationService.currentTranslations[this.localizationCode]) {
          this.openUpdateTranslationDialog();
        }
        else {
          this.openNewTranslationCodeDialog();
        }

      });
    }
  }

  openNewTranslationCodeDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      systemId: this.localizationCode
    };

    const dialogRef = this.dialog.open(NewTranslationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true){
          this.openUpdateTranslationDialog();
        }
      }
    );
  }

  openUpdateTranslationDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      systemId: this.localizationCode
    };

    const dialogRef = this.dialog.open(UpdateTranslationDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        
      }
    );
  }

}
