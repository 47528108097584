<mat-card>
    <mat-card-title>
        <h5>{{title}}</h5>
    </mat-card-title>

    <mat-card-content fxLayout="column" fxLayoutAlign="start">
        <p>{{description}}</p>

        <kendo-chart [seriesColors]="seriesColors" class="chart">
            <kendo-chart-legend position="bottom"></kendo-chart-legend>
            <kendo-chart-series>
                <kendo-chart-series-item
                    [autoFit]="true"
                    type="pie"
                    [data]="dataArray"
                    field="value"
                    categoryField="category"
                    [labels]="{ visible: true, content: labelContent }"
                >
                </kendo-chart-series-item>
            </kendo-chart-series>
        </kendo-chart>
    </mat-card-content>
</mat-card>
