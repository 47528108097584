<h5 *ngIf="directory !== undefined" mat-dialog-title appLocalizationPopupDirective [localizationCode]="'EditDirectory'">
    {{ 'EditDirectory' | translate: {Default: "Edit Directory"} }}
</h5>

<h5 *ngIf="directory === undefined" mat-dialog-title appLocalizationPopupDirective [localizationCode]="'CreateDirectory'">
    {{ 'CreateDirectory' | translate: {Default: "Create Directory"} }}
</h5>

<mat-dialog-content class="form">
    <mat-form-field appearance="fill">
        <mat-label>
            Select A Directory
        </mat-label>
     
        <mat-select disableOptionCentering [(ngModel)]="clonedDirectory.parentDirectoryId">
            <mat-option [value]="null">
                None
            </mat-option>

            <ng-container *ngFor="let directory of directories">
                <mat-option [value]="directory.id">{{directory.name}}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'DirectoryName' | translate: {Default: "Directory Name"} }}
        </mat-label>
        <input matInput [(ngModel)]="clonedDirectory.name">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'Comments' | translate: {Default: "Comments"} }}
        </mat-label>
        <textarea matInput [(ngModel)]="clonedDirectory.comments"></textarea>
    </mat-form-field>

    <mat-slide-toggle  setColor="primaryColour" [(ngModel)]="clonedDirectory.hugoAccess">{{ 'HugoAccess' | translate: {Default: "Hugo Access"} }}</mat-slide-toggle>

    <mat-slide-toggle  setColor="primaryColour" [(ngModel)]="clonedDirectory.isPrivate">{{ 'Private' | translate: {Default: "Private"} }}</mat-slide-toggle>

    <div class="form-elements-container">
        <app-work-locations-form-field
            class="work-locations-form-field"
            [formElement]="workLocationFormField"
            [parentGroup]="form"
        ></app-work-locations-form-field>

        <app-security-role-form-field
            class="security-role-form-field"
            [formElement]="securityRoleFormField"
            [parentGroup]="form"
        ></app-security-role-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button class="close" appPreventDoubleClick (throttledClick)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="saveDirectory()">{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>