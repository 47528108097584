import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HugoDocument } from '../../models/hugo-documents.model';
import { ActivatedRoute } from '@angular/router';
import { HugoDocumentsService } from '../../services/hugo-documents.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-hugo-document',
  templateUrl: './edit-hugo-document.component.html',
  styleUrls: ['./edit-hugo-document.component.scss']
})
export class EditHugoDocumentComponent implements OnInit {
  currentFile?: File;
  progress = 0;
  message = '';
  employeeId: string = '';
  parentDirectory: string;
  name: string;

  fileName = 'Select File';
  fileInfos?: Observable<any>;
  directories$: Observable<any>;
  document: HugoDocument;
  documentId: string;
  isLoading: boolean;
  documents: HugoDocument[];
  clonedDocument: HugoDocument;
  parentDirectoryId: string;
  currentEmployeeDetails: CurrentUserDetails;
  directories: HugoDocument[] = [];

  form: UntypedFormGroup = this.fb.group({
    workLocations: this.fb.array([]),
    securityRoles: this.fb.array([]),
    employees: this.fb.array([])
  });

  securityRoleFormField: any = {
    "id":"fel_wWPNZkgF4EfY4G",
    "text":"Security Roles",
    "tooltipText":null,
    "properties":[
       {
          "property":"requiredField",
          "value":"true"
       },
       {
          "property":"hidden",
          "value":""
       },
       {
          "property":"disabled",
          "value":""
       },
       {
          "property":"formControl",
          "value":"firstName"
       },
       {
          "property":"order",
          "value":"0"
       }
    ],
    "formElementType":null,
    "parentFormElement":null,
    "tableField":null,
    "requiredField":"false",
    "hidden":"false",
    "disabled":"",
    "formControl":"securityRoles",
    "order":"0",
    "placeholder":null
  }

  workLocationFormField: any = {
    "id":"fel_wWPNZkgF4EfY4G",
    "text":"Work Locations",
    "tooltipText":null,
    "properties":[
       {
          "property":"requiredField",
          "value":"true"
       },
       {
          "property":"hidden",
          "value":""
       },
       {
          "property":"disabled",
          "value":""
       },
       {
          "property":"formControl",
          "value":"firstName"
       },
       {
          "property":"order",
          "value":"0"
       }
    ],
    "formElementType":null,
    "parentFormElement":null,
    "tableField":null,
    "requiredField":"false",
    "hidden":"false",
    "disabled":"",
    "formControl":"workLocations",
    "order":"0",
    "placeholder":null
  }

  constructor(
    private dialogRef: MatDialogRef<EditHugoDocumentComponent>,
    private hugoDocumentsService: HugoDocumentsService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails();

    this.document = data.document;
    this.parentDirectoryId = data.parentDirectoryId;

    if(this.document === undefined) {
      this.clonedDocument = {
        id: this.generateRandomId(),
        isDirectory: false,
        parentDirectoryId: this.parentDirectoryId,
        fileLength: 0,
        name: '',
        comments: '',
        contentType: null,
        hugoAccess: true,
        isPrivate: false,
        security: null,
        version: {
          createdOn: moment().format(),
          effectiveDate: moment().format(),
          modifiedList: [],
          changeReason: null,
          comments: null,
          isDeleted: false,
          createdBy: {
            id: this.currentEmployeeDetails.username,
            firstName: this.currentEmployeeDetails.firstname,
            lastName: this.currentEmployeeDetails.lastname
          }
        }
      }
    }
    else {
      this.clonedDocument = {...this.document};

      const securityRolesArray = this.form.get('securityRoles') as FormArray;
      securityRolesArray.clear();
      this.clonedDocument.security?.securityRoles.forEach(role => {
        securityRolesArray.push(new FormControl(role));
      });

      const workLocationsArray = this.form.get('workLocations') as FormArray;
      workLocationsArray.clear();
      this.clonedDocument.security?.workLocations.forEach(location => {
        workLocationsArray.push(new FormControl(location));
      });

      const employeesArray = this.form.get('employees') as FormArray;
      employeesArray.clear();
      this.clonedDocument.security?.employees.forEach(employee => {
        employeesArray.push(new FormControl(employee));
      });
    }
  }

  ngOnInit() {
    this.getDocuments();
    this.getDirectories();
  }

  generateRandomId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  getDirectories(): void {
    this.hugoDocumentsService.getDirectories()
    .subscribe(
      directories => {
        this.directories = directories;
      }
    );
  }

  getDocuments() {
    this.isLoading = true;

    this.hugoDocumentsService.getDocuments()
    .subscribe(
      documents => {
        this.documents = documents;
        this.isLoading = false
      }
    );
  }

  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
      console.log(this.currentFile)
    } else {
      this.fileName = 'Select File';
    }
  }

  upload() {
    this.progress = 0;
    this.message = "";

    if (this.currentFile) {
      const interval = setInterval(() => {
        if (this.progress < 100) {
          this.progress += Math.floor(Math.random() * 10) + 1;
          if (this.progress > 100) {
            this.progress = 100;
            this.message = 'Uploaded successfully!';
            this.hugoDocumentsService.createDocument(this.clonedDocument)
            this.snackbarService.openSnackBar('Document created successfully', 'clear', 'success');
            this.dialogRef.close(true);

            clearInterval(interval);
          }
        } else {
          clearInterval(interval);
        }
      }, 100);
    }
  }

  saveDocument() {
    if(this.document === undefined) {
      this.clonedDocument.contentType = this.currentFile.type;
      this.clonedDocument.fileLength = this.currentFile.size;
      this.clonedDocument.security = this.form.value;

      this.upload();
    }
    else {
      this.clonedDocument.security = this.form.value;
      this.document = {...this.clonedDocument};
      this.hugoDocumentsService.updateDocument(this.document)
      this.snackbarService.openSnackBar('Document updated successfully', 'clear', 'success');
      this.dialogRef.close(true);
    }
  }

  close() {
    this.dialogRef.close();
  }

}

