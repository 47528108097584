import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs';
import { PublishedWorkflow } from '../../models/workflows.model';
import { WorkflowsService } from '../../services/workflows.service';

@Component({
  selector: 'app-workflow-published-list',
  templateUrl: './workflow-published-list.component.html',
  styleUrls: ['./workflow-published-list.component.scss']
})
export class WorkflowPublishedListComponent implements OnInit {

  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'name', 
      title: 'Name'
    }, 
    {
      field: 'description', 
      title: 'Description'
    },
    {
      field: 'status', 
      subField: 'text', 
      title: 'status', 
      type: "chip",
      chipValue: [ "Complete", "Cancelled", "In Progress" ]
    }, 
    {
      field: 'workflow', 
      subField: 'name', 
      title: 'Workflow'
    }, 
    {
      field: 'startDate', 
      title: 'Start Date', 
      type: "date"
    },
    {
      field: 'endDate', 
      title: 'End Date', 
      type: "date"
    },
    {
      field: 'participants', 
      title: 'Participants', 
      type: "employees"
    }, 
    // {
    //   field: 'owners', 
    //   title: 'Owners', 
    //   type: "employees"
    // },
    {
      field: 'createdOn', 
      title: 'Created On', 
      type: "date"
    }, 
    {
      field: 'createdBy', 
      title: 'Created By', 
      type: "employee"
    },
  ];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: string = '0';
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  
  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private workflowsService: WorkflowsService,
  ) { }

  ngOnInit(): void {
    this.getPublishedWorkflows();
  }

  getPublishedWorkflows(): void {
    this.isLoading = true;

    this.getRequest = this.workflowsService.getPublishedWorkflows()
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res,
                    total: res.length,
                };
            }
        );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip.toString();
      this.pageSize = event.take;
      this.getPublishedWorkflows();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToPublishWorkflow(publishedWorkflow?: PublishedWorkflow) {
    if(publishedWorkflow){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.WORKFLOWS}${routes.WORKFLOWS_PUBLISHED}${routes.EDITOR}/${publishedWorkflow?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.WORKFLOWS}${routes.WORKFLOWS_PUBLISHED}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }

}
