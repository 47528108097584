import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Integration } from '@app/modules/integrations/models/integration.model';

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss']
})
export class IntegrationSettingsComponent implements OnInit {
  @Input() integration: Integration
  integrationDetails: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.integrationDetails = this.fb.group({
      name: [this.integration ? this.integration.name : '', Validators.required],
      description: [this.integration ? this.integration.description : ''],
      startDate: [this.integration ? this.integration.startDate : ''],
      frequency: [this.integration ? this.integration.frequency : ''],

      clientId: [this.integration ? this.integration.connectionSettings.clientId : ''],
      clientSecret: [this.integration ? this.integration.connectionSettings.clientSecret : ''],
      apiurl: [this.integration ? this.integration.connectionSettings.apiurl : ''],
      authurl: [this.integration ? this.integration.connectionSettings.authurl : ''],
      certificate: [this.integration ? this.integration.connectionSettings.certificate : ''],
      privateKey: [this.integration ? this.integration.connectionSettings.privateKey : ''],
      workerId: [this.integration ? this.integration.connectionSettings.workerId : ''],
      associateOID: [this.integration ? this.integration.connectionSettings.associateOID : ''],

    });

    this.integrationDetails.valueChanges.subscribe(newValue => {
      this.integration.name = newValue.name;
      this.integration.description = newValue.description;
      this.integration.startDate = newValue.startDate;

      this.integration.connectionSettings.clientId = newValue.clientId;
      this.integration.connectionSettings.clientSecret = newValue.clientSecret;
      this.integration.connectionSettings.apiurl = newValue.apiurl;
      this.integration.connectionSettings.authurl = newValue.authurl;
      this.integration.connectionSettings.certificate = newValue.certificate;
      this.integration.connectionSettings.privateKey  = newValue.privateKey;
      this.integration.connectionSettings.workerId = newValue.workerId;
      this.integration.connectionSettings.associateOID = newValue.associateOID;
    });
  }

}
