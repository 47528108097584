import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hourly-rate-comparison',
  templateUrl: './hourly-rate-comparison.component.html',
  styleUrls: ['./hourly-rate-comparison.component.scss']
})
export class HourlyRateComparisonComponent implements OnInit {
  @Input() hourlyRateComparisonData: {
    title: string,
    description: string,
    currency: string,
    categories: string[],
    meanHourlyRate: [number, number],
    medianHourlyRate: [number, number],
  } = {
    title: 'Hourly Rate Comparison',
    description: 'This chart shows the mean and median hourly',
    currency: 'GBP',
    categories: ['Women', 'Men'],
    meanHourlyRate: [23.47, 25.25],
    medianHourlyRate: [21.37, 23.62],
  };
  
  constructor() { }

  ngOnInit(): void {
  }

}
