import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { db_tables } from '@app/consts';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { WorkLocationsService } from '@app/modules/work-locations/services/work-locations.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs';
import { SelectWorkLocationsDialogComponent } from './components/select-work-locations-dialog/select-work-locations-dialog.component';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-work-locations-form-field',
  templateUrl: './work-locations-form-field.component.html',
  styleUrls: ['./work-locations-form-field.component.scss']
})
export class WorkLocationsFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  public db_tables = db_tables;
  isLoading: boolean;
  gridDataResult: GridDataResult;
  pageSize: number = 10;
  skip: number = 0;

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }

  public columns: any[] = [
    {field: 'name', title: 'Name', dataType: "String"},
    { field: "streetAddress", title: "Street Address", tableId: "tfi_WlStreetAddress", dataType: "String"},
    { field: "city", title: "City", dataType: "String", tableId: "tfi_WlCity" },
    { field: "province", title: "Province", dataType: "String", tableId: "tfi_WlProvince" },
    { field: "zip", title: "Zip", dataType: "String", tableId: "tfi_WlZip" },
    { field: "region", subField: 'text', title: "Region", dataType: "String", tableId: "" },
    { field: "country", subField: 'name', title: "Country", dataType: "String", tableId: "tfi_WlCountry" },
    { field: "timezone", subField: 'text', title: "Timezone", dataType: "String", tableId: "" },
    { field: "stylingColours", subField: 'name', title: "styling Color", tableId: "" },
    { field: 'companyLogo', subField: 'id', title: 'Logo', type: 'logoImage', tableId: "" },
    { field: "startDate", title: "Start Date", type: "date", dataType: "Date", tableId: "" },
    { field: "endDate", title: "End Date", type: "date", dataType: "Date", tableId: "" },
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "streetAddress", sortValue: "streetAddress" },
    { field: "city", sortValue: "city" },
    { field: "province", sortValue: "province" },
    { field: "zip", sortValue: "zip" },
    { field: "region", sortValue: "region.text" },
    { field: "country", sortValue: "country.name" },
    { field: "timezone", sortValue: "timezone.text" },
    { field: "startDate", sortValue: "startDate" },
    { field: "endDate", sortValue: "endDate" },
    { field: "stylingColor", sortValue: "stylingColor" },
  ];

  public filterCategories: any[] = [].concat(this.columns)
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getRequest: any;
  selectWorkLocationsDialog: MatDialogRef<SelectWorkLocationsDialogComponent>;
  selectedWorkLocations: string[] = [];
  selectedRowsButtonSettings : {
    show: boolean
    text: boolean
    icon: string
  } = {
    show: false,
    text: null,
    icon: null
  }
  sortString: string;

  constructor(
    private workLocationsService: WorkLocationsService,
    private ngZone: NgZone,    
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getWorkLocations();
    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.selectedWorkLocations = this.parentGroup.controls[this.formElement.formControl].value
    }
  }

  getWorkLocations() {
    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined && this.parentGroup.controls[this.formElement.formControl].value.length > 0){
      this.isLoading = true;
      let filter;
  
      this.parentGroup.controls[this.formElement.formControl].value.forEach(element => {
        if (filter) {
          filter += ' OR ';
        } else {
          filter = '';
        }
        filter += `(id = "${element}")`;
      });
  
      this.getRequest = this.workLocationsService.getWorkLocations(this.skip, String(this.pageSize), filter)
      .pipe(
        finalize(
          () => this.isLoading = false
        )
      )
      .subscribe(
        pagedWorkLocations => {
          this.gridDataResult = {
            data: pagedWorkLocations.data,
            total: pagedWorkLocations.total,
          };
        }
      );
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getWorkLocations();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;;
    this.getWorkLocations();
  }

  deleteAllSelected(itemsToDelete: string[]) {
  }

  search(searchValue) {
    this.searchValue = searchValue;
    this.searchFilterString = `(Name like "${this.searchValue}")`;
    this.getRequest?.unsubscribe();
    this.getWorkLocations();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getWorkLocations();
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getWorkLocations();
  }

  showAddWorkLocationModal() {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    // dialogConfig.data = {
    //   nonSelectableEmployees: [ ...this.employeesGridDataResult.map( item => item.employee?.id )],
    //   employeeValueToSelect: 'employee'
    // };

    this.ngZone.runOutsideAngular(() => {
      this.selectWorkLocationsDialog = this.dialog.open(SelectWorkLocationsDialogComponent, dialogConfig);
    });

    const sub = this.selectWorkLocationsDialog.componentInstance.emitSelected.subscribe((event) => {
      this.selectedWorkLocations = event;
      const formArray = this.parentGroup.controls[this.formElement.formControl] as UntypedFormArray;
      formArray.clear();
      event.forEach(role => formArray.push(new UntypedFormControl(role)));
      // this.parentGroup.controls[this.formElement.formControl].setValue(event);
      this.selectWorkLocationsDialog.close();
      this.getWorkLocations();
    });
  }

}
