import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Survey } from '../models/surveys.model';

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  surveys: Survey[] = [
    {
      id: '121212',
      title: "Employee Satisfaction Survey",
      description: "Please take a few minutes to complete this survey. Your specific answers will be completely anonymous",
      type: "paged",
      questions: [
        {
          id: '1232134',
          name: "I am satisfied with our firm as an employee",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "I find my work interesting and challenging",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "My salary is appropriate based on my responsibilities and experience",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "I feel my contributions are adequately recognized and rewarded",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "I am treated with trust and respect",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "Management communicates all the information I need to perform my job effectively",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "I recieve an adequate level of training and on-the-job mentoring",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "My salary is appropriate based on my responsibilities and experience",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "I understand how my performance is evaluated",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "I am satisfied with the level of guidance provided by my supervisor",
          description: "",
          type: "radio",
          options: [
            "Strongly Disagree",
            "Disagree",
            "Neither",
            "Agree",
            "Strongly Agree",
          ]
        },
        {
          id: '1232134',
          name: "Any other input you would like to add",
          description: "",
          type: "textarea"
        }
      ]
    },
    {
      id: '34265665y',
      title: "Engagement Survey",
      description: "Please take a few minutes to complete this survey. Your specific answers will be completely anonymous",
      type: "paged",
      questions: [
        {
          id: '1232134',
          name: "How satisfied are you with your current role within the company?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "How satisfied are you with the compensation and benefits package that you receive?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "How satisfied are you with the opportunities for professional growth and development within the company?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "How satisfied are you with the company culture and work environment?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "How satisfied are you with the communication and feedback you receive from your manager?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "How satisfied are you with the level of support and resources provided to you by the company to perform your job effectively?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "How likely are you to recommend the company to a friend or family member as a good place to work?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "Do you feel that your work is recognized and appreciated by the company?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "Do you feel that the company values and respects diversity and inclusion in the workplace?",
          description: "",
          type: "radio",
          options: [
            "Very satisfied",
            "Satisfied",
            "Neither satisfied nor dissatisfied",
            "Dissatisfied",
            "Very dissatisfied",
          ]
        },
        {
          id: '1232134',
          name: "Any other input you would like to add",
          description: "",
          type: "textarea"
        }
      ]
    },
    {
      id: 'fer342refv',
      title: "Leaver Survey",
      description: "Please take a few minutes to complete this survey.",
      type: "paged",
      questions: [
        {
          id: '1232134',
          name: "Why did you make the decision to leave the group?​",
          description: "",
          type: "radio",
          options: [
            "Salary or Benefits ",
            "Growth or Development Opportunity",
            "Leadership",
            "Career Change",
            "Very dissatisfied",
            "Other",
          ]
        },
        {
          id: '1232134',
          name: "How did you discover your new role?",
          description: "",
          type: "radio",
          options: [
            "LinkedIn",
            "Job Board (Total Jobs, Indeed...)",
            "Referral",
            "Headhunted",
            "Recruitment Agency",
            "Other",
          ]
        },
        {
          id: '1232134',
          name: "How would you rate overall your experience with the group? On a scale of 1-4 (1 being poor and 4 being brilliant)",
          description: "",
          type: "radio",
          options: [
            "1",
            "2",
            "3",
            "4",
          ]
        },
        {
          id: '1232134',
          name: "Did your line manager live the values of our Culture Code?",
          description: "",
          type: "radio",
          options: [
            "Yes",
            "No",
            "I'm not sure",
          ]
        },
        {
          id: '1232134',
          name: "Would you recommend the group (or your brand) to a friend as a great place to work?",
          description: "",
          type: "radio",
          options: [
            "Yes",
            "No",
            "I'm not sure",
          ]
        },
        {
          id: '1232134',
          name: "Would you consider returning to work for Foxtons in the future?",
          description: "",
          type: "radio",
          options: [
            "Yes",
            "No",
          ]
        },
      ]
    }
  ]

  constructor() { }

  getSurveys(): Observable<Survey[]> {
    return of(this.surveys)
  }

  getSurvey(surveyId): Observable<Survey> {
    return of(this.surveys.find(obj => obj.id === surveyId))
  }
}
