<div class="container">
    <div class="left">
        <div class="title">
            <h6>Mobility Preferences</h6>
            <button mat-flat-button setColor="primaryColour" color="primary">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                Add
            </button>
        </div>
    
        <div>
            <mat-list>
                <div mat-subheader>Work Location</div>
                <mat-list-item *ngFor="let location of preferences; let i = index">
                  <mat-icon matListItemIcon class="material-icons-outlined clickable-icon" (click)="preferences.splice(i, 1)">close</mat-icon>
                  <div matListItemTitle>{{location}}</div>
                </mat-list-item>
                <!-- <mat-divider></mat-divider> -->
            </mat-list>
        </div>
    </div>

    <div class="right">
        <div class="title">
            <h6>Mobility Restrictions</h6>
            <button mat-flat-button setColor="primaryColour" color="primary">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                Add
            </button>
        </div>
    
        <div>
            <mat-list>
                <div mat-subheader>Work Location</div>
                <mat-list-item *ngFor="let location of restrictions; let i = index">
                  <mat-icon matListItemIcon class="material-icons-outlined clickable-icon" (click)="restrictions.splice(i, 1)">close</mat-icon>
                  <div matListItemTitle>{{location}}</div>
                </mat-list-item>
                <!-- <mat-divider></mat-divider> -->
            </mat-list>
        </div>
    </div>

    <mat-form-field appearance="fill">
        <mat-label>Mobility Notes</mat-label>

        <textarea rows="15" matInput [(ngModel)]="mobilityNotes"></textarea>
    </mat-form-field>

</div>