import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ChartOptions} from "@app/modules/reports/components/diversity-report/components/simple-vertical-bar-chart/simple-horizontal-bar-chart.component";
import { AxisLabelContentArgs, LegendLabelsContentArgs } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-copilot-pay-gap-report',
  templateUrl: './copilot-pay-gap-report.component.html',
  styleUrls: ['./copilot-pay-gap-report.component.scss']
})
export class CopilotPayGapReportComponent implements OnInit {
  public departmentPayGapData = [
    { department: "Engineering", male: 120, female: 100, disability: 110, ethnicMinority: 105 },
    { department: "Marketing", male: 90, female: 80, disability: 85, ethnicMinority: 88 },
    { department: "Sales", male: 110, female: 95, disability: 100, ethnicMinority: 98 },
    { department: "HR", male: 70, female: 75, disability: 72, ethnicMinority: 74 },
    { department: "Finance", male: 130, female: 110, disability: 120, ethnicMinority: 115 },
    { department: "Customer Support", male: 60, female: 65, disability: 62, ethnicMinority: 63 },
    { department: "IT", male: 100, female: 85, disability: 90, ethnicMinority: 92 },
    { department: "Operations", male: 95, female: 90, disability: 93, ethnicMinority: 91 },
    { department: "Legal", male: 105, female: 100, disability: 102, ethnicMinority: 101 },
    { department: "R&D", male: 115, female: 105, disability: 110, ethnicMinority: 108 },
  ];

  public labelContent(e: AxisLabelContentArgs): string {
    return `${e.dataItem.time.substring(0, 2)}h`;
  }

  public pieChartLabelContent(args: LegendLabelsContentArgs): string {
    return `${args.dataItem.category} : ${args.dataItem.value}%`;
}

  public valuePlotBands = [
    {
      from: 50,
      to: 100,
      color: "#cacaca",
      opacity: 0.5,
    },
    {
      from: 150,
      to: 200,
      color: "#cacaca",
      opacity: 0.5,
    },
    {
      from: 250,
      to: 300,
      color: "#cacaca",
      opacity: 0.5,
    },
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  backToReportGroups() {
    this.router.navigate([`Reports/Custom/Type/PayGap`]);
  }
}
