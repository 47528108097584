import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";


@Component({
    selector: 'app-employee-review-dialog',
    templateUrl: './employee-review-dialog.component.html',
    styleUrls: ['./employee-review-dialog.component.scss']
})
export class EmployeeReviewDialogComponent implements OnInit {

    form: UntypedFormGroup;
    formId: string;
    cultures: Culture[];
    loading: boolean;

    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;

    goals = [
        { id: 1, name: 'Increase 12 year average sales by 15%' },
        { id: 2, name: 'Reduce tenancy turnover by 10' },
        { id: 3, name: 'Reduce overhead cost by 25%' },
    ];
    ratingOptions = ['Did not meet expectations', 'Met some but not all expectations', 'Fully met expectations', 'Exceeded expectations', 'Significantly exceeded expectations'];


    constructor(
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private metadataFormService: MetadataFormService,
        private reportsService: CustomReportsService,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private metadataTableService: MetadataTableService,
        private dialogRef: MatDialogRef<EmployeeReviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({});
        for (const goal of this.goals) {
            this.form.addControl(`goal${goal.id}Rating`, this.fb.control('', Validators.required));
            this.form.addControl(`goal${goal.id}Comment`, this.fb.control(''));
        }

    }


    save() {
        const data = this.form.value;

        this.dialogRef.close(data);
    }

    close() {
        this.dialogRef.close();
    }
}
