import { Component, OnInit } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';

@Component({
  selector: 'app-benefits-compensation-widget',
  templateUrl: './benefits-compensation-widget.component.html',
  styleUrls: ['./benefits-compensation-widget.component.scss']
})
export class BenefitsCompensationWidgetComponent implements OnInit {
  items: {text: string, icon: string, url: string}[] = [] 
  user$: any;

  constructor(
    private oidcAuthService: OidcAuthService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
    
    this.items = [
      {
        text: 'Benefits',
        icon: 'favorite_border',
        url: `/TalentTrack/Edit/${this.user$.userId}/EmploymentCompensation/Benefits`
      },
      {
        text: 'Compensation',
        icon: 'paid',
        url: `/TalentTrack/Edit/${this.user$.userId}/EmploymentCompensation/Compensation`
      },
      // {
      //   text: 'Learning Activities',
      //   icon: 'school',
      //   url: ''
      // }
    ] 
  }

  ngOnInit(): void {
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

}