import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {EmployeeService} from '@app/core/services/employee.service';
import {DataBindingDirective, GridDataResult, PageChangeEvent, RowClassArgs} from '@progress/kendo-angular-grid';
import {finalize} from 'rxjs/operators';
import {SortDescriptor} from "@progress/kendo-data-query";
import {miscellaneous, routes} from '@app/consts';
import {EmployeeContactCardService} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service'

@Component({
  selector: 'app-workflow-publish-editor-select-employees',
  templateUrl: './workflow-publish-editor-select-employees.component.html',
  styleUrls: ['./workflow-publish-editor-select-employees.component.scss']
})
export class WorkflowPublishEditorSelectEmployeesComponent implements OnInit {
  @Input() selectedEmployees;

  @Output() emitSelectedEmployees: EventEmitter<string[]> = new EventEmitter<string[]>();

  public columns: any[] = [
    { field: "preferredName", title: "Preferred Name" },
    { field: "userId", title: "Employee ID" },
    { field: "customClientId", title: "Custom Client ID" },
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public mySelection: string[] = [];
  public routes: typeof routes = routes;
  public miscellaneous: typeof miscellaneous = miscellaneous;
  public sort: SortDescriptor[] = [];
  pageSize: number = 20;
  skip: string = '0';
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  isLoading: boolean;
  filterString: string;
  searchFilterString: string;
  searchValue: string;
  getEmployeesRequest: any;
  sortString: string = 'Lastname-asc~Firstname-asc';
  selectedItems: string[] = [];
  nonSelectableEmployees: string[] = [];

  public filterCategories: any[] = [
    {field: 'firstname', title: 'Firstname', dataType: 'String'},
    {field: 'lastname', title: 'Lastname', dataType: 'String'},
    {field: 'preferredName', title: 'Preferred Name', dataType: 'String'},
    {field: 'userId', title: 'User ID', dataType: 'String'},
    {field: 'customClientId', title: 'Custom Client ID', dataType: 'String'},
    {field: 'Position', title: 'Position', dataType: 'SpecialLookup', lookupCode: 'POSITION'},
    {field: 'Organization', title: 'Organisation', dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
    {field: 'WorkLocation', title: 'Work Location', dataType: 'SpecialLookup', lookupCode: 'WORK_LOCATION'},
    {field: 'email', title: 'Email', dataType: 'String'},
    {field: 'cellNumber', title: 'Cell Number', dataType: 'Number'},
    {field: 'PhoneNumber', title: 'Phone Number', dataType: 'String'},
    {field: 'SubStatus', title: 'SubStatus', dataType: 'String'},
    // {field: 'Status', title: 'Status'},
    {field: 'timezone', title: 'Timezone', dataType: 'String'},
  ].sort((a, b) => a.field.localeCompare(b.field));

  // sort function
  constructor(
    private employeeContactCardService: EmployeeContactCardService,
    private employeeService: EmployeeService,
  ){

  }

  ngOnInit(): void {
    this.selectionToggleCallback = this.selectionToggleCallback.bind(this);
    this.view = this.employeeService.getEmployeesKendo();
    this.getEmployees();

    this.selectedItems = this.selectedEmployees.map(e => e.id);
  }

  selectionToggleCallback(context:RowClassArgs){
    const isDisabled = this.nonSelectableEmployees.includes(context.dataItem.id);
    return { 'k-disabled' : isDisabled };
  }

  getEmployees(): void {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getEmployeesRequest = this.employeeContactCardService.getAllEmployeeContactCards(parseInt(this.skip), String(this.pageSize), filter, this.sortString)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
        this.gridData = res.data;
      }
    )
  }

  search() {
      let variants = this.searchValue.split(' ').filter(value => value);
      this.searchFilterString = '';
      variants.map(variant => {
          if (variant) {
              if (this.searchFilterString.length > 2) {
                  this.searchFilterString += ' AND ';
              }
              this.searchFilterString += `((FirstName like "${variant}") OR (LastName like "${variant}") OR (PreferredName like "${variant}") OR (customClientId like "${variant}") OR (userId like  "${variant}"))`;
          }
      });
      this.skip = '0';
      this.getEmployeesRequest.unsubscribe();
      this.getEmployees();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip.toString();
    this.pageSize = event.take
    this.getEmployees();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;

    if (sort[0].dir === undefined) {
      this.sortString = null
    }
    else {
      //use regex to get column field to sort with
      let field: any;

      // If it is the fullname column sort by firstname and lastname
      if(sort[0].field === 'fullName'){
        this.sortString = `Lastname-${sort[0].dir}~Firstname-${sort[0].dir}`;
      }
      else {
        //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
        (sort[0].field).match(/.+(?=\.)/) === null
        ? field = sort[0].field
        : field = (sort[0].field).match(/.+(?=\.)/)
        this.sortString = `${field}-${sort[0].dir}`;
      }

    }

    this.getEmployees();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = '0';
    this.getEmployees();
  }

  public selectionChange(e) {
    this.selectedItems.length > 0 
    ? this.emitSelectedEmployees.emit(this.selectedItems)
    : this.emitSelectedEmployees.emit(null)
  }

}
