import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Table } from '@app/modules/security-setup/models/table.model';
import { TableField } from '@app/modules/security-setup/models/table-field.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { CrudPermission, FieldPermissions, FieldPermissionsSubmit, SecurityRole, TablePermissions, TablePermissionsSubmit } from '@app/modules/security-setup/models/security-role.model';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { ModuleConfigurationService } from "@app/core/services/module-configuration/module-configuration.service";

@Component({
    selector: 'app-security-setup',
    templateUrl: './security-setup.component.html',
    styleUrls: [
        './security-setup.component.scss'],
})
export class SecuritySetupComponent implements OnInit {

    constructor(
        private snackbarService: SnackbarService,
        private securitySetupService: SecuritySetupService,
        private moduleConfigurationService: ModuleConfigurationService
    ) {}

    public tableGroups: Array<any>;
    public selectedTable: Table;
    public fieldList: Array<TableField>;
    public selectedField: TableField;
    public roleList: SecurityRole[] = [];
    public isLoading: boolean = false;
    public showHelp: boolean = false;

    private tablePermission: TablePermissions = null;
    public updatedTablePermission: TablePermissions = null;

    public fieldRolePermissions: FieldPermissions[];
    public tableRolePermissions: TablePermissions[];

    public eTablePermissionType = TablePermissionType;
    public eFieldPermissionType = FieldPermissionType;

    public selectedRole: SecurityRole;
    private allFieldsSelected = false;

    private fieldPermission: FieldPermissions = null;
    public updatedFieldPermission: FieldPermissions;


    modules = {
        "toil": ['tbl_Toil', 'tbl_ToilManualAdjustments', ],
        "performance": ['tbl_Performance', ],
        "timeOff": ['tbl_EmployeeTimeOffPolicies', 'tbl_TimeOffDetails', 'tbl_TimeOffManualAdjustments', 'tbl_TimeOffTypes',],
    };


    ngOnInit(): void {

        this.getTables();
    }

    getSecurityRoles() {
        this.securitySetupService.getSecurityRoles()
            .subscribe(
                pagedSecurityRoles => {
                    this.roleList = pagedSecurityRoles.data;
                }, err => console.log(err));
    }

    getRolePermissionsForTable() {
        this.securitySetupService.getSecurityRoles()
            .subscribe(
                pagedSecurityRoles => {
                    this.roleList = pagedSecurityRoles.data;
                }, err => console.log(err));
    }

    getRolePermissionsForField() {
        this.securitySetupService.getSecurityRoles()
            .subscribe(
                pagedSecurityRoles => {
                    this.roleList = pagedSecurityRoles.data;
                }, err => console.log(err));
    }


    getTables() {
        this.securitySetupService.getTables(0, '200').subscribe(
            pagedTableList => {
                let tableList = pagedTableList.data;
                let tableMap = [];
                tableList.map(table => {
                    if (table.parentTable != null) {
                        let groupIndex = tableMap.findIndex(t => t.groupId === table.parentTable.id);

                        if (groupIndex >= 0) {
                            tableMap[groupIndex].tables.push(table);
                        } else {
                            tableMap.push({groupName: table.parentTable.name, groupId: table.parentTable.id, tables: [table]});
                        }
                    } else {
                        tableMap.push({groupName: table.name, groupId: table.id, tables: [table]});
                    }
                });
                this.tableGroups = tableMap;

                // Remove all performance table groups
                this.tableGroups = this.tableGroups.filter( 
                    table => 
                    ![
                        "tbl_PerformanceReviewPlans", 
                        "tbl_PerformanceGoalPlanGoals", 
                        "tbl_PerformanceEmployeeGoalComments", 
                        "tbl_PerformanceEmployeeGoals", 
                        "tbl_PerformanceGoalPlans",
                        "tbl_PerformanceGoalTypes",
                        "tbl_ReviewPlanRatings"
                    ].includes(table.groupId)
                );

                this.filterTablesBasedOnModules();
            },
            err => {
                console.log(err);
            }
        );
    }

    filterTablesBasedOnModules() {
        for (const moduleCode in this.modules) {
            if (!this.moduleConfigurationService.hasModuleAccess(moduleCode)) {
                // If no access to the module, filter out the tables associated with it
                this.modules[moduleCode].forEach(tableId => {
                    this.tableGroups.forEach(group => {
                        group.tables = group.tables.filter(table => table.id !== tableId);
                    });
                });
            }
        }
    }

    getFields(tableId) {
        this.securitySetupService.getFields(tableId, 0, '200').subscribe(
            pagedFieldList => {
                this.fieldList = pagedFieldList.data;
                this.handleSelectFieldClick('All fields');
            }, err => console.log(err)
        );
    }

    getSecurityPermissionsForTable(tableId: string) {
        this.isLoading = true;
        this.fieldRolePermissions = null;
        this.securitySetupService.getSecurityPermissionsForTable(tableId)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(
                tableRolePermissions => {
                    tableRolePermissions.map(tableRolePermission => {
                        if (!tableRolePermission.create) {
                            tableRolePermission.create = new CrudPermission(false);
                        }
                        if (!tableRolePermission.update) {
                            tableRolePermission.update = new CrudPermission(false);
                        }

                        if (!tableRolePermission.read) {
                            tableRolePermission.read = new CrudPermission(false);
                        }

                        if (!tableRolePermission.delete) {
                            tableRolePermission.delete = new CrudPermission(false);
                        }

                        if (!tableRolePermission.viewHistory) {
                            tableRolePermission.viewHistory = new CrudPermission(false);
                        }

                        if (!tableRolePermission.viewFutureDated) {
                            tableRolePermission.viewFutureDated = new CrudPermission(false);
                        }

                        if (!tableRolePermission.updateWithConfirmation) {
                            tableRolePermission.updateWithConfirmation = new CrudPermission(false);
                        }

                        if (!tableRolePermission.approveUpdate) {
                            tableRolePermission.approveUpdate = new CrudPermission(false);
                        }
                    });

                    this.tableRolePermissions = tableRolePermissions;
                }, err => console.log(err)
            );
    }

    getSecurityPermissionsForField(fieldId: string) {
        this.isLoading = true;
        this.tableRolePermissions = null;
        this.securitySetupService.getSecurityPermissionsForField(fieldId)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(
                fieldRolePermissions => {
                    fieldRolePermissions.map(fieldRolePermission => {
                        if (!fieldRolePermission.read) {
                            fieldRolePermission.read = new CrudPermission(false);
                        }

                        if (!fieldRolePermission.update) {
                            fieldRolePermission.update = new CrudPermission(false);
                        }

                        if (!fieldRolePermission.deny) {
                            fieldRolePermission.deny = new CrudPermission(false);
                        }

                        if (!fieldRolePermission.updateWithConfirmation) {
                            fieldRolePermission.updateWithConfirmation = new CrudPermission(false);
                        }

                        if (!fieldRolePermission.approveUpdate) {
                            fieldRolePermission.approveUpdate = new CrudPermission(false);
                        }

                        if( fieldRolePermission.role?.id === 'rle_Manager' && fieldId === 'tfi_CompensationPayRate') {
                            fieldRolePermission.updateWithConfirmation = new CrudPermission(true);
                        }

                        if( fieldRolePermission.role?.id === 'rle_cSB1C9x44G3yrh' && fieldId === 'tfi_CompensationPayRate') {
                            fieldRolePermission.approveUpdate = new CrudPermission(true);
                        }

                        
                    });

                    this.fieldRolePermissions = fieldRolePermissions;

                }
            );
    }

    handleSelectTableClick(table) {
        this.resetSelection();
        this.selectedTable = table;
        this.selectedField = null;
        this.getFields(table.id);
    }

    handleSelectFieldClick(tableField) {
        this.resetSelection();
        this.selectedField = tableField;

        if (tableField == 'All fields') {
            this.getSecurityPermissionsForTable(this.selectedTable.id);
        } else {
            this.getSecurityPermissionsForField(this.selectedField.id);
        }
    }

    resetSelection() {
        this.updatedFieldPermission = null;
        this.fieldPermission = null;
        this.updatedTablePermission = null;
        this.tablePermission = null;
    }

    updateTablePermission(tablePermissionType: TablePermissionType, checked) {
        switch (tablePermissionType) {
            case TablePermissionType.CREATE:
                this.updatedTablePermission.create.enabled = checked;
                break;
            case TablePermissionType.READ:
                this.updatedTablePermission.read.enabled = checked;
                break;
            case TablePermissionType.DELETE:
                this.updatedTablePermission.delete.enabled = checked;
                break;
            case TablePermissionType.UPDATE:
                this.updatedTablePermission.update.enabled = checked;
                break;
            case TablePermissionType.VIEWHISTORY:
                this.updatedTablePermission.viewHistory.enabled = checked;
                break;
            case TablePermissionType.VIEWFUTUREDATED:
                this.updatedTablePermission.viewFutureDated.enabled = checked;
                break;
            case TablePermissionType.UPDATEWITHCONFIRMATION:
                this.updatedTablePermission.updateWithConfirmation.enabled = checked;
                break;    
            default:
        }
    }

    updateFieldPermission(fieldPermissionType: FieldPermissionType, checked) {
        switch (fieldPermissionType) {
            case FieldPermissionType.READ:
                this.updatedFieldPermission.read.enabled = checked;
                break;
            case FieldPermissionType.DENY:
                this.updatedFieldPermission.deny.enabled = checked;
                break;
            case FieldPermissionType.UPDATE:
                this.updatedFieldPermission.update.enabled = checked;
                break;
            default:
        }
    }

    save() {
        if (this.allFieldsSelected) {
            this.saveSecurityPermissionsForTable();
        } else {
            this.saveSecurityPermissionsForField();
        }
    }


    saveSecurityPermissionsForTable() {
        this.isLoading = true;
        let tablePermissionsSubmit: TablePermissionsSubmit = {
            create: this.updatedTablePermission.create,
            read: this.updatedTablePermission.read,
            update: this.updatedTablePermission.update,
            delete: this.updatedTablePermission.delete,
            viewHistory: this.updatedTablePermission.viewHistory,
            viewFutureDated: this.updatedTablePermission.viewFutureDated,
            updateWithConfirmation: this.updatedTablePermission.updateWithConfirmation,
            approveUpdate: this.updatedTablePermission.approveUpdate,
        };
        this.securitySetupService.updateSecurityPermissionsForTable(this.updatedTablePermission.role.id, this.selectedTable.id, tablePermissionsSubmit)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(
                securityPermissions => {
                    this.getSecurityPermissionsForTable(this.selectedTable.id);
                    this.updatedTablePermission = null;
                    this.snackbarService.openSnackBar('Role permissions saved successfully', 'clear', 'success');
                }, err => console.log(err)
            );
    }


    saveSecurityPermissionsForField() {
        this.isLoading = true;
        let fieldPermissionsSubmit: FieldPermissionsSubmit = {
            read: this.updatedFieldPermission.read,
            update: this.updatedFieldPermission.update,
            deny: this.updatedFieldPermission.deny,
            updateWithConfirmation: this.updatedFieldPermission.updateWithConfirmation,
            approveUpdate: this.updatedFieldPermission.approveUpdate,
        };
        this.securitySetupService.updateSecurityPermissionsForField(this.updatedFieldPermission.role.id, this.selectedField.id, fieldPermissionsSubmit)
            .pipe(finalize(() => {
                this.isLoading = false;
            }))
            .subscribe(
                securityPermissions => {
                    this.getSecurityPermissionsForField(this.selectedField.id);
                    this.updatedFieldPermission = null;
                    this.snackbarService.openSnackBar('Role permissions saved successfully', 'clear', 'success');
                }, err => console.log(err)
            );
    }


    editFieldPermission(item: FieldPermissions) {
        this.fieldPermission = item;
        this.updatedFieldPermission = _.cloneDeep(item);
    }

    cancelFieldChanges() {
        this.updatedFieldPermission = null;
        this.getSecurityPermissionsForField(this.selectedField.id);
    }


    editTablePermission(item: TablePermissions) {
        this.tablePermission = item;
        this.updatedTablePermission = _.cloneDeep(item);
    }

    cancelTableChanges() {
        this.updatedTablePermission = null;
        this.getSecurityPermissionsForTable(this.selectedTable.id);
    }

    isTheEditedField(dataItem: any) {
        if (dataItem.role.id == this.updatedFieldPermission?.role?.id) {
            return true;
        }
        return false;
    }

    isTheEditedTable(dataItem: any) {
        if (dataItem.role.id == this.updatedTablePermission?.role?.id) {
            return true;
        }
        return false;
    }

    selectDeselectAllForTable() {
        let select = this.shouldSelectForTable();
        this.updateTablePermission(TablePermissionType.CREATE, select);
        this.updateTablePermission(TablePermissionType.READ, select);
        this.updateTablePermission(TablePermissionType.UPDATE, select);
        this.updateTablePermission(TablePermissionType.DELETE, select);
        this.updateTablePermission(TablePermissionType.VIEWHISTORY, select);
        this.updateTablePermission(TablePermissionType.VIEWFUTUREDATED, select);
        this.updateTablePermission(TablePermissionType.UPDATEWITHCONFIRMATION, select);
        this.updateTablePermission(TablePermissionType.APPROVEUPDATE, select);
    }

    shouldSelectForTable() {
        return !(this.updatedTablePermission.create.enabled && this.updatedTablePermission.read.enabled && this.updatedTablePermission.update.enabled && this.updatedTablePermission.delete.enabled);
    }

    selectDeselectAllForField() {
        let select = this.shouldSelectForField();
        this.updateFieldPermission(FieldPermissionType.READ, select);
        this.updateFieldPermission(FieldPermissionType.UPDATE, select);
        this.updateFieldPermission(FieldPermissionType.DENY, select);
        this.updateFieldPermission(FieldPermissionType.UPDATEWITHCONFIRMATION, select);
        this.updateFieldPermission(FieldPermissionType.APPROVEUPDATE, select);
    }

    shouldSelectForField() {
        return !(this.updatedFieldPermission?.read?.enabled && this.updatedFieldPermission?.update?.enabled && this.updatedFieldPermission?.deny?.enabled);
    }
}

export enum TablePermissionType {
    READ,
    CREATE,
    UPDATE,
    DELETE,
    VIEWHISTORY,
    VIEWFUTUREDATED,
    UPDATEWITHCONFIRMATION,
    APPROVEUPDATE
}

export enum FieldPermissionType {
    READ,
    UPDATE,
    DENY,
    UPDATEWITHCONFIRMATION,
    APPROVEUPDATE
}
