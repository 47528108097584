import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-succession-talent-summary-details',
  templateUrl: './succession-talent-summary-details.component.html',
  styleUrls: ['./succession-talent-summary-details.component.scss']
})
export class SuccessionTalentSummaryDetailsComponent implements OnInit {
  @Input() employee;
  
  talentPool;
  potentialRating;
  criticalSkills;
  promotionReadiness;
  retentionRisk;
  performanceRating;
  leadershipMatrixRating;
  criticalRole;
  reasonForLeaving;

  talentPools = [
    'Group A - Global Executive',
    'Group B - Global Leader',
    'Group C - Business Specialist',
    'Group E - Early Career'
  ]

  potentialRatings = [
    'Promotable (1 level)',
    'Needs Development',
    'Critical Retention',
    'Too New to Rate',
  ]

  criticalSkillsOptions = [
    'Yes',
    'No'
  ]

  promotionReadinessOptions = [
    'Within 1 Year',
    'Within 2 Year',
    'Immediate (Ready Now)',
    'Too New to Rate'
  ]

  retentionRisks = [
    'High (likely to leave soon)',
    'Low (within 5 years)',
    'Medium (within 1 year)',
    'None (not likely to leave)',
    'Too New to Rate'
  ]

  performanceRatings = [
    'Below Satisfactory',
    'Exceptional',
    'Satisfactory',
    'Unsatisfactory',
  ]

  leadershipMatrixRatings = [
    '1A: Exceptional Talent',
    '1B: Strong Talent',
    '1C: Potential Talent',
    '2A: High Performer with Potential',
    '2B: Performer with Potential',
    '2C: Potential Performer',
    '3A: High Performer',
    '3B: Good Performer',
    '3C: Poor Performer',
  ]

  criticalRoles = [
    'Yes',
    'No'
  ]

  reasonForLeavings = [
    'Approaching retirement',
    'Compensation dissatisfaction',
    'Lack of cultural fit',
    'Lack of opportunities',
    'Not applicable',
    'Other reason',
    'Other reason - personal',
    'Other reason - work related',
    'Returning to school',
    'Seeking career change',
  ]

  constructor() { }

  ngOnInit(): void {
    this.talentPool = this.getRandomItem(this.talentPools);
    this.potentialRating = this.getRandomItem(this.potentialRatings);
    this.criticalSkills = this.getRandomItem(this.criticalSkillsOptions);
    this.promotionReadiness = this.getRandomItem(this.promotionReadinessOptions);
    this.retentionRisk = this.getRandomItem(this.retentionRisks);
    this.performanceRating = this.getRandomItem(this.performanceRatings);
    this.leadershipMatrixRating = this.getRandomItem(this.leadershipMatrixRatings);
    this.criticalRole = this.getRandomItem(this.criticalRoles);
    this.reasonForLeaving = this.getRandomItem(this.reasonForLeavings);
  }

  getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

}
