<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5>{{ 'SalaryReviewSummary' | translate: {Default: "Salary Review Summary"} }}</h5>
    </mat-card-title>
    <mat-card-content>
        <app-data-grid-comp
            [columns]="columns"
            [filterCategories]="columns"
            [bindingType]="bindingType"
            [gridDataResult]="gridDataResult"
            [isLoading]="isLoading"
            [pageSize]="pageSize"
            [skip]="skip"
            [category]=""
            [isLoading]="isLoading"
            searchable="true"
            hideActionButtons="true"
        ></app-data-grid-comp>
    </mat-card-content>
</mat-card>
