import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts/routes';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-birthdays',
  templateUrl: './birthdays.component.html',
  styleUrls: ['./birthdays.component.scss']
})
export class BirthdaysComponent implements OnInit {
  employees: any[] = [];
  loading: boolean = true;
  public routes: typeof routes = routes;
  birthdayTimePeriodSearchValue: string = "thisMonth";

  constructor(
    private employeeService: EmployeeService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getEmployees("1000", "1" )
    .subscribe(
      res => {
        this.employees = res?.body?.data;

        const today = parseInt(moment().format('D')); // Get current date and time
        const endOfMonth = parseInt(moment().endOf('month').format('D')); // Set to the end of the month
        const daysDiff = endOfMonth - today // Calculate the number of days between today and the end of the month

        this.employees[0].birthday = moment();
        
        for(let x = 1; x < 5; x++){
          const randomDays = Math.floor(Math.random() * (daysDiff + 1)); // Generate a random number of days
          const randomDate = moment().add(randomDays, 'days'); // Add the random number of days to the current date

          this.employees[x].birthday = randomDate;
        }

        this.loading = false;
      }
    )
  }

  isToday(date: string){
    return moment().dayOfYear() === moment(date).dayOfYear()
  }

  sendCongrats(){
    this.snackbarService.openSnackBar('Congrats Sent', 'clear', 'success');
  }

}
