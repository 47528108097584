import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SuccessionTrackService } from '@app/modules/succession-track/services/succession-track.service';
import { RowActionButtonInput, RowActionEventOutput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize, map } from 'rxjs';
// import { Integration } from '../../models/integration.model';

@Component({
  selector: 'app-succession-profiles',
  templateUrl: './succession-profiles.component.html',
  styleUrls: ['./succession-profiles.component.scss']
})
export class SuccessionProfilesComponent implements OnInit {

  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'name', 
      title: 'Name',
      type: "employee"
    }, 
    {
      field: 'configuration', 
      title: 'Configuration'
    }, 
    {
      field: 'workflowStep', 
      title: 'Workflow Step'
    }, 
    {
      field: 'talentPool', 
      title: 'Talent Pool', 
    },
    {
      field: 'potentialRating', 
      title: 'Potential Rating', 
    },
    {
      field: 'promotionReadiness', 
      title: 'Promotion Readiness',
    }, 
    {
      field: 'division', 
      title: 'Division',
    }, 
    {
      field: 'workLocation', 
      title: 'Work Location',
    }, 
    
  ];

  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'edit',
      name: this.translate.instant('Edit'),
      icon: 'edit'
    },
    {
      id: 'delete',
      name: this.translate.instant('Delete'),
      icon: 'delete'
    }
  ];

  talentPools = [
    'Group A - Global Executive',
    'Group B - Global Leader',
    'Group C - Business Specialist',
    'Group E - Early Career'
  ]

  potentialRatings = [
    'Promotable (1 level)',
    'Needs Development',
    'Critical Retention',
    'Too New to Rate',
  ]

  promotionReadiness = [
    'Within 1 Year',
    'Within 2 Year',
    'Immediate (Ready Now)',
    'Too New to Rate'
  ]

  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  
  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private successionTrackService: SuccessionTrackService,
    private employeeContactCardService: EmployeeContactCardService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees(): void {
    this.isLoading = true;

    this.getRequest = this.employeeContactCardService.getAllEmployeeContactCards(this.skip, String(this.pageSize), null, null, true)
    .pipe(
      finalize(()=>{ this.isLoading = false; }),
      map(
        res => {

          const modifiedItems = res.data.map(
            employee => {
              return {
                name: {
                  id: employee.id,
                  firstName: employee.firstname,
                  lastName: employee.lastname
                },
                configuration: 'Corporate Succession Plan',
                workflowStep: 'Draft',
                talentPool: this.getRandomItem(this.talentPools),
                potentialRating: this.getRandomItem(this.potentialRatings),
                promotionReadiness: this.getRandomItem(this.promotionReadiness),
                division: employee.organization?.name,
                workLocation: employee.workLocations[0]?.name,
              };
            }
          );

          res.data = modifiedItems;
          return res
        }
      )
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    )
  }

  getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getEmployees();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToSuccessionProfile(employee) {
    if(employee){
      this.router.navigate([`${routes.SUCCESSION_TRACK}${routes.SUCCESSION_PROFILE}/${employee?.name?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }

  rowActionButtonClicked($event){
    if($event.id === 'analytics') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ANALYTICS}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
    else if($event.id === 'error-log') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ERROR_LOG}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
  }

}
