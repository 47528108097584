import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-employee-absences',
  templateUrl: './all-employee-absences.component.html',
  styleUrls: ['./all-employee-absences.component.scss']
})
export class AllEmployeeAbsencesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
