<h5 mat-dialog-title>Add Variable Reviewer</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>
        <form class="form" *ngIf="form" [formGroup]="form">

            <mat-form-field appearance="fill">
                <mat-label>
                    Reviewer
                </mat-label>

                <mat-select disableOptionCentering formControlName="reviewer">
                    <ng-container *ngFor="let option of reviewers">
                        <mat-option [value]="option.display">{{option.display}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-slide-toggle formControlName="provideRatings">Provide Ratings</mat-slide-toggle>

            <mat-slide-toggle formControlName="provideComments">Provide Comments</mat-slide-toggle>

            <mat-slide-toggle formControlName="viewOtherReviewRatings">View Other Review Ratings</mat-slide-toggle>

            <mat-slide-toggle formControlName="employeeCanViewRatings">Employee Can View Ratings</mat-slide-toggle>

            <mat-slide-toggle formControlName="managerCanViewRatings">Manager Can View Ratings</mat-slide-toggle>

        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
