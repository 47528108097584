import { Injectable } from '@angular/core';
import { HugoDocument } from '../models/hugo-documents.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HugoDocumentsService {

  documents: HugoDocument[] = [
    {
      id: '3',
      isDirectory: true,
      parentDirectoryId: null,
      fileLength: 0,
      name: 'HR',
      comments: 'Human Resources Directory',
      contentType: 'directory',
      hugoAccess: true,
      isPrivate: false,
      security: {
        workLocations: ['wln_jBjVGPtUPL9Z8w'],
        securityRoles: ['rle_8Opib5G8G8HJku'],
        employees: ['usr_g63Ur6oEZ37Vgw']
      },
      version: {
        "createdOn": "2025-01-28T17:54:27.0243245+00:00",
        "effectiveDate": "2025-01-28T00:00:00",
        "modifiedList": [],
        "changeReason": null,
        "comments": null,
        "isDeleted": false,
        "createdBy": {
          "id": "usr_0000001",
          "firstName": "Hubbub",
          "lastName": "HR"
        }
      }
    },
    {
      id: '4',
      isDirectory: false,
      parentDirectoryId: '3',
      fileLength: 1024,
      name: 'Employee Handbook.pdf',
      comments: 'Company policies and procedures',
      contentType: 'application/pdf',
      hugoAccess: true,
      isPrivate: false,
      security: {
        workLocations: ['wln_jBjVGPtUPL9Z8w'],
        securityRoles: ['rle_8Opib5G8G8HJku'],
        employees: ['usr_g63Ur6oEZ37Vgw']
      },
      version: {
        "createdOn": "2025-01-28T17:54:27.0243245+00:00",
        "effectiveDate": "2025-01-28T00:00:00",
        "modifiedList": [],
        "changeReason": null,
        "comments": null,
        "isDeleted": false,
        "createdBy": {
          "id": "usr_0000001",
          "firstName": "Hubbub",
          "lastName": "HR"
        }
      }
    },
    {
      id: '5',
      isDirectory: true,
      parentDirectoryId: null,
      fileLength: 0,
      name: 'Finance',
      comments: 'Finance Department Directory',
      contentType: 'directory',
      hugoAccess: true,
      isPrivate: false,
      security: {
        workLocations: ['wln_jBjVGPtUPL9Z8w'],
        securityRoles: ['rle_8Opib5G8G8HJku'],
        employees: ['usr_g63Ur6oEZ37Vgw']
      },
      version: {
        "createdOn": "2025-01-28T17:54:27.0243245+00:00",
        "effectiveDate": "2025-01-28T00:00:00",
        "modifiedList": [],
        "changeReason": null,
        "comments": null,
        "isDeleted": false,
        "createdBy": {
          "id": "usr_0000001",
          "firstName": "Hubbub",
          "lastName": "HR"
        }
      }
    },
    {
      id: '6',
      isDirectory: false,
      parentDirectoryId: '5',
      fileLength: 2048,
      name: 'Annual Report 2024.pdf',
      comments: 'Financial report for the year 2024',
      contentType: 'application/pdf',
      hugoAccess: true,
      isPrivate: false,
      security: {
        workLocations: ['wln_jBjVGPtUPL9Z8w'],
        securityRoles: ['rle_8Opib5G8G8HJku'],
        employees: ['usr_g63Ur6oEZ37Vgw']
      },
      version: {
        "createdOn": "2025-01-28T17:54:27.0243245+00:00",
        "effectiveDate": "2025-01-28T00:00:00",
        "modifiedList": [],
        "changeReason": null,
        "comments": null,
        "isDeleted": false,
        "createdBy": {
          "id": "usr_0000001",
          "firstName": "Hubbub",
          "lastName": "HR"
        }
      }
    }

    // {
    //   id: '1',
    //   isDirectory: true,
    //   parentDirectoryId: null,
    //   fileLength: 0,
    //   name: 'Directory 1',
    //   comments: 'This is a directory',
    //   contentType: 'directory',
    //   hugoAccess: true,
    //   isPrivate: false,
    //   security: {
    //     workLocations: ['wln_jBjVGPtUPL9Z8w'],
    //     securityRoles: ['rle_8Opib5G8G8HJku'],
    //     employees: ['usr_g63Ur6oEZ37Vgw']
    //   },
    //   version: {
    //     "createdOn": "2025-01-28T17:54:27.0243245+00:00",
    //     "effectiveDate": "2025-01-28T00:00:00",
    //     "modifiedList": [
    //         "SexualOrientation",
    //         "Firstname"
    //     ],
    //     "changeReason": null,
    //     "comments": null,
    //     "isDeleted": false,
    //     "createdBy": {
    //         "id": "usr_0000001",
    //         "firstName": "Hubbub",
    //         "lastName": "HR"
    //     }
    //   }
    // },
    // {
    //   id: '2',
    //   isDirectory: false,
    //   parentDirectoryId: null,
    //   fileLength: 0,
    //   name: 'Document 1',
    //   comments: 'This is a document',
    //   contentType: 'application/pdf',
    //   hugoAccess: false,
    //   isPrivate: false,
    //   security: {
    //     workLocations: ['wln_jBjVGPtUPL9Z8w'],
    //     securityRoles: ['rle_8Opib5G8G8HJku'],
    //     employees: ['usr_g63Ur6oEZ37Vgw']
    //   },
    //   version: {
    //     "createdOn": "2025-01-28T17:54:27.0243245+00:00",
    //     "effectiveDate": "2025-01-28T00:00:00",
    //     "modifiedList": [
    //         "SexualOrientation",
    //         "Firstname"
    //     ],
    //     "changeReason": null,
    //     "comments": null,
    //     "isDeleted": false,
    //     "createdBy": {
    //         "id": "usr_0000001",
    //         "firstName": "Hubbub",
    //         "lastName": "HR"
    //     }
    //   }
    // }
  ];

  constructor() { 
    this.loadDocumentsFromLocalStorage();
  }

  saveDocumentsToLocalStorage(): void {
    localStorage.setItem('hugoDocuments', JSON.stringify(this.documents));
  }

  loadDocumentsFromLocalStorage(): void {
    const documents = localStorage.getItem('hugoDocuments');
    if (documents) {
      this.documents = JSON.parse(documents);
    }
  }

  updateDocument(updatedDocument: HugoDocument): void {
    const index = this.documents.findIndex(doc => doc.id === updatedDocument.id);
    if (index !== -1) {
      this.documents[index] = updatedDocument;
      this.saveDocumentsToLocalStorage();
    }
  }

  getDocuments(directoryId?: string): Observable<HugoDocument[]> {
    if (!directoryId) {
      return of(this.documents.filter(doc => doc.parentDirectoryId === null));
    }
    else {
      return of(this.documents.filter(doc => doc.parentDirectoryId === directoryId));
    }
  }

  getDirectories(): Observable<HugoDocument[]> {
    return of(this.documents.filter(doc => doc.isDirectory));
  }

  getDocument(id: string): Observable<HugoDocument> {
    return of(this.documents.find(doc => doc.id === id));
  }

  createDocument(newDocument: HugoDocument): void {
    this.documents.push(newDocument);
    this.saveDocumentsToLocalStorage();
  }

  clearDocumentsFromLocalStorage(): void {
    localStorage.removeItem('hugoDocuments');
    this.documents = [];
  }

  deleteDocument(id: string): void {
    const index = this.documents.findIndex(doc => doc.id === id);
    if (index !== -1) {
      this.documents.splice(index, 1);
      this.saveDocumentsToLocalStorage();
    }
  }
}
