import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Category, Field, Mapping } from '@app/modules/integrations/models/integration.model';
import { IntegrationsService } from '@app/modules/integrations/services/integrations.service';
import { TableField, TableFieldVerbose } from '@app/modules/security-setup/models/table-field.model';
import { Table } from '@app/modules/security-setup/models/table.model';
import { SecuritySetupService } from '@app/modules/security-setup/services/security-setup.service';
import { DemoIntegrationsService } from '@app/modules/site-settings-integrations/services/demo-integrations.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-integration-field-mappings',
  templateUrl: './integration-field-mappings.component.html',
  styleUrls: ['./integration-field-mappings.component.scss']
})
export class IntegrationFieldMappingsComponent implements OnInit {
  @Input() mappingId: string;

  public selectedTable: Table;
  public tableGroups: Array<any>;
  public fieldList: Array<TableField>;
  public selectedField: TableField;
  public selectedFieldVerbose: TableFieldVerbose;
  
  loadingTableField: boolean;
  loadingFields: boolean;
  loadingTables: boolean;
  loadingMapping: boolean;
  mapping: Mapping;
  tables: Table[];
  
  isLoading: boolean = true;
  formData: any;
  formId: string = 'frm_yegAe6EGDJAYP0';
  changeReasonFormId: string = 'frm_yPsGlEqUSjqwkp';
  getFormData: boolean = false;
  formValid: boolean = false;

  mappingProperties: FormGroup;
  yamlMapping: any;
  apiPaths: any[] = [];

  constructor(
    private snackbarService: SnackbarService,
    private securitySetupService: SecuritySetupService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    private overlayService: OverlayService,
    private integrationsService: IntegrationsService,
    private demoIntegrationsService: DemoIntegrationsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if(this.mappingId){
      // this.getMapping();
      this.getYamlMapping();
    }

    this.getTables();

    this.createForm();
  }

  createForm() {
    this.mappingProperties = this.fb.group({
      integrationFieldName: [''],
      fieldLocation: [''],
      responseLocation: [''],
    });
  }

  getYamlMapping() {
    this.loadingMapping = true;

    this.demoIntegrationsService.getYamlMapping(this.mappingId)
    .pipe(
      finalize(()=>{ 
        this.loadingMapping = false;
      })
    )
    .subscribe(
        res => {
          this.yamlMapping = res;
          this.parseOpenApiJson();
        }
    )
  }

  // Function to parse the OpenAPI JSON
  parseOpenApiJson() {
    const paths = this.yamlMapping.paths;
    this.apiPaths = Object.keys(paths).map(pathKey => {
      const pathObj = paths[pathKey];
      const methods = Object.keys(pathObj).map(method => ({
        method: method,
        ...pathObj[method]
      }));
      return {
        path: pathKey,
        methods: methods,
        selected: false, // checkbox selection binding
        summary: methods[0]?.summary || pathKey,
        description: methods[0]?.description || 'No description',
        fields: methods[0]?.fields
      };
    });
  }

  getMapping() {
    this.loadingMapping = true;

    this.integrationsService.getMapping(this.mappingId)
    .pipe(
      finalize(()=>{ 
        this.loadingMapping = false;
      })
    )
    .subscribe(
        res => {
          this.mapping = res;
        }
    )
  }

  getTables() {
    this.loadingTables = true;

    this.securitySetupService.getTables(0, '200')
    .pipe(
      finalize(()=>{ 
        this.loadingTables = false;
      })
    )
    .subscribe(
        pagedTableList => {
            this.tables = pagedTableList.data;

            let tableList = pagedTableList.data;
            let tableMap = [];
            tableList.map(table => {
                if (table.parentTable != null) {
                    let groupIndex = tableMap.findIndex(t => t.groupId === table.parentTable.id);

                    if (groupIndex >= 0) {
                        tableMap[groupIndex].tables.push(table);
                    } else {
                        tableMap.push({groupName: table.parentTable.name, groupId: table.parentTable.id, tables: [table]});
                    }
                } else {
                    tableMap.push({groupName: table.name, groupId: table.id, tables: [table]});
                }
            });
            this.tableGroups = tableMap;
        }
    );
  }

  createFormData() {
    this.formData = {
      id: this.selectedFieldVerbose ? this.selectedFieldVerbose.id : null,
      changeReason: '',
      changeReasonComments: '',
      name: this.selectedFieldVerbose ? this.selectedFieldVerbose.name : null,
      enabled: this.selectedFieldVerbose ? this.selectedFieldVerbose.enabled : null,
      requiredField: this.selectedFieldVerbose ? this.selectedFieldVerbose.requiredField : null,
      minimumValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.minimumValue : null,
      maximumValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.maximumValue : null,
      defaultValue: this.selectedFieldVerbose ? this.selectedFieldVerbose.defaultValue : null,
      format: this.selectedFieldVerbose ? this.selectedFieldVerbose.format : null,
      originalCreatedOn: this.selectedFieldVerbose ? this.selectedFieldVerbose.version?.createdOn : null,
      asOf: moment().format(),
    };
  }

  getSelectedField() {
    this.loadingTableField = true;

    this.securitySetupService.getField(this.selectedTable.id, this.selectedField.id)
    .pipe(
      finalize(()=>{ 
        this.loadingTableField = false;
      })
    )
    .subscribe(
      res => {
          this.selectedFieldVerbose = res;
          this.createFormData();
      }
    );
  }

  selectField(field: Field){
    this.selectedTable = this.tables.find( table => table.id === field.mappedTo.table);

    this.loadingFields = true;

    this.securitySetupService.getFields(this.selectedTable.id, 0, '200')
    .pipe(
      finalize(()=>{ 
        this.loadingFields = false;
      })
    )
    .subscribe(
      pagedFieldList => {
        this.fieldList = pagedFieldList.data;

        this.selectedField = this.fieldList.find( f => f.id === field.mappedTo.field)

        this.getSelectedField();
      }
    );
  }

  handleSelectFieldClick(tableField) {
    if(tableField) {
      this.selectedField = tableField;
      this.getSelectedField();
    }
    else {
      this.selectedFieldVerbose = null;
      this.selectedField = null;
    }  
  }

  formDataEmitted(formData) {
    // this.openChangeReasonDialog(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  categoryCheckboxChange($event: MatCheckboxChange, category: Category) {
    category.routes.forEach(
      route => {
        route.selected = $event.checked;
      }
    )
    // this.checkedChange.emit($event.checked);
  } 


}
