import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DirectReporteeSchedule } from '@app/modules/generate-schedules/models/generate-schedules.model';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { EmployeeAbsenceService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-absences/services/employee-absence.service';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';
import { DateChangeEvent, SchedulerEvent } from '@progress/kendo-angular-scheduler';
import * as moment from 'moment';
import { concat, defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';

const displayDate = moment().toDate();
const currentYear = new Date().getFullYear();

const randomInt = (min, max): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const parseAdjust = (eventDate: string): Date => {
  // Remove timezone from date string
  // const date = new Date( eventDate.substring(0, 19) );
  const date = new Date( eventDate );

  return date;
};

const parseAdjustDay = (eventDate: string): Date => {
  // Remove timezone from date string
  const date = new Date( eventDate.substring(0, 19) );

  return date;
};

const parseAdjustDayAddDays = (eventDate: string, numberOfDays: number): Date => {
  // Remove timezone from date string
  let date = new Date( eventDate.substring(0, 19) );

  // Add a day to the date
  date.setDate(date.getDate() + numberOfDays);

  return date;
};

const getTimeZone = (eventDate: string): string => {
  // Remove timezone from date string

  return eventDate.substring(20,25);
};

@Component({
  selector: 'app-team-members-schedule-widget',
  templateUrl: './team-members-schedule-widget.component.html',
  styleUrls: ['./team-members-schedule-widget.component.scss']
})
export class TeamMembersScheduleWidgetComponent implements OnInit {
  public selectedDate: Date = displayDate;
  from: string = moment(this.selectedDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
  to: string = moment(this.selectedDate).add(1, 'months').endOf('month').format('YYYY-MM-DD');
  isLoading: boolean = true;
  scheduleData: any[] = [];

  eventsToDisplayOptions = [
    {
      id: 'schedule',
      text: 'Schedule'
    },
    {
      id: 'timeOff',
      text: 'TimeOff'
    },
  ]

  timeOffStatus = [
    {
      id: 'approved',
      text: 'AbsenceRequestApproved'
    },
    {
      id: 'pending',
      text: 'AbsenceRequestPending'
    },
  ]

  scheduleFiltersForm;
  schedule: DirectReporteeSchedule[];
  filteredScheduleData: any[] = [];
  timeOffTypes: { id: string, name: string }[] = [];
  employees: any[];
  loadingSchedules: boolean;

  constructor(
    private fb: FormBuilder,
    public translate: TranslateService,
    private generateScheduleService: GenerateScheduleService,
    private employeeContactCardService: EmployeeContactCardService,
    private employeeAbsenceService: EmployeeAbsenceService
  ) { 
    this.scheduleFiltersForm = this.fb.group({
      employees: [['all']],
      eventsToDisplay: [['all']],
      timeOffType: [['all']],
      timeOffStatus: [['all']],
    });

    this.scheduleFiltersForm.valueChanges
    .subscribe(value => {
      this.filterScheduleData();
    });
  }

  ngOnInit(): void {
    this.getEmployees();
  }

  dateChange(event: DateChangeEvent){
    if(!this.isLoading){
      this.selectedDate = event.selectedDate;
      this.from = moment(this.selectedDate).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
      this.to = moment(this.selectedDate).add(1, 'months').endOf('month').format('YYYY-MM-DD');
      this.getDirectReporteeSchedules();
    }
  }

  getEmployees() {
    this.employeeContactCardService.getAllEmployeeContactCards(0, String(20), null, null, true)
    .pipe(
        finalize(()=>{ 
          this.isLoading = false; 
          this.getAllSchedules();
        }
      )
    )
    .subscribe(
      res => {
        this.employees = res.data;
      }
    )
  }

  getAllSchedules() {
    this.loadingSchedules = true;
    
    // const observables = this.employees.map(employee => defer(() => this.generateScheduleService.getEmployeeSchedule(employee.id, this.from, this.to)));

    const observables = this.employees.map(employee => defer(() => this.employeeAbsenceService.getEmployeeAbsences(employee.id, '100', 0, null, `(startDate >= "${this.from}") AND (endDate <= "${this.to}")`)));

    forkJoin(observables)
    .pipe(
      finalize(() => {
        this.loadingSchedules = false; 
        this.createAbsencesScheduleData();
      })
    )
    .subscribe(
      (res) => {
        res.forEach((fields, index) => {
            this.employees[index].absences = fields.data;
          }
        ) 
      }
    );
  }

  createAbsencesScheduleData() {
    this.filteredScheduleData = this.employees.flatMap(employee => 
      employee.absences.map(absence => ({
        id: randomInt(1, 10),
        start: parseAdjust(absence.startDate),
        startTimezone: getTimeZone(absence.startDate),
        end: parseAdjust(absence.endDate),
        endTimezone: getTimeZone(absence.endDate),
        isAllDay: false,
        title: `${employee.firstname} ${employee.lastname} - Absence`,
        description: 'Leave',
        recurrenceRule: null,
        recurrenceId: null,
        recurrenceException: null,
        roomId: null,
        ownerID: null,
        type: 'Leave',
      }))
    );
  }

  getDirectReporteeSchedules() {
    this.isLoading = true;

    this.generateScheduleService.getDirectReporteeSchedules(this.from, this.to)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.schedule = res;
            this.sortAlphabeticallyByEmployeeFirstName();
            this.getTimeOffTypes();
            this.filteredScheduleData = this.formatData(this.filterTimeOffTypes(this.filterOutEmployeesSchedule(this.schedule)));
        }
    );
  }

  sortAlphabeticallyByEmployeeFirstName() {
    this.schedule.sort( (a, b) => a.employee.firstName.localeCompare(b.employee.firstName) );
  }

  getTimeOffTypes() {
    this.schedule.forEach(
      item => {
        item.timeoffSchedule.forEach(
          absence => {
            if(!this.timeOffTypes.some(a => a.id === absence.type.id)){
              this.timeOffTypes.push(absence.type);
            }
          }
        )

        item.pendingRequests.forEach(
          absence => {
            if(!this.timeOffTypes.some(a => a.id === absence.type.id)){
              this.timeOffTypes.push(absence.type);
            }
          }
        )
      }
    )

    this.timeOffTypes.sort( (a, b) => a.name.localeCompare(b.name) );
  }

  filterScheduleData() {
    this.filteredScheduleData = this.formatData(
                                  this.filterTimeOffTypes(
                                    this.filterOutEmployeesSchedule(this.schedule)
                                  )
                                );
  }

  formatData(schedule) {
    let s: SchedulerEvent[] = [];
    let selectedSectionsToDisplay = this.scheduleFiltersForm.get('eventsToDisplay').value;
    let selectedTimeOffStatus = this.scheduleFiltersForm.get('timeOffStatus').value;

    if(selectedSectionsToDisplay === null){
      selectedSectionsToDisplay = [];
    }

    schedule.forEach(
      item => {

        if(selectedSectionsToDisplay.includes('schedule') || selectedSectionsToDisplay.includes('all')){
          s.push(
            item.workSchedule.map(
              dataItem => (
                <SchedulerEvent> {
                    id: randomInt(1, 10),
                    start: parseAdjust(dataItem.start),
                    startTimezone: getTimeZone(dataItem.start),
                    end: parseAdjust(dataItem.end),
                    endTimezone: getTimeZone(dataItem.end),
                    isAllDay: false,
                    title: `${item.employee.firstName} ${item.employee.lastName} - Work Day`,
                    description: 'Work',
                    recurrenceRule: null,
                    recurrenceId: null,
                    recurrenceException: null,
          
                    roomId: null,
                    ownerID: null,
                    type: 'Work', 
                }
              )
            )
          );
        }

        if(selectedSectionsToDisplay.includes('timeOff') || selectedSectionsToDisplay.includes('all')) {

          if(selectedTimeOffStatus.includes('approved') || selectedTimeOffStatus.includes('all')) {
            s.push(item.timeoffSchedule.map(dataItem => (
              <SchedulerEvent> {
                id: randomInt(1, 10),
                start: parseAdjustDay(dataItem.start),
                startTimezone: null,
                end: parseAdjustDayAddDays(dataItem.end, 1),
                endTimezone: null,
                isAllDay: false,
                title: `${item.employee.firstName} ${item.employee.lastName} - ${dataItem.type.name}`,
                description: 'Leave',
                recurrenceRule: null,
                recurrenceId: null,
                recurrenceException: null,
      
                roomId: null,
                ownerID: null,
                type: 'Leave',
              }
            )));
          }

          if(selectedTimeOffStatus.includes('pending') || selectedTimeOffStatus.includes('all')) {
            s.push(item.pendingRequests.map(dataItem => (
              <SchedulerEvent> {
                id: randomInt(1, 10),
                start: parseAdjustDay(dataItem.start),
                startTimezone: null,
                end: parseAdjustDayAddDays(dataItem.end, 1),
                endTimezone: null,
                isAllDay: false,
                title: `${item.employee.firstName} ${item.employee.lastName} - ${dataItem.type.name}`,
                description: 'PendingTimeOff',
                recurrenceRule: null,
                recurrenceId: null,
                recurrenceException: null,
      
                roomId: null,
                ownerID: null,
                type: 'PendingTimeOff',
              }
            )));
          }
          
        }

      }
    )

    return [...s.flat()]
  }

  filterOutEmployeesSchedule(schedule: DirectReporteeSchedule[]): any {
    let selectedEmployees = this.scheduleFiltersForm.get('employees').value;

    if(selectedEmployees === null){
      selectedEmployees = [];
    }

    if(selectedEmployees.includes('all')){
      return schedule;
    }
    else if(selectedEmployees.length === 0) {
      return [];
    }
    else {
      return schedule.filter(
        item => {
          return selectedEmployees.includes(item.employee) ? item.employee : null;
        }
      )
    }
  }

  filterTimeOffTypes(schedule: DirectReporteeSchedule[]): any {
    let scheduleClone: DirectReporteeSchedule[] = JSON.parse(JSON.stringify(schedule))
    let timeOffTypes = this.scheduleFiltersForm.get('timeOffType').value;

    if(timeOffTypes === null){
      timeOffTypes = [];
    }

    if(timeOffTypes.includes('all')){
      return schedule;
    }
    else if(timeOffTypes.length === 0) {
      scheduleClone.forEach(
        item => {

          item.timeoffSchedule = [];
          item.pendingRequests = [];

        }
      );

      return scheduleClone;
    }
    else {
      scheduleClone.forEach(
        item => {

          let filteredAbsences = item.timeoffSchedule.filter( a => timeOffTypes.includes(a.type.id) ? a : null );
          let filteredPendingAbsences = item.pendingRequests.filter( a => timeOffTypes.includes(a.type.id) ? a : null );

          item.timeoffSchedule = filteredAbsences;
          item.pendingRequests = filteredPendingAbsences;

        }
      );

      return scheduleClone;
    }
  }

}
