<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>{{ translate.instant('SiteSettings') }}</h1>
    </mat-toolbar>

    <div class="container">
        <ng-container *ngFor="let section of navList">
            <ng-container *appHasModuleAccess="section?.module">
                <mat-card
                    [routerLink]="section.link" 
                    class="mat-elevation-z0 primaryHoverColor" 
                    *appHasFeature="section.featureCode"
                >
                    <div class="icon-div">
                        <span class="material-icons-outlined">
                            {{ section.icon }}
                        </span>
                    </div>
    
                    <div class="text">
                        <mat-card-title class="header">
                            {{ translate.instant(section.text) }}
                        </mat-card-title>
                        <mat-card-content>
                            <!-- {{ translate.instant(section.detail) }} -->
                        </mat-card-content>
                    </div>
                </mat-card>
            </ng-container>
        </ng-container>
    </div>

</app-layout>