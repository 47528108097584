<app-layout>
    <div class="main-container" *ngIf="hasFeatureAccess">
        <div class="menu">
            <mat-list>
                <ng-container *ngFor="let item of navList">
                    <mat-list-item 
                        [routerLink]="item.link" 
                        routerLinkActive="primaryColor" 
                        class="primaryHoverColor" 
                        *appHasFeature="item.featureCode"
                        routerLinkActive="primaryColor" 
                        class="primaryHoverColor"  
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            },
                            {
                                color: 'primaryColour',
                                setIfClass: ['primaryColor'],
                                events: ['routerLinkActive']
                            },
                        ]"
                    >
                        <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>
                        <div>{{item.text}}</div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
        <div class="content">
            <div class="main-content">
                <ng-content></ng-content>
            </div>
        </div>
    </div>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-layout>
