<div class="container">
    <mat-form-field appearance="fill">
        <mat-label>What are your strengths?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="strengths"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Areas for development?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="development"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Aspirations: 1 Year?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="oneyear"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Aspirations: 2 Years?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="twoyear"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Aspirations: 5 Years?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="fiveyear"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>What do you enjoy?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="enjoy"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>What do you dislike?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="dislike"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Are you open to travel?</mat-label>

        <textarea rows="10" matInput [(ngModel)]="travel"></textarea>
    </mat-form-field>
</div>