import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-quick-links-widget',
  templateUrl: './quick-links-widget.component.html',
  styleUrls: ['./quick-links-widget.component.scss']
})
export class QuickLinksWidgetComponent implements OnInit {
  @Output() changeTabIndexEmitted = new EventEmitter<number>();

  items = [
    {
      icon: 'schedule',
      title: 'Time Off',
      description: `Request time off and view your balance`,
      href: '/home/employee-directory',
      tabindex: 2
    },
    {
      icon: 'adjust',
      title: 'Goals',
      description: `Set goals and track your progress`,
      href: '/home/employee-directory',
      tabindex: 4
    },
    {
      icon: 'loyalty',
      title: 'Benefits',
      description: `Access benefits information`,
      href: '/home/employee-directory'
    },
    {
      icon: 'work',
      title: 'Career',
      description: `Manage your career development`,
      href: '/home/employee-directory',
      tabindex: 5
    },
    {
      icon: 'description',
      title: 'Documents',
      description: `View and sign documents`,
      href: '/home/employee-directory'
    },
    {
      icon: 'monitoring',
      title: 'Company News',
      description: `Read the latest company news`,
      href: '/home/employee-directory'
    },
    {
      icon: 'diversity_1',
      title: 'Diversity & Inclusion',
      description: `Access diversity and inclusion resources`,
      href: '/home/employee-directory'
    },
    {
      icon: 'policy',
      title: 'Policies',
      description: `View company policies and procedures`,
      href: '/home/employee-directory'
    },
    {
      icon: 'calendar_month',
      title: 'Calendar',
      description: `View company events and holidays`,
      href: '/home/employee-directory'
    },
    {
      icon: 'groups',
      title: 'Directory',
      description: `Find contact information`,
      href: '/home/employee-directory'
    },
    
  ]
  loading: boolean = true;

  constructor() { 
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  ngOnInit(): void {
  }

  linkClicked(tabindex: number) {
    if(tabindex) {
      this.changeTabIndexEmitted.emit(tabindex)
    }
  }

}
