import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Workflow, WorkflowCategory, WorkflowTask } from '@app/modules/workflow-section/models/workflows.model';
import { WorkflowsService } from '@app/modules/workflow-section/services/workflows.service';
import { finalize } from 'rxjs';
import { WorkflowDialogComponent } from '../../../workflow-dialog/workflow-dialog.component';

@Component({
  selector: 'app-workflow-publish-editor-select-workflow',
  templateUrl: './workflow-publish-editor-select-workflow.component.html',
  styleUrls: ['./workflow-publish-editor-select-workflow.component.scss']
})
export class WorkflowPublishEditorSelectWorkflowComponent implements OnInit {
  @Input() selectedWorkflow: Workflow;
  
  @Output() selectedWorkflowChanged = new EventEmitter<Workflow>();

  isLoading: boolean;
  workflows: Workflow[] = [];
  selectedItems = [];

  constructor(
    private dialog: MatDialog,
    private workflowsService: WorkflowsService,
  ) { }

  ngOnInit(): void {
    if(this.selectedWorkflow) {
      this.selectedItems.push(this.selectedWorkflow.id);
    }
    this.getWorkflows();
  }

  getWorkflows(): void {
    this.isLoading = true;

    this.workflowsService.getWorkflows()
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
            this.workflows = res
        }
    );
  }

  public selectionChange(e) {
    this.selectedItems.length > 0 
    ? this.selectedWorkflowChanged.emit(
        this.workflows.find(workflow => workflow.id === this.selectedItems[0])
      )
    : this.selectedWorkflowChanged.emit(null)
  }

  openWorkflowDialog(workflow: Workflow) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;


    dialogConfig.data = {
      workflow: workflow,
      clearCompleted: true
    };

    let dialogRef = this.dialog.open(WorkflowDialogComponent, dialogConfig);
  }

}
