<mat-card class="position-editor">
    <mat-card-title class="header">
        <h4>{{ 'Job' | translate: {Default: "Job"} }}: <span>{{job?.name}}</span></h4>

        <div class="buttons-container" *ngIf="!readOnly">
            <mat-form-field appearance="fill" class="effective-date-form-field">
                <mat-label>
                    {{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}
                </mat-label>

                <input matInput [matDatepicker]="effectiveDatePicker" (dateChange)="effectiveDateChange($event)" [(ngModel)]="effectiveDate">
                <span fxLayout="row" matSuffix>
                    <mat-datepicker-toggle [for]="effectiveDatePicker"></mat-datepicker-toggle>
                </span>
                <mat-datepicker #effectiveDatePicker></mat-datepicker>
            </mat-form-field>

            <mat-icon 
                class="material-icons-outlined clickable-icon" 
                appPreventDoubleClick 
                (throttledClick)="openHistoryDialog()"
                setColor
                [setColorRules]="[
                    {
                        color: 'primaryColour',
                        events: ['hover']
                    }
                ]"
            >history</mat-icon>
            <button mat-raised-button routerLink="{{routes.SITE_SETTINGS}}{{routes.JOB_MANAGEMENT}}">{{ 'Close' | translate: {Default: "Close"} }}</button>
            <button mat-raised-button setColor="primaryColour" color="primary" [disabled]="form.valid === false" appPreventDoubleClick (throttledClick)="save()">{{ 'Save' | translate: {Default: "Save"} }}</button>
        </div>
    </mat-card-title>
    <mat-card-content>
        <div class="position-details-form">

            <ng-container *ngIf="isLoading; else loaded">
                <div class="loading-container">
                    <ngx-skeleton-loader
                        count="5"
                        [theme]="{
                            'height.px': 50
                        }"
                    ></ngx-skeleton-loader>
                </div>
            </ng-container>

            <ng-template #loaded>

                <form class="form" *ngIf="form" [formGroup]="form">

                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>{{ 'Job' | translate: {Default: "Job"} }}</h6>

                            <button mat-stroked-button color="primary" (click)="addNewLocalization()">
                                <mat-icon class="material-icons-outlined">add</mat-icon>
                                {{ 'BenefitGroupAddNameLocalization' | translate: {Default: "Add Name Localization"} }}
                            </button>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <ng-container formArrayName="name">
                                    <ng-container *ngFor="let localizationForm of localizations.controls; let i = index">
                                        <div class="form-row">
                                            <div [formGroup]="localizationForm">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Select Language
                                                    </mat-label>

                                                    <mat-select disableOptionCentering formControlName="culture">
                                                        <mat-option *ngFor="let culture of cultures" [value]="culture.id">{{culture.text}}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        Name
                                                    </mat-label>

                                                    <input matInput
                                                           formControlName="text">
                                                </mat-form-field>

                                                <mat-icon class="material-icons-outlined clickable-icon" (click)="deleteLocalization(i)" matTooltip="Delete">delete</mat-icon>

                                            </div>
                                        </div>

                                        <mat-divider></mat-divider>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Job Details</h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">

                                <mat-form-field appearance="fill">
                                    <mat-label> {{ 'JobType' | translate: {Default: "Job Type"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="jobType">
                                        <ng-container *ngFor="let option of jobTypes">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'JobStep' | translate: {Default: "Job Step"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="jobStep">
                                        <ng-container *ngFor="let option of jobSteps">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'JobGroup' | translate: {Default: "Job Group"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="jobGroup">
                                        <ng-container *ngFor="let option of jobGroups">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'JobFamily' | translate: {Default: "Job Family"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="jobFamily">
                                        <ng-container *ngFor="let option of jobFamilies">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'JobBand' | translate: {Default: "Job Band"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="jobBand">
                                        <ng-container *ngFor="let option of jobBands">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'JobCode' | translate: {Default: "Job Code"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="jobCode">
                                        <ng-container *ngFor="let option of jobCodes">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Market</h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'MarketPositionTitle' | translate: {Default: "Market Position Title"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="marketPositionTitle">
                                        <ng-container *ngFor="let option of marketPositionTitles">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'MarketView' | translate: {Default: "Market View"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="marketView">
                                        <ng-container *ngFor="let option of marketViews">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>{{ 'MarketPosition' | translate: {Default: "Market Position"} }}</mat-label>
                                    <mat-select disableOptionCentering formControlName="marketPosition">
                                        <ng-container *ngFor="let option of marketPositions">
                                            <mat-option [value]="option.value">{{option.text}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Salary Band</h6>
                        </div>
                        <div class="form-section-contents">
                            <div class="section-row">
                                <mat-form-field appearance="fill">
                                    <mat-label>Target Percentile</mat-label>
                                    <input matInput formControlName="targetPercentile">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Salary Min</mat-label>
                                    <input matInput formControlName="salaryMin">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Salary Midpoint</mat-label>
                                    <input matInput formControlName="salaryMidpoint">
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>Salary Maximum</mat-label>
                                    <input matInput formControlName="salaryMax">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>


                    <div class="form-section">
                        <div class="form-section-title">
                            <h6>Benchmarked Ranges</h6>
                        </div>
                        <div class="form-section-contents" formArrayName="benchmarkedRanges">
                            <div *ngFor="let range of benchmarkedRanges.controls; let i = index" [formGroupName]="i">
                                <div class="section-row">

                                    <mat-form-field appearance="fill">
                                        <mat-label>Market Survey Source</mat-label>
                                        <input matInput formControlName="marketSurveySource">
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>Market Position Title</mat-label>
                                        <input matInput formControlName="marketPositionTitle">
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>Market Minimum</mat-label>
                                        <input matInput formControlName="marketMin">
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>Market Maximum</mat-label>
                                        <input matInput formControlName="marketMax">
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>Benchmark Range Type</mat-label>
                                        <mat-select formControlName="benchmarkRangeType">
                                            <mat-option [value]="">-- None --</mat-option>
                                            <mat-option [value]="'primary'">Primary</mat-option>
                                            <mat-option [value]="'secondary'">Secondary</mat-option>
                                            <mat-option [value]="'other'">Other</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <mat-divider *ngIf="i < benchmarkedRanges.length - 1" class="my-2"></mat-divider>

                            </div>

                            <button mat-raised-button (click)="addBenchmarkedRange()">Add Benchmark Range</button>
                        </div>
                    </div>


                </form>


                <!--                <app-form-generator-->
                <!--                    [formId]="formId"-->
                <!--                    [formData]="formData"-->
                <!--                    [readOnly]="readOnly"-->
                <!--                    [emitFormDataTrigger]="getFormData"-->
                <!--                    (emitFormData)="formDataEmitted($event)"-->
                <!--                    (emitFormStatus)="formStatusUpdated($event)"-->
                <!--                ></app-form-generator>-->


                <!--                <div class="form-section">-->
                <!--                    <div class="form-section-title"><h6>Benchmarked Ranges</h6></div>-->

                <!--                    <kendo-grid-->
                <!--                        [kendoGridBinding]="gridData">-->

                <!--                        <kendo-grid-column field="priority" title="">-->
                <!--                            <ng-template kendoGridCellTemplate let-dataItem>-->
                <!--                                <mat-form-field appearance="fill">-->
                <!--                                    <mat-label>-->
                <!--                                        {{ 'Priority' | translate: {Default: "Priority"} }}-->
                <!--                                    </mat-label>-->

                <!--                                    <mat-select [(value)]="dataItem.priority" disableOptionCentering>-->
                <!--                                        <ng-container *ngFor="let option of priorityList">-->
                <!--                                            <mat-option [value]="option.value">{{option.text}}</mat-option>-->
                <!--                                        </ng-container>-->
                <!--                                    </mat-select>-->
                <!--                                </mat-form-field>-->

                <!--                            </ng-template>-->
                <!--                        </kendo-grid-column>-->
                <!--                        <kendo-grid-column field="marketSurveySource" title="Market Survey Source"></kendo-grid-column>-->
                <!--                        <kendo-grid-column field="marketPositionTitle" title="Market Position Title"></kendo-grid-column>-->
                <!--                        <kendo-grid-column field="marketMinimum" title="Market Minimum"></kendo-grid-column>-->
                <!--                        <kendo-grid-column field="marketMaximum" title="Market Maximum"></kendo-grid-column>-->
                <!--                    </kendo-grid>-->
                <!--                </div>-->

            </ng-template>
        </div>

        <div class="right">
            <div class="employees-list">
                <h6>{{ 'AssignedToJob' | translate: {Default: "Assigned To Job"} }}</h6>

                <ng-container *ngIf="!loadingJobPositions; else loadingAssignedIn">
                    <div class="slot-info" *ngIf="job">
                        <div class="number-of-slots">{{ 'NumberOfSlots' | translate: {Default: "Number of slots"} }}: <span>{{job.numberOfSlots}}</span></div>
                        <div class="slots-available">{{ 'SlotsAvailable' | translate: {Default: "Slots available"} }}: <span>{{job.slotsAvailable}}</span></div>
                        <div class="slots-filled">{{ 'SlotsFilled' | translate: {Default: "Slots filled"} }}: <span>{{jobPositions.length}}</span></div>
                    </div>

                    <kendo-grid
                        [data]="jobPositions"
                        [style.maxHeight.px]="350"
                        [resizable]="true"
                        [pageable]="false">

                        <kendo-grid-column field="" title="" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <p>
                                    {{dataItem.id}}
                                </p>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="" title="" [width]="80">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <mat-chip-list>
                                    <p>
                                        {{dataItem.name}}
                                    </p>
                                </mat-chip-list>
                            </ng-template>
                        </kendo-grid-column>

                        <ng-template kendoGridNoRecordsTemplate>
                            {{ 'ThereAreNoPositions' | translate: {Default: "There are no positions"} }}
                        </ng-template>
                    </kendo-grid>


                    <div class="position-documents-container" *ngIf="!readOnly">
                        <app-job-documents [positionId]="jobId"></app-job-documents>
                    </div>

                </ng-container>
            </div>

            <ng-template #loadingAssignedIn>
                <div class="loading-container">
                    <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30}"></ngx-skeleton-loader>
                </div>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>
