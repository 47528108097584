import { Component, OnInit } from '@angular/core';
import { features } from '@app/consts';
import { modules } from '@app/consts/modules';
import { CheckDeviceService } from '@app/core/services/check-device/check-device.service';
import { EmployeeService } from '@app/core/services/employee.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { map, Observable, switchMap } from 'rxjs';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  public modules: typeof modules = modules;
  public features: typeof features = features;
  refreshMyTimeOffRequest: boolean = false;
  refreshMyToilRequest: boolean = false;
  currentEmployeeDetails: CurrentUserDetails;
  isMobile: boolean;
  user$: any;
  selectedTabIndex: number = 0;
    
  constructor(
      private employeeService: EmployeeService,
      private oidcAuthService: OidcAuthService,
      private checkDeviceService: CheckDeviceService
  ) {
    this.checkDeviceService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  timeOffRequestCreated(event: boolean) {
    this.refreshMyTimeOffRequest = !this.refreshMyTimeOffRequest;
  }

  toilRequestCreated(event: boolean) {
    this.refreshMyToilRequest = !this.refreshMyToilRequest;
  }

  changeTab(section: string) {
    if(section === 'Performance') {
      this.selectedTabIndex = 4;
    }
    else if(section === 'TimeOff') {
      this.selectedTabIndex = 2;
    }
  }

}
