import { Component, Input, NgZone, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { db_tables } from '@app/consts';
import { FormElement } from '@app/modules/form-generator/edit-form-v3/models/form.model';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs';
import { SelectSecurityRoleDialogComponent } from './components/select-security-role-dialog/select-security-role-dialog.component';

@Component({
  selector: 'app-security-role-form-field',
  templateUrl: './security-role-form-field.component.html',
  styleUrls: ['./security-role-form-field.component.scss']
})
export class SecurityRoleFormFieldComponent implements OnInit {
  @Input() formElement: FormElement;
  @Input() parentGroup: UntypedFormGroup;
  @Input() readOnly: boolean;
  
  public db_tables = db_tables;
  isLoading: boolean;
  gridDataResult: GridDataResult;
  pageSize: number = 10;
  skip: number = 0;
  public columns: any[] = [
    {field: 'name', title: 'Name', dataType: "String"},
    {field: 'totalUsers', title: 'Total Users', dataType: "String"},
    {field: 'roleType', subField: 'name', title: 'Role Type', dataType: "String"},
    {field: 'description', title: 'Description', type: "longText", dataType: "String"},
  ]

  public filterCategories: any[] = [
      {field: 'name', title: 'Name', dataType: "String"},
      {field: 'description', title: 'Description', dataType: "String"}
  ];
  clearSelectedItems: boolean = false;
  searchValue: string;
  searchFilterString: string;
  getRequest: any;
  selectSecurityRolesDialog: MatDialogRef<SelectSecurityRoleDialogComponent>;
  selectedSecurityRoles: string[] = [];
  selectedRowsButtonSettings : {
    show: boolean
    text: boolean
    icon: string
  } = {
    show: false,
    text: null,
    icon: null
  }

  constructor(
    private securityRoleService: SecurityRoleService,
    private ngZone: NgZone,    
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getSecurityRoles();
    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined){
      this.selectedSecurityRoles = this.parentGroup.controls[this.formElement.formControl].value
    }
  }

  getSecurityRoles() {
    if(this.parentGroup.controls[this.formElement.formControl].value !== null && this.parentGroup.controls[this.formElement.formControl].value !== undefined && this.parentGroup.controls[this.formElement.formControl].value.length > 0){
      this.isLoading = true;
      let filter;
  
      this.parentGroup.controls[this.formElement.formControl].value.forEach(element => {
        if (filter) {
          filter += ' OR ';
        } else {
          filter = '';
        }
        filter += `(id = "${element}")`;
      });
  
      this.getRequest = this.securityRoleService.getSecurityRoles(this.skip, String(this.pageSize), filter)
      .pipe(
        finalize(
          () => this.isLoading = false
        )
      )
      .subscribe(
        pagedSecurityRoles => {
          this.gridDataResult = {
            data: pagedSecurityRoles.data,
            total: pagedSecurityRoles.total,
          };
        }
      );
    }
  }

  search(searchValue) {
    this.searchValue = searchValue;
    this.searchFilterString = `(Name like "${this.searchValue}")`;
    this.getRequest.unsubscribe();
    this.getSecurityRoles();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.getSecurityRoles();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;;
    this.getSecurityRoles();
  }

  deleteAllSelected(itemsToDelete: string[]) {
  }

  showAddSecurityRoleModal() {
    const dialogConfig = new MatDialogConfig();
    
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    // dialogConfig.data = {
    //   nonSelectableEmployees: [ ...this.employeesGridDataResult.map( item => item.employee?.id )],
    //   employeeValueToSelect: 'employee'
    // };

    this.ngZone.runOutsideAngular(() => {
      this.selectSecurityRolesDialog = this.dialog.open(SelectSecurityRoleDialogComponent, dialogConfig);
    });

    const sub = this.selectSecurityRolesDialog.componentInstance.emitSelected.subscribe((event) => {
      this.selectedSecurityRoles = event;
      const formArray = this.parentGroup.controls[this.formElement.formControl] as UntypedFormArray;
      formArray.clear();
      event.forEach(role => formArray.push(new UntypedFormControl(role)));
      // this.parentGroup.controls[this.formElement.formControl].setValue(event);
      this.selectSecurityRolesDialog.close();
      this.getSecurityRoles();
    });
  }

}
