<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
      <h4 class="title">{{ 'Homepage-Announcements' | translate: {Default: "Announcements"} }}</h4>
    </mat-card-title>

    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <div class="announcement" *ngFor="let item of items">
                <div class="image">
                    <img [src]="item.img">
                </div>

                <div class="title">{{item.title}}</div>
                <div class="description">{{item.description}}</div>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>
