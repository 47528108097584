<app-site-settings-menu>
    <mat-card class="main mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'IntegrationErrorLog' | translate: {Default: "Integration Error Log"} }}: {{integration?.name}}</h5>

            <button mat-flat-button color="primary" routerLink="{{routes.SITE_SETTINGS}}{{routes.INTEGRATIONS}}{{routes.INTEGRATIONS_LIST}}">
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">arrow_back</mat-icon> Return To Integrations
            </button>
        </mat-card-title>
        <mat-card-content>
            <div class="error-log">
                01 <br>
                03/22 08:51:01 INFO   :.main: *************** RSVP Agent started ***************<br>
                 02 <br>
                03/22 08:51:01 INFO   :...locate_configFile: Specified configuration file: /u/user10/rsvpd1.conf<br>
                03/22 08:51:01 INFO   :.main: Using log level 511<br>
                03/22 08:51:01 INFO   :..settcpimage: Get TCP images rc - EDC8112I Operation not supported on socket.<br>
                 03 <br>
                03/22 08:51:01 INFO   :..settcpimage: Associate with TCP/IP image name = TCPCS<br>
                03/22 08:51:02 INFO   :..reg_process: registering process with the system<br>
                03/22 08:51:02 INFO   :..reg_process: attempt OS/390 registration<br>
                03/22 08:51:02 INFO   :..reg_process: return from registration rc=0<br>
                 04 <br>
                03/22 08:51:06 TRACE  :...read_physical_netif: Home list entries returned = 7<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #0, interface VLINK1 has address 129.1.1.1, ifidx 0<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #1, interface TR1 has address 9.37.65.139, ifidx 1<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #2, interface LINK11 has address 9.67.100.1, ifidx 2<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #3, interface LINK12 has address 9.67.101.1, ifidx 3<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #4, interface CTCD0 has address 9.67.116.98, ifidx 4<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #5, interface CTCD2 has address 9.67.117.98, ifidx 5<br>
                03/22 08:51:06 INFO   :...read_physical_netif: index #6, interface LOOPBACK has address 127.0.0.1, ifidx 0<br>
                03/22 08:51:06 INFO   :....mailslot_create: creating mailslot for timer<br>
                03/22 08:51:06 INFO   :...mailbox_register: mailbox allocated for timer<br>
                 05 <br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP via UDP<br>
                 06 <br>
                03/22 08:51:06 WARNING:.....mailslot_create: setsockopt(MCAST_ADD) failed - EDC8116I Address not available.<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp-udp<br>
                03/22 08:51:06 TRACE  :..entity_initialize: interface 129.1.1.1, entity for rsvp allocated and initialized<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP via UDP<br>
                03/22 08:51:06 WARNING:.....mailslot_create: setsockopt(MCAST_ADD) failed - EDC8116I Address not available.<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp-udp<br>
                03/22 08:51:06 TRACE  :..entity_initialize: interface 9.37.65.139, entity for rsvp allocated and 
                initialized<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP via UDP<br>
                03/22 08:51:06 WARNING:.....mailslot_create: setsockopt(MCAST_ADD) failed - EDC8116I Address not available.<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp-udp<br>
                03/22 08:51:06 TRACE  :..entity_initialize: interface 9.67.100.1, entity for rsvp allocated and initialized<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP via UDP<br>
                03/22 08:51:06 WARNING:.....mailslot_create: setsockopt(MCAST_ADD) failed - EDC8116I Address not available.<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp-udp<br>
                03/22 08:51:06 TRACE  :..entity_initialize: interface 9.67.101.1, entity for rsvp allocated and initialized<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP via UDP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp-udp<br>
                03/22 08:51:06 TRACE  :..entity_initialize: interface 9.67.116.98, entity for rsvp allocated and 
                initialized<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp<br>
                03/22 08:51:06 INFO   :.....mailslot_create: creating mailslot for RSVP via UDP<br>
                03/22 08:51:06 INFO   :....mailbox_register: mailbox allocated for rsvp-udp<br>
                03/22 08:51:06 TRACE  :..entity_initialize: interface 9.67.117.98, entity for rsvp allocated and 
                initialized<br>
            </div>
        </mat-card-content>
    </mat-card>
</app-site-settings-menu>