<mat-tab-group animationDuration="0ms" setColor="primaryColour" class="succession-profile-tab-group">
    <mat-tab label="Talent Summary Details">
        <app-succession-talent-summary-details
            [employee]="employee"
        ></app-succession-talent-summary-details>
    </mat-tab>
    <mat-tab label="Mobility">
        <app-succession-mobility></app-succession-mobility>
    </mat-tab>
    <mat-tab label="Potential & Readiness">
        <app-succession-potential-and-readiness></app-succession-potential-and-readiness>
    </mat-tab>
    <mat-tab label="Successors">
        <app-succession-successors
            [successors]="successors"
        ></app-succession-successors>
    </mat-tab>
    <mat-tab label="Peer Recommendations">
        <app-succession-peer-recommendations></app-succession-peer-recommendations>
    </mat-tab>
    <mat-tab label="Succession Interview">
        <app-succession-interview></app-succession-interview>
    </mat-tab>
</mat-tab-group>