import { Component, OnInit } from '@angular/core';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';
import { FeatureService } from '@app/core/services/feature.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { features, routes } from '@app/consts';
import { modules } from '@app/consts/modules';

@Component({
  selector: 'app-site-settings-page',
  templateUrl: './site-settings-page.component.html',
  styleUrls: ['./site-settings-page.component.scss']
})
export class SiteSettingsPageComponent implements OnInit {
  public routes: typeof routes = routes;
  navList = [
      {
          text: 'OrganizationStructure',
          link: `${routes.SITE_SETTINGS}${routes.ORGANIZATION}`,
          icon: 'domain',
          featureCode: features.ORGANIZATION,
          detail: 'OrganizationStructure-Description'
      },
      {
          text: 'Positions',
          link: `${routes.SITE_SETTINGS}${routes.POSITIONS}`,
          icon: 'badge',
          featureCode: 'Positions',
          detail: 'Positions-Description'
      },
      {
          text: 'CostCenters',
          link: `${routes.SITE_SETTINGS}${routes.COST_CENTERS}`,
          icon: 'attach_money',
          featureCode: features.COST_CENTERS,
          detail: 'CostCenters-Description'
      },
      {
          text: 'Lists',
          link: `${routes.SITE_SETTINGS}${routes.LISTS}`,
          icon: 'edit_note',
          featureCode: 'Lists',
          detail: 'Lists-Description'
      },
      {
          text: 'tbl_WorkLocations',
          link: `${routes.SITE_SETTINGS}${routes.WORK_LOCATIONS}`,
          icon: 'home_work',
          featureCode: 'WorkLocations',
          detail: 'WorkLocations-Description'
      },
      {
          text: 'WorkRotations',
          link: `${routes.SITE_SETTINGS}${routes.WORK_ROTATIONS}`,
          icon: 'pending_actions',
          featureCode: 'WorkRotations',
          module: modules.TIME_OFF,
          detail: 'WorkRotations-Description'
      },
      {
          text: 'TimeOff',
          link: `${routes.SITE_SETTINGS}${routes.TIME_OFF}`,
          icon: 'alarm',
          featureCode: features.TIME_OFF,
          module: modules.TIME_OFF,
          detail: 'TimeOff-Description'
      },
      {
          text: 'TimeOffInLieu',
          link: `${routes.SITE_SETTINGS}${routes.TOIL}${routes.TOIL_POLICIES}`,
          icon: 'work_history',
          featureCode: features.TOIL_ADMINISTRATION,
          module: modules.TOIL,
          detail: 'TimeOffInLieu-Description',
          // svg: '/assets/icons/calendar-clock-icon.svg'
      },
      {
          text: 'StatutoryHolidays',
          link: `${routes.SITE_SETTINGS}${routes.STATUTORY_HOLIDAYS}`,
          icon: 'wb_sunny',
          featureCode: features.TIME_OFF,
          module: modules.TIME_OFF,
          detail: 'StatutoryHolidays-Description'
      },
      {
          text: 'Forms',
          link: `${routes.SITE_SETTINGS}${routes.FORMS}`,
          icon: 'library_books',
          featureCode: 'Forms',
          detail: 'Forms-Description'
      },
      {
          text: 'Configure',
          link: `${routes.SITE_SETTINGS}${routes.CONFIGURE}`,
          icon: 'settings',
          featureCode: features.SITE_SETTINGS,
          detail: 'Configure-Description'
      },
      {
          text: 'Currency',
          link: `${routes.SITE_SETTINGS}${routes.CURRENCY}`,
          icon: 'attach_money',
          featureCode: 'Currency',
          detail: 'Currency-Description'
      },
      {
          text: 'Translations',
          link: `${routes.SITE_SETTINGS}${routes.TRANSLATIONS}`,
          icon: 'translate',
          featureCode: features.GLOBALIZATION,
          detail: 'Translations-Description'
      },
      {
          text: 'CountryConfig',
          link: `${routes.SITE_SETTINGS}${routes.COUNTRY_CONFIG}`,
          icon: 'public',
          featureCode: features.COUNTRY_CONFIG,
          detail: 'CountryConfig-Description'
      },
      {
          text: 'SchedulesAdministration',
          link: `${routes.SITE_SETTINGS}${routes.GENERATE_SCHEDULE}`,
          icon: 'calendar_month',
          featureCode: features.SCHEDULES,
          module: modules.TIME_OFF,
          detail: 'SchedulesAdministration-Description'
      },
      {
          text: 'TableFieldEditor',
          link: `${routes.SITE_SETTINGS}${routes.TABLE_FIELD_EDITOR}`,
          icon: 'edit_note',
          featureCode: 'allow',
          detail: 'TableFieldEditor-Description'
      },
      {
          text: 'BusinessRuleAutomation',
          link: `${routes.SITE_SETTINGS}${routes.BUSINESS_RULE_AUTOMATION}`,
          icon: 'mark_email_unread',
          featureCode: features.BUSINESS_RULE_AUTOMATION,
          module: modules.BUSINESS_RULE_AUTOMATION,
          detail: 'BusinessRuleAutomation-Description'
      },
      {
          text: 'Styling',
          link: `${routes.SITE_SETTINGS}${routes.STYLING}`,
          icon: 'style',
          featureCode: features.SITE_STYLING,
          detail: 'Styling-Description'
      },
      {
        text: this.translate.instant('Documents'),
        link: `${routes.SITE_SETTINGS}${routes.DOCUMENTS}`,
        icon: 'description',
        featureCode: 'allow'
    },
      {
        text: this.translate.instant('Integrations'),
        link: `${routes.SITE_SETTINGS}${routes.INTEGRATIONS}/List`,
        icon: 'integration_instructions',
        featureCode: 'allow'
      },
      // {
      //     text: 'CompanyLogos'),
      //     link: `${routes.SITE_SETTINGS}${routes.STYLING}${routes.COMPANY_LOGOS}`,
      //     icon: 'photo',
      //     featureCode: features.SITE_STYLING
      // },
  ];

  constructor(
      public translate: TranslateService,
      public router: Router,
      private featureService: FeatureService,
      private moduleConfigurationService: ModuleConfigurationService,
  ) {
  }

  ngOnInit(): void {
      this.removeFeaturesWithoutModuleAccess();
  }

  // If the client doesnt have access to a module remove it form the list
  removeFeaturesWithoutModuleAccess() {
      this.navList = this.navList.filter(
          feature => {
              if(feature.module) {
                  return this.moduleConfigurationService.hasModuleAccess(feature.module);
              }
              else {
                  return true;
              }
          }
      )
  }

}
