import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WorkflowCategory, WorkflowTask } from '@app/modules/workflow-section/models/workflows.model';

@Component({
  selector: 'app-workflow-editor-category',
  templateUrl: './workflow-editor-category.component.html',
  styleUrls: ['./workflow-editor-category.component.scss']
})
export class WorkflowEditorCategoryComponent implements OnInit {
  @Input() category: WorkflowCategory;
  
  workflowCategoryDetails: FormGroup;
  tasks: WorkflowTask[];

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.workflowCategoryDetails = this.fb.group({
      name: [this.category ? this.category.name : '', Validators.required],
      description: [this.category ? this.category.description : ''],
    });

    this.tasks = this.category?.tasks;

    if(this.tasks === undefined || this.tasks.length < 1) {
      this.tasks = [];
      this.addNewTask();
    }
  }

  addNewTask() {
    let newTask: WorkflowTask = {
      id: null,
      name: null,
      description: null,
      createdBy: null,
      createdOn: null,
      completed: null,
      type: null
    }

    this.tasks.push(newTask);
  }

  removeTask(index: number) {
    this.tasks.splice(index, 1);
  }
}
