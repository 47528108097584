<mat-card class="chart">
    <mat-card-title class="chart__header">
        <h5 
            class="chart__header-title" 
            appLocalizationPopupDirective 
            [localizationCode]="'Disability-Pay-Gap'">
            {{ 'Disability-Pay-Gap' | translate: {Default: "Disability Pay Gap"} }}
        </h5>
  
        <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">
        <kendo-grid [data]="data">
            <kendo-grid-column field="hourlyRate" title="Hourly Rate" [width]="50"></kendo-grid-column>
            <kendo-grid-column field="mean" title="Mean (average)" [width]="50"> </kendo-grid-column>
            <kendo-grid-column field="meanMovement" title="movement" [width]="50"> </kendo-grid-column>
            <kendo-grid-column field="median" title="Median (mid-point)" [width]="50"> </kendo-grid-column>
            <kendo-grid-column field="medianMovement" title="movement" [width]="50"> </kendo-grid-column>
        </kendo-grid>
    </mat-card-content>
</mat-card>
