import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gender-pay-quartiles',
  templateUrl: './gender-pay-quartiles.component.html',
  styleUrls: ['./gender-pay-quartiles.component.scss']
})
export class GenderPayQuartilesComponent implements OnInit {

  headcountData = [
    {
      quarter: "Q1",
      employeeMale: "28.8%",
      employeeFemale: "71.2%",
      employeeDiff: "75",
      memberMale: "78.6%",
      memberFemale: "21.4%",
      memberDiff: "16",
      peopleMale: "26.2%",
      peopleFemale: "73.8%",
      peopleDiff: "98",
    },
    {
      quarter: "Q2",
      employeeMale: "20.8%",
      employeeFemale: "79.2%",
      employeeDiff: "104",
      memberMale: "58.6%",
      memberFemale: "41.4%",
      memberDiff: "5",
      peopleMale: "22.3%",
      peopleFemale: "77.7%",
      peopleDiff: "114",
    },
    {
      quarter: "Q3",
      employeeMale: "30.5%",
      employeeFemale: "69.5%",
      employeeDiff: "69",
      memberMale: "64.3%",
      memberFemale: "35.7%",
      memberDiff: "13",
      peopleMale: "38.8%",
      peopleFemale: "61.2%",
      peopleDiff: "46",
    },
    {
      quarter: "Q4",
      employeeMale: "47.5%",
      employeeFemale: "52.5%",
      employeeDiff: "9",
      memberMale: "72.7%",
      memberFemale: "27.3%",
      memberDiff: "8",
      peopleMale: "60.5%",
      peopleFemale: "39.5%",
      peopleDiff: "43",
    },
  ]

  hourlySalaryData = [
    {
      quarter: "Q1",
      employeeMale: "£9.55",
      employeeFemale: "£9.58",
      employeeDiff: "-0.4%",
      memberMale: "£52.72",
      memberFemale: "£53.20",
      memberDiff: "-0.9%",
      peopleMale: "£9.63",
      peopleFemale: "£9.83",
      peopleDiff: "-2.1%",
    },
    {
      quarter: "Q2",
      employeeMale: "£12.82",
      employeeFemale: "£12.41",
      employeeDiff: "3.2%",
      memberMale: "£65.42",
      memberFemale: "£64.75",
      memberDiff: "1.0%",
      peopleMale: "£13.75",
      peopleFemale: "£13.57",
      peopleDiff: "1.3%",
    },
    {
      quarter: "Q3",
      employeeMale: "£19.09",
      employeeFemale: "£18.40",
      employeeDiff: "3.6%",
      memberMale: "£96.78",
      memberFemale: "£95.33",
      memberDiff: "1.5%",
      peopleMale: "£23.12",
      peopleFemale: "£22.37",
      peopleDiff: "3.2%",
    },
    {
      quarter: "Q4",
      employeeMale: "£35.05",
      employeeFemale: "£35.10",
      employeeDiff: "8.4%",
      memberMale: "£176.79",
      memberFemale: "£174.11",
      memberDiff: "1.5%",
      peopleMale: "£75.98",
      peopleFemale: "£65.71",
      peopleDiff: "13.5%",
    },
    
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
