import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { PublishedWorkflow, Workflow } from '../../models/workflows.model';
import { WorkflowsService } from '../../services/workflows.service';
@Component({
  selector: 'app-workflow-publish-editor',
  templateUrl: './workflow-publish-editor.component.html',
  styleUrls: ['./workflow-publish-editor.component.scss']
})
export class WorkflowPublishEditorComponent implements OnInit {
  selectedIndex: number = 0;
  loadingWorkflow: boolean;
  publishedWorkflow: PublishedWorkflow;
  selectedWorkflow: Workflow;
  workflows: Workflow[];

  employees: EmployeeContactCard[];
  loadingEmployees: boolean;
  selectedParticipants: EmployeeContactCard[];
  selectedOwners: EmployeeContactCard[];

  workflowPublishDetails: FormGroup;

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,  
    private route: ActivatedRoute,
    private workflowsService: WorkflowsService,
    private employeeContactCardService: EmployeeContactCardService,
    private translate: TranslateService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.getWorkflows();
    this.getEmployees();

    if(this.getIdInURL()) {
      this.getPublishedWorkflow(this.getIdInURL());
    }
    else {
      this.createWorkflowPublishDetailsForm();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get('publishedWorkflowId')
    );

    return IdInURL;
  }

  createWorkflowPublishDetailsForm() {
    this.workflowPublishDetails = this.fb.group({
      name: [this.publishedWorkflow ? this.publishedWorkflow.name : '', Validators.required],
      description: [this.publishedWorkflow ? this.publishedWorkflow.description : ''],
      startDate: [this.publishedWorkflow ? this.publishedWorkflow.startDate : ''],
      endDate: [this.publishedWorkflow ? this.publishedWorkflow.endDate : ''],
      status: [this.publishedWorkflow ? this.publishedWorkflow.status?.id : ''],
    });
  }

  getPublishedWorkflow(publishedWorkflowId: string) {
    this.loadingWorkflow = true;

    this.workflowsService.getPublishedWorkflow(publishedWorkflowId)
    .pipe(
      finalize(() => {
        this.loadingWorkflow = false;
        this.createWorkflowPublishDetailsForm();
      })
    )
    .subscribe(
        res => {
          this.publishedWorkflow = res;
          this.selectedWorkflow = this.workflows.find(workflow => workflow.id === this.publishedWorkflow?.workflow.id)
        }
    );
  }

  getWorkflows(): void {
    this.workflowsService.getWorkflows()
    .subscribe(
        res => {
            this.workflows = res
        }
    );
  }

  getEmployees(): void {
    this.loadingEmployees = true;

    this.employeeContactCardService.getAllEmployeeContactCards(0, '1000')
    .pipe(
        finalize(()=>{ this.loadingEmployees = false; })
    )
    .subscribe(
      res => {
        this.employees = res.data;

        this.selectedParticipants = this.employees.filter(emp => this.publishedWorkflow?.participants.map(p => p.id).includes(emp.id));
        this.selectedOwners = this.employees.filter(emp => this.publishedWorkflow?.owners.map(p => p.id).includes(emp.id));

      }
    )
  }

  getEmployee(employeeId: string) {
    return this.employees.find(e => e.id === employeeId);
  }

  updatedSelectedWorkflow(workflow: Workflow) {
    this.selectedWorkflow = workflow;
  }

  updateSelectedParticipants($event) {
    this.selectedParticipants = this.employees.filter(emp => $event.includes(emp.id));
  }

  updateSelectedOwners($event) {
    this.selectedOwners = this.employees.filter(emp => $event.includes(emp.id));
  }

  navigationButtonClicked(forward: boolean){
    if(forward) {
      if(this.selectedIndex < 4) {
        this.selectedIndex = this.selectedIndex + 1;
      }
      else {
        this.save();
      }
    }
    else {
      if(this.selectedIndex !== 0) {
        this.selectedIndex = this.selectedIndex - 1;
      }
    }
  }

  selectedTabChange($event) {
    this.selectedIndex = $event.index;
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    }, 500)
  }

}
