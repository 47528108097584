import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-by-quartile',
  templateUrl: './pay-by-quartile.component.html',
  styleUrls: ['./pay-by-quartile.component.scss']
})
export class PayByQuartileComponent implements OnInit {
  @Input() payByQuartile: {
    title: string,
    description: string,
    data: {
      category: string,
      value: number
    }[]
  }[]

  constructor() { }

  ngOnInit(): void {
  }

}
