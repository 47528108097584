import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MetadataFormService } from '@app/core/services/metadata/metadata-form.service';
import { MetadataTableService } from '@app/core/services/metadata/metadata-table.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { CultureService } from '@app/core/services/system-language/culture.service';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Culture } from '@app/shared/models/system-language/culture.model';
import { CustomReportsService } from "@app/modules/custom-reports/services/reports.service";


@Component({
    selector: 'app-reviewer-dialog',
    templateUrl: './add-reviewer-dialog.component.html',
    styleUrls: ['./add-reviewer-dialog.component.scss']
})
export class AddReviewerDialogComponent implements OnInit {

    form: UntypedFormGroup;
    formId: string;
    cultures: Culture[];
    loading: boolean;

    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;

    public reviewers: { id: string, display: string }[] = [
        {id: "employee", display: 'Employee'},
        {id: "manager", display: 'Manager'},
        {id: "2_level_manager", display: '2nd Level Manager'},
        {id: "360_employee", display: '360 Employee'},
    ]

    constructor(
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private metadataFormService: MetadataFormService,
        private reportsService: CustomReportsService,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private metadataTableService: MetadataTableService,
        private dialogRef: MatDialogRef<AddReviewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {

    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            reviewer: ['', Validators.required],
            provideRatings: [false],
            provideComments: [false],
            viewOtherReviewRatings: [false],
            employeeCanViewRatings: [false],
            managerCanViewRatings: [false],
        });
    }


    save() {
        const data = this.form.value;

        this.dialogRef.close(data);
    }

    close() {
        this.dialogRef.close();
    }
}
