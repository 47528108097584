import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-radio-group',
  templateUrl: './survey-radio-group.component.html',
  styleUrls: ['./survey-radio-group.component.scss']
})
export class SurveyRadioGroupComponent implements OnInit {
  @Input() question;
  @Input() number;

  selectedRadio;

  constructor() { }

  ngOnInit(): void {
  }

}
