import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { FeatureService } from '@app/core/services/feature.service';
import { features } from '@app/consts';
import { EmailNotificationsService } from "@app/modules/site-settings/email-notifications/services/email-notifications.service";
import { EmailNotification } from "@app/modules/site-settings/email-notifications/models/email-notification.model";
import { EmailNotificationsDialogComponent } from "@app/modules/site-settings/email-notifications/components/email-notifications-dialog/email-notifications-dialog.component";

@Component({
    selector: 'app-settings-email-notifications',
    templateUrl: './email-notifications.component.html',
    styleUrls: ['./email-notifications.component.scss']
})
export class EmailNotificationsComponent extends SecurityProtectedBase implements OnInit {
    @Input() refreshView: boolean;
    public bindingType: String = 'array';
    public view: Observable<GridDataResult>;
    public gridData: any;
    public gridDataResult: GridDataResult;
    public selectedItems: any[] = [];
    pageSize: number = 20;
    skip: string = '0';
    isLoading: boolean;
    dialogRef: any;
    getRequest: any;

    public columns: any[] = [
        {field: "subject", title: "Subject"},
        {field: "recipients", title: "Recipients", type: "listCount"},
        {field: "active", title: "Active", type: "boolean", default: ["Yes", "No"]},
        {field: "reminderPriorDays", title: "Reminder Prior (Days)"},
        {field: "sendEveryDays", title: "Send Every (Days)"},
    ];
    public searchFilterString: string;
    public searchValue: string;
    public sort: SortDescriptor[] = [];
    public sortString: string = 'name-asc';
    clearSelectedItems: boolean = false;

    constructor(
        private dialog: MatDialog,
        featureService: FeatureService,
        private overlayService: OverlayService,
        private snackbarService: SnackbarService,
        private translate: TranslateService,
        private service: EmailNotificationsService
    ) {
        super(featureService, features.COST_CENTERS)
    }

    ngOnInit(): void {
        this.getData();
    }

    getData() {
        this.isLoading = true;

        this.getRequest = this.service.getEmailNotifications()
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridData = res;
                this.gridDataResult = {
                    data: res,
                    total: res.length,
                };
            }
        );
    }

    getEmailNotification(emailNotification: EmailNotification) {
        if (emailNotification === undefined) {
            this.openNotificationsDialog();
        } else {
            this.openNotificationsDialog(emailNotification);
        }
    }

    openNotificationsDialog(emailNotification?: EmailNotification) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            notification: emailNotification
        };

        this.dialogRef = this.dialog.open(EmailNotificationsDialogComponent, dialogConfig);

        this.dialogRef.afterClosed().subscribe(result => {
            let notification = result.notification;
            let update = this.gridData.find((item) => item.id === notification.id);
            if (update) {

                this.gridData.splice(this.gridData.indexOf(update), 1, notification);

            } else {
                this.gridData.push(notification);
            }
            this.gridDataResult = {
                data: this.gridData,
                total: this.gridData.length,
            }
        });
    }

    deleteNotifications(associationIds: string[]) {

        let updatedGridDataResult = this.gridDataResult.data.filter((item) => !associationIds.includes(item.id));
        this.gridDataResult.data = updatedGridDataResult;
        this.gridDataResult.total = updatedGridDataResult.length;
        this.gridData = updatedGridDataResult;
    }

    public pageChange(event: PageChangeEvent): void {

    }

    search(searchValue) {
        let filteredData = this.gridData.filter((item) => item.subject.includes(searchValue) || item.content.includes(searchValue));
        this.gridDataResult.data = filteredData;
        this.gridDataResult.total = filteredData.length;
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;

        if (sort[0].dir === undefined) {
            this.sortString = null;
        } else {
            //use regex to get column field to sort with
            let field: any;

            //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
            (sort[0].field).match(/.+(?=\.)/) === null
                ? field = sort[0].field
                : field = (sort[0].field).match(/.+(?=\.)/);
            this.sortString = `${field}-${sort[0].dir}`;
        }

        this.getData();
    }
}
