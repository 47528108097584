import { Component, OnInit, ViewChild } from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ApexLegend,
  ApexFill
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};

@Component({
  selector: 'app-benefits-details',
  templateUrl: './benefits-details.component.html',
  styleUrls: ['./benefits-details.component.scss']
})
export class BenefitsDetailsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  tableColumns = [
    {name: 'Retirement & Insurance', dataKey: 'retirementAndInsurance', isSortable: true, position: 'left'},
    {name: 'Company Contribution', dataKey: 'companyContribution', isSortable: true, position: 'left'},
    {name: 'Your Contribution', dataKey: 'yourContribution', isSortable: true, position: 'left'}
  ];

  tableData = [
    {
      retirementAndInsurance: '401k Retirement Plan',
      companyContribution: '$6,465',
      yourContribution: '$8,081',
    },
    {
      retirementAndInsurance: 'Medical',
      companyContribution: '$17,391',
      yourContribution: '$4,452',
    },
    {
      retirementAndInsurance: 'Dental',
      companyContribution: '$332',
      yourContribution: '$332',
    },
    {
      retirementAndInsurance: 'Vision',
      companyContribution: '$181',
      yourContribution: '$43',
    },
    {
      retirementAndInsurance: 'Total',
      companyContribution: '$24,369',
      yourContribution: '$12,908',
    },
    
  ];

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Company Contribution",
          data: [6465, 17391, 332, 181]
        },
        {
          name: "Your Contribution",
          data: [8081, 4452, 332, 43]
        }
      ],
      chart: {
        type: "bar",
        height: 600,
        stacked: true,
        stackType: "100%"
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      xaxis: {
        categories: [
          "401k",
          "Medical",
          "Dental",
          "Vision",
        ]
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50
      }
    };
  }

  ngOnInit(): void {
  }

}
