<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
      <h4 class="title">{{ 'Homepage-QuickLinks' | translate: {Default: "Quick Links"} }}</h4>
    </mat-card-title>

    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <div class="quick-link" *ngFor="let item of items" (click)="linkClicked(item.tabindex)">
                <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>
                <div class="title">{{item.title}}</div>
                <div class="description">{{item.description}}</div>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>
