import { CheckDeviceService } from '@app/core/services/check-device/check-device.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { GenerateScheduleService } from '@app/modules/generate-schedules/services/generate-schedule.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pending-time-off-dialog',
  templateUrl: './pending-time-off-dialog.component.html',
  styleUrls: ['./pending-time-off-dialog.component.scss']
})
export class PendingTimeOffDialogComponent implements OnInit {
  timeOffRequest: MyRequest;
  loadingWorkingHours: boolean;
  workingHours: number;
  isMobile: boolean;

  constructor(
    private workflowsAbsencesService: WorkflowsAbsencesService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PendingTimeOffDialogComponent>,
    private denialCommentsDialogRef: MatDialogRef<FormGeneratorDialogComponent>,
    private translate: TranslateService,
    private generateScheduleService: GenerateScheduleService,
    private checkDeviceService: CheckDeviceService,
    @Inject(MAT_DIALOG_DATA) data,
    ) {
      this.checkDeviceService.getIsMobile().subscribe((isMobile) => {
        this.isMobile = isMobile;
      });
    this.timeOffRequest = data.timeOffRequest;
      console.log(this.timeOffRequest);
  }

  ngOnInit(): void {
    if(this.timeOffRequest.hours === 0){
      this.getEmployeeWorkingHours();
    }
  }

  getEmployeeWorkingHours() {
    this.loadingWorkingHours = true;

    this.generateScheduleService.getEmployeeWorkingHours(this.timeOffRequest.requestedBy.id, this.timeOffRequest.from, this.timeOffRequest.to)
    .pipe(
      finalize(() => this.loadingWorkingHours = false)
    )
    .subscribe(
      (res) => {
        this.workingHours = res.workingHours;
        if(this.timeOffRequest.halfDay === true){
          this.workingHours = this.workingHours / 2;
        }
      }
    );
  }

  openDenialCommentsDialog() {
    let formData = {
      id: this.timeOffRequest.id,
      denialComments: null
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('DenyTimeOffRequest')}`,
      formId: 'frm_AIYzcgPsGYztA5',
      formData: formData
    };

    this.denialCommentsDialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.denialCommentsDialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.deny(event)
    });
  }

  deny(denialComments){
    this.overlayService.show();

    this.workflowsAbsencesService.denyRequest(this.timeOffRequest.id, denialComments)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('RequestDenied')}`, 'clear', 'success');
        this.dialogRef.close(true);
        this.denialCommentsDialogRef.close();
      }
    );
  }

  approve(){
    this.overlayService.show();

    this.workflowsAbsencesService.approveRequest(this.timeOffRequest.id)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('RequestApproved')}`, 'clear', 'success');
        this.dialogRef.close(true);
      }
    );
  }

  close(){
    this.dialogRef.close();
  }

    formatTime(time) {
        const [hours, minutes] = time.split(':');
        return `${hours}:${minutes}`;
    }
}
