<app-layout>

    <mat-toolbar class="page-header" role="heading">
        <h1>Gender and Ethnicity Pay Gap Report</h1>
    </mat-toolbar>


    <!--    <div class="page-container">-->
    <!--        <mat-toolbar class="page-header" role="heading">-->
    <!--          <h1>Gender and Ethnicity Pay Gap Report</h1>-->
    <!--        </mat-toolbar>-->

    <div class="content" fxLayout="column" fxLayoutAlign="start start">

        <div fxLayout="row" fxLayoutAlign="start start">
            <app-ethincity-promotions></app-ethincity-promotions>
            <app-ethnicity-bonus-pay></app-ethnicity-bonus-pay>
            <app-ethnicity-pay-gap-grid></app-ethnicity-pay-gap-grid>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
            <app-gender-pay-quartiles></app-gender-pay-quartiles>

            <div fxLayout="column">
            <app-pay-gap-yearly-difference></app-pay-gap-yearly-difference>
            <app-gender-promotions></app-gender-promotions>
            </div>

        </div>
<!--        <div fxLayout="row" fxLayoutAlign="start start">-->
<!--        </div>-->
        <div fxLayout="row" fxLayoutAlign="start start">
            <app-gender-pay-gap-grid></app-gender-pay-gap-grid>
            <app-gender-bonus-pay></app-gender-bonus-pay>

<!--            <app-headcount-by-gender></app-headcount-by-gender>-->
        </div>


        <!--            <div class="bottom">-->
        <!--              <div class="left">-->

        <!--              </div>-->

        <!--              <div class="right">-->

        <!--              </div>-->
        <!--            </div>-->


        <!-- <div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start stretch">
          <div fxLayout="row" fxLayoutAlign="start start">
            <app-talent-and-cem></app-talent-and-cem>
            <app-kpi-overview></app-kpi-overview>
          </div>
          <div>
          <app-headcount-by-country></app-headcount-by-country>
          <app-new-hires></app-new-hires>
        </div>
      </div> -->
        <!--        </div>-->
    </div>
</app-layout>
