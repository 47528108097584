import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethincity-promotions',
  templateUrl: './ethincity-promotions.component.html',
  styleUrls: ['./ethincity-promotions.component.scss']
})
export class EthincityPromotionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
