import { Component, OnInit } from '@angular/core';
import { miscellaneous } from '@app/consts';
import * as moment from 'moment';

@Component({
  selector: 'app-whats-new-widget',
  templateUrl: './whats-new-widget.component.html',
  styleUrls: ['./whats-new-widget.component.scss']
})
export class WhatsNewWidgetComponent implements OnInit {
  public miscellaneous: typeof miscellaneous = miscellaneous;

  items = [
    {
      text: 'New feature: Employee Directory',
      img: 'assets/images/whats-new/mountain-sunset.jpg',
    },
    {
      text: `${moment().year()} Compensation Planning`,
      img: 'assets/images/whats-new/compensation-planning.jpeg',
    },
    {
      text: 'Employee survey results',
      img: 'assets/images/whats-new/survey.jpeg',
    }
  ]
  loading: boolean = true;

  constructor() { 
    setTimeout(() => {
      this.loading = false
    }, 500)
  }

  ngOnInit(): void {
  }

}
