<mat-card>
    <mat-card-title class="header">
        <h5 appLocalizationPopupDirective localizationCode="MyBenefits">{{ 'MyBenefits' | translate: {Default: "My Benefits"} }}</h5>
    </mat-card-title>
    <mat-card-content>
        <div class="left">
            <div class="top">
                <div class="effective-date">Supporting You and Your Family </div>
                <div class="blurb">
                    <!-- <div class="blub-header">Compensation: Your Pay Today</div> -->
                    <div class="blurb-details">At your company name we are here to support your health and provide financial stability for you and your family.  Our insurance policies include health, dental and vision care, as well as other benefits.  We also invest in securing your future with an automatic 4% contribution to your retirement plan.</div>
                </div>
            </div>
            <app-table
                [tableData]="tableData"
                [tableColumns]="tableColumns"
                [isFilterable]="false"
                [isPageable]="false"
            ></app-table>
        </div>

        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [plotOptions]="chartOptions.plotOptions"
              [responsive]="chartOptions.responsive"
              [xaxis]="chartOptions.xaxis"
              [legend]="chartOptions.legend"
              [fill]="chartOptions.fill"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>