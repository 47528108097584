import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { DocumentUpdate } from '../../models/document.model';
import { SiteDocumentsService } from '../../services/site-documents.service';

@Component({
  selector: 'app-edit-site-document-dialog',
  templateUrl: './edit-site-document-dialog.component.html',
  styleUrls: ['./edit-site-document-dialog.component.scss']
})
export class EditSiteDocumentDialogComponent implements OnInit {
  document: Directory;
  newName: string;
  employeeId: string;

  constructor(
    private snackbarService: SnackbarService,
    private siteDocumentsService: SiteDocumentsService,
    private dialogRef: MatDialogRef<EditSiteDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.employeeId = data.employeeId;
      this.document = data.document;
      this.newName = this.document.name;
  }

  ngOnInit(): void {
  }

  save() {
    const updateDetails: DocumentUpdate = {
      parent: this.document.parent,
      comments: '',
      name: this.newName
    }

    this.siteDocumentsService.updateDocument(this.employeeId, this.document.id, updateDetails)
    .subscribe(
      (res) => {
        this.dialogRef.close(true);
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

}
