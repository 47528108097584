<form class="integration-details-form" [formGroup]="integrationDetails">
    <h5>{{ 'IntegrationDetails' | translate: {Default: "Integration Details"} }}</h5>

    <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>

        <input matInput type="text" formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Description</mat-label>

        <textarea matInput formControlName="description"></textarea>
    </mat-form-field>

    <mat-form-field  appearance="fill">
        <mat-label>Start Date</mat-label>

        <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle  [for]="pickerStartDate"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #pickerStartDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Frequency</mat-label>

        <mat-select disableOptionCentering formControlName="frequency">
            <mat-option value="Daily">Daily</mat-option>
            <mat-option value="Weekly">Weekly</mat-option>
            <mat-option value="Bi Monthly">Bi Monthly</mat-option>
            <mat-option value="Monthly">Monthly</mat-option>
        </mat-select>
    </mat-form-field>

    <h5>{{ 'ConnectionDetails' | translate: {Default: "Connection Details"} }}</h5>

    <mat-form-field appearance="fill">
        <mat-label>Client ID</mat-label>

        <input matInput formControlName="clientId">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Client Secret</mat-label>

        <input matInput formControlName="clientSecret">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>API URL</mat-label>

        <input matInput formControlName="apiurl">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Auth URL</mat-label>

        <input matInput formControlName="authurl">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Certificate</mat-label>

        <textarea rows="15" matInput formControlName="certificate"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Private Key</mat-label>

        <textarea rows="15" matInput formControlName="privateKey"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Worker Id</mat-label>

        <input matInput formControlName="workerId">
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Associate OID</mat-label>

        <input matInput formControlName="associateOID">
    </mat-form-field>
    <!-- <mat-form-field  appearance="fill">
        <mat-label>End Date</mat-label>

        <input matInput [matDatepicker]="pickerEndDate" formControlName="endDate">
        <span fxLayout="row" matSuffix>
            <mat-datepicker-toggle  [for]="pickerEndDate"></mat-datepicker-toggle>
        </span>
        <mat-datepicker #pickerEndDate></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Status</mat-label>

        <mat-select disableOptionCentering formControlName="status">
            <mat-option value="complete">Complete</mat-option>
            <mat-option value="inProgress">In Progress</mat-option>
            <mat-option value="cancelled">Cancelled</mat-option>
        </mat-select>
    </mat-form-field> -->
</form>