import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Form } from '@app/modules/form-generator/models/form.model';

@Component({
  selector: 'app-form-selection-dialog',
  templateUrl: './form-selection-dialog.component.html',
  styleUrls: ['./form-selection-dialog.component.scss']
})
export class FormSelectionDialogComponent implements OnInit {
  forms: Form[];
  selectedItems = [];

  constructor(
    private dialogRef: MatDialogRef<FormSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.forms = data.forms;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  addForm() {
    this.dialogRef.close(this.selectedItems[0]);
  }

}
