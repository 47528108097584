import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';
import { finalize } from 'rxjs';
import { Integration } from '../../models/integration.model';
import { IntegrationsService } from '../../services/integrations.service';

@Component({
  selector: 'app-integration-error-log',
  templateUrl: './integration-error-log.component.html',
  styleUrls: ['./integration-error-log.component.scss']
})
export class IntegrationErrorLogComponent implements OnInit {
  public routes: typeof routes = routes;
  loadingIntegration: boolean = true;
  integration: Integration;

  constructor(
    private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.getIntegration('12213123');

  }

  getIntegration(id: string) {
    this.loadingIntegration = true;

    this.integrationsService.getIntegration(id)
    .pipe(
      finalize(() => {
        this.loadingIntegration = false;
      })
    )
    .subscribe(
        res => {
          this.integration = res;
        }
    );
  }

}
