<app-mass-import-export>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>Template Upload</h5>
        </mat-card-title>
        <mat-card-content>
            
            <div class="file-upload">
                <div>
                    <button mat-stroked-button color="primary" (click)="fileInput.click()">
                        Choose a file
                    </button>
                    <input
                    #fileInput
                    type="file"
                    id="fileInput"
                        (change)="selectFile($event)"
                        name="fileInput"
                    />
                </div>
                <div *ngIf="currentFile">
                    <div class="current-file">
                        <mat-toolbar class="progress-bar">
                            <mat-progress-bar setColor="primaryColour" color="accent" [value]="uploadProgress"></mat-progress-bar>
                            <span class="progress">{{ uploadProgress }}%</span>
                        </mat-toolbar>
                        <div class="current-file-details">
                            <div class="current-file-name">{{currentFile.name}}</div>
                            <div class="current-file-size">{{ currentFile.size | filesize }}</div>
                        </div>
                    </div>
                </div>
                <button mat-raised-button class="submit-button" setColor="primaryColour" color="primary" [disabled]="!currentFile" (click)="uploadFakeFile()">Upload</button>
            </div>

            <ng-container *ngIf="showTickAnimation">
                <div class="submitted-container">
                    <div class="animation-container">
                        <div class="trigger" [ngClass]="{'drawn':showTickAnimation === true}"></div>
                        <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="100" x="0px" y="0px"
                            viewBox="0 0 37 50" xml:space="preserve">
                            <path class="circ path" style="fill:none;stroke:#00b189;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" d="
                                M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                                />
                            <polyline class="tick path" style="fill:none;stroke:#00b189;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" points="
                                11.6,20 15.9,24.2 26.4,13.8 "/>
                        </svg>
                    </div>
            
                    <div class="text" [ngClass]="{'visible':showTickAnimation === true}">
                        <p>Uploaded Successfully</p>
                        <p>The job to upload the data has been submitted.</p>
                        <p>Status updates will be sent to you by email to keep you up to date with the job.</p>
                    </div>
                </div>
            </ng-container>


        </mat-card-content>
    </mat-card>
</app-mass-import-export>
