import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import * as moment from 'moment';

@Component({
  selector: 'app-out-of-office-widget',
  templateUrl: './out-of-office-widget.component.html',
  styleUrls: ['./out-of-office-widget.component.scss']
})
export class OutOfOfficeWidgetComponent implements OnInit {
  employees: any[] = []; 
  loading: boolean = true;
  public routes: typeof routes = routes;
  leaveOptions = [
    'Sick Leave',
    'Vacation Days',
    'Work From Home',
  ];

  constructor(
    private employeeService: EmployeeService,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getEmployees()
    .subscribe(
      res => {
        this.employees = res?.body?.data;

        this.employees.forEach(
          employee => {
            employee.leaveType = this.leaveOptions[Math.floor(Math.random()*this.leaveOptions.length)];
            employee.leaveStartDate = moment().subtract(Math.floor(Math.random() * 14),'d').format('YYYY-MM-DD');
            employee.leaveEndDate = moment().add(Math.floor(Math.random() * 14),'d').format('YYYY-MM-DD');
          }
        );

        this.loading = false;
      }
    )
  }
}
