<div class="container">
    <div class="left">
        <div class="title">
            <h5>
                {{ ordinaryPay.title}}
            </h5>
        </div>
        <div class="description">
            {{ ordinaryPay.description}}
        </div>
    </div>

    <div class="value-container ">
        <div class="value">
            {{ ordinaryPay.mean}}
        </div>
        <div class="label">
            Mean Hourly Rate
        </div>
    </div>
    
    <div class="value-container ">
        <div class="value">
            {{ ordinaryPay.median}}
        </div>
        <div class="label">
            Median Hourly Rate
        </div>
    </div>
</div>