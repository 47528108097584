<app-site-settings-menu>
    <div class="navigation-menu-list main-container">
        <div class="menu">
            <mat-list>
                <ng-container *ngFor="let item of navList">
<!--                    <mat-list-item [routerLink]="item.link" routerLinkActive="active" *appHasFeature="item.featureCode">-->
                    <mat-list-item 
                        setColor
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            },
                            {
                                color: 'primaryColour',
                                setIfClass: ['primaryColor'],
                                events: ['routerLinkActive']
                            },
                        ]"
                        [routerLink]="item.link" 
                        routerLinkActive="primaryColor" 
                        class="primaryHoverColor" 
                        *appHasFeature="item.featureCode"
                    >
                        <ng-container *ngIf="item.svg; else iconTemplate">
                            <svg xmlns="http://www.w3.org/2000/svg" class="mat-icon">
                                <image [attr.href]="item.svg" width="100%" height="100%"/>
                            </svg>
                        </ng-container>
                        <ng-template #iconTemplate>
                            <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>
                        </ng-template>
                        <div class="text">{{item.text}}</div>
                    </mat-list-item>
                </ng-container>
            </mat-list>
        </div>
        <div class="content">
            <ng-content></ng-content>
            <!-- <app-toil-policies-types></app-toil-policies-types> -->
        </div>
    </div>
</app-site-settings-menu>