<app-mass-import-export>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>Template Download</h5>
            <div>
                <button mat-flat-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="exportToExcel()" [disabled]="selectedTablesList.length === 0" >
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">save_alt</mat-icon> Download Template
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <ng-container 
                class="loading-container"
                *ngIf="loadingTables || loadingFields">
                <ngx-skeleton-loader
                    count="10"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-container>
            
            <div class="left list" *ngIf="!loadingTables && !loadingFields">
                <h6>Available Fields</h6>

                <mat-form-field class="search-field" appearance="outline" >
                    <input matInput
                            [(ngModel)]="tableListSearchValue"
                            placeholder="Search...">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>
            
                <div class="tables-list-container">
                    <mat-list>
                        <ng-container *ngFor="let tableList of tables | lookupListSearch:tableListSearchValue">
                            <div 
                                mat-subheader 
                                class="clickable" 
                                (click)="addToList(tableList)"
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                            >
                                {{tableList.name}} <mat-icon class="material-icons-outlined">add</mat-icon>
                            </div>
                            <mat-list-item 
                                class="clickable" 
                                *ngFor="let item of tableList.fields" 
                                (click)="addToList(tableList, item)"
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                            >
                                {{item.name}} <mat-icon class="material-icons-outlined">add</mat-icon>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </mat-list>
                </div>
            </div>
        
            <div class="right list" *ngIf="!loadingTables && !loadingFields">
                <h6>Selected Fields</h6>

                <mat-form-field class="search-field" appearance="outline" >
                    <input matInput
                            [(ngModel)]="selectedTablesListSearchValue"
                            placeholder="Search...">
                    <mat-icon matPrefix class="material-icons-outlined">
                        search
                    </mat-icon>
                </mat-form-field>
            
                <div class="tables-list-container">
                    <mat-list>
                        <ng-container *ngFor="let tableList of selectedTablesList | lookupListSearch:selectedTablesListSearchValue">
                            <div 
                                mat-subheader 
                                class="clickable" 
                                (click)="removeFromList(tableList)"
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                            >
                                {{tableList.name}} <mat-icon class="material-icons-outlined">remove</mat-icon>
                            </div>
                            <mat-list-item 
                                class="clickable" 
                                *ngFor="let item of tableList.fields" 
                                (click)="removeFromList(tableList, item)"
                                setColor
                                [setColorRules]="[
                                    {
                                        color: 'primaryColour',
                                        events: ['hover']
                                    }
                                ]"
                            >
                                {{item.name}} <mat-icon class="material-icons-outlined">remove</mat-icon>
                            </mat-list-item>
                            <mat-divider></mat-divider>
                        </ng-container>
                    </mat-list>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</app-mass-import-export>
