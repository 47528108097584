<mat-list-item appPreventDoubleClick (throttledClick)="openLeaveRequestDialog.emit(leaveRequest)"> 
    <div class="leaveRequest-row">
        <div class="left">
            <div class="timeofftype-details">
                <img *ngIf="iconUrl" [src]="domSanitizer.bypassSecurityTrustUrl(iconUrl)"/>
                <h6>{{leaveRequest.timeOffType.name}}</h6>
            </div>
        </div>
        
        <div class="dates-section">
            <div class="dates">
                <div class="start-date">
                    <div class="date">{{ moment(leaveRequest.from).format('ll') }}</div>
                    <div class="time" *ngIf="leaveRequest.startTime !== null">{{ moment(leaveRequest.startTime, 'HH:mm:ss').format('h:mm A') }}</div>
                </div>
                -
                <div class="end-date">
                    <div class="date">{{ moment(leaveRequest.to).format('ll') }}</div>
                    <div class="time" *ngIf="leaveRequest.endTime !== null">{{ moment(leaveRequest.endTime, 'HH:mm:ss').format('h:mm A') }}</div>
                </div>
            </div>

            <mat-chip-list class="half-day">
                <mat-chip class="green-chip" *ngIf="leaveRequest.halfDay === true" appLocalizationPopupDirective [localizationCode]="'LeaveRequestHalfDay'">{{ 'LeaveRequestHalfDay' | translate: {Default: "Half Day"} }}</mat-chip>
            </mat-chip-list>
        </div>
        
        <div class="middle">
            <ng-container *ngIf="leaveRequest.requestStatusSetBy !== null; else showManager">
                <div class="title">Status Updated</div>

                <div class="employee-name">
                    <app-employee-image [employeeId]="leaveRequest.requestStatusSetBy.id" [width]="25" [height]="25" [round]="true" *ngIf="!isLoading"></app-employee-image>
                    <span class="employee">
                        {{leaveRequest.requestStatusSetBy.firstName}} {{leaveRequest.requestStatusSetBy.lastName}}
                    </span>
                </div>

                <div class="status-date">
                    <div class="date">{{ moment(leaveRequest.requestStatusUpdatedOn).format('lll') }}</div>
                </div>
            </ng-container>

            <ng-template #showManager>
                <div class="title">Awaiting Feedback From</div>

                <ng-container *ngIf="leaveRequest.managers.length > 1">
                    <mat-icon class="material-icons-outlined employee-list-icon" [matBadge]="leaveRequest.managers.length" [matTooltip]="showEmployeeTooltip(leaveRequest.managers)">
                        account_circle
                    </mat-icon>
                </ng-container>
    
                <ng-container *ngIf="leaveRequest.managers.length == 1">
                    <ng-container *ngFor="let employee of leaveRequest.managers">
                        <div class="employee-name">
                            <app-employee-image [employeeId]="employee.id" [width]="25" [height]="25" [round]="true" *ngIf="!isLoading"></app-employee-image>
    
                            <span class="employee">
                                {{employee.firstName}} {{employee.lastName}}
                            </span>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-template>
        </div>
        
        <div class="right">
            <mat-chip-list>
                <mat-chip 
                    [ngClass]="{
                        'red-chip': leaveRequest.requestStatus?.id === 'trs_Denied',
                        'green-chip': leaveRequest.requestStatus?.id === 'trs_Approved',
                        'orange-chip': leaveRequest.requestStatus?.id === 'trs_Pending'
                    }"
                >{{ leaveRequest.requestStatus?.name }}</mat-chip>
            </mat-chip-list>

            <ng-container *ngIf="leaveRequest.requestStatus?.id !== 'trs_Approved'">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button appLocalizationPopupDirective [localizationCode]="'EmployeeTimeOffTypeEdit'" class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openEditRequestDialog(leaveRequest.id)" mat-menu-item *ngIf="leaveRequest.requestStatus?.id === 'trs_Pending'">
                            <mat-icon class="settings-button__icon material-icons-outlined">edit</mat-icon>
                            {{ 'EmployeeTimeOffTypeEdit' | translate: {Default: "Edit"} }}
                        </button>
                        <button appLocalizationPopupDirective [localizationCode]="'EmployeeTimeOffTypeCancel'" class="settings-menu__item material-icons-outlined" appPreventDoubleClick (throttledClick)="$event.stopPropagation(); openConfirmDeleteDialog();" mat-menu-item>
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'EmployeeTimeOffTypeCancel' | translate: {Default: "Cancel"} }}
                        </button>
                    </mat-menu>
                </div>
            </ng-container>

            <ng-container *ngIf="leaveRequest.requestStatus?.id === 'trs_Approved' && isInFuture(leaveRequest.from)">
                <button class="settings-button" mat-mini-fab [matMenuTriggerFor]="settings" appPreventDoubleClick (throttledClick)="$event.stopPropagation()">
                    <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
                </button>
                <div class="settings-menu">
                    <mat-menu #settings="matMenu" xPosition="before">
                        <button 
                            class="settings-menu__item material-icons-outlined" 
                            appPreventDoubleClick 
                            (throttledClick)="$event.stopPropagation(); openConfirmDeleteDialog();" 
                            mat-menu-item
                            appLocalizationPopupDirective 
                            [localizationCode]="'EmployeeTimeOffTypeCancel'"
                        >
                            <mat-icon class="settings-button__icon material-icons-outlined">delete</mat-icon>
                            {{ 'EmployeeTimeOffTypeCancel' | translate: {Default: "Cancel"} }}
                        </button>
                    </mat-menu>
                </div>
            </ng-container>

        </div>
    </div>    
</mat-list-item>