import { Component, OnInit } from '@angular/core';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as moment from 'moment';
import { EmployeeReviewDialogComponent } from "@app/modules/homepage/components/employee-review-dialog/employee-review-dialog.component";

@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.scss']
})
export class TasksWidgetComponent implements OnInit {
  employees: any[] = [];
  loading: boolean = true;
  public routes: typeof routes = routes;
  birthdayTimePeriodSearchValue: string = "thisMonth";

  taskOptions = [
    {
      task: 'General',
      description: 'You submitted time off',
    },
    {
      task: 'Sign Document',
      description: 'You gave feeback to Alex',
    },
    {
      task: 'Read Document',
      description: 'You were given feedback',
    },
    {
      task: "Review",
      description: "You had a 1:1 with Alex"
    }
  ];

  constructor(
    private employeeService: EmployeeService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees() {
    this.employeeService.getEmployeesNew(0, 4)
    .subscribe(
      res => {

        // this.employees = res.employees;
        this.employees = res.data;

        this.employees.forEach(
          (employee, index) => {
            employee.task = this.taskOptions[index];
            employee.taskDueDate = moment().subtract(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD');
          }
        )


        // this.employees.forEach(
        //     (employee, index) => {
        //         if (index == 0) {
        //             employee.task = this.taskOptions[3];
        //             employee.taskDueDate = moment().add(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD');
        //         } else {
        //             employee.task = this.taskOptions[Math.floor(Math.random() * (this.taskOptions.length - 1))];
        //             employee.taskDueDate = moment().add(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD');
        //         }
        //     }
        // )

        this.employees = this.shuffleArray(this.employees);
        this.loading = false;
      }
    )
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {

        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
}

    handleClick(task: any) {
        if (task.task == "Review") {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            const dialogRef = this.dialog.open(EmployeeReviewDialogComponent, dialogConfig);
            dialogRef.afterClosed().subscribe(
                data => {
                    if (data) {
                        // this.addVariableReviewer(data);
                    }
                }
            );
        }
    }
}
