
<app-layout>
  <ng-container *ngIf="hasFeatureAccess">
    <mat-toolbar class="page-header" role="heading">
      <h1 appLocalizationPopupDirective [localizationCode]="'Dashboard'">{{ 'Dashboard' | translate: {Default: "Dashboard"} }}</h1>
    </mat-toolbar>

    <div *ngIf="currentEmployeeDetails.userType === 'Employee'" class="content">
      <app-direct-reportees></app-direct-reportees>
      
      <app-direct-reportees-schedule></app-direct-reportees-schedule>

      <ng-container *appHasFeature="features.PERFORMANCE_DIRECT_REPORTEE_GOALS">
        <app-direct-reportees-goals *appHasModuleAccess="modules.PERFORMANCE"></app-direct-reportees-goals>
      </ng-container>

      <div class="k-d-flex k-justify-content-center request-container">
        <app-pending-time-off-approvals *appHasModuleAccess="modules.TIME_OFF" class="requests"></app-pending-time-off-approvals>
        <ng-container *appHasModuleAccess="modules.TOIL">
          <app-pending-toil-approvals *appHasFeature="features.TOIL" class="requests"></app-pending-toil-approvals>
        </ng-container>
      </div>

      <!-- <app-employees-page></app-employees-page> -->
    </div>
  </ng-container>

  <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-layout>
