import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Integration, Mapping, Route } from '../models/integration.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  integrations: Integration[] = [
    {
      id: '12213123',
      name: 'Zellis',
      description: 'example integration with zellis payroll',
      integration: {
        id: '325345',
        name: 'Zellis'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: '4374575234235',
      name: 'ADP',
      description: "An ADP integration links ADP's payroll and HR services with other systems to automate data synchronization, streamline HR processes, and ensure seamless management of employee information.",
      integration: {
        id: '45748764',
        name: 'ADP'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: '4374575234235',
      name: 'Docebo',
      description: "A Docebo integration connects the Docebo learning management system with other platforms to automate training processes, synchronize learner data, and enhance the overall learning experience across systems.",
      integration: {
        id: '12343241534',
        name: 'Docebo'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: '45tgefg',
      name: 'BOSS',
      description: 'example integration with BOSS sales system',
      integration: {
        id: 'f43rerf',
        name: 'BOSS'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'v45fgh45y',
      name: 'PIPA',
      description: 'example integration with PIPA fleet system',
      integration: {
        id: 'f43rerf',
        name: 'BOSS'
      },
      frequency: 'Weekly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_mwUJf9lRKWbNPg", firstName: "Esme", lastName: "Biship"},
      createdOn: '2023-01-05T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'f345g',
      name: 'White Pages',
      description: 'example integration with White Pages directory system',
      integration: {
        id: 'drg345tgf',
        name: 'White Pages'
      },
      frequency: 'Daily',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_oQiQUCIWV3S8fP", firstName: "Francesca", lastName: "Campos"},
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'g45grdfxz',
      name: 'Genesys',
      description: 'example integration with Genesys telephony system',
      integration: {
        id: 'drg345tgf',
        name: 'Genesys'
      },
      frequency: 'Daily',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'b54gtrg',
      name: 'OpenBlend',
      description: 'example integration with OpenBlend coaching system',
      integration: {
        id: 'bfgb345gr',
        name: 'OpenBlend'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_uLtt6NpkTOTWDZ", firstName: "Ben", lastName: "Buckton"},
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'c432tdfgdf',
      name: 'SAP Litmos',
      description: 'example integration with SAP Litmos coaching system',
      integration: {
        id: 'c324trvfg',
        name: 'SAP Litmos'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
    {
      id: 'v45ytfdh',
      name: ' Azure AD',
      description: 'example integration with  Azure Active Directory',
      integration: {
        id: 'fr234terg',
        name: ' Azure AD'
      },
      frequency: 'Monthly',
      startDate: '2023-03-01T00:00:00',
      createdBy: {id: "usr_QbBuVt9SLK8fSI", firstName: "Kone", lastName: "Bouba"},
      createdOn: '2023-02-11T00:00:00',
      employees: [
        {
          id: 'usr_GWGmNiX25fBVvk',
          firstname: 'Bineta',
          lastname: 'Aissatou'
        }
      ],
      connectionSettings: {
        clientId: '9866d68c-7f23-40f8-8c5d-84f9aa441e6b',
        clientSecret: '56b2735d-6dcb-4ec8-b0c8-0e1e9cf9bba2',
        apiurl: 'https://api.zellis.com/v1',
        authurl: 'https://auth.zellis.com/v1',
        certificate: `-----BEGIN CERTIFICATE-----
        MIIC5zCCAc+gAwIBAgIUQ8WQI2mCX1m2QfIZwvB8WncbPu0wDQYJKoZIhvcNAQEL
        BQAwEzERMA8GA1UEAwwIUGF5bWVudCBDQTAeFw0yMzAzMTAxMTA4MzdaFw0zNTAz
        MTAxMTA4MzdaMBMxETAPBgNVBAMMCFBheW1lbnQgQ0EwggEiMA0GCSqGSIb3DQEB
        AQUAA4IBDwAwggEKAoIBAQDWgU/tDhJlKQ2sCHsU9m+tWlRblGGYkYd/MaocHjK9
        A1V7q3qAxSRwV7lW8yOFgbV+hvrYsYi1QKzW9SydU6weB1I/eKw6TJpPMo0KmzY1
        DvL20zFZ8XlP0jbfKfOvO2QIy8xgG2Kn0qB5vS0/9W8XJtRgKt0i4e4OMyWxN02K
        bq3d3Sv5SckzX18t/U/jRjCnG4F4bW17I8czGLaJ1YDd+MWZIJKVAo8cEj/QM37a
        1v2QDRWU5c5kjRy6B1vF8lwoWfPq3HqZVGr/L2SgLo9k3neqsiOyHXTTSrt4wCU4
        DhZJ/P4ZmOWzHLB+c9NBsGTs+ox/AgMBAAGjIzAhMA4GA1UdDwEB/wQEAwIBBjAP
        BgNVHRMBAf8EBTADAQH/MB0GA1UdDgQWBBSRZv+HdKFh1Ks72t+YJyTItwPnKjAf
        BgNVHSMEGDAWgBQ0A7V+xXQ2uWy7KAXh0/z+IN7iwzANBgkqhkiG9w0BAQsFAAOC
        AQEAhUaA6G5vF/Lf+6Hbsyn11hRHgVyzO/jv5E6fXz5c5G6Y5RQeH1Wd5dhj3Ltf
        LhZbyoE50V7X8Q2SluSh7yDnFzBUVNE/BxzyjZggfzwLPKl0d9DzKj34s+pmvrYf
        wLQL0jKx8Q+T1yhNjkF2jSdCd0DU9qyzgqyztOq4ghjK4d4CZMwrIexB4eTptKrD
        Q1ZTJbMNwxihPWT+xMogzONtI9Fsh1dxHnVYlfzoRgGVx`,
        privateKey: `-----BEGIN RSA PRIVATE KEY-----
        MIIEowIBAAKCAQEA2sUO7OyvKjGuj7V1d0k4/a+sxt0ZM8WPy7SRJbSBDuA7LSq
        p8WxLEbFrAFuNUNpHvwbNjSTX8Q/To6Uksd6y1gc6SVhAG6L1fV4+Y4QZIBF4mR8
        B7VnSvn58lq7+BHH1Uh8jKZhM5FlnMN3q68qWkX9OK/YCj5r5i2wzvjTrRZX52Q
        iBhLvYKSSMcOZbh9X9gH7EuGDUdBoIgsHgF12RLzPQoTNJF42YPM/OWDfH9IiGiv
        gKroMvLtt+eN8AldwtGlczRXnSKa3Z3n1W9+pA5m5r5rFWPwMQmFbRLOd4Av7+4C
        v2VK7hW8RbJF7SdE+ye3VJxWzEGbE+GJlYojvQIDAQABAoIBAHG2eLsU6dkbWKQP
        ajx6HfPF86eTIFX8dMgVU+AR6OdN7Bxwo1hUgFoeMBsP6RZmVWmX8D4MT4UMC1vm
        v1VWzq3oNUbq3l0dzwA/Uz0TlT/fpHz6sRvYsISBkCBcPHtrwsuUHfyzEGTeAaX6
        xU6e1dIsMvU7rxdUrbtw77Xd7QWOnf8OLv4B9/w+MDGxQl5D5Sk5vYENp+Iy1xr5
        WtC8RvptPO9t/9x/7gohqo3nGQc1Nn0IxDNw4I4eKxBbG9f9XQZzTX5+5dYlnwkK
        O88FrMw5+lOh8KGM0/TvRnb20ERlgnBxan3Kk3Q80mtv0wnsfJyAKGt3o/vb2/3n
        jve1zFECgYEA8sYnR1kPyjKsl/XcCr6ylgRLhkkxyP6z/yX6eZU6gWd7GTFmDmSL
        vxVQn8eU0M7OvO6X9H6jxG6jrWVrJrFnn+uVQfHq3vjJTHdndfjGk9GV7xTJ6s/F
        0R/PdjHbpiy7OcLgDlxn+1Rz28t9o+/tZ8j3WYf+qAhju1kwPs/ouyECgYEA4irL
        mCgh9fzZm+5d63gS/xjO5p5w5y5`,
        workerId: `8fa2c9d5-92a7-4c18-bd75-7ab6e29d986a`,
        associateOID: `3a6c7d9f-ef15-432c-9241-084dbb726fe5`
      },
      mappingId: '32714rny890f7yn'
    },
  ]

  mappings: Mapping[] = [
    {
      id: '32714rny890f7yn',
      name: 'Zellis Mapping',
      description: '',
      categories: [
        {
          id: 'Workers',
          name: 'Workers',
          description: 'Worker REST API',
          selected: false,
          routes: [
            {
              type: 'put',
              route: 'Workers',
              summary: 'Create or update Worker',
              description: 'Creating a new or updating an existing Worker record. Use of this method will wholly replace the existing record with a new one. Therefore empty/null fields will overwrite existing populated items.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
              selected: true,
              fields: [
                {
                  text: 'WorkerNumber',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_EmployeeId'
                  }
                },
                {
                  text: 'KnownAs',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_PreferredName'
                  }
                },
                {
                  text: 'Surname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_LastName'
                  }
                },
                {
                  text: 'PreviousSurname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_MaidenName'
                  }
                },
                {
                  text: 'BirthDate',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_Birthday'
                  }
                }
              ]
            },
            {
              type: 'post',
              route: 'Workers',
              summary: 'Create Worker',
              description: 'Creating a new Worker record. Use this Method where Worker does not yet exist in ResourceLink Application.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
              selected: true,
              fields: [
                {
                  text: 'WorkerNumber',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_EmployeeId'
                  }
                },
                {
                  text: 'KnownAs',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_PreferredName'
                  }
                },
                {
                  text: 'Surname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_LastName'
                  }
                },
                {
                  text: 'PreviousSurname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_MaidenName'
                  }
                },
                {
                  text: 'BirthDate',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_Birthday'
                  }
                }
              ]
            },
            {
              type: 'patch',
              route: 'Workers',
              summary: 'Partially update Worker',
              description: 'Updating an existing Worker record with selected data values. Use of this method will replace only the data items provided. Any not specified will be left as is.<br><br><b>PLEASE NOTE: Version 2.0 of this API is available and supports asynchronous transactions.  Using a Parameter as “version=v2” as part of the request will select this version.',
              selected: false,
              fields: [
                {
                  text: 'WorkerNumber',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_EmployeeId'
                  }
                },
                {
                  text: 'KnownAs',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_PreferredName'
                  }
                },
                {
                  text: 'Surname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_LastName'
                  }
                },
                {
                  text: 'PreviousSurname',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_MaidenName'
                  }
                },
                {
                  text: 'BirthDate',
                  valueType: 'string',
                  mappedTo: {
                      table: 'tbl_Employees',
                      field: 'tfi_Birthday'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]

  constructor() { }

  getIntegrations(): Observable<Integration[]> {
    return of(this.integrations)
  }

  getIntegration(id: string): Observable<Integration> {
    return of(this.integrations.find(obj => obj.id === id))
  }

  getMappings(): Observable<Mapping[]> {
    return of(this.mappings)
  }

  getMapping(id: string): Observable<Mapping> {
    return of(this.mappings.find(obj => obj.id === id))
  }
}
