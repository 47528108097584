import { Component, OnInit, ViewChild } from "@angular/core";
import { ApexLegend, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend;
};

@Component({
  selector: 'app-turnover-pie-chart',
  templateUrl: './turnover-pie-chart.component.html',
  styleUrls: ['./turnover-pie-chart.component.scss']
})
export class TurnoverPieChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [65, 35],
      chart: {
        width: 380,
        type: "pie"
      },
      labels: ["Remaining", "Turnover"],
      legend: {
        position: 'bottom'
      }
    };
  }

  ngOnInit(): void {
  }

}
