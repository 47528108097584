import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from "@app/core/services/environment.service";
import { EmailNotification } from "@app/modules/site-settings/email-notifications/models/email-notification.model";
import { Observable } from "rxjs";

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class EmailNotificationsService {

    constructor(private http: HttpClient, private envService: EnvironmentService) {
    }

    getEmailNotifications(): Observable<EmailNotification[]> {
        const dummyNotifications: EmailNotification[] = [
            {
                id: 1,
                active: true,
                reminderPriorDays: 3,
                sendEveryDays: 7,
                excludeTerminatedEmployees: false,
                subject: "Reminder: Upcoming Meeting",
                content: "Hi {EmployeeFirstName},<br/><br/>Just a quick reminder that we have a meeting scheduled for {MeetingDate}. See you then!<br/><br/>Best,<br/>{YourName}",
                recipients: ["jane@example.com", "john@example.com"]
            },
            {
                id: 2,
                active: false,
                reminderPriorDays: 1,
                sendEveryDays: 0,
                excludeTerminatedEmployees: true,
                subject: "Important Announcement",
                content: "Dear all,<br/><br/>I wanted to let you know that we'll be having an important company-wide meeting on {MeetingDate}. This is a mandatory meeting for all employees.<br/><br/>Best,<br/>{YourName}",
                recipients: ["all@example.com"]
            },
            {
                id: 3,
                active: true,
                reminderPriorDays: 2,
                sendEveryDays: 14,
                excludeTerminatedEmployees: false,
                subject: "Monthly Newsletter",
                content: "Hi {EmployeeFirstName},<br/><br/>I hope you're all doing well! Just wanted to share a few updates and announcements with you in our monthly newsletter. Please let me know if you have any questions or feedback.<br/><br/>Best,<br/>{YourName}",
                recipients: ["jane@example.com", "john@example.com", "mary@example.com", "sam@example.com"]
            },
            {
                id: 4,
                active: false,
                reminderPriorDays: 7,
                sendEveryDays: 0,
                excludeTerminatedEmployees: false,
                subject: "Reminder: Performance Review",
                content: "Hi {EmployeeFirstName},<br/><br/>Just a quick reminder that your performance review is scheduled for {ReviewDate}. Please come prepared to discuss your goals, accomplishments, and any challenges you've faced.<br/><br/>Best,<br/>{YourName}",
                recipients: ["jane@example.com", "john@example.com", "mary@example.com"]
            }, {
                id: 5,
                subject: "Apprenticeship Program - End",
                content: "Hi {EmployeeFirstName}, your apprenticeship program has ended.",
                recipients: ["john@example.com"],
                active: true,
                reminderPriorDays: 7,
                sendEveryDays: 0,
                excludeTerminatedEmployees: true
            },
            {
                id: 6,
                subject: "Apprenticeship Program Start",
                content: "Hi {EmployeeFirstName}, your apprenticeship program has started.",
                recipients: ["jane@example.com"],
                active: true,
                reminderPriorDays: 0,
                sendEveryDays: 0,
                excludeTerminatedEmployees: true
            },
            {
                id: 7,
                subject: "Contract End Date",
                content: "Hi {EmployeeFirstName}, your contract has ended.",
                recipients: ["susan@example.com", "bob@example.com"],
                active: false,
                reminderPriorDays: 30,
                sendEveryDays: 0,
                excludeTerminatedEmployees: false
            },
            {
                id: 8,
                subject: "Contract Start Date",
                content: "Hi {EmployeeFirstName}, your contract has started.",
                recipients: ["alice@example.com"],
                active: true,
                reminderPriorDays: 0,
                sendEveryDays: 0,
                excludeTerminatedEmployees: false
            },
            {
                id: 9,
                subject: "Induction Completed",
                content: "Hi {EmployeeFirstName}, your induction is complete.",
                recipients: ["david@example.com"],
                active: true,
                reminderPriorDays: 0,
                sendEveryDays: 14,
                excludeTerminatedEmployees: true
            },
            {
                id: 10,
                subject: "Negotiator Promotion",
                content: "Employee is ready for promotion",
                recipients: ["david@example.com"],
                active: true,
                reminderPriorDays: 0,
                sendEveryDays: 14,
                excludeTerminatedEmployees: true
            }
        ];
        return new Observable<EmailNotification[]>(subscriber => {
            subscriber.next(dummyNotifications);
            subscriber.complete();
        });

    }
}
