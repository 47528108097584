<mat-card>
    <mat-card-title >
        <h5>Turnover Rate Year Over Year</h5>
    </mat-card-title>

    <mat-card-content>
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis"
              [stroke]="chartOptions.stroke"
              [dataLabels]="chartOptions.dataLabels"
              [yaxis]="chartOptions.yaxis"
              [labels]="chartOptions.labels"
              [legend]="chartOptions.legend"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>