import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gender-bonus-pay',
  templateUrl: './gender-bonus-pay.component.html',
  styleUrls: ['./gender-bonus-pay.component.scss']
})
export class GenderBonusPayComponent implements OnInit {
  data = [
    {
      category: "Mean",
      percentage: "21.6%",
    },
    {
      category: "Median",
      percentage: "46%",
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
