<app-site-settings-menu>
    <div class="main-container">
        <div class="top-container">
            <h5>Documents</h5>
        </div>

        <app-documents-site-tree-view
            *ngIf="selectedView == 'treeView'"
            [employeeID]="employeeID"
            (editAction)="openDocumentEditDialog($event)"
            (deleteAction)="openConfirmDeleteDialog($event)"
            (viewAction)="download($event)"
            (moveAction)="moveItem($event)"
            (downloadAction)="download($event)"
            (showCreateNewDirectoryForm)="openDirectoryFormDialog($event)"
            (showDocumentUploadDialog)="openDocumentUploadDialog($event)"
            [refreshView]="refreshView"
        ></app-documents-site-tree-view>
    </div>
</app-site-settings-menu>
