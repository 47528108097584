import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { api_routes, db_tables, routes } from '@app/consts';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { defer, forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GoalPlanService } from "@app/modules/performance/components/goal-plans/services/goal-plan.service";
import { EmployeeGoal, EmployeeGoalSubmit } from "@app/modules/performance/components/goal-plans/models/goal-plan.model";
import { EmployeeReviewDialogComponent } from "@app/modules/homepage/components/employee-review-dialog/employee-review-dialog.component";
import { EmployeeService } from "@app/core/services/employee.service";
import {SalaryReviewDialogComponent} from '@app/modules/compensation/components/salary-review-dialog/salary-review-dialog.component';

@Component({
    selector: 'app-salary-review-widget',
    templateUrl: './salary-review-widget.component.html',
    styleUrls: ['./salary-review-widget.component.scss']
})
export class SalaryReviewWidgetComponent implements OnInit {
    public columns: any[] = [
        {field: "employee", title: "Employee", type: "employee"},
        {field: "performanceRating", title: "Performance Rating", type: "longText"},
        {field: "compaRatio", title: "Compa Ratio", type: "longText"},
        {field: "benchmarkRange", title: "Benchmark Range", type: "longText"},
        {field: "meritIncreaseGuideline", title: "Merit Increase Guideline", type: "longText"},
        {field: "currentBaseSalary", title: "Current Base Salary", type: "longText"},
        {field: "newBaseSalary", title: "New Base Salary", type: "longText"},
        {field: "proposedIncreaseAmount", title: "Proposed Increase Amount", type: "longText"},
        {field: "proposedIncreasePercentage", title: "Proposed Increase Percentage", type: "longText"},
        {field: "comments", title: "Comments", type: "longText"},
    ];
    public bindingType: String = "array";
    public view: any;
    public gridData: any;
    public gridDataResult: GridDataResult;
    public api_routes = api_routes;
    public db_tables = db_tables;
    isLoading: boolean;
    pageSize: number = 5;
    skip: string = '0';
    clearSelectedItems: boolean = false;
    dialogRef: any;
    formValid: any;
    user$: any;

    fieldBaseData = [];

    @Input() title: string = "Salary Reviews";

    constructor(
        private dialog: MatDialog,
        public translate: TranslateService,
        private snackbarService: SnackbarService,
        private route: ActivatedRoute,
        private router: Router,
        private overlayService: OverlayService,
        private oidcAuthService: OidcAuthService,
        private goalService: GoalPlanService,
        private employeeService: EmployeeService,
    ) {
        this.user$ = this.oidcAuthService.userProfile;
    }

    ngOnInit(): void {
        this.view = this.getData();
    }

    getData() {
        this.isLoading = true;

        forkJoin([
            this.employeeService.getEmployees()
        ])
        .pipe(
            finalize(() => {
                this.isLoading = false;
            }))
        .subscribe(
            (res) => {


                let employeesPaged = res[0].body;
                let salaries =[];

                employeesPaged.data.forEach(
                    employee => {
                        salaries.push(this.generateRandomSalary(employee))
                    }
                )

                this.gridDataResult = {
                    data: salaries.slice(0, this.pageSize),
                    total: salaries.length,
                }
            })

    }

    generateRandomSalary(employee: any){
        let res = {};
        let commentsRandom = [
            "Employee has consistently exceeded expectations in all areas of their work.",
            "Employee is a valuable asset to the team and is always willing to go the extra mile.",
            "Employee has made significant contributions to the company's success.",
            "Employee is a highly motivated and results-oriented individual.",
            "Employee is a team player and is always willing to help others.",
            "Employee is a positive and enthusiastic member of the team.",
            "Employee is a strong communicator and is able to clearly and concisely explain their ideas.",
            "Employee is a quick learner and is always eager to take on new challenges.",
            "Employee is a valuable asset to the company and is deserving of a salary increase.",
        ];




        res['employee'] = employee;
        res['performanceRating'] = Math.floor(Math.random() * 5) + 1;
        res['compaRatio'] = (Math.random() * (3.0 - 0.5) + 0.5).toFixed(2);


        const salary1 = (Math.random() * 100000) + 20000;
        const maxSalary2 = salary1 + (salary1 * 0.4);
        const salary2 = Math.random() * (maxSalary2 - salary1) + salary1;
        res['benchmarkRange'] = "$" + salary1.toFixed(2) + " - $" + salary2.toFixed(2);

        const min = 0;
        const max = 15;


        const guid1 =  Math.floor(Math.random() * max) ;
        const maxGuid2 = guid1 + (guid1 * 0.8);
        const guid2 =  Math.floor(Math.random() * (max) + guid1);
        res['meritIncreaseGuideline'] = guid1 + "% - " + guid2 + "%";

        const currentBaseSalary = Math.random() * (maxSalary2 - salary1 + 1) + salary1;
        res['currentBaseSalary'] = "$ " + currentBaseSalary.toFixed(2);

        const proposedIncreasePercentage = Math.random() * (guid2 - guid1 + 1) + guid1;

        let newBaseSalary = currentBaseSalary + (currentBaseSalary * (proposedIncreasePercentage / 100));
        res['newBaseSalary'] = "$ " + newBaseSalary.toFixed(2);


        let proposedIncreaseAmount = newBaseSalary -  currentBaseSalary;
        res['proposedIncreaseAmount'] = "$ " + proposedIncreaseAmount.toFixed(2);

        res['proposedIncreasePercentage'] = proposedIncreasePercentage.toFixed(2) + " %";

        res['comments'] = commentsRandom[Math.floor(Math.random() * commentsRandom.length)];

        let fieldBaseData = {
            employeeId : res['employee']['id'],
            meritIncreaseGuideline: res['meritIncreaseGuideline'],
            currentBaseSalary: currentBaseSalary.toFixed(2),
            newBaseSalary: newBaseSalary.toFixed(2),
            proposedIncreasePercentage: proposedIncreasePercentage.toFixed(2),
            proposedIncreaseAmount: proposedIncreaseAmount.toFixed(2),
            comments: res['comments'],
        }

        res['fieldBaseData'] = fieldBaseData;

        return res;
    }

    openReviewDialog(inputData?: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            salaryReview: inputData,
        };
        console.log(inputData)

        this.dialogRef = this.dialog.open(SalaryReviewDialogComponent, dialogConfig);

        this.dialogRef.afterClosed().subscribe(
            data => {

                if (data) {
                    this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');

                    let newBaseSalary =  (Number(inputData.fieldBaseData.currentBaseSalary) +  Number(data.proposedIncreaseAmount)).toFixed(2);
                    inputData.fieldBaseData.currentBaseSalary = newBaseSalary;
                    inputData.fieldBaseData.proposedIncreaseAmount = Number(data.proposedIncreaseAmount);
                    inputData.fieldBaseData.proposedIncreasePercentage = Number(data.proposedIncreasePercentage);
                    inputData.fieldBaseData.comments = data.comments;

                    inputData.newBaseSalary = "$ " + newBaseSalary;
                    inputData.proposedIncreaseAmount = "$ " + data.proposedIncreaseAmount;
                    inputData.proposedIncreasePercentage = data.proposedIncreasePercentage + " %";
                    inputData.comments = data.comments;
                }
            }
        );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip.toString();
        this.pageSize = event.take
        this.view = this.getData();
    }

    protected readonly routes = routes;
}
