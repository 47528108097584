<div class="container">
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ formElement.text }}</h5>
    
            <button
                mat-flat-button
                class="action-button"
                setColor="primaryColour"
                color="primary"
                (click)="showAddWorkLocationModal()"
            >
                <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
                <span class="button-text" appLocalizationPopupDirective localizationCode="Add">{{ 'Add' | translate: {Default: "Add"} }}</span>
            </button>
        </mat-card-title>
    
        <mat-card-content>
            <app-data-grid-comp
                [columns]="columns"
                [gridDataResult]="gridDataResult"
                [isLoading]="isLoading"
                [pageSize]="pageSize"
                [skip]="skip"
                category=""
                [rowSelectionSettings]="{
                    canSelectRows: true,
                    selectableMode: 'multiple',
                    selectRowBy: 'id'
                }"
                clickableRows="true"
                searchable="true"
                [filterCategories]="columns"
                [clearSelectedItems]="clearSelectedItems"
                [filterToggleDetails]="filterToggleDetails"
                [hideActionButtons]="true"
                [sortable]="{
                    mode: 'multiple'
                }"
                [sortableColumns]="sortableColumns"
                (emitToggleFilter)="updateFilterToggleDetails($event)"
                (emitPageChangeEvent)="pageChange($event)"
                (emitSearchEvent)="search($event)"
                (emitDeleteEvent)="deleteAllSelected($event)"
                (emitFilterEvent)="filterCallback($event)"
                (emitSortEvent)="sortChange($event)"
            ></app-data-grid-comp>
        </mat-card-content>
    </mat-card>
</div>
