<mat-card class="main-card mat-elevation-z0">
    <mat-card-title>
        <h5 appLocalizationPopupDirective [localizationCode]="'RequestTimeOffInLieu'">{{ 'RequestTimeOffInLieu' | translate: {Default: "Request Time Off in Lieu"} }}</h5>
    </mat-card-title>
    
    <mat-card-content>
        <button mat-flat-button setColor="primaryColour" color="primary" (click)="openAccrualDialog()" appLocalizationPopupDirective [localizationCode]="'AccrueToil'">
            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon>
            {{ 'AccrueToil' | translate: {Default: "Accrue TOIL"} }}
        </button>

        <div class="toil-policies">
            <div *ngFor="let toilPolicy of toilPolicies">
                <mat-card class="time-off-type-card">
                    <mat-card-title>
                        <h6>{{toilPolicy.toilPolicy.name}}</h6>
                    </mat-card-title>
    
                    <mat-card-content>
                        <div setColor="primaryColour" class="grid-cell-vertical availabe-hours">
                            {{toilPolicy.availableHours | number:'1.0-1'}}
                        </div>
                        <div class="grid-cell-vertical">
                            {{toilPolicy.toilPolicy.toilType.name}}
                        </div>
                        <div class="grid-cell-vertical">
                            <button mat-stroked-button setColor="primaryColour" color="primary"  appLocalizationPopupDirective [localizationCode]="'timeOffLeaveTypeRequestToil'" appPreventDoubleClick (throttledClick)="openRequestDialog(toilPolicy)">
                                {{ 'timeOffLeaveTypeRequestToil' | translate: {Default: "Request TOIL"} }}
                            </button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        
    </mat-card-content>
</mat-card>
