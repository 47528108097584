import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmergencyContactService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/emergency-contacts/services/emergency-contact.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { finalize } from 'rxjs/operators';
import { defer, forkJoin } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { api_routes, db_tables } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmergencyContact } from '../../models/emergency-contact.model';

@Component({
  selector: 'app-emergency-contacts-grid',
  templateUrl: './emergency-contacts-grid.component.html',
  styleUrls: ['./emergency-contacts-grid.component.scss']
})
export class EmergencyContactsGridComponent implements OnInit {
  @Input() employeeId: string;

  public columns: any[] = [
    { field: "firstName", title: "First Name", type: "navigation", tableId: "tfi_EcFirstName"},
    { field: "lastName", title: "last Name", tableId: "tfi_EcLastName"},
    { field: "preferredName", title: "preferred Name", tableId: "tfi_EcPreferredName"},
    { field: "relationshipToEmployee", subField: 'text', title: "relationship To Employee", tableId: "tfi_EcRelationshipToEmployee"},
    { field: "isPrimary", title: "is Primary", type: "boolean", default: ["Yes", "No"], tableId: "tfi_EcIsPrimary"},
    { field: "validFrom", title: "valid From", type: "date", tableId: "tfi_EcValidFrom"},
    { field: "validTo", title: "valid to", type: "date", tableId: "tfi_EcValidTo"},
    { field: "email", title: "email", tableId: "tfi_EcEmail"},
    { field: "homePhone", title: "home Phone", tableId: "tfi_EcHomePhone"},
    { field: "workPhone", title: "work Phone", tableId: "tfi_EcWorkPhone"},
  ];
  public bindingType: String = "array";
  public view: any;
  public gridData: any;
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 5;
  skip: number = 0;
  clearSelectedItems: boolean = false;
  formId: string = 'frm_akcgqgbqDaJiIE'
  changeReasonFormId: string = 'frm_k6xojHb9B23uM8';
  dialogRef: any;
  formValid: any;
  sortString: string = 'Lastname-asc~Firstname-asc';
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  accessAndHistoryURL: string = `${api_routes.TALENT}/[employeeId]/${api_routes.EMERGENCY_CONTACTS}/[entityId]`;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private emergencyContactService: EmergencyContactService,
    private snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private tablePermissionsService: TablePermissionsService,
    private overlayService: OverlayService
  ) {
    // this.route.paramMap.subscribe(
    //   params => this.employeeId = params.get("id")
    // )
  }

  ngOnInit(): void {
    this.view = this.getEmergencyContacts();
    this.getTargetEmployeeTablePermissions();
  }

  getEmergencyContacts() {
    this.isLoading = true;

    this.emergencyContactService.getEmergencyContacts(this.employeeId, String(this.pageSize), this.skip, this.sortString)
      .pipe(
        finalize( () => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (res) => {
          this.gridDataResult = {
            data: res.data,
            total: res.total,
          }
          this.gridData = res.data;
        }
      );
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getEmergencyContact(emergencyContact?: EmergencyContact) {
    if(emergencyContact === undefined){
      this.openFormDialog();
    }
    else {
      this.isLoading = true;

      this.emergencyContactService.getEmergencyContact(this.employeeId, emergencyContact.id)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        res => {
          this.openFormDialog(res);
        }
      );
    }
  }

  openFormDialog(emergencyContact?: EmergencyContact) {
    let formData = {
      id: emergencyContact ? emergencyContact.id : null,
      asOf: moment().format(),
      changeReason: '',
      changeReasonComments: '',
      firstName: emergencyContact ? emergencyContact.firstName : null,
      lastName: emergencyContact ? emergencyContact.lastName : null,
      middleName: emergencyContact ? emergencyContact.middleName : null,
      preferredName: emergencyContact ? emergencyContact.preferredName : null,
      birthday: emergencyContact ? emergencyContact.birthday: null,
      gender: emergencyContact ? emergencyContact.gender?.id : null,
      isPrimary: emergencyContact ? emergencyContact.isPrimary : false,
      validFrom: emergencyContact ? emergencyContact.validFrom : null,
      validTo: emergencyContact ? emergencyContact.validTo : null,
      relationshipToEmployee: emergencyContact ? emergencyContact.relationshipToEmployee?.id : null,
      title: emergencyContact ? emergencyContact.title?.id : null,
      streetAddress: emergencyContact ? emergencyContact.streetAddress : null,
      city: emergencyContact ? emergencyContact.city : null,
      province: emergencyContact ? emergencyContact.province : null,
      zip: emergencyContact ? emergencyContact.zip : null,
      country: emergencyContact ? emergencyContact.country?.id : null,
      email: emergencyContact ? emergencyContact.email : null,
      workPhone: emergencyContact ? emergencyContact.workPhone : null,
      homePhone: emergencyContact ? emergencyContact.homePhone : null,
      cellPhone: emergencyContact ? emergencyContact.cellPhone : null,
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formTitle: `${this.translate.instant('EmergencyContact')}`,
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions,
      formData: formData
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      this.save(event)
    });
  }

  formDataEmitted(formData) {
    this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  save(formData: any){
    this.overlayService.show();
    if (formData.birthday) {formData.birthday = moment(formData.birthday).format().slice(0, 10);}
    if(formData.id === null){
      this.emergencyContactService.createNewEmergencyContact(this.employeeId, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getEmergencyContacts();
        }
      );
    }
    else {
      this.emergencyContactService.updateEmergencyContact(this.employeeId, formData)
      .pipe(
        finalize(() => this.overlayService.hide())
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
          this.dialogRef.close(true);
          this.getEmergencyContacts();
        }
      );
    }
  }

  deleteEmergencyContacts(emergencyContactIds: string[]) {
    const observables = emergencyContactIds.map(selectedItem => defer(() => this.emergencyContactService.deleteEmergencyContact(this.employeeId, selectedItem)));

    this.overlayService.show();

    forkJoin(observables)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.view = this.getEmergencyContacts();
        this.clearSelectedItems = !this.clearSelectedItems;
        this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
      }
    );
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.view = this.getEmergencyContacts();
  }

}
