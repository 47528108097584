<mat-card>
    <mat-card-title >
        <h5>Actual vs Benchmark Seniority in Months Per Division</h5>
    </mat-card-title>

    <mat-card-content>
        <div id="chart">
            <apx-chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [yaxis]="chartOptions.yaxis"
            [xaxis]="chartOptions.xaxis"
            [labels]="chartOptions.labels"
            [stroke]="chartOptions.stroke"
            [title]="chartOptions.title"
            [dataLabels]="chartOptions.dataLabels"
            [fill]="chartOptions.fill"
            [tooltip]="chartOptions.tooltip"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>