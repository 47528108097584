import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { MatrixListDialogComponent } from '../matrix-list-dialog/matrix-list-dialog.component';

@Component({
  selector: 'app-matrix-item-list',
  templateUrl: './matrix-item-list.component.html',
  styleUrls: ['./matrix-item-list.component.scss']
})
export class MatrixItemListComponent implements OnInit {
  @Input() employees: EmployeeContactCard[];
  @Input() color: string;
  @Input() title: string;
  @Input() employeesLength: number;

  @Output() employeeUpdated: EventEmitter<any> = new EventEmitter();

  dialogRef: any;

  mappings = {
    "Potential Gem": {
      potential: "High",
      performance: "Low",
    },
    "Inconsistent": {
      potential: "Medium",
      performance: "Low",
    },
    "Risk": {
      potential: "Low",
      performance: "Low",
    },
    "High Potential": {
      potential: "High",
      performance: "Medium",
    },
    "Core Player": {
      potential: "Medium",
      performance: "Medium",
    },
    "Average Performer": {
      potential: "Medium",
      performance: "Low",
    },
    "Star": {
      potential: "High",
      performance: "High",
    },
    "High Performer": {
      potential: "Medium",
      performance: "High",
    },
    "Solid Performer": {
      potential: "Low",
      performance: "High",
    },
  }

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  openPotentialPerformanceDialog(employee?: EmployeeContactCard) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      employee: employee,
      potential: this.mappings[this.title].potential,
      performance: this.mappings[this.title].performance,
      title: this.title
    };

    this.dialogRef = this.dialog.open(MatrixListDialogComponent, dialogConfig);

    this.dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.employeeUpdated.emit(data)
        }
      }
    ); 
    
  }

  getPercentage(): string{
    return `${((this.employees.length / this.employeesLength) * (100/1)).toFixed(1)}%`;
  }

}
