<mat-card class="chart-card mat-elevation-z0">
    <mat-card-title class="header">
        <h6>{{ 'TotalRequests' | translate: {Default: "Total Requests"} }}</h6>
    </mat-card-title>
    <mat-card-content>
        <div id="wrapper">
            <div id="chart-line">
              <apx-chart
                [series]="chartOptions1.series"
                [chart]="chartOptions1.chart"
                [xaxis]="chartOptions1.xaxis"
                [dataLabels]="chartOptions1.dataLabels"
                [colors]="chartOptions1.colors"
                [fill]="chartOptions1.fill"
                [markers]="chartOptions1.markers"
                [stroke]="chartOptions1.stroke"
              ></apx-chart>
            </div>
            <div id="chart-line2">
              <apx-chart
                [series]="chartOptions2.series"
                [chart]="chartOptions2.chart"
                [xaxis]="chartOptions2.xaxis"
                [colors]="chartOptions2.colors"
                [fill]="chartOptions2.fill"
                [yaxis]="chartOptions2.yaxis"
              ></apx-chart>
            </div>
          </div>
    </mat-card-content>
</mat-card>