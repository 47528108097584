import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disciplines-report',
  templateUrl: './disciplines-report.component.html',
  styleUrls: ['./disciplines-report.component.scss']
})
export class DisciplinesReportComponent implements OnInit {
  gridData = [
    {
      employee: "Kevin Adboulaye",
      action: "Poor Attendance",
      reason: "Public transport delays",
      date: "April 23, 2022",
      conductedBy: "Mark Smith",
      comments: "Employee has been notified and agrees this is an issue",
    },
    {
      employee: "Kevin Adboulaye",
      action: "Performance",
      reason: "Poor employee performance",
      date: "June 23, 2022",
      conductedBy: "Mark Smith",
      comments: "Employee has confirmed they did have adequate training and the correct equipment",
    },
    {
      employee: "Jane Doe",
      action: "Late Submission",
      reason: "Missed deadline for project",
      date: "July 15, 2022",
      conductedBy: "Alice Johnson",
      comments: "Employee has been reminded of the importance of deadlines",
    },
    {
      employee: "John Smith",
      action: "Unprofessional Behavior",
      reason: "Inappropriate comments during meeting",
      date: "August 10, 2022",
      conductedBy: "Bob Brown",
      comments: "Employee has apologized and promised to improve behavior",
    },
    {
      employee: "Emily Davis",
      action: "Unauthorized Absence",
      reason: "Did not inform about leave",
      date: "September 5, 2022",
      conductedBy: "Carol White",
      comments: "Employee has been warned about the importance of informing in advance",
    }
  ]
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  backToReportGroups() {
    this.router.navigate([`Reports/Custom/Type/Employees`]);
  }

}
