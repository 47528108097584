import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { db_tables, routes } from '@app/consts';
import { modules } from '@app/consts/modules';
import { ModuleConfigurationService } from '@app/core/services/module-configuration/module-configuration.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit {
  @Input() section: string;
  public routes: typeof routes = routes;
  employeeId: string;

  navList = [];
  public db_tables = db_tables;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoading: boolean = true;
  // selectedComponent = this.translate.instant('PersonalDetails');

  constructor(
    public translate: TranslateService,
    public router: Router,
    private route: ActivatedRoute,
    private tablePermissionsService: TablePermissionsService,
    private moduleConfigurationService: ModuleConfigurationService,
  ) {
    this.employeeId = this.getIdInURL();

    this.getTargetEmployeeTablePermissions();


  }

  ngOnInit(): void {}

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )

    return IdInURL;
  }

  getTargetEmployeeTablePermissions() {
    this.isLoading = true;

    this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
    .pipe(
      finalize(() => {
          this.buildNavList();
          this.navigateToFirstAllowedSection();
      })
    )
    .subscribe(
      res => {
        this.targetEmployeeTablePermissions = res;
      }
    );
  }

  buildNavList() {
    this.navList = [
      {
        text: 'New Hire Checklist',
        section: 'NewHireChecklist',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/EmployeeOnboarding`,
        tableAccess: {
          tableId: db_tables.Talent,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: 'Offboarding Checklist',
        section: 'OffboardingChecklist',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/EmployeeOffboarding`,
        tableAccess: {
          tableId: db_tables.Talent,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'PersonalDetails',
        section: 'PersonalDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/PersonalDetails`,
        tableAccess: {
          tableId: db_tables.Talent,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'LeaveHistory',
        section: 'LeaveHistory',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/LeaveHistory`,
        module: modules.TIME_OFF,
        tableAccess: {
          tableId: db_tables.ABSENCES,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
          text:'ToilHistory',
          section: 'ToilHistory',
          link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/ToilHistory`,
          module: modules.TOIL,
          tableAccess: {
              tableId: db_tables.TOIL,
              permission: 'read',
              targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
          },
      },
      {
        text:'Compensation',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Compensation`,
        section: 'Compensation',
        tableAccess: {
          tableId: db_tables.Compensation,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'OtherCompensation',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/OtherCompensation`,
        section: 'OtherCompensation',
        tableAccess: {
          tableId: db_tables.OtherCompensations,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'EmploymentDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/EmploymentDetails`,
        section: 'EmploymentDetails',
        tableAccess: {
          tableId: db_tables.EmploymentDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_EmergencyContacts',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/EmergencyContacts`,
        section: 'EmergencyContacts',
        tableAccess: {
          tableId: db_tables.EmergencyContacts,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'FamilyDependants',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/FamilyDependants`,
        section: 'FamilyDependants',
        tableAccess: {
          tableId: db_tables.Family,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'MedicalTests',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/MedicalTests`,
        section: 'MedicalTests',
        tableAccess: {
          tableId: db_tables.MedicalTestings,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_VisaPermits',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/VisaPermits`,
        section: 'VisaPermits',
        tableAccess: {
          tableId: db_tables.VisaPermits,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_Languages',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Languages`,
        section: 'Languages',
        tableAccess: {
          tableId: db_tables.Languages,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_TrainingAndCertifications',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/TrainingAndCertifications`,
        section: 'TrainingAndCertifications',
        tableAccess: {
          tableId: db_tables.TrainingAndCertifications,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_Education',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Education`,
        section: 'Education',
        tableAccess: {
          tableId: db_tables.Education,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'WorkHistory',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/WorkExperiences`,
        section: 'WorkExperiences',
        tableAccess: {
          tableId: db_tables.WorkExperiences,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_ProfessionalExpertises',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/ProfessionalExpertises`,
        section: 'ProfessionalExpertises',
        tableAccess: {
          tableId: db_tables.ProfessionalExpertises,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_Association',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Associations`,
        section: 'Associations',
        tableAccess: {
          tableId: db_tables.Associations,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'Documents',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Documents`,
        section: 'Documents',
        tableAccess: {
          tableId: db_tables.DocumentLibrary,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      // {
      //   text: 'Schedule',
      //   tableAccess: {
      //     tableId: db_tables.Talent,
      //     permission: 'read',
      //     targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
      //   },
      // },
      {
        text:'ProfileImage',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/ProfileImage`,
        section: 'ProfileImage',
        tableAccess: {
          tableId: db_tables.ProfilePictures,
          permission: 'update',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_Projects',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Projects`,
        section: 'Projects',
        tableAccess: {
          tableId: db_tables.Projects,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_CompanyAssets',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/CompanyAssets`,
        section: 'CompanyAssets',
        tableAccess: {
          tableId: db_tables.CompanyAssets,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_Relocations',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Relocations`,
        section: 'Relocations',
        tableAccess: {
          tableId: db_tables.Relocations,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text: this.translate.instant('tbl_Disciplines'),
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Disciplines`,
        section: 'Disciplines',
        tableAccess: {
          tableId: db_tables.Grievances,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_Grievances',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/Grievances`,
        section: 'Grievances',
        tableAccess: {
          tableId: db_tables.Grievances,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_InjuryIllness',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/InjuryIllness`,
        section: 'InjuryIllness',
        tableAccess: {
          tableId: db_tables.InjuryIllness,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'tbl_BankDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/BankDetails`,
        section: 'BankDetails',
        tableAccess: {
          tableId: db_tables.BankDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
        text:'PayrollDetails',
        link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/PayrollDetails`,
        section: 'PayrollDetails',
        tableAccess: {
          tableId: db_tables.PayrollDetails,
          permission: 'read',
          targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
        },
      },
      {
          text:'IntegrationDetails',
          link: `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}/IntegrationDetails`,
          section: 'IntegrationDetails',
          tableAccess: {
              tableId: db_tables.IntegrationDetails,
              permission: 'read',
              targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
          },
      },
    ];

    // If the client doesnt have access to a module remove it form the list
    this.navList = this.navList.filter(
      feature => {
        if(feature.module) {
          return this.moduleConfigurationService.hasModuleAccess(feature.module);
        }
        else {
          return true;
        }
      }
    )

    this.isLoading = false;

    setTimeout(() => {
      this.scrollToElement();
    }, 1500);
  }

  scrollToElement() {
    const elements = document.querySelectorAll('.scrollTarget');

    elements[0]?.scrollIntoView();
  }

  getSectionInUrl(): string {
    let SectionInURL: string;

    this.route.paramMap.subscribe(
      params => SectionInURL = params.get("section")
    )

    return SectionInURL;
  }

  redirectTo(route: string) {
    // this.router.navigateByUrl(this.router.url.replace(this.section, route));
    this.router.navigate([`TalentTrack/Edit/${this.employeeId}/EmployeeDetails/${route}`]);
  }

  navigateToFirstAllowedSection() {
    let urlToNavigateTo = '';

    if(this.router.url === `${routes.TALENT_TRACK}${routes.EDIT}/${this.employeeId}${routes.EMPLOYEE_DETAILS}`) {
      this.navList.every(element => {
        let hasAccess = this.tablePermissionsService.hasReadPermission(element.tableAccess.tableId, this.targetEmployeeTablePermissions)

        if(hasAccess){
          urlToNavigateTo = element.link;
          return false;
        }

        return true;
      });
    }

    if(urlToNavigateTo !== ''){
      this.router.navigate([urlToNavigateTo]);
    }
  }
}
