<h5 mat-dialog-title>{{ 'PerformancePotentialRating' | translate: {Default: "Performance Potential Rating"} }}</h5>

<mat-dialog-content>
    <app-direct-reportee-card 
        [directReportee]="employee"
    ></app-direct-reportee-card>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'Potential' | translate: {Default: "Potential"} }}
        </mat-label>

        <mat-select disableOptionCentering [(ngModel)]="potential">
            <mat-option value="">Select potential</mat-option>
            <mat-option value="High">High</mat-option>
            <mat-option value="Medium">Medium</mat-option>
            <mat-option value="Low">Low</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>
            {{ 'Performance' | translate: {Default: "Performance"} }}
        </mat-label>

        <mat-select disableOptionCentering [(ngModel)]="performance">
            <mat-option value="">Select potential</mat-option>
            <mat-option value="High">High</mat-option>
            <mat-option value="Medium">Medium</mat-option>
            <mat-option value="Low">Low</mat-option>
        </mat-select>
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        mat-raised-button 
        color="primary" 
        appPreventDoubleClick 
        (throttledClick)="save()" 
        [disabled]="formValid === false"
    >{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>