<div class="survey">
    <h4 class="title">{{surveySettings.title}}</h4>

    
    <ng-container *ngIf="showForm; else showTick">
        <div class="description" *ngIf="surveySettings.description">
            {{surveySettings.description}}
        </div>
        
        <div class="single-page-survey" *ngIf="surveySettings.type === 'singlePage'">
            <div class="questions">
                <div class="question" *ngFor="let question of surveySettings.questions; let i = index">
                    <app-survey-radio-group *ngIf="question.type === 'radio'" [number]="i+1" [question]="question"></app-survey-radio-group>
        
                    <app-survey-checkbox-group *ngIf="question.type === 'checkbox'" [number]="i+1" [question]="question"></app-survey-checkbox-group>
                    
                    <app-survey-textarea *ngIf="question.type === 'textarea'" [number]="i+1" [question]="question"></app-survey-textarea>
                </div>
            </div>
        
            <div class="buttons">
                <button 
                    mat-raised-button 
                    setColor="primaryColour"
                    color="primary" 
                    (click)="submit()">
                    {{ 'Save' | translate: {Default: "Save"} }}
                </button> 
            </div>
        </div>

        <div class="paged-survey" *ngIf="surveySettings.type === 'paged'">

            <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="formProgress"></mat-progress-bar>

            <div class="questions">
                <div class="question" *ngFor="let question of surveySettings.questions; let i = index">
                    <ng-container *ngIf="selectedQuestion === i">
                        <app-survey-radio-group *ngIf="question.type === 'radio'" [number]="i+1" [question]="question"></app-survey-radio-group>
            
                        <app-survey-checkbox-group *ngIf="question.type === 'checkbox'" [number]="i+1" [question]="question"></app-survey-checkbox-group>
                        
                        <app-survey-textarea *ngIf="question.type === 'textarea'" [number]="i+1" [question]="question"></app-survey-textarea>
                    </ng-container>
                </div>
            </div>

            <div class="buttons">
                <button 
                    mat-raised-button 
                    [disabled]="formProgress === 0"
                    (click)="previousQuestion()">
                    Previous
                </button> 
                
                <button 
                    *ngIf="formProgress !== 100"
                    mat-raised-button 
                    color="primary" 
                    setColor="primaryColour"
                    (click)="nextQuestion()">
                    Next
                </button> 
                
                <button 
                    *ngIf="formProgress === 100"
                    mat-raised-button 
                    color="primary" 
                    setColor="primaryColour"
                    (click)="submit()">
                    {{ 'Save' | translate: {Default: "Save"} }}
                </button> 
            </div>
        </div>
    </ng-container>

    <ng-template #showTick>
        <div class="submitted-container">
            <div class="animation-container">
                <div class="trigger" [ngClass]="{'drawn':showTickAnimation === true}"></div>
                <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200" height="100" x="0px" y="0px"
                    viewBox="0 0 37 50" xml:space="preserve">
                    <path class="circ path" style="fill:none;stroke:#00b189;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" d="
                        M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                        />
                    <polyline class="tick path" style="fill:none;stroke:#00b189;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" points="
                        11.6,20 15.9,24.2 26.4,13.8 "/>
                </svg>
            </div>
    
            <div class="text" [ngClass]="{'visible':showTickAnimation === true}">Submitted Successfully</div>
        </div>
    </ng-template>
    
</div>
