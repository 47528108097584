<div class="main-container">
    <div class="top-container">
        <div class="title">
            <h3 setColor="primaryColour">
                {{data.description.title}}
            </h3>
        </div>
        <div class="text">
            <p>
                {{data.description.description}}
            </p>
        </div> 
    </div>
    
    <div class="bottom-container">
        <div class="grey-container">
            <div class="checked-percentage-container">
                <div class="text">
                    {{this.numberOfChecked}} of {{data.list.length}} Completed
                </div>
                <mat-progress-bar setColor="primaryColour" mode="determinate" [value]="checkedPercentage"></mat-progress-bar>
            </div>
        
            <div class="checklist-container">
                <mat-list class="checkbox-task-list">
                    <div class="checkbox-task" [ngClass]="{'checked': item.checked }" *ngFor="let item of data.list; let i = index;">
                        <mat-list-item>
                            <div class="checkbox-task-details">
                                <mat-checkbox setColor="primaryColour" class="example-margin" [(ngModel)]="item.checked" (change)="getChecklistProgress()"></mat-checkbox>
                                <span class="index">{{i + 1}}</span>
                                <div class="column">
                                    <span class="task">{{item.task}}</span>
                                    <div *ngIf="item.document" class="document">
                                        <span class="title" appPreventDoubleClick (throttledClick)="openPdfViewerDialog(item.document.url, item.document.signatureRequired)">{{item.document.title}}</span>
                                        <div class="signature-container">
                                            <mat-icon class="material-icons-outlined">{{item.document.icon}}</mat-icon> 
                            
                                            <div *ngIf="item.document.signatureRequired &&  !item.document.signed" class="signature-required">Signature Required</div>
                                            <div *ngIf="item.document.signatureRequired &&  item.document.signed" class="signed">Signed</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-divider></mat-divider>
                    </div>
                </mat-list>
            </div>
        </div>
    </div>
</div>
