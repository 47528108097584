<mat-card class="main-card">
    <mat-card-title>
        <h5 appLocalizationPopupDirective [localizationCode]="'RequestTimeOff'">{{ 'RequestTimeOff' | translate: {Default: "Request Time Off"} }}</h5>
    </mat-card-title>

    <mat-card-content>
        <div *ngFor="let timeOffType of timeOffTypes">
            <mat-card class="time-off-type-card">
                <mat-card-title>
                    <h6>{{timeOffType.timeOffType.name}}</h6>
                </mat-card-title>

                <mat-card-content>
                    <div setColor="primaryColour" class="grid-cell-vertical availabe-hours">
                        {{timeOffType.availableHours | number:'1.0-1'}}
                    </div>
                    <div class="grid-cell-vertical">
                        Future Hours Scheduled: {{timeOffType.futureHoursScheduled | number:'1.0-1'}}
                    </div>
                    <div class="grid-cell-vertical">
                        Hours Taken: {{timeOffType.hoursTaken | number:'1.0-1'}}
                    </div>
                    <div class="grid-cell-vertical">
                        <button mat-stroked-button setColor="primaryColour" color="primary" appPreventDoubleClick (throttledClick)="openDialog(timeOffType.timeOffType.id)">
                            {{ 'timeOffLeaveTypeRequestTimeOff' | translate: {Default: "Request Time Off"} }}
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card-content>
</mat-card>
