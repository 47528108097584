<app-compensation>
    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        [category]="title"
        canSelectRows="true"
        clickableRows="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        hideActionButtons="true"
        (emitOpenDialogEvent)="openReviewDialog($event)"
    >
    </app-data-grid-comp>
</app-compensation>

