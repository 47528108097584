<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Ethnicity Bonus Pay</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">
        <div class="top">
            <div class="gender-section female">
                <div class="right">
                    <div class="gender">Ethinc Minority</div>
                    <div class="percentage">18.4%</div>
                </div>
            </div>
    
            <div class="gender-section male">
                <div class="right">
                    <div class="gender">White British</div>
                    <div class="percentage">18%</div>
                </div>
            </div>
        </div>
        
        <kendo-grid [data]="data">
            <kendo-grid-column field="category" title="Bonus Payments" [width]="75"></kendo-grid-column>
            <kendo-grid-column field="percentage" title="2022" [width]="50"> </kendo-grid-column>
        </kendo-grid>
    </mat-card-content>
</mat-card>
