import {Component, OnInit, ViewChild} from '@angular/core';
import {ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexPlotOptions, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  tooltip: ApexTooltip;
  grid: ApexGrid
};

@Component({
  selector: 'app-diversity-by-age',
  templateUrl: './diversity-by-age.component.html',
  styleUrls: ['./diversity-by-age.component.scss']
})
export class DiversityByAgeComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "",
          data: [11, 32, 21, 19, 10, 1, 5]
        }
      ],
      chart: {
        type: "bar",
        // height: 430,
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
        grid: {
            show: false,
        },
      dataLabels: {
        enabled: false,
          formatter(val: string | number | number[], opts?: any): string | number {
            return val + "%";
          }
      },
      xaxis: {
        categories: [
          "16-24",
          "25-34",
          "35-44",
          "45-54",
          "55 - 64",
          "65+",
          "Prefer not to say"
        ]
      },
        yaxis: {
            labels: {
                formatter: function (value) {
                    // return value + "%";
                    return "";
                }
            },
        },
        tooltip: {
          enabled: true,
            y: {
                formatter: function (value) {
                    return value + "%";
                }
            },
            x: {
                show: false
            }
        }
    };
  }

  ngOnInit(): void {
  }

}
