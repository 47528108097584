<mat-card class="mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <h5>{{ summary?.title }}</h5>
        </mat-card-title>
        <mat-card-subtitle>
            {{ summary?.description }}
        </mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <div class="summary-container">
            <div class="summary-left">
                <img src="assets/pay-gap-report/pay-gap-median-mean.png" class="summary-image" />
                <div class="section" *ngFor="let section of summary?.sections">
                    <h6>{{ section.title }}</h6>
                    <p *ngFor="let paragraph of section.paragraphs">{{ paragraph }}</p>
                </div>
            </div>
            <div class="summary-right">
                <h6>{{ summary?.bulletList.title }}</h6>

                <ul>
                    <li *ngFor="let bullet of summary?.bulletList?.items">{{ bullet }}</li>
                </ul>
            </div>
        </div>
    </mat-card-content>
</mat-card>