<h5 mat-dialog-title>Select Survey</h5>

<mat-dialog-content>
    <kendo-grid
        [data]="surveys"
        [style.maxHeight.vh]="65"
        [selectable]="{
            enabled: true,
            mode: 'single'
        }"
        kendoGridSelectBy="id"
        [(selectedKeys)]="selectedItems"
    >
        <kendo-grid-checkbox-column [width]="80"></kendo-grid-checkbox-column>
        <kendo-grid-column field="title" title="Name"></kendo-grid-column>
        <kendo-grid-column field="type" title="Type"></kendo-grid-column>
        <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    </kendo-grid>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Cancel</button>

    <button class="add-all-selected-button" [disabled]="selectedItems.length === 0" mat-raised-button color="primary" appPreventDoubleClick (throttledClick)="addSurvey()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Add Survey
    </button>
</mat-dialog-actions>

