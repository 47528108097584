import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SurveyQuestion } from '@app/modules/survey-section/models/surveys.model';

@Component({
  selector: 'app-survey-editor-question',
  templateUrl: './survey-editor-question.component.html',
  styleUrls: ['./survey-editor-question.component.scss']
})
export class SurveyEditorQuestionComponent implements OnInit {
  @Input() question: SurveyQuestion;
  
  surveyQuestionDetails: FormGroup;

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.surveyQuestionDetails = this.fb.group({
      name: [this.question ? this.question.name : '', Validators.required],
      description: [this.question ? this.question.description : ''],
      type: [this.question ? this.question.type : '', Validators.required],
      options: new FormArray([])
    });

    this.question?.options?.forEach(
      option => {
        this.addControl(option);
      }
    )
  }

  addControl(option?: string) {
    const control = new FormControl(option ? option : '', Validators.required);
    (<FormArray>this.surveyQuestionDetails.get('options')).push(control);
  }
  
  removeControl(index: number) {
    (<FormArray>this.surveyQuestionDetails.get('options')).removeAt(index);
  }

}
