<app-letter-generator>
    <mat-card>
        <mat-card-title class="header">
            <h5>Letters</h5>
            <div>
                <button mat-flat-button setColor="primaryColour" color="primary" routerLink="{{routes.LETTERS}}{{routes.LETTERS_EDITOR}}">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create new letter
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <div *ngIf="!isLoading; else loadingTable">

                <app-local-data-grid-comp
                    [columns]="columns"
                    [data]="letters"
                    [isLoading]="isLoading"
                    [pageSize]="pageSize"
                    [pageable]="false"
                    category=""
                    [rowActionButtons]="gridActionButtons"
                    (rowActionEvent)="rowEvent($event)"
                ></app-local-data-grid-comp>


                <!-- <app-table
                    *ngIf="!isLoading"
                    [tableData]="letters"
                    [tableColumns]="tableColumns"
                    [rowActionIcon]="['create', 'delete']"
                    (rowAction)="rowEvent($event)"
                    [isFilterable]="true"
                ></app-table> -->
            </div>

            <ng-template #loadingTable>
                <app-skeleton-table></app-skeleton-table>
            </ng-template>
        </mat-card-content>
    </mat-card>
</app-letter-generator>

