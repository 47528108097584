<kendo-grid
    [data]="workflows"
    [style.maxHeight.vh]="65"
    [selectable]="{
        enabled: true,
        mode: 'single'
    }"
    kendoGridSelectBy="id"
    [(selectedKeys)]="selectedItems"
    (selectionChange)="selectionChange($event)"
>
    <kendo-grid-checkbox-column [width]="80"></kendo-grid-checkbox-column>
    <kendo-grid-column field="name" title="Name"></kendo-grid-column>
    <kendo-grid-column field="description" title="Description"></kendo-grid-column>
    <kendo-grid-column field="createdBy" title="Created By">
        <ng-template kendoGridCellTemplate let-dataItem >
            <div class="grid-cell-vertical employee">
                <app-employee-image [employeeId]="dataItem.createdBy.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
            
                <span class="employee">
                    {{dataItem.createdBy.firstName}} {{dataItem.createdBy.lastName}}
                </span>
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="createdOn" title="Created On">
        <ng-template kendoGridCellTemplate let-dataItem >
            {{ dataItem.createdOn | date:'mediumDate' }}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="" title="">
        <ng-template kendoGridCellTemplate let-dataItem >
            <button class="row-action-button" (click)="$event.stopPropagation()" mat-mini-fab [matMenuTriggerFor]="settings">
                <mat-icon class="settings-button__icon material-icons-outlined">more_vert</mat-icon>
            </button>
            <div class="settings-menu">
                <mat-menu #settings="matMenu" xPosition="before">
                    <button class="settings-menu__item material-icons-outlined" (click)="$event.stopPropagation(); openWorkflowDialog(dataItem)" mat-menu-item>
                        <mat-icon class="settings-button__icon material-icons-outlined">pageview</mat-icon>
                        View
                    </button>
                </mat-menu>
            </div>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>