<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>Goal Performance Report</h1>
    </mat-toolbar>

    <div style="display: flex; flex-wrap: wrap;">
        <div style="flex-basis: 25%;" class="graph-container">
            <mat-card>
                <mat-card-title>
                    <h5>Average Number of Goals</h5>
                </mat-card-title>

                <mat-card-content fxLayout="column" fxLayoutAlign="start">
                    <kendo-chart style="" class="chart">
                        <ng-template kendoChartDonutCenterTemplate>
                            <h2 class="k-mb-0">5</h2>
                            <span>per Employee</span>
                        </ng-template>
                        <kendo-chart-series-defaults type="donut"></kendo-chart-series-defaults>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                type="donut"
                                [holeSize]="holeSize"
                                [data]="activeGoalsData"
                                categoryField="category"
                                field="value"
                                [color]="getColor"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                    </kendo-chart>
                </mat-card-content>
            </mat-card>

        </div>
        <div style="flex-basis: 25%;" class="graph-container">
            <mat-card>
                <mat-card-title>
                    <h5>Overall Progress</h5>
                </mat-card-title>

                <mat-card-content fxLayout="column" fxLayoutAlign="start">
                    <kendo-chart style="" class="chart">
                        <ng-template kendoChartDonutCenterTemplate>
                            <h2 class="k-mb-0">29.1%</h2>
                        </ng-template>
                        <kendo-chart-series-defaults type="donut"></kendo-chart-series-defaults>
                        <kendo-chart-series>
                            <kendo-chart-series-item
                                type="donut"
                                [holeSize]="holeSize"
                                [data]="overallProgressData"
                                categoryField="category"
                                field="value"
                                [color]="getColor"
                            >
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                        <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                    </kendo-chart>
                </mat-card-content>
            </mat-card>
        </div>

        <div style="flex-basis: 50%;" class="graph-container">
            <mat-card>
                <mat-card-title>
                    <h5>Aligned Goals</h5>
                </mat-card-title>

                <mat-card-content fxLayout="row">
                    <div class="chart-container">
                        <kendo-chart style=";" class="chart">
                            <ng-template kendoChartDonutCenterTemplate>
                                <h2 class="k-mb-0">20%</h2>
                            </ng-template>
                            <kendo-chart-series-defaults type="donut"></kendo-chart-series-defaults>
                            <kendo-chart-series>
                                <kendo-chart-series-item
                                    type="donut"
                                    [holeSize]="holeSize"
                                    [data]="alignedGoalsData"
                                    categoryField="category"
                                    field="value"
                                    [color]="getColor"
                                >
                                </kendo-chart-series-item>
                            </kendo-chart-series>
                            <kendo-chart-legend [visible]="false"></kendo-chart-legend>
                        </kendo-chart>
                    </div>

                    <div class="text-column">
                        <ul>
                            <li>Aligned to Organization</li>
                            <li>Aligned to Department</li>
                            <li>Aligned to Business Unit</li>
                            <li>Aligned to Other Goals</li>
                        </ul>
                    </div>
                </mat-card-content>

            </mat-card>
        </div>

        <div style="flex-basis: 50%;" class="graph-container">
            <mat-card>
                <mat-card-title>
                    <h5>Check-in Activity</h5>
                </mat-card-title>

                <mat-card-content fxLayout="column" fxLayoutAlign="start" style="height: 400px">
                    <div>
                        <apx-chart
                            [series]="chartOptions.series"
                            [chart]="chartOptions.chart"
                            [xaxis]="chartOptions.xaxis"
                            [stroke]="chartOptions.stroke"
                            [dataLabels]="chartOptions.dataLabels"
                            [yaxis]="chartOptions.yaxis"
                            [labels]="chartOptions.labels"
                            [legend]="chartOptions.legend"
                        ></apx-chart>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>

        <div style="flex-basis: 50%;" class="graph-container">
            <mat-card>
                <mat-card-title>
                    <h5>Goal Achievement</h5>
                </mat-card-title>

                <mat-card-content fxLayout="column" fxLayoutAlign="start" style="height: 400px">

                    <kendo-chart [categoryAxis]="{ categories: ['0-1%', '1%-25%', '25-50%', '50-75%', '75-99%', '100%'], majorGridLines: { visible: false } }">
                        <kendo-chart-series>
                            <kendo-chart-series-item type="column" [data]="[1, 50, 30, 40, 10, 0]" color="#69f5db" [border]="{ width: 0 }">
                            </kendo-chart-series-item>
                        </kendo-chart-series>
                    </kendo-chart>

                </mat-card-content>
            </mat-card>
        </div>


    </div>

</app-layout>
