<app-site-settings-menu>
    <mat-card *ngIf="hasFeatureAccess">
        <mat-card-title class="header">
            <h5>{{ 'JobManagement' | translate: {Default: "Job Management"} }}</h5>
            <div>
<!--                <button mat-stroked-button color="primary" (click)="redirectOrgChart()" class="k-mr-3" *appHasFeature="features.POSITIONS_REPORT">-->
<!--                    Show Position Chart-->
<!--                </button>-->
                <button mat-flat-button setColor="primaryColour" color="primary" routerLink="{{routes.SITE_SETTINGS}}/{{routes.JOB_MANAGEMENT}}{{routes.EDITOR}}">
                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> Create New Job
                </button>
            </div>
        </mat-card-title>
        <mat-card-content>
            <app-data-grid-comp
                *ngIf="hasFeatureAccess"
                [columns]="columns"
                [bindingType]="bindingType"
                [view]="view"
                [gridData]="gridData"
                [gridDataResult]="gridDataResult"
                [isLoading]="isLoading"
                [pageSize]="pageSize"
                [skip]="skip"
                canSelectRows="true"
                clickableRows="true"
                searchable="true"
                hideActionButtons="true"
                [filterCategories]="filterCategories"
                [clearSelectedItems]="clearSelectedItems"
                (emitPageChangeEvent)="pageChange($event)"
                (emitOpenDialogEvent)="navigateToJob($event)"
                (emitSearchEvent)="search($event)"
                (emitDeleteEvent)="deleteAllSelected($event)"
                (emitFilterEvent)="filterCallback($event)"
            ></app-data-grid-comp>
        </mat-card-content>
    </mat-card>

    <no-feature-access *ngIf="!hasFeatureAccess"></no-feature-access>
</app-site-settings-menu>
