import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-data-template-upload',
  templateUrl: './data-template-upload.component.html',
  styleUrls: ['./data-template-upload.component.scss']
})
export class DataTemplateUploadComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  uploadProgress: number = 0;
  currentFile?: File;
  progress = 0;
  message = '';
  parentDirectory: string;
  name: string;
  fileName = 'Select File';
  fileInfos?: Observable<any>;
  form: UntypedFormGroup;
  directories$: Observable<any>;
  uploadApiPath: any;
  uploadInProgress: boolean = false;
  progressInterval: any;
  showTickAnimation: boolean = false;

  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
    } else {
      this.fileName = 'Select File';
    }
  }

  onFileChange(event: any) {
    const files: FileList = event.target.files;
    // Process the uploaded file here
  }

  uploadFakeFile() {
    if (this.uploadInProgress) {
      return; // Prevent multiple uploads at once
    }
    
    // Create a fake file object
    const file = new File(['Fake File Content'], this.currentFile.name, { type: 'text/plain' });

    // Assign the fake file object to the file input
    const inputElement: HTMLInputElement = this.fileInput.nativeElement;
    const fileList = new DataTransfer();
    fileList.items.add(file);
    inputElement.files = fileList.files;

    // Simulate upload progress
    let progress = 0;
    this.uploadInProgress = true;
    this.progressInterval = setInterval(() => {
      progress += 10;
      if (progress >= 100) {
        progress = 100;
        clearInterval(this.progressInterval);
        this.uploadInProgress = false;
        this.showTickAnimation = true;
      }
      this.uploadProgress = progress;
    }, 100);
    
    // Trigger the change event manually
    const changeEvent = new Event('change', { bubbles: true });
    inputElement.dispatchEvent(changeEvent);
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
