import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ViewChild } from "@angular/core";
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke
} from "ng-apexcharts";
import { finalize, from } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  colors: string[];
};

@Component({
  selector: 'app-average-salary-by-departments-and-gener-bar-chart',
  templateUrl: './average-salary-by-departments-and-gener-bar-chart.component.html',
  styleUrls: ['./average-salary-by-departments-and-gener-bar-chart.component.scss']
})
export class AverageSalaryByDepartmentsAndGenerBarChartComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  
  isLoading: boolean;
  departments: any[];
  showChart: boolean;

  constructor(
    private lookupService: LookupService,
    public currencyPipe: CurrencyPipe
  ) {
    this.chartOptions = {
      series: [
        {
          name: "Male",
          data: []
        },
        {
          name: "Female",
          data: []
        }
      ],
      chart: {
        type: "bar",
        height: 600,
      },
      colors: ["#008FFB", "#FF4560"],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: (val) => {
          return `$${this.numberWithCommas(val)}`;
        },
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: function(val) {
            return `$${val}`
            // return `$${this.numberWithCommas(val)}`;
          }
        }
      }
    };

    setTimeout(
      () => {
        window.dispatchEvent(new Event('resize'));
      }, 1000
    )
  }

  ngOnInit(): void {
    this.getDepartments();
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  getDepartments() {
    from(this.lookupService.getListOptions('DEPARTMENT'))
    .pipe(
      finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.departments = res.data;
        this.populateChart();
      }
    )
  }

  populateChart() {
    this.assignRandomValues();

    this.departments.forEach(
      department => {
        this.chartOptions.xaxis.categories.push(department.text);
        this.chartOptions.series[0].data.push(this.generateRandomNumber());
        this.chartOptions.series[1].data.push(this.generateRandomNumber());
        // this.chartOptions.labels.push(department.text);
      }
    )

    this.showChart = true;
  }

  generateRandomNumber(): any {
    const min = 20000;
    const max = 200000;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  assignRandomValues() {
    let remainingTotal = 100;
    let lastIndex = this.departments.length - 1;
  
    // Assign random values to objects in the array
    for (var i = 0; i < lastIndex; i++) {
      let maxValue = Math.min(remainingTotal, 15); // Maximum value is 15 or remaining total, whichever is smaller
      let randomValue = Math.floor(Math.random() * maxValue) + 1;
      this.departments[i].value = randomValue;
      remainingTotal -= randomValue;
    }
  
    // Assign the remaining total to the last object
    this.departments[lastIndex].value = remainingTotal;
  }

}
