import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { miscellaneous, routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import { finalize, map } from 'rxjs';
@Component({
  selector: 'app-succession-profile',
  templateUrl: './succession-profile.component.html',
  styleUrls: ['./succession-profile.component.scss']
})
export class SuccessionProfileComponent implements OnInit {
  public miscellaneous: typeof miscellaneous = miscellaneous;
  
  employeeId: string;
  employeeContactCard$: any;
  step: string = 'Pending';
  image;
  employees;
  employee: any;

  promotionReadiness = [
    {
      readiness: 'Immediate (Ready Now)',
      comments: 'They can step into the role on short notice.'
    },
    {
      readiness: 'Within 1 Year',
      comments: 'Just needs a bit more experience.'
    },
    {
      readiness: 'Within 2 Year',
      comments: 'They are a keen leader and would be a great successor.'
    },
    {
      readiness: 'Too New to Rate',
      comments: 'They appear to have potential but it is too soon to give an informed opinion.'
    },
  ]
  isLoading: boolean;
  successors: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private employeeContactCardService: EmployeeContactCardService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employeeService: EmployeeService,
  ) { 
    (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnInit(): void {
    this.employeeId = this.getIdInURL();
    this.employeeContactCard$ = this.employeeContactCardService.getEmployeeContactCard(this.employeeId);
    this.getEmployees();
    this.getEmployeeImage();
    this.getEmployeeDetails();
  }

  getEmployeeImage() {
    this.employeeService.getEmployeeProfilePicture(this.employeeId)
    .subscribe(
      res => {
        this.image = res;
      }
    )
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
        params => IdInURL = params.get('employeeId')
    );

    return IdInURL;
  }

  getEmployeeDetails(){
    this.employeeContactCard$
    .subscribe(
      res => {
        this.employee = res;
      }
    )
  }

  getEmployees(): void {
    this.isLoading = true;

    this.employeeContactCardService.getAllEmployeeContactCards(0, '100', null, null, true)
    .pipe(
      finalize(()=>{ 
        this.isLoading = false; 
        this.getSuccessorImages();
      }),
      map(
        res => {
          this.employees = res.data;
          this.employees = this.employees.filter(item => item.id !== this.employeeId);

          const modifiedItems = res.data.map(
            employee => {
              let randomEmployee = this.getRandomItem(this.employees)
              let randomEmployee2 = this.getRandomItem(this.employees)
              let promotionReadiness = this.getRandomItem(this.promotionReadiness)

              return {
                successor: {
                  id: randomEmployee?.id,
                  firstName: randomEmployee?.firstname,
                  lastName: randomEmployee?.lastname
                },
                readiness: promotionReadiness.readiness,
                nominatedBy: {
                  id: randomEmployee2?.id,
                  firstName: randomEmployee2?.firstname,
                  lastName: randomEmployee2?.lastname
                },
                comments: promotionReadiness.comments,
                position: randomEmployee?.positions[0]?.name ?? '',
                workLocation: randomEmployee?.workLocations[0]?.name ?? '',
                organization: randomEmployee?.organization?.name ?? '',
              };
            }
          );

          res.data = modifiedItems;
          return res
        }
      )
    )
    .subscribe(
      res => {
        this.successors = [
          res.data[0], 
          res.data[1],
          res.data[2],
          res.data[3],
          res.data[4]
        ]
      }
    )
  }

  getSuccessorImages() {
    this.successors.forEach(
      successor => {
        this.employeeService.getEmployeeProfilePicture(successor.successor.id)
        .subscribe(
          res => {
            successor.image = res;
          },
          async err => {
            successor.image = await this.getBase64ImageFromURL(miscellaneous.STOCK_EMPLOYEE_IMAGE);
          }
        )
      }
    );
  }

  getRandomItem(array) {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  }

  updateStep(step: string){
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
      this.step = step
    }, 2000);

  }

  save(){
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
    }, 2000);
  }

  back(){
    this.router.navigate([`${routes.SUCCESSION_TRACK}${routes.SUCCESSION_PROFILES}`], {relativeTo: this.route});
  }

  generatePdf() {
    const documentDefinition = {
      content: [
        {
          width: 'auto',
          text: `Succession Chart: ${this.employee.firstname} ${this.employee.lastname}`,
          style: 'header',
        },
        {
          alignment: 'justify',
          columns: [
            {
              image: this.image,
              width: 100
            },
            [
              {
                width: 'auto',
                text: `${this.employee.firstname} ${this.employee.lastname}`,
                style: 'header',
              },
              {
                columns: [
                  [
                    {
                      text: 'Organisation',
                    },
                    {
                      text: 'Originial Hire Date'
                    },
                    {
                      text: 'Current Role Date'
                    },
                    {
                      text: 'Education'
                    },
                    {
                      text: 'Age'
                    }
                  ],
                  [
                    {
                      text: `${this.employee?.organization?.name}`,
                    },
                    {
                      text: 'Originial Hire Date'
                    },
                    {
                      text: 'Current Role Date'
                    },
                    {
                      text: 'Education'
                    },
                    {
                      text: 'Age'
                    }
                  ]
                ]
              }
            ]
          ],
          margin: [0, 20],
        },
        this.getEmployeesInReadinessCategory(this.promotionReadiness[0].readiness),
        this.getEmployeesInReadinessCategory(this.promotionReadiness[1].readiness),
        this.getEmployeesInReadinessCategory(this.promotionReadiness[2].readiness),
        this.getEmployeesInReadinessCategory(this.promotionReadiness[3].readiness),
      ],
      images: {
        building: this.image
      },
      styles: {
        header: {
          fontSize: 18,
          bold: true
        },
        subheader: {
          fontSize: 15,
          bold: true
        },
        subsubheader: {
          fontSize: 12,
          bold: true
        },
        quote: {
          italics: true
        },
        small: {
          fontSize: 10
        }
      },
      defaultStyle: {
        columnGap: 20
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  }

  getEmployeesInReadinessCategory(readinessCategory: string){
    let successors = this.successors.filter(item => item.readiness === readinessCategory)
    let result = []

    if(successors.length > 0){
      result.push({
        width: 'auto',
        text: readinessCategory,
        style: 'subheader',
        margin: [0, 20],
      },)

      successors.forEach(
        s => {
          result.push(
            {
              alignment: 'justify',
              unbreakable: true,
              columns: [
                {
                  image: s?.image,
                  width: 80
                },
                [
                  {
                    width: 'auto',
                    text: `${s.successor.firstName} ${s.successor.lastName}`,
                    style: 'subsubheader',
                    margin: [0, 10],
                  },
                  {
                    columns: [
                      [
                        {
                          text: 'Position',
                          style: 'small'
                        },
                        {
                          text: 'Work Location',
                          style: 'small'
                        },
                        {
                          text: 'Organisation',
                          style: 'small'
                        }
                      ],
                      [
                        {
                          text: `${s?.position}`,
                          style: 'small'
                        },
                        {
                          text: `${s?.workLocation}`,
                          style: 'small'
                        },
                        {
                          text: `${s?.organization}`,
                          style: 'small'
                        },
                      ]
                    ]
                  }
                ]
              ],
              margin: [0, 15],
            }
          )
        }
      )

      // prevent section from splitting between pages
      result = [
        {
          alignment: 'justify',
          unbreakable: true,
          columns: [result]
        }
      ]
    }

    return result;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;
    });
  }

}
