import { Component, OnInit, ViewChild } from "@angular/core";
import { ApexAxisChartSeries, ApexChart, ApexFill, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  labels: string[];
  stroke: any; // ApexStroke;
  dataLabels: any; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-actual-vs-benchmark',
  templateUrl: './actual-vs-benchmark.component.html',
  styleUrls: ['./actual-vs-benchmark.component.scss']
})
export class ActualVsBenchmarkComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Actual",
          type: "column",
          data: [22.25, 22.70, 21.05, 18.90, 15.30]
        },
        {
          name: "Benchmark",
          type: "line",
          data: [29.10, 28.50, 27.60, 26.50, 25.10]
        }
      ],
      chart: {
        height: 350,
        type: "line"
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: ""
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: [
        "Customer Service",
        "R&D",
        "Marketing",
        "Mobile/Web",
        "IT",
      ],

    };
  }

  ngOnInit(): void {
  }

}
