<app-workflow-section>
    <mat-card>
        <mat-card-title class="header">
            <h5>{{ 'PublishJourney' | translate: {Default: "Publish Journey"} }}</h5>
        </mat-card-title>
        <mat-card-content>
            <div>
                <mat-tab-group class="my-mat-tab-group" setColor="primaryColour" [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChange($event)">
                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">1</span> SELECT A JOURNEY </ng-template>
                        <div class="tab-container">
                            <app-workflow-publish-editor-select-workflow
                                [selectedWorkflow]="selectedWorkflow"
                                (selectedWorkflowChanged)="updatedSelectedWorkflow($event)"
                            ></app-workflow-publish-editor-select-workflow>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">2</span> SELECT PARTICIPANTS </ng-template>
                        <div class="tab-container">
                            <app-workflow-publish-editor-select-employees
                                *ngIf="!loadingEmployees"
                                [selectedEmployees]="selectedParticipants"
                                (emitSelectedEmployees)="updateSelectedParticipants($event)"
                            ></app-workflow-publish-editor-select-employees>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">3</span> SELECT OWNERS </ng-template>
                        <div class="tab-container">
                            <app-workflow-publish-editor-select-employees
                                *ngIf="!loadingEmployees"
                                [selectedEmployees]="selectedOwners"
                                (emitSelectedEmployees)="updateSelectedOwners($event)"
                            ></app-workflow-publish-editor-select-employees>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">4</span> ADD DETAILS </ng-template>
                        <div class="tab-container">
                            <form class="workflow-publish-details-form" [formGroup]="workflowPublishDetails">
                                <h5>{{ 'JourneyPublishDetails' | translate: {Default: "Publish Journey Details"} }}</h5>
            
                                <mat-form-field appearance="fill">
                                    <mat-label>Name</mat-label>
            
                                    <input matInput type="text" formControlName="name">
                                </mat-form-field>
            
                                <mat-form-field appearance="fill">
                                    <mat-label>Description</mat-label>
            
                                    <textarea matInput formControlName="description"></textarea>
                                </mat-form-field>

                                <mat-form-field  appearance="fill">
                                    <mat-label>Start Date</mat-label>

                                    <input matInput [matDatepicker]="pickerStartDate" formControlName="startDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="pickerStartDate"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #pickerStartDate></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field  appearance="fill">
                                    <mat-label>End Date</mat-label>

                                    <input matInput [matDatepicker]="pickerEndDate" formControlName="endDate">
                                    <span fxLayout="row" matSuffix>
                                        <mat-datepicker-toggle  [for]="pickerEndDate"></mat-datepicker-toggle>
                                    </span>
                                    <mat-datepicker #pickerEndDate></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Status</mat-label>
                        
                                    <mat-select disableOptionCentering formControlName="status">
                                        <mat-option value="complete">Complete</mat-option>
                                        <mat-option value="inProgress">In Progress</mat-option>
                                        <mat-option value="cancelled">Cancelled</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </form>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">5</span> REVIEW AND PUBLISH </ng-template>
                        <div class="tab-container review-section">

                            <div class="review-sub-section details">
                                <h5>Publish Journey Details</h5>

                                <div class="name">{{workflowPublishDetails.get('name').value}}</div>

                                <div class="description">{{workflowPublishDetails.get('description').value}}</div>
                                <div class="start-date">
                                    <span class="label">Start Date</span> 
                                    {{workflowPublishDetails.get('startDate').value | date:'mediumDate'}}
                                </div>
                                <div class="end-date">
                                    <span class="label">End Date</span>
                                    {{workflowPublishDetails.get('endDate').value | date:'mediumDate'}}
                                </div>

                                <mat-chip-list>
                                    <mat-chip class="green-chip" *ngIf="workflowPublishDetails.get('status').value === 'complete'">Complete</mat-chip>
                                    <mat-chip class="red-chip" *ngIf="workflowPublishDetails.get('status').value === 'cancelled'">Cancelled</mat-chip>
                                    <mat-chip class="yellow-chip" *ngIf="workflowPublishDetails.get('status').value === 'inProgress'">In Progress</mat-chip>
                                </mat-chip-list>
                            </div>

                            <div class="review-sub-section selected-journey">
                                <h5>Journey</h5>

                                <h6>{{selectedWorkflow?.name}}</h6>
                            </div>
                            
                            <div class="review-sub-section selected-participants employees">
                                <h5>Participants</h5>

                                <ng-container *ngFor="let employee of selectedParticipants">
                                    <div class="employee">
                                        <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                
                                        <span class="employee-name">
                                            {{employee.firstname}} {{employee.lastname}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="review-sub-section selected-owners employees">
                                <h5>Owners</h5>

                                <ng-container *ngFor="let employee of selectedOwners">
                                    <div class="employee">
                                        <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                
                                        <span class="employee-name">
                                            {{employee.firstname}} {{employee.lastname}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button class="continue-button" setColor="primaryColour" mat-flat-button color="primary" (click)="navigationButtonClicked(true)">{{ selectedIndex === 4 ? 'Publish' : 'Continue' }}</button>
            <button mat-button [disabled]="selectedIndex === 0" (click)="navigationButtonClicked(false)">Previous Step</button>
          </mat-card-actions>
    </mat-card>
</app-workflow-section>

