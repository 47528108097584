import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { Workflow, WorkflowCategory } from '../../models/workflows.model';
import { WorkflowsService } from '../../services/workflows.service';

@Component({
  selector: 'app-workflows-editor',
  templateUrl: './workflows-editor.component.html',
  styleUrls: ['./workflows-editor.component.scss']
})
export class WorkflowsEditorComponent implements OnInit {

  public routes: typeof routes = routes;

  workflowDetails: FormGroup;
  workflow: Workflow;
  categories: WorkflowCategory[] = [];
  isLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,  
    private route: ActivatedRoute,
    private workflowsService: WorkflowsService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if(this.getIdInURL()) {
      this.getWorkflow(this.getIdInURL());
    }
    else {
      this.buildForm();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get('workflowId')
    );

    return IdInURL;
  }

  getWorkflow(workflowId: string) {
    this.workflowsService.getWorkflow(workflowId)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
        res => {
          this.workflow = res;
          this.buildForm();
        }
    );
  }

  buildForm() {
    this.workflowDetails = this.fb.group({
      name: [this.workflow ? this.workflow.name : '', Validators.required],
      description: [this.workflow ? this.workflow.description : '', Validators.required],
    });

    this.categories = this.workflow?.categories;

    if(this.categories === undefined || this.categories.length < 1) {
      this.categories = [];
      this.addNewCategory();
    }
  }

  addNewCategory() {
    let newQuestion: WorkflowCategory = {
      id: null,
      name: null,
      description: null,
      tasks: []
    }

    this.categories.push(newQuestion);
  }

  removeCategory(index: number) {
    this.categories.splice(index, 1);
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    }, 500)
  }

}
