<app-performance>
  <ng-container *ngIf="isLoading === true; else loaded">
    <ngx-skeleton-loader
        count="5"
        [theme]="{ 
            'height.px': 50
        }"
    ></ngx-skeleton-loader>
  </ng-container>
  <ng-template #loaded>
    <ng-container *ngIf="error === true; else noError">ERROR</ng-container>
    <ng-template #noError>
      <mat-card class="mat-elevation-z0">
          <mat-card-title class="header">
            <h5 mat-dialog-title>{{ 'Review' | translate: {Default: 'Review'} }} - {{review.reviewPlan?.name}}</h5>
            <mat-chip 
              [ngClass]="
                review?.status?.id === 'Complete' ? 'green-chip' : 
                review?.status?.id === 'Archived' ? 'red-chip' :
                review?.status?.id === 'InProgress' ? 'orange-chip' :
                review?.status?.id === 'Pending' ? 'blue-chip' :
                ''
              "
            >{{ review?.status?.name }}</mat-chip>
        
            <div class="k-flex-grow"></div>

            <button mat-stroked-button class="export-to-pdf-button" (click)="saveAsPdf()">
              <img
                  class="ms-icon"
                  src="assets/icons/pdf-icon.svg"
                  style="width: 30px;
                              height: 30px;
                              vertical-align: middle;">
              {{ 'SaveAsPdf' | translate: {Default: "Save As PDF"} }}
            </button>
      
            <button class="mat-stroked-button" appPreventDoubleClick (throttledClick)="navigateBack()">{{ 'Close' | translate: {Default: "Close"} }}</button>
  
            <div *ngIf="review?.status?.id === 'InProgress'">
              <button 
                  *ngIf="review.currentUser.isReviewPlanOwner"
                  appLocalizationPopupDirective 
                  localizationCode="RevertReview" 
                  mat-stroked-button 
                  setColor="primaryColour"
                  color="primary" 
                  appPreventDoubleClick 
                  (throttledClick)="revertReview()"
              >{{ 'RevertReview' | translate: {Default: "Revert Review"} }}</button>  

              <button 
                  *ngIf="review.currentUser.isPendingForReview"
                  appLocalizationPopupDirective 
                  localizationCode="CompleteReview" 
                  mat-flat-button 
                  setColor="primaryColour"
                  color="primary" 
                  appPreventDoubleClick 
                  (throttledClick)="openConfirmationWarning()"
              >{{ 'CompleteReview' | translate: {Default: "Complete Review"} }}</button>  
            </div>
          </mat-card-title>
      
          <mat-card-content class="">
            <ng-container *ngIf="isLoading; else loaded">
              <ngx-skeleton-loader
                count="5"
                [theme]="{ 
                    'height.px': 50
                }"
              ></ngx-skeleton-loader>
            </ng-container>
      
            <ng-template #loaded>
              <div class="top-row">
                <app-employee-contact-card [employeeId]="review?.employee?.id"></app-employee-contact-card>
      
                <mat-card class="review-plan">
                    <mat-card-title>
                        <h6 appLocalizationPopupDirective localizationCode="Review-Plan" >{{ 'Review-Plan' | translate: {Default: 'Review Plan'} }}</h6>
                    </mat-card-title>
      
                    <mat-card-content>
                      <div>
                        <div class="label">{{getLabel('tfi_RpName')}}</div>
                        <div class="value">{{review.reviewPlan?.name}}</div>
                      </div>
                      <div>
                        <div class="label">{{getLabel('tfi_RpDescription')}}</div>
                        <div class="value">{{review.reviewPlan?.description}}</div>
                      </div>
                      <div>
                        <div class="label">{{getLabel('tfi_RpStartDate')}}</div>
                        <div class="value">{{review.reviewPlan?.startDate | date:'mediumDate'}}</div>
                      </div>
                      <div>
                        <div class="label">{{getLabel('tfi_RpEndDate')}}</div>
                        <div class="value">{{review.reviewPlan?.endDate | date:'mediumDate'}}</div>
                      </div>
                    </mat-card-content>
                </mat-card>
              </div>
  
              <div class="bottom-row">
                <mat-tab-group class="mat-tab-group">
  
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Review-Reviews">
                        {{ 'Review-Reviews' | translate: {Default: 'Reviews'} }}

                        <mat-icon *ngIf="review.allGoalPlansReviewStatus === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                        <mat-icon *ngIf="review.allGoalPlansReviewStatus === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>

                      </div>
                    </ng-template>

                    <app-review-goals-section 
                      [review]="review" 
                      (saveGoalPlanReview)="saveGoalPlanReview($event)" 
                      (saveGoalReview)="saveGoalReview($event)"
                    ></app-review-goals-section>
                  </mat-tab>
  
                  <mat-tab *ngIf="review?.reviews !== null && review?.reviews.length > 0">
                    <ng-template mat-tab-label>
                      <div class="mat-tab-header" appLocalizationPopupDirective localizationCode="Review-RatingsAndComments">
                        {{ 'Review-RatingsAndComments' | translate: {Default: 'Ratings & Comments'} }}

                        <mat-icon *ngIf="review.overallReviewStatus === 'reviewed'" class="complete material-icons-outlined" [matTooltip]="translate.instant('Reviewed')">check_circle</mat-icon>
                        <mat-icon *ngIf="review.overallReviewStatus === 'needsReview'" class="need-input material-icons-outlined" [matTooltip]="translate.instant('NeedsInput')">feedback</mat-icon>

                      </div>
                    </ng-template>
                    
                    <mat-card class="ratings-and-comments">
                      <mat-card-title>
                          <h6>{{ 'Review-RatingsAndComments' | translate: {Default: 'Ratings & Comments'} }}</h6>
                      </mat-card-title>
          
                      <mat-card-content>
                        <div class="reviews">
                          <div *ngFor="let r of review.reviews; let last = last">
                            <app-overall-rating-comment
                              class="review"
                              [reviewPlanId]="review.reviewPlan.id"
                              [review]="r"
                              [reviewerPermissions]="getReviewerPermissions(r.reviewedBy.id)"
                              (saveReview)="saveReview($event)"
                            ></app-overall-rating-comment>
                            <mat-divider *ngIf="!last"></mat-divider>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </mat-tab>
  
                  <mat-tab>
                    <ng-template mat-tab-label>
                      <div appLocalizationPopupDirective localizationCode="Review-Reviewers">
                        {{ 'Review-Reviewers' | translate: {Default: 'Reviewers'} }}
                      </div>
                    </ng-template>

                    <app-local-data-grid-comp
                        [tableId]="db_tables.PerformanceReviewPlanReviewers"
                        [category]="translate.instant('Review-Reviewers')"
                        class="reviewers-data-grid"
                        [columns]="reviewerColumns"
                        [data]="review?.reviewers"
                        [isLoading]="isLoading"
                        [pageable]="false"
                    ></app-local-data-grid-comp>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </ng-template>
          </mat-card-content>
      </mat-card>
    </ng-template>
  </ng-template>

</app-performance>
