<!-- <h5 
    mat-dialog-title
    appLocalizationPopupDirective 
    [localizationCode]="'UploadProfilePicture'"
>
    {{ 'UploadProfilePicture' | translate: {Default: "Upload Profile Picture"} }}
</h5> -->

<mat-dialog-content>
    <app-upload-profile-image-comp [employeeID]="employeeID"></app-upload-profile-image-comp>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
</mat-dialog-actions>

