import { Component, OnInit, ViewChild } from '@angular/core';
import { routes } from '@app/consts';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';
import { finalize } from 'rxjs';
import { Integration } from '../../models/integration.model';
import { IntegrationsService } from '../../services/integrations.service';

@Component({
  selector: 'app-integration-analytics',
  templateUrl: './integration-analytics.component.html',
  styleUrls: ['./integration-analytics.component.scss']
})
export class IntegrationAnalyticsComponent implements OnInit {
  public routes: typeof routes = routes;
  
  chartOptions: ApexOptions = {
    series: [],
    chart: {
       height: 350,
       type: "line",
    },
    xaxis: {
       type: "datetime",
    },
  };

  failedChartOptions: ApexOptions = {
    series: [],
    chart: {
       height: 350,
       type: "line",
    },
    xaxis: {
       type: "datetime",
    },
    colors: ['#FF0000']
  };

  loadingIntegration: boolean = true;
  integration: Integration;

  constructor(
    private integrationsService: IntegrationsService
  ) { }

  ngOnInit(): void {
    this.getIntegration('12213123');

    this.chartOptions.series = [
      {
         name: "Requests",
         data: [
          {
             "x": 1614086400000,
             "y": 104
          },
          {
             "x": 1614172800000,
             "y": 97
          },
          {
             "x": 1614259200000,
             "y": 89
          },
          {
             "x": 1614345600000,
             "y": 121
          },
          {
             "x": 1614432000000,
             "y": 133
          },
          {
             "x": 1614518400000,
             "y": 120
          },
          {
             "x": 1614604800000,
             "y": 98
          },
          {
             "x": 1614691200000,
             "y": 91
          },
          {
             "x": 1614777600000,
             "y": 102
          },
          {
             "x": 1614864000000,
             "y": 112
          },
          {
             "x": 1614950400000,
             "y": 107
          },
          {
             "x": 1615036800000,
             "y": 119
          },
          {
             "x": 1615123200000,
             "y": 101
          },
          {
             "x": 1615209600000,
             "y": 85
          },
          {
             "x": 1615296000000,
             "y": 99
          },
          {
             "x": 1615382400000,
             "y": 111
          },
          {
             "x": 1615468800000,
             "y": 122
          },
          {
             "x": 1615555200000,
             "y": 132
          },
          {
             "x": 1615641600000,
             "y": 117
          },
          {
             "x": 1615728000000,
             "y": 105
          },
          {
             "x": 1615814400000,
             "y": 94
          },
          {
             "x": 1615900800000,
             "y": 108
          },
          {
             "x": 1615987200000,
             "y": 115
          },
          {
             "x": 1616073600000,
             "y": 122
          },
          {
             "x": 1616160000000,
             "y": 126
          },
          {
             "x": 1616246400000,
             "y": 118
          },
          {
             "x": 1616332800000,
             "y": 103
          },
          {
             "x": 1616419200000,
             "y": 94
          },
          {
             "x": 1616505600000,
             "y": 110
          },
          {
             "x": 1616592000000,
             "y": 119
          },
          {
             "x": 1616678400000,
             "y": 132
          },
          {
             "x": 1616764800000,
             "y": 126
          },
          {
             "x": 1616851200000,
             "y": 116
          },
          {
             "x": 1616937600000,
             "y": 156
          }
        ]
      },
    ];

    this.failedChartOptions.series = [
      {
        name: "Requests",
        data: [
          {
            "x": 1615440000000,
            "y": 102
          },
          {
            "x": 1615526400000,
            "y": 67
          },
          {
            "x": 1615612800000,
            "y": 89
          },
          {
            "x": 1615699200000,
            "y": 123
          },
          {
            "x": 1615785600000,
            "y": 98
          },
          {
            "x": 1615872000000,
            "y": 76
          },
          {
            "x": 1615958400000,
            "y": 109
          },
          {
            "x": 1616044800000,
            "y": 97
          },
          {
            "x": 1616131200000,
            "y": 115
          },
          {
            "x": 1616217600000,
            "y": 87
          },
          {
            "x": 1616304000000,
            "y": 94
          },
          {
            "x": 1616390400000,
            "y": 101
          },
          {
            "x": 1616476800000,
            "y": 78
          },
          {
            "x": 1616563200000,
            "y": 102
          },
          {
            "x": 1616649600000,
            "y": 119
          },
          {
            "x": 1616736000000,
            "y": 92
          },
          {
            "x": 1616822400000,
            "y": 81
          },
          {
            "x": 1616908800000,
            "y": 111
          },
          {
            "x": 1616995200000,
            "y": 85
          },
          {
            "x": 1617081600000,
            "y": 97
          },
          {
            "x": 1617168000000,
            "y": 104
          },
          {
            "x": 1617254400000,
            "y": 90
          },
          {
            "x": 1617340800000,
            "y": 75
          },
          {
            "x": 1617427200000,
            "y": 99
          },
          {
            "x": 1617513600000,
            "y": 83
          },
          {
            "x": 1617600000000,
            "y": 113
          },
          {
            "x": 1617686400000,
            "y": 91
          },
          {
            "x": 1617772800000,
            "y": 107
          },
          {
            "x": 1617859200000,
            "y": 84
          },
          {
            "x": 1617945600000,
            "y": 116
          },
          {
            "x": 1618032000000,
            "y": 82
          },
          {
            "x": 1618118400000,
            "y": 98
          },
          {
            "x": 1618204800000,
            "y": 74
          },
          {
            "x": 1618291200000,
            "y": 82
          }
        ]
      }
    ]

    setTimeout(
      () => {
        window.dispatchEvent(new Event('resize'));
      }, 1000
    )
   
  }

  getIntegration(id: string) {
    this.loadingIntegration = true;

    this.integrationsService.getIntegration(id)
    .pipe(
      finalize(() => {
        this.loadingIntegration = false;
        // this.createWorkflowPublishDetailsForm();
      })
    )
    .subscribe(
        res => {
          this.integration = res;
        }
    );
  }

}
