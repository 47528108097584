import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-gender-pay-gap-report',
  templateUrl: './gender-pay-gap-report.component.html',
  styleUrls: ['./gender-pay-gap-report.component.scss']
})
export class GenderPayGapReportComponent implements OnInit {
  report = {
    title: 'Gender Pay Gap Report',
    description: null,
    ordinaryPay: {
      title: 'Ordinary Pay',
      description: `Gender pay gap - the difference between women's pay and men's pay as a percentage of men's pay.`,
      mean: '7%',
      median: '10%',
    },
    hourlyRateComparisonData: {
      title: 'Hourly Rate Comparison',
      description: 'This chart shows the mean and median hourly',
      currency: 'GBP',
      categories: ['Women', 'Men'],
      meanHourlyRate: [23.47, 25.25],
      medianHourlyRate: [21.37, 23.62],
    },
    proportionData: {
      title: 'Proportion of female and male employees',
      data: [
        { category: "Male", value: 35 },
        { category: "Women", value: 65 },
      ]
    },
    oneOffContributions: {
      description: 'The company does not pay bonuses. To comply with the regulations, we have used any pay that is paid annually or less frequently than monthly as a `one-off contribution` to calculate the equivalent of bonus pay. This includes one-off `rewarding your contribution` payments and long service awards.',
      data: [
        {
          title: 'One-off pay',
          description: `One-off payments gender pay gap - the difference between women's payments and men's payments as a % of men's payments.`,
          left: {
            label: 'Mean',
            value: '-3%',
          },
          right: {
            label: 'Median',
            value: '0%',
          }
        },
        {
          title: 'One-off payments made',
          description: null,
          left: {
            label: 'Women paid as a % of all women',
            value: '14%',
          },
          right: {
            label: 'Men paid as a % of all men',
            value: '13%',
          }
        }
      ]
    },
    payByQuartile: [
      {
        title: 'Upper quartile',
        description: 'Proportion of women and men in the upper quartile (paid above the 75th percentile point)',
        data: [
          { category: "Male", value: 47 },
          { category: "Women", value: 53 },
        ]
      },
      {
        title: 'Upper middle quartile',
        description: 'Proportion of women and men in the upper middle quartile (paid above the median and at or below the 75th percentile point)',
        data: [
          { category: "Male", value: 47 },
          { category: "Women", value: 53 },
        ]
      },
      {
        title: 'Lower middle quartile',
        description: 'Proportion of women and men in the lower middle quartile (paid above the 25th percentile point and at or below the median)',
        data: [
          { category: "Male", value: 36 },
          { category: "Women", value: 64 },
        ]
      },
      {
        title: 'Lower quartile',
        description: 'Proportion of women and men in the lower quartile (paid below the 25th percentile point)',
        data: [
          { category: "Male", value: 36 },
          { category: "Women", value: 64 },
        ]
      },
    ],
    summary: {
      title: "Gender pay gap summary",
      description: "gender pay gap summary description",
      sections: [
        {
          title: "Gender Pay Gap",
          paragraphs: [
            `58% of our workforce is female. This year's report shows a reduction in the Gender pay gap. The mean pay gap has decreased from 10% in ${moment().subtract(1, 'year').year()} to 7% in ${moment().year()} and the median pay gap has reduced from 12% in ${moment().subtract(1, 'year').year()} to 10% in ${moment().year()}.`
          ]
        },
        {
          title: "What this means",
          paragraphs: [
            `Our gender pay gap data tells us that our focus on addressing gender diversity at
              all levels is bringing about positive change. Recruitment plays an important role
              in improving gender representation and as of March ${moment().year()}, 58% of our employees
              are females. Developing and progressing our female workforce into senior roles
              is equally important and 45% of our senior roles are held by females.`,
            `Our gender pay gap data tells us that our focus on addressing gender diversity at all levels is bringing about positive change. Recruitment plays an important role in improving gender representation and as of March ${moment().year()}, 58% of our employees are females. Developing and progressing our female workforce into senior roles is equally important and 45% of our senior roles are held by females. We are pleased that the interventions we have put in place such as greater focus on salary negotiations and taking proactive actions to examine starting salaries, has resulted in equity of starting salaries for women and men. Pay increases are also having the desired impact on the gender pay gap, as a higher proportion of females are receiving promotions and pay increases.`
          ]
        }
      ],
      bulletList: {
        title: "These improvements have taken place due to the following actions: ",
        items: [
          "Pay Gap Taskforce working with Senior leaders, to focus on career development, succession planning and pay moderation.",
          "Ensuring our Executive Leadership Team are engaged on the recruitment panel of all Senior Leaders appointments.",
          "Encouraging senior leaders to offer sponsorship support to help accelerate female development.",
          "Positive action has ensured that every vacancy for middle manager and above roles include a gender balanced shortlist.",
          "Post interview feedback for each interviewee and hiring managers also receive coaching to ensure constructive feedback is given to applicants.",
          "Refreshed our own Inclusive mentoring programme which gives leaders insight of the experiences of our women to help them understand the challenges they face.",
          "Our agile working has enabled different working patterns and we have made this explicit as part of our recruitment process. We have held discussions across the organisation on flexible working opportunities."
        ],
      }
    }
  };

  constructor() { }

  ngOnInit(): void {
  }

}
