<h5 mat-dialog-title>Review</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>
        <form class="form" *ngIf="form" [formGroup]="form">

            <div *ngFor="let goal of goals; let i = index" class="goal">
                <p class="k-font-weight-bold">Goal {{i + 1}}: {{ goal.name }}</p>

                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Rating:</mat-label>
                    <mat-select formControlName="goal{{i+1}}Rating" required>
                        <mat-option *ngFor="let option of ratingOptions" [value]="option">{{option}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Comment:</mat-label>
                    <input matInput formControlName="goal{{i+1}}Comment">
                </mat-form-field>
            </div>

        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
