import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { routes } from '@app/consts';
import { EmployeeService } from '@app/core/services/employee.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import * as moment from 'moment';
import { ChangeRequestApprovalDialogComponent } from './components/change-request-approval-dialog/change-request-approval-dialog.component';
import { DirectReporteesService } from '@app/modules/direct-reportees/services/direct-reportees.service';

@Component({
  selector: 'app-actionable-tasks-widget',
  templateUrl: './actionable-tasks-widget.component.html',
  styleUrls: ['./actionable-tasks-widget.component.scss']
})
export class ActionableTasksWidgetComponent implements OnInit {
  @Output() emitTabChange = new EventEmitter();
  employees: any[] = [];
  loading: boolean = true;
  public routes: typeof routes = routes;
  birthdayTimePeriodSearchValue: string = "thisMonth";

  taskOptions = [];

  constructor(
    private employeeService: EmployeeService,
    private directReporteesService: DirectReporteesService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getEmployees()
    // setTimeout(() => {
    //   this.loading = false
    // }, 500)
  }

  getEmployees() {
    this.directReporteesService.getDirectReportees(0, '1000', null, 'Lastname-desc~Firstname-desc')
    .subscribe(
      res => {
        this.employees = res?.data;
        this.buildTasksList();
        this.loading = false;
      }
    )
  }

  buildTasksList() {
    this.taskOptions = [
      {
        id: 1,
        task: 'ApproveChangeRequest',
        description: `Approve Compensation - Pay Rate change for ${this.employees[0]?.firstname} ${this.employees[0]?.lastname}`,
        dueDate: moment().add(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD'),
        changeRequest: {
          employee: this.employees[0],
          form: 'frm_jVYSMy8oJaI6hf',
          formElement: 'fel_a6Gzh3YQ9s28mo',
          changeType: 'Salary',
          previousValue: 40000,
          newValue: 45000,
          changedBy: this.employees[1],
          changedOn: moment().subtract(1, 'd').format('YYYY-MM-DD:HH:mm:ss'),
          effectiveDate: moment().add(1, 'M').format('YYYY-MM-DD')
        }
      },
      {
        id: 2,
        task: 'Performance',
        description: `Complete your ${moment().year()} goals`,
        dueDate: moment().add(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD')
      },
      {
        id: 3,
        task: 'TimeOff',
        description: `Review your time off balance`,
        dueDate: moment().add(Math.floor(Math.random() * 14), 'd').format('YYYY-MM-DD')
      },
    ];
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {

        // Generate random number
        var j = Math.floor(Math.random() * (i + 1));

        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }

    return array;
  }

  startTask(task) {

    if(task.task === 'ApproveChangeRequest') {
      this.openDialog(task);
    }
    else {
      this.emitTabChange.emit(task.task)
    }

  }

  openDialog(task) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    // dialogConfig.minWidth = 500;

    dialogConfig.data = {
      changeRequest: task.changeRequest,
    };

    const dialogRef = this.dialog.open(ChangeRequestApprovalDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
          if (data === true) {
            this.taskOptions = this.taskOptions.filter(taskOption => taskOption.id !== task.id);
            this.snackbarService.openSnackBar('Approved Successfully', 'clear', 'success');
          }
          else if(data === false) {
            this.taskOptions = this.taskOptions.filter(taskOption => taskOption.id !== task.id);
            this.snackbarService.openSnackBar('Rejected Successfully', 'clear', 'success');
          }
      }
    );
  }

}
