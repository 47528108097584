<app-employee-details>
    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        category="Disciplinary Action"
        canSelectRows="true"
        clickableRows="true"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="getDiscipline($event)"
        (emitDeleteEvent)="deleteDisciplines($event)"
    ></app-data-grid-comp>
</app-employee-details>
