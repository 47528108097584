import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { RowActionButtonInput, RowActionEventOutput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs';
import { Integration } from '../../models/integration.model';
import { IntegrationsService } from '../../services/integrations.service';
import { DemoIntegrationsService } from '@app/modules/site-settings-integrations/services/demo-integrations.service';

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss']
})
export class IntegrationsListComponent implements OnInit {

  public routes: typeof routes = routes;
  public columns: any[] = [
    {
      field: 'name', 
      title: 'Name'
    }, 
    {
      field: 'description', 
      title: 'Description'
    }, 
    {
      field: 'frequency', 
      title: 'Frequency'
    }, 
    {
      field: 'startDate', 
      title: 'Start Date', 
      type: "date"
    },
    {
      field: 'createdBy', 
      title: 'Created By', 
      type: "employee"
    },
    {
      field: 'createdOn', 
      title: 'Created On', 
      type: "date"
    }, 
  ];

  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'analytics',
      name: this.translate.instant('Analytics'),
      icon: 'insights'
    },
    {
      id: 'error-log',
      name: this.translate.instant('Error Log'),
      icon: 'error'
    }
  ];

  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  isLoading: boolean;
  pageSize: number = 20;
  skip: string = '0';
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  
  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    public translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private integrationsService: DemoIntegrationsService,
  ) { }

  ngOnInit(): void {
    this.getIntegrations();
  }

  getIntegrations(): void {
    this.isLoading = true;

    this.getRequest = this.integrationsService.getIntegrations()
        .pipe(
            finalize(() => {
                this.isLoading = false;
            })
        )
        .subscribe(
            res => {
                this.gridDataResult = {
                    data: res,
                    total: res.length,
                };
            }
        );
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip.toString();
      this.pageSize = event.take;
      this.getIntegrations();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToIntegration(integration?: Integration) {
    if(integration){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}/${integration?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }

  rowActionButtonClicked($event){
    if($event.id === 'analytics') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ANALYTICS}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
    else if($event.id === 'error-log') {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.INTEGRATIONS}${routes.ERROR_LOG}/${$event.rowData?.id}`], {relativeTo: this.route});
    }
  }

}
