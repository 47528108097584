import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MetadataFormService} from '@app/core/services/metadata/metadata-form.service';
import {MetadataTableService} from '@app/core/services/metadata/metadata-table.service';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {CultureService} from '@app/core/services/system-language/culture.service';
import {LookupService} from '@app/modules/lookup/services/lookup.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {Culture} from '@app/shared/models/system-language/culture.model';
import {CustomReportsService} from '@app/modules/custom-reports/services/reports.service';
import * as moment from 'moment/moment';


@Component({
    selector: 'app-salary-review-dialog',
    templateUrl: './salary-review-dialog.component.html',
    styleUrls: ['./salary-review-dialog.component.scss']
})
export class SalaryReviewDialogComponent implements OnInit {

    form: UntypedFormGroup;
    formId: string;
    cultures: Culture[];
    loading: boolean;

    formElementId: string;
    parentId: string;
    formTableId: string;
    order: number;

    inputData: any;

    constructor(
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private metadataFormService: MetadataFormService,
        private reportsService: CustomReportsService,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private lookupService: LookupService,
        private metadataTableService: MetadataTableService,
        private dialogRef: MatDialogRef<SalaryReviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data) {
        this.inputData = data.salaryReview;

    }

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        this.form = this.fb.group({
            meritIncreaseGuideline: [{
                value: this.inputData.meritIncreaseGuideline ? this.inputData.meritIncreaseGuideline : null,
                disabled: true
            }],
            currentBaseSalary: [{
                value: this.inputData.fieldBaseData.currentBaseSalary ? this.inputData.fieldBaseData.currentBaseSalary : null,
                disabled: true
            }],
            newBaseSalary: [{
                value: this.inputData.fieldBaseData.newBaseSalary ? this.inputData.fieldBaseData.newBaseSalary : null,
                disabled: true
            }],
            proposedIncreaseAmount: [this.inputData.fieldBaseData.proposedIncreaseAmount ? this.inputData.fieldBaseData.proposedIncreaseAmount : null],
            proposedIncreasePercentage: [this.inputData.fieldBaseData.proposedIncreasePercentage ? this.inputData.fieldBaseData.proposedIncreasePercentage : null],
            comments: [this.inputData.comments ? this.inputData.comments : null],
        });
    }

    onAmountChange(event) {
        let amountValue = Number(event.target.value);
        let newBaseSalary = Number(this.inputData.fieldBaseData.currentBaseSalary) + amountValue;
        let proposedIncreasePercentage = (amountValue / Number(this.inputData.fieldBaseData.currentBaseSalary)) * 100;

        this.inputData.fieldBaseData.newBaseSalary = newBaseSalary.toFixed(2);
        this.inputData.fieldBaseData.proposedIncreasePercentage = proposedIncreasePercentage.toFixed(2);

    }

    onPercentageChange(event) {
        let percentageValue = event.target.value;
        let proposedIncreaseAmount = (percentageValue / 100) * Number(this.inputData.fieldBaseData.currentBaseSalary);

        this.inputData.fieldBaseData.newBaseSalary = (Number(this.inputData.fieldBaseData.currentBaseSalary) + proposedIncreaseAmount).toFixed(2);
        this.inputData.fieldBaseData.proposedIncreaseAmount = proposedIncreaseAmount.toFixed(2);
    }


    save() {
        const data = this.form.value;

        this.dialogRef.close(data);
    }

    close() {
        this.dialogRef.close();
    }
}
