<mat-card>
    <mat-card-title class="header">
      <h5 class="title"></h5>
    </mat-card-title>
  
    <mat-card-content class="content">
        <div *ngFor="let item of items" class="item" routerLink="{{item.url}}">
            <mat-icon class="material-icons-outlined">{{item.icon}}</mat-icon>
            <h6 class="text">{{item.text}}</h6>
        </div>
        <div class="item" (click)="goToLink('https://www.zestbenefits.com')">
            <img [src]="'assets/icons/zest_logo.svg'"/>
            <img class="zest-icon-text" [src]="'assets/icons/zest_logo_text.svg'"/>
        </div>
    </mat-card-content>
</mat-card>
  