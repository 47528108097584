import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmployeeService } from '@app/core/services/employee.service';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-talent-matrix',
  templateUrl: './talent-matrix.component.html',
  styleUrls: ['./talent-matrix.component.scss']
})
export class TalentMatrixComponent implements OnInit {
  isLoading: boolean;
  searchFilterString: any;
  filterString: any;
  getEmployeesRequest: any;
  employees: EmployeeContactCard[];
  searchValue: any;
  skip: number;
  filterInactive: boolean;
  sortString: string;

  highLow: EmployeeContactCard[] = [];
  mediumLow: EmployeeContactCard[] = [];
  lowLow: EmployeeContactCard[] = [];

  highMedium: EmployeeContactCard[] = [];
  mediumMedium: EmployeeContactCard[] = [];
  lowMedium: EmployeeContactCard[] = [];

  highHigh: EmployeeContactCard[] = [];
  mediumHigh: EmployeeContactCard[] = [];
  lowHigh: EmployeeContactCard[] = [];

  colors: string[] = [
    '#FF0000',
    '#FFA500',
    '#ADFF2F',
    '#32CD32',
    '#008000',
  ]

  mappings = [
    {
      title: "Potential Gem",
      potential: "High",
      performance: "Low",
      array: this.highLow,
    },
    {
      title: "Inconsistent",
      potential: "Medium",
      performance: "Low",
      array: this.mediumLow,
    },
    {
      title: "Risk",
      potential: "Low",
      performance: "Low",
      array: this.lowLow,
    },
    {
      title: "High Potential",
      potential: "High",
      performance: "Medium",
      array: this.highMedium,
    },
    {
      title: "Core Player",
      potential: "Medium",
      performance: "Medium",
      array: this.mediumMedium,
    },
    {
      title: "Average Performer",
      potential: "Medium",
      performance: "Low",
      array: this.lowMedium,
    },
    {
      title: "Star",
      potential: "High",
      performance: "High",
      array: this.highHigh,
    },
    {
      title: "High Performer",
      potential: "Medium",
      performance: "High",
      array: this.mediumHigh,
    },
    {
      title: "Solid Performer",
      potential: "Low",
      performance: "High",
      array: this.lowHigh,
    },
  ]

  constructor(
    private dialog: MatDialog,
    private employeeContactCardService: EmployeeContactCardService,
    private employeeService: EmployeeService,
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees(): void {
    this.isLoading = true;

    let filter;
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }
    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }

    this.getEmployeesRequest = this.employeeContactCardService.getAllEmployeeContactCards(this.skip, '1000', filter, this.sortString, this.filterInactive)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.employees = res.data;
        this.addEmployeesToArray();
      }
    )
  }

  addEmployeesToArray() {
    // Create an array containing all six arrays
    const arrays = [
      this.highLow, 
      this.mediumLow, 
      this.lowLow, 
      this.highMedium, 
      this.mediumMedium,
      this.lowMedium,
      this.highHigh,
      this.mediumHigh,
      this.lowHigh,
    ];

    this.employees.forEach(
      employee => {
        // Generate a random index to select one of the arrays
        const randomIndex = Math.floor(Math.random() * arrays.length);
    
        // Select the array using the random index
        const selectedArray = arrays[randomIndex];
    
        // Add an item to the selected array
        selectedArray.push(employee);
      }
    )

  }

  search() {
      let variants = this.searchValue.split(' ').filter(value => value);
      this.searchFilterString = '';
      variants.map(variant => {
          if (variant) {
              if (this.searchFilterString.length > 2) {
                  this.searchFilterString += ' AND ';
              }
              this.searchFilterString += `((FirstName like "${variant}") OR (LastName like "${variant}") OR (PreferredName like "${variant}") OR (customClientId like "${variant}") OR (userId like  "${variant}"))`;
          }
      });
      this.skip = 0;
      this.getEmployeesRequest.unsubscribe();
      this.getEmployees();
  }

  public sortChange(sort: string): void {
    // this.sort = sort;

    // if (sort[0].dir === undefined) {
    //   this.sortString = null
    // }
    // else {
    //   //use regex to get column field to sort with
    //   let field: any;

    //   // If it is the fullname column sort by firstname and lastname
    //   if(sort[0].field === 'fullName'){
    //     this.sortString = `Lastname-${sort[0].dir}~Firstname-${sort[0].dir}`;
    //   }
    //   else {
    //     //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
    //     (sort[0].field).match(/.+(?=\.)/) === null
    //     ? field = sort[0].field
    //     : field = (sort[0].field).match(/.+(?=\.)/)
    //     this.sortString = `${field}-${sort[0].dir}`;
    //   }

    // }

    this.getEmployees();
  }

  filterCallback(filterString: string) {
    this.searchFilterString = filterString;
    this.skip = 0;
    this.getEmployees();
  }

  employeeUpdated(
    updatedEmployee: {
      employee: EmployeeContactCard,
      potential: string,
      performance: string,
      title: string,
    }
  ) {
    this.moveEmployee(updatedEmployee, this.mappings.find(obj => obj.title === updatedEmployee.title))
  }

  moveEmployee(
    updatedEmployee: {
      employee: EmployeeContactCard,
      potential: string,
      performance: string,
      title: string,
    },
    mapping: {
      title: string,
      potential: string,
      performance: string,
      array: EmployeeContactCard[],
    }
  ) {
    const findIndex = mapping.array.findIndex(employee => employee === updatedEmployee.employee);

    if(findIndex > -1){
      mapping.array.splice(findIndex, 1);
      const foundObject = this.mappings.find(obj => obj.potential === updatedEmployee.potential && obj.performance === updatedEmployee.performance);
      foundObject.array.push(updatedEmployee.employee);
    }
  }

}
