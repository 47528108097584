import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PublishedWorkflow, Workflow } from '../models/workflows.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowsService {

  workflows: Workflow[] = [
    {
      id: '1231234234',
      name: 'New Hire Tasks',
      description: '',
      createdBy: {
        id: 'usr_GWGmNiX25fBVvk',
        firstName: 'Bineta',
        lastName: 'Aissatou'
      },
      createdOn: '2023-03-01T00:00:00',
      categories: [
        {
          id: '56745',
          name: 'IT',
          description: '',
          tasks: [
            {
              id: '56346745',
              name: 'Create email address',
              description: 'Create email address',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: true,
              type: 'standard',
            },
            {
              id: '457456756',
              name: 'Fill out Family Dependants form',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'form',
              form: 'frm_FyFtnMvPWhDtcO'
            },
            {
              id: '56856',
              name: 'Read new hire welcome letter',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'letter',
              letter: '678567856'
            }
          ]
        },
        {
          id: '43573567456845',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: '34645634',
              name: 'New hire orientation',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '678568457',
              name: 'Office Tour',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '45654684656',
              name: 'Introduce new hire to 2 employees outside of group',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 'hj789yh',
              name: 'New hire training',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '8968799',
              name: 'Confirm W4 is signed',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            }
          ]
        },
        {
          id: 'h978t6b9t6',
          name: 'New Hire Paperwork',
          description: '',
          tasks: [
            {
              id: 'h987t6t',
              name: 'Complete direct deposit form',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: true,
              type: 'letter',
              letter: '4236345ds'
            },
            {
              id: 't3sghdrsht',
              name: 'Complete engagement survey',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: true,
              type: 'survey',
              survey: '34265665y'
            },
            {
              id: 'g7ifgi7d5t',
              name: 'Read employee Handbook',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: true,
              type: 'letter',
              letter: '33d2df4rd'
            },
            {
              id: 'bhigy7786r',
              name: 'Review and sign 19',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: true,
              type: 'letter',
              letter: '1235342'
            },
          ]
        }
      ],
    },
    {
      id: '54jthjr',
      name: 'Leaver Process',
      description: '',
      createdBy: {
        id: 'usr_JdaZBwb1r2HrCg',
        firstName: 'Oliwia',
        lastName: 'Alford'
      },
      createdOn: '2023-03-01T00:00:00',
      categories: [
        {
          id: 'f43fedf',
          name: 'Leaver',
          description: '',
          tasks: [
            {
              id: '56thjnrtjy',
              name: 'Complete Leaver Survey',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'survey',
              survey: 'fer342refv',
            },
          ]
        },
        {
          id: '56htyh5',
          name: 'IT',
          description: '',
          tasks: [
            {
              id: '56thjnrtjy',
              name: 'Review outstanding corporate assets issued to terminated user',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: true,
              type: 'standard',
            },
            {
              id: 'h546u85y',
              name: 'Deactivate user account',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 'tghn456',
              name: 'Deactivate Access Card',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '564367456',
              name: 'Reclaim office material (Computers, Phone)',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
          ]
        },
        {
          id: '342tfewrg',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: '45t4t5ger',
              name: 'Draft Termination Documentation',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '45t4342534t5ger',
              name: 'Provide Termination Documentation to Employee',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Receive Termination Documentation from employees',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 'f34tfrtgvrdbg',
              name: 'Calculate Termination Pay in Lieu',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 'defv34t34',
              name: 'Calculate Termination Vacation Payout',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '34qt453tg',
              name: 'Perform Exit Interview',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
          ]
        }
      ],
    },
    {
      id: '432r23fe',
      name: 'Disciplines',
      description: '',
      createdBy: {
        id: 'usr_JdaZBwb1r2HrCg',
        firstName: 'Oliwia',
        lastName: 'Alford'
      },
      createdOn: '2023-01-20T00:00:00',
      categories: [
        {
          id: '342tfewrg',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: '45t4t5ger',
              name: 'Complete discipline record',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'form',
              form: 'frm_vmmI4Zj0zefPLq'
            },
            {
              id: '45t4342534t5ger',
              name: 'Attach any relevant documents to employee record',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Identify resolution',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            }
          ]
        }
      ],
    },
    {
      id: '34fr3wt5',
      name: 'Grievance',
      description: '',
      createdBy: {
        id: 'usr_JdaZBwb1r2HrCg',
        firstName: 'Oliwia',
        lastName: 'Alford'
      },
      createdOn: '2023-03-01T00:00:00',
      categories: [
        {
          id: '342tfewrg',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: '45t4t5ger',
              name: 'Complete grievance record',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'form',
              form: 'frm_WJLu2uhMVwO7JD'
            },
            {
              id: '45t4342534t5ger',
              name: 'Attach any relevant documents to employee record',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Identify resolution',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            }
          ]
        }
      ],
    },
    {
      id: '2546y3tg',
      name: 'Short Term Disability',
      description: '',
      createdBy: {id: "usr_uLtt6NpkTOTWDZ", firstName: "Ben", lastName: "Buckton"},
      createdOn: '2023-02-12T00:00:00',
      categories: [
        {
          id: '342tfewrg',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: '45t4342534t5ger',
              name: 'Attach any relevant documents to employee record',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Contact insurance provider',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Identify return to work plan',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            }
          ]
        }
      ],
    },
    {
      id: 't45yg5gy',
      name: 'Long Term Disability',
      description: '',
      createdBy: {
        id: 'usr_MABzHrkfBmJm4T',
        firstName: 'Hampton',
        lastName: 'Anastasia'
      },
      createdOn: '2023-01-15T00:00:00',
      categories: [
        {
          id: '342tfewrg',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: '45t4342534t5ger',
              name: 'Close any short term disability journey attached to employee',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '45t4342534t5ger',
              name: 'Attach any relevant documents to employee record',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Contact insurance provider',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 't5w3fgrt4',
              name: 'Identify return to work plan',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-03-01T00:00:00',
              completed: false,
              type: 'standard',
            }
          ]
        }
      ],
    },
    // {
    //   id: 'gt4etrew',
    //   name: 'Lime and Corclaim Bonus Automation',
    //   description: '',
    //   createdBy: {
    //     id: 'usr_ajdf6IX90m9OVl',
    //     firstName: 'Zidan',
    //     lastName: 'Farell'
    //   },
    //   createdOn: '2023-01-12T00:00:00',
    //   categories: [
    //     {
    //       id: '342tfewrg',
    //       name: 'HR',
    //       description: '',
    //       tasks: [
    //         {
    //           id: 'vrgfge',
    //           name: 'Import employees from Lime and Corclaim Bonus report',
    //           description: '',
    //           createdBy: {
    //             id: 'usr_FfXgVlfbvpNR06',
    //             firstName: 'Daniel',
    //             lastName: 'CEO'
    //           },
    //           createdOn: '2023-01-12T00:00:00',
    //           completed: false,
    //           type: 'standard',
    //         },
    //         {
    //           id: '34qgr',
    //           name: 'Review Employee Short Term Incentive',
    //           description: '',
    //           createdBy: {
    //             id: 'usr_FfXgVlfbvpNR06',
    //             firstName: 'Daniel',
    //             lastName: 'CEO'
    //           },
    //           createdOn: '2023-01-12T00:00:00',
    //           completed: false,
    //           type: 'standard',
    //         },
    //         {
    //           id: 'bwr45y345y',
    //           name: 'Calculate Bonus Amount',
    //           description: '',
    //           createdBy: {
    //             id: 'usr_FfXgVlfbvpNR06',
    //             firstName: 'Daniel',
    //             lastName: 'CEO'
    //           },
    //           createdOn: '2023-01-12T00:00:00',
    //           completed: false,
    //           type: 'standard',
    //         },
    //         {
    //           id: 'g45gtrgvdrt',
    //           name: 'Assign Bonus to Compensation Module',
    //           description: '',
    //           createdBy: {
    //             id: 'usr_FfXgVlfbvpNR06',
    //             firstName: 'Daniel',
    //             lastName: 'CEO'
    //           },
    //           createdOn: '2023-01-12T00:00:00',
    //           completed: false,
    //           type: 'standard',
    //         },
    //         {
    //           id: 'ervvvdsav',
    //           name: 'Pay out bonus on employee payroll',
    //           description: '',
    //           createdBy: {
    //             id: 'usr_FfXgVlfbvpNR06',
    //             firstName: 'Daniel',
    //             lastName: 'CEO'
    //           },
    //           createdOn: '2023-01-12T00:00:00',
    //           completed: false,
    //           type: 'standard',
    //         },
    //       ]
    //     }
    //   ],
    // },
    {
      id: 'vefvw34rtg45',
      name: 'Adoption',
      description: '',
      createdBy: {
        id: 'usr_CG0pDTZCio0qRr',
        firstName: 'Pavan',
        lastName: 'Jai'
      },
      createdOn: '2023-01-12T00:00:00',
      categories: [
        {
          id: '342tfewrg',
          name: 'HR',
          description: '',
          tasks: [
            {
              id: 'vrgfge',
              name: 'Provide Reference Documentation to Employee',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-01-12T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: '34qgr',
              name: 'Assign KIT Time Off Policy',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-01-12T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 'bwr45y345y',
              name: 'Contact benefits provider',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-01-12T00:00:00',
              completed: false,
              type: 'standard',
            },
            {
              id: 'g45gtrgvdrt',
              name: 'Backfill Position',
              description: '',
              createdBy: {
                id: 'usr_FfXgVlfbvpNR06',
                firstName: 'Daniel',
                lastName: 'CEO'
              },
              createdOn: '2023-01-12T00:00:00',
              completed: false,
              type: 'standard',
            },
          ]
        }
      ],
    }
  ];

  publishedWorkflows: PublishedWorkflow[] = [
    {
      id: '34645634',
      name: 'Graduate Programme New Hires Winter 2023',
      description: 'New hire journey for all the new hires through the winter 2023 graduate programme',
      workflow: {
        id: '1231234234',
        name: 'New Hire Tasks',
      },
      startDate: '2023-03-02T00:00:00',
      endDate: '2023-03-10T00:00:00',
      createdBy: {
        id: 'usr_MABzHrkfBmJm4T',
        firstName: 'Hampton',
        lastName: 'Anastasia'
      },
      createdOn: '2023-03-01T00:00:00',
      status: {
        id: 'inProgress',
        text: 'In Progress',
      },
      participants: [
        {id: "usr_mwUJf9lRKWbNPg", firstName: "Esme", lastName: "Biship"},
        {id: "usr_uLtt6NpkTOTWDZ", firstName: "Ben", lastName: "Buckton"},
      ],
      owners: [
        {id: "usr_QbBuVt9SLK8fSI", firstName: "Kone", lastName: "Bouba"},
        {id: "usr_oQiQUCIWV3S8fP", firstName: "Francesca", lastName: "Campos"}
      ],
    },
    {
      id: '659679',
      name: 'Graduate Programme New Hires Winter 2023',
      description: 'New hire journey for all the new hires through the winter 2023 graduate programme',
      workflow: {
        id: '1231234234',
        name: 'New Hire Tasks',
      },
      startDate: '2023-03-02T00:00:00',
      endDate: '2023-03-10T00:00:00',
      createdBy: {
        id: 'usr_NHVtajbrY2LvTW',
        firstName: 'Mark',
        lastName: 'Beesley'
      },
      createdOn: '2023-03-01T00:00:00',
      status: {
        id: 'complete',
        text: 'Complete',
      },
      participants: [
        {id: "usr_mwUJf9lRKWbNPg", firstName: "Esme", lastName: "Biship"},
      ],
      owners: [
        {id: "usr_QbBuVt9SLK8fSI", firstName: "Kone", lastName: "Bouba"},
        {id: "usr_uLtt6NpkTOTWDZ", firstName: "Ben", lastName: "Buckton"},
        {id: "usr_oQiQUCIWV3S8fP", firstName: "Francesca", lastName: "Campos"}
      ],
    },
    {
      id: '46894698436',
      name: 'Graduate Programme New Hires Winter 2023',
      description: 'New hire journey for all the new hires through the winter 2023 graduate programme',
      workflow: {
        id: '1231234234',
        name: 'New Hire Tasks',
      },
      startDate: '2023-03-02T00:00:00',
      endDate: '2023-03-10T00:00:00',
      createdBy: {
        id: 'usr_ehwoZMM90tCHUW',
        firstName: 'Paul',
        lastName: 'Bird'
      },
      createdOn: '2023-03-01T00:00:00',
      status: {
        id: 'cancelled',
        text: 'Cancelled',
      },
      participants: [
        {id: "usr_mwUJf9lRKWbNPg", firstName: "Esme", lastName: "Biship"},
      ],
      owners: [
        {id: "usr_QbBuVt9SLK8fSI", firstName: "Kone", lastName: "Bouba"},
        {id: "usr_uLtt6NpkTOTWDZ", firstName: "Ben", lastName: "Buckton"},
        {id: "usr_oQiQUCIWV3S8fP", firstName: "Francesca", lastName: "Campos"}
      ],
    }
  ]

  constructor() { }

  getWorkflows(): Observable<Workflow[]> {
    return of(this.workflows)
  }

  getWorkflow(id: string): Observable<Workflow> {
    return of(this.workflows.find(obj => obj.id === id))
  }

  getPublishedWorkflows(): Observable<PublishedWorkflow[]> {
    return of(this.publishedWorkflows)
  }

  getPublishedWorkflow(id: string): Observable<PublishedWorkflow> {
    return of(this.publishedWorkflows.find(obj => obj.id === id))
  }
}
