import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {PagedData} from '@app/modules/lookup/models/paged-data.model';
import {EnvironmentService} from '@app/core/services/environment.service';
import {
    DataTriggerAction,
    DataTriggerRule,
    DataTriggerRuleCondition
} from '@app/modules/data-trigger-rules/models/data-trigger-rules.model';


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class DataTriggerRulesService {

    constructor(private http: HttpClient, private envService: EnvironmentService) {
    }


    // dataTriggerRules: DataTriggerRule[] =
    //     [
    //         {
    //             id: 'bnstre4s56f',
    //             name: 'Bonus Tier 4%',
    //             description: 'Set bonus tier to 4% when income exceeds 300,000 ',
    //             createdBy: 'usr_33xh1RLZykBym3',
    //             createdByName: 'Kevin Abdoulaye',
    //             createdOn: '2023-02-10T00:00:00',
    //             rules: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     fieldValue: '300000',
    //                     fieldValue2: '',
    //                     formId: 'frm_jVYSMy8oJaI6hf',
    //                     fieldId: 'tfi_CompensationBaseSalaryFteAdjusted',
    //                     conditionId: 'greater-than',
    //                     dataType: 'DECIMAL',
    //                     currentField: 'DECIMAL',
    //                     tableId: 'tbl_Compensations',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }],
    //             outcomes: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     actionId: 'setValue',
    //                     fieldValue: 'lok_fTF2Ur3GFHY1ew',
    //                     fieldValue2: '',
    //                     formId: 'frm_jVYSMy8oJaI6hf',
    //                     tableId: 'tbl_Compensations',
    //                     fieldId: 'tfi_CompensationStiIncentivePlan',
    //                     // conditionId: '',
    //                     dataType: 'DROPDOWN',
    //                     currentField: 'DROPDOWN',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 },
    //                 {
    //                     id: 'bnstre4s56f',
    //                     actionId: 'triggerNotification',
    //                     fieldValue: 10,
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }]
    //         },
    //         {
    //             id: 'bnstre6s56f',
    //             name: 'Bonus Tier 6%',
    //             description: 'Set bonus tier to 6% when income exceeds 500,000 ',
    //             createdBy: 'usr_33xh1RLZykBym3',
    //             createdByName: 'Kevin Abdoulaye',
    //             createdOn: '2023-02-10T00:00:00',
    //             rules: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     fieldValue: '',
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }],
    //             outcomes: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     actionId: '',
    //                     fieldValue: '',
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }]
    //         },
    //         {
    //             id: 'bnstre4s56n',
    //             name: 'Promotion Notifications',
    //             description: 'Notify Manager of promotion of user when hired for 3 years and compensation is over 20,000',
    //             createdBy: 'usr_33xh1RLZykBym3',
    //             createdByName: 'Kevin Abdoulaye',
    //             createdOn: '2023-04-10T00:00:00',
    //             rules: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     fieldValue: '',
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }],
    //             outcomes: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     actionId: '',
    //                     fieldValue: '',
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }]
    //         },
    //         {
    //             id: 'bdvftre4s56n',
    //             name: 'Hire Pay Rate Update ',
    //             description: 'Update pay rate when user hire rate has passed 4 months',
    //             createdBy: 'usr_33xh1RLZykBym3',
    //             createdByName: 'Kevin Abdoulaye',
    //             createdOn: '2023-01-10T00:00:00',
    //             rules: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     fieldValue: '',
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }],
    //             outcomes: [
    //                 {
    //                     id: 'bnstre4s56f',
    //                     actionId: '',
    //                     fieldValue: '',
    //                     fieldValue2: '',
    //                     formId: '',
    //                     fieldId: '',
    //                     conditionId: '',
    //                     dataType: '',
    //                     currentField: '',
    //                     createdBy: 'usr_33xh1RLZykBym3',
    //                     createdByName: 'Kevin Abdoulaye',
    //                     createdOn: '2023-02-10T00:00:00'
    //                 }]
    //         }
    //     ];

    demoTriggerRules: DataTriggerRule[] =
        [
            {
                id: 'bnstre4s56sdf',
                name: 'Bonus Tier 4%',
                description: 'Set bonus tier to 4% when income exceeds 300,000 ',
                createdBy: 'usr_33xh1RLZykBym3',
                createdByName: 'Kevin Abdoulaye',
                createdOn: '2023-01-03T00:00:00',
                rules: [
                    {
                        id: 'bnstrej4s56f',
                        fieldValue: '300000',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        fieldId: 'tfi_CompensationStiActualPercentage',
                        conditionId: 'greater-than',
                        dataType: 'DECIMAL',
                        currentField: 'DECIMAL',
                        tableId: 'tbl_Compensations',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }],
                outcomes: [
                    {
                        id: 'bnstre4js56f',
                        actionId: 'setValue',
                        fieldValue: 'lok_EpnwJseAzKE2fQ',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        tableId: 'tbl_Compensations',
                        fieldId: 'tfi_CompensationLtiStockOption',
                        dataType: 'DROPDOWN',
                        currentField: 'DROPDOWN',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }]
            },
            {
                id: 'bns3edtre6s56f',
                name: 'Bonus Tier 6%',
                description: 'Set bonus tier to 6% when income exceeds 500,000 ',
                createdBy: 'usr_33xh1RLZykBym3',
                createdByName: 'Kevin Abdoulaye',
                createdOn: '2022-05-10T00:00:00',
                rules: [
                    {
                        id: 'bnstre4s56df',
                        fieldValue: '500000',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        fieldId: 'tfi_CompensationStiActualPercentage',
                        conditionId: 'greater-than',
                        dataType: 'DECIMAL',
                        currentField: 'DECIMAL',
                        tableId: 'tbl_Compensations',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }],
                outcomes: [
                    {
                        id: 'bnstre4gs56f',
                        actionId: 'setValue',
                        fieldValue: 'lok_0MqIcJIzpVL5gS',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        tableId: 'tbl_Compensations',
                        fieldId: 'tfi_CompensationLtiStockOption',
                        dataType: 'DROPDOWN',
                        currentField: 'DROPDOWN',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }]
            },
            {
                id: 'bns23edtre4sh56n',
                name: 'Promotion Notifications',
                description: 'Notify Manager of promotion of user when hired for 3 years and compensation is over 20,000',
                createdBy: 'usr_33xh1RLZykBym3',
                createdByName: 'Kevin Abdoulaye',
                createdOn: '2023-04-23T00:00:00',
                rules: [
                    {
                        id: 'bnstre4fds56f',
                        fieldValue: '1095',
                        fieldValue2: '',
                        formId: 'frm_ygxyk9Zfg6zaXh',
                        fieldId: 'tfi_ErHireDate',
                        tableId: 'tbl_EmploymentRecords',
                        conditionId: 'days-before',
                        dataType: 'DECIMAL',
                        currentField: 'DECIMAL',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    },
                    {
                        id: 'bnstre4fds56f',
                        fieldValue: '20000',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        fieldId: 'tfi_CompensationStiTargetAmount',
                        tableId: 'tbl_Compensations',
                        conditionId: 'greater-than',
                        dataType: 'DECIMAL',
                        currentField: 'DECIMAL',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    },
                ],
                outcomes: [
                    {
                        id: 'bnstre4s56f',
                        actionId: 'triggerNotification',
                        fieldValue: 10,
                        fieldValue2: '',
                        formId: '',
                        fieldId: '',
                        conditionId: '',
                        dataType: '',
                        currentField: '',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }]
            },
            {
                id: 'bdvf32dstre4s56n',
                name: 'Hire Pay Rate Update ',
                description: 'Update pay rate when user hire rate has passed 4 months',
                createdBy: 'usr_33xh1RLZykBym3',
                createdByName: 'Kevin Abdoulaye',
                createdOn: '2022-06-15T00:00:00',
                rules: [
                    {
                        id: 'bnstre4fds56f',
                        fieldValue: '120',
                        fieldValue2: '',
                        formId: 'frm_ygxyk9Zfg6zaXh',
                        fieldId: 'tfi_ErHireDate',
                        tableId: 'tbl_EmploymentRecords',
                        conditionId: 'days-before',
                        dataType: 'DECIMAL',
                        currentField: 'DECIMAL',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }],
                outcomes: [
                    {
                        id: 'bnstre4gs56f',
                        actionId: 'setValue',
                        fieldValue: '0',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        tableId: 'tbl_Compensations',
                        fieldId: 'tfi_CompensationPayRate',
                        dataType: 'DECIMAL',
                        currentField: 'DECIMAL',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    },
                    {
                        id: 'bnstre4gs56f',
                        actionId: 'setValue',
                        fieldValue: 'lok_xRed9Ha7Lmaw5u',
                        fieldValue2: '',
                        formId: 'frm_jVYSMy8oJaI6hf',
                        tableId: 'tbl_Compensations',
                        fieldId: 'tfi_CompensationRoleGroup',
                        dataType: 'DROPDOWN',
                        currentField: 'DROPDOWN',
                        createdBy: 'usr_33xh1RLZykBym3',
                        createdByName: 'Kevin Abdoulaye',
                        createdOn: '2023-02-10T00:00:00'
                    }]
            }
        ];

    actions: DataTriggerAction[] = [
        {
            id: 'setValue',
            name: 'Set Value'
        },
        {
            id: 'triggerNotification',
            name: 'Trigger Notification'
        }
    ];

    conditions: DataTriggerRuleCondition[] = [
        {
            id: 'number',
            dataType: 'DECIMAL',
            conditions: [
                {
                    id: 'equals',
                    name: 'Equals'
                },
                {
                    id: 'greater-than',
                    name: 'Greater than'
                },
                {
                    id: 'less-than',
                    name: 'Less than'
                },
                {
                    id: 'between',
                    name: 'Between x number and y number'
                }
            ]
        },
        {
            id: 'date',
            dataType: 'DATETIME',
            conditions: [
                {
                    id: 'days-before',
                    name: 'X days before the date'
                },
                {
                    id: 'days-after',
                    name: 'X days after the date'
                },
                {
                    id: 'date-equals',
                    name: 'Equals'
                }
            ]
        },
        {
            id: 'string',
            dataType: 'TEXT',
            conditions: [
                {
                    id: 'is',
                    name: 'Is'
                },
                {
                    id: 'is-not',
                    name: 'Is not'
                },
                {
                    id: 'is-empty',
                    name: 'Is Blank'
                },
                {
                    id: 'is-not-empty',
                    name: 'Is Not Blank'
                }
            ]
        },
        {
            id: 'dropdown',
            dataType: 'DROPDOWN',
            conditions: [
                {
                    id: 'is',
                    name: 'Is'
                },
                {
                    id: 'is-not',
                    name: 'Is not'
                },
            ]
        },

    ];


    getDataTriggerRule(id: string): DataTriggerRule {
        let res = null;
        this.demoTriggerRules.forEach(rule => {
            if (rule.id === id) {
                res = rule;
            }
        });
        return res;
    }


    getDataTriggerRules(skip?: string, take?: string, filterString?: string): Observable<PagedData<DataTriggerRule>> {
        const page = 1;
        const pageSize = 5;
        const pagedData: PagedData<DataTriggerRule> = {
            skip: parseInt(skip, 10),
            take: parseInt(take, 10),
            data: this.demoTriggerRules.slice((page - 1) * pageSize, page * pageSize),
            total: this.demoTriggerRules.length,
            totalPages: Math.ceil(this.demoTriggerRules.length / pageSize),
        };

        return of(pagedData);

    }

    getAvailableActions(skip?: string, take?: string): Observable<PagedData<DataTriggerAction>> {
        // TODO - get from API

        const page = 1;
        const pageSize = 5;
        const pagedData: PagedData<DataTriggerAction> = {
            skip: parseInt(skip, 10),
            take: parseInt(take, 10),
            data: this.actions.slice((page - 1) * pageSize, page * pageSize),
            total: this.actions.length,
            totalPages: Math.ceil(this.actions.length / pageSize),
        };

        return of(pagedData);

    }

    getComparators(skip?: string, take?: string): Observable<PagedData<DataTriggerAction>> {
        // TODO - get from API

        const page = 1;
        const pageSize = 5;
        const pagedData: PagedData<DataTriggerAction> = {
            skip: parseInt(skip, 10),
            take: parseInt(take, 10),
            data: this.actions.slice((page - 1) * pageSize, page * pageSize),
            total: this.actions.length,
            totalPages: Math.ceil(this.actions.length / pageSize),
        };

        return of(pagedData);

    }

    getConditions(skip?: string, take?: string): Observable<PagedData<DataTriggerRuleCondition>> {
        // TODO - get from API

        const page = 1;
        const pageSize = 5;
        const pagedData: PagedData<DataTriggerRuleCondition> = {
            skip: parseInt(skip, 10),
            take: parseInt(take, 10),
            data: this.conditions.slice((page - 1) * pageSize, page * pageSize),
            total: this.conditions.length,
            totalPages: Math.ceil(this.conditions.length / pageSize),
        };

        return of(pagedData);

    }
}
