import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { Workflow, WorkflowCategory, WorkflowTask } from '../../models/workflows.model';
import { WorkflowsService } from '../../services/workflows.service';

@Component({
  selector: 'app-workflow-generator',
  templateUrl: './workflow-generator.component.html',
  styleUrls: ['./workflow-generator.component.scss']
})
export class WorkflowGeneratorComponent implements OnInit {
  @Input() workflowId: string;
  @Input() resetCompletedTasks: boolean;
  workflow: Workflow;
  isLoading: boolean = true;

  constructor(
    private workflowsService: WorkflowsService
  ) { }

  ngOnInit(): void {
    this.getWorkflow();
  }

  getWorkflow() {
    this.workflowsService.getWorkflow(this.workflowId)
    .pipe(
        finalize(() => {
            this.isLoading = false;
        })
    )
    .subscribe(
        res => {
          if(this.resetCompletedTasks === true) {
            this.workflow = this.deepClone(res);
            this.resetCompleted(this.workflow);
          }
          else {
            this.workflow = res;
          }
        }
    );
  }

  getWorkflowProgress() {
    let tasks: WorkflowTask[] = this.workflow.categories.flatMap(c => c.tasks);

    let completed = tasks.filter(t => t.completed === true);
    
    return (completed.length/(tasks.length)) * (100/1);
  }

  getCategoryProgress(category: WorkflowCategory) {
    let completed = category.tasks.filter(t => t.completed === true);

    return (completed.length/(category.tasks.length)) * (100/1);
  }

  deepClone(obj: any): any {
    let clonedObj: any;
  
    if (obj && typeof obj === 'object') {
      if (Array.isArray(obj)) {
        // Create a new array and copy each element recursively
        clonedObj = [];
        for (let i = 0; i < obj.length; i++) {
          clonedObj[i] = this.deepClone(obj[i]);
        }
      } else {
        // Create a new object and copy each property recursively
        clonedObj = {};
        for (let prop in obj) {
          if (obj.hasOwnProperty(prop)) {
            clonedObj[prop] = this.deepClone(obj[prop]);
          }
        }
      }
    } else {
      // If obj is not an object or is null/undefined, return it directly
      clonedObj = obj;
    }
  
    return clonedObj;
  }

  setCompletedFalse(category: WorkflowCategory) {
    category.tasks.forEach(
      task => {
        task.completed = false
      }
    )

    return category;
  }

  resetCompleted(workflow: Workflow): Workflow {
    let resetWorkflow: Workflow = Object.assign({}, workflow);

    resetWorkflow.categories.forEach( category => this.setCompletedFalse(category) )

    return resetWorkflow
  }

}
