import { Component, OnInit } from '@angular/core';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-pt-calibration-report-wizard',
  templateUrl: './pt-calibration-report-wizard.component.html',
  styleUrls: ['./pt-calibration-report-wizard.component.scss']
})
export class PtCalibrationReportWizardComponent implements OnInit {
  loadingEmployees: boolean;
  employees: any[];

  

  constructor(
    private employeeContactCardService: EmployeeContactCardService
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }

  getEmployees(): void {
    this.loadingEmployees = true;

    this.employeeContactCardService.getAllEmployeeContactCards(0, '10')
    .pipe(
        finalize(()=>{  })
    )
    .subscribe(
      res => {
        this.employees = res.data;
        // this.setSelectedCategories();
      }
    )
  }

}
