<app-letter-generator>
    <mat-card>
        <mat-card-title class="header">
        </mat-card-title>
        <mat-card-content>
            <mat-tab-group class="my-mat-tab-group" [selectedIndex]="selectedIndex">
                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">1</span> SELECT A LETTER </ng-template>
                    <div class="tab-container">
                        <app-select-letter-list (letterSelected)="letterSelected($event)"></app-select-letter-list>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">2</span> SELECT RECIPIENTS </ng-template>
                    <div class="tab-container">
                        <app-select-recipient-list (selectedRecipients)="recipientsSelected($event)"></app-select-recipient-list>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">3</span> ADD LETTER DETAILS </ng-template>
                    <div class="tab-container">
                        <app-add-letter-details *ngIf="selectedLetter" [letter]="selectedLetter" (letterVariables)="variablesCompleted($event)"></app-add-letter-details>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">4</span> REVIEW AND SEND </ng-template>
                    <div class="tab-container">
                        <app-review-and-send
                            [selectedLetter]="selectedLetter"
                            [selectedRecipients]="selectedRecipients"
                            [letterVariables]="letterVariables"
                        ></app-review-and-send>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions>
            <button class="continue-button" mat-flat-button color="warn" (click)="selectedIndex = selectedIndex+1">Continue</button>
            <button mat-button [disabled]="selectedIndex === 0" (click)="selectedIndex = selectedIndex-1">Previous Step</button>
          </mat-card-actions>
    </mat-card>
</app-letter-generator>

