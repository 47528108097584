import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-emergency-contacts',
  templateUrl: './emergency-contacts.component.html',
  styleUrls: ['./emergency-contacts.component.scss']
})

export class EmergencyContactsComponent implements OnInit {
  employeeId: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
      params => this.employeeId = params.get("id")
    )
  }

  ngOnInit(): void {
  }
}
