import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { routes } from '@app/consts';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { Letter } from '../../models/leter.model';
import { LetterService } from '../../services/letter.service';
import { RowActionButtonInput } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/data-grid-comp/models/data-grid-comp.model';

@Component({
  selector: 'app-created-letters-list',
  templateUrl: './created-letters-list.component.html',
  styleUrls: ['./created-letters-list.component.scss']
})
export class CreatedLettersListComponent implements OnInit {
  public routes: typeof routes = routes;
  
  isLoading: boolean = true;
  letters: Letter[] = [];

  public columns: any[] = [
    {
      field: 'name', 
      title: 'Name'
    }, 
    {
      field: 'description', 
      title: 'Description'
    }
  ];
  public bindingType: String = 'array';
  public gridDataResult: GridDataResult;
  public selectedPositions: any[] = [];
  pageSettings = {
    pageSizes: [5, 10, 20, 50, 100],
  };
  pageSize: number = 20;
  skip: number = 0;
  filterString: string;
  getRequest: any;
  public searchValue: string;
  clearSelectedItems: boolean = false;
  gridActionButtons: RowActionButtonInput[] = [
    {
      id: 'edit',
      name: this.translate.instant('Edit'),
      icon: 'edit'
    },
    {
      id: 'delete',
      name: this.translate.instant('Delete'),
      icon: 'delete'
    }
  ];

  constructor(
    private letterService: LetterService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getLetters();
  }

  getLetters() {
    this.isLoading = true;

    this.letterService.getLetters()
    .pipe(
      finalize( () => {
        this.isLoading = false;
      })
    )
    .subscribe( 
      res => {
        this.letters = res;

        this.gridDataResult = {
          data: res,
          total: res.length,
        };
      }
    );
  }

  rowEvent(event: any) {
    if (event.id === 'edit') {
      this.openEditLetter(event.rowData.id);
    }
    else if (event.id === 'delete') {
      this.openConfirmDeleteDialog(event.rowData.id);
    }
  }

  openEditLetter(letterId: string){
    this.router.navigateByUrl(`SiteSettings/Letters/Editor/${letterId}`);
  }

  openConfirmDeleteDialog(letterId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: 'Are you sure you want to delete this?'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete(letterId);
        }
      }
    ); 
  }

  delete(letterId: string){

  }

  public pageChange(event: PageChangeEvent): void {
      // this.skip = event.skip.toString();
      // this.pageSize = event.take;
      // this.getWorkflows();
  }

  deleteAllSelected(itemsToDelete: string[]) {
    // const observables = itemsToDelete.map(selectedPosition => defer(() => this.holidayService.deleteHolidayGroup(selectedPosition)));

    // this.overlayService.show();

    // forkJoin(observables)
    // .pipe(
    //     finalize(() => this.overlayService.hide())
    // )
    // .subscribe(
    //     (res) => {
    //         this.clearSelectedItems = !this.clearSelectedItems;
    //         this.getHolidayGroups();
    //         this.snackbarService.openSnackBar(`${this.translate.instant('DeletedSuccessfully')}`, 'clear', 'success');
    //     },
    //     err => {
    //         this.snackbarService.openSnackBar(err, 'clear', 'warn');
    //     }
    // );
  }

  filterCallback(filterString: string) {
      // this.filterString = filterString;
      // this.skip = '0';
      // this.getHolidayGroups();
  }

  search(searchValue) {
    // this.searchValue = searchValue;
    // this.filterString = `(Name like "${this.searchValue}")`;
    // this.getRequest.unsubscribe();
    // this.getHolidayGroups();
  }

  navigateToLetter(letter?: Letter) {
    if(letter){
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.LETTERS}${routes.EDITOR}/${letter?.id}`], {relativeTo: this.route});
    }
    else {
      this.router.navigate([`${routes.SITE_SETTINGS}${routes.LETTERS}${routes.EDITOR}`], {relativeTo: this.route});
    }
  }

}

