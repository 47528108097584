import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Job, JobPosition, JobSubmit } from '../models/job-management.model';
import { PagedData } from '@app/modules/lookup/models/paged-data.model';
import { EnvironmentService } from "@app/core/services/environment.service";


const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root'
})
export class JobManagementService {

    constructor(private http: HttpClient, private envService: EnvironmentService) {
    }

    getJobs(skip?: string, take?: string, filterString?: string, sortString?: string, includeEmployees?: string): Observable<PagedData<any>> {

        //TODO replace dummy result with time off in lieu api
        let dummyData: PagedData<any> = new PagedData<any>()
        dummyData.data = this.getJobList();
        dummyData.total = 2;
        return of(dummyData);
    }

    getJob(jobId: string, asOf?: string): Observable<any> {
        return of(this.getJobList().find(job => job.id === jobId));
    }

    postJob(job: JobSubmit): Observable<any> {
        return of({success: true, message: 'Request successful', job});
    }

    putJob(jobId: string, job: JobSubmit): Observable<any> {
        return of({success: true, message: 'Request successful', jobId});
    }

    deleteJob(jobId: string): Observable<any> {
        return of({success: true, message: 'Request successful', jobId});
    }

    getJobPositions(jobId: string): Observable<JobPosition[]> {
        return of([this.generateJobPosition('22', "Position 1"), this.generateJobPosition("23", "Position 2")]);
    }


    getJobList() {
        // let job1: Job = {
        //     id: "1",
        //     name: "Software Engineer",
        //     nameLocalizations: [
        //         {culture: "en-CA", text: "Software Engineer"}
        //     ],
        //     jobCode: "code#2123",
        //     jobFamily: "Information Technology",
        //     jobGroup: "Technical",
        //     jobBand: "Band 5",
        //     jobType: "full_time",
        //     effectiveDate: "2023-06-01",
        //
        //     assistant: false,
        //     functionalJobTitle: "Software Engineer",
        //     region: "North America",
        //     division: "Engineering",
        //     subDivision: "Backend",
        //     department: "Development",
        //     jobStep: "Step 3",
        //     projectTeam: "Project X",
        //     marketPositionTitle: "Software Engineer",
        //     marketPosition: "Mid-level",
        //     marketView: "Internal",
        //     unionCode: "UNION-001",
        //     shiftCode: "SHIFT-001",
        //     employmentGroup: "Group A",
        //     employeeCategory: "Category 1",
        //     employmentType: "Permanent",
        //     workLocation: "Headquarters",
        //     workRotationStartDate: "2023-06-01",
        //     parentPositions: "Manager",
        //     childPositions: "",
        //     workRotation: {
        //         id: "67890",
        //         name: "Weekday Rotation",
        //         elements: [
        //             {
        //                 id: "element-1",
        //                 startTime: "08:00 AM",
        //                 endTime: "05:00 PM",
        //                 numberOfDays: 5,
        //                 isWorkday: true
        //             }
        //         ]
        //     },
        //     employeesInPosition: [
        //         {
        //             id: "emp-1",
        //             firstName: "John",
        //             lastName: "Doe",
        //             image: "https://example.com/johndoe.jpg"
        //         }
        //     ],
        //     endDate: "2023-12-31"
        // };
        //
        //
        // let job2: Job = {
        //     id: "2",
        //     name: "QA Engineer",
        //     nameLocalizations: [
        //         {culture: "en-CA", text: "QA Engineer"}
        //     ],
        //     jobCode: "code#2123",
        //     jobFamily: "Information Technology",
        //     jobGroup: "Technical",
        //     jobBand: "Band 5",
        //     jobType: "Full-time",
        //     effectiveDate: "2023-06-01",
        //
        //     assistant: false,
        //     functionalJobTitle: "Software Engineer",
        //     region: "North America",
        //     division: "Engineering",
        //     subDivision: "Backend",
        //     department: "Development",
        //     jobStep: "Step 3",
        //     projectTeam: "Project X",
        //     marketPositionTitle: "Software Engineer",
        //     marketPosition: "Mid-level",
        //     marketView: "Internal",
        //     unionCode: "UNION-001",
        //     shiftCode: "SHIFT-001",
        //     employmentGroup: "Group A",
        //     employeeCategory: "Category 1",
        //     employmentType: "Permanent",
        //     workLocation: "Headquarters",
        //     workRotationStartDate: "2023-06-01",
        //     parentPositions: "Manager",
        //     childPositions: "",
        //     workRotation: {
        //         id: "67890",
        //         name: "Weekday Rotation",
        //         elements: [
        //             {
        //                 id: "element-1",
        //                 startTime: "08:00 AM",
        //                 endTime: "05:00 PM",
        //                 numberOfDays: 5,
        //                 isWorkday: true
        //             }
        //         ]
        //     },
        //     employeesInPosition: [
        //         {
        //             id: "emp-1",
        //             firstName: "John",
        //             lastName: "Doe",
        //             image: "https://example.com/johndoe.jpg"
        //         }
        //     ],
        //     endDate: "2023-12-31"
        // };

        let job1: Job = {
            id: "1",
            name: "Software Engineer",
            nameLocalizations: [
                {culture: "en-CA", text: "Software Engineer"}
            ],
            jobCode: "code#2123",
            jobFamily: "information_technology",
            jobGroup: "technical",
            jobBand: "band_5",
            jobType: "full_time",
            effectiveDate: "2023-06-01",

            assistant: false,
            functionalJobTitle: "Software Engineer",
            region: "North America",
            division: "Engineering",
            subDivision: "Backend",
            department: "Development",
            jobStep: "step_3",
            projectTeam: "Project X",
            marketPositionTitle: "software_engineer",
            marketPosition: "mid_level",
            marketView: "internal",
            unionCode: "UNION-001",
            shiftCode: "SHIFT-001",
            employmentGroup: "Group A",
            employeeCategory: "Category 1",
            employmentType: "Permanent",
            workLocation: "Headquarters",
            workRotationStartDate: "2023-06-01",
            parentPositions: "Manager",
            childPositions: "",
            workRotation: {
                id: "67890",
                name: "Weekday Rotation",
                elements: [
                    {
                        id: "element-1",
                        startTime: "08:00 AM",
                        endTime: "05:00 PM",
                        numberOfDays: 5,
                        isWorkday: true
                    }
                ]
            },
            employeesInPosition: [
                {
                    id: "emp-1",
                    firstName: "John",
                    lastName: "Doe",
                    image: "https://example.com/johndoe.jpg"
                }
            ],
            endDate: "2023-12-31"
        };


        let job2: Job = {
            id: "2",
            name: "QA Engineer",
            nameLocalizations: [
                {culture: "en-CA", text: "QA Engineer"}
            ],
            jobCode: "code#2124",
            jobFamily: "information_technology",
            jobGroup: "technical",
            jobBand: "band_5",
            jobType: "full_time",
            effectiveDate: "2023-06-01",

            assistant: false,
            functionalJobTitle: "QA Engineer",
            region: "North America",
            division: "Engineering",
            subDivision: "Testing",
            department: "Quality Assurance",
            jobStep: "step_2",
            projectTeam: "Project Y",
            marketPositionTitle: "product_manager",
            marketPosition: "entry_level",
            marketView: "external",
            unionCode: "UNION-002",
            shiftCode: "SHIFT-002",
            employmentGroup: "Group B",
            employeeCategory: "Category 2",
            employmentType: "Permanent",
            workLocation: "Test Site",
            workRotationStartDate: "2023-06-01",
            parentPositions: "QA Manager",
            childPositions: "",
            workRotation: {
                id: "67891",
                name: "Weekend Rotation",
                elements: [
                    {
                        id: "element-2",
                        startTime: "09:00 AM",
                        endTime: "06:00 PM",
                        numberOfDays: 5,
                        isWorkday: true
                    }
                ]
            },
            employeesInPosition: [],
            endDate: "2023-12-31"
        };

        return [job1, job2];
    }


    generateJobPosition(id: string = "12", name: string = "Dummy Position"): JobPosition {
        let dummyJobPosition: JobPosition = {id: id, name: name};
        return dummyJobPosition;
    }

    generateMarketData() {
        let dummyData: any[] = [
            {priority: 1, marketSurveySource: 'Source 1', marketPositionTitle: 'Title 1', marketMinimum: 1000, marketMaximum: 2000},
            {priority: 2, marketSurveySource: 'Source 2', marketPositionTitle: 'Title 2', marketMinimum: 1500, marketMaximum: 2500},
            {priority: 3, marketSurveySource: 'Source 3', marketPositionTitle: 'Title 3', marketMinimum: 1200, marketMaximum: 2200}
        ];
        return dummyData;
    }

}
