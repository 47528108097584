<mat-card>
    <mat-card-title class="header">
      <h5 class="title">Who is out of office</h5>
    </mat-card-title>
  
    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="employee" role="listitem" *ngFor="let employee of employees">
                    <div class="leave-details">
                        <div>{{employee.leaveType}}</div>
                        <div class="date">{{employee.leaveStartDate | date:'mediumDate'}} - {{employee.leaveEndDate | date:'mediumDate'}}</div>
                    </div>
                    <div class="employee-details">
                        <app-employee-image class="profile-pic" round="true" width="50" height="50" [employeeId]="employee.id"></app-employee-image>
                        <div>
                            <span class="name" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{employee.id}}">
                                {{employee.firstName}} {{employee.lastName}}
                            </span>
                            <div class="positions">
                                <span *ngFor="let position of employee.positions">
                                    {{position.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>
            <ng-container *ngIf="employees.length === 0">
                <h6>No bithdays coming up</h6>
            </ng-container>
        </ng-template>
    </mat-card-content>
</mat-card>