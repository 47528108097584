import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Workflow } from '../../models/workflows.model';

@Component({
  selector: 'app-workflow-dialog',
  templateUrl: './workflow-dialog.component.html',
  styleUrls: ['./workflow-dialog.component.scss']
})
export class WorkflowDialogComponent implements OnInit {
  workflow: Workflow;
  clearCompleted: boolean;

  constructor(
    private dialogRef: MatDialogRef<WorkflowDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.workflow = data.workflow
    this.clearCompleted = data.clearCompleted
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
