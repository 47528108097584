<app-workflow-section>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'JourneyEditor' | translate: {Default: "Journey Editor"} }}</h5>

            <div class="k-flex-grow"></div>
            <button 
                mat-raised-button
                routerLink="{{routes.SITE_SETTINGS}}{{routes.WORKFLOWS}}{{routes.WORKFLOWS_LIST}}">
                {{ 'Close' | translate: {Default: "Close"} }}
            </button>

            <button 
                mat-raised-button color="primary" 
                setColor="primaryColour"
                [disabled]="workflowDetails.invalid" 
                appPreventDoubleClick 
                (throttledClick)="save()">
                {{ 'Save' | translate: {Default: "Save"} }}
            </button>
        </mat-card-title>
        <mat-card-content>

            <div class="workflow-details">
                <form class="workflow-details-form" [formGroup]="workflowDetails">
                    <h5>{{ 'JourneyDetails' | translate: {Default: "Journey Details"} }}</h5>

                    <mat-form-field appearance="fill">
                        <mat-label>Name</mat-label>

                        <input matInput type="text" formControlName="name">
                    </mat-form-field>

                    <mat-form-field appearance="fill">
                        <mat-label>Description</mat-label>

                        <textarea matInput formControlName="description"></textarea>
                    </mat-form-field>
                  
                    <!-- <button type="submit" [disabled]="myForm.invalid">Submit</button> -->
                  </form>
            </div>

            <div class="right">
                <div class="categories-section">
                    <div class="categories-section-header">
                        <h5>{{ 'Categories' | translate: {Default: "Categories"} }}</h5>

                        <div class="k-flex-grow"></div>

                        <button 
                            mat-raised-button
                            appPreventDoubleClick 
                            (throttledClick)="addNewCategory()">
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">add</mat-icon> {{ 'AddCategory' | translate: {Default: "Add Category"} }}
                        </button>
                    </div>
                    <div class="categories">
                        <div class="category" *ngFor="let category of categories; let i=index">
                            <app-workflow-editor-category [category]="category"></app-workflow-editor-category>
                            <!-- <app-survey-editor-question [question]="question"></app-survey-editor-question> -->
                            <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon" (click)="removeCategory(i)">highlight_off</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

        </mat-card-content>
    </mat-card>
</app-workflow-section>