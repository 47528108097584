import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethnicity-bonus-pay',
  templateUrl: './ethnicity-bonus-pay.component.html',
  styleUrls: ['./ethnicity-bonus-pay.component.scss']
})
export class EthnicityBonusPayComponent implements OnInit {
  data = [
    {
      category: "Mean",
      percentage: "20.0%",
    },
    {
      category: "Median",
      percentage: "50.0%",
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
