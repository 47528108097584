import { Component, Input, OnInit } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import { Document, DocumentUpdate } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/models/document.model';
import {
  TreeItemDropEvent,
  TreeItemLookup,
  DropAction,
} from "@progress/kendo-angular-treeview";
import { Observable } from 'rxjs';
import * as fileSaver from 'file-saver';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { EditDocumentDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-documents/components/edit-document-dialog/edit-document-dialog.component';
import { FileUploadDialogComponent } from '@app/shared/components/file-upload-dialog/file-upload-dialog.component';
import { api_routes, routes } from '@app/consts';
import { CreateDirectoryDialogComponent } from '@app/shared/components/create-directory-dialog/create-directory-dialog.component';
import { PositionDocumentsService } from '../../../positions/components/position-documents/services/position-documents.service';

const isOfType = (fileName: string, ext: string) =>
  new RegExp(`.${ext}\$`).test(fileName);
const isFile = (name: string) => name.split(".").length > 1;

@Component({
  selector: 'app-job-documents',
  templateUrl: './job-documents.component.html',
  styleUrls: ['./job-documents.component.scss']
})
export class JobDocumentsComponent implements OnInit {
  @Input() positionId: string;

  uploadApiPath: string;
  isLoading: boolean = true;
  documentsList$: Observable<Document[]>;
  public expandedKeys: any[] = [];
  public pageSize = 1;
  public routes: typeof routes = routes;

  icons = {
    'application/pdf': 'assets/icons/pdf-icon.svg',
    'application/msword': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'assets/icons/excel-icon.svg',
    'application/vnd.oasis.opendocument.spreadsheet': 'assets/icons/ods-icon.svg',
    'text/csv': 'assets/icons/csv-icon.svg',
    'application/zip': 'assets/icons/zip-icon.svg',
    'image/jpeg': 'assets/icons/jpg-icon.svg'
  };

  constructor(
    private positionDocumentsService: PositionDocumentsService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog,
    private overlayService: OverlayService
  ) { }

  ngOnInit(): void {
    this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
    this.uploadApiPath = `${api_routes.POSITIONS}/${this.positionId}/${api_routes.DOCUMENTS}`;
  }

  public hasChildren = (item: any) => item.isFolder === true;

  public fetchChildren = (item: any) => this.positionDocumentsService.getDocuments(this.positionId, item.id);

  public getDragStatus(
    action: DropAction,
    destinationItem: TreeItemLookup
  ): string {
    if (
      destinationItem && destinationItem.item.dataItem.isFolder === false
    ) {
      console.log(destinationItem)
      return "k-i-cancel";
    }

    switch (action) {
      case DropAction.Add:
        return "k-i-plus";
      case DropAction.InsertTop:
        return "k-i-insert-up";
      case DropAction.InsertBottom:
        return "k-i-insert-down";
      case DropAction.InsertMiddle:
        return "k-i-insert-middle";
      case DropAction.Invalid:
      default:
        return "k-i-cancel";
    }
  }

  public handleDrop(event: TreeItemDropEvent): void {
    if( event.destinationItem.item.dataItem.isFolder ) {
      this.moveItem({
        'destination': event.destinationItem.item.dataItem,
        'itemToMove': event.sourceItem.item.dataItem
      });
    }
  }

  createDirectory(name: string, parentDirectoryID: string) {
    this.positionDocumentsService.uploadDocument(this.positionId, null, name, parentDirectoryID)
    .subscribe(
      (res) => {
        this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
        this.snackbarService.openSnackBar('Directory saved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  download(document: Document) {
    this.overlayService.show('Downloading');
    this.positionDocumentsService.downloadDocument(this.positionId, document.id)
    .subscribe(
      response => {
        console.log(response.headers);

        let blob:any = new Blob([response.body], { type: document.contentType });
        fileSaver.saveAs(blob, document.name);
        this.overlayService.hide();

		  }
    ),
    err => this.snackbarService.openSnackBar(err, 'clear', 'warn');
  }

  delete(id: string) {
    this.positionDocumentsService.delete(this.positionId, id)
    .subscribe(
      (res) => {
        this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
        this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  moveItem(details: {'destination': Document, 'itemToMove': Document}) {
    const updateDetails: DocumentUpdate = {
      parent: details.destination ? details.destination.id : null,
      comments: details.itemToMove.comments,
      name: details.itemToMove.name
    }

    this.positionDocumentsService.updateDocument(this.positionId, details.itemToMove.id, updateDetails)
    .subscribe(
      (res) => {
        this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
        this.snackbarService.openSnackBar('Moved successfully', 'clear', 'success');
      },
      err => {
        this.snackbarService.openSnackBar(err, 'clear', 'warn');
      }
    );
  }

  openConfirmDeleteDialog(itemToDelete: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to delete ${itemToDelete.name}?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete(itemToDelete.id);
        }
      }
    );
  }

  openDirectoryFormDialog(parentDirectory?: Directory) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      parentDirectory: parentDirectory ? parentDirectory.id : null,
      uploadApiPath: this.uploadApiPath,
    };

    const dialogRef = this.dialog.open(CreateDirectoryDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data){
          this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
          this.snackbarService.openSnackBar('Directory created', 'clear', 'success');
          // this.createDirectory(data.directoryName, parentDirectory ? parentDirectory.id : null);
        }
      }
    );
  }

  openDocumentUploadDialog(parentDirectory?: Directory) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      parentDirectory: parentDirectory ? parentDirectory.id : null,
      uploadApiPath: this.uploadApiPath,
    };

    const dialogRef = this.dialog.open(FileUploadDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data) {
          this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
          this.snackbarService.openSnackBar('Document uploaded', 'clear', 'success');
        }
      }
    );
  }

  openDocumentEditDialog(document: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = 500;

    dialogConfig.data = {
      employeeId: this.positionId,
      document: document
    };

    const dialogRef = this.dialog.open(EditDocumentDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if(data === true) {
          this.documentsList$ = this.positionDocumentsService.getDocuments(this.positionId);
          this.snackbarService.openSnackBar('Document updated', 'clear', 'success');
        }
      }
    );
  }


}



