import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {EmployeeService} from '@app/core/services/employee.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import {SnackbarService} from '@app/core/services/snackbar.service';
import {OverlayService} from '@app/shared/components/overlay/overlay.service';
import {EmployeeTableSecurity, TalentVerbose} from '@app/shared/models/employee.model';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AccessLogsDialogComponent} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import {HistoryDialogComponent} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import {api_routes, db_tables} from '@app/consts';
import { ActivatedRoute } from '@angular/router';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

@Component({
  selector: 'app-personal-details-form',
  templateUrl: './personal-details-form.component.html',
  styleUrls: ['./personal-details-form.component.scss']
})
export class PersonalDetailsFormComponent implements OnInit {
  @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
  @Input() employeeId: string;
  
  public db_tables = db_tables;
  isLoading: boolean = true;
  talent: TalentVerbose;
  formData: any;
  formId: string = 'frm_C2EziiNXjk0igH';
  changeReasonFormId: string = 'frm_yPsGlEqUSjqwkp';
  getFormData: boolean = false;
  formValid: boolean = false;
  tableId: string = db_tables.Talent;
  effectiveDate = new Date();

  public columns: any[] = [
    {field: 'userId', title: 'User ID', tableId: "tfi_EmpUserId"},
    {field: 'firstname', title: 'firstname', tableId: "tfi_FirstName"},
    {field: 'lastname', title: 'lastname', tableId: "tfi_LastName"},
    {field: 'middleName', title: 'middle Name', tableId: "tfi_MiddleName"},
    {field: 'preferredName', title: 'preferred Name', tableId: "tfi_PreferredName"},
    {field: 'maidenName', title: 'maidenName', tableId: "tfi_MaidenName"},
    {field: 'birthday', title: 'birthday', type: 'date', tableId: "tfi_Birthday"},
    {field: 'username', title: 'username', tableId: "tfi_Username"},
    {field: 'culture', subField: "text", title: 'culture', tableId: "tfi_CulturalAffiliation"},
    {field: 'gender', subField: "text", title: 'gender', tableId: "tfi_Gender"},
    {field: 'maritalStatus', subField: "text", title: 'marital Status', tableId: "tfi_MaritalStatus"},
    {field: 'culturalAffiliation', subField: "text", title: 'cultural Affiliation', tableId: "tfi_CulturalAffiliation"},
    {field: 'religiousAffiliation', subField: "text", title: 'religious Affiliation', tableId: "tfi_Religious"},
    // {field: 'preferredLanguage', subField: "text", title: 'preferred Language', tableId: "tfi_BankAccountName"},
    {field: 'title', subField: "text", title: 'title', tableId: "tfi_Title"},
    {field: 'linkedInUrl', title: 'linkedIn Url', tableId: "tfi_EmpLinkedInUrl"},
    {field: 'twitterHandle', title: 'twitter Handle', tableId: "tfi_EmpTwitterHandle"},
    {field: 'facebookUrl', title: 'facebook Url', tableId: "tfi_EmpFacebookUrl"},
    {field: 'streetAddress', title: 'street Address', tableId: "tfi_EmpStreetAddress"},
    {field: 'city', title: 'city', tableId: "tfi_EmpCity"},
    {field: 'province', title: 'province', tableId: "tfi_EmpProvince"},
    {field: 'zip', title: 'zip', tableId: "tfi_EmpZip"},
    {field: 'country', subField: "text", title: 'country', tableId: "tfi_EmpCountry"},
    {field: 'email', title: 'email', tableId: "tfi_EmpEmail"},
    {field: 'workPhone', title: 'work Phone', tableId: "tfi_EmpWorkPhone"},
    {field: 'homePhone', title: 'home Phone', tableId: "tfi_EmpHomePhone"},
    {field: 'cellPhone', title: 'cell Phone', tableId: "tfi_EmpCellPhone"},
    {field: 'timezone', subField: "text", title: 'timezone', tableId: "tfi_EmpTimezone"},
  ];
  formPristine: boolean;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoadingPermissions: boolean = true;

  constructor(
    private dialog: MatDialog,
    private employeeService: EmployeeService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) { 
    // this.employeeId = this.getIdInURL();
  }

  ngOnInit(): void {
    this.getTargetEmployeeTablePermissions();
    this.getEmployeePersonalDetails();
  }

  get tableReadAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getEmployeePersonalDetails(){
    this.isLoading = true; 

    let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

    this.employeeService.getTalent(this.employeeId, asOf)
    .pipe(
      finalize(()=>{ 
        this.isLoading = false; 
      })
      )
      .subscribe(
        res => {
          this.talent = res;
          this.createFormData();
      }
    )
  }

  createFormData() {
    this.isLoading = true; 

    this.formData = {
      id: this.talent ? this.talent.id : null,
      userId: this.talent ? this.talent.userId : null,
      asOf: moment().format(),
      changeReason: null,
      changeReasonComments: '',
      firstname: this.talent ? this.talent.firstName : null,
      lastname: this.talent ? this.talent.lastName : null,
      middleName: this.talent ? this.talent.middleName : null,
      preferredName: this.talent ? this.talent.preferredName : null,
      maidenName: this.talent ? this.talent.maidenName : null,
      gender: this.talent ? this.talent.gender?.id : null,
      birthday: this.talent ? this.talent.birthday : null,
      maritalStatus: this.talent ? this.talent.maritalStatus?.id : null,
      culturalAffiliation: this.talent ? this.talent.culturalAffiliation?.id : null,
      religiousAffiliation: this.talent ? this.talent.religiousAffiliation?.id : null,
      preferredLanguage: this.talent ? this.talent.preferredLanguage : null,
      title: this.talent ? this.talent.title?.id : null,
      customClientId: this.talent ? this.talent.customClientId : null,
      username: this.talent ? this.talent.username : null,
      linkedInUrl: this.talent ? this.talent.linkedInUrl : null,
      twitterHandle: this.talent ? this.talent.twitterHandle : null,
      facebookUrl: this.talent ? this.talent.facebookUrl : null,
      streetAddress: this.talent ? this.talent.streetAddress : null,
      city: this.talent ? this.talent.city : null,
      province: this.talent ? this.talent.province : null,
      zip: this.talent ? this.talent.zip : null,
      country: this.talent ? this.talent.country?.id : null,
      email: this.talent ? this.talent.email : null,
      workPhone: this.talent ? this.talent.workPhone : null,
      homePhone: this.talent ? this.talent.homePhone : null,
      cellPhone: this.talent ? this.talent.cellPhone : null,
      timezone: this.talent ? this.talent.timezone?.id : null,
      culture: this.talent ? this.talent.culture?.id : null,
      organization: this.talent ? this.talent.organization : null,
      streetAddress2: this.talent ? this.talent.streetAddress2 : null,
      municipality: this.talent ? this.talent.municipality : null,
      region1: this.talent ? this.talent.region1 : null,
      region2: this.talent ? this.talent.region2 : null,
      region3: this.talent ? this.talent.region3 : null,
      neighbourhood: this.talent ? this.talent.neighbourhood : null,
      email2: this.talent ? this.talent.email2 : null,
      internetAddress: this.talent ? this.talent.internetAddress : null,
      socialSecurityNumber: this.talent ? this.talent.socialSecurityNumber : null,
      identityNumber: this.talent ? this.talent.identityNumber : null,
      regionOfBirth: this.talent ? this.talent.regionOfBirth : null,
      townOfBirth: this.talent ? this.talent.townOfBirth : null,
      poBox: this.talent ? this.talent.poBox : null,
      registeredDisabled: this.talent ? this.talent.registeredDisabled?.id : null,
      disability: this.talent ? this.talent.disability?.id : null,
      veteranStatus: this.talent ? this.talent.veteranStatus?.id : null,
      equalEmploymentOpportunity: this.talent ? this.talent.equalEmploymentOpportunity?.id : null,
      nationality: this.talent ? this.talent.nationality?.id : null,
      countryOfBirth: this.talent ? this.talent.countryOfBirth?.id : null,
      preferredPronoun: this.talent ? this.talent.preferredPronoun : null,
      sexualOrientation: this.talent ? this.talent.sexualOrientation?.id : null,
    }

    this.isLoading = false; 
  }

  formDataEmitted(formData) {
    this.openChangeReasonDialog(formData);
    // this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  formPristineEmitted(formPristine: boolean) {
    this.formPristine = formPristine;
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
              this.addChangeReasonsToFormData(formData, data);
          }

      }
    );
  }

  addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.save(merged);
  }

  save(formData) {
    formData.organization = formData.organization?.id;
    formData.asOf = this.effectiveDate;
    formData.originalCreatedOn = this.talent?.version?.createdOn ? this.talent?.version?.createdOn : null;

    this.overlayService.show();

    if(this.employeeId) {
      this.employeeService.updateTalent(formData)
      .pipe(
        finalize(()=>{ 
          this.overlayService.hide();
        })
      )
      .subscribe(
        (res) => {
          this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
        }
      );
    }
  }

  openAccessLogsDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeId,
          entityId: null,
          url: `${api_routes.TALENT}/${this.employeeId}`
      };

      const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  openHistoryDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeId,
          entityId: null,
          url: `${api_routes.TALENT}/${this.employeeId}`,
          columns: this.columns,
          tableId: this.tableId
      };

      const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  effectiveDateChange(event) {
    if(this.talent){
        this.getEmployeePersonalDetails();
    }
  }
}
