<app-site-settings-menu>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'IntegrationConfiguration' | translate: {Default: "Integration Configuration"} }}</h5>
        </mat-card-title>
        <mat-card-content>
            <div>
                <mat-tab-group class="my-mat-tab-group" [selectedIndex]="selectedIndex" (selectedTabChange)="selectedTabChange($event)">
                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">1</span> INTEGRATION SETTINGS </ng-template>
                        <div class="tab-container">
                            <app-integration-settings
                                *ngIf="!loadingIntegration"
                                [(integration)]="integration"
                            ></app-integration-settings>
                            <!-- <app-workflow-publish-editor-select-workflow
                                [selectedWorkflow]="selectedWorkflow"
                                (selectedWorkflowChanged)="updatedSelectedWorkflow($event)"
                            ></app-workflow-publish-editor-select-workflow> -->
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">2</span> SELECT PARTICIPANTS </ng-template>
                        <div class="tab-container">
                            <app-integration-select-employees
                                *ngIf="!loadingEmployees"
                                [(selectedEmployees)]="integration.employees"
                                (emitSelectedEmployees)="integration.employees = $event"
                            ></app-integration-select-employees>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">3</span> VIEW API </ng-template>
                        <div class="tab-container">
                            <div id="swagger-ui-item"></div>

                            <!-- <iframe [src]="integration.docsUrl" frameborder="0" width="100%" height="650"></iframe> -->
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">4</span> VIEW MAPPINGS </ng-template>
                        <div class="tab-container">
                            <app-integration-field-mappings [mappingId]="integration?.mappingId"></app-integration-field-mappings>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label> <span class="mat-tab-label-number">5</span> REVIEW AND SAVE </ng-template>
                        <div class="tab-container review-section">

                            <div class="section integration-settings">
                                <h6>Integration Settings</h6>
                                <p><span class="label">Name:</span> {{integration.name}}</p>
                                <p><span class="label">Description:</span> {{integration.description}}</p>
                                <p><span class="label">Frequency:</span> {{integration.frequency}}</p>
                                <p><span class="label">Start Date:</span> {{integration.startDate | date:'mediumDate'}}</p>
                            </div>

                            <div class="section connection-settings">
                                <h6>Connection Details</h6>
                                <p><span class="label">Client Id:</span> {{integration.connectionSettings.clientId}}</p>
                                <p><span class="label">Client Secret:</span> {{integration.connectionSettings.clientSecret}}</p>
                                <p><span class="label">API URL:</span> {{integration.connectionSettings.apiurl}}</p>
                                <p><span class="label">Auth URL:</span> {{integration.connectionSettings.authurl}}</p>
                                <p><span class="label">Worker ID:</span> {{integration.connectionSettings.workerId}}</p>
                                <p><span class="label">Association OID:</span> {{integration.connectionSettings.associateOID}}</p>
                            </div>

                            <div class="section selected-employees">
                                <h6>Selected Participants</h6>

                                <mat-chip-list>
                                    <mat-chip *ngFor="let employee of integration.employees" class="yellow-chip">
                                        <div class="employee">
                                            <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                    
                                            <span class="employee-name">
                                                {{employee.firstname}} {{employee.lastname}}
                                            </span>
                                        </div>
                                    </mat-chip>
                                </mat-chip-list>
                            </div>

                            <!-- <div class="review-sub-section details">
                                <h5>Publish Journey Details</h5>

                                <div class="name">{{workflowPublishDetails.get('name').value}}</div>

                                <div class="description">{{workflowPublishDetails.get('description').value}}</div>
                                <div class="start-date">
                                    <span class="label">Start Date</span> 
                                    {{workflowPublishDetails.get('startDate').value | date:'mediumDate'}}
                                </div>
                                <div class="end-date">
                                    <span class="label">End Date</span>
                                    {{workflowPublishDetails.get('endDate').value | date:'mediumDate'}}
                                </div>

                                <mat-chip-list>
                                    <mat-chip class="green-chip" *ngIf="workflowPublishDetails.get('status').value === 'complete'">Complete</mat-chip>
                                    <mat-chip class="red-chip" *ngIf="workflowPublishDetails.get('status').value === 'cancelled'">Cancelled</mat-chip>
                                    <mat-chip class="yellow-chip" *ngIf="workflowPublishDetails.get('status').value === 'inProgress'">In Progress</mat-chip>
                                </mat-chip-list>
                            </div>

                            <div class="review-sub-section selected-journey">
                                <h5>Journey</h5>

                                <h6>{{selectedWorkflow?.name}}</h6>
                            </div>
                            
                            <div class="review-sub-section selected-participants employees">
                                <h5>Participants</h5>

                                <ng-container *ngFor="let employee of selectedParticipants">
                                    <div class="employee">
                                        <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                
                                        <span class="employee-name">
                                            {{employee.firstname}} {{employee.lastname}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div>

                            <div class="review-sub-section selected-owners employees">
                                <h5>Owners</h5>

                                <ng-container *ngFor="let employee of selectedOwners">
                                    <div class="employee">
                                        <app-employee-image [employeeId]="employee.id" [width]="35" [height]="35" [round]="true"></app-employee-image>
                
                                        <span class="employee-name">
                                            {{employee.firstname}} {{employee.lastname}}
                                        </span>
                                    </div>
                                </ng-container>
                            </div> -->

                        </div>
                    </mat-tab>
                </mat-tab-group>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <button class="continue-button" mat-flat-button color="primary" (click)="navigationButtonClicked(true)">{{ selectedIndex === 4 ? 'Save' : 'Continue' }}</button>
            <button mat-button [disabled]="selectedIndex === 0" (click)="navigationButtonClicked(false)">Previous Step</button>
          </mat-card-actions>
    </mat-card>
</app-site-settings-menu>
