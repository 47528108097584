import { Component, OnInit } from '@angular/core';
import { HugoDocumentsService } from './services/hugo-documents.service';
import { HugoDocument } from './models/hugo-documents.model';
import { DropAction, TreeItemDropEvent, TreeItemLookup } from '@progress/kendo-angular-treeview';
import { BreadCrumbItem } from '@progress/kendo-angular-navigation';
import { routes } from '@app/consts';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EditHugoDocumentComponent } from './components/edit-hugo-document/edit-hugo-document.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckDeviceService } from '@app/core/services/check-device/check-device.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { EditHugoDirectoryDialogComponent } from './components/edit-hugo-directory-dialog/edit-hugo-directory-dialog.component';

@Component({
  selector: 'app-hugo-documents',
  templateUrl: './hugo-documents.component.html',
  styleUrls: ['./hugo-documents.component.scss']
})
export class HugoDocumentsComponent implements OnInit {
  public routes: typeof routes = routes;
  documents: HugoDocument[] = [];  
  
  public breadcrumbs: BreadCrumbItem[] = [];

  icons = {
    'application/pdf': 'assets/icons/pdf-icon.svg',
    'application/msword': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'assets/icons/excel-icon.svg',
    'application/vnd.oasis.opendocument.spreadsheet': 'assets/icons/ods-icon.svg',
    'text/csv': 'assets/icons/csv-icon.svg',
    'application/zip': 'assets/icons/zip-icon.svg',
    'image/jpeg': 'assets/icons/jpg-icon.svg'
  };

  isLoading: boolean = true;
  isLoadingBreadcrumbs: boolean = false;
  isMobile: boolean;
  document: HugoDocument;

  constructor(
    private hugoDocumentsService: HugoDocumentsService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private overlayService: OverlayService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private checkDeviceService: CheckDeviceService,
    private router: Router
  ) { 
    this.checkDeviceService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });

    this.route.queryParams.subscribe(
      params => {
        const directoryId = params['directoryId'];

        if(directoryId) {
          this.hugoDocumentsService.getDocument(directoryId)
          .subscribe(
            document => {
              this.document = document;
              this.openDirectory();
            }
          );
        }
        else {
          this.openDirectory();
        }
      }
    );
  }

  ngOnInit(): void {
    // this.openDirectory();
  }

  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getDocuments(document?: HugoDocument): void {
    this.isLoading = true;

    this.hugoDocumentsService.getDocuments(document?.id)
    .subscribe(
      documents => {
        this.documents = documents;
        this.documents.sort((a, b) => {
          if (a.isDirectory && !b.isDirectory) return -1;
          if (!a.isDirectory && b.isDirectory) return 1;
          return 0;
        });
        this.isLoading = false;
      }
    );
  }

  changeRoute(item: HugoDocument): void {
    this.route.paramMap.subscribe(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { directoryId: item.id },
        queryParamsHandling: 'merge'
      });
    });
  }

  openDirectory(): void {
    this.breadcrumbs = [
      {
        text: this.document?.name || 'Home',
        title: this.document?.id || null
      }
    ];
    this.getDocuments(this.document);
    this.buildBreadcrumbs(this.document);
  }

  buildBreadcrumbs(item?: HugoDocument): void {
    if(item && item.parentDirectoryId !== null) {
      this.hugoDocumentsService.getDocument(item.parentDirectoryId)
      .subscribe(
        parent => {
          this.breadcrumbs = [
            {
              text: parent.name,
              title: parent.id
            },
            ...this.breadcrumbs
          ];
          this.buildBreadcrumbs(parent);
        }
      );
    }
    else if(this.document){
      this.breadcrumbs = [
        {
          text: 'Home',
          title: null
        },
        ...this.breadcrumbs
      ];
    }
  }

  public onItemClick(item: BreadCrumbItem): void {
    const index = this.breadcrumbs.findIndex((e) => e.text === item.text);
    this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);

    this.route.paramMap.subscribe(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { directoryId: item.title },
        queryParamsHandling: 'merge'
      });
    });
  }

  openEditDirectoryDialog(item?: HugoDocument): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = this.isMobile ? 0 : 500;

    dialogConfig.data = {
      directory: item,
      parentDirectoryId: this.breadcrumbs[this.breadcrumbs.length - 1].title
    };

    const dialogRef = this.dialog.open(EditHugoDirectoryDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
          if (data === true) {
            this.openDirectory();
          }
      }
    );
  }

  openEditDocumentDialog(document?: HugoDocument) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = this.isMobile ? 0 : 500;

    dialogConfig.data = {
      document: document,
      parentDirectoryId: this.breadcrumbs[this.breadcrumbs.length - 1].title
    };

    const dialogRef = this.dialog.open(EditHugoDocumentComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
          if (data === true) {
            this.openDirectory();
          }
      }
    );
  }

  openConfirmDeleteDialog(item: HugoDocument) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      localizationCode: 'ConfirmDeleteCommentMessage',
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.deleteDocument(item);
        }
      }
    ); 
  }

  deleteDocument(item: HugoDocument): void {
    this.hugoDocumentsService.deleteDocument(item.id);
    this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
    this.openDirectory();
  }

  clearLocalStorage(): void {
    this.hugoDocumentsService.clearDocumentsFromLocalStorage();
  }

}
