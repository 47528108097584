import { Component, OnInit, ViewChild } from '@angular/core';
import { routes } from '@app/consts';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { ReviewStatusReportService } from '../../services/review-status-report.service';
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { finalize } from 'rxjs';

@Component({
    selector: 'app-review-status-details-data-grid',
    templateUrl: './review-status-report-details-data-grid.component.html',
    styleUrls: ['./review-status-report-details-data-grid.component.scss']
})
export class ReviewStatusReportDetailsDataGridComponent implements OnInit {
    @ViewChild(GridComponent)
    public grid: GridComponent;
    public routes: typeof routes = routes;
    public bindingType = 'array';
    public gridDataResult: GridDataResult;
    isLoading: boolean;
    sortString: string;
    filterString: string;
    filterToggleDetails: {
        text: string,
        checked: boolean
    } = {
        text: 'FilterInactive',
        checked: true
    }

    public columns: any[] = [
        {field: 'reviewPlanName', title: this.translate.instant('ReviewStatusReport-PlanName'), dataType: 'String'},
        {field: 'employeeName', title: this.translate.instant('ReviewStatusReport-EmployeeName'), dataType: 'String'},
        {field: 'employeePosition', title: this.translate.instant('ReviewStatusReport-EmployeePosition'), dataType: 'String'},
        {field: 'managerName', title: this.translate.instant('ReviewStatusReport-ManagerName'), dataType: 'String'},
        {field: 'reviewerName', title: this.translate.instant('ReviewStatusReport-ReviewerName'), dataType: 'String'},
        {field: 'reviewerStatus', title: this.translate.instant('ReviewStatusReport-ReviewStatus'), dataType: 'String'},
    ];
    
    sortableColumns: any[] = [
        {field: 'reviewPlanName', sortValue: 'reviewPlanName'},
        {field: 'employeeName', sortValue: 'employeeName'},
        {field: 'employeePosition', sortValue: 'employeePosition'},
        {field: 'managerName', sortValue: 'managerName'},
        {field: 'reviewerName', sortValue: 'reviewerName'},
        {field: 'reviewerStatus', sortValue: 'reviewerStatus'},
    ];

    filterCategories: any[] = [
    ];

    constructor(
        private translate: TranslateService,
        private reviewStatusReportService: ReviewStatusReportService,
        private employeeContactCardService: EmployeeContactCardService
    ) {

        this.getData();
    }

    ngOnInit(): void {
    }

    getData(): void {
        this.isLoading = true;
        this.reviewStatusReportService.fetch(this.filterString, this.sortString, this.filterToggleDetails.checked)
        .subscribe((response) => {
            this.gridDataResult = response;

            this.gridDataResult.data.forEach(
                x => {
                    this.getMissingEmployeeData(x)
                }
            )

            this.isLoading = false;
        });
    }

    getMissingEmployeeData(row : {
            employeeName: string
            employeePosition: string
            managerName: string
            reviewPlanName: string
            reviewerName: string
            reviewerStatus: string
        }
    ) {
        const [firstName, lastName] = row.employeeName.split(' ');
        let filter = `(firstname like "${firstName}") AND (lastname like "${lastName}")`
        
        this.employeeContactCardService.getAllEmployeeContactCards(0, '100', filter)
        .subscribe(
            res => {
                let empData;

                if(res.data.length > 0) {
                    empData = res.data[0];

                    empData.managers.length > 0 ? row.managerName = `${empData.managers[0].firstName} ${empData.managers[0].lastName}` : null
                    empData.positions.length > 0 ? row.employeePosition = `${empData.positions[0].name}` : null
                }
            }
        )
    }

    sortChange(sortString: string) {
        if (sortString != this.sortString && this.isSortColumnSupported(sortString)) {
            this.sortString = sortString
            this.getData();
        } else {
            console.log(`Sorting by ${sortString} is not supported.`);
        }
    }

    isSortColumnSupported(sortString: string): boolean {
        const columnName = sortString.split('-')[0];
        return this.sortableColumns.some(column => column.sortValue === columnName);
    }


    updateFilterToggleDetails(toggleChange: MatSlideToggleChange) {
        this.filterToggleDetails.checked = toggleChange.checked;
        this.getData();
    }

    filterCallback(filterString: string) {
        this.filterString = filterString;
        console.log(this.filterString);
        this.getData();
    }
}
