import { Component, OnInit } from '@angular/core';
import { api_routes, db_tables } from '@app/consts';
import { TableListFieldOption } from '@app/modules/lookup/models/lookup.model';
import { LookupService } from '@app/modules/lookup/services/lookup.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { finalize, from } from 'rxjs';

@Component({
  selector: 'app-salary-review-data-grid',
  templateUrl: './salary-review-data-grid.component.html',
  styleUrls: ['./salary-review-data-grid.component.scss']
})
export class SalaryReviewDataGridComponent implements OnInit {
  public columns: any[] = [
    { field: "department", title: "Department" },
    { field: "positions", title: "Positions" },
    { field: "totalSalary", title: "Total Salary" },
    { field: "totalBasePay", title: "Total Base Pay" },
    { field: "totalPrimaryCompensation", title: "Total Primary Compensation" },
    { field: "averageBasePay", title: "Average Base Pay" },
    { field: "averagePrimaryCompensationBasis", title: "Average Primary Compensation Basis" },
    { field: "defaultCurrency", title: "Default Currency" },
    { field: "frequency", title: "Frequency" },
    { field: "averageCompaRatio", title: "Average Compa-Ratio" },
    { field: "averagePayRangePenetration", title: "Average Pay Range Penetration" },
  ];
  public bindingType: String = "array";
  public gridDataResult: GridDataResult;
  public api_routes = api_routes;
  public db_tables = db_tables;
  isLoading: boolean;
  pageSize: number = 10;
  skip: string = '0';
  departments: TableListFieldOption[];

  constructor(
    private lookupService: LookupService,
  ) { }

  ngOnInit(): void {
    this.getDepartments();
  }

  getDepartments() {
    from(this.lookupService.getListOptions('DEPARTMENT'))
    .pipe(
      finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.departments = res.data;
        this.createGridData();
      }
    )
  }

  createGridData() {
    this.gridDataResult = {
      data: [],
      total: this.departments.length,
    }

    this.departments.forEach(
      department => {
        this.gridDataResult.data.push(
          {
            department: department.text,
            positions: Math.floor(Math.random() * 101),
            totalSalary: `$${ this.numberWithCommas(Math.floor(Math.random() * 4000000)) }`,
            totalBasePay: `$${ this.numberWithCommas(Math.floor(Math.random() * 4000000)) }`,
            totalPrimaryCompensation: `$${ this.numberWithCommas(Math.floor(Math.random() * 4000000)) }`,
            averageBasePay: `$${ this.numberWithCommas(Math.floor(Math.random() * 400000)) }`,
            averagePrimaryCompensationBasis: `$${ this.numberWithCommas(Math.floor(Math.random() * 400000)) }`,
            defaultCurrency: `USD`,
            frequency: `Annual`,
            averageCompaRatio: (Math.random() * (2 - 1) + 1).toFixed(3),
            averagePayRangePenetration: (Math.random() * (1 - 0) + 1).toFixed(2),
          }
        )
      }
    )
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

}
