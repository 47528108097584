import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-time-off-guide-dialog',
  templateUrl: './time-off-guide-dialog.component.html',
  styleUrls: ['./time-off-guide-dialog.component.scss']
})
export class TimeOffGuideDialogComponent implements OnInit {

  guideTitle: string;
  htmlContent: any;

  constructor(
    private dialogRef: MatDialogRef<TimeOffGuideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.guideTitle = data.guideTitle;
    this.htmlContent = data.htmlContent;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
}
