import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { EmployeeLeaveService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/services/employee-leave.service'
import { EmployeeTimeOffType } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employee-details/components/employee-leave/models/leave-request.model';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { finalize } from 'rxjs/operators';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { RequestTimeOffDialogComponent } from './components/request-time-off-dialog/request-time-off-dialog.component';
import { ViewFutureScheduleDialogComponent } from './components/view-future-schedule-dialog/view-future-schedule-dialog.component';

@Component({
  selector: 'app-time-off-policy-widget',
  templateUrl: './time-off-policy-widget.component.html',
  styleUrls: ['./time-off-policy-widget.component.scss']
})
export class TimeOffPolicyWidgetComponent implements OnInit {
  @Output() timeOffRequestCreated = new EventEmitter<boolean>();
  formId: string = 'frm_Sp9UWjaZBsb1Ha';
  user$: any;
  timeOffTypes: EmployeeTimeOffType[];
  public isLoading: boolean = true;
  dialogRef: any;

  constructor(
    private dialog: MatDialog,
    private employeeLeaveService: EmployeeLeaveService,
    private oidcAuthService: OidcAuthService,
    public translate: TranslateService,
    private workflowsAbsencesService: WorkflowsAbsencesService,
    private snackbarService: SnackbarService,
    private overlayService: OverlayService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
    this.getTimeOffTypes();
  }

  getTimeOffTypes() {
    this.isLoading = true;

    this.employeeLeaveService.getEmployeeTimeOffTypes(this.user$.userId)
    .pipe(
      finalize(() => this.isLoading = false)
    )
    .subscribe(
      res => {
        this.timeOffTypes = res.filter( timeOffType => timeOffType.timeOffPolicy !== null );
      }
    )
  }

  openDialog(timeOffType: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = 500;

    dialogConfig.data = {
      timeOffType: timeOffType,
      employeeId: this.user$.userId
    };

    const dialogRef = this.dialog.open(RequestTimeOffDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
          if (data === true) {
            this.timeOffRequestCreated.emit(true);
          }
      }
    );
  }

  openFutureScheduleDialog(employeeTimeOffType: EmployeeTimeOffType){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      employeeId: this.user$.userId,
      employeeTimeOffType: employeeTimeOffType
    };

    const dialogRef = this.dialog.open(ViewFutureScheduleDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {

        }
      }
    );
  }

  requestTimeOff(data: any) {
    this.overlayService.show();

    this.workflowsAbsencesService.submitRequest(data)
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
        this.dialogRef.close(true);
        this.timeOffRequestCreated.emit(true);
      }
    );
  }

}
