import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { Survey, SurveyQuestion } from '../../models/surveys.model';
import { SurveysService } from '../../services/surveys.service';

@Component({
  selector: 'app-survey-editor',
  templateUrl: './survey-editor.component.html',
  styleUrls: ['./survey-editor.component.scss']
})
export class SurveyEditorComponent implements OnInit {
  public routes: typeof routes = routes;

  surveyDetails: FormGroup;
  survey: Survey;
  questions: SurveyQuestion[] = [];
  isLoading: boolean;

  constructor(
    private fb: FormBuilder,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,  
    private route: ActivatedRoute,
    private surveysService: SurveysService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    if(this.getIdInURL()) {
      this.getSurvey();
    }
    else {
      this.buildForm();
    }
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get('surveyId')
    );

    return IdInURL;
  }

  getSurvey() {
    this.surveysService.getSurveys()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe(
        res => {
          this.survey = res.find(obj => obj.id === this.getIdInURL());
          this.buildForm();
        }
    );
  }

  buildForm() {
    this.surveyDetails = this.fb.group({
      title: [this.survey ? this.survey.title : '', Validators.required],
      description: [this.survey ? this.survey.description : '', Validators.required],
      type: [this.survey ? this.survey.type : '', Validators.required]
    });

    this.questions = this.survey?.questions;

    if(this.questions === undefined || this.questions.length < 1) {
      this.questions = [];
      this.addNewQuestion();
    }
  }

  addNewQuestion() {
    let newQuestion: SurveyQuestion = {
      id: null,
      name: null,
      description: null,
      type: null,
      options: []
    }

    this.questions.push(newQuestion);
  }

  removeQuestion(index: number) {
    this.questions.splice(index, 1);
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    }, 500)
  }

}
