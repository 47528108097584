import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, switchMap } from 'rxjs';
import { fromEvent } from 'rxjs';

const userAgent$ = new Observable<string>((observer) => {
  // observer.next(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  observer.next(navigator.userAgent);
  observer.complete();
});

const userAgentObservable$ = fromEvent(window, 'resize').pipe(
  // Get the user agent string from the navigator object
  map(() => userAgent$),
  // Convert the Observable<string> to a string value
  switchMap((userAgent) => userAgent),
);

@Injectable({
  providedIn: 'root'
})
export class CheckDeviceService {
  userAgent: string;
  isMobile = new BehaviorSubject<boolean>(false)

  constructor() {
    userAgentObservable$.subscribe((userAgent) => {
      this.userAgent = userAgent;
      this.isMobile.next(/iPhone|iPad|iPod|Android/i.test(this.userAgent));
    });
  }

  getIsMobile() {
    return this.isMobile.asObservable();
  }

  // get isMobile(): Observable<boolean> {
  //   return of(/iPhone|iPad|iPod|Android/i.test(this.userAgent));
  // }
}
