import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { EmployeeContactCard } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/models/employee-contact-card.model';
import { EmployeeContactCardService } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/container/employee-layout/components/employee-contact-card/services/employee-contact-card.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { Integration } from './demo-integration.model';
import { DemoIntegrationsService } from '../../services/demo-integrations.service';
import { DomSanitizer } from '@angular/platform-browser';
import SwaggerUI from 'swagger-ui';

@Component({
  selector: 'app-demo-integration-editor',
  templateUrl: './demo-integration-editor.component.html',
  styleUrls: ['./demo-integration-editor.component.scss']
})
export class DemoIntegrationEditorComponent implements OnInit {
  selectedIndex: number = 0;

  loadingIntegration: boolean = true;
  integration: any;

  loadingEmployees: boolean = true;
  employees: EmployeeContactCard[];

  constructor(
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,  
    private route: ActivatedRoute,
    private employeeContactCardService: EmployeeContactCardService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private integrationsService: DemoIntegrationsService,
    private sanitizer: DomSanitizer
  ) { }
  

  ngOnInit(): void {
    

    if(this.getIdInURL()) {
      this.getIntegration(this.getIdInURL());
    }
    else {
      this.integration = new Integration();
      this.loadingIntegration = false;
    }

    this.getEmployees();
  }

  getIntegration(id: string) {
    this.loadingIntegration = true;

    this.integrationsService.getIntegration(id)
    .pipe(
      finalize(() => {
        this.loadingIntegration = false;
        // this.createWorkflowPublishDetailsForm();
      })
    )
    .subscribe(
        res => {
          this.integration = res;
        }
    );
  }

  getIdInURL(): string {
    let IdInURL: string;

    this.route.paramMap.subscribe(
      params => IdInURL = params.get('id')
    );

    return IdInURL;
  }

  navigationButtonClicked(forward: boolean){
    if(forward) {
      if(this.selectedIndex < 4) {
        this.selectedIndex = this.selectedIndex + 1;
      }
      else {
        this.save();
      }
    }
    else {
      if(this.selectedIndex !== 0) {
        this.selectedIndex = this.selectedIndex - 1;
      }
    }
  }

  selectedTabChange($event) {
    this.selectedIndex = $event.index;

    if(this.selectedIndex === 2) {
      SwaggerUI({
        domNode: document.getElementById('swagger-ui-item'),
        url: null,
        spec: this.integration.docsUrl,
      });
    }
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
    }, 500)
  }

  getEmployees(): void {
    this.loadingEmployees = true;

    this.employeeContactCardService.getAllEmployeeContactCards(0, '1000')
    .pipe(
        finalize(()=>{ this.loadingEmployees = false; })
    )
    .subscribe(
      res => {
        this.employees = res.data;
      }
    )
  }

}