import { Component, OnInit, ViewChild } from "@angular/core";
import { TableListFieldOption } from "@app/modules/lookup/models/lookup.model";
import { LookupService } from "@app/modules/lookup/services/lookup.service";
import { ApexLegend, ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
import { finalize, from } from "rxjs";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  legend: ApexLegend;
  dataLabels: any;
};

@Component({
  selector: 'app-total-salary-by-departments-pie-chart',
  templateUrl: './total-salary-by-departments-pie-chart.component.html',
  styleUrls: ['./total-salary-by-departments-pie-chart.component.scss']
})
export class TotalSalaryByDepartmentsPieChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  isLoading: boolean;
  departments: any[];
  showChart: boolean = false;

  constructor(
    private lookupService: LookupService,
  ) {
    this.chartOptions = {
      series: [],
      chart: {
        width: 500,
        type: "pie"
      },
      labels: [],
      legend: {
        position: 'bottom',
      },
    };
  }

  ngOnInit(): void {
    this.getDepartments();
  }

  getDepartments() {
    from(this.lookupService.getListOptions('DEPARTMENT'))
    .pipe(
      finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.departments = res.data;
        this.populatePieChart();
      }
    )
  }

  populatePieChart() {
    this.assignRandomValues();

    this.departments.forEach(
      department => {
        this.chartOptions.series.push(department.value);
        this.chartOptions.labels.push(department.text);
      }
    )

    this.showChart = true;
  }

  assignRandomValues() {
    let remainingTotal = 100;
    let lastIndex = this.departments.length - 1;
  
    // Assign random values to objects in the array
    for (var i = 0; i < lastIndex; i++) {
      let maxValue = Math.min(remainingTotal, 15); // Maximum value is 15 or remaining total, whichever is smaller
      let randomValue = Math.floor(Math.random() * maxValue) + 1;
      this.departments[i].value = randomValue;
      remainingTotal -= randomValue;
    }
  
    // Assign the remaining total to the last object
    this.departments[lastIndex].value = remainingTotal;
  }

}
