import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables } from '@app/consts';
import { SettingsService } from '@app/core/services/settings.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { AccessLogsDialogComponent } from '../../../employment-records/components/employment-record-details/components/access-logs-dialog/access-logs-dialog.component';
import { HistoryDialogComponent } from '../../../employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { EmployeeBankDetails } from './models/employee-bank-details.model';
import { EmployeeBankDetailsService } from './services/employee-bank-details.service';
import { FormGeneratorDialogComponent } from '@app/shared/components/form-generator-dialog/form-generator-dialog.component';
import { indexOf } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-bank-details',
  templateUrl: './employee-bank-details.component.html',
  styleUrls: ['./employee-bank-details.component.scss']
})
export class EmployeeBankDetailsComponent implements OnInit {
  bankdetails = [
    {
        "id": "usr_Q58GDL7caiRl0A",
        "bankAccountName": "John Doe",
        "bankSortCode": "04-00-04",
        "bankAccountNumber": "12345678",
        "buildingSocietyRollNo": "BS12345",
        "bankName": "HSBC Bank",
        "iban": "GB29NWBK60161331926819",
        "swift": "HBUKGB4B",
        "big": "HSBCUKL",
        "percentage": 0.4,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    },
    {
        "id": "usr_KL9TX73EiAQR7B",
        "bankAccountName": "Jane Smith",
        "bankSortCode": "20-19-97",
        "bankAccountNumber": "87654321",
        "buildingSocietyRollNo": "BS54321",
        "bankName": "Barclays Bank",
        "iban": "GB33BUKB20201555555555",
        "swift": "BUKBGB22",
        "big": "BARCGB22",
        "percentage": 0.2,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    },
    {
        "id": "usr_LK7JN98OciQW3C",
        "bankAccountName": "Alice Brown",
        "bankSortCode": "40-47-84",
        "bankAccountNumber": "98765432",
        "buildingSocietyRollNo": "BS67890",
        "bankName": "Lloyds Bank",
        "iban": "GB85LOYD30963444966369",
        "swift": "LOYDGB2L",
        "big": "LOYDGB2L",
        "percentage": 0,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    },
    {
        "id": "usr_JL5UZ20PmiQX8D",
        "bankAccountName": "Michael Green",
        "bankSortCode": "30-99-99",
        "bankAccountNumber": "11223344",
        "buildingSocietyRollNo": "BS11223",
        "bankName": "Santander Bank",
        "iban": "GB18ABBY09012712345678",
        "swift": "ABBYGB2L",
        "big": "ABBYGB2L",
        "percentage": 0.4,
        "version": {
            "createdOn": "2024-08-19T15:48:10.944805+00:00",
            "effectiveDate": "2024-08-19T00:00:00",
            "modifiedList": [
                "BankAccountName"
            ],
            "changeReason": null,
            "comments": null,
            "isDeleted": false,
            "createdBy": {
                "id": "hubbub_3",
                "firstName": "Alan",
                "lastName": "Niemiec"
            }
        }
    }
  ]


  @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent
  public api_routes = api_routes;
  public db_tables = db_tables;
  employeeID: string;
  form: UntypedFormGroup;
  bankDetails: EmployeeBankDetails;
  isLoading: boolean = true;
  formData: any;
  formId: string = 'frm_hF9jQTZ6HQR3ed';
  changeReasonFormId: string = 'frm_9FKbvUllhUq91m';
  getFormData: boolean = false;
  formValid: boolean = false;
  isLoadingPermissions: boolean = true;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  effectiveDate = new Date();

  public columns: any[] = [
    {field: 'bankName', title: 'bank Name', tableId: "tfi_BankName"},
    {field: 'bankAccountNumber', title: 'bank Account Number', tableId: "tfi_BankAccountNumber"},
    {field: 'percentage', title: 'Salary Percentage', type: "percentage", percentageMaxValue: 1},
    {field: 'bankSortCode', title: 'bank Sort Code', tableId: "tfi_BankSortCode"},
    {field: 'buildingSocietyRollNo', title: 'building Society Roll No', tableId: "tfi_BuildingSocietyRollNo"},
    {field: 'iban', title: 'iban', tableId: "tfi_IBAN"},
    {field: 'swift', title: 'swift', tableId: "tfi_SWIFT"},
    {field: 'big', title: 'big', tableId: "tfi_BIG"},
    {field: 'bankAccountName', title: 'bank Account Name', tableId: "tfi_BankAccountName"},
  ];

  filterCategories: any[] = [
    { field: "name", tableId: "tfi_PgTName", title: "Name", type: "String", dataType: 'String'},
    { field: "escription", tableId: "tfi_PgTName", title: "Description", type: "String", dataType: 'String'},
  ];

  sortableColumns: any[] = [
    { field: "bankAccountName", sortValue: "bankAccountName"},
    { field: "bankSortCode", sortValue: "bankSortCode"},
    { field: "bankAccountNumber", sortValue: "bankAccountNumber"},
    { field: "buildingSocietyRollNo", sortValue: "buildingSocietyRollNo"},
    { field: "bankName", sortValue: "bankName"},
    { field: "iban", sortValue: "iban"},
    { field: "swift", sortValue: "swift"},
    { field: "big", sortValue: "big"},
  ];
  gridDataResult: any;
  dialogRef: any;
  pageSize: number = 20;
  skip: number = 0;

  constructor(
    public translate: TranslateService,
    private dialog: MatDialog,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
    private employeeBankDetailsService: EmployeeBankDetailsService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
  ) {
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    )
  }

  ngOnInit(): void {
    this.getEmployeeBankDetails();
    this.getTargetEmployeeTablePermissions();
  }

  get showPercentageWarning() {
    if (this.bankdetails.reduce((total, bankDetail) => total + bankDetail.percentage, 0) !== 1) { 
      return true; 
    } else { 
      return false; 
    }
  }

  get tableReadAccessObj() {
    return {
      tableId: db_tables.BankDetails,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: db_tables.BankDetails,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: db_tables.BankDetails,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: db_tables.BankDetails,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeID);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeID)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  getEmployeeBankDetails() {
    this.gridDataResult = {
      "data":this.bankdetails,
      "skip":0,
      "take":20,
      "total":this.bankdetails.length
    }
    this.isLoading = false;
    
    // this.isLoading = true;
    // let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

    // this.employeeBankDetailsService.getBankDetails(this.employeeID, asOf)
    // .pipe(
    //     finalize(() => {
    //         this.isLoading = false;
    //         this.createForm();
    //     })
    // )
    // .subscribe(
    //     res => {
    //         this.gridDataResult = res;
    //     }
    // );
  }

  openFormDialog(bankDetail?: any) {
    let formData = {
      asOf: moment().format(),
        changeReason: '',
        changeReasonComments: '',

        bankName: bankDetail ? bankDetail.bankName : null,
        bankSortCode: bankDetail ? bankDetail.bankSortCode : null,
        bankAccountNumber: bankDetail ? bankDetail.bankAccountNumber : null,
        buildingSocietyRollNo: bankDetail ? bankDetail.buildingSocietyRollNo : null,
        iban: bankDetail ? bankDetail.iban : null,
        swift: bankDetail ? bankDetail.swift : null,
        big: bankDetail ? bankDetail.big : null,
        bankAccountName: bankDetail ? bankDetail.bankAccountName : null,
        percentage: bankDetail ? bankDetail.percentage : 0,
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.formId,
      changeReasonFormId: this.changeReasonFormId,
      formData: formData,
    };

    this.dialogRef = this.dialog.open(FormGeneratorDialogComponent, dialogConfig);

    const sub = this.dialogRef.componentInstance.emitFormData.subscribe((event) => {
      let index = indexOf(this.bankdetails, bankDetail)
      
      this.bankdetails[index] = event;
      this.getEmployeeBankDetails();
      this.snackbarService.openSnackBar('Bank details saved successfully', 'clear', 'success');
      this.dialogRef.close();
    });
  }


  createForm() {
    this.formData = {
        asOf: moment().format(),
        changeReason: '',
        changeReasonComments: '',

        bankName: this.bankDetails ? this.bankDetails.bankName : null,
        bankSortCode: this.bankDetails ? this.bankDetails.bankSortCode : null,
        bankAccountNumber: this.bankDetails ? this.bankDetails.bankAccountNumber : null,
        buildingSocietyRollNo: this.bankDetails ? this.bankDetails.buildingSocietyRollNo : null,
        iban: this.bankDetails ? this.bankDetails.iban : null,
        swift: this.bankDetails ? this.bankDetails.swift : null,
        big: this.bankDetails ? this.bankDetails.big : null,
        bankAccountName: this.bankDetails ? this.bankDetails.bankAccountName : null,
    };
  }

  formDataEmitted(formData) {
    this.openChangeReasonDialog(formData);
    // this.save(formData);
  }

  formStatusUpdated(formValid) {
    this.formValid = formValid;
  }

  openChangeReasonDialog(formData: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      formId: this.changeReasonFormId,
    };

    const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
          if (data) {
              this.addChangeReasonsToFormData(formData, data);
          }

      }
    );
  }

  addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
    let merged = {...formData, ...changeReasonFormData};
    this.save(merged);
  }

  save(formData: any) {
    formData.asOf = this.effectiveDate;
    formData.originalCreatedOn = this.bankDetails?.version?.createdOn ? this.bankDetails?.version?.createdOn : null;

    this.overlayService.show();

    this.employeeBankDetailsService.updateBankDetails(this.employeeID, formData)
        .pipe(
            finalize(() => {
                this.overlayService.hide();
            })
        )
        .subscribe(
            (res) => {
                this.snackbarService.openSnackBar('Bank details saved successfully', 'clear', 'success');
            }
        );
  }

  openAccessLogsDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeID,
          entityId: this.employeeID,
          url: api_routes.TALENT + '/' + this.employeeID + '/' + api_routes.BANK_DETAIL + '/' + this.employeeID ,
      };

      const dialogRef = this.dialog.open(AccessLogsDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  openHistoryDialog() {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          employeeId: this.employeeID,
          entityId: null,
          url: api_routes.TALENT + '/' + this.employeeID + '/' + api_routes.BANK_DETAIL,
          columns: this.columns,
          tableId: db_tables.BankDetails,
      };

      const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(
          data => {
          }
      );
  }

  effectiveDateChange(event) {
    if(this.bankDetails){
        this.getEmployeeBankDetails();
    }
  }

}
