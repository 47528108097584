import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SiteDocumentsService } from '../../services/site-documents.service';

@Component({
  selector: 'app-document-site-upload-dialog',
  templateUrl: './document-site-upload-dialog.component.html',
  styleUrls: ['./document-site-upload-dialog.component.scss']
})
export class DocumentSiteUploadDialogComponent implements OnInit {
  currentFile?: File;
  progress = 0;
  message = '';
  employeeId: string = '';
  parentDirectory: string;
  name: string;

  fileName = 'Select File';
  fileInfos?: Observable<any>;
  form: UntypedFormGroup;
  directories$: Observable<any>;

  constructor(
    private siteDocumentsService: SiteDocumentsService,
    private dialogRef: MatDialogRef<DocumentSiteUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.employeeId = data.employeeId;
      this.parentDirectory = data.parentDirectory;
  }

  ngOnInit() {
    // this.directories$ = this.employeeDocumentsService.getDirectories(this.employeeId);
  }

  selectFile(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.currentFile = file;
      this.fileName = this.currentFile.name;
      console.log(this.currentFile)
    } else {
      this.fileName = 'Select File';
    }
  }

  upload() {
    this.progress = 0;
    this.message = "";

    if (this.currentFile) {
      this.siteDocumentsService.uploadDocument(this.employeeId, this.currentFile, null, this.parentDirectory).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
          } else if (event instanceof HttpResponse) {
            this.message = event.body.message;
            this.dialogRef.close(true);
          }
        },
        (err: any) => {
          this.progress = 0;

          if (err.error && err.error.message) {
            this.message = err.error.message;
          } else {
            this.message = 'Could not upload the file!';
          }

          this.currentFile = undefined;
        });
    }
  }

  close() {
      this.dialogRef.close();
  }

}
