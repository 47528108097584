<app-mass-import-export>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>Template Instructions</h5>
        </mat-card-title>
        <mat-card-content>
            <mat-tab-group setColor="primaryColour" class="my-mat-tab-group" [selectedIndex]="selectedIndex">
                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">1</span> CREATE A DATA LOAD FILE TEMPLATE </ng-template>
                    <div class="tab-container">
                        <div class="step">
                            <h6>1. Select the fields you wish to upload data for</h6>

                            <img [src]="'assets/import-data/select-fields-template.png'" alt="logo"/>
                        </div>
                        <div class="step">
                            <h6>2. Download the data load file template</h6>

                            <img [src]="'assets/import-data/download-template.png'" alt="logo"/>
                        </div>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">2</span> POPULATE THE DATA LOAD FILE TEMPLATE </ng-template>
                    <div class="tab-container">
                        <mat-list>
                            <mat-list-item>
                              <span matListItemTitle>1. Append the new column at the end of the table</span>
                            </mat-list-item>
                            <mat-list-item>
                              <span matListItemTitle>2. Highlight the new column name in yellow with black text:</span>
                            </mat-list-item>
                            <mat-list-item>
                              <span matListItemTitle>3. New Values can be added to the dropdown lookups in each table, please also highlight them yellow with black text</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>4. Mandatory Fields will be highlighted Green</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>5. If you do not want to use a field in any form, please leave it blank</span>
                            </mat-list-item>
                        </mat-list>
                    </div>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label> <span class="mat-tab-label-number">3</span> UPLOAD THE DATA LOAD FILE TEMPLATE </ng-template>
                    <div class="tab-container">
                        <div class="step">
                            <h6>1. Upload the template you wish to use</h6>

                            <img [src]="'assets/import-data/upload-template.png'" alt="logo"/>
                        </div>

                        <div class="step">
                            <h6>2. Follow the data upload job through the email notifications</h6>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card-content>
        <mat-card-actions>
            <button class="continue-button" setColor="primaryColour" mat-flat-button color="warn" (click)="selectedIndex = selectedIndex+1">Continue</button>
            <button mat-button [disabled]="selectedIndex === 0" (click)="selectedIndex = selectedIndex-1">Previous Step</button>
        </mat-card-actions>
    </mat-card>
</app-mass-import-export>
