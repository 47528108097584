import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ethnicity-pay-gap-grid',
  templateUrl: './ethnicity-pay-gap-grid.component.html',
  styleUrls: ['./ethnicity-pay-gap-grid.component.scss']
})
export class EthnicityPayGapGridComponent implements OnInit {
  data = [
    {
      hourlyRate: 'Employees',
      mean: '14.5%',
      meanMovement: '-3.2%',
      median: '12.6%',
      medianMovement: '-4.2%',
    },
    {
      hourlyRate: 'Members',
      mean: '3.3%',
      meanMovement: '1.2%',
      median: '17.3%',
      medianMovement: '-1.7%',
    },
    {
      hourlyRate: 'Our People (combined)',
      mean: '44.3%',
      meanMovement: '0.0%',
      median: '42.4%',
      medianMovement: '-1.7%',
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
