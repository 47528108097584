import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hugo-dropdown',
  templateUrl: './hugo-dropdown.component.html',
  styleUrls: ['./hugo-dropdown.component.scss']
})
export class HugoDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
