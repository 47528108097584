<div class="main-container" *ngIf="documentsList$ | withLoading | async as documentsList">
    <ng-template [ngIf]="documentsList.value">
        <div class="controls">
            <div class="buttons">
                <button mat-flat-button color="primary" (click)="emitShowDocumentUploadDialog()" *appTableAccess="[tableCreateAccessObj]">
                    <mat-icon class="material-icons-outlined">add</mat-icon> 
                    Add
                </button>
                <button mat-flat-button (click)="emitShowCreateNewDirectoryForm()" *appTableAccess="[tableCreateAccessObj]">
                    <mat-icon class="material-icons-outlined">create_new_folder</mat-icon> 
                </button>
            </div>
        </div>
        
        <div class="tree-container">
            <kendo-treeview
                [nodes]="documentsList.value"
                [children]="fetchChildren"
                [hasChildren]="hasChildren"
                [textField]="['name']"


                [isExpanded]="isExpanded"
                (collapse)="handleCollapse($event)"
                (expand)="handleExpand($event)"

                kendoTreeViewDragAndDrop
                kendoTreeViewDragAndDropEditing
                kendoTreeViewHierarchyBinding
                (nodeDrop)="handleDrop($event)"
            >
                <ng-template kendoTreeViewNodeTemplate let-dataItem>
                    <div class="details-container" *ngIf="dataItem.isFolder">
                        <img 
                            class="ms-icon" 
                            src="assets/icons/folder.svg" 
                            style="width: 30px;
                                    height: 30px;
                                    vertical-align: middle;">
                                    
                        <div class="document-label-top">
                            {{ dataItem.name }}
                        </div>
                        <div class="controls-container">
                            <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn" aria-label="Example icon-button with a menu">
                                <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="emitEditEvent(dataItem)" *appTableAccess="[tableUpdateAccessObj]">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon> 
                                    <span>Edit</span>
                                </button>
                                <button mat-menu-item (click)="emitDeleteEvent(dataItem)" *appTableAccess="[tableDeleteAccessObj]">
                                    <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                    <span>Delete</span>
                                </button>
                                <button mat-menu-item (click)="emitShowCreateNewDirectoryForm(dataItem)" *appTableAccess="[tableCreateAccessObj]">
                                    <mat-icon class="material-icons-outlined">create_new_folder</mat-icon> 
                                    <span>Add a Directory</span>
                                </button>
                                <button mat-menu-item (click)="emitShowDocumentUploadDialog(dataItem)" *appTableAccess="[tableCreateAccessObj]">
                                    <mat-icon class="material-icons-outlined">note_add</mat-icon> 
                                    <span>Upload a File</span>
                                </button>
                            </mat-menu>    
                        </div>
                    </div>
                    <div *ngIf="!dataItem.isFolder">
                        <div class="details-container">
                            <ng-container *ngIf="icons[dataItem.contentType]; else blankIcon">
                                <img 
                                    class="ms-icon" 
                                    src={{icons[dataItem.contentType]}} 
                                    style="width: 35px;
                                            height: 35px;
                                            vertical-align: middle;"> 
                            </ng-container>
                            <ng-template #blankIcon>
                                <mat-icon class="blank-document-icon material-icons-outlined">description</mat-icon>
                            </ng-template>
                            <div class="document-label">
                                <div class="document-label-top">
                                    <div class="title" (click)="emitViewFileEvent(dataItem)">{{dataItem.name}}</div>
                                    <div class="filesize">{{dataItem.fileLength | filesize }}</div>
                                </div>
                                <div class="document-label-bottom">
                                    Created {{dataItem.version.createdOn | date:'longDate'}} by <span class="clickable-icon" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.version.createdBy.id}}">{{dataItem.version.createdBy.firstName}} {{dataItem.version.createdBy.lastName}}</span>
                                </div>
                            </div>
                            <div class="controls-container">
                                <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn" aria-label="Example icon-button with a menu">
                                    <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="emitDeleteEvent(dataItem)" *appTableAccess="[tableDeleteAccessObj]">
                                        <mat-icon class="material-icons-outlined">delete</mat-icon> 
                                        <span>Delete</span>
                                    </button>
                                    <button mat-menu-item (click)="emitDownloadEvent(dataItem)">
                                        <mat-icon class="material-icons-outlined">download</mat-icon> 
                                        <span>Download</span>
                                    </button>
                                </mat-menu>    
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template
                    kendoTreeViewDragClueTemplate
                    let-action="action"
                    let-destinationItem="destinationItem"
                    let-text="text"
                    let-sourceItem="sourceItem"
                >
                    <div class="drag-clue-template">
                        <span
                            class="k-drag-status k-icon"
                            [ngClass]="getDragStatus(action, destinationItem)"
                        ></span>
                        <span *ngIf="sourceItem">{{ sourceItem.item.dataItem.name }}</span>
                    </div>
                </ng-template>
            </kendo-treeview>
        </div>
    </ng-template>
    <ng-template [ngIf]="documentsList.error">Error {{ obs.error }}</ng-template>
    <ng-template [ngIf]="documentsList.loading">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-template>
</div>

