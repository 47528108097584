<h5 mat-dialog-title>{{ performanceReview.name }}</h5>

<mat-dialog-content>

    <div class="employee-contact-card-container" *ngIf="employeeContactCard$ | withLoading | async as employeeContactCard">
        <div class="employee">
            <app-employee-image class="profile-pic" [employeeId]="performanceReview.review.employee.id" round="true" width="200" height="200"></app-employee-image>
            
            <div class="employee-details">
                <mat-form-field appearance="fill" >
                    <mat-label>
                        Employee Name
                    </mat-label>
            
                    <input matInput type="text" [value]="performanceReview.review.employee.firstName+' '+performanceReview.review.employee.lastName">
                </mat-form-field>
            
                <mat-form-field appearance="fill" >
                    <mat-label>
                        <div class="label">Position</div>
                    </mat-label>
            
                    <input matInput type="text" [value]="employeeContactCard?.value?.positions[0]?.name">
                </mat-form-field>
            </div>
        </div>

        <div class="container">
            <mat-form-field appearance="fill">
                <mat-label>Review Description</mat-label>
        
                <textarea rows="10" matInput [value]="performanceReview.review.description"></textarea>
            </mat-form-field>
        </div>

        <div class="form-section">
            <div class="form-section-title">
                <h6>Goal Plans</h6>
            </div>
            <div class="form-section-contents">
                <div class="goal-plans">
                    <div *ngFor="let goalPlan of performanceReview.review.goalPlans" class="goal-plan">
                        <mat-form-field appearance="fill" >
                            <mat-label>
                                Goal Plan
                            </mat-label>
                    
                            <input matInput type="text" [value]="goalPlan.name">
                        </mat-form-field>
                    
                        <mat-form-field appearance="fill" >
                            <mat-label>
                                <div class="label">Weight</div>
                            </mat-label>
                    
                            <input matInput type="text" [value]="goalPlan.weight">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="form-section">
        <div class="form-section-title">
            <h6>Reviewers</h6>
        </div>
        <div class="form-section-contents">
            <button mat-flat-button color="primary">
                Rever To Previous Reviewer
            </button>
            <app-data-grid-comp
                [columns]="columns"
                [bindingType]="bindingType"
                [gridData]="gridDataResult"
                [gridDataResult]="gridDataResult"
                [isLoading]="isLoading"
                [pageSize]="pageSize"
                [skip]="skip"
                category=""
                canSelectRows="false"
                clickableRows="true"
                searchable="false"
                hideActionButtons="true"
                (emitOpenDialogEvent)="openReviewDialog($event)"
            ></app-data-grid-comp>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button 
        mat-raised-button 
        color="primary" 
        appPreventDoubleClick 
        (throttledClick)="save()" 
    >{{ 'Save' | translate: {Default: "Save"} }}</button>
</mat-dialog-actions>