<ng-container *ngIf="isLoadingPermissions; else loadedPermissions">
    <ngx-skeleton-loader
        count="5"
        [theme]="{ 
            'height.px': 50
        }"
    ></ngx-skeleton-loader>
</ng-container>
<ng-template #loadedPermissions>
    <app-data-grid-comp
        [columns]="columns"
        [bindingType]="bindingType"
        [view]="view"
        [gridData]="gridData"
        [gridDataResult]="gridDataResult"
        [isLoading]="isLoading"
        [pageSize]="pageSize"
        [skip]="skip"
        category="tbl_EmergencyContacts"
        [isLoading]="isLoading"
        [clearSelectedItems]="clearSelectedItems"
        [tableId]="db_tables.EmergencyContacts"
        [accessAndHistoryURL]="accessAndHistoryURL"
        [parentModelId]="employeeID"
        [rowSelectionSettings]="{
            canSelectRows: true,
            selectableMode: 'multiple',
            selectRowBy: 'id'
        }"
        clickableRows="true"
        [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
        (emitPageChangeEvent)="pageChange($event)"
        (emitOpenDialogEvent)="getEmergencyContact($event)"
        (emitDeleteEvent)="deleteEmergencyContacts($event)"
    ></app-data-grid-comp>
</ng-template>
