<app-performance>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5 mat-dialog-title>{{ 'EditGoalReview' | translate: {Default: 'Edit Goal Review'} }}</h5>

            <div class="k-flex-grow"></div>

            <button class="mat-raised-button" (click)="navigateBack()">{{ 'Close' | translate: {Default: "Close"} }}</button>
            <button
                mat-raised-button
                color="primary"
                appPreventDoubleClick
                (throttledClick)="getFormData = !getFormData"
                [disabled]="formValid === false"
            >{{ 'Save' | translate: {Default: "Save"} }}</button>
        </mat-card-title>

        <mat-card-content>
            <ng-container *ngIf="isLoading; else loaded">
                <ngx-skeleton-loader
                    count="5"
                    [theme]="{
                  'height.px': 50
              }"
                ></ngx-skeleton-loader>
            </ng-container>
            <ng-template #loaded>

                <div class="top-row">
                    <app-form-generator
                        [formId]="formId"
                        [formData]="formData"
                        [emitFormDataTrigger]="getFormData"
                        (emitFormData)="formDataEmitted($event)"
                        (emitFormStatus)="formStatusUpdated($event)"
                        (emitFormPristine)="formPristineEmitted($event)"
                    ></app-form-generator>
                </div>

                <form [formGroup]="parentGroup">
                    <div class="">
                        <div class="form-section">
                            <div class="form-section-title k-mb-2">
                                <h6>{{ 'Weighted Goals' | translate: {Default: "Weighted Goals"} }}</h6>

                                <button mat-stroked-button class="add-localization-btn" color="primary" (click)="addNewGoal()">
                                    <mat-icon class="material-icons-outlined">add</mat-icon>
                                    {{ 'AddGoal' | translate: {Default: "Add Goal"} }}
                                </button>
                            </div>
                            <div class="form-section-contents">
                                <div class="section-row">
                                    <div *ngFor="let goalPlanForm of parentGroup.get('goalPlans').controls; let i = index">
                                        <div [formGroup]="goalPlanForm">
                                            <div class="localization-row">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        {{ 'GoalPlan' | translate: {Default: "Goal Plan"} }}
                                                    </mat-label>

                                                    <mat-select disableOptionCentering formControlName="goalPlan">
                                                        <ng-container>
                                                            <mat-option *ngFor="let goalPlan of goalPlans" [value]="goalPlan.id">
                                                                {{goalPlan.name}}
                                                            </mat-option>
                                                        </ng-container>
                                                    </mat-select>
                                                </mat-form-field>

                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        {{ 'PercentAllocated' | translate: {Default: "Percent Allocated"} }}
                                                    </mat-label>

                                                    <input matInput
                                                           type="number"
                                                           formControlName="percentAllocated"
                                                           placeholder="0">
                                                </mat-form-field>

                                                <mat-icon class="material-icons-outlined clickable-icon" (click)="deleteGoal(i)" matTooltip="Delete">delete</mat-icon>
                                            </div>

                                            <div class="localization-row" *ngFor="let goalForm of goalPlanForm.get('goals').controls; let i = index">
                                                <div [formGroup]="goalForm" class="k-ml-2">
                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{ 'Goal' | translate: {Default: "Goal"} }}
                                                        </mat-label>

                                                        <input matInput
                                                               type="text"
                                                               formControlName="description"
                                                               placeholder="Goal Description"
                                                               disabled>
                                                    </mat-form-field>

                                                    <mat-form-field appearance="fill">
                                                        <mat-label>
                                                            {{ 'PercentAllocated' | translate: {Default: "Percent Allocated"} }}
                                                        </mat-label>

                                                        <input matInput
                                                               type="number"
                                                               formControlName="percentAllocated"
                                                               placeholder="0">
                                                    </mat-form-field>
                                                </div>
                                            </div>

                                            <mat-divider></mat-divider>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <mat-divider class="k-my-3"></mat-divider>

                <div class="grid-container" *ngIf="reviewId">
                    <div class="form-section-title k-mb-2 k-d-flex">
                        <h6 class="title">
                            {{ 'Reviewers' | translate: {Default: "Reviewers"} }}
                        </h6>
                        <div class="k-flex-grow"></div>
                        <div>
                            <button mat-stroked-button class="add-localization-btn k-mx-2" color="primary" (click)="showAddUserModal()">
                                <mat-icon class="material-icons-outlined">add</mat-icon>
                                {{ 'AddSpecificReviewer' | translate: {Default: "Add Specific Reviewer"} }}
                            </button>

                            <button mat-stroked-button class="add-localization-btn k-mx-2" color="primary" (click)="showAddVariableReviewer()">
                                <mat-icon class="material-icons-outlined">add</mat-icon>
                                {{ 'AddVariableReviewer' | translate: {Default: "Add Variable Reviewer"} }}
                            </button>
                        </div>
                    </div>

                    <kendo-grid
                        [data]="employeesGridDataResult"
                        [loading]="isLoadingEmployees"
                        [style.maxHeight.vh]="85"
                        [selectable]="{enabled: true}"
                        kendoGridSelectBy="id"
                        [(selectedKeys)]="selectedItems"
                        [sortable]="true"
                        [pageSize]="pageSize"
                        [skip]="skip"
                        [pageable]="{info: true,pageSizes: true,previousNext: true}"
                        (pageChange)="pageChange($event)"
                    >

                        <ng-template kendoGridToolbarTemplate>
                            <div *ngIf="selectedItems.length > 0">
                                <button class="delete-all-selected-button" mat-stroked-button color="primary" (click)="deleteAllSelected()">
                                    <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">delete</mat-icon>
                                    {{ 'RemoveAllSelected' | translate: {Default: "Remove All Selected"} }} ({{selectedItems.length}})
                                </button>
                            </div>
                        </ng-template>

                        <kendo-grid-checkbox-column
                            [width]="60"
                            showSelectAll="true"
                        ></kendo-grid-checkbox-column>

                        <kendo-grid-column field="name" title="Name">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical employee-name">
                                    <!--                                        <app-employee-image [width]="50" [height]="50" [round]="true" class="profile-pic" [employeeId]="dataItem.id" *ngIf="!isLoading"></app-employee-image>-->
                                    <span class="name">
                                        {{dataItem.firstName}} {{dataItem.lastName}}
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>


                        <kendo-grid-column
                            title="Ratings"
                            [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        <mat-slide-toggle [checked]="dataItem.provideRatings" (click)="$event.stopPropagation()"></mat-slide-toggle>
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                            title="Comments"
                            [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        <mat-slide-toggle [checked]="dataItem.provideComments" (click)="$event.stopPropagation()"></mat-slide-toggle>
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                            title="View Other Review Ratings"
                            [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        <mat-slide-toggle [checked]="dataItem.viewOtherReviewRatings" (click)="$event.stopPropagation()"></mat-slide-toggle>
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                            title="Employee Can View Ratings"
                            [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        <mat-slide-toggle [checked]="dataItem.employeeCanViewRatings" (click)="$event.stopPropagation()"></mat-slide-toggle>
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column
                            title="Manager Can View Ratings"
                            [width]="200">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="grid-cell-vertical">
                                    <span>
                                        <mat-slide-toggle [checked]="dataItem.managerCanViewRatings" (click)="$event.stopPropagation()"></mat-slide-toggle>
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                    </kendo-grid>
                </div>



            </ng-template>
        </mat-card-content>
    </mat-card>
</app-performance>
