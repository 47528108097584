<mat-card class="mat-elevation-z0 card">
  <mat-card-header>
    <div [ngClass]="{'green-dot': hugoStatus === 'Online', 'red-dot': hugoStatus === 'Offline'}"></div>
    <div mat-card-avatar class="header-image"></div>
    <mat-card-title>
      {{ 'AI-Chatbot-Name' | translate: {Default: "Ask Hugo"} }}
    </mat-card-title>
    <mat-card-subtitle>{{ hugoStatus }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content id="chat-container">
    <ng-container *ngIf="hugoStatus === 'Offline'; else showMessages">
      <div class="retry-connection-container">
        <mat-spinner *ngIf="showSpinner"></mat-spinner>
        <button 
          mat-raised-button 
          setColor
          [setColorRules]="[
            {
              color: 'primaryColour',
              style: 'background'
            }
          ]"
          color="primary" 
          (click)="getThread()" 
          [disabled]="showSpinner"
        >Retry Connection</button>
      </div>
    </ng-container>
    <ng-template #showMessages>
      <ng-container *ngFor="let message of messages">
        <div class="message-container">
          <div class="message" [ngClass]="{ 'user': message.author === user }">
            <div class="message-content">
              {{ message.text }}
            </div>
          </div>
        </div>
      </ng-container>
  
      <div *ngIf="isWaitingForResponse" class="message-container">
        <div class="thinking message">
          <div class="dot-elastic"></div>
        </div>
      </div>
    </ng-template>

  </mat-card-content>

  <mat-card-actions>
    <div class="textarea-container" *ngIf="hugoStatus === 'Online'">
      <mat-form-field>
        <textarea 
        id="text-area"
        matInput 
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="15"
        [(ngModel)]="messageText"
        placeholder="Ask Hugo"></textarea>
      </mat-form-field>

      <button 
        mat-mini-fab     
        setColor
        [setColorRules]="[
          {
            color: 'primaryColour',
            style: 'background'
          }
        ]"
        color="primary" 
        aria-label="submit button icon"
        (click)="sendMessage(messageText)">
        <mat-icon class="material-icons-outlined">send</mat-icon>
      </button>
     </div>
  </mat-card-actions>
</mat-card>