import { Component, OnInit } from '@angular/core';
import { Job, JobVerbose } from './models/job-management.model';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { db_tables, features, routes } from '@app/consts';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { FeatureService } from '@app/core/services/feature.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { defer, forkJoin, Observable } from 'rxjs';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { TranslateService } from '@ngx-translate/core';
import { JobManagementService } from './services/job-management.service';
import { finalize } from 'rxjs/operators';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { TableField } from '../security-setup/models/table-field.model';
import { SecuritySetupService } from '../security-setup/services/security-setup.service';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';
import { EmployeeTableFieldSecurity } from '@app/shared/models/employee.model';

@Component({
    selector: 'app-job-management',
    templateUrl: './job-management.component.html',
    styleUrls: ['./job-management.component.scss']
})
export class JobManagementComponent extends SecurityProtectedBase implements OnInit {
    public routes: typeof routes = routes;
    public features: typeof features = features;
    public columns: any[] = [
        {field: 'name', title: 'Job Name', dataType: 'String'},
        {field: 'jobCode', title: 'Job Code', dataType: 'String'},
        {field: 'jobFamily', title: 'Job Family', dataType: 'String'},
        {field: 'jobGroup', title: 'Job Group', dataType: 'String'},
        {field: 'jobBand', title: 'Job Band', dataType: 'String'},
        {field: 'jobType', title: 'Job Type', dataType: 'String'},
        {field: 'effectiveDate', title: 'Effective Date', dataType: 'String'}
    ];
    public sort: SortDescriptor[] = [];
    public bindingType: String = 'array';
    public view: Observable<GridDataResult>;
    public gridDataResult: GridDataResult;
    clearSelectedItems: boolean = false;
    isLoading: boolean;
    pageSize: number = 20;
    skip: string = '0';
    sortString: string = "name-asc";
    getJobsRequest: any;
    fieldList: TableField[];
    sortingColumns: boolean;
    public searchFilterString: string;
    public searchValue: string;

    public filterCategories: any[] = [
        //{field: 'region', title: 'Region', dataType: "Lookup", lookupCode: 'REGION',},
        //{field: 'division', title: 'Division', dataType: "Lookup", lookupCode: 'DIVISION'},
        //{field: 'SubDivision', title: 'Sub Division', dataType: "Lookup", lookupCode: 'SUB_DIVISION'},
        //{field: 'JobBand', title: 'Job Band', dataType: "Lookup", lookupCode: 'JOB_BAND'},
        //{field: 'JobStep', title: 'Job Step', dataType: "Lookup", lookupCode: 'JOB_STEP'},
        //{field: 'JobType', title: 'Job Type', dataType: "Lookup", lookupCode: 'JOB_TYPE'},
        //{field: 'JobFamily', title: 'Job Family', dataType: "Lookup", lookupCode: 'JOB_FAMILY'},
        //{field: 'JobGroup', title: 'Job Group', dataType: "Lookup", lookupCode: 'JOB_GROUP'},
        //{field: 'ProjectTeam', title: 'Project Team', dataType: "Lookup", lookupCode: 'PROJECT_TEAM'},
        // {
        //     field: 'MarketPositionTitle',
        //     title: 'Market Position Title',
        //     dataType: "Lookup",
        //     lookupCode: 'MARKET_POSITION_TITLE'
        // },
        // {field: 'MarketPosition', title: 'Market Position', dataType: "Lookup", lookupCode: 'MARKET_POSITION'},
        // {field: 'MarketView', title: 'Market View', dataType: "Lookup", lookupCode: 'MARKET_VIEW'},
        // {field: 'UnionCode', title: 'Union Code', dataType: "Lookup", lookupCode: 'UNION_CODE'},
        // {field: 'ShiftCode', title: 'Shift Code', dataType: "Lookup", lookupCode: 'SHIFT_CODE'},
        // {field: 'EmploymentGroup', title: 'Employment Group', dataType: "Lookup", lookupCode: 'EMPLOYMENT_GROUP'},
        // {field: 'WorkLocation', title: 'Work Location', dataType: "SpecialLookup", lookupCode: 'WORK_LOCATION'},
        // {field: 'Organization', title: 'Organisation', dataType: 'SpecialLookup', lookupCode: 'ORGANISATION'},
        // {field: 'CostCenter', title: 'Cost Center', dataType: 'SpecialLookup', lookupCode: 'CostCenter'},
        // {field: 'ReportsTo', title: 'Reports To', dataType: "String"},
        // {field: 'ClientPositionId', title: 'Client Position ID', dataType: "String"},
    ].concat(this.columns);
    filterString: string;
    dialogRef: any;

    constructor(
        private route: ActivatedRoute,
        private dialog: MatDialog,
        private translate: TranslateService,
        public router: Router,
        private jobService: JobManagementService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        featureService: FeatureService,
        private securitySetupService: SecuritySetupService,
        private tablePermissionsService: TablePermissionsService,
    ) {
        super(featureService, features.POSITIONS)
    }

    ngOnInit(): void {
        this.filterCategories.splice(this.filterCategories.findIndex(v => v.field === "employees"), 1)

        this.getJobs();
        this.getFields(db_tables.Positions);
    }

    getFields(tableId) {
        this.sortingColumns = true;

        this.securitySetupService.getFields(tableId, 0, '200')
            .pipe(
                finalize(() => {
                    this.sortingColumns = false;
                    this.getTableFieldPermissions();
                })
            )
            .subscribe(
                pagedFieldList => {
                    this.fieldList = pagedFieldList.data;

                    this.fieldList.forEach(
                        field => {
                            let index = this.columns.findIndex(column => column.tableId === field.id)

                            if (index !== -1) {
                                if (field.enabled === false) {
                                    this.columns.splice(index, 1);
                                }

                                if (field.name !== null) {
                                    this.columns[index].title = field.name;
                                }
                            }
                        }
                    );
                }
            );
    }

    getTableFieldPermissions() {
        let tableFieldPermissions: EmployeeTableFieldSecurity[] = this.tablePermissionsService.getTableFieldsPermissions(db_tables.Positions);

        tableFieldPermissions.forEach(
            tableFieldPermission => {
                let index = this.columns.findIndex(column => column.tableId === tableFieldPermission.field.id)

                if (index !== -1) {
                    if (tableFieldPermission.read === false || tableFieldPermission.deny === true) {
                        this.columns.splice(index, 1);
                    }
                }
            }
        )
    }

    redirectOrgChart() {
        this.router.navigate([`${routes.REPORTS}${routes.ORG_CHART}`, {type: "positions", isEditable: "true"}]);
    }

    getJobs(): void {
        this.isLoading = true;

        this.getJobsRequest = this.jobService.getJobs(this.skip, String(this.pageSize), this.searchFilterString, this.sortString, 'true')
            .pipe(
                finalize(() => {
                    this.isLoading = false;
                })
            )
            .subscribe(
                res => {
                    this.gridDataResult = {
                        data: res.data,
                        total: res.total,
                    };
                }
            );
    }

    public pageChange(event: PageChangeEvent): void {
        this.skip = event.skip.toString();
        this.pageSize = event.take;
        this.getJobs();
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.sort = sort;

        if (sort[0].dir === undefined) {
            this.sortString = null;
        } else {
            //use regex to get column field to sort with
            let field: any;

            //if it is an object category like (department.text) trim .text from the end, else it doesnt need to be trimmed
            (sort[0].field).match(/.+(?=\.)/) === null
                ? field = sort[0].field
                : field = (sort[0].field).match(/.+(?=\.)/);
            this.sortString = `${field}-${sort[0].dir}`;
        }

        this.getJobs();
    }

    deleteAllSelected(itemsToDelete: string[]) {
        const observables = itemsToDelete.map(item => defer(() => this.jobService.deleteJob(item)));

        this.overlayService.show();

        forkJoin(observables)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.clearSelectedItems = !this.clearSelectedItems;
                    this.getJobs();
                    this.snackbarService.openSnackBar('Deleted successfully', 'clear', 'success');
                },
                err => {
                    this.snackbarService.openSnackBar(err, 'clear', 'warn');
                }
            );
    }

    deleteJob(jobId: string) {
        this.overlayService.show();

        this.jobService.deleteJob(jobId)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                res => {
                    this.getJobs();
                    this.snackbarService.openSnackBar('Position deleted successfully', 'clear', 'success');
                }
            );
    }


    filterCallback(filterString: string) {
        this.searchFilterString = filterString;
        // this.filterString = filterString;
        this.skip = '0';
        this.getJobs();
    }

    search(searchValue) {
        let filtered = this.jobService.getJobList().filter((job) => {
            return job.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        this.gridDataResult = {
            data: filtered,
            total: filtered.length,
        }
    }

    createJobFormData(job: JobVerbose) {
        return {
            id: job ? job.id : null,
            clientPositionId: job ? job.clientPositionId : null,
            startDate: job ? job.startDate : null,
            endDate: job ? job.endDate : null,
            organization: job ? job.organization : null,
            assistant: job ? job.assistant : false,
            functionalJobTitle: job && job.functionalJobTitle ? job.functionalJobTitle.id : null,
            region: job && job.region ? job.region.id : null,
            division: job && job.division ? job.division.id : null,
            subDivision: job && job.subDivision ? job.subDivision.id : null,
            department: job && job.department ? job.department.id : null,
            workLocation: job ? job.workLocation?.id : null,
            workRotationStartDate: job ? job.workRotationStartDate : null,
            workRotation: job ? job.workRotation?.id : null,
            jobGroup: job && job.jobGroup ? job.jobGroup.id : null,
            jobFamily: job && job.jobFamily ? job.jobFamily.id : null,
            jobBand: job && job.jobBand ? job.jobBand.id : null,
            jobType: job && job.jobType ? job.jobType.id : null,
            jobStep: job && job.jobStep ? job.jobStep.id : null,
            projectTeam: job && job.projectTeam ? job.projectTeam.id : null,
            marketPositionTitle: job && job.marketPositionTitle ? job.marketPositionTitle.id : null,
            marketPosition: job && job.marketPosition ? job.marketPosition.id : null,
            marketView: job && job.marketView ? job.marketView.id : null,
            unionCode: job && job.unionCode ? job.unionCode.id : null,
            shiftCode: job && job.shiftCode ? job.shiftCode.id : null,
            employmentGroup: job && job.employmentGroup ? job.employmentGroup.id : null,
            employeeCategory: job && job.employeeCategory ? job.employeeCategory.id : null,
            employmentType: job && job.employmentType ? job.employmentType.id : null,
            enforceSlotLimit: job ? job.enforceSlotLimit : false,
            numberOfSlots: job ? job.numberOfSlots : 1,
            slotsAvailable: job ? job.slotsAvailable : null,
            slotsFilled: job ? job.slotsFilled : null,
            parentPosition: job?.parentPosition?.id ? job.parentPosition.id : null,
            name: job ? job.name : null,
            costCenters: job ? job.costCenters : null,
            fte: job ? job.fte : null,
            udl1: job ? job.udl1?.id : null,
            udl2: job ? job.udl2?.id : null,
            udt1: job ? job.udt1 : null,
            udt2: job ? job.udt2 : null,
            udd1: job ? job.udd1 : null,
            udd2: job ? job.udd2 : null,
            changeReason: null,
            changeReasonComments: null,
        };
    }


    getJobDetails(copyFormData: { name: any[], changeReason: string, changeReasonComments: string }, job: any) {
        this.jobService.getJob(job.id)
            .pipe(
                finalize(() => this.isLoading = false)
            )
            .subscribe(
                res => {
                    let formData = this.createJobFormData(res);

                    formData.name = copyFormData.name;
                    formData.changeReason = copyFormData.changeReason;
                    formData.changeReasonComments = copyFormData.changeReasonComments;
                    this.createJob(formData);
                }
            );
    }

    createJob(formData) {
        this.overlayService.show();

        this.jobService.postJob(formData)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('CopiedSuccessfully')}`, 'clear', 'success');
                    this.dialogRef.close();
                    this.router.navigate([`${routes.SITE_SETTINGS}/${routes.JOB_MANAGEMENT}/${routes.EDITOR}/${res.positionId}`]);
                }
            );
    }

    navigateToJob(job?: Job) {
        if (job) {
            this.router.navigate([`${routes.SITE_SETTINGS}${routes.JOB_MANAGEMENT}${routes.EDITOR}/${job?.id}`], {relativeTo: this.route});
        }
    }


}
