<app-data-grid-comp
    [columns]="columns"
    [bindingType]="bindingType"
    [gridData]="gridDataResult"
    [gridDataResult]="gridDataResult"
    [isLoading]="isLoading"
    [pageSize]="pageSize"
    [skip]="skip"
    category="Potential & Readiness"
    canSelectRows="true"
    clickableRows="true"
    searchable="true"
    [isLoading]="isLoading"
    [clearSelectedItems]="clearSelectedItems"
    (emitPageChangeEvent)="pageChange($event)"
    (emitOpenDialogEvent)="navigateToSuccessionProfile($event)"
    (emitSearchEvent)="search($event)"
    (emitDeleteEvent)="deleteAllSelected($event)"
    (emitFilterEvent)="filterCallback($event)"
    (rowActionEvent)="rowActionButtonClicked($event)"
></app-data-grid-comp>