import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ChatgptService {
  private apiUrl = 'https://chatgpthugo.azurewebsites.net';

 constructor(private http: HttpClient) { }
 
  getThread() {
    return this.http.get(`${this.apiUrl}/thread`);
  }

  sendMessage(message: { threadId: string; message: string }) {
    return this.http.post(`${this.apiUrl}/message`, message);
  }
}
