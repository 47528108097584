<app-compensation>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'SalaryReview' | translate: {Default: "Salary Review"} }}</h5>
        </mat-card-title>
        <mat-card-content>
            <div class="top">
                <div class="box" *ngFor="let detail of details">
                    <h6 
                        class="label"
                        setColor
                        [setColorRules]="[
                            {
                            color: 'primaryColour',
                            style: 'background'
                            }
                        ]" 
                    >{{detail.label}}</h6>
                    <h2 class="value">{{detail.value}}</h2>
                </div>
            </div>

            <div class="middle" fxLayout="row" fxLayoutAlign="space-between">
                <app-salary-by-age-gender-chart fxFlex="33.33%"></app-salary-by-age-gender-chart>

                <app-total-salary-by-departments-pie-chart fxFlex="33.33%"></app-total-salary-by-departments-pie-chart>

                <app-average-salary-by-departments-and-gener-bar-chart fxFlex="33.33%"></app-average-salary-by-departments-and-gener-bar-chart>
            </div>

            <div class="bottom">
                <app-salary-review-data-grid></app-salary-review-data-grid>
            </div>
        </mat-card-content>
    </mat-card>
</app-compensation>
