import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { SecurityRoleService } from '@app/modules/security-roles/services/security-role.service';
import { SecurityRole } from '@app/modules/security-setup/models/security-role.model';
import { WorkLocationsService } from '@app/modules/work-locations/services/work-locations.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { TranslateService } from '@ngx-translate/core';
import { GridDataResult, PageChangeEvent, SelectionEvent } from '@progress/kendo-angular-grid';
import { defer, finalize } from 'rxjs';

@Component({
  selector: 'app-select-work-locations-dialog',
  templateUrl: './select-work-locations-dialog.component.html',
  styleUrls: ['./select-work-locations-dialog.component.scss']
})
export class SelectWorkLocationsDialogComponent implements OnInit {
  @Output() emitSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  
  public gridDataResult: GridDataResult;
  public isLoading: boolean;
  public selectedItems: any[] = [];
  public roleList: SecurityRole[] = [];
  private sortString = "name-asc";
  public searchValue: string;
  public searchFilterString: string;
  clearSelectedItems: boolean = false;
  pageSize: number = 10;
  skip: number = 0;
  filterString: string;

  filterToggleDetails: {
    text: string,
    checked: boolean
  } = {
    text: 'FilterInactive',
    checked: true
  }

  public columns: any[] = [
    {field: 'name', title: 'Name', dataType: "String"},
    { field: "streetAddress", title: "Street Address", tableId: "tfi_WlStreetAddress", dataType: "String"},
    { field: "city", title: "City", dataType: "String", tableId: "tfi_WlCity" },
    { field: "province", title: "Province", dataType: "String", tableId: "tfi_WlProvince" },
    { field: "zip", title: "Zip", dataType: "String", tableId: "tfi_WlZip" },
    { field: "region", subField: 'text', title: "Region", dataType: "String", tableId: "" },
    { field: "country", subField: 'name', title: "Country", dataType: "String", tableId: "tfi_WlCountry" },
    { field: "timezone", subField: 'text', title: "Timezone", dataType: "String", tableId: "" },
    { field: "stylingColours", subField: 'name', title: "styling Color", tableId: "" },
    { field: 'companyLogo', subField: 'id', title: 'Logo', type: 'logoImage', tableId: "" },
    { field: "startDate", title: "Start Date", type: "date", dataType: "Date", tableId: "" },
    { field: "endDate", title: "End Date", type: "date", dataType: "Date", tableId: "" },
  ];

  sortableColumns: any[] = [
    { field: "name", sortValue: "name"},
    { field: "streetAddress", sortValue: "streetAddress" },
    { field: "city", sortValue: "city" },
    { field: "province", sortValue: "province" },
    { field: "zip", sortValue: "zip" },
    { field: "region", sortValue: "region.text" },
    { field: "country", sortValue: "country.name" },
    { field: "timezone", sortValue: "timezone.text" },
    { field: "startDate", sortValue: "startDate" },
    { field: "endDate", sortValue: "endDate" },
    { field: "stylingColor", sortValue: "stylingColor" },
  ];

  public filterCategories: any[] = [].concat(this.columns)
  getRequest: any;

  constructor(
      private workLocationsService: WorkLocationsService,
      public translate: TranslateService,
      private dialog: MatDialog,
      private dialogRef: MatDialogRef<SelectWorkLocationsDialogComponent>,
  ) {}

  ngOnInit(): void {
      this.getWorkLocations();
  }

  getWorkLocations(){
    this.isLoading = true;

    let filter: string = ``;
    
    if(this.searchFilterString) {
      filter = this.searchFilterString;
    }

    if(this.filterString) {
      if(filter){
        filter = `${filter} AND ${this.filterString}`;
      }
      else {
        filter = `${this.filterString}`;
      }
    }


    this.getRequest = this.workLocationsService.getWorkLocations(this.skip, String(this.pageSize), filter, this.sortString, this.filterToggleDetails.checked)
    .pipe(
        finalize(()=>{ this.isLoading = false; })
    )
    .subscribe(
      res => {
        this.gridDataResult = {
          data: res.data,
          total: res.total,
        }
      }
    )
  }

  deleteAllSelected(itemsToDelete: string[]) {

  }

  search(searchValue) {
      this.searchValue = searchValue;
      this.searchFilterString = `(Name like "${this.searchValue}")`;
      this.getRequest.unsubscribe();
      this.getWorkLocations();
  }

  public pageChange(event: PageChangeEvent): void {
      this.skip = event.skip;
      this.pageSize = event.take;
      this.getWorkLocations();
  }

  filterCallback(filterString: string) {
      this.searchFilterString = filterString;
      this.skip = 0;;
      this.getWorkLocations();
  }

  openConfirmDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
        text: `Are you sure you want to add ${this.selectedItems.length} selected Work Location(s)?`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.emitSelected.emit(this.selectedItems);
          this.dialogRef.close();
        }
      }
    );
  }

  sortChange(sortString: string){
    this.sortString = sortString;
    this.getWorkLocations();
  }

  updateFilterToggleDetails(toggleChange: MatSlideToggleChange){
    this.filterToggleDetails.checked = toggleChange.checked;
    this.getWorkLocations();
  }

  close() {
    this.dialogRef.close();
  }

  selectedRowsChanged(selectionEvent: SelectionEvent){
    if(selectionEvent?.selectedRows[0]?.dataItem){
      this.selectedItems.push(selectionEvent.selectedRows[0].dataItem.id);
    }
    else if(selectionEvent?.deselectedRows[0]?.dataItem) {
      let index = this.selectedItems.findIndex( i => i === selectionEvent.deselectedRows[0].dataItem.id);

      if(index > -1){
        this.selectedItems.splice(index, 1);
      }
    }
  }

}
