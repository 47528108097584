import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Survey } from '@app/modules/survey-section/models/surveys.model';

@Component({
  selector: 'app-survey-selection-dialog',
  templateUrl: './survey-selection-dialog.component.html',
  styleUrls: ['./survey-selection-dialog.component.scss']
})
export class SurveySelectionDialogComponent implements OnInit {
  surveys: Survey[];
  selectedItems = [];

  constructor(
    private dialogRef: MatDialogRef<SurveySelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data)
  {
    this.surveys = data.surveys;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  addSurvey() {
    this.dialogRef.close(this.selectedItems[0]);
  }

}
