<app-layout>

    <mat-toolbar class="page-header" role="heading">
        <h1>Disciplines Report</h1>

        <button mat-stroked-button setColor="primaryColour" color="primary" (click)="backToReportGroups()">
            Back to Custom Reports
        </button>
    </mat-toolbar>

    <mat-card>

        <mat-card-content>
            <kendo-grid
                [kendoGridBinding]="gridData"
            >
                <kendo-grid-column field="employee"></kendo-grid-column>
                <kendo-grid-column field="action"></kendo-grid-column>
                <kendo-grid-column field="reason"></kendo-grid-column>
                <kendo-grid-column field="date"></kendo-grid-column>
                <kendo-grid-column field="conductedBy"></kendo-grid-column>
                <kendo-grid-column field="comments"></kendo-grid-column>
            </kendo-grid>
        </mat-card-content>
    </mat-card>

</app-layout>
