import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gender-pay-gap-grid',
  templateUrl: './gender-pay-gap-grid.component.html',
  styleUrls: ['./gender-pay-gap-grid.component.scss']
})
export class GenderPayGapGridComponent implements OnInit {

  data = [
    {
      hourlyRate: 'Employees',
      mean: '22.2%',
      meanMovement: '3.9%',
      median: '28.7%',
      medianMovement: '-0.4%',
    },
    {
      hourlyRate: 'Members',
      mean: '-4.2%',
      meanMovement: '-3.9%',
      median: '-1.3%',
      medianMovement: '7.2%',
    },
    {
      hourlyRate: 'Our People (combined)',
      mean: '44.3%',
      meanMovement: '0.0%',
      median: '42.4%',
      medianMovement: '-1.7%',
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
