import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-employee-family-dependants',
    templateUrl: './employee-family-dependants.component.html',
    styleUrls: ['./employee-family-dependants.component.scss']
})
export class EmployeeFamilyDependantsComponent implements OnInit {
    employeeId: string;

    constructor(
        private route: ActivatedRoute,
    ) {
        this.route.paramMap.subscribe(
            params => this.employeeId = params.get("id")
        )
    }

    ngOnInit(): void {
    }
}
