<mat-card class="chart-card mat-elevation-z0">
    <mat-card-title class="header">
        <h6>{{ 'SuccessRate' | translate: {Default: "Success Rate"} }}</h6>
    </mat-card-title>
    <mat-card-content>
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [labels]="chartOptions.labels"
              [responsive]="chartOptions.responsive"
            ></apx-chart>
          </div>
    </mat-card-content>
</mat-card>