import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-ethnicity-pay-gap-report',
  templateUrl: './ethnicity-pay-gap-report.component.html',
  styleUrls: ['./ethnicity-pay-gap-report.component.scss']
})
export class EthnicityPayGapReportComponent implements OnInit {
report = {
    title: 'Ethnicity Pay Gap Analysis',
    description: null,
    ordinaryPay: {
        title: 'Standard Pay',
        description: `The ethnicity pay gap represents the disparity in pay between employees from minority ethnic backgrounds and white employees, expressed as a percentage of white employees' pay.`,
        mean: '5%',
        median: '8%',
    },
    hourlyRateComparisonData: {
        title: 'Hourly Wage Comparison',
        description: 'This chart illustrates the mean and median hourly wages across different ethnic groups.',
        currency: 'GBP',
        categories: ['Minority Ethnic', 'White'],
        meanHourlyRate: [22.45, 24.95],
        medianHourlyRate: [20.32, 23.12],
    },
    proportionData: {
        title: 'Employee Distribution by Ethnicity',
        data: [
            { category: "Minority Ethnic", value: 40 },
            { category: "White", value: 60 },
        ]
    },
    oneOffContributions: {
        description: 'The company does not offer bonuses. To comply with regulations, we have categorized any payments made annually or less frequently than monthly as `one-off contributions` to calculate the equivalent of bonus pay. This includes one-time payments such as long service awards.',
        data: [
            {
                title: 'One-off Payments',
                description: `The one-off payments ethnicity pay gap shows the difference between the payments to minority ethnic employees and white employees as a percentage of white employees' payments.`,
                left: {
                    label: 'Mean',
                    value: '-2%',
                },
                right: {
                    label: 'Median',
                    value: '1%',
                }
            },
            {
                title: 'One-off Payments Distribution',
                description: null,
                left: {
                    label: 'Percentage of Minority Ethnic Employees Receiving Payments',
                    value: '16%',
                },
                right: {
                    label: 'Percentage of White Employees Receiving Payments',
                    value: '14%',
                }
            }
        ]
    },
    payByQuartile: [
        {
            title: 'Top Quartile',
            description: 'Distribution of Minority Ethnic and White employees in the top quartile (above the 75th percentile)',
            data: [
                { category: "Minority Ethnic", value: 45 },
                { category: "White", value: 55 },
            ]
        },
        {
            title: 'Upper Middle Quartile',
            description: 'Distribution of Minority Ethnic and White employees in the upper middle quartile (above the median and at or below the 75th percentile)',
            data: [
                { category: "Minority Ethnic", value: 48 },
                { category: "White", value: 52 },
            ]
        },
        {
            title: 'Lower Middle Quartile',
            description: 'Distribution of Minority Ethnic and White employees in the lower middle quartile (above the 25th percentile and at or below the median)',
            data: [
                { category: "Minority Ethnic", value: 38 },
                { category: "White", value: 62 },
            ]
        },
        {
            title: 'Bottom Quartile',
            description: 'Distribution of Minority Ethnic and White employees in the bottom quartile (below the 25th percentile)',
            data: [
                { category: "Minority Ethnic", value: 42 },
                { category: "White", value: 58 },
            ]
        },
    ],
    summary: {
        title: "Ethnicity Pay Gap Overview",
        description: "Summary of the ethnicity pay gap analysis",
        sections: [
            {
                title: "Ethnicity Pay Gap Insights",
                paragraphs: [
                    `40% of our workforce is from minority ethnic backgrounds. This year's report indicates a reduction in the ethnicity pay gap. The mean pay gap has decreased from 7% in ${moment().subtract(1, 'year').year()} to 5% in ${moment().year()}, and the median pay gap has reduced from 10% in ${moment().subtract(1, 'year').year()} to 8% in ${moment().year()}.`
                ]
            },
            {
                title: "Implications",
                paragraphs: [
                    `Our ethnicity pay gap data highlights that our efforts to enhance ethnic diversity at all levels are yielding positive results. Recruitment plays a crucial role in improving ethnic representation, and as of March ${moment().year()}, 40% of our employees are from minority ethnic backgrounds. Promoting and advancing our minority ethnic workforce into senior roles is equally important, with 30% of our senior positions held by minority ethnic employees.`,
                    `The data also shows that our initiatives, such as focusing on salary negotiations and proactively examining starting salaries, have resulted in equitable starting salaries for both minority ethnic and white employees. Pay increases are also contributing to reducing the ethnicity pay gap, as a higher proportion of minority ethnic employees are receiving promotions and pay raises.`
                ]
            }
        ],
        bulletList: {
            title: "Key Actions Driving Improvements: ",
            items: [
                "A dedicated Pay Gap Taskforce collaborating with senior leaders to focus on career development, succession planning, and pay moderation.",
                "Involvement of our Executive Leadership Team in the recruitment process for all senior leader appointments.",
                "Encouraging senior leaders to provide sponsorship support to accelerate the development of minority ethnic employees.",
                "Ensuring that every vacancy for middle management and above roles includes an ethnically diverse shortlist.",
                "Providing post-interview feedback to each candidate and coaching hiring managers to deliver constructive feedback.",
                "Revamping our Inclusive Mentoring Program to give leaders insights into the experiences of our minority ethnic employees and the challenges they face.",
                "Promoting agile working arrangements and explicitly including flexible working opportunities in our recruitment process."
            ],
        }
    }
};

  constructor() { }

  ngOnInit(): void {
  }

}
