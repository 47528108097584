import { Component, OnInit } from '@angular/core';
import { ViewChild } from "@angular/core";

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  grid: ApexGrid;
  colors: string[];
  tooltip: ApexTooltip;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'app-salary-by-age-gender-chart',
  templateUrl: './salary-by-age-gender-chart.component.html',
  styleUrls: ['./salary-by-age-gender-chart.component.scss']
})
export class SalaryByAgeGenderChartComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "Males",
          data: [
            -74.0,
            -75.0,
            -87.0,
            -74.0,
            -58.0,
            -63.0,
          ]
        },
        {
          name: "Females",
          data: [
            98.0,
            83.0,
            74.0,
            76.0,
            82.0,
            74.0,
          ]
        }
      ],
      chart: {
        type: "bar",
        height: 600,
        stacked: true
      },
      colors: ["#008FFB", "#FF4560"],
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "80%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },

      grid: {
        xaxis: {
          lines: {
            show: false
          }
        }
      },
      yaxis: {
        min: -100,
        max: 100,
        title: {
          // text: 'Age',
        }
      },
      tooltip: {
        shared: false,
        x: {
          formatter: function(val) {
            return val.toString();
          }
        },
        y: {
          formatter: function(val) {
            return Math.abs(val) + "%";
          }
        }
      },
      xaxis: {
        categories: [
          "15-24",
          "25-34",
          "35-44",
          "45-54",
          "55-64",
          "65+",
        ],
        title: {
          text: "Percent"
        },
        labels: {
          formatter: function(val) {
            return Math.abs(Math.round(parseInt(val, 10))) + "%";
          }
        }
      }
    };

    setTimeout(
      () => {
        window.dispatchEvent(new Event('resize'));
      }, 1000
    )
  }

  ngOnInit(): void {
  }

}
