import { Component, OnInit } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';

@Component({
  selector: 'app-goals-section',
  templateUrl: './goals-section.component.html',
  styleUrls: ['./goals-section.component.scss']
})
export class GoalsSectionComponent implements OnInit {
  user$: any;

  constructor(
    private oidcAuthService: OidcAuthService,
  ) {
    this.user$ = this.oidcAuthService.userProfile;
  }

  ngOnInit(): void {
  }

}
