<app-layout>
    <mat-toolbar class="page-header" role="heading">
      <mat-tab-group 
        [(selectedIndex)]="selectedTabIndex" 
        setColor="primaryColour"
        mat-stretch-tabs="false" 
        mat-align-tabs="center"
      >

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="material-icons-outlined">home</mat-icon>
  
            <span appLocalizationPopupDirective [localizationCode]="'Homepage-Home'">
              {{ 'Homepage-Home' | translate: {Default: "Home"} }}
            </span>
          </ng-template>

          <ng-template matTabContent>
            <div class="content">
              <app-welcome-message *ngIf="!isMobile"></app-welcome-message>
              
              <div class="space-between">
                <app-actionable-tasks-widget (emitTabChange)="changeTab($event)"></app-actionable-tasks-widget>
                
                <app-whats-new-widget></app-whats-new-widget>
              </div>
              
              <app-announcements-widget></app-announcements-widget>
              
              <app-quick-links-widget (changeTabIndexEmitted)="selectedTabIndex = $event"></app-quick-links-widget>
        
              <div class="space-between">
                <app-tasks-widget></app-tasks-widget>

                <app-engagement-survey></app-engagement-survey>
        
                <app-chat-gpt-widget></app-chat-gpt-widget>
              </div>
            </div>
          </ng-template>

        </mat-tab>


        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="material-icons-outlined">emoji_people</mat-icon>
  
            <span appLocalizationPopupDirective [localizationCode]="'Homepage-AboutMe'">
              {{ 'Homepage-AboutMe' | translate: {Default: "About Me"} }}
            </span>
          </ng-template>

          <ng-template matTabContent>
            <app-about-me-section></app-about-me-section>
          </ng-template>
        </mat-tab>

        <mat-tab *appHasModuleAccess="modules.TIME_OFF">
          <ng-template mat-tab-label>
            <mat-icon class="material-icons-outlined">schedule</mat-icon>
  
            <span appLocalizationPopupDirective [localizationCode]="'Homepage-TimeOff'">
              {{ 'Homepage-TimeOff' | translate: {Default: "Time Off"} }}
            </span>
          </ng-template>
          
          <ng-template matTabContent>
            <app-time-off-section></app-time-off-section>
          </ng-template>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="material-icons-outlined">local_atm</mat-icon>
  
            <span appLocalizationPopupDirective [localizationCode]="'Homepage-Compensation'">
              {{ 'Homepage-Compensation' | translate: {Default: "Compensation"} }}
            </span>
          </ng-template>

          <ng-template matTabContent>
            <app-compensation-section></app-compensation-section>
          </ng-template>
        </mat-tab>
        
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="material-icons-outlined">adjust</mat-icon>
  
            <span appLocalizationPopupDirective [localizationCode]="'Homepage-Goals'">
              {{ 'Homepage-Goals' | translate: {Default: "Goals"} }}
            </span>
          </ng-template>

          <ng-template matTabContent>
            <app-goals-section></app-goals-section>
          </ng-template>

        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="material-icons-outlined">flag</mat-icon>
  
            <span appLocalizationPopupDirective [localizationCode]="'Homepage-Career'">
              {{ 'Homepage-Career' | translate: {Default: "Career"} }}
            </span>
          </ng-template>

          <ng-template matTabContent>
            <app-quick-links-widget (changeTabIndexEmitted)="selectedTabIndex = $event"></app-quick-links-widget>
        
            <div class="space-between">
              <app-tasks-widget></app-tasks-widget>

              <app-engagement-survey></app-engagement-survey>
      
              <app-chat-gpt-widget></app-chat-gpt-widget>
            </div>
          </ng-template>
        </mat-tab>

      </mat-tab-group>

    </mat-toolbar>
    
    <div class="content">
      <!-- <app-welcome-message *ngIf="!isMobile"></app-welcome-message>
      
      <div class="space-between">
        <app-actionable-tasks-widget></app-actionable-tasks-widget>
        
        <app-whats-new-widget></app-whats-new-widget>
      </div>
      
      <app-announcements-widget></app-announcements-widget>
      
      <app-quick-links-widget></app-quick-links-widget>

      <div class="space-between">
        <app-tasks-widget></app-tasks-widget>

        <app-chat-gpt-widget></app-chat-gpt-widget>
      </div> -->


      <!-- <ng-container *ngIf="!isMobile">
        <ng-container *appHasModuleAccess="modules.PERFORMANCE">
          <ng-container *appHasFeature="features.PERFORMANCE">
            <app-homepage-employee-goals-widget *appHasFeature="features.PERFORMANCE_MY_GOALS" [employeeId]="user$.userId"></app-homepage-employee-goals-widget>
          </ng-container>
        </ng-container>
      </ng-container> -->
<!-- 
        <div class="time-off-container">
          <app-time-off-policy-widget *appHasModuleAccess="modules.TIME_OFF"
                    (timeOffRequestCreated)="timeOffRequestCreated($event)"></app-time-off-policy-widget>
          <app-my-time-off-requests *appHasModuleAccess="modules.TIME_OFF"
                  [refreshMyTimeOffRequest]="refreshMyTimeOffRequest"></app-my-time-off-requests>
        </div>

        <div class="second-container">
            <app-engagement-survey></app-engagement-survey>
            <app-birthdays></app-birthdays>
            <app-tasks-widget></app-tasks-widget>
            <app-out-of-office-widget></app-out-of-office-widget>
            <app-company-announcements-widget></app-company-announcements-widget>
            <app-inbox-widget *ngIf="!isMobile"></app-inbox-widget>
        </div>

        <app-benefits-compensation-widget style="width: 100%"></app-benefits-compensation-widget>

        <ng-container *appHasModuleAccess="modules.TOIL">
          <div class="time-off-row" *appHasFeature="features.TOIL">
            <app-toil-policy-widget 
              (toilRequestCreated)="toilRequestCreated($event)">
            </app-toil-policy-widget>
            
            <app-my-toil-requests 
              [refreshMyToilRequest]="refreshMyToilRequest">
            </app-my-toil-requests>
          </div>
        </ng-container>
  
        <ng-container *ngIf="!isMobile">
          <app-schedule-widget *appHasModuleAccess="modules.TIME_OFF"></app-schedule-widget>
        </ng-container>

        <app-employee-directory-widget *ngIf="!isMobile"></app-employee-directory-widget> -->
    </div>
   
</app-layout>
