import { Component, OnInit, ViewChild } from "@angular/core";

import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexLegend, ApexStroke, ApexTitleSubtitle, ApexXAxis, ApexYAxis, ChartComponent } from "ng-apexcharts";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  labels: string[];
  legend: ApexLegend;
  subtitle: ApexTitleSubtitle;
};
@Component({
  selector: 'app-turnover-rate',
  templateUrl: './turnover-rate.component.html',
  styleUrls: ['./turnover-rate.component.scss']
})
export class TurnoverRateComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  constructor() {
    this.chartOptions = {
      series: [
        {
          name: "",
          data: [
            18.5,
            22.8,
            26.5,
            32.5,
          ]
        }
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "straight"
      },
      labels: [
        "2019",
        "2020",
        "2021",
        "2022",
      ],
      legend: {
        horizontalAlign: "left"
      }
    };
  }

  ngOnInit(): void {
  }

}
