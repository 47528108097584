import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { features, routes } from '@app/consts';
import { FeatureService } from '@app/core/services/feature.service';
import { SecurityProtectedBase } from '@app/shared/components/security-protected/security-protected';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-compensation',
  templateUrl: './compensation.component.html',
  styleUrls: ['./compensation.component.scss']
})
export class CompensationComponent extends SecurityProtectedBase implements OnInit {
  public routes: typeof routes = routes;
  navList = [
    {
        text: this.translate.instant('Salary Review'),
        link: `${routes.COMPENSATION}${routes.SALARY_REVIEW}`,
        icon: 'adjust',
        featureCode: 'allow'
    },
    {
      text: `Salary Review Summary`,
      link: `/Compensation/SalaryReviewSummary`,
      icon: 'request_quote',
      featureCode: 'allow'
    },
  ];

  constructor(
      private translate: TranslateService,
      public router: Router,
      featureService: FeatureService,
      private featureServicePrivate: FeatureService,
  ) {
    super(featureService, features.ALLOW)
  }

  ngOnInit(): void {
    if(this.hasFeatureAccess){
      this.navigateToFirstAllowedSection();
    }
  }

  navigateToFirstAllowedSection() {
    let urlToNavigateTo = '';

    if(this.router.url === `${routes.COMPENSATION}`) {
      this.navList.every(element => {
        let hasAccess = this.featureServicePrivate.hasFeatureAccess(element.featureCode)

        if(hasAccess){
          urlToNavigateTo = element.link;
          return false;
        }

        return true;
      });
    }

    if(urlToNavigateTo !== ''){
      this.router.navigate([urlToNavigateTo]);
    }
}

}
