<mat-card class="mat-elevation-z0 employee-page-card">
    <mat-card-title class="header">
      <h5 appLocalizationPopupDirective [localizationCode]="'DirectReports'">{{ 'DirectReports' | translate: {Default: "Direct Reports"} }}</h5>

      <mat-form-field class="search-field" appearance="outline" >
        <input matInput
               [(ngModel)]="directReporteeSearchValue"
               [placeholder]="translate.instant('Search')">
        <mat-icon matPrefix class="material-icons-outlined">
            search
        </mat-icon>
      </mat-form-field>
    </mat-card-title>
  
    <mat-card-content>
            <div class="card-container" *ngIf="directReportees$ | withLoading | async as directReportees">
                <ng-template [ngIf]="directReportees.value">
                    <app-direct-reportee-card 
                      *ngFor="let directReportee of directReportees.value.data | directReporteeSearch:directReporteeSearchValue"
                      [directReportee]="directReportee"
                    ></app-direct-reportee-card>

                    <h6 *ngIf="directReportees.value.data.length === 0" appLocalizationPopupDirective [localizationCode]="'YouHaveNoDirectReports'">
                      {{ 'YouHaveNoDirectReports' | translate: {Default: "You have no direct reports"} }}
                    </h6>
                </ng-template>
                <ng-template [ngIf]="directReportees.error" appLocalizationPopupDirective [localizationCode]="'ThereWasAnErrorLoading'">
                  <h6>{{ 'ThereWasAnErrorLoading' | translate: {Default: "There was an error loading"} }}</h6>
                </ng-template>
                <ng-template [ngIf]="directReportees.loading">
                    <app-employee-card *ngFor="let number of [0,1,2,3]"></app-employee-card>
                </ng-template>
            </div>
    </mat-card-content>
</mat-card>
  