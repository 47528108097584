<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Promotions By Gender</h5>

      <app-settings-menu></app-settings-menu>
    </mat-card-title>

    <mat-card-content class="chart__content">
        <div class="bottom">
            <div>
                <div class="percentage">68.2%</div>
                <div class="large">Internal promotions were female</div>
                <div class="small mt-2">(April 2021 - April 2022)</div>
                <div class="small mt-2">(the business is 68.1% female)</div>
            </div>
            <div>
                <mat-icon class="material-icons-outlined">
                    female
                </mat-icon>
            </div>
        </div>
    </mat-card-content>
</mat-card>
