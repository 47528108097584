<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
        <h5>{{ 'AverageSalaryByDepartmentAndGender' | translate: {Default: "Average Salary by Department and Gender"} }}</h5>
    </mat-card-title>
    <mat-card-content>
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [dataLabels]="chartOptions.dataLabels"
              [plotOptions]="chartOptions.plotOptions"
              [xaxis]="chartOptions.xaxis"
              [stroke]="chartOptions.stroke"
              [colors]="chartOptions.colors"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>
