import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ordinary-pay',
  templateUrl: './ordinary-pay.component.html',
  styleUrls: ['./ordinary-pay.component.scss']
})
export class OrdinaryPayComponent implements OnInit {
  @Input() ordinaryPay: {
    title: string,
    description: string,
    mean: string,
    median: string,
  }

  constructor() { }

  ngOnInit(): void {
  }

}
