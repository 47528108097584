import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Survey } from '../../models/surveys.model';

@Component({
  selector: 'app-survey-dialog',
  templateUrl: './survey-dialog.component.html',
  styleUrls: ['./survey-dialog.component.scss']
})
export class SurveyDialogComponent implements OnInit {
  survey: Survey;

  constructor(
    private dialogRef: MatDialogRef<SurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { 
    this.survey = data.survey
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
