import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CheckDeviceService } from '@app/core/services/check-device/check-device.service';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { WorkflowsAbsencesService } from '@app/core/services/workflows-absences/workflows-absences.service';
import { TimeOffService } from '@app/modules/time-off/services/time-off.service';
import { ConfirmDialogComponent } from '@app/shared/components/confirm-dialog/confirm-dialog.component';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import { MyRequest } from '@app/shared/models/workflows-absences/workflows-absences.model';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import { RequestTimeOffDialogComponent } from '../../../time-off-policies-widget/components/request-time-off-dialog/request-time-off-dialog.component';

@Component({
  selector: 'app-requests-list-item',
  templateUrl: './requests-list-item.component.html',
  styleUrls: ['./requests-list-item.component.scss']
})
export class RequestsListItemComponent implements OnInit {
  @Output() openLeaveRequestDialog = new EventEmitter<MyRequest>();
  @Output() refreshRequests = new EventEmitter<boolean>();
  @Input() leaveRequest;
  iconUrl: string;
  user$: any;
  isMobile: boolean;
  moment = moment;

  constructor(
    private dialog: MatDialog,
    private timeOffService: TimeOffService,
    private workflowsAbsencesService: WorkflowsAbsencesService,
    public domSanitizer: DomSanitizer,
    private overlayService: OverlayService,
    private datePipe: DatePipe,
    private oidcAuthService: OidcAuthService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private checkDeviceService: CheckDeviceService
  ) { 
    this.user$ = this.oidcAuthService.userProfile;

    this.checkDeviceService.getIsMobile().subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  ngOnInit(): void {
    this.timeOffService.getTimeOffTypeIcon(this.leaveRequest?.timeOffType?.id)
    .subscribe(
      res => {
        this.iconUrl = res;
      }
    )
  }

  isInPast(from: string): boolean {
    return moment(from).isBefore();
  }

  isInFuture(from: string): boolean {
    return moment(from).isAfter();
  }

  openEditRequestDialog(timeOffRequestId: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = this.isMobile ? 0 : 500;

    dialogConfig.data = {
      timeOffRequestId: timeOffRequestId,
      employeeId: this.user$.userId
    };

    const dialogRef = this.dialog.open(RequestTimeOffDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(
      data => {
          if (data === true) {
            this.refreshRequests.emit(true);
          }
      }
    );
  }

  openConfirmDeleteDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      localizationCode: 'ConfirmCancelTimeOffRequest',
      text: `${this.leaveRequest.timeOffType.name}: ${this.datePipe.transform(this.leaveRequest.from, 'mediumDate')} - ${this.datePipe.transform(this.leaveRequest.to, 'mediumDate')}`
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data === true) {
          this.delete();
        }
      }
    ); 
  }

  delete(){
    this.overlayService.show();

    this.workflowsAbsencesService.deleteRequest(this.leaveRequest.id) 
    .pipe(
      finalize(() => this.overlayService.hide())
    )
    .subscribe(
      (res) => {
        this.refreshRequests.emit(true);
        this.snackbarService.openSnackBar(this.translate.instant('CancelledSuccessfully'), 'clear', 'success');
      }
    );
  }

  showEmployeeTooltip(employees: {id: string, firstName: string, lastName: string}[]) {
    if(employees){
        return employees.map( employee => `${employee.firstName} ${employee.lastName}`).join(', ');
    }

    return null;
  }

}
