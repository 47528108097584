import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-succession-mobility',
  templateUrl: './succession-mobility.component.html',
  styleUrls: ['./succession-mobility.component.scss']
})
export class SuccessionMobilityComponent implements OnInit {
  preferences = [
    'São Paulo',
    'Vancouver Head Office',
  ]

  restrictions = [
    'Dubai',
    'Germany',
  ]

  mobilityNotes = 'I would like to work in a city in Canada if possible as my spouse can work only in Canada.';

  constructor() { }

  ngOnInit(): void {
  }

  removeLocation(index, array){
    array.splice(index, 1)
  }

}
