import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-upload-profile-image',
  templateUrl: './upload-profile-image.component.html',
  styleUrls: ['./upload-profile-image.component.scss']
})
export class UploadProfileImageComponent {
  employeeID: string;

  constructor(
    private route: ActivatedRoute,
  ) { 
    this.route.paramMap.subscribe(
        params => this.employeeID = params.get("id")
    ) 
  }
}
