import {Component, OnInit} from '@angular/core';
import {ChartOptions} from "@app/modules/reports/components/diversity-report/components/simple-vertical-bar-chart/simple-horizontal-bar-chart.component";

@Component({
  selector: 'app-diversity-report',
  templateUrl: './diversity-report.component.html',
  styleUrls: ['./diversity-report.component.scss']
})
export class DiversityReportComponent implements OnInit {


    sexualOrientationData = [
        { label: 'Bisexual', value: "1%" },
        { label: 'Gay/Lesbian %', value: '12.4%' },
        { label: 'Heterosexual/straight', value: "92%" },
        { label: 'Other', value: '0%' },
        { label: 'Prefer not to say', value: "5%" },
    ];


    ethnicityData = [
        { label: 'Bangladeshi', value: '0%' },
        { label: 'Chinese', value: '0%' },
        { label: 'Indian', value: '0%' },
        { label: 'Pakistani', value: '0%' },
        { label: 'Any other Asian background', value: '1%' },
        { label: 'African', value: '0%' },
        { label: 'Caribbean', value: '0%' },
        { label: 'Any other Black background', value: '0%' },
        { label: 'White and Asian', value: '2%' },
        { label: 'White and Black African', value: '0%' },
        { label: 'White and Black Caribbean', value: '11%' },
        { label: 'White and Chinese', value: '3%' },
        { label: 'Any other mixed / multiple ethnic background', value: '1%' },
        { label: 'British / English / Welsh / Northern Irish / Scottish', value: '1%' },
        { label: 'Irish', value: '0%' },
        { label: 'Any other White background', value: '3%' },
        { label: 'Any other ethnic group', value: '71%' },
        { label: 'Prefer not to say', value: '2%' }
    ]

    religionData = [
        { label: 'No religion or belief / Atheist', value: '35%' },
        { label: 'Christian', value: '41%' },
        { label: 'Hindu', value: '4%' },
        { label: 'Jewish', value: '0%' },
        { label: 'Muslim', value: '6%' },
        { label: 'Sikh', value: '6%' },
        { label: 'Any other religion or belief', value: '1%' },
        { label: 'Prefer not to say', value: '7%' }
    ];

    unpaidCareData = [
        { label: 'No', value: '85%' },
        { label: 'Yes, 1 - 19 hours a week', value: '8%' },
        { label: 'Yes, 20 - 49 hours a week', value: '1%' },
        { label: 'Yes, 50 or more hours a week', value: '5%' },
        { label: 'Prefer not to say', value: '1%' }
    ];

    primaryCarerData = [
        { label: 'Yes', value: '27%' },
        { label: 'No', value: '71%' },
        { label: 'Prefer not to say', value: '3%' }
    ];

    disabilityData = [
        { label: 'Yes', value: '4%' },
        { label: 'No', value: '94%' },
        { label: 'Prefer not to say', value: '3%' }
    ];

    limitedActivities = [
        { label: 'Yes, limited a lot', value: '0%' },
        { label: 'Yes, limited a little', value: '4%' },
        { label: 'No', value: '93%' },
        { label: 'Prefer not to say', value: '3%' }
    ];

    schoolTypeData = [
        { label: 'UK State-run or state-funded school (non-selective)', value: '74%' },
        { label: 'UK State-run or state-funded school (selective on academie faith or other arounds)', value: '6%' },
        { label: 'UK Independent or fee-paying school (bursary & non bursary)', value: '11%' },
        { label: 'Attended school outside the UK', value: '4%' },
        { label: 'Other', value: '1%' },
        { label: 'Prefer not to say', value: '5%' }
    ];

    highestIncomeEarnerData =  [
        { label: 'Modern professional occupations', value: '25%' },
        { label: 'Clerical and intermediate occupations', value: '3%' },
        { label: 'Senior managers and administrators', value: '16%' },
        { label: 'Technical and craft occupations', value: '8%' },
        { label: 'Routine manual and service occupations', value: '12%' },
        { label: 'Middle or junior managers', value: '3.3%' },
        { label: 'Traditional professional occupations', value: '9.4%' },
        { label: 'Long term unemployed (claimed Jobseeker\'s Allowance or earlier unemployment benefit for more than a year)', value: '0%' },
        { label: 'Prefer not to say', value: '16%' },
        { label: 'Others (such as retired, not applicable or don\'t know)', value: '20%' },
    ];

    highestQualificationData = [
        { label: 'Yes, one or both of my parents attended university and at least one has a degree level', value: '25%' },
        { label: 'No formal qualifications', value: '54%' },
        { label: 'Don\'t know', value: '6%' },
        { label: 'Prefer not to say', value: '15%' }
    ];


    genderData = [
        { category: "Male", value: 0.35 },
        { category: "Women", value: 0.63 },
        { category: "Prefer not to say", value: 0.02 },
    ];


    transgenderData: Partial<ChartOptions> = {
        series: [
            {
                name: "",
                data: [2, 93, 6]
            }
        ],
        chart: {
            type: "bar",
            height: 230,
        },
        plotOptions: {
            bar: {
                horizontal: true
            }
        },
        grid: {
            show: false,
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: [
                "Yes",
                "No",
                "Prefer not to say"
            ]
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: function (value) {
                    return value + "%";
                }
            },
            x: {
                show: false
            }
        }
    };


  constructor() {

  }

  ngOnInit(): void {
  }

}
