<app-employee-details>
    <ng-container *ngIf="isLoadingPermissions; else permissionsLoaded">
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-container>
    <ng-template #permissionsLoaded>
        <app-bootstrap-alert 
            *ngIf="showPercentageWarning"
            [text]="translate.instant('BankDetailsExceeds100Percent')" 
            localizationCode="BankDetailsExceeds100Percent" 
            type="danger">
        </app-bootstrap-alert>

        <app-data-grid-comp
            [tableId]="db_tables.BankDetails"
            [columns]="columns"
            [filterCategories]="filterCategories"
            [gridDataResult]="gridDataResult"
            [pageSize]="pageSize"
            [skip]="skip"
            category="BankDetails"
            [rowSelectionSettings]="{
                canSelectRows: true,
                selectableMode: 'multiple',
                selectRowBy: 'id'
            }"
            clickableRows="true"
            [isLoading]="isLoading"
            [clearSelectedItems]="clearSelectedItems"
            searchable="true"
            [sortable]="{
                mode: 'multiple'
            }"
            [sortableColumns]="sortableColumns"
            (emitPageChangeEvent)="pageChange($event)"
            (emitSearchEvent)="search($event)"
            (emitOpenDialogEvent)="openFormDialog($event)"
            (emitDeleteEvent)="deleteBankDetails($event)"
            (emitFilterEvent)="filterCallback($event)"
            (emitSortEvent)="sortChange($event)"
        ></app-data-grid-comp>
        <!-- <mat-card class="mat-elevation-z0">
            <mat-card-title class="header">
                <h5 appLocalizationPopupDirective localizationCode="BankDetails">{{ 'BankDetails' | translate: {Default: "Bank Details"} }}</h5>
        
                <div class="k-flex-grow"></div>

                <div class="buttons-container">
                    <mat-form-field appearance="fill" class="effective-date-form-field">
                        <mat-label>
                            {{ 'EffectiveDate' | translate: {Default: "Effective Date"} }}
                        </mat-label>
            
                        <input matInput [matDatepicker]="effectiveDatePicker" (dateChange)="effectiveDateChange($event)" [(ngModel)]="effectiveDate">
                        <span fxLayout="row" matSuffix>
                            <mat-datepicker-toggle [for]="effectiveDatePicker"></mat-datepicker-toggle>
                        </span>
                        <mat-datepicker #effectiveDatePicker></mat-datepicker>
                    </mat-form-field>

                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]" 
                        aria-hidden="false" 
                        class="material-icons-outlined k-mr-3 clickable-icon" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openAccessLogsDialog()"
                    >fingerprint</mat-icon>
                    <mat-icon 
                        setColor 
                        [setColorRules]="[
                            {
                                color: 'primaryColour',
                                events: ['hover']
                            }
                        ]" 
                        aria-hidden="false" 
                        class="material-icons-outlined k-mr-3 clickable-icon" 
                        aria-label="add icon"
                        (click)="$event.stopPropagation(); openHistoryDialog()"
                    >history</mat-icon>
                    <button 
                        mat-raised-button 
                        setColor="primaryColour" 
                        color="primary" 
                        [disabled]="formValid === false" 
                        appPreventDoubleClick 
                        (throttledClick)="getFormData = !getFormData"
                        *appTableAccess="[tableCreateAccessObj, tableUpdateAccessObj]">
                        {{ 'Save' | translate: {Default: "Save"} }}
                    </button> 
                </div>

            </mat-card-title>
            <mat-card-content>
                <app-form-generator 
                    *ngIf="!isLoading"
                    [formId]="formId"
                    [formData]="formData"
                    [targetEmployeeTablePermissions]="targetEmployeeTablePermissions"
                    [emitFormDataTrigger]="getFormData"
                    (emitFormData)="formDataEmitted($event)"
                    (emitFormStatus)="formStatusUpdated($event)"
                ></app-form-generator>
            </mat-card-content>
        </mat-card> -->
    </ng-template>
</app-employee-details>