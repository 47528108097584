<app-site-settings-menu>
    <mat-toolbar class="page-header" role="heading">
        <h1>Documents</h1>
    </mat-toolbar>

    <div class="container">
        <div class="controls">
            <div class="buttons">
                <button mat-flat-button  setColor="primaryColour" color="primary" (click)="openEditDocumentDialog()">
                    <mat-icon class="material-icons-outlined">add</mat-icon> 
                    Add
                </button>
                <button mat-flat-button>
                    <mat-icon class="material-icons-outlined" (click)="openEditDirectoryDialog()">create_new_folder</mat-icon> 
                </button>
                <button mat-flat-button>
                    <mat-icon class="material-icons-outlined" (click)="clearLocalStorage()">delete</mat-icon> 
                </button>
            </div>

        </div>
        
        <kendo-breadcrumb 
            *ngIf="!isLoadingBreadcrumbs" 
            [items]="breadcrumbs"
            (itemClick)="onItemClick($event)"
        ></kendo-breadcrumb>

        <kendo-grid
            [kendoGridBinding]="documents"
            [sortable]="true"
        >
            <kendo-grid-column field="name">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <ng-container *ngIf="icons[dataItem.contentType]; else blankIcon">
                        <img 
                            class="ms-icon" 
                            src={{icons[dataItem.contentType]}} 
                            style="width: 35px;
                                    height: 35px;
                                    vertical-align: middle;"> 
                    </ng-container>
                    <ng-template #blankIcon>
                        <ng-container *ngIf="dataItem.isDirectory; else blankIcon">
                            <img 
                                class="ms-icon" 
                                src="assets/icons/folder.svg" 
                                style="width: 30px;
                                        height: 30px;
                                        vertical-align: middle;">
                        </ng-container>
                        <ng-template #blankIcon>
                            <mat-icon class="blank-document-icon material-icons-outlined">description</mat-icon>
                        </ng-template>
                    </ng-template>
                    {{dataItem.name}}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Hugo Access" field="hugoAccess">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <img *ngIf="dataItem.hugoAccess === true" class="hugo-icon" src="assets/images/hugo/octo-bot-5.jpg">

                <!-- <mat-icon class="material-icons-outlined" *ngIf="dataItem.hugoAccess">lock</mat-icon> -->
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Security">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <mat-chip-list>
                        <mat-chip class="red-chip" *ngIf="dataItem.isPrivate === true">Private</mat-chip>
                        <mat-chip class="green-chip" *ngIf="dataItem.isPrivate === false && dataItem.security === null">Public</mat-chip>
                        <mat-chip class="orange-chip" *ngIf="dataItem.isPrivate === false && dataItem.security !== null">Custom</mat-chip>
                    </mat-chip-list>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="File Length" field="fileLength">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <div *ngIf="!dataItem.isDirectory">
                        {{formatBytes(dataItem.fileLength)}}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="Created By" field="version">
                <ng-template kendoGridCellTemplate let-dataItem>
                    Created {{dataItem.version.createdOn | date:'longDate'}} by <span class="clickable-icon" routerLink="{{routes.TALENT_TRACK}}{{routes.EDIT}}/{{dataItem.version.createdBy.id}}">{{dataItem.version.createdBy.firstName}} {{dataItem.version.createdBy.lastName}}</span>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column title="" field="actions">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <button mat-icon-button [matMenuTriggerFor]="menu" class="actions-btn" aria-label="Example icon-button with a menu">
                        <mat-icon class="material-icons-outlined">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="dataItem.isDirectory" (click)="changeRoute(dataItem)">
                            <mat-icon class="material-icons-outlined">folder_open</mat-icon> 
                            <span>Open Directory</span>
                        </button>
                        <button mat-menu-item *ngIf="!dataItem.isDirectory">
                            <mat-icon class="material-icons-outlined">download</mat-icon> 
                            <span>Download</span>
                        </button>
                        <button mat-menu-item (click)="dataItem.isDirectory ? openEditDirectoryDialog(dataItem) : openEditDocumentDialog(dataItem)">
                            <mat-icon class="material-icons-outlined">edit</mat-icon> 
                            <span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="openConfirmDeleteDialog(dataItem)">
                            <mat-icon class="material-icons-outlined">delete</mat-icon> 
                            <span>Delete</span>
                        </button>
                    </mat-menu>    
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
</app-site-settings-menu>
