export class Integration {
    id: string
    name: string
    description: string
    integration: {
        id: string
        name: string
    }
    frequency: string
    startDate: string
    createdOn: string
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    employees: {
        id: string
        firstname: string
        lastname: string
    }[]
    connectionSettings: {
        clientId: string
        clientSecret: string
        apiurl: string
        authurl: string
        certificate: string
        privateKey: string
        workerId: string
        associateOID: string
    }
    mappingId: string
}

export interface IntegrationDetails {
    id: string
    name: string
    description: string
    frequency: string
    startsOn: string
    createdOn: string
    employees: {
        id: string
        firstName: string
        lastName: string
    }[]
    createdBy: {
        id: string
        firstName: string
        lastName: string
    }
    connectionSettings: {
        clientId: string
        clientSecret: string
        apiurl: string
        authurl: string
        certificate: string
        privateKey: string
        workerId: string
        associateOID: string
    }
    mappingId: string
}

export interface Mapping {
    id: string
    name: string
    description: string
    categories: Category[]
}

export class Category {
    id: string
    name: string
    description: string
    selected: boolean
    routes: Route[]
}

export class Route {
    type: string
    route: string
    summary: string
    description: string
    selected: boolean
    fields: Field[]
}

export class Field {
    text: string
    valueType: string
    mappedTo: {
        table: string
        field: string
    }
}