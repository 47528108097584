<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Pay Gap Yearly Change</h5>
  
      <app-settings-menu></app-settings-menu>
    </mat-card-title>
  
    <mat-card-content class="chart__content">
        
        <div class="gender-section">
            <div class="title">Gender</div>

            <div class="text">
                <p>Our mean gender pay gap is now 22.2%, an increase from 2020 (18.3%).</p>

                <div class="values">
                    <div class="left section">
                        <div class="value">18.3%</div>
                        <div class="year">(2021)</div>
                    </div>
                    to
                    <div class="right section">
                        <div class="value">22.2%</div>
                        <div class="year">(2022)</div>
                    </div>
                </div>

                <p>During this time the median gender pay gap has decreased by 0.4% and our combined people gender pay gap (including members) has remained stationary at <span class="highlight">44.3%.</span></p>
                <p>The average gross hourly rate of pay for females is now <span class="highlight">£16.99</span> against male's rate of pay being <span class="highlight">£21.85</span> or a take-home of £33,131pa vs £42,608pa.</p>
            </div>
        </div>

        <div class="ethnicity-section">
            <div class="title">ETHNICITY</div>

            <div class="text">
                <p>Our mean ethnicity pay gap has fallen to 8.1% (2022) from 14.5% (2021).</p>

                <div class="values">
                    <div class="left section">
                        <div class="value">14.5%</div>
                        <div class="year">(2021)</div>
                    </div>
                    to
                    <div class="right section">
                        <div class="value">8.1%</div>
                        <div class="year">(2022)</div>
                    </div>
                </div>

                <p>The proportion of people who have chosen to disclose their ethnicity has also risen - one of our goals from last year's report - and we have a greater understanding of why those who do not disclose ethnicity have chosen not to.</p>
                <p>The average gross hourly rate of pay has risen to <span class="highlight">£18.10</span> compared to <span class="highlight">£16.34</span> in 2021, against white British employees £18.64 or £35,295pa vs £36,348pa.</p>
            </div>
        </div>
    </mat-card-content>
</mat-card>


