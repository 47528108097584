<button class="header__title-button" mat-button [matMenuTriggerFor]="menu">
    <mat-icon class="material-icons-outlined header__title-button-icon">menu</mat-icon>
</button>
<mat-menu #menu="matMenu">
    <ng-container *ngIf="translationsLoaded; else loading">
        <ng-container
          *ngFor="let item of menuItems"
        >
          <ng-container *appHasModuleAccess="item?.module">
      
            <button mat-menu-item
            [routerLink]="item.route" 
            setColor
            [setColorRules]="[
              {
                  color: 'primaryColour',
                  events: ['hover']
              },
              {
                  color: 'primaryColour',
                  setIfClass: ['primaryColor'],
                  events: ['routerLinkActive']
              },
            ]"
            routerLinkActive="primaryColor" 
            class="primaryHoverColor" 
            *appHasFeature="item.feature"
          >
            <mat-icon 
              routerLinkActive="primaryColor" 
              setColor
              [setColorRules]="[
                {
                    color: 'primaryColour',
                    events: ['hover']
                },
                {
                    color: 'primaryColour',
                    setIfClass: ['primaryColor'],
                    events: ['routerLinkActive']
                },
              ]"
              class="material-icons-outlined"
            >{{ item.icon }}</mat-icon> 
      
            <span appLocalizationPopupDirective [localizationCode]="item.localizationCode">
                {{ translate.instant(item.localizationCode) }}
            </span>
      
        </button>
          </ng-container>
        </ng-container>
    </ng-container>

    <ng-template #loading>
        <ngx-skeleton-loader
            count="5"
            [theme]="{ 
                'height.px': 50
            }"
        ></ngx-skeleton-loader>
    </ng-template>
</mat-menu>