<h5 mat-dialog-title>Review</h5>

<mat-dialog-content>
    <ng-container *ngIf="!form; else LoadedFormElement">
        <div class="loading-container" style="width:100%;">
            <ngx-skeleton-loader
                count="5"
                [theme]="{
                'height.px': 50
                }"
            ></ngx-skeleton-loader>
        </div>
    </ng-container>

    <ng-template #LoadedFormElement>
        <form class="form" *ngIf="form" [formGroup]="form">

            <div class="form-group">
                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Merit Increase Guideline:</mat-label>
                    <input matInput formControlName="meritIncreaseGuideline" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Current Base Salary:</mat-label>
                    <input matInput formControlName="currentBaseSalary" [disabled]="true">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>New Base Salary:</mat-label>
                    <input matInput formControlName="newBaseSalary"  [(ngModel)]="this.inputData.fieldBaseData.newBaseSalary" >
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Proposed Increase Amount:</mat-label>
                    <input matInput formControlName="proposedIncreaseAmount" [(ngModel)]="this.inputData.fieldBaseData.proposedIncreaseAmount" (input)="onAmountChange($event)">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Proposed Increase Percentage:</mat-label>
                    <input matInput formControlName="proposedIncreasePercentage" [(ngModel)]="this.inputData.fieldBaseData.proposedIncreasePercentage" (input)="onPercentageChange($event)">
                </mat-form-field>
            </div>
            <div class="form-group">
                <mat-form-field class="form-group" appearance="fill">
                    <mat-label>Comments:</mat-label>
                    <textarea matInput formControlName="comments" [(ngModel)]="this.inputData.fieldBaseData.comments"></textarea>
                </mat-form-field>
            </div>
        </form>
    </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button" (click)="close()">Close</button>
    <button mat-raised-button setColor="primaryColour" color="primary" (click)="save()" [disabled]="!form.valid" *ngIf="form">Save</button>
</mat-dialog-actions>
