<app-succession-track>
    <mat-card class="mat-elevation-z0">
        <mat-card-title class="header">
            <h5>{{ 'TalentMatrix' | translate: {Default: "Talent Matrix"} }}</h5>
        </mat-card-title>
        <mat-card-content>
            <ng-container *ngIf="isLoading; else loaded">
                <ngx-skeleton-loader
                    count="10"
                    [theme]="{ 
                        'height.px': 50
                    }"
                ></ngx-skeleton-loader>
            </ng-container>

            <ng-template #loaded>
                <div class="top">
                    <div class="arrow-container">
                        <div class="arrow"></div>
        
                        <div class="potential-arrow">
                            <div class="word">Potential</div>
                        </div>
                    </div>
        
                    <div class="grid-container" *ngIf="isLoading === false">
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Potential Gem"
                            [employees]="highLow"
                            [color]="colors[2]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="High Potential"
                            [employees]="highMedium"
                            [color]="colors[3]"                        
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Star"
                            [employees]="highHigh"
                            [color]="colors[4]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Inconsistent"
                            [employees]="mediumLow"
                            [color]="colors[1]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Core Player"
                            [employees]="mediumMedium"
                            [color]="colors[1]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="High Performer"
                            [employees]="mediumHigh"
                            [color]="colors[3]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Risk"
                            [employees]="lowLow"
                            [color]="colors[0]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Average Performer"
                            [employees]="lowMedium"
                            [color]="colors[1]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
        
                        <app-matrix-item-list 
                            class="grid-item"
                            title="Solid Performer"
                            [employees]="lowHigh"
                            [color]="colors[2]"
                            [employeesLength]="employees.length"
                            (employeeUpdated)="employeeUpdated($event)"
                        ></app-matrix-item-list>
                    </div>
                </div>
    
                <div class="bottom">
                    <div class="arrow-container">
                        <div class="performance-arrow">
                            <div class="word">Performance</div>
                        </div>
    
                        <div class="arrow"></div>
                    </div>
                </div>
            </ng-template>
        </mat-card-content>
    </mat-card>
</app-succession-track>