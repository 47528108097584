<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
      <h4 class="title">What's New</h4>
    </mat-card-title>

    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="item" role="listitem" *ngFor="let item of items">
                        <img [src]="item.img">
                        <div class="description">{{item.text}}</div>
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-card-content>
</mat-card>
