import { Component, OnInit } from '@angular/core';
import { ChatgptService } from './services/chatgpt.service';
import {
  Message,
  User,
} from "@progress/kendo-angular-conversational-ui";

@Component({
  selector: 'app-chat-gpt-widget',
  templateUrl: './chat-gpt-widget.component.html',
  styleUrls: ['./chat-gpt-widget.component.scss']
})
export class ChatGptWidgetComponent implements OnInit {
  public user: User = { id: 1 };
  public bot: User = { id: 0 };

  public messages: Message[] = [
    {
      author: this.bot,
      text: "My name is Hugo. I'm here to assist you with any HR-related queries or tasks you might have. How can I help you today?",
    },
    // {
    //   author: this.bot,
    //   text: "Please note that I am a prototype and my responses are generated by a machine learning model. If you encounter any issues, please let me know so I can improve my responses."
    // },
    // {
    //   author: this.user,
    //   text: "Hello Hugo, I have a question about my leave balance. Can you help me with that?",
    // },
    // {
    //   author: this.bot,
    //   text: "Sure! I can help you with that. Please provide me with your employee ID so I can look up your leave balance."
    // },
    // {
    //   author: this.user,
    //   text: "My employee ID is 12345"
    // },
    // {
    //   author: this.bot,
    //   text: "Thank you! Let me check your leave balance for you. One moment please..."
    // },
    // {
    //   author: this.bot,
    //   text: "Your current leave balance is 10 days. Is there anything else I can help you with?"
    // }

  ];

  threadId: string;
  messageText: string
  hugoStatus: string = 'Offline';
  error: boolean;
  isWaitingForResponse: boolean;
  showSpinner: boolean = false;

  constructor(private chatgptService: ChatgptService) {}

  ngOnInit(): void {
    const textarea = document.getElementById('text-area');

    textarea?.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        this.sendMessage(this.messageText);
      }
    });

    this.getThread();
  }

  getThread() {
    this.showSpinner = true;

    setTimeout(() => {
      this.showSpinner = false;
    }, 3000);

    this.chatgptService.getThread()
    .subscribe(
      {
        next: (v: any) => {
          this.threadId = v.threadId;
          this.hugoStatus = 'Online';
        },
        error: (e) => this.hugoStatus = 'Offline',
        complete: () => {}
      }
    );
  }

  scrollToBottom(): void {
    const chatContainer = document.getElementById('chat-container');
    chatContainer.scrollTop = chatContainer.scrollHeight      
  }

  // test() {
  //   let t = "I found information related to absences in the company policy document: Regular attendance and punctuality are emphasized in the company policy. Employees are expected to report to work on time and notify their supervisor in advance of any planned absences. This is crucial for the smooth operation of the business【6:0†source】. If you need more specific details or have any other questions regarding absences or related policies, feel free to ask!"
  //   this.messages = [...this.messages, { author: this.bot, text: '' }];
  //   this.textTypingEffect(t);
  // }

  textTypingEffect(text, index = 0) {
    this.messages[this.messages.length - 1].text = text.substring(0, index);
    this.scrollToBottom();

    if(index < text.length) {
      setTimeout(() => {
        this.textTypingEffect(text, index + 1);
      }, 50);
    }
  }

  public sendMessage(messageText: string): void {
    if(messageText !== null) {
      this.messages = [...this.messages, { author: this.user, text: messageText }];
      this.messageText = null;
      this.isWaitingForResponse = true;

      setTimeout(() => {
        this.scrollToBottom();
        this.messageText = null;
      }, 100);


      // setTimeout(() => {
      //   this.messages = [...this.messages, { author: this.bot, text: '' }];
      //   this.textTypingEffect('THIS IS NOT A REAL MESSAGE. THIS IS A TEST MESSAGE.');
      //   this.isWaitingForResponse = false;
      // }, 1000);
  
      this.chatgptService.sendMessage({ threadId: this.threadId, message: messageText })
      .subscribe(
        {
          next: (v: any) => {
            this.messages = [...this.messages, { author: this.bot, text: '' }];
            this.textTypingEffect(v.messages[0][0].text.value);
          },
          error: (e) => this.error = true,
          complete: () => {
            this.isWaitingForResponse = false;
          }
        }
      );
    }
  }

}
