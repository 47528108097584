import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { api_routes, db_tables, routes } from '@app/consts';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { Job, JobPosition } from '../../models/job-management.model';
import { JobManagementService } from '../../services/job-management.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HistoryDialogComponent } from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/components/employment-record-details/components/history-dialog/history-dialog.component';
import { ChangeReasonDialogComponent } from '@app/shared/components/change-reason-dialog/change-reason-dialog.component';
import { FormGeneratorComponent } from '@app/shared/components/form-generator/form-generator.component';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Culture } from "@app/shared/models/system-language/culture.model";
import { CultureService } from "@app/core/services/system-language/culture.service";

@Component({
    selector: 'app-job-editor',
    templateUrl: './job-editor.component.html',
    styleUrls: ['./job-editor.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class JobEditorComponent implements OnInit {
    @ViewChild(FormGeneratorComponent) formGeneratorComponent: FormGeneratorComponent;

    @Input() readOnly: boolean;
    @Input() readOnlyPositionId: string;

    @Output() formGeneratorComponentEmit: EventEmitter<FormGeneratorComponent> = new EventEmitter<FormGeneratorComponent>();

    public routes: typeof routes = routes;
    // formId: string = 'frm_QfZ5gj3yCOQWOt';
    // formId: string = 'frm_hDiK2dcXX4QGw5';
    form: UntypedFormGroup;
    changeReasonFormId: string = 'frm_cwTMKxheRUNZud';
    formData: any;
    gridData: any[];
    getFormData: boolean = false;
    formValid: boolean = false;
    job: Job;
    jobId: string;
    isLoading: boolean = true;
    loadingJobPositions: boolean;
    jobPositions: JobPosition[];
    effectiveDate = new Date();
    priorityList: any[] = [{value: 1, text: "Primary"}, {value: 2, text: "Secondary"}, {value: 3, text: "Other"}];
    public columns: any[] = [
        {field: 'name', title: 'name'},
        {field: 'jobBand', subField: "text", title: 'job Band'},
        {field: 'jobFamily', subField: "text", title: 'job Family'},
        {field: 'jobGroup', subField: "text", title: 'job Group'},
        {field: 'jobType', subField: "text", title: 'job Type'}
    ];

    cultures: Culture[];

    jobTypes = [
        {value: 'full_time', text: 'Full-time'},
        {value: 'part_time', text: 'Part-time'},
        {value: 'contract', text: 'Contract'}
    ];

    jobSteps = [
        {value: 'step_1', text: 'Step 1'},
        {value: 'step_2', text: 'Step 2'},
        {value: 'step_3', text: 'Step 3'}
    ];

    jobGroups = [
        {value: 'technical', text: 'Technical'},
        {value: 'management', text: 'Management'},
        {value: 'administrative', text: 'Administrative'}
    ];

    jobFamilies = [
        {value: 'information_technology', text: 'Information Technology'},
        {value: 'engineering', text: 'Engineering'},
        {value: 'sales', text: 'Sales'}
    ];

    jobBands = [
        {value: 'band_1', text: 'Band 1'},
        {value: 'band_2', text: 'Band 2'},
        {value: 'band_3', text: 'Band 3'},
        {value: 'band_4', text: 'Band 4'},
        {value: 'band_5', text: 'Band 5'}
    ];

    marketPositionTitles = [
        {value: 'software_engineer', text: 'Software Engineer'},
        {value: 'product_manager', text: 'Product Manager'},
        {value: 'data_scientist', text: 'Data Scientist'}
    ];

    marketViews = [
        {value: 'internal', text: 'Internal'},
        {value: 'external', text: 'External'}
    ];

    marketPositions = [
        {value: 'entry_level', text: 'Entry level'},
        {value: 'mid_level', text: 'Mid-level'},
        {value: 'senior_level', text: 'Senior level'}
    ];

    jobCodes = [
        {value: 'code#2123', text: 'Code#2123'},
        {value: 'code#2124', text: 'Code#2124'},
        {value: 'code#2125', text: 'Code#2125'}
    ];


    constructor(
        private dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private cultureService: CultureService,
        private snackbarService: SnackbarService,
        private overlayService: OverlayService,
        private jobService: JobManagementService,
        private translate: TranslateService,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit(): void {
        this.createForm();

        this.cultureService.getCultures().subscribe(res => {
            this.cultures = res
        });

        this.jobId = this.getIdInURL();

        if (this.jobId !== null) {
                this.getJob();
                this.getPositionEmployees();
            //     //TODO WIP
            //     this.gridData = this.jobService.generateMarketData();
            //
            //     this.isLoading = false;
        } else {
            this.addNewLocalization();
            this.gridData = [];
            this.isLoading = false;
        }
    }

    // formDataEmitted(formData) {
    //     this.openChangeReasonDialog(formData);
    // }
    //
    // formStatusUpdated(formValid) {
    //     this.formValid = formValid;
    //     this.formGeneratorComponentEmit.emit(this.formGeneratorComponent);
    // }

    getIdInURL(): string {
        let IdInURL: string;

        this.route.paramMap.subscribe(
            params => IdInURL = params.get('positionId')
        );

        return IdInURL;
    }

    getJob() {
        this.isLoading = true;

        let asOf = moment(this.effectiveDate).format('YYYY-MM-DD');

        this.jobService.getJob(this.jobId, asOf)
        .subscribe(
            res => {
                this.job = res;
                this.updateForm();
                this.isLoading = false;
            }
        );
    }

    getPositionEmployees() {
        this.loadingJobPositions = true;

        this.jobService.getJobPositions(this.jobId)
        .pipe(
            finalize(() => this.loadingJobPositions = false)
        )
        .subscribe(
            res => {
                this.jobPositions = res;
            }
        );
    }

    updateForm() {
        // const { nameLocalizations, workRotation, employeesInPosition } = this.job;

        // nameLocalizations.forEach(item => {
        //
        // });

        // workRotation.elements.forEach(item => {
        //     (this.form.get('workRotation').get('elements') as FormArray).push(this.fb.group(item));
        // });
        //
        // employeesInPosition.forEach(item => {
        //     (this.form.get('employeesInPosition') as FormArray).push(this.fb.group(item));
        // });

        // console.log(this.job)
        //
        const { nameLocalizations, workRotation, employeesInPosition, name, ...rest } = this.job;
        //
        // console.log(rest)
        this.form.patchValue(rest);

        // const { name, jobCode, jobFamily, jobGroup, jobBand, jobType,
        //     nameLocalizations, workRotation, employeesInPosition } = this.job;

        // console.log(name, jobCode, jobFamily, jobGroup, jobBand, jobType)
        // update form fields
        // this.form.patchValue({
        //     // name,
        //     jobCode,
        //     jobFamily,
        //     jobGroup,
        //     jobBand,
        //     jobType
        // });

        // const nameLocalizationArray = this.form.get('nameLocalizations') as FormArray;
        nameLocalizations.forEach(item => {
            this.addNewLocalization(item.culture, item.text);
        });

        // update the rest of the fields
        // this.form.patchValue(this.job);

        console.log(this.form)
    }



    // createFormData() {
    //     this.formData = {
    //         id: this.job ? this.job.id : null,
    //         clientPositionId: this.job ? this.job.clientPositionId : null,
    //         startDate: this.job ? this.job.startDate : null,
    //         endDate: this.job ? this.job.endDate : null,
    //         organization: this.job ? this.job.organization : null,
    //         assistant: this.job ? this.job.assistant : false,
    //         functionalJobTitle: this.job && this.job.functionalJobTitle ? this.job.functionalJobTitle.id : null,
    //         region: this.job && this.job.region ? this.job.region.id : null,
    //         division: this.job && this.job.division ? this.job.division.id : null,
    //         subDivision: this.job && this.job.subDivision ? this.job.subDivision.id : null,
    //         department: this.job && this.job.department ? this.job.department.id : null,
    //         workLocation: this.job ? this.job.workLocation?.id : null,
    //         workRotationStartDate: this.job ? this.job.workRotationStartDate : null,
    //         workRotation: this.job ? this.job.workRotation?.id : null,
    //         jobGroup: this.job && this.job.jobGroup ? this.job.jobGroup.id : null,
    //         jobFamily: this.job && this.job.jobFamily ? this.job.jobFamily.id : null,
    //         jobBand: this.job && this.job.jobBand ? this.job.jobBand : null,
    //         jobType: this.job && this.job.jobType ? this.job.jobType : null,
    //         jobStep: this.job && this.job.jobStep ? this.job.jobStep.id : null,
    //         projectTeam: this.job && this.job.projectTeam ? this.job.projectTeam.id : null,
    //         marketPositionTitle: this.job && this.job.marketPositionTitle ? this.job.marketPositionTitle.id : null,
    //         marketPosition: this.job && this.job.marketPosition ? this.job.marketPosition.id : null,
    //         marketView: this.job && this.job.marketView ? this.job.marketView.id : null,
    //         unionCode: this.job && this.job.unionCode ? this.job.unionCode.id : null,
    //         shiftCode: this.job && this.job.shiftCode ? this.job.shiftCode.id : null,
    //         employmentGroup: this.job && this.job.employmentGroup ? this.job.employmentGroup.id : null,
    //         employeeCategory: this.job && this.job.employeeCategory ? this.job.employeeCategory.id : null,
    //         employmentType: this.job && this.job.employmentType ? this.job.employmentType.id : null,
    //         enforceSlotLimit: this.job ? this.job.enforceSlotLimit : false,
    //         numberOfSlots: this.job ? this.job.numberOfSlots : 1,
    //         slotsAvailable: this.job ? this.job.slotsAvailable : null,
    //         slotsFilled: this.job ? this.job.slotsFilled : null,
    //         parentPosition: this.job?.parentPosition?.id ? this.job.parentPosition.id : null,
    //         name: this.job ? this.job.name : null,
    //         costCenters: this.job ? this.job.costCenters : null,
    //         fte: this.job ? this.job.fte : null,
    //         udl1: this.job ? this.job.udl1?.id : null,
    //         udl2: this.job ? this.job.udl2?.id : null,
    //         udt1: this.job ? this.job.udt1 : null,
    //         udt2: this.job ? this.job.udt2 : null,
    //         udd1: this.job ? this.job.udd1 : null,
    //         udd2: this.job ? this.job.udd2 : null,
    //     };
    //
    //     console.log(this.formData);
    // }

    openChangeReasonDialog(formData: any) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            formId: this.changeReasonFormId,
        };

        const dialogRef = this.dialog.open(ChangeReasonDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data) {
                    this.addChangeReasonsToFormData(formData, data);
                }
            }
        );
    }

    addChangeReasonsToFormData(formData: any, changeReasonFormData: any) {
        let merged = {...formData, ...changeReasonFormData};
        this.save(merged);
    }

    save(formData: any) {
        this.overlayService.show();

        // Add asof and originalCreateOn values for future effective dating
        // formData.asOf = moment(this.effectiveDate).format('YYYY-MM-DD');
        // formData.originalCreatedOn = this.job?.version?.createdOn ? this.job?.version?.createdOn : null;

        // Adding the organization id to the form data for saving
        // formData.organization = formData.organization?.id;
        // if (formData.startDate) {
        //     formData.startDate = moment(formData.startDate).format().slice(0, 10);
        // }
        // if (formData.endDate) {
        //     formData.endDate = moment(formData.endDate).format().slice(0, 10);
        // }
        if (this.job) {
            this.jobService.putJob(this.job.id, formData)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('SavedSuccessfully')}`, 'clear', 'success');
                }
            );
        } else {
            this.jobService.postJob(formData)
            .pipe(
                finalize(() => this.overlayService.hide())
            )
            .subscribe(
                (res) => {
                    this.snackbarService.openSnackBar(`${this.translate.instant('CreatedSuccessfully')}`, 'clear', 'success');
                }
            );
        }
    }

    isActiveEmployee(endDate: string) {
        if (endDate === null) {
            return -1;
        }

        return moment().diff(endDate, 'days');
    }

    openHistoryDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
            employeeId: null,
            entityId: this.job.id,
            url: api_routes.POSITIONS,
            columns: this.columns,
            tableId: db_tables.Positions
        };

        const dialogRef = this.dialog.open(HistoryDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
            }
        );
    }

    effectiveDateChange(event) {
        if (this.job) {
            this.getJob();
        }
    }

    createForm() {
        this.form = this.fb.group({
            name: this.fb.array([]),
            jobType: [this.job?.jobType || null, Validators.required],
            jobStep: [''],
            jobGroup: [''],
            jobFamily: [''],
            jobBand: [''],
            jobCode: [''],
            marketPositionTitle: [''],
            marketView: [''],
            marketPosition: [''],
            targetPercentile: [''],
            salaryMin: [''],
            salaryMidpoint: [''],
            salaryMax: [''],
            benchmarkedRanges: this.fb.array([
                this.createBenchmarkedRange('primary'),
                this.createBenchmarkedRange('secondary'),
                this.createBenchmarkedRange('other')
            ])
        });
    }

    get localizations() {
        return this.form.controls["name"] as UntypedFormArray;
    }

    //Create a textLocalization form group object to add to the localizations form array
    addNewLocalization(culture?: string, text?: string) {
        const localizationForm = this.fb.group({
            culture: [culture || '', Validators.required],
            text: [text || '', Validators.required]
        });

        this.localizations.push(localizationForm);
    }

    deleteLocalization(index: number) {
        this.localizations.removeAt(index);
    }

    createBenchmarkedRange(defaultType = ''): FormGroup {
        return this.fb.group({
            marketSurveySource: [''],
            marketPositionTitle: [''],
            marketMin: [''],
            marketMax: [''],
            benchmarkRangeType: [defaultType]
        });
    }


// Getter for easier access to the benchmarkedRanges FormArray.
    get benchmarkedRanges(): FormArray {
        return this.form.get('benchmarkedRanges') as FormArray;
    }

// Function to add a new benchmarked range FormGroup to the benchmarkedRanges FormArray.
    addBenchmarkedRange(): void {
        this.benchmarkedRanges.push(this.createBenchmarkedRange());
    }
}

