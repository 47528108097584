import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { Directory } from '@app/shared/models/employee/documents/directory.model';
import {
  TreeItemDropEvent,
  DropPosition,
  TreeItemLookup,
  DropAction,
} from "@progress/kendo-angular-treeview";
import { EmployeeDocumentsService } from '../../services/employee-documents.service';
import { finalize, Observable } from 'rxjs';
import { Document } from '../../models/document.model';
import { ActivatedRoute, Router } from '@angular/router';
import { db_tables, routes } from '@app/consts';
import { EmployeeTableSecurity } from '@app/shared/models/employee.model';
import { TablePermissionsService } from '@app/core/services/table-permissions/table-permissions.service';

const isOfType = (fileName: string, ext: string) =>
  new RegExp(`.${ext}\$`).test(fileName);
const isFile = (name: string) => name.split(".").length > 1;

@Component({
  selector: 'app-documents-tree-view',
  templateUrl: './documents-tree-view.component.html',
  styleUrls: ['./documents-tree-view.component.scss']
})
export class DocumentsTreeViewComponent implements OnInit {
  @Input() employeeID: string;
  @Input() refreshView: boolean;
  @Output() editAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() viewAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() moveAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadAction: EventEmitter<any> = new EventEmitter<any>();
  @Output() showCreateNewDirectoryForm: EventEmitter<any> = new EventEmitter<any>();
  @Output() showDocumentUploadDialog: EventEmitter<any> = new EventEmitter<any>();

  public routes: typeof routes = routes;
  isLoading: boolean = true;
  documentsList$: Observable<Document[]>;
  public expandedKeys: any[] = [];
  public pageSize = 1;
  tableId: string = db_tables.DocumentLibrary;
  targetEmployeeTablePermissions: EmployeeTableSecurity[];
  isLoadingPermissions: boolean = true;
  employeeId: string;
  
  icons = {
    'application/pdf': 'assets/icons/pdf-icon.svg',
    'application/msword': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'assets/icons/docx-icon.svg',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'assets/icons/excel-icon.svg',
    'application/vnd.oasis.opendocument.spreadsheet': 'assets/icons/ods-icon.svg',
    'text/csv': 'assets/icons/csv-icon.svg',
    'application/zip': 'assets/icons/zip-icon.svg',
    'image/jpeg': 'assets/icons/jpg-icon.svg'
  };
  
  constructor(
    private employeeDocumentsService: EmployeeDocumentsService,
    private snackbarService: SnackbarService,
    private tablePermissionsService: TablePermissionsService,
    private route: ActivatedRoute,
    private router: Router
  ) { 
    this.employeeId = this.getIdInURL();
  }

  ngOnInit(): void {
    this.getTargetEmployeeTablePermissions();
    this.documentsList$ = this.employeeDocumentsService.getDocuments(this.employeeID);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.refreshView && (changes.refreshView.currentValue !== changes.refreshView.previousValue)) {
      this.documentsList$ = this.employeeDocumentsService.getDocuments(this.employeeID);
    }
  }

  public isExpanded = (dataItem: any, index: string) => {
    return this.expandedKeys.indexOf(index) > -1;
  };

  public handleCollapse(node) {
    this.expandedKeys = this.expandedKeys.filter((k) => k !== node.index);
  }

  public handleExpand(node) {
    this.expandedKeys = this.expandedKeys.concat(node.index);
  }

  getIdInURL(): string {
    let IdInURL: string;
    
    this.route.paramMap.subscribe(
      params => IdInURL = params.get("id")
    )
      
    return IdInURL;
  }

  get tableReadAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'read',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableCreateAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'create',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableUpdateAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'update',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  get tableDeleteAccessObj() {
    return {
      tableId: this.tableId,
      permission: 'delete',
      targetEmployeeTablePermissions: this.targetEmployeeTablePermissions
    }
  }

  getTargetEmployeeTablePermissions() {
    this.isLoadingPermissions = true;

    let currentFetchedData = this.tablePermissionsService.getFetchedTargetEmployeeTablePermissions(this.employeeId);

    // if the current table permissions that are saved are for this employee use those, else fetch the new permissions.
    if(currentFetchedData !== null){
      this.targetEmployeeTablePermissions = currentFetchedData;
      this.isLoadingPermissions = false;
    }
    else {
      this.tablePermissionsService.getTargetEmployeeTablePermissions(this.employeeId)
      .pipe(
        finalize(() => {
            this.isLoadingPermissions = false;
        })
      )
      .subscribe(
        res => {
          this.targetEmployeeTablePermissions = res;
        }
      );
    }
  }

  public hasChildren = (item: any) => item.isFolder === true;

  public fetchChildren = (item: any) => this.employeeDocumentsService.getDocuments(this.employeeID, item.id);

  public getDragStatus(
    action: DropAction,
    destinationItem: TreeItemLookup
  ): string {
    if (
      destinationItem && destinationItem.item.dataItem.isFolder === false
    ) {
      console.log(destinationItem)
      return "k-i-cancel";
    }

    switch (action) {
      case DropAction.Add:
        return "k-i-plus";
      case DropAction.InsertTop:
        return "k-i-insert-up";
      case DropAction.InsertBottom:
        return "k-i-insert-down";
      case DropAction.InsertMiddle:
        return "k-i-insert-middle";
      case DropAction.Invalid:
      default:
        return "k-i-cancel";
    }
  }

  public handleDrop(event: TreeItemDropEvent): void {
    if( event.destinationItem.item.dataItem.isFolder ) {
      this.moveAction.emit({
        'destination': event.destinationItem.item.dataItem, 
        'itemToMove': event.sourceItem.item.dataItem
      });
    }
    // else if(event.destinationItem.item.dataItem.elementType !== 'FOLDER' && event.destinationItem.parent){
    //   this.moveAction.emit({
    //     'destination': event.destinationItem.parent.item.dataItem, 
    //     'itemToMove': event.sourceItem.item.dataItem
    //   })
    // }
    // else if(!event.destinationItem.parent) {
    //   this.moveAction.emit({
    //     'destination': null, 
    //     'itemToMove': event.sourceItem.item.dataItem
    //   })
    // }
  }

  emitEditEvent(item: Directory) {
    this.editAction.emit(item);
  }
  
  emitDeleteEvent(item: Directory) {
    this.deleteAction.emit(item);
  }

  emitViewFileEvent(item: Directory) {
    this.viewAction.emit(item);
  }

  emitDownloadEvent(item: Directory) {
    this.downloadAction.emit(item);
  }

  emitShowCreateNewDirectoryForm(parentDiretory?: Directory) {
    this.showCreateNewDirectoryForm.emit(parentDiretory ? parentDiretory : null);
  }

  emitShowDocumentUploadDialog(parentDiretory?: Directory) {
    this.showDocumentUploadDialog.emit(parentDiretory ? parentDiretory : null);
  }
}


