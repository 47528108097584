<kendo-grid
    [data]="gridDataResult"
    [pageSize]="pageSize"
    [skip]="skip"
    [style.maxHeight.vh]="65"
    [resizable]="true"
    [pageable]="{
        info: true,
        pageSizes: true,
        previousNext: true
    }"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
    [loading]="isLoading"
    kendoGridSelectBy="id"
    [selectable]="{enabled: true}"
    [(selectedKeys)]="selectedItems"
    (selectionChange)="selectionChange($event)"
    [rowClass]="selectionToggleCallback"
>

    <ng-template kendoGridToolbarTemplate>
        <div class="filters-container">
            <mat-form-field class="search-field" appearance="outline">
                <input matInput
                    placeholder="Search..."
                    [(ngModel)]="searchValue"
                    (input)="search()"
                >
                <mat-icon matPrefix class="material-icons-outlined">
                    search
                </mat-icon>
            </mat-form-field>
        </div>

        <div class="mt-2">
            <filter-control [categories]="filterCategories" (callback)="filterCallback($event)"></filter-control>
        </div>
    </ng-template>

    <kendo-grid-checkbox-column
        [width]="60"
        showSelectAll="true"
    ></kendo-grid-checkbox-column>

    <kendo-grid-column field="fullName" title="Full Name" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="employee-name">
                <app-employee-image [width]="50" [height]="50" [round]="true" class="profile-pic" [employeeId]="dataItem.id" *ngIf="!isLoading"></app-employee-image>
                <span class="name">
                    {{dataItem.firstname}} {{dataItem.lastname}}
                </span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
            *ngFor="let col of columns"
            [field]="col.field"
            [title]="col.title"
            [width]="165"
    ></kendo-grid-column>

    <kendo-grid-column title="Positions" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                <span *ngFor="let position of dataItem.positions" class="position">
                    {{position.name}}
                </span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Managers" [width]="250">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                <ng-container *ngFor="let manager of dataItem.managers" >
                    <div class="manager-name">
                        <app-employee-image [width]="35" [height]="35" [round]="true" class="profile-pic" [employeeId]="manager.id" *ngIf="!isLoading"></app-employee-image>
    
                        <span class="manager">
                            {{manager.firstName}} {{manager.lastName}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="organization" title="{{ 'Organization' | translate: {Default: 'Organization'} }}" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    {{dataItem.organization?.name}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Work Locations" [width]="200">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                <span *ngFor="let workLocation of dataItem.workLocations" class="workLocation">
                    {{workLocation.name}}
                </span>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="email" title="Work Email" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                {{dataItem.email}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="cellNumber" title="Cell Number" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    {{dataItem.cellNumber}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="phoneNumber" title="Phone Number" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    {{dataItem.phoneNumber}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="status" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    {{dataItem.status}}
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Sub Status" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    <mat-chip-list>
                        <mat-chip 
                            *ngIf="dataItem.subStatus"
                            class="green-chip"
                        >{{dataItem.subStatus?.text}}</mat-chip>
                    </mat-chip-list>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="timezone" title="timezone" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
            <div class="grid-cell-vertical">
                    {{dataItem.timezone.text}}
            </div>
        </ng-template>
    </kendo-grid-column>
</kendo-grid>