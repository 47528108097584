<mat-accordion multi>
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-checkbox setColor="primaryColour" class="example-margin" [(ngModel)]="task.completed" (click)="checkboxChange($event); $event.stopPropagation();"></mat-checkbox>
            <span class="task">{{task.name}}</span>
        </mat-panel-title>
        <mat-panel-description>
            {{task.description}}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="letter" [innerHTML]="selectedLetter?.letterHTML"></div>
      
    </mat-expansion-panel>
</mat-accordion>