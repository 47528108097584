<mat-card class="mat-elevation-z0">
    <mat-card-title class="header">
      <h4 class="title">Tasks awaiting your action</h4>
    </mat-card-title>

    <mat-card-content class="content">
        <ng-container *ngIf="loading; else loaded">
            <ngx-skeleton-loader count="5" [theme]="{ 'height.px': 30 }"></ngx-skeleton-loader>
        </ng-container>
        <ng-template #loaded>
            <mat-list role="list">
                <mat-list-item class="task" role="listitem" *ngFor="let task of taskOptions">
                    <div>
                        <div class="description">{{task.description}}</div>
                        <div class="date">Due by: {{task.dueDate | date:'mediumDate'}}</div>
                    </div>

                    <div class="actions">
                        <button mat-stroked-button (click)="startTask(task)">
                            Start
                        </button>
                    </div>
                </mat-list-item>
            </mat-list>
        </ng-template>
    </mat-card-content>
</mat-card>
