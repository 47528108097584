<app-layout>
    <mat-toolbar class="page-header" role="heading">
        <h1>Diversity Report</h1>
    </mat-toolbar>

    <div class="content" fxLayout="row" fxLayoutAlign="start start">
        <div>
            <app-diversity-by-age></app-diversity-by-age>
            <app-simple-piechart [dataArray]="genderData" title="Gender Identity"></app-simple-piechart>
            <app-simple-horizontal-bar-chart [chartOptions]="transgenderData" title="Transgender"></app-simple-horizontal-bar-chart>
        </div>

        <div>
            <app-simple-table [dataArray]="ethnicityData" title="Ethnicity"></app-simple-table>
        </div>

        <div>
            <app-simple-table [dataArray]="schoolTypeData" title="School type from 11 to 16"></app-simple-table>
            <app-simple-table [dataArray]="religionData" title="Religion or belief"></app-simple-table>
            <app-simple-table [dataArray]="sexualOrientationData" title="Sexual Orientation"></app-simple-table>
        </div>

        <div>
            <app-simple-table [dataArray]="unpaidCareData" title="Time spent providing unpaid care"></app-simple-table>
            <app-simple-table [dataArray]="primaryCarerData" title="Primary carer for a child under 18"></app-simple-table>
            <app-simple-table [dataArray]="disabilityData" title="Disability"></app-simple-table>
            <app-simple-table [dataArray]="limitedActivities" title="Activities limited by health"></app-simple-table>
        </div>

        <div>
            <app-simple-table [dataArray]="highestIncomeEarnerData" title="Work of the main/ highest income earner in your household when you were aged about 14"></app-simple-table>
            <app-simple-table [dataArray]="highestQualificationData" title="Highest level of qualifications
achieved"></app-simple-table>
        </div>

    </div>

</app-layout>
