import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-survey-textarea',
  templateUrl: './survey-textarea.component.html',
  styleUrls: ['./survey-textarea.component.scss']
})
export class SurveyTextareaComponent implements OnInit {
  @Input() question;
  @Input() number;
  
  constructor() { }

  ngOnInit(): void {
  }

}
