import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {api_routes} from '@app/consts';
import {Observable, of} from 'rxjs';
import {DirectReporteeSchedule, EmployeeWorkingHours, GenerateShiftsSubmit, ScheduleItem} from '../models/generate-schedules.model';
import {EnvironmentService} from "@app/core/services/environment.service";


const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root'
})
export class GenerateScheduleService {

  constructor(private http: HttpClient, private envService: EnvironmentService) { }

  generateShifts(generateShiftsSubmit: GenerateShiftsSubmit): Observable<any> {
    return this.http.post<any>(`${this.envService.env.apiUrl}${api_routes.SCHEDULES}/${api_routes.GENERATE_SHIFTS}`, generateShiftsSubmit, {
      headers: headers,
    });
  }

  getEmployeeSchedule(employeeId: string, from: string, to: string): Observable<ScheduleItem[]> {
    let params = new HttpParams();
    employeeId ? params = params.append('employeeId', employeeId) : null;
    from ? params = params.append('from', from) : null;
    to ? params = params.append('to', to) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SCHEDULES}/${employeeId}`, {
      params: params,
      headers: headers,
    });
  }

  getCurrentUserSchedule(from: string, to: string): Observable<ScheduleItem[]> {
    let params = new HttpParams();
    from ? params = params.append('from', from) : null;
    to ? params = params.append('to', to) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SCHEDULES}/${api_routes.MY_WORK_SCHEDULE}`, {
      params: params,
      headers: headers,
    });
  }

  getEmployeeWorkingHours(employeeId: string, from: string, to: string): Observable<EmployeeWorkingHours> {
    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SCHEDULES}/${api_routes.EMPLOYEE_WORKING_HOURS}/${employeeId}/${from}/${to}`, {
      headers: headers,
    });
  }

  getDirectReporteeSchedules(from: string, to: string): Observable<DirectReporteeSchedule[]> {
    let params = new HttpParams();
    from ? params = params.append('from', from) : null;
    to ? params = params.append('to', to) : null;

    return this.http.get<any>(`${this.envService.env.apiUrl}${api_routes.SCHEDULES}/${api_routes.DIRECT_REPORTEES}`, {
      params: params,
      headers: headers,
    });

    return of(
      [
        {
           "employee":{
              "id":"usr_JdaZBwb1r2HrCg",
              "firstName":"Oliver",
              "lastName":"Alford"
           },
           "workSchedule":[
              {
                 "id":"sce_dJ70tfV6rJ3JSD",
                 "start":"2023-02-01T08:00:00-08:00",
                 "end":"2023-02-01T17:00:00-08:00"
              },
              {
                 "id":"sce_oHCXSRpmJGVhCw",
                 "start":"2023-02-02T08:00:00-08:00",
                 "end":"2023-02-02T17:00:00-08:00"
              },
              {
                 "id":"sce_DtMjtyI5HnO8O7",
                 "start":"2023-02-03T08:00:00-08:00",
                 "end":"2023-02-03T17:00:00-08:00"
              },
              {
                 "id":"sce_2JNtBKatBTrN9u",
                 "start":"2023-02-06T08:00:00-08:00",
                 "end":"2023-02-06T17:00:00-08:00"
              },
              {
                 "id":"sce_SPPw1AGBtGcr2F",
                 "start":"2023-02-07T08:00:00-08:00",
                 "end":"2023-02-07T17:00:00-08:00"
              },
              {
                 "id":"sce_oBrzFyA4REBacT",
                 "start":"2023-02-08T08:00:00-08:00",
                 "end":"2023-02-08T17:00:00-08:00"
              },
              {
                 "id":"sce_rnT4l1EL4YVKle",
                 "start":"2023-02-09T08:00:00-08:00",
                 "end":"2023-02-09T17:00:00-08:00"
              },
              {
                 "id":"sce_XflVyidKGAm5pR",
                 "start":"2023-02-10T08:00:00-08:00",
                 "end":"2023-02-10T17:00:00-08:00"
              },
              {
                 "id":"sce_gumji7KCiFWRU0",
                 "start":"2023-02-13T08:00:00-08:00",
                 "end":"2023-02-13T17:00:00-08:00"
              },
              {
                 "id":"sce_SFX6BKsousdNl5",
                 "start":"2023-02-14T08:00:00-08:00",
                 "end":"2023-02-14T17:00:00-08:00"
              },
              {
                 "id":"sce_9lHivSn91tj4Cf",
                 "start":"2023-02-15T08:00:00-08:00",
                 "end":"2023-02-15T17:00:00-08:00"
              },
              {
                 "id":"sce_YwzTgdy5Sf9Ii2",
                 "start":"2023-02-16T08:00:00-08:00",
                 "end":"2023-02-16T17:00:00-08:00"
              },
              {
                 "id":"sce_vkuedXDlrOqXqx",
                 "start":"2023-02-17T08:00:00-08:00",
                 "end":"2023-02-17T17:00:00-08:00"
              },
              {
                 "id":"sce_qcVTT8hZFAwWIJ",
                 "start":"2023-02-20T08:00:00-08:00",
                 "end":"2023-02-20T17:00:00-08:00"
              },
              {
                 "id":"sce_yWhnWE3Sol8hQM",
                 "start":"2023-02-21T08:00:00-08:00",
                 "end":"2023-02-21T17:00:00-08:00"
              },
              {
                 "id":"sce_xHP0Z1AYLCFhTn",
                 "start":"2023-02-22T08:00:00-08:00",
                 "end":"2023-02-22T17:00:00-08:00"
              },
              {
                 "id":"sce_WFzdbRDXk0rClf",
                 "start":"2023-02-23T08:00:00-08:00",
                 "end":"2023-02-23T17:00:00-08:00"
              },
              {
                 "id":"sce_oE2ITxuF7Qe9Tw",
                 "start":"2023-02-24T08:00:00-08:00",
                 "end":"2023-02-24T17:00:00-08:00"
              },
              {
                 "id":"sce_qc9OVIG2tKv55w",
                 "start":"2023-02-27T08:00:00-08:00",
                 "end":"2023-02-27T17:00:00-08:00"
              },
              {
                 "id":"sce_y5Hhk48LPDVgF5",
                 "start":"2023-02-28T08:00:00-08:00",
                 "end":"2023-02-28T17:00:00-08:00"
              },
              {
                 "id":"sce_fPSrPZBmlQHNEv",
                 "start":"2023-03-01T08:00:00-08:00",
                 "end":"2023-03-01T17:00:00-08:00"
              },
              {
                 "id":"sce_fyImWwDDkdipKD",
                 "start":"2023-03-02T08:00:00-08:00",
                 "end":"2023-03-02T17:00:00-08:00"
              },
              {
                 "id":"sce_bhbQTBzJizaFLP",
                 "start":"2023-03-03T08:00:00-08:00",
                 "end":"2023-03-03T17:00:00-08:00"
              },
              {
                 "id":"sce_Jb5DWMToqsnt0S",
                 "start":"2023-03-06T08:00:00-08:00",
                 "end":"2023-03-06T17:00:00-08:00"
              },
              {
                 "id":"sce_lf7C41hPFM28Ov",
                 "start":"2023-03-07T08:00:00-08:00",
                 "end":"2023-03-07T17:00:00-08:00"
              },
              {
                 "id":"sce_VfXm5EWBRjcAFK",
                 "start":"2023-03-08T08:00:00-08:00",
                 "end":"2023-03-08T17:00:00-08:00"
              },
              {
                 "id":"sce_orJQCJ4f157cK1",
                 "start":"2023-03-09T08:00:00-08:00",
                 "end":"2023-03-09T17:00:00-08:00"
              },
              {
                 "id":"sce_QJ3Rt8P6IfL9be",
                 "start":"2023-03-10T08:00:00-08:00",
                 "end":"2023-03-10T17:00:00-08:00"
              },
              {
                 "id":"sce_AgkufmLQ1LK06v",
                 "start":"2023-03-13T08:00:00-07:00",
                 "end":"2023-03-13T17:00:00-07:00"
              },
              {
                 "id":"sce_7XH8WZ46mz1Ni7",
                 "start":"2023-03-14T08:00:00-07:00",
                 "end":"2023-03-14T17:00:00-07:00"
              },
              {
                 "id":"sce_DxoMpf1pFQMS4M",
                 "start":"2023-03-15T08:00:00-07:00",
                 "end":"2023-03-15T17:00:00-07:00"
              },
              {
                 "id":"sce_HXk3IWnrFtTuAU",
                 "start":"2023-03-16T08:00:00-07:00",
                 "end":"2023-03-16T17:00:00-07:00"
              },
              {
                 "id":"sce_9dbx1zeLKwAcNm",
                 "start":"2023-03-17T08:00:00-07:00",
                 "end":"2023-03-17T17:00:00-07:00"
              },
              {
                 "id":"sce_8uTF8iIAIkjEWU",
                 "start":"2023-03-20T08:00:00-07:00",
                 "end":"2023-03-20T17:00:00-07:00"
              },
              {
                 "id":"sce_3wgLxrUbmAa1uB",
                 "start":"2023-03-21T08:00:00-07:00",
                 "end":"2023-03-21T17:00:00-07:00"
              },
              {
                 "id":"sce_AD9hZMuydWAwra",
                 "start":"2023-03-22T08:00:00-07:00",
                 "end":"2023-03-22T17:00:00-07:00"
              },
              {
                 "id":"sce_e5GZ9FFa2GxGU4",
                 "start":"2023-03-23T08:00:00-07:00",
                 "end":"2023-03-23T17:00:00-07:00"
              },
              {
                 "id":"sce_PrKmmyqyCZTWCf",
                 "start":"2023-03-24T08:00:00-07:00",
                 "end":"2023-03-24T17:00:00-07:00"
              },
              {
                 "id":"sce_1htEZKAQKq9V4s",
                 "start":"2023-03-27T08:00:00-07:00",
                 "end":"2023-03-27T17:00:00-07:00"
              },
              {
                 "id":"sce_9iJrMEJGM0rKGZ",
                 "start":"2023-03-28T08:00:00-07:00",
                 "end":"2023-03-28T17:00:00-07:00"
              },
              {
                 "id":"sce_Dn6ipIdFGWHLsQ",
                 "start":"2023-03-29T08:00:00-07:00",
                 "end":"2023-03-29T17:00:00-07:00"
              },
              {
                 "id":"sce_tfdIzdT1Qtc8v4",
                 "start":"2023-03-30T08:00:00-07:00",
                 "end":"2023-03-30T17:00:00-07:00"
              },
              {
                 "id":"sce_JbkBl5pyCmpaoy",
                 "start":"2023-03-31T08:00:00-07:00",
                 "end":"2023-03-31T17:00:00-07:00"
              },
              {
                 "id":"sce_8UYkriyKrkQ6O0",
                 "start":"2023-04-03T08:00:00-07:00",
                 "end":"2023-04-03T17:00:00-07:00"
              },
              {
                 "id":"sce_arApcs552JRCyS",
                 "start":"2023-04-04T08:00:00-07:00",
                 "end":"2023-04-04T17:00:00-07:00"
              },
              {
                 "id":"sce_hdB1dvFsg7C8KS",
                 "start":"2023-04-05T08:00:00-07:00",
                 "end":"2023-04-05T17:00:00-07:00"
              },
              {
                 "id":"sce_HmabvIZsVMAl2z",
                 "start":"2023-04-06T08:00:00-07:00",
                 "end":"2023-04-06T17:00:00-07:00"
              },
              {
                 "id":"sce_AQB7wh9aIjbA1q",
                 "start":"2023-04-07T08:00:00-07:00",
                 "end":"2023-04-07T17:00:00-07:00"
              },
              {
                 "id":"sce_j9YplFNrAsQPmJ",
                 "start":"2023-04-10T08:00:00-07:00",
                 "end":"2023-04-10T17:00:00-07:00"
              },
              {
                 "id":"sce_D7HKfyaKhSBUjx",
                 "start":"2023-04-11T08:00:00-07:00",
                 "end":"2023-04-11T17:00:00-07:00"
              },
              {
                 "id":"sce_etAVsZJgHE7N2F",
                 "start":"2023-04-12T08:00:00-07:00",
                 "end":"2023-04-12T17:00:00-07:00"
              },
              {
                 "id":"sce_DwmKF6hHdtiyTo",
                 "start":"2023-04-13T08:00:00-07:00",
                 "end":"2023-04-13T17:00:00-07:00"
              },
              {
                 "id":"sce_I5Xbg4gKdK6oJC",
                 "start":"2023-04-14T08:00:00-07:00",
                 "end":"2023-04-14T17:00:00-07:00"
              },
              {
                 "id":"sce_7Gc3FhRc5Ry2FA",
                 "start":"2023-04-17T08:00:00-07:00",
                 "end":"2023-04-17T17:00:00-07:00"
              },
              {
                 "id":"sce_Ww4ZRd3Nojv6wr",
                 "start":"2023-04-18T08:00:00-07:00",
                 "end":"2023-04-18T17:00:00-07:00"
              },
              {
                 "id":"sce_BCdVZTGX935PMA",
                 "start":"2023-04-19T08:00:00-07:00",
                 "end":"2023-04-19T17:00:00-07:00"
              },
              {
                 "id":"sce_uaAempUPFTDvWZ",
                 "start":"2023-04-20T08:00:00-07:00",
                 "end":"2023-04-20T17:00:00-07:00"
              },
              {
                 "id":"sce_ssOb1RUZDOECqs",
                 "start":"2023-04-21T08:00:00-07:00",
                 "end":"2023-04-21T17:00:00-07:00"
              },
              {
                 "id":"sce_riVXCMuplEGWCL",
                 "start":"2023-04-24T08:00:00-07:00",
                 "end":"2023-04-24T17:00:00-07:00"
              },
              {
                 "id":"sce_bpovRYJZ8RCZBH",
                 "start":"2023-04-25T08:00:00-07:00",
                 "end":"2023-04-25T17:00:00-07:00"
              },
              {
                 "id":"sce_hRjkJZ55SkOmeE",
                 "start":"2023-04-26T08:00:00-07:00",
                 "end":"2023-04-26T17:00:00-07:00"
              },
              {
                 "id":"sce_bVmADlEBTRJWtu",
                 "start":"2023-04-27T08:00:00-07:00",
                 "end":"2023-04-27T17:00:00-07:00"
              },
              {
                 "id":"sce_bZY3XzPZ9afDNN",
                 "start":"2023-04-28T08:00:00-07:00",
                 "end":"2023-04-28T17:00:00-07:00"
              }
           ],
           "timeoffSchedule":[
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"cas_D56MKJxFfH1RJC",
                 "start":"2023-02-26T00:00:00+00:00",
                 "end":"2023-03-03T00:00:00+00:00"
              }
           ],
           "pendingRequests":[
              
           ]
        },
        {
           "employee":{
              "id":"usr_UkW1ag8Ys0XLBs",
              "firstName":"Brian",
              "lastName":"Sangare"
           },
           "workSchedule":[
              {
                 "id":"sce_JmvT6HamvDE00k",
                 "start":"2023-03-01T08:00:00-08:00",
                 "end":"2023-03-01T16:00:00-08:00"
              },
              {
                 "id":"sce_Gr6DOQPB6gepqp",
                 "start":"2023-03-02T08:00:00-08:00",
                 "end":"2023-03-02T16:00:00-08:00"
              },
              {
                 "id":"sce_xELLNTLsbvtwIp",
                 "start":"2023-03-03T08:00:00-08:00",
                 "end":"2023-03-03T16:00:00-08:00"
              },
              {
                 "id":"sce_aCzbyHYJiBg7k5",
                 "start":"2023-03-05T12:00:00-08:00",
                 "end":"2023-03-05T14:00:00-08:00"
              },
              {
                 "id":"sce_J8u5KWe69pK6YO",
                 "start":"2023-03-06T12:00:00-08:00",
                 "end":"2023-03-06T20:00:00-08:00"
              },
              {
                 "id":"sce_Il91CdG1aMctvj",
                 "start":"2023-03-08T08:00:00-08:00",
                 "end":"2023-03-08T16:00:00-08:00"
              },
              {
                 "id":"sce_GD5SPd7IgjsbdG",
                 "start":"2023-03-09T08:00:00-08:00",
                 "end":"2023-03-09T16:00:00-08:00"
              },
              {
                 "id":"sce_XTQQTKnuRjV5I6",
                 "start":"2023-03-10T08:00:00-08:00",
                 "end":"2023-03-10T16:00:00-08:00"
              },
              {
                 "id":"sce_Yxoi7acOVVM8di",
                 "start":"2023-03-12T12:00:00-07:00",
                 "end":"2023-03-12T14:00:00-07:00"
              },
              {
                 "id":"sce_CO1lK6odxpQCpc",
                 "start":"2023-03-13T12:00:00-07:00",
                 "end":"2023-03-13T20:00:00-07:00"
              },
              {
                 "id":"sce_0Zh22BpaPM8RD4",
                 "start":"2023-03-15T08:00:00-07:00",
                 "end":"2023-03-15T16:00:00-07:00"
              },
              {
                 "id":"sce_3Lrz4qHpV28fpj",
                 "start":"2023-03-16T08:00:00-07:00",
                 "end":"2023-03-16T16:00:00-07:00"
              },
              {
                 "id":"sce_ACtzOkcCUn4nzE",
                 "start":"2023-03-17T08:00:00-07:00",
                 "end":"2023-03-17T16:00:00-07:00"
              }
           ],
           "timeoffSchedule":[
              
           ],
           "pendingRequests":[
              
           ]
        },
        {
           "employee":{
              "id":"usr_criKbtB7CFXjoq",
              "firstName":"EJ",
              "lastName":"Kim"
           },
           "workSchedule":[
              {
                 "id":"sce_dJ70tfV6rJ3JSD",
                 "start":"2023-02-01T08:00:00-08:00",
                 "end":"2023-02-01T17:00:00-08:00"
              },
              {
                 "id":"sce_oHCXSRpmJGVhCw",
                 "start":"2023-02-02T08:00:00-08:00",
                 "end":"2023-02-02T17:00:00-08:00"
              },
              {
                 "id":"sce_DtMjtyI5HnO8O7",
                 "start":"2023-02-03T08:00:00-08:00",
                 "end":"2023-02-03T17:00:00-08:00"
              },
              {
                 "id":"sce_2JNtBKatBTrN9u",
                 "start":"2023-02-06T08:00:00-08:00",
                 "end":"2023-02-06T17:00:00-08:00"
              },
              {
                 "id":"sce_SPPw1AGBtGcr2F",
                 "start":"2023-02-07T08:00:00-08:00",
                 "end":"2023-02-07T17:00:00-08:00"
              },
              {
                 "id":"sce_oBrzFyA4REBacT",
                 "start":"2023-02-08T08:00:00-08:00",
                 "end":"2023-02-08T17:00:00-08:00"
              },
              {
                 "id":"sce_rnT4l1EL4YVKle",
                 "start":"2023-02-09T08:00:00-08:00",
                 "end":"2023-02-09T17:00:00-08:00"
              },
              {
                 "id":"sce_XflVyidKGAm5pR",
                 "start":"2023-02-10T08:00:00-08:00",
                 "end":"2023-02-10T17:00:00-08:00"
              },
              {
                 "id":"sce_gumji7KCiFWRU0",
                 "start":"2023-02-13T08:00:00-08:00",
                 "end":"2023-02-13T17:00:00-08:00"
              },
              {
                 "id":"sce_SFX6BKsousdNl5",
                 "start":"2023-02-14T08:00:00-08:00",
                 "end":"2023-02-14T17:00:00-08:00"
              },
              {
                 "id":"sce_9lHivSn91tj4Cf",
                 "start":"2023-02-15T08:00:00-08:00",
                 "end":"2023-02-15T17:00:00-08:00"
              },
              {
                 "id":"sce_YwzTgdy5Sf9Ii2",
                 "start":"2023-02-16T08:00:00-08:00",
                 "end":"2023-02-16T17:00:00-08:00"
              },
              {
                 "id":"sce_vkuedXDlrOqXqx",
                 "start":"2023-02-17T08:00:00-08:00",
                 "end":"2023-02-17T17:00:00-08:00"
              },
              {
                 "id":"sce_qcVTT8hZFAwWIJ",
                 "start":"2023-02-20T08:00:00-08:00",
                 "end":"2023-02-20T17:00:00-08:00"
              },
              {
                 "id":"sce_yWhnWE3Sol8hQM",
                 "start":"2023-02-21T08:00:00-08:00",
                 "end":"2023-02-21T17:00:00-08:00"
              },
              {
                 "id":"sce_xHP0Z1AYLCFhTn",
                 "start":"2023-02-22T08:00:00-08:00",
                 "end":"2023-02-22T17:00:00-08:00"
              },
              {
                 "id":"sce_WFzdbRDXk0rClf",
                 "start":"2023-02-23T08:00:00-08:00",
                 "end":"2023-02-23T17:00:00-08:00"
              },
              {
                 "id":"sce_oE2ITxuF7Qe9Tw",
                 "start":"2023-02-24T08:00:00-08:00",
                 "end":"2023-02-24T17:00:00-08:00"
              },
              {
                 "id":"sce_qc9OVIG2tKv55w",
                 "start":"2023-02-27T08:00:00-08:00",
                 "end":"2023-02-27T17:00:00-08:00"
              },
              {
                 "id":"sce_y5Hhk48LPDVgF5",
                 "start":"2023-02-28T08:00:00-08:00",
                 "end":"2023-02-28T17:00:00-08:00"
              },
              {
                 "id":"sce_fPSrPZBmlQHNEv",
                 "start":"2023-03-01T08:00:00-08:00",
                 "end":"2023-03-01T17:00:00-08:00"
              },
              {
                 "id":"sce_fyImWwDDkdipKD",
                 "start":"2023-03-02T08:00:00-08:00",
                 "end":"2023-03-02T17:00:00-08:00"
              },
              {
                 "id":"sce_bhbQTBzJizaFLP",
                 "start":"2023-03-03T08:00:00-08:00",
                 "end":"2023-03-03T17:00:00-08:00"
              },
              {
                 "id":"sce_Jb5DWMToqsnt0S",
                 "start":"2023-03-06T08:00:00-08:00",
                 "end":"2023-03-06T17:00:00-08:00"
              },
              {
                 "id":"sce_lf7C41hPFM28Ov",
                 "start":"2023-03-07T08:00:00-08:00",
                 "end":"2023-03-07T17:00:00-08:00"
              },
              {
                 "id":"sce_VfXm5EWBRjcAFK",
                 "start":"2023-03-08T08:00:00-08:00",
                 "end":"2023-03-08T17:00:00-08:00"
              },
              {
                 "id":"sce_orJQCJ4f157cK1",
                 "start":"2023-03-09T08:00:00-08:00",
                 "end":"2023-03-09T17:00:00-08:00"
              },
              {
                 "id":"sce_QJ3Rt8P6IfL9be",
                 "start":"2023-03-10T08:00:00-08:00",
                 "end":"2023-03-10T17:00:00-08:00"
              },
              {
                 "id":"sce_AgkufmLQ1LK06v",
                 "start":"2023-03-13T08:00:00-07:00",
                 "end":"2023-03-13T17:00:00-07:00"
              },
              {
                 "id":"sce_7XH8WZ46mz1Ni7",
                 "start":"2023-03-14T08:00:00-07:00",
                 "end":"2023-03-14T17:00:00-07:00"
              },
              {
                 "id":"sce_DxoMpf1pFQMS4M",
                 "start":"2023-03-15T08:00:00-07:00",
                 "end":"2023-03-15T17:00:00-07:00"
              },
              {
                 "id":"sce_HXk3IWnrFtTuAU",
                 "start":"2023-03-16T08:00:00-07:00",
                 "end":"2023-03-16T17:00:00-07:00"
              },
              {
                 "id":"sce_9dbx1zeLKwAcNm",
                 "start":"2023-03-17T08:00:00-07:00",
                 "end":"2023-03-17T17:00:00-07:00"
              },
              {
                 "id":"sce_8uTF8iIAIkjEWU",
                 "start":"2023-03-20T08:00:00-07:00",
                 "end":"2023-03-20T17:00:00-07:00"
              },
              {
                 "id":"sce_3wgLxrUbmAa1uB",
                 "start":"2023-03-21T08:00:00-07:00",
                 "end":"2023-03-21T17:00:00-07:00"
              },
              {
                 "id":"sce_AD9hZMuydWAwra",
                 "start":"2023-03-22T08:00:00-07:00",
                 "end":"2023-03-22T17:00:00-07:00"
              },
              {
                 "id":"sce_e5GZ9FFa2GxGU4",
                 "start":"2023-03-23T08:00:00-07:00",
                 "end":"2023-03-23T17:00:00-07:00"
              },
              {
                 "id":"sce_PrKmmyqyCZTWCf",
                 "start":"2023-03-24T08:00:00-07:00",
                 "end":"2023-03-24T17:00:00-07:00"
              },
              {
                 "id":"sce_1htEZKAQKq9V4s",
                 "start":"2023-03-27T08:00:00-07:00",
                 "end":"2023-03-27T17:00:00-07:00"
              },
              {
                 "id":"sce_9iJrMEJGM0rKGZ",
                 "start":"2023-03-28T08:00:00-07:00",
                 "end":"2023-03-28T17:00:00-07:00"
              },
              {
                 "id":"sce_Dn6ipIdFGWHLsQ",
                 "start":"2023-03-29T08:00:00-07:00",
                 "end":"2023-03-29T17:00:00-07:00"
              },
              {
                 "id":"sce_tfdIzdT1Qtc8v4",
                 "start":"2023-03-30T08:00:00-07:00",
                 "end":"2023-03-30T17:00:00-07:00"
              },
              {
                 "id":"sce_JbkBl5pyCmpaoy",
                 "start":"2023-03-31T08:00:00-07:00",
                 "end":"2023-03-31T17:00:00-07:00"
              },
              {
                 "id":"sce_8UYkriyKrkQ6O0",
                 "start":"2023-04-03T08:00:00-07:00",
                 "end":"2023-04-03T17:00:00-07:00"
              },
              {
                 "id":"sce_arApcs552JRCyS",
                 "start":"2023-04-04T08:00:00-07:00",
                 "end":"2023-04-04T17:00:00-07:00"
              },
              {
                 "id":"sce_hdB1dvFsg7C8KS",
                 "start":"2023-04-05T08:00:00-07:00",
                 "end":"2023-04-05T17:00:00-07:00"
              },
              {
                 "id":"sce_HmabvIZsVMAl2z",
                 "start":"2023-04-06T08:00:00-07:00",
                 "end":"2023-04-06T17:00:00-07:00"
              },
              {
                 "id":"sce_AQB7wh9aIjbA1q",
                 "start":"2023-04-07T08:00:00-07:00",
                 "end":"2023-04-07T17:00:00-07:00"
              },
              {
                 "id":"sce_j9YplFNrAsQPmJ",
                 "start":"2023-04-10T08:00:00-07:00",
                 "end":"2023-04-10T17:00:00-07:00"
              },
              {
                 "id":"sce_D7HKfyaKhSBUjx",
                 "start":"2023-04-11T08:00:00-07:00",
                 "end":"2023-04-11T17:00:00-07:00"
              },
              {
                 "id":"sce_etAVsZJgHE7N2F",
                 "start":"2023-04-12T08:00:00-07:00",
                 "end":"2023-04-12T17:00:00-07:00"
              },
              {
                 "id":"sce_DwmKF6hHdtiyTo",
                 "start":"2023-04-13T08:00:00-07:00",
                 "end":"2023-04-13T17:00:00-07:00"
              },
              {
                 "id":"sce_I5Xbg4gKdK6oJC",
                 "start":"2023-04-14T08:00:00-07:00",
                 "end":"2023-04-14T17:00:00-07:00"
              },
              {
                 "id":"sce_7Gc3FhRc5Ry2FA",
                 "start":"2023-04-17T08:00:00-07:00",
                 "end":"2023-04-17T17:00:00-07:00"
              },
              {
                 "id":"sce_Ww4ZRd3Nojv6wr",
                 "start":"2023-04-18T08:00:00-07:00",
                 "end":"2023-04-18T17:00:00-07:00"
              },
              {
                 "id":"sce_BCdVZTGX935PMA",
                 "start":"2023-04-19T08:00:00-07:00",
                 "end":"2023-04-19T17:00:00-07:00"
              },
              {
                 "id":"sce_uaAempUPFTDvWZ",
                 "start":"2023-04-20T08:00:00-07:00",
                 "end":"2023-04-20T17:00:00-07:00"
              },
              {
                 "id":"sce_ssOb1RUZDOECqs",
                 "start":"2023-04-21T08:00:00-07:00",
                 "end":"2023-04-21T17:00:00-07:00"
              },
              {
                 "id":"sce_riVXCMuplEGWCL",
                 "start":"2023-04-24T08:00:00-07:00",
                 "end":"2023-04-24T17:00:00-07:00"
              },
              {
                 "id":"sce_bpovRYJZ8RCZBH",
                 "start":"2023-04-25T08:00:00-07:00",
                 "end":"2023-04-25T17:00:00-07:00"
              },
              {
                 "id":"sce_hRjkJZ55SkOmeE",
                 "start":"2023-04-26T08:00:00-07:00",
                 "end":"2023-04-26T17:00:00-07:00"
              },
              {
                 "id":"sce_bVmADlEBTRJWtu",
                 "start":"2023-04-27T08:00:00-07:00",
                 "end":"2023-04-27T17:00:00-07:00"
              },
              {
                 "id":"sce_bZY3XzPZ9afDNN",
                 "start":"2023-04-28T08:00:00-07:00",
                 "end":"2023-04-28T17:00:00-07:00"
              }
           ],
           "timeoffSchedule":[
              
           ],
           "pendingRequests":[
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_BxIFZG6bgRnmBb",
                 "start":"2022-10-02T00:00:00+00:00",
                 "end":"2022-10-31T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_vMXq5K2yz14nhO",
                 "start":"2022-10-23T00:00:00+00:00",
                 "end":"2022-10-28T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_oYNT90xHSEZH9k",
                 "start":"2022-10-23T00:00:00+00:00",
                 "end":"2022-10-28T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_FO25CE9yHfRXhT",
                 "start":"2022-10-01T00:00:00+00:00",
                 "end":"2022-10-01T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_2ObygDRmitH80y",
                    "name":"Parental Leave"
                 },
                 "id":"wfl_brEjmOMhVHlYCD",
                 "start":"2022-09-30T00:00:00+00:00",
                 "end":"2022-10-13T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_2ObygDRmitH80y",
                    "name":"Parental Leave"
                 },
                 "id":"wfl_0B5O1iG9Bd9IzF",
                 "start":"2022-09-30T00:00:00+00:00",
                 "end":"2022-10-13T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_2ObygDRmitH80y",
                    "name":"Parental Leave"
                 },
                 "id":"wfl_aAqbhqbXHH5znr",
                 "start":"2022-09-30T00:00:00+00:00",
                 "end":"2022-10-13T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_2ObygDRmitH80y",
                    "name":"Parental Leave"
                 },
                 "id":"wfl_nXgDkIqsMdi14Z",
                 "start":"2022-09-28T00:00:00+00:00",
                 "end":"2022-10-10T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_kCzFgsNYgAkjkA",
                    "name":""
                 },
                 "id":"wfl_EMD2djqC6e1q05",
                 "start":"2022-09-28T00:00:00+00:00",
                 "end":"2022-10-10T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_kCzFgsNYgAkjkA",
                    "name":""
                 },
                 "id":"wfl_OetI9TJgHwZj68",
                 "start":"2022-09-28T00:00:00+00:00",
                 "end":"2022-10-10T00:00:00+00:00"
              }
           ]
        },
        {
           "employee":{
              "id":"usr_2rJSfjqfl5L344",
              "firstName":"Alison",
              "lastName":"Keita"
           },
           "workSchedule":[
              
           ],
           "timeoffSchedule":[
              
           ],
           "pendingRequests":[
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_WPxYDd5oNQyHix",
                 "start":"2022-09-20T00:00:00+00:00",
                 "end":"2022-09-21T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_JMu78rPb4cJOx8",
                 "start":"2022-09-20T00:00:00+00:00",
                 "end":"2022-09-21T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_v9f8Tbl71XOLVi",
                 "start":"2022-09-20T00:00:00+00:00",
                 "end":"2022-09-21T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_ghiMC211G12TzW",
                 "start":"2022-09-18T00:00:00+00:00",
                 "end":"2022-09-19T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_FfXyaiDY6nav9D",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-15T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_3XhXg0oeS1Gojz",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-15T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_niVpApxwsxalfn",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-14T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_A4CWbYVJhiUr9W",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-14T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_Sa1viA9WbYXqog",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-14T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_FAnIkbi4EwVbMS",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-14T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_L4r5Q1NxIWxybT",
                 "start":"2022-09-13T00:00:00+00:00",
                 "end":"2022-09-14T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_nQ02uLNowSrTIM",
                 "start":"2022-09-10T00:00:00+00:00",
                 "end":"2022-09-20T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_rOwOlVpQhP9uVR",
                 "start":"2022-09-10T00:00:00+00:00",
                 "end":"2022-09-20T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_rYbVCpxSH8GOpJ",
                    "name":"Vacation"
                 },
                 "id":"wfl_6xIr88stOPUIQV",
                 "start":"2022-09-10T00:00:00+00:00",
                 "end":"2022-09-12T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_BRqCEwqUkzs57f",
                 "start":"2022-09-10T00:00:00+00:00",
                 "end":"2022-09-11T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_r1uXXqPoo9sFBO",
                 "start":"2022-09-09T00:00:00+00:00",
                 "end":"2022-09-10T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_gwIae7Xl4SDZAG",
                 "start":"2022-09-09T00:00:00+00:00",
                 "end":"2022-09-10T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_bOJrj3pFyuafgT",
                 "start":"2022-09-09T00:00:00+00:00",
                 "end":"2022-09-10T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_kkjFNfg7KJBeAz",
                 "start":"2022-09-09T00:00:00+00:00",
                 "end":"2022-09-10T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_DqhudtFavnmlN6",
                 "start":"2022-09-09T00:00:00+00:00",
                 "end":"2022-09-10T00:00:00+00:00"
              }
           ]
        },
        {
           "employee":{
              "id":"usr_C5g9jHCrNcCitv",
              "firstName":"Dahlia",
              "lastName":"Powell"
           },
           "workSchedule":[
              {
                 "id":"sce_JmvT6HamvDE00k",
                 "start":"2023-03-01T08:00:00-08:00",
                 "end":"2023-03-01T16:00:00-08:00"
              },
              {
                 "id":"sce_Gr6DOQPB6gepqp",
                 "start":"2023-03-02T08:00:00-08:00",
                 "end":"2023-03-02T16:00:00-08:00"
              },
              {
                 "id":"sce_xELLNTLsbvtwIp",
                 "start":"2023-03-03T08:00:00-08:00",
                 "end":"2023-03-03T16:00:00-08:00"
              },
              {
                 "id":"sce_aCzbyHYJiBg7k5",
                 "start":"2023-03-05T12:00:00-08:00",
                 "end":"2023-03-05T14:00:00-08:00"
              },
              {
                 "id":"sce_J8u5KWe69pK6YO",
                 "start":"2023-03-06T12:00:00-08:00",
                 "end":"2023-03-06T20:00:00-08:00"
              },
              {
                 "id":"sce_Il91CdG1aMctvj",
                 "start":"2023-03-08T08:00:00-08:00",
                 "end":"2023-03-08T16:00:00-08:00"
              },
              {
                 "id":"sce_GD5SPd7IgjsbdG",
                 "start":"2023-03-09T08:00:00-08:00",
                 "end":"2023-03-09T16:00:00-08:00"
              },
              {
                 "id":"sce_XTQQTKnuRjV5I6",
                 "start":"2023-03-10T08:00:00-08:00",
                 "end":"2023-03-10T16:00:00-08:00"
              },
              {
                 "id":"sce_Yxoi7acOVVM8di",
                 "start":"2023-03-12T12:00:00-07:00",
                 "end":"2023-03-12T14:00:00-07:00"
              },
              {
                 "id":"sce_CO1lK6odxpQCpc",
                 "start":"2023-03-13T12:00:00-07:00",
                 "end":"2023-03-13T20:00:00-07:00"
              },
              {
                 "id":"sce_0Zh22BpaPM8RD4",
                 "start":"2023-03-15T08:00:00-07:00",
                 "end":"2023-03-15T16:00:00-07:00"
              },
              {
                 "id":"sce_3Lrz4qHpV28fpj",
                 "start":"2023-03-16T08:00:00-07:00",
                 "end":"2023-03-16T16:00:00-07:00"
              },
              {
                 "id":"sce_ACtzOkcCUn4nzE",
                 "start":"2023-03-17T08:00:00-07:00",
                 "end":"2023-03-17T16:00:00-07:00"
              }
           ],
           "timeoffSchedule":[
              
           ],
           "pendingRequests":[
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_WiYRVINKMtxeFF",
                 "start":"2022-11-22T00:00:00+00:00",
                 "end":"2022-11-25T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_qlXhF2ITxNEbPt",
                 "start":"2022-11-10T00:00:00+00:00",
                 "end":"2022-11-11T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_lD2Zk8spCAm6K5",
                 "start":"2022-11-10T00:00:00+00:00",
                 "end":"2022-11-11T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_eDMyhP5dKQs0FY",
                    "name":"Bereavement"
                 },
                 "id":"wfl_45kPjntlaho9Ly",
                 "start":"2022-11-10T00:00:00+00:00",
                 "end":"2022-11-11T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_PCU2n8biz3PXqM",
                 "start":"2022-11-06T00:00:00+00:00",
                 "end":"2022-11-25T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_U7oLeKIeIP8Jpr",
                 "start":"2022-11-06T00:00:00+00:00",
                 "end":"2022-11-19T00:00:00+00:00"
              },
              {
                 "type":{
                    "id":"tot_96JEe6OzLDIlSa",
                    "name":"Adverse Weather"
                 },
                 "id":"wfl_fHsHYK097Wnecg",
                 "start":"2022-10-23T00:00:00+00:00",
                 "end":"2022-11-19T00:00:00+00:00"
              }
           ]
        }
     ]
    )
  }
}
