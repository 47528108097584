import {Component, OnInit} from '@angular/core';
import {routes} from '@app/consts';
import {finalize} from 'rxjs/operators';
import {
    EmploymentRecordsService
} from '@app/modules/talent-track/talent-track-edit-employee/edit-employee/components/employment-records/services/employment-records.service';

@Component({
    selector: 'app-inbox-widget',
    templateUrl: './inbox-widget.component.html',
    styleUrls: ['./inbox-widget.component.scss']
})
export class InboxWidgetComponent implements OnInit {
    public routes: typeof routes = routes;
    public emails: {
        name: string;
        time: string;
        message: string
    }[] = [
        {name: 'Jane Hew', time: '9:32', message: 'Hey! How is it going?'},
        {name: 'Lloyd Brown', time: '9:18', message: 'Check out my new Dashboard'},
        {name: 'Mark Winstein', time: '9:15', message: 'I want rearrange the appointment'},
        {name: 'Liana Dutti', time: '9:09', message: 'Good news from sale department'}
    ];

    public colors: string[] = [
        'yellow',
        'green',
        'blue',
        'ping'
    ];

    constructor(
        private employmentRecordsService: EmploymentRecordsService,
    ) {
    }

    ngOnInit(): void {
        this.employmentRecordsService.getEmploymentRecords('usr_33xh1RLZykBym3', 0, '100')
            .pipe(
                finalize(() => {
                })
            )
            .subscribe(
                (res) => {
                    // this.employmentRecords = res.data

                    res.data.forEach(employmentRecord => {

                        if (employmentRecord.hireDate != null && employmentRecord.hireDate != '' ){
                            const hireDate = new Date(employmentRecord.hireDate);
                            const today = new Date();
                            const threeYearsBack = new Date(today.getFullYear() - 3, today.getMonth(), today.getDate());

                            if (hireDate.getTime() < threeYearsBack.getTime()) {
                                this.emails.unshift({
                                    name: 'Negotiator Promotion',
                                    time: '9:45',
                                    message: 'Employee is ready for promotion'
                                });
                            }
                        }
                    });
                }
            );
    }

}
