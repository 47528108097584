import {Component, Input, OnInit} from '@angular/core';
import {LegendLabelsContentArgs} from "@progress/kendo-angular-charts";
import {IntlService} from "@progress/kendo-angular-intl";

@Component({
  selector: 'app-simple-piechart',
  templateUrl: './simple-piechart.component.html',
  styleUrls: ['./simple-piechart.component.scss']
})
export class SimplePieChartComponent implements OnInit {

    @Input() dataArray: any[];
    @Input() title: string;
    @Input() description: string;
    @Input() seriesColors: string[] = [
      "#41ade1",
      "#f195bf",
    ];

    constructor(private intl: IntlService) {

  }

  ngOnInit(): void {
  }

    public labelContent(args: LegendLabelsContentArgs): string {
        return `${args.dataItem.category} : ${args.dataItem.value}%`;
    }

}
