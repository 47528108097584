<h5 mat-dialog-title appLocalizationPopupDirective [localizationCode]="'TimeOffRequest'">
    {{ 'TimeOffRequest' | translate: {Default: "Time Off Request"} }}
</h5>

<mat-dialog-content>
    <div class="top">
        <div class="requested-on">
            <span class="label" appLocalizationPopupDirective [localizationCode]="'RequestedOn'">{{ 'RequestedOn' | translate: {Default: "Requested On"} }}:</span> {{timeOffRequest.requestedOn | date:'medium'}}
        </div>
        <div class="approval">
            <div class="approved-by">
                <div class="label" appLocalizationPopupDirective [localizationCode]="'LeaveRequestRequestedBy'">{{ 'LeaveRequestRequestedBy' | translate: {Default: "Requested By"} }}: </div>
                <app-employee-image class="profile-pic" round="true" width="35" height="35" [employeeId]="timeOffRequest.requestedBy?.id"></app-employee-image>
                <div>{{timeOffRequest.requestedBy?.firstName}} {{timeOffRequest.requestedBy?.lastName}}</div>
            </div>

            <mat-chip-list>
                <mat-chip 
                    [ngClass]="{
                        'red-chip': timeOffRequest.requestStatus?.id === 'trs_Denied',
                        'green-chip': timeOffRequest.requestStatus?.id === 'trs_Approved',
                        'orange-chip': timeOffRequest.requestStatus?.id === 'trs_Pending'
                    }"
                >{{ timeOffRequest.requestStatus?.name }}</mat-chip>
            </mat-chip-list>
        </div>
    </div>
    <div class="bottom">
        <h6>{{timeOffRequest.timeOffType.name}}</h6>
        <h6 class="dates">
            {{timeOffRequest.from | date:'mediumDate'}} - {{timeOffRequest.to | date:'mediumDate'}}
            <mat-chip-list>
                <mat-chip class="green-chip" *ngIf="timeOffRequest.halfDay === true" appLocalizationPopupDirective [localizationCode]="'LeaveRequestHalfDay'">
                    {{ 'LeaveRequestHalfDay' | translate: {Default: "Half Day"} }}
                </mat-chip>
            </mat-chip-list>
        </h6>
        <h6 *ngIf="!loadingWorkingHours && workingHours" appLocalizationPopupDirective [localizationCode]="'WorkingHours'">
            {{workingHours}} {{ 'WorkingHours' | translate: {Default: "working hours"} }}
        </h6>

        <h6 *ngIf="timeOffRequest.hours" appLocalizationPopupDirective [localizationCode]="'Hours'">{{ timeOffRequest.hours }} {{ 'Hours' | translate: {Default: "hours"} }}</h6>
        <h6 *ngIf="timeOffRequest.endTime && timeOffRequest.endTime">{{ formatTime(timeOffRequest.startTime) }} - {{ formatTime(timeOffRequest.endTime) }}</h6>

        <h6 class="comments">
            {{timeOffRequest.comments}}
        </h6>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button class="close" (click)="close()">{{ 'Close' | translate: {Default: "Close"} }}</button>
    <button mat-raised-button color="warn" (click)="openDenialCommentsDialog()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">cancel</mat-icon>
        <ng-container *ngIf="!isMobile">{{ 'Deny' | translate: {Default: "Deny"} }}</ng-container>
    </button> 
    <button mat-raised-button class="approve" (click)="approve()">
        <mat-icon aria-hidden="false" class="material-icons-outlined" aria-label="add icon">task_alt</mat-icon>
        <ng-container *ngIf="!isMobile">{{ 'Approve' | translate: {Default: "Approve"} }}</ng-container>
    </button> 
</mat-dialog-actions>
