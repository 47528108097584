import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-succession-emp-self-assessment',
  templateUrl: './succession-emp-self-assessment.component.html',
  styleUrls: ['./succession-emp-self-assessment.component.scss']
})
export class SuccessionEmpSelfAssessmentComponent implements OnInit {

  strengths = 'I enjoy leading teams of people and realizing team objectives and goals together.'
  development = 'I would like to learn more about managing teams and to continue to develop my leadership skills.'
  oneyear = 'To learn as much as possible in my current job.'
  twoyear = 'To be considered for a Director position.'
  fiveyear = 'To move into senior management and possibly a VP position.'
  enjoy = 'A position where I can grow, be mentored and challenged to stretch.'
  dislike = 'Rote positions that do not allow for creativity.'
  travel = 'Yes I love travelling for my job.'

  constructor() { }

  ngOnInit(): void {
  }

}
