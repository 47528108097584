<mat-card class="chart">
    <mat-card-title class="chart__header">
      <h5 class="chart__header-title">Promotions By Ethnicity</h5>

      <app-settings-menu></app-settings-menu>
    </mat-card-title>

    <mat-card-content class="chart__content">
        <div class="bottom">
            <div>
                <div class="percentage">36.4%</div>
                <div class="large">Internal promotions were by ethnic minority employees</div>
                <div class="small mt-2">(April 2021 - April 2022)</div>
                <div class="small mt-2">(20% of our employees are from ethnic minorities)</div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
