<mat-card>
    <mat-card-title>
        <h5>{{title}}</h5>
    </mat-card-title>

    <mat-card-content fxLayout="row" fxLayoutAlign="start">
        <div>
            <ng-container *ngFor="let item of dataArray">
                <div class="block">
                    <div class="label">{{item.label}}</div>
                    <div class="value">{{item.value}}</div>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
