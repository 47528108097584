import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-one-off-contributions',
  templateUrl: './one-off-contributions.component.html',
  styleUrls: ['./one-off-contributions.component.scss']
})
export class OneOffContributionsComponent implements OnInit {
  @Input() oneOffContributions: {
    description: string;
    data: {
      title: string;
      description: string;
      left: {
        label: string;
        value: string;
      },
      right: {
        label: string;
        value: string;
      }
    }[]
  }

  constructor() { }

  ngOnInit(): void {
  }

}
