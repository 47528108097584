<div class="guides">
    <div *ngFor="let guide of guides" (click)="openGuideDialog(guide)" class="guide">
        <img [src]="guide.image" alt="guide.image" />
        <div class="title">{{ guide.title }}</div>
    </div>
</div>

<div class="quick-start">
    <div>
        <div class="top">New to booking time off?</div>
        <div class="bottom">Check out our quick start guide to learn how to plan and book time off effectively</div>
    </div>

    <button mat-raised-button setColor="primaryColour" color="primary">View quick start guide</button> 
</div>