import { Component, OnInit } from '@angular/core';
import { OidcAuthService } from '@app/core/services/oidc-auth.service';
import { SnackbarService } from '@app/core/services/snackbar.service';
import { PacketsService } from '@app/modules/packets/services/packets.service';
import { OverlayService } from '@app/shared/components/overlay/overlay.service';

@Component({
  selector: 'app-about-me-section',
  templateUrl: './about-me-section.component.html',
  styleUrls: ['./about-me-section.component.scss']
})
export class AboutMeSectionComponent implements OnInit {
  user$: any;
  packet$: any;

  constructor(
    private oidcAuthService: OidcAuthService,
    private packetsService: PacketsService,
    private overlayService: OverlayService,
    private snackbarService: SnackbarService,
  ) { 
    this.user$ = this.oidcAuthService.userProfile;
    this.packet$ = this.packetsService.getPacket('1');
  }

  ngOnInit(): void {
    
  }

  save() {
    this.overlayService.show();

    setTimeout(() => {
      this.overlayService.hide();
      this.snackbarService.openSnackBar('Saved successfully', 'clear', 'success');
    }, 2000);
  }

}
