<mat-card>
    <mat-card-title >
        <h5>Employee Turnover Rate</h5>
    </mat-card-title>

    <mat-card-content>
        <p>As at December 31, 2022</p>
        <div id="chart">
            <apx-chart
              [series]="chartOptions.series"
              [chart]="chartOptions.chart"
              [labels]="chartOptions.labels"
              [responsive]="chartOptions.responsive"
            ></apx-chart>
        </div>
    </mat-card-content>
</mat-card>
