<!-- <app-header (isShowSidebar)="sidenav.toggle($event)" [isMenuOpened]="isShowSidebar"></app-header> -->
<div class="example-container">
  <!-- Chirstmas Lights -->
  <ul *ngIf="this.environmentService?.env?.customTheme === 'christmasTheme'" class="lightrope primaryColorBG">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  
  <app-navigation (isShowSidebar)="sidenav.toggle($event)" [isMenuOpened]="isShowSidebar"></app-navigation>

  <div class="layout-content">
    <ng-content></ng-content>
  </div>
  
  <!-- <mat-sidenav-container class="layout" autosize>
    <mat-sidenav 
    #sidenav 
    mode="rail"
    [(opened)]="isShowSidebar" 
    class="layout-sidebar"
    [fixedInViewport]="mobileQuery.matches"
    >
    <app-sidebar
    [showTooltip]="!isShowSidebar"
    ></app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="layout-content">
      <ng-content></ng-content>
      
      <ng-container *ngIf="this.environmentService?.env?.customTheme === 'christmasTheme'">
        <div class="gif-container" *ngIf="showChristmasGif">
          <img src="assets/santa-sleigh.gif" class="animated-gif" (animationend)="onAnimationEnd()">
        </div>
      </ng-container>
    </mat-sidenav-content>
  </mat-sidenav-container> -->
</div>

