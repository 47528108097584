import { Injectable } from '@angular/core';
import { EmployeeService } from '@app/core/services/employee.service';
import { CurrentUserDetails } from '@app/shared/models/employee.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PacketsService {
  currentEmployeeDetails: CurrentUserDetails;

  constructor(
    private employeeService: EmployeeService,
  ) { 
    this.currentEmployeeDetails = this.employeeService.getFetchedCurrentUserDetails()
  }

  getPackets(): Observable<any> {
    return of(
      
        [
          { 
            id: '1',
            name: 'Onboarding'
          },
          { 
            id: '2',
            name: 'Maternity Leave'
          },
          { 
            id: '3',
            name: 'Sick Leave'
          }
        ]
      
    );
  }

  getPacket(id: string): Observable<any> {
    return of(
      [
        {
          id: '1234',
          type: 'info',
          title: 'When & Where',
          data: {
            title: `Welcome ${this.currentEmployeeDetails.firstname}`,
            text: `<p>On behalf of Foxtons, I would like to extend a warm welcome to you! We are thrilled that you have decided to join our team and we look forward to the many contributions we know you will make.</p><p>As a new member of our team, you will be part of a dynamic and innovative group of professionals who are dedicated to providing exceptional service to our clients. We believe that our employees are our most valuable asset and we are committed to supporting your success and growth within our organization.</p><p>You have been selected for this position because of your exceptional skills and experience. We are confident that you will excel in your new role and make valuable contributions to our team.</p><p>In the coming weeks, you will receive information about our company, policies, and procedures, as well as your specific role and responsibilities. We encourage you to ask questions and seek clarification as needed to ensure that you are comfortable and confident in your new position.</p><p>Once again, welcome to the Foxtons team! We are excited to have you on board and look forward to working with you.</p>`
          }
        },
        {
          id: '4321',
          type: 'questionnaire',
          title: 'Get Introduced',
          data: {
            id: '12341234312',
            title: 'Get Introduced to The Team',
            text: "You're almost done, but first we'd like to get to know a bit more about you so we can introduce you to the team. Please answer the following questions and we'll send out a quick information email to the people you'll be working with.",
            formTitle: "Tell us a little about yourself:",
            formId: 'frm_ostZzTtkUGNYsz'
          }
        },
        {
          id: '4567',
          type: 'checklist',
          title: 'Tasks to Complete',
          data: {
            id: '214234',
            label: '',
            description: {
              title: 'Tasks to Complete',
              description: "We want to make sure that you are ready to go on your first day. Please complete the tasks below and we'll be able to get you up and running quickly. Again, if you have any questions please email or call Charlotte at 415-555-1237 ext. 1273.",
            },
            list: [
              {
                id: '234235',
                checked: true,
                task: 'Watch Company Overview Video'
              },
              {
                id: 'fe34rfe',
                checked: false,
                task: 'Review and Sign Job Offer',
                document: {
                  title: 'Job Offer',
                  url: '/assets/foxtons-job-offer.pdf',
                  signatureRequired: true,
                  signed: false,
                  icon: 'description'
                }
              },
              {
                id: '234235',
                checked: true,
                task: 'Review and Sign W4',
                document: {
                  title: 'W4(2017)',
                  url: '/assets/fw4.pdf',
                  signatureRequired: true,
                  signed: true,
                  icon: 'description'
                }
              },
              {
                id: '234235',
                checked: false,
                task: 'Complete Direct Deposit Form',
                document: {
                  title: 'Direct Deposit Form',
                  url: '/assets/direct-deposit.pdf',
                  signatureRequired: false,
                  signed: false,
                  icon: 'description'
                }
              },
              {
                id: '234235',
                checked: false,
                task: 'Read Employee Handbook'
              },
              {
                id: '234235',
                checked: false,
                task: 'Review and Sign I9',
                document: {
                  title: 'I9',
                  url: '/assets/i-9.pdf',
                  signatureRequired: true,
                  signed: false,
                  icon: 'description'
                }
              }
            ]
          }
        }
      ]
    );
  }
}
