<mat-card>
    <mat-card-title>
        <h5>{{hourlyRateComparisonData?.title}}</h5>
    </mat-card-title>

    <mat-card-content>
        <p>{{hourlyRateComparisonData?.description}}</p>

        <div class="parent">
            <div class="div1 border-right"> </div>
            <div class="div2 border-right">
                Hourly Rate Of {{ hourlyRateComparisonData.categories[0] }}
            </div>
            <div class="div3 border-right">
                Hourly Rate Or {{ hourlyRateComparisonData.categories[1] }}
            </div>
            <div class="div4">
                Difference
            </div>
            <div class="div5 border-right">
                Mean Hourly Rate
            </div>
            <div class="div6 currency border-right">
                {{hourlyRateComparisonData.currency | currencyDisplay: hourlyRateComparisonData.meanHourlyRate[0]}}
            </div>
            <div class="div7 currency border-right">
                {{hourlyRateComparisonData.currency | currencyDisplay: hourlyRateComparisonData.meanHourlyRate[1]}}
            </div>
            <div class="div8 currency">
                {{hourlyRateComparisonData.currency | currencyDisplay: hourlyRateComparisonData.meanHourlyRate[1] - hourlyRateComparisonData.meanHourlyRate[0]}}
            </div>
            <div class="div9 border-right">
                Median Hourly Rate
            </div>
            <div class="div10 currency border-right">
                {{hourlyRateComparisonData.currency | currencyDisplay: hourlyRateComparisonData.medianHourlyRate[0]}}
            </div>
            <div class="div11 currency border-right">
                {{hourlyRateComparisonData.currency | currencyDisplay: hourlyRateComparisonData.medianHourlyRate[1]}}
            </div>
            <div class="div12 currency">
                {{hourlyRateComparisonData.currency | currencyDisplay: hourlyRateComparisonData.medianHourlyRate[1] - hourlyRateComparisonData.medianHourlyRate[0]}}
            </div>
        </div>
    </mat-card-content>
</mat-card>
