import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeOffGuidesService {

  constructor() { }

  getGuides(): any {
    return of(
      [
        {
          id: '1',
          title: 'Company time off policy',
          description: 'Learn how to set goals and achieve them',
          image: 'assets/images/time-off/time-off-1.jpg'
        },
        {
          id: '2',
          title: 'How to plan work time off correctly',
          description: 'Learn how to set goals and achieve them',
          image: 'assets/images/time-off/time-off-2.jpg'
        },
        {
          id: '3',
          title: 'Handling emergencies and unplanned Leave',
          description: 'Learn how to set goals and achieve them',
          image: 'assets/images/time-off/time-off-3.jpg'
        }
      ]
    )
  }

  getGuide(id: string) {
    let guides = [
      {
        id: '1',
        html: `

    <h5>1. Overview of Time-Off Policies</h5>
    <p><strong>Summary:</strong> This section provides a general overview of the company's time-off policies, including the types of leave available.</p>
    <ul>
        <li>Paid Time Off (PTO) – Covers vacation days, personal days, and sick leave.</li>
        <li>Unpaid Leave – Includes extended personal leave, sabbaticals, and leave of absence.</li>
        <li>Public Holidays – Company-observed holidays where the office is closed.</li>
        <li>Parental Leave – Time off for new parents (maternity, paternity, adoption leave).</li>
        <li>Bereavement Leave – Time off due to the death of a family member.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Public Holidays:</strong> The company observes national holidays such as New Year’s Day, Independence Day, and Thanksgiving.</li>
    </ul>

    <h5>2. Accrual and Usage of Paid Time Off (PTO)</h5>
    <p><strong>Summary:</strong> This section explains how PTO is accrued, how it can be used, and any restrictions on its usage.</p>
    <ul>
        <li>PTO accrues based on the length of employment and hours worked.</li>
        <li>Employees can use PTO for vacations, personal days, or sick leave.</li>
        <li>Unused PTO may carry over to the next year, subject to a maximum limit.</li>
        <li>Requests for PTO should be submitted in advance and approved by management.</li>
        <li>PTO cannot be used during the notice period without management approval.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>PTO Accrual:</strong> Employees earn 1.5 days of PTO per month, totaling 18 days per year.</li>
    </ul>

    <h5>3. Requesting Time Off</h5>
    <p><strong>Summary:</strong> This section outlines the process for requesting time off, including the required notice period and approval process.</p>
    <ul>
        <li>Submit a time-off request through the company’s HR system.</li>
        <li>Provide at least two weeks' notice for planned time off (e.g., vacations).</li>
        <li>For unplanned absences (e.g., sick leave), notify your manager as soon as possible.</li>
        <li>Management will review and approve time-off requests based on staffing needs.</li>
        <li>Time-off requests during peak business periods may be subject to restrictions.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Time-Off Request:</strong> Submit your vacation request at least 30 days in advance for a two-week holiday.</li>
    </ul>

    <h5>4. Unpaid Leave of Absence</h5>
    <p><strong>Summary:</strong> This section describes the conditions under which employees can take unpaid leave and the process for requesting it.</p>
    <ul>
        <li>Unpaid leave may be granted for personal reasons, extended travel, or education.</li>
        <li>Approval for unpaid leave is at the discretion of management.</li>
        <li>Unpaid leave typically does not exceed three months.</li>
        <li>Employees must submit a formal request outlining the reason for the leave.</li>
        <li>Benefits coverage during unpaid leave may be affected.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Unpaid Leave:</strong> An employee may request unpaid leave for a three-month volunteer program abroad.</li>
    </ul>

    <h5>5. Parental Leave</h5>
    <p><strong>Summary:</strong> This section covers the company’s parental leave policy, including eligibility, duration, and how to apply.</p>
    <ul>
        <li>Parental leave is available to all employees who are new parents.</li>
        <li>Maternity leave offers up to 12 weeks of paid leave.</li>
        <li>Paternity leave provides up to 4 weeks of paid leave.</li>
        <li>Adoption leave is available for up to 8 weeks of paid leave.</li>
        <li>Employees must provide at least 30 days' notice before the expected due date or adoption.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Parental Leave:</strong> A new mother may take 12 weeks of maternity leave, starting two weeks before the expected due date.</li>
    </ul>

    <h5>6. Bereavement Leave</h5>
    <p><strong>Summary:</strong> This section outlines the company's policy on time off due to the death of a family member.</p>
    <ul>
        <li>Employees are entitled to up to 5 days of paid bereavement leave.</li>
        <li>Bereavement leave can be used for the death of an immediate family member.</li>
        <li>Additional unpaid leave may be requested if more time is needed.</li>
        <li>Employees should inform their manager as soon as possible when they need to take bereavement leave.</li>
        <li>Proof of the family member's death may be required.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Bereavement Leave:</strong> An employee may take five days off following the death of a parent, with the option to request additional unpaid leave if needed.</li>
    </ul>

    <h5>7. Public Holidays</h5>
    <p><strong>Summary:</strong> This section lists the company-observed public holidays and explains the policy for working on holidays.</p>
    <ul>
        <li>The company observes the following public holidays: New Year’s Day, Memorial Day, Independence Day, Labor Day, Thanksgiving, and Christmas Day.</li>
        <li>Employees will be paid for these holidays if they fall on a workday.</li>
        <li>If an employee is required to work on a public holiday, they may be eligible for additional pay or a compensatory day off.</li>
        <li>Part-time employees may receive pro-rated holiday pay based on their work schedule.</li>
        <li>Floating holidays may be offered, allowing employees to choose additional days off.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Holiday Pay:</strong> An employee who works on Independence Day may receive time-and-a-half pay or choose a different day off in lieu.</li>
    </ul>

    <h5>8. Carryover and Expiration of Time Off</h5>
    <p><strong>Summary:</strong> This section details the policy on carrying over unused time off and when it expires.</p>
    <ul>
        <li>Unused PTO can be carried over to the next year, up to a maximum of 10 days.</li>
        <li>PTO that exceeds the carryover limit will expire at the end of the year.</li>
        <li>Employees are encouraged to use their PTO to maintain work-life balance.</li>
        <li>Exceptions to the carryover limit may be granted in special circumstances with management approval.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Carryover Policy:</strong> An employee with 15 unused PTO days at the end of the year may carry over 10 days to the next year, with the remaining 5 days expiring.</li>
    </ul>

    <h5>9. Compliance with Legal Requirements</h5>
    <p><strong>Summary:</strong> This section ensures that the company's time-off policies comply with applicable labor laws and regulations.</p>
    <ul>
        <li>The company’s time-off policies adhere to federal, state, and local labor laws.</li>
        <li>All employees are entitled to the minimum required leave as per law.</li>
        <li>Any conflicts between company policy and legal requirements will default to the law.</li>
        <li>Employees can contact HR for clarification on legal entitlements and compliance issues.</li>
        <li>Any updates to the law will be reflected in company policy as soon as possible.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Compliance:</strong> The company complies with the Family and Medical Leave Act (FMLA) by providing up to 12 weeks of unpaid, job-protected leave for eligible employees.</li>
    </ul>`
      },
      {
        id: '2',
        html: `<h5>1. Understand Company Policies</h5>
    <p><strong>Summary:</strong> Familiarize yourself with your company's time-off policies, including the types of leave available and any restrictions.</p>
    <ul>
        <li>Review the employee handbook or HR portal for time-off policies.</li>
        <li>Know the types of leave available: PTO, sick leave, unpaid leave, etc.</li>
        <li>Be aware of any blackout dates or peak periods where time off may be restricted.</li>
        <li>Understand the process for requesting time off and who needs to approve it.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Policy Familiarity:</strong> Before planning a vacation, check the company's policy to ensure you have enough PTO accrued and that your time off request aligns with company guidelines.</li>
    </ul>

    <h5>2. Plan Ahead and Provide Notice</h5>
    <p><strong>Summary:</strong> Plan your time off well in advance and give your employer ample notice to accommodate your absence.</p>
    <ul>
        <li>Plan your time off at least a few months in advance, if possible.</li>
        <li>Submit your time-off request as early as possible, especially for extended vacations.</li>
        <li>Consider the workload and ensure coverage during your absence.</li>
        <li>Be flexible if your initial request cannot be accommodated.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Advance Planning:</strong> Submit your request for a two-week vacation three months in advance, allowing your manager time to plan for your absence.</li>
    </ul>

    <h5>3. Coordinate with Your Team</h5>
    <p><strong>Summary:</strong> Communicate with your colleagues and team members to ensure your absence doesn't disrupt the workflow.</p>
    <ul>
        <li>Discuss your planned time off with your team before submitting the request.</li>
        <li>Coordinate with colleagues to cover your responsibilities while you're away.</li>
        <li>Share important project details or deadlines with your team in advance.</li>
        <li>Ensure that someone is available to handle urgent matters in your absence.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Team Coordination:</strong> Arrange for a colleague to take over your client communications while you're on vacation, and brief them on any ongoing projects.</li>
    </ul>

    <h5>4. Set Up Out-of-Office Communications</h5>
    <p><strong>Summary:</strong> Prepare your out-of-office messages and inform key stakeholders of your absence to manage expectations.</p>
    <ul>
        <li>Set up an out-of-office email response that includes your return date and an alternate contact person.</li>
        <li>Update your voicemail greeting to inform callers of your absence.</li>
        <li>Notify key clients or partners of your time off in advance.</li>
        <li>Schedule a reminder for yourself to follow up on any critical tasks upon your return.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Out-of-Office Message:</strong> "I am currently out of the office and will return on [date]. For urgent matters, please contact [alternate contact] at [email/phone]."</li>
    </ul>

    <h5>5. Manage Your Workload Before and After</h5>
    <p><strong>Summary:</strong> Prepare for your time off by managing your workload effectively before you leave and catching up promptly upon your return.</p>
    <ul>
        <li>Complete critical tasks before your time off begins.</li>
        <li>Create a handover document or checklist for the person covering your work.</li>
        <li>Plan for a buffer day after returning to catch up on missed work and emails.</li>
        <li>Prioritize your tasks upon your return to ease back into your routine.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Workload Management:</strong> Finish any pending reports and set aside your first day back to review emails and meet with your team for updates.</li>
    </ul>

    <h5>6. Consider the Timing of Your Time Off</h5>
    <p><strong>Summary:</strong> Choose a time for your leave that minimizes disruption to your team and aligns with personal and work-related priorities.</p>
    <ul>
        <li>Avoid requesting time off during peak periods or major deadlines.</li>
        <li>Consider your personal schedule and upcoming life events.</li>
        <li>If possible, align your time off with quiet periods at work.</li>
        <li>Be mindful of your colleagues' time off to avoid overlapping absences.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Optimal Timing:</strong> Plan your vacation after the completion of a major project, allowing you to take a break without impacting deadlines.</li>
    </ul>

    <h5>7. Review and Reflect on Your Time Off</h5>
    <p><strong>Summary:</strong> After your time off, reflect on the process to identify areas for improvement in future planning.</p>
    <ul>
        <li>Assess how smoothly your absence was handled by your team.</li>
        <li>Gather feedback from colleagues or clients on any issues that arose.</li>
        <li>Reflect on your planning process and any areas that could be improved.</li>
        <li>Apply lessons learned to future time-off requests and planning.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Post-Leave Reflection:</strong> After returning, evaluate whether the handover process was effective and make notes on how to improve it for your next time off.</li>
    </ul>`
      },
      {
        id: '3',
        html: `<h5>1. Recognize the Need for Unplanned Leave</h5>
    <p><strong>Summary:</strong> Understand the situations where unplanned leave is necessary, such as sudden illness, family emergencies, or unexpected personal issues.</p>
    <ul>
        <li>Unplanned leave can include sick leave, family emergencies, or other urgent personal matters.</li>
        <li>It's important to act quickly and communicate as soon as possible.</li>
        <li>Familiarize yourself with your company’s policy on unplanned leave.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Immediate Response:</strong> If you wake up feeling seriously ill, notify your manager as soon as possible to inform them that you will be taking sick leave for the day.</li>
    </ul>

    <h5>2. Communicate Promptly and Clearly</h5>
    <p><strong>Summary:</strong> When an emergency arises, inform your employer as soon as possible, providing clear details about your situation and the expected duration of your absence.</p>
    <ul>
        <li>Contact your manager or HR as soon as you realize you need unplanned leave.</li>
        <li>Provide specific details about the reason for your absence and the estimated duration.</li>
        <li>If you’re unable to communicate directly, consider asking a family member or friend to inform your employer.</li>
        <li>Follow any specific company protocols for reporting emergencies or unplanned leave.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Clear Communication:</strong> "I’m dealing with a family emergency and will need to take leave starting today. I’m unsure how long it will last, but I’ll keep you updated."</li>
    </ul>

    <h5>3. Arrange for Work Coverage</h5>
    <p><strong>Summary:</strong> If possible, help arrange coverage for your responsibilities during your absence to minimize disruption to your team.</p>
    <ul>
        <li>If time allows, inform your team or delegate tasks before taking leave.</li>
        <li>Share important deadlines or project details with a colleague who can take over.</li>
        <li>Update your out-of-office communication to guide those who may need assistance while you're away.</li>
        <li>In cases where advance notice isn’t possible, ensure your manager is aware of ongoing tasks.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Task Delegation:</strong> "I’ve sent an email to Jane with instructions on handling my tasks while I’m away. She’s agreed to cover for me."</li>
    </ul>

    <h5>4. Provide Documentation if Required</h5>
    <p><strong>Summary:</strong> Depending on your company’s policies, you may need to provide documentation to support your unplanned leave, such as a doctor’s note.</p>
    <ul>
        <li>Check your company’s policy on documentation for unplanned leave, especially for extended absences.</li>
        <li>If required, obtain a doctor’s note or other relevant documentation.</li>
        <li>Submit the documentation to HR or your manager as soon as possible.</li>
        <li>Keep copies of all documents for your records.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Documentation Submission:</strong> After recovering from a serious illness, you submit a doctor’s note to HR confirming the dates of your medical leave.</li>
    </ul>

    <h5>5. Stay in Touch During Extended Leave</h5>
    <p><strong>Summary:</strong> If your unplanned leave extends for a longer period, maintain communication with your employer to provide updates on your situation.</p>
    <ul>
        <li>Provide regular updates to your manager or HR on your status and expected return date.</li>
        <li>If the situation changes, inform your employer as soon as possible.</li>
        <li>Be transparent about your ability to work remotely or respond to urgent matters, if applicable.</li>
        <li>Ensure your out-of-office communication remains updated if your return date changes.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Regular Updates:</strong> "I’m still in the hospital but expect to return to work next week. I’ll confirm my exact return date in a few days."</li>
    </ul>

    <h5>6. Plan Your Return to Work</h5>
    <p><strong>Summary:</strong> Before returning from unplanned leave, prepare to resume your responsibilities smoothly and communicate your return plans with your employer.</p>
    <ul>
        <li>Inform your employer of your return date and confirm your schedule.</li>
        <li>Catch up on any missed work or updates from your team.</li>
        <li>Gradually ease back into your workload, prioritizing critical tasks first.</li>
        <li>Schedule a meeting with your manager to discuss any changes or updates that occurred during your absence.</li>
    </ul>
    <p><strong>Example:</strong></p>
    <ul>
        <li><strong>Return Planning:</strong> "I’m planning to return to work on Monday. Could we set up a meeting that morning to go over anything I missed?"</li>
    </ul>`
      }
    ]

    return guides.find(guide => guide.id === id);
  }
}
